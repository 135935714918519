import React, { Component } from "react";
import { Alert, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Link } from "react-router-dom";
import {
    Body2,
    Heading6,
    Table,
    PaginationWrapper,
    OSUButton,
    OSULoading
} from "osu-react-components";
import {
    Row, Col
} from "reactstrap";
import { chunk } from "lodash";
import ComplexFilter from "../../../Common/ComplexFilter/components/ComplexFilter.js";
import Select from "react-select";
import toaster from 'toasted-notes';
import 'toasted-notes/src/styles.css'; // optional styles
import * as Constants from '../../constants.js';
import moment from "moment";
import EllipsisWithTooltip from 'react-ellipsis-with-tooltip';
import * as Globals from '../../../constants';
const COLLEGE = "COLLEGE";
const DORG = "DORG";
const PROGRAM_OF_STUDY = "PROGRAM OF STUDY";
const AWARDS = "AWARDS";

export default class CommitteeSelection extends Component {

    constructor(props) {

        super(props);
        this.state = {
            selectedReviewers: [],
            dataIndex: 0,
            rowsPerPage: 10,
            value: '',
            copied: false,
            assignPacketsModalOpen: false
        };

    }

    componentDidUpdate(prevProps) {
        if (prevProps.assignPacketsToReviewersSuccess === false && this.props.assignPacketsToReviewersSuccess === true) { // assign packets - success
            this.setState({ assignPacketsModalOpen: false }, // close modal
                () => {
                    this.props.findReviewers(); // update reviewers
                    this.props.setFocusOnAlerts(); // focus on alert
                }
            );
        }
    }

    dataKeys = () => {

        let dataKeys = [
            {
                key: "checked",
                label: "",
                width: 10,
                className: "align-self-end"
            },
            {
                key: "linkName",
                label: "Name",
                width: 60,
                className: "align-self-end min-width-0"
            },
            {
                key: "renderedStatus",
                label: "Status",
                width: 30,
                className: "align-self-end ellipsis min-width-0"
            }
        ];

        if (window.innerWidth > Globals.MOBILE_MAXIMUM_RESOLUTION) {
            dataKeys.push(
                {
                    key: "renderedDorg",
                    label: "D-Org",
                    width: 40,
                    className: "align-self-end ellipsis min-width-0"
                },
                {
                    key: "renderedCollege",
                    label: "College",
                    width: 60,
                    className: "align-self-end ellipsis min-width-0"
                })
        }



        return dataKeys;

    }

    addNewReviewer = () => {
        return <Heading6 className="mb-2">{`ALL Reviewers`}</Heading6>;
    };

    collegeDropdownResults = () => {
        return Array.from(
            new Set(this.state.reviewers.map(reviewer => reviewer.college ? reviewer.college.toString() : ""))
        ).sort();
    };

    dorgDropdownResults = () => {
        return Array.from(
            new Set(
                this.state.reviewers.map(
                    reviewer => (reviewer.dorg ? reviewer.dorg : reviewer.dOrg)
                )
            )
        ).sort();
    };

    programOfStudyDropdownResults = () => {
        const awards = [];
        this.state.reviewers.forEach(function (reviewer) {
            if (reviewer.shortPlans) {
                awards.push(reviewer.shortPlans);
            }
        });
        return Array.from(new Set(awards.reduce((acc, val) => acc.concat(val), []))).sort();
    };

    awardsDropdownResults = () => {
        return Array.from(
            new Set(
                this.state.reviewers.map(
                    reviewer => (Constants.AWARD_STATUS_MAP[reviewer.status] ? Constants.AWARD_STATUS_MAP[reviewer.status] : reviewer.status)
                )
            )
        ).sort();
    };

    setDataIndex = dataIndex => {
        this.setState({ dataIndex });
    };

    setRowsPerPage = rowsPerPage => {
        this.setState({ rowsPerPage });
    };

    linkReviewerDetails = filteredReviewers => {
        if (filteredReviewers.length === 0) {
            return [];
        }
        const linkedReviewerDetails = filteredReviewers.map(reviewer => {
            reviewer.linkName = (
                <Link to={{
                    pathname: "/reviewer-management/reviewer/" + reviewer.emplid,
                    state: {
                        breadcrumb: {
                            path: "/reviewer-management/committee", text: "Committee Selection"
                        }
                    }
                }}>
                    {reviewer.name}
                </Link>
            );
            const disabled = reviewer.status === Constants.AWARD_STATUS_LABEL_ASSIGNED
            reviewer.renderedDorg = (<EllipsisWithTooltip placement="bottom">{reviewer.dorg}</EllipsisWithTooltip>)
            reviewer.renderedCollege = (<EllipsisWithTooltip placement="bottom">{reviewer.college}</EllipsisWithTooltip>)


            reviewer.checked = (
                <div style={{ 'textAlign': 'left' }} ><label htmlFor={`select-${reviewer.emplid}`} className="sr-only">{`Select ${reviewer.name}`}</label><input id={`select-${reviewer.emplid}`} type="checkbox" onChange={event => this.modifyCheckedList(event.target.checked, reviewer)} disabled={disabled} >
                </input></div>
            )

            reviewer.status = Constants.AWARD_STATUS_MAP[reviewer.status] ? Constants.AWARD_STATUS_MAP[reviewer.status] : reviewer.status;
            reviewer.renderedStatus = (<div className="ellipsis">{reviewer.status}</div>)
            return reviewer;
        });
        return linkedReviewerDetails;
    };

    modifyCheckedList(checked, reviewer) {
        const state = this.state;
        if (checked) {
            state.selectedReviewers.push(reviewer.emplid);

        } else {
            state.selectedReviewers = state.selectedReviewers.filter(function (value, index, arr) {
                return value !== reviewer.emplid;
            });
        }
        this.setState(state)
    }

    filteredReviewers = (query, allReviewers) => {
        // replace allReviewers with empty array if the data hasn't pulled yet
        allReviewers = allReviewers ? allReviewers : [];
        const reviewers = this.filterOnDropDownSelections(
            this.filterOnNamesAndComments(allReviewers, query)
        );
        reviewers.forEach(function (reviewer) {
            reviewer.dorg = reviewer.dOrg;
            reviewer.committesServed = reviewer.stats
                ? Object.keys(reviewer.stats).length
                : 0;
        });
        return reviewers;
    };

    filterInactiveReviewers(reviewers) {
        const filteredReviewers = reviewers.filter(reviewer => {
            return reviewer.active ? reviewer : null;
        });
        return filteredReviewers;
    }

    filterOnNamesAndComments(reviewers, query) {
        const filteredReviewers = reviewers.filter(reviewer => {
            if (query && query !== "") {
                return (
                    (reviewer.name && reviewer.name.toLowerCase().includes(query.toLowerCase())) ||
                    ((reviewer.comments &&
                        reviewer.comments.toLowerCase().includes(query.toLowerCase())) ||
                        (reviewer.emplid && reviewer.emplid.toLowerCase().includes(query.toLowerCase())))
                );
            }
            return reviewer;
        });
        return filteredReviewers;
    }

    filterOnDropDownSelections(reviewers) {
        let filteredReviewers = reviewers.slice();
        filteredReviewers = this.state.collegeDropdownSelection
            ? this.filterReviewersForDropdown(
                filteredReviewers,
                this.state.collegeDropdownSelection,
                COLLEGE
            )
            : filteredReviewers;
        filteredReviewers = this.state.dorgDropdownSelection
            ? this.filterReviewersForDropdown(
                filteredReviewers,
                this.state.dorgDropdownSelection,
                DORG
            )
            : filteredReviewers;
        filteredReviewers = this.state.programOfStudyDropdownSelection
            ? this.filterReviewersForDropdown(
                filteredReviewers,
                this.state.programOfStudyDropdownSelection,
                PROGRAM_OF_STUDY
            )
            : filteredReviewers;
        filteredReviewers = this.state.awardsDropdownSelection
            ? this.filterReviewersForDropdown(
                filteredReviewers,
                this.state.awardsDropdownSelection,
                AWARDS
            )
            : filteredReviewers;
        return filteredReviewers;
    }

    filterReviewersForDropdown(reviewers, selection, dropdownType) {
        const filteredReviewers = [];
        reviewers.forEach(function (reviewer) {
            if (dropdownType === COLLEGE && reviewer.college === selection) {
                filteredReviewers.push(reviewer);
            } else if (dropdownType === DORG && reviewer.dOrg === selection) {
                filteredReviewers.push(reviewer);
            } else if (dropdownType === PROGRAM_OF_STUDY) {
                reviewer.shortPlans.forEach(function (program) {
                    if (program === selection) {
                        filteredReviewers.push(reviewer);
                    }
                });
            } else if (dropdownType === AWARDS) {

                selection.length === 0 ? filteredReviewers.push(reviewer) :
                    selection.forEach(selectedElement => {
                        if (reviewer.status === selectedElement.value) {
                            filteredReviewers.push(reviewer);
                        }
                    });

            }
        });
        return filteredReviewers;
    }


    updateFilterState() {
        const state = this.state;
        if (this.filterStateHasChanged(state)) {
            state.collegeDropdownSelection = this.props.filterOnCollegeDropdown
                ? this.props.filterOnCollegeDropdown
                : "";
            state.dorgDropdownSelection = this.props.filterOnDorgDropdown
                ? this.props.filterOnDorgDropdown
                : "";
            state.programOfStudyDropdownSelection = this.props
                .filterOnProgramOfStudyDropdown
                ? this.props.filterOnProgramOfStudyDropdown
                : "";
            state.awardsDropdownSelection = this.props.filterOnAwardsDropdown
            this.setState(state);
        }

    }

    filterStateHasChanged(state) {
        return (
            state.collegeDropdownSelection !== this.props.filterOnCollegeDropdown ||
            state.dorgDropdownSelection !== this.props.filterOnDorgDropdown ||
            state.programOfStudyDropdownSelection !== this.props.filterOnProgramOfStudyDropdown ||
            state.awardsDropdownSelection !== this.props.filterOnAwardsDropdown)
    }

    updateRenderedReviewerState(renderableReviewers) {
        const state = this.state;
        if (!state.reviewers || state.reviewers.length !== renderableReviewers.length) {
            state.reviewers = renderableReviewers;
            this.setState(state);
        }
    }

    setAssignPacketsModalOpen = (assignPacketsModalOpen) => {
        this.setState({ assignPacketsModalOpen });
    }

    renderAssignPacketsModal = () => {
        return (
            <Modal isOpen={this.state.assignPacketsModalOpen} onClosed={() => this.props.resetAssignPacketsToSelectedReviewersFlags()}>
                <ModalHeader>
                    Assign Packets
                </ModalHeader>
                <ModalBody>
                    {this.props.assignPacketsToReviewersProcessing ?
                        (<div>
                                {!this.props.assignPacketsToReviewersProcessingMessage ?
                            <OSULoading text="Processing... This might take long time to process... Hang on there..." /> : <OSULoading text={this.props.assignPacketsToReviewersProcessingMessage} /> }
                        </div>
                        ) :
                        (<div>
                            {this.props.assignPacketsToReviewersError &&
                                <Alert color="danger" toggle={() => this.props.resetAssignPacketsToSelectedReviewersFlags()}>
                                    There was an error assigning packets
                                    {!this.props.assignPacketsToReviewersErrorMessage ?
                                        (<span>.<br />Please retry to see if it resolves the issue.</span>) :
                                        (<span>:<br />{this.props.assignPacketsToReviewersErrorMessage}</span>)
                                    }
                                </Alert>
                            }
                            <Body2>Are you sure that you want to assign packets?</Body2>
                        </div>)
                    }
                </ModalBody>
                <ModalFooter>
                    {!this.props.assignPacketsToReviewersProcessing && [
                        <OSUButton ariaLabel="No" key="no" color="gray" solid uppercase={false} className="mr-1" onClick={() => { this.props.resetAssignPacketsToSelectedReviewersFlags(); this.setAssignPacketsModalOpen(false); }}>No</OSUButton>,
                        <OSUButton ariaLabel="Yes" key="yes" color="blue" solid uppercase={false} onClick={() => {this.props.resetAssignPacketsToSelectedReviewersFlags(); this.props.assignPacketsToSelectedReviewers(this.props.systemAward.pk)}}>Yes</OSUButton>
                    ]}
                </ModalFooter>
            </Modal>
        );
    }

    render() {

        if (this.props.awardReviewersLoading) {
            return (<OSULoading text="Loading..." />);
        } else {
            const {
                searchReviewers,
                query,
            } = this.props;

            const filteredReviewers = this.filteredReviewers(query, this.props.awardReviewers);
            const renderableReviewers = this.linkReviewerDetails(filteredReviewers);
            this.updateFilterState();
            this.updateRenderedReviewerState(renderableReviewers);

            const filters = [];
            const collegeFilter = {
                action: this.props.collegeFilterAction,
                name: "College",
                selection: this.props.filterOnCollegeDropdown,
                results: this.collegeDropdownResults(),
            }
            const dorgFilter = {
                action: this.props.dorgFilterAction,
                name: "D-Org",
                selection: this.props.filterOnDorgDropdown,
                results: this.dorgDropdownResults(),
            }
            const programOfStudyFilter = {
                action: this.props.programOfStudyFilterAction,
                name: "Program Of Study",
                selection: this.props.filterOnProgramOfStudyDropdown,
                results: this.programOfStudyDropdownResults(),
            }
            const statusFilter = {
                action: this.props.awardsFilterAction,
                name: "Status",
                selection: this.props.filterOnAwardsDropdown,
                results: ["Assigned", "Selected", "Alternate", "None", "Declined", "Accepted"],
                multiSelectable: "yes"
            }
            filters.push(collegeFilter);
            filters.push(dorgFilter);
            filters.push(programOfStudyFilter);
            filters.push(statusFilter);

            const chunkedData =
                renderableReviewers.length > 0
                    ? chunk(
                        renderableReviewers,
                        this.state.rowsPerPage || renderableReviewers.length
                    )
                    : [[]];

            return (
                <div>
                    <br />
                    <div>
                        <ComplexFilter
                            filters={filters}
                            searchReviewers={searchReviewers}
                            query={query}
                            clearAll={this.clearAll}
                        />
                        <PaginationWrapper
                            rowsPerPageOptions={[10, 20, 30]}
                            persist
                            totalPageCount={chunkedData.length}
                            updateDataIndex={this.setDataIndex}
                            updateRowsPerPage={this.setRowsPerPage}
                            rowsPerPage={this.state.rowsPerPage}
                            dataIndex={this.state.dataIndex}
                            resultsData={{ shownResults: renderableReviewers.length, totalResults: this.props.awardReviewers.length }}
                            showOptionalCount={true}
                        >
                            <Table
                                sortable
                                paginationData={{
                                    rowsPerPage: this.state.rowsPerPage,
                                    dataIndex: this.state.dataIndex,
                                }}
                                defaultSortColumn={1}
                                headerVariant="subtitle2"
                                data={renderableReviewers}
                                dataKeys={this.dataKeys(this.props.scoringCriteria)}
                            />
                            <br />
                            <Row>
                                <Col>
                                    <Select aria-label="Update Reviewer Status" isClearable={false} menuPlacement="top" 
                                        isDisabled={this.statusChangeIsDisabled()} defaultValue={{ label: "No Reviewer Selected", value: "change_status" }}
                                        onChange={elementClicked => this.props.changeStatusOfReviewers(elementClicked, this.props.systemAward.pk, this.state.selectedReviewers)}
                                        options={this.changeStatusMenu()} value={{ label: this.defaultChangeStatusLabel(), value: "change_status" }}>Change Status</Select></Col>
                                <Col></Col>
                                <Col>                            <div style={{ 'float': 'right' }}>
                                    <CopyToClipboard text={this.copyEmailAddresses(renderableReviewers)}
                                        onCopy={() => {
                                            toaster.notify('Emails Copied to clipboard.', {
                                                position: 'bottom-right', // top-left, top, top-right, bottom-left, bottom, bottom-right
                                                duration: 3000
                                            })
                                        }}>
                                        <OSUButton ariaLabel="Copy Email Addresses" outline>Copy Email Addresses</OSUButton>
                                    </CopyToClipboard>&nbsp;&nbsp;
                                    <OSUButton ariaLabel="Assign Packets" onClick={() => this.setAssignPacketsModalOpen(true)} disabled={this.assignedPacketsIsDisabled()} color="red">Assign Packets</OSUButton>
                                    {this.renderAssignPacketsModal()}
                                </div></Col>


                            </Row>

                        </PaginationWrapper>
                    </div>
                </div>
            );
        }
    }

    defaultChangeStatusLabel() {
        return this.statusChangeIsDisabled() ? "No Reviewer Selected" : "Update status to..."
    }

    assignedPacketsIsDisabled() {
        //return moment(moment().format()).isAfter(this.props.systemAward.reviewStartDate);
        return moment().isSameOrAfter(moment(this.props.systemAward.reviewStartDate).subtract(30,"minutes"))

    }

    statusChangeIsDisabled() {
        return !(this.state.selectedReviewers.length > 0);
    }

    changeStatusMenu() {

        return [
            {
                label: Constants.AWARD_STATUS_LABEL_NONE,
                value: Constants.AWARD_STATUS_KEY_NONE
            },
            {
                label: Constants.AWARD_STATUS_LABEL_SELECTED,
                value: Constants.AWARD_STATUS_KEY_SELECTED
            },
            {
                label: Constants.AWARD_STATUS_LABEL_ACCEPTED,
                value: Constants.AWARD_STATUS_KEY_ACCEPTED
            },
            {
                label: Constants.AWARD_STATUS_LABEL_ALTERNATE,
                value: Constants.AWARD_STATUS_KEY_ALTERNATE
            },
            {
                label: Constants.AWARD_STATUS_LABEL_DECLINED,
                value: Constants.AWARD_STATUS_KEY_DECLINED
            },
        ];
    };

    copyEmailAddresses(reviewers) {
        const emailAddresses = reviewers.map(reviewer =>
            reviewer.email
        );
        return this.convertEmailListToSemicolonSeperatedString(emailAddresses);
    }



    convertEmailListToSemicolonSeperatedString(emailAddresses) {
        //replace left brace, right brace, or double-quote with empty string, replace comma with semicolon for easy email pasting
        return emailAddresses ? JSON.stringify(emailAddresses).replace(/"|]|\[|"/g, "").replace(/,/g, ";") : ""
    }
};

