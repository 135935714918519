import React, { Component } from "react";
import PropTypes from "prop-types";
import { Icon, OSUButton } from "osu-react-components";
import Moment from "moment";

class ScoreDistribution extends Component {
    getScoreRanges = () => {
        const { reviewerStats } = this.props;
        return (reviewerStats && reviewerStats.scoreRanges) ? reviewerStats.scoreRanges : null;
    };

    buildStatusMessage = () => {
        const { reviewerStats } = this.props;
        return (reviewerStats.totalNominees && reviewerStats.totalPrimaryNominees && reviewerStats.percentCompleted) ?
            `Status: ${reviewerStats.totalNominees} Reviews (${reviewerStats.totalPrimaryNominees} Composite), ${reviewerStats.percentCompleted} Complete` :
            null;
    };

    buildScoreMessage = () => {
        const { reviewerStats } = this.props;
        let scoreMessage = null;
        if (this.props.rankedScoresExist && reviewerStats.notEvaluatedCount === 0) { // scores in range and complete
            scoreMessage = (<span><h2><Icon type="check" color="green" size="xs" /> Reviews are complete and ranks have been adjusted!</h2></span>);
        } else if (reviewerStats.validQuintile === true && reviewerStats.notEvaluatedCount > 0) { // scores in range, but not complete
            const reviewCompletionDate = this.getReviewCompletionDate();
            if (reviewCompletionDate !== null && Moment().isSameOrAfter(reviewCompletionDate)) {
                scoreMessage = (<span><Icon type="exclamation-triangle" color="red" size="xs" /> Review completion date has passed, please complete scores immediately.</span>);
            } else {
                // scoreMessage = (<span><Icon type="exclamation-triangle" color="red" size="xs" /> All scores must be within assigned quintile distribution ranges.</span>);
            }
        } else if (reviewerStats.validQuintile === false) { // scores out of range
            // scoreMessage = (<span><Icon type="exclamation-triangle" color="red" size="xs" /> Scores do not meet assigned quintile distribution ranges. Scores must be in assigned ranges for calculation of nominee’s final weighted score.</span>);
        }
        return scoreMessage
    };

    getReviewCompletionDate = () => {
        const { reviewCompletionDate } = this.props;
        return reviewCompletionDate ?
            (Moment(reviewCompletionDate).isValid ?
                Moment(reviewCompletionDate) :
                null) :
            null;
    };

    render() {
        const scoreRanges = this.getScoreRanges();
        if (scoreRanges) {
            const tableHeadColSpan = scoreRanges.length + 1;
            const statusMessage = this.buildStatusMessage();
            const scoreMessage = this.buildScoreMessage();
            const scoreAttributesRequiredOrAdjustedText = this.props.rankedScoresExist ? "Rank Applied" : "Required";
            return (
                <div data-testid="scoreDistribution" className="w-100 border rounded p-2">
                    <table className="w-100" style={{ minWidth: "24rem" }}>
                        <thead data-testid="header" className="border-bottom">
                            {scoreMessage &&
                                <tr>
                                    <th colSpan={tableHeadColSpan} className="typography typography-caption">
                                        {scoreMessage}
                                    </th>
                                </tr>
                            }
                            <tr>
                                <th colSpan={tableHeadColSpan} className="typography typography-subtitle1">
                                    Overall Weighted Score Distribution
                                </th>
                                <th colSpan={tableHeadColSpan} className="typography typography-subtitle1">
                                    <OSUButton onClick={() => this.props.onCalculateRankedScores(true)} ariaLabel="Calculate Ranked Scores" disabled={!this.props.rankedScoresEnabled} color="blue" className={`mt-2${false ? " d-none" : ""}`}>
                                        Calculate Ranked Scores
                                    </OSUButton>
                                </th>
                            </tr>

                            {statusMessage &&
                                <tr>
                                    <th colSpan={tableHeadColSpan} className="typography typography-caption">
                                        {statusMessage}
                                    </th>
                                </tr>
                            }
                        </thead>
                        <tbody>
                            <tr style={{ height: "0.5rem" }} />
                            <tr>
                                <th scope="row" data-testid="scoreAttributes0" className="typography typography-subtitle2 font-weight-bold">Range</th>
                                {scoreRanges.map((score, index) =>
                                    <th scope="col" data-testid={`scoreAttributes0Values${index}`} key={`scoreAttributes0Values${index}`} className={`typography typography-body2 text-center${index !== scoreRanges.length - 1 ? " border-right" : ""}`}>
                                        {score.hasOwnProperty('range') ? score.range : '-'}
                                        {/* {score.flag && <Icon color="red" type="exclamation-triangle" ariaLabel="Scores out of assigned ranges." className="ml-1 invisible" />} */}
                                    </th>
                                )}
                            </tr>
                            <tr>
                                <th scope="row" data-testid="scoreAttributes1" className="typography typography-subtitle2 font-weight-bold">Current</th>
                                {scoreRanges.map((score, index) =>
                                    <th data-testid={`scoreAttributes1Values${index}`} key={`scoreAttributes1Values${index}`} className={`typography typography-body2 text-center${index !== scoreRanges.length - 1 ? " border-right" : ""}`}>
                                        {score.hasOwnProperty('currentCount') ? score.currentCount : '-'}
                                        {/* {score.flag && <Icon color="red" type="exclamation-triangle" ariaLabel="Scores out of assigned ranges." className="ml-1" />} */}
                                    </th>
                                )}
                            </tr>
                            <tr>
                                <th scope="row" data-testid="scoreAttributes2" className="typography typography-subtitle2 font-weight-bold">{scoreAttributesRequiredOrAdjustedText}</th>
                                {scoreRanges.map((score, index) =>
                                    <th data-testid={`scoreAttributes2Values${index}`} key={`scoreAttributes2Values${index}`} className={`typography typography-body2 text-center${index !== scoreRanges.length - 1 ? " border-right" : ""}`}>
                                        {score.hasOwnProperty('maxAllowedCount') ? score.maxAllowedCount : '-'}
                                        {/* {score.flag && <Icon color="red" type="exclamation-triangle" ariaLabel="Scores out of assigned ranges." className="ml-1 invisible" />} */}
                                    </th>
                                )}
                            </tr>
                        </tbody>
                    </table>
                </div>
            );
        } else {
            return null;
        }
    }
}

ScoreDistribution.propTypes = {
    reviewCompletionDate: PropTypes.string,
    rankedScoresEnabled: PropTypes.bool,
    reviewerStats: PropTypes.shape({
        percentCompleted: PropTypes.string,
        scoreRanges: PropTypes.arrayOf(
            PropTypes.shape({
                currentCount: PropTypes.number,
                flag: PropTypes.bool,
                maxAllowedCount: PropTypes.number,
                range: PropTypes.string
            })
        ),
        totalNominees: PropTypes.number,
        totalPrimaryNominees: PropTypes.number,
        validQuintile: PropTypes.bool
    })
}

export default ScoreDistribution;