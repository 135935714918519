import React, { Component } from "react";
import { connect } from "react-redux";
import { getCurrentAwards } from "../../actions-index";
import AwardDates from "../components/AwardDates";
import { filterCurrentAwardsForNominations } from "../transform";

export default function(WrappedComponent) {
    class WithAwardDates extends Component {
        componentDidMount() {
            const { currentAwards, isAuthorized } = this.props;
            if(isAuthorized && currentAwards.status === "") {
                getCurrentAwards();
            }
        }

        render() {
            const { list: currentAwards = [], status: currentAwardsStatus = "" } = this.props.currentAwards;
            return (
                <div className="d-flex flex-xs-wrap justify-content-between">
                    <div className="mb-4 pr-2 w-100">
                        <WrappedComponent {...this.props} />
                    </div>
                    <div>
                        {(this.props.isAuthorized && currentAwardsStatus === "success") &&
                            <AwardDates awards={currentAwards} />
                        }
                    </div>
                </div>
            );
        }
    }

    const mapStateToProps = (state, props) => {
        return {
            currentAwards: filterCurrentAwardsForNominations(state.currentAwards),
            isAuthorized: (props.isAdmin === true || props.isNominator === true)
        };
    };
    
    const mapDispatchToProps = (dispatch) => {
        return {
            getCurrentAwards: () => dispatch(getCurrentAwards())
        };
    };

    return connect(mapStateToProps, mapDispatchToProps)(WithAwardDates)
}
