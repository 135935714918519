import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { Alert, Card, CardBody, CardTitle, Col, Form, FormFeedback, FormGroup, Input, Label, UncontrolledTooltip } from "reactstrap";
import Select from "react-select";
import { Body2, Icon, OSUButton, OSUError, OSULoading, Subtitle2 } from "osu-react-components";
import { Auth } from "aws-amplify";
import AwardDates from "../../Common/AwardDates/components/";
import { ACTION_STATE_ERROR, ACTION_STATE_LOADING, ACTION_STATE_SUCCESS, FORM_FEEDBACK_SR_PREFIX } from "../../util/constants";
import { redirectToLogOut } from "../../util/util";
import { find, forOwn, keys, without } from "lodash";
import moment from "moment";
import { ADDITIONAL_QUESTIONS, AWARD_DATE_FORMAT, AWARD_NAME_MAX_LENGTH, AWARD_NAME_REGEX, AWARD_TERM_FALL,
    AWARD_TERM_SPRING, AWARD_TERM_SUMMER, GPA_ELIGIBILITY_WAIVER, GPA_ELIGIBILITY_WAIVER_PETITION, GPA_MAX_LENGTH,
    GPA_REGEX, NOMINATIONS_LIMITED_BY, STUDENT_STATUSES, STUDENT_STATUS_NEWADMIT } from "../constants";
import {PAGE_TITLE_POSTFIX} from "../../constants";

const ACTION_VALUE_CREATE = "CREATE";
const ACTION_VALUE_COPY = "COPY";
const CLASSNAME_BODY1 = "typography typography-body1";
const CLASSNAME_HEADING2 = "heading6 mb-2 mt-4";
const CLASSNAME_CHECKBOX = "position-relative ml-3";

export default class CreateAward extends Component {
    constructor(props) {
        super(props);
        const now = moment();
        const year = now.year() - (now.month() < 7 ? 1 : 0); // use current year if the month is August or later, otherwise use previous year
        const academicYears = [];
        for(let i = 0; i < 3; i++) {
            const academicYearStart = (year + i);
            const academicYearEnd = (academicYearStart + 1);
            const academicYear = `${academicYearStart}-${academicYearEnd}`;
            academicYears.push({ label: academicYear, value: academicYear });
        }
        const studentStatusOptions = [];
        forOwn(STUDENT_STATUSES, (value, key) => {
            studentStatusOptions.push({ label: value, value: key });
        });
        const nominationsLimitedByOptions = [];
        forOwn(NOMINATIONS_LIMITED_BY, (value, key) => {
            nominationsLimitedByOptions.push({ label: value, value: key });
        });
        const gpaEligibilityWaiverOptions = [];
        forOwn(GPA_ELIGIBILITY_WAIVER, (value, key) => {
            gpaEligibilityWaiverOptions.push({ label: value, value: key });
        });
        this.state = {
            pageTitle: "Create Award",
            actions: [
                { label: "Copy from a previous award", value: ACTION_VALUE_COPY },
                { label: "Manually create the award", value: ACTION_VALUE_CREATE }
            ],
            studentStatusOptions,
            academicYears,
            awardTerms: [
                { label: AWARD_TERM_FALL, value: AWARD_TERM_FALL },
                { label: AWARD_TERM_SPRING, value: AWARD_TERM_SPRING },
                { label: AWARD_TERM_SUMMER, value: AWARD_TERM_SUMMER }
            ],
            nominationsLimitedByOptions,
            gpaEligibilityWaiverOptions,
            name: "",
            action: null,
            previousAward: null,
            previousAwardDates: null,
            category: "",
            studentStatus: null,
            academicYear: null,
            awardTerm: null,
            nominationsLimitedBy: nominationsLimitedByOptions[0],
            additionalQuestions: [],
            minimumGpaRequired: false,
            gpaRequirement: "",
            gpaEligibilityWaiver: gpaEligibilityWaiverOptions[1],
            petitionDisclaimer: "",
            requireReview: true,
            requireDocumentUpload: true,
            combineAwardResults: false,
            createAwardLetter: true,
            sendAwardLetter: true,
            awardDates: null,
            awardDatesInvalid: true,
            validForm: false,
            info: {
                combineAwardResults: "Combine award results for an individual student + academic plan. For all awards with the same academic year, award term, student status.",
                createAwardLetter: "PDF will be generated with Graduate School letter head."
            }
        }
    }

    componentWillMount() {
        document.title = this.state.pageTitle + PAGE_TITLE_POSTFIX;
    }

    componentDidMount() {
        this.props.setA11yMessage(`Navigated to the ${this.state.pageTitle} page`);
        this.pageContent.focus();
        this.props.getPreviousAwards();
        setTimeout(() => { // hack to add aria-required to react-select
            this.setAriaRequired("academicYearSelect");
            this.setAriaRequired("awardTermSelect");
        }, 500); // provide time for react-select to mount
    }

    componentDidUpdate(prevProps) {
        if((prevProps.createAwardState !== ACTION_STATE_ERROR && this.props.createAwardState === ACTION_STATE_ERROR) ||
            (prevProps.createAwardState !== ACTION_STATE_SUCCESS && this.props.createAwardState === ACTION_STATE_SUCCESS)) {
            this.alerts.focus();
        }
    }

    componentWillUnmount() {
        this.props.resetCreateAwardState();
        this.props.clearCreateAwardKey();
    }

    setAriaRequired = (elementId) => {
        const element = document.getElementById(elementId);
        if(element) {
            const ariaRequired = element.getAttribute("aria-required");
            if(!ariaRequired || ariaRequired !== true) element.setAttribute("aria-required", true);
        }
    }

    validateRequired = (value) => {
        return (value && value.length > 0);
    }

    validateName = (name) => {
        return (this.validateRequired(name) && name.length <= AWARD_NAME_MAX_LENGTH && AWARD_NAME_REGEX.test(name));
    }

    validateGpa = (gpa) => {
        return (this.validateRequired(gpa) && gpa.length <= GPA_MAX_LENGTH && GPA_REGEX.test(gpa));
    }

    onFormInputChange = (event, validator = this.validateRequired) => {
        const name = event.target.name;
        const value = event.target.value;
        const invalidName = `${name}Invalid`;
        const invalidValue = !(validator(value));
        this.setState({ [name]: value, [invalidName]: invalidValue }, () => this.validateForm());
    }

    onActionChange = (event) => {
        const action = event.target.value;
        this.setState({ action, category: null, previousAward: null, previousAwardDates: null, studentStatus: null }, () => {
            // hack to add aria-required to react-select
            if(action === ACTION_VALUE_CREATE) {
                this.setAriaRequired("studentStatusSelect");
            }
            if(action === ACTION_VALUE_COPY) {
                this.setAriaRequired("previousAwardSelect");
            }
            this.setAriaRequired("nominationsLimitedBySelect");
            this.validateForm();
        });
    }

    onFormSelectChange = (name, selectedOption) => {
        this.setState({ [name]: selectedOption }, () => this.validateForm());
    }

    onMinimumGpaRequiredChange = (event) => {
        const checked = event.target.checked;
        this.setState({ minimumGpaRequired: checked }, () => {
            this.setAriaRequired("gpaEligibilityWaiverSelect"); // hack to add aria-required to react-select
            this.validateForm();
        });
    }

    onPreviousAwardsSelectChange = (selectedOption) => {
        const state = { previousAward: selectedOption };
        const previousAward = selectedOption.data;
        
        const studentStatus = (find(this.state.studentStatusOptions, ["value", previousAward.studentStatus]) || null);
        state.studentStatus = studentStatus;
        state.category = previousAward.category || null;

        const nominationInfo = previousAward.nominationInfo;
        if(nominationInfo) {
            if(nominationInfo.nominationsLimitedBy) {
                const nominationsLimitedBy = (find(this.state.nominationsLimitedByOptions, ["value", nominationInfo.nominationsLimitedBy]) || null);
                if(nominationsLimitedBy) state.nominationsLimitedBy = nominationsLimitedBy;
            }
            if(nominationInfo.additionalQuestions) state.additionalQuestions = nominationInfo.additionalQuestions;
        }

        const eligibility = previousAward.eligibility;
        if(eligibility) {
            const minimumGpaRequired = eligibility.minimumGpaRequired;
            if(typeof minimumGpaRequired === "boolean") state.minimumGpaRequired = minimumGpaRequired;
            if(minimumGpaRequired === true) {
                if(eligibility.gpaRequirement)  {
                    state.gpaRequirement = eligibility.gpaRequirement;
                    state.gpaRequirementInvalid = !this.validateGpa(eligibility.gpaRequirement); // required by validateForm()
                }
                if(eligibility.gpaEligibilityWaiver) {
                    const gpaEligibilityWaiver = (find(this.state.gpaEligibilityWaiverOptions, ["value", eligibility.gpaEligibilityWaiver]) || null);
                    if(gpaEligibilityWaiver) {
                        state.gpaEligibilityWaiver = gpaEligibilityWaiver;
                        if(gpaEligibilityWaiver.value === GPA_ELIGIBILITY_WAIVER_PETITION && eligibility.petitionDisclaimer) {
                            state.petitionDisclaimer = eligibility.petitionDisclaimer;
                        }
                    }
                }
            }
        }

        if(typeof previousAward.requireDocumentUpload === "boolean") state.requireDocumentUpload = previousAward.requireDocumentUpload;
        if(typeof previousAward.requireReview === "boolean") state.requireReview = previousAward.requireReview;

        if(typeof previousAward.combineAwardResults === "boolean") {
            state.combineAwardResults = previousAward.combineAwardResults; // use previous award value
        } else {
            state.combineAwardResults = ((studentStatus && studentStatus === STUDENT_STATUS_NEWADMIT) ? true : false); // default by student status
        }
        if(typeof previousAward.createAwardLetter === "boolean") state.createAwardLetter = previousAward.createAwardLetter;
        if(typeof previousAward.sendAwardLetter === "boolean") state.sendAwardLetter = previousAward.sendAwardLetter;

        state.previousAwardDates = {
            nominationStartDate: previousAward.nominationStartDate || "",
            nominationEndDate: previousAward.nominationEndDate || "",
            reviewStartDate: previousAward.reviewStartDate || "",
            reviewCompletionDate: previousAward.reviewCompletionDate || "",
            reviewEndDate: previousAward.reviewEndDate || "",
            reviewCommitteeMeetingStartDate: previousAward.reviewCommitteeMeetingStartDate || "",
            reviewCommitteeMeetingEndDate: previousAward.reviewCommitteeMeetingEndDate || "",
            reviewAccessEndDate: previousAward.reviewAccessEndDate || "",
            awardPostedDate: previousAward.awardPostedDate || "",
            awardLetterSendOptionDate: previousAward.awardLetterSendOptionDate || "",
            awardLetterSentDate: previousAward.awardLetterSentDate || "",
            acceptanceDeadlineDate: previousAward.acceptanceDeadlineDate || "",
            awardEndDate: previousAward.awardEndDate || ""
        };

        this.setState(state, () => this.validateForm());
    }

    onAwardDatesValidate = (isValid, awardDates) => {
        this.setState({ awardDates, awardDatesInvalid: !isValid }, () => this.validateForm());
    }

    validateForm = () => {
        let validForm;
        switch(this.state.action) {
            case ACTION_VALUE_COPY:
                validForm = (
                    this.state.name !== "" &&
                    this.state.nameInvalid === false &&
                    this.state.academicYear !== null &&
                    this.state.awardTerm !== null &&
                    this.state.previousAward !== null && 
                    this.state.studentStatus !== null &&
                    this.state.category !== "" &&
                    this.state.nominationsLimitedBy !== null &&
                    (this.state.minimumGpaRequired === false || 
                        (this.state.minimumGpaRequired === true && 
                            this.state.gpaRequirement !== "" && this.state.gpaRequirementInvalid === false &&
                            this.state.gpaEligibilityWaiver !== null)) &&
                    this.state.awardDates !== null &&
                    this.state.awardDatesInvalid === false
                );
                break;
            case ACTION_VALUE_CREATE:
                validForm = (
                    this.state.name !== "" &&
                    this.state.nameInvalid === false && 
                    this.state.academicYear !== null &&
                    this.state.awardTerm !== null &&
                    this.state.studentStatus !== null && 
                    this.state.category !== "" &&
                    this.state.categoryInvalid === false &&
                    this.state.nominationsLimitedBy !== null &&
                    (this.state.minimumGpaRequired === false || 
                        (this.state.minimumGpaRequired === true && 
                            this.state.gpaRequirement !== "" && this.state.gpaRequirementInvalid === false &&
                            this.state.gpaEligibilityWaiver !== null)) &&
                    this.state.awardDates !== null &&
                    this.state.awardDatesInvalid === false
                );
                break;
            default:
                validForm = false;
        }
        this.setState({ validForm });
    }

    buildAward = () => {
        const { gpaEligibilityWaiver, minimumGpaRequired } = this.state;
        const award = {
            name: this.state.name,
            studentStatus: this.state.studentStatus.value,
            category: this.state.category,
            academicYear: this.state.academicYear.value,
            awardTerm: this.state.awardTerm.value,
            nominationInfo: {
                nominationsLimitedBy: this.state.nominationsLimitedBy.value,
                additionalQuestions: this.state.additionalQuestions
            },
            eligibility: {
                minimumGpaRequired,
                gpaRequirement: (minimumGpaRequired ? this.state.gpaRequirement : null),
                gpaEligibilityWaiver: (minimumGpaRequired ? gpaEligibilityWaiver.value : null),
                petitionDisclaimer: ((minimumGpaRequired && gpaEligibilityWaiver.value === GPA_ELIGIBILITY_WAIVER_PETITION) ? this.state.petitionDisclaimer : null)
            },
            requireDocumentUpload: this.state.requireDocumentUpload,
            requireReview: this.state.requireReview,
            combineAwardResults: this.state.combineAwardResults,
            createAwardLetter: this.state.createAwardLetter,
            sendAwardLetter: this.state.sendAwardLetter
        }
        forOwn(this.state.awardDates, (value, key) => {
            award[key] = moment(value).format(AWARD_DATE_FORMAT);
        });
        if(this.state.previousAward){
            award.copyFromAwardId = this.state.previousAward.value; //value should be previous award pk (id)
        }
        return award;
    }

    onFormSubmit = (event) => {
        event.preventDefault();
        this.props.createAward(this.buildAward());
    }

    renderPageContent = () => {
        if(this.props.awardsStatus === "loading") {
            return (<OSULoading text="Loading..." />);
        } else if(this.props.awardsStatus === "401") {
            Auth.signOut();
            redirectToLogOut();
        } else if(this.props.awardsStatus === "error") {
            return (<OSUError text="An error occurred while loading the page." ariaLabel="Reload the page" onClick={() => this.props.getPreviousAwards()} />);
        } else {
            const academicYearSelectStyles = { container: provided => ({ ...provided, maxWidth: "12rem" }) };
            const awardTermSelectStyles = { container: provided => ({ ...provided, maxWidth: "12rem" }) };
            const nominationsLimitedBySelectStyles = { container: provided => ({ ...provided, maxWidth: "16.5rem" }) };
            const gpaEligibilityWaiverSelectStyles = { container: provided => ({ ...provided, maxWidth: "16rem" }) };
            const creatingAward = (this.props.createAwardState === ACTION_STATE_LOADING);
            return (
                <div>
                    <div data-testid="alerts" ref={(el) => { this.alerts = el; }} tabIndex="-1">
                        <Alert data-testid="create-award-error-alert" color="danger" isOpen={this.props.createAwardState === ACTION_STATE_ERROR} toggle={this.props.resetCreateAwardState}>
                            <Subtitle2>Create Award Failure</Subtitle2>
                            <p className="mb-0">Failed to create the award. Please retry to see if it resolves the issue.</p>
                        </Alert>
                        <Alert data-testid="create-award-success-alert" color="success" isOpen={this.props.createAwardState === ACTION_STATE_SUCCESS}>
                            <Subtitle2>Create Award Success</Subtitle2>
                            <p>Successfully created the award.  Navigate to <Link data-testid="award-details-link" to={`/award-management/award-details/${window.btoa(this.props.createAwardKey)}`}>Award Details</Link> to review the award.</p>
                        </Alert>
                    </div>
                    {creatingAward &&
                        <OSULoading dataTestId="creating-award" text="Creating Award..." />
                    }
                    <Form id="createAwardForm" data-testid="create-award-form" noValidate onSubmit={this.onFormSubmit} className={creatingAward ? "d-none" : ""}>
                        <FormGroup data-testid="nameFormGroup">
                            <Label for="name" className={`${CLASSNAME_BODY1} required`}>Name:</Label>
                            <Input id="name" name="name" data-testid="name" type="text" aria-required="true" maxLength={AWARD_NAME_MAX_LENGTH}
                                style={{ maxWidth: "35rem" }} invalid={this.state.nameInvalid} aria-invalid={this.state.nameInvalid}
                                onBlur={e => this.onFormInputChange(e, this.validateName)} onChange={e => this.onFormInputChange(e, this.validateName)} />
                            <FormFeedback aria-live="polite">{FORM_FEEDBACK_SR_PREFIX}Name is required and can include only alphanumeric characters, dashes, spaces, and single quotes.</FormFeedback>
                        </FormGroup>
                        <FormGroup data-testid="academicYearFormGroup">
                            <Label for="academicYear" className={`${CLASSNAME_BODY1} required`}>Academic Year:</Label>
                            <Select id="academicYear" inputId="academicYearSelect" name="academicYear" aria-label="Academic Year"
                                options={this.state.academicYears} value={this.state.academicYear} styles={academicYearSelectStyles}
                                onChange={selectedOption => this.onFormSelectChange("academicYear", selectedOption)} />
                        </FormGroup>
                        <FormGroup data-testid="awardTermFormGroup">
                            <Label for="awardTerm" className={`${CLASSNAME_BODY1} required`}>Award Term:</Label>
                            <Select id="awardTerm" inputId="awardTermSelect" name="awardTerm" aria-label="Award Term" options={this.state.awardTerms} 
                                value={this.state.awardTerm} styles={awardTermSelectStyles} onChange={selectedOption => this.onFormSelectChange("awardTerm", selectedOption)} />
                        </FormGroup>
                        <FormGroup data-testid="actionFormGroup" tag="fieldset" aria-required="true">
                            <legend className={`${CLASSNAME_BODY1} col-form-label required`}>Select how to create this award:</legend>
                            <Col>
                                {this.state.actions.map(action => {
                                    return (
                                        <FormGroup check key={action.value}>
                                            <Label check>
                                                <Input name="action" data-testid={`action-${action.value}`} type="radio" value={action.value} 
                                                    onChange={this.onActionChange} />{' '}
                                                <Body2>{action.label}</Body2>
                                            </Label>
                                        </FormGroup>
                                    );
                                })}
                            </Col>
                        </FormGroup>
                        {this.state.action === ACTION_VALUE_COPY && this.renderCopyFields()}
                        {this.state.action === ACTION_VALUE_CREATE && this.renderCreateFields()}
                        {this.state.action !== null &&
                            <Fragment>
                                <h2 className={CLASSNAME_HEADING2}>Nomination Requirements</h2>
                                <FormGroup data-testid="nominationsLimitedByFormGroup">
                                    <Label for="nominationsLimitedBy" className={`${CLASSNAME_BODY1} required`}>Nominations Limited By:</Label>
                                    <Select id="nominationsLimitedBy" inputId="nominationsLimitedBySelect" name="nominationsLimitedBy" aria-label="Nominations Limited By"
                                        options={this.state.nominationsLimitedByOptions} value={this.state.nominationsLimitedBy} styles={nominationsLimitedBySelectStyles}
                                        onChange={selectedOption => this.onFormSelectChange("nominationsLimitedBy", selectedOption)} />
                                </FormGroup>
                                <fieldset data-testid="addAdditionalQuestionsFieldset" className="mb-3">
                                    <legend className={CLASSNAME_BODY1}>Add Additional Questions</legend>
                                    {keys(ADDITIONAL_QUESTIONS).map((key, index) => {
                                        return (
                                            <FormGroup key={`additionalQuestion${index}FormGroup`} data-testid={`additionalQuestion${index}FormGroup`} className="ml-3 mb-0">
                                                <Label for={`additionalQuestion${index}`}>{ADDITIONAL_QUESTIONS[key]}:
                                                    <Input id={`additionalQuestion${index}`} name={`additionalQuestion${index}`} type="checkbox" className={CLASSNAME_CHECKBOX}
                                                        checked={this.state.additionalQuestions.includes(key)}
                                                        onChange={e => {
                                                            let additionalQuestions;
                                                            if(e.target.checked) {
                                                                additionalQuestions = [...this.state.additionalQuestions, key];
                                                            } else {
                                                                additionalQuestions = without(this.state.additionalQuestions, key);
                                                            }
                                                            this.setState({ additionalQuestions });
                                                        }} />
                                                </Label>
                                            </FormGroup>
                                        );
                                    })}
                                </fieldset>
                                <FormGroup data-testid="minimumGpaRequiredFormGroup" className="mb-0">
                                    <Label for="minimumGpaRequired" className={CLASSNAME_BODY1}>Minimum GPA Required:
                                        <Input id="minimumGpaRequired" name="minimumGpaRequired" type="checkbox" checked={this.state.minimumGpaRequired}
                                            className={CLASSNAME_CHECKBOX} onChange={this.onMinimumGpaRequiredChange} />
                                    </Label>
                                </FormGroup>
                                {this.state.minimumGpaRequired === true &&
                                    <Fragment>
                                        <FormGroup data-testid="gpaRequirementFormGroup" className={`ml-3 ${this.state.minimumGpaRequired === true ? "" : " d-none"}`}>
                                            <Label for="gpaRequirement" className="d-inline-block mb-0 mr-2 required">GPA Requirement:</Label>
                                            <Input id="gpaRequirement" name="gpaRequirement" type="text" maxLength={GPA_MAX_LENGTH} aria-required="true"
                                                defaultValue={this.state.gpaRequirement} invalid={this.state.gpaRequirementInvalid} aria-invalid={this.state.gpaRequirementInvalid}
                                                style={{ width: "5.7rem" }} className="d-inline-block" onBlur={e => this.onFormInputChange(e, this.validateGpa)} />
                                            <FormFeedback aria-live="polite">{FORM_FEEDBACK_SR_PREFIX}GPA is required and must be a number between 0 and 4.</FormFeedback>
                                        </FormGroup>
                                        <FormGroup data-testid="gpaEligibilityWaiverFormGroup" className={`ml-3 ${this.state.minimumGpaRequired === true ? "" : " d-none"}`}>
                                            <Label for="gpaEligibilityWaiver" className={`${CLASSNAME_BODY1} required`}>GPA Eligibility Waiver:</Label>
                                            <Select id="gpaEligibilityWaiver" inputId="gpaEligibilityWaiverSelect" name="gpaEligibilityWaiver" aria-label="GPA Eligibility Waiver"
                                                options={this.state.gpaEligibilityWaiverOptions} value={this.state.gpaEligibilityWaiver} styles={gpaEligibilityWaiverSelectStyles}
                                                onChange={selectedOption => this.onFormSelectChange("gpaEligibilityWaiver", selectedOption)} />
                                        </FormGroup>
                                        {this.state.gpaEligibilityWaiver && this.state.gpaEligibilityWaiver.value === GPA_ELIGIBILITY_WAIVER_PETITION &&
                                            <FormGroup data-testid="petitionDisclaimerFormGroup" className="ml-3">
                                                <Label for="petitionDisclaimer" className={CLASSNAME_BODY1}>Petition Disclaimer (displayed when submitting):</Label>
                                                <Input id="petitionDisclaimer" name="petitionDisclaimer" type="textarea" rows="10" 
                                                    value={this.state.petitionDisclaimer} style={{ maxWidth: "22rem", resize: "vertical" }}
                                                    onChange={e => this.setState({ petitionDisclaimer: e.target.value })} />
                                            </FormGroup>
                                        }
                                    </Fragment>
                                }

                                <h2 className={CLASSNAME_HEADING2}>Review</h2>
                                <FormGroup data-testid="requireReviewFormGroup" className="mb-0">
                                    <Label for="requireReview">This award requires a review:
                                        <Input id="requireReview" name="requireReview" type="checkbox" checked={this.state.requireReview}
                                            onChange={e => this.setState({ requireReview: e.target.checked })} className={CLASSNAME_CHECKBOX} />
                                    </Label>
                                </FormGroup>
                                <FormGroup data-testid="requireDocumentUploadFormGroup" className="mb-0">
                                    <Label for="requireDocumentUpload">Require Document Upload for Review:
                                        <Input id="requireDocumentUpload" name="requireDocumentUpload" type="checkbox" checked={this.state.requireDocumentUpload}
                                            onChange={e => this.setState({ requireDocumentUpload: e.target.checked })} className={CLASSNAME_CHECKBOX} />
                                    </Label>
                                </FormGroup>


                                <h2 className={CLASSNAME_HEADING2}>Award Results Settings</h2>
                                <FormGroup data-testid="combineAwardResultsFormGroup" className="mb-0">
                                    <Label for="combineAwardResults">Combine Award Results:
                                        <span data-testid="combineAwardResultsInfoIconWrapper" aria-describedby="combineAwardResultsInfo" className="d-inline-block ml-2 mt-2">
                                            <Icon id="combineAwardResultsInfoIcon" type="info-circle" color="blue" />
                                            <UncontrolledTooltip placement="top" target="combineAwardResultsInfoIcon" innerClassName="date-info-tooltip">
                                                {this.state.info.combineAwardResults}
                                            </UncontrolledTooltip>
                                        </span>
                                        <span id="combineAwardResultsInfo" className="sr-only">{this.state.info.combineAwardResults}</span>
                                        <Input id="combineAwardResults" name="combineAwardResults" type="checkbox" checked={this.state.combineAwardResults}
                                            className={CLASSNAME_CHECKBOX} onChange={e => {
                                                const checked = e.target.checked;
                                                const state = { combineAwardResults: checked };
                                                if(checked === true) {
                                                    state.createAwardLetter = true;
                                                    state.sendAwardLetter = true;
                                                }
                                                this.setState(state)}
                                             } />
                                    </Label>
                                </FormGroup>
                                <FormGroup data-testid="createAwardLetterFormGroup" className="mb-0">
                                    <Label for="createAwardLetter">Create Award Letter:
                                        <span data-testid="createAwardLetterInfoIconWrapper" aria-describedby="createAwardLetterInfo" className="d-inline-block ml-2 mt-2">
                                            <Icon id="createAwardLetterInfoIcon" type="info-circle" color="blue" />
                                            <UncontrolledTooltip placement="top" target="createAwardLetterInfoIcon" innerClassName="date-info-tooltip">
                                                {this.state.info.createAwardLetter}
                                            </UncontrolledTooltip>
                                        </span>
                                        <span id="createAwardLetterInfo" className="sr-only">{this.state.info.createAwardLetter}</span>
                                        <Input id="createAwardLetter" name="createAwardLetter" type="checkbox" checked={this.state.createAwardLetter}
                                            onChange={e => this.setState({ createAwardLetter: e.target.checked })} className={CLASSNAME_CHECKBOX}
                                            disabled={this.state.combineAwardResults === true} />
                                    </Label>
                                </FormGroup>
                                <FormGroup data-testid="sendAwardLetterFormGroup" className="mb-0">
                                    <Label for="sendAwardLetter">Send Award Letter:
                                        <Input id="sendAwardLetter" name="sendAwardLetter" type="checkbox" checked={this.state.sendAwardLetter}
                                            onChange={e => this.setState({ sendAwardLetter: e.target.checked })} className={CLASSNAME_CHECKBOX}
                                            disabled={this.state.combineAwardResults === true} />
                                    </Label>
                                </FormGroup>

                                <h2 className={CLASSNAME_HEADING2}>Dates</h2>
                                <AwardDates previousDates={this.state.previousAwardDates} onValidate={this.onAwardDatesValidate}
                                    showReviewDates={this.state.requireReview} showAwardLetterDates={this.state.sendAwardLetter} />
                            </Fragment>
                        }
                        <span className="d-block mt-4">            
                            <OSUButton solid color="gray" ariaLabel="Cancel" path="/award-management/" className="mr-2">Cancel</OSUButton>
                            {!this.props.createAwardKey &&
                                <OSUButton solid color="blue" ariaLabel="Create Award" type="submit" disabled={!this.state.validForm}>Create</OSUButton>
                            }
                        </span>
                    </Form>
                </div>
            );
        }
    }

    renderCreateFields = () => {
        const studentStatusSelectStyles = { container: provided => ({ ...provided, maxWidth: "12rem" })};
        return (
            <Fragment>
                <FormGroup data-testid="studentStatusFormGroup">
                    <Label for="studentStatus" className={`${CLASSNAME_BODY1} required`}>Student Status:</Label>
                    <Select id="studentStatus" inputId="studentStatusSelect" name="studentStatus" aria-label="Student Status" options={this.state.studentStatusOptions} 
                        value={this.state.studentStatus} styles={studentStatusSelectStyles}
                        onChange={selectedOption => {
                            const updateStudentStatus = () => this.onFormSelectChange("studentStatus", selectedOption);
                            if(selectedOption.value === STUDENT_STATUS_NEWADMIT) {
                                this.setState({ combineAwardResults: true }, () => updateStudentStatus());
                            } else {
                                this.setState({ combineAwardResults: false }, () => updateStudentStatus());
                            }
                        }} />
                </FormGroup>
                <FormGroup data-testid="categoryFormGroup">
                    <Label for="category" className={`${CLASSNAME_BODY1} required`}>Category:</Label>
                    <Input id="category" name="category" data-testid="category" type="text" aria-required="true" style={{ maxWidth: "35rem" }} 
                        invalid={this.state.categoryInvalid} aria-invalid={this.state.categoryInvalid} onBlur={this.onFormInputChange} onChange={this.onFormInputChange} />
                    <FormFeedback aria-live="polite">{FORM_FEEDBACK_SR_PREFIX}Category is required.</FormFeedback>
                </FormGroup>
            </Fragment>
        );
    }

    renderCopyFields = () => {
        const previousAwardOptions = this.props.previousAwards.map(previousAward => {
            return { label: previousAward.name, value: previousAward.pk, data: previousAward };
        });
        const previousAwardSelectStyles = { container: provided => ({ ...provided, maxWidth: "35rem" }) };
        const warningText = "Prevents Award Copy";
        const Warning = (
            <span data-testid="missingDataWarning" title={warningText}>
                Missing <span aria-describedby="warningText"><Icon type="exclamation-circle" color="red" /></span>
                <span id="warningText" className="sr-only">{warningText}</span>
            </span>
        );
        return (
            <Fragment>
                <FormGroup data-testid="previousAwardFormGroup">
                    <Label for="previousAward" className={`${CLASSNAME_BODY1} required`}>Previous Award:</Label>
                    <Select id="previousAward" inputId="previousAwardSelect" name="previousAward" aria-label="Previous Award" options={previousAwardOptions} 
                        noOptionsMessage={() => { return "No Previous Awards" }} value={this.state.previousAward} styles={previousAwardSelectStyles}
                        onChange={this.onPreviousAwardsSelectChange} />
                </FormGroup>
                {this.state.previousAward &&
                    <Fragment>
                        <FormGroup data-testid="studentStatusFormGroup">
                            <Label className={`${CLASSNAME_BODY1} d-block`}>Student Status:</Label>
                            <Body2 dataTestId="studentStatus">{this.state.studentStatus ? this.state.studentStatus.label : Warning}</Body2>
                        </FormGroup>
                        <FormGroup data-testid="categoryFormGroup">
                            <Label className={`${CLASSNAME_BODY1} d-block`}>Category:</Label>
                            <Body2 dataTestId="category">{this.state.category ? this.state.category : Warning}</Body2>
                        </FormGroup>
                    </Fragment>
                }
            </Fragment>
        );
    }

    render() {
        return (
            <div data-testid="pageContent" ref={(el) => { this.pageContent = el; }} tabIndex="-1" aria-labelledby="pageHeader">
                <Card>
                    <CardTitle className="rounded-top mb-0">
                        <h1 id="pageHeader" data-testid="pageHeader" className="heading5 mt-2 ml-4">Create a New Award</h1>
                        <hr className="mt-2 mb-0" />
                    </CardTitle>
                    <CardBody className="px-4" style={{ minHeight: "37.9rem" }}>
                        {this.renderPageContent()}
                    </CardBody>
                </Card>
            </div>
        );
    }
}