import { API } from "aws-amplify";
import {
    ALL_AWARDS, ALL_AWARDS_ERROR, ALL_AWARDS_LOADING,
    AWARD_INFO, AWARD_INFO_STATUS, AWARD_NOMINEES, AWARD_NOMINEES_AWARDED, AWARD_NOMINEES_STATUS, AWARD_NOMINEES_SORT_KEY,
    CURRENT_AWARDS, SYSTEM_AWARD, SCORING_CRITERIA, TYPES_OF_GPA, CURRENT_AWARDS_STATUS, PREVIOUS_AWARDS_STATUS, SHOW_PREVIOUS_AWARDS
} from "../actions-index";
import { setApiStatus } from "../redux-helpers";
import { isEmpty, sortBy } from "lodash";
import {setUser} from "../App/actions";
import { systemHeaders } from '../util/util';

export function getAllAwards() {
    return dispatch => {
        // cleanup prior state
        dispatch({ type: ALL_AWARDS_ERROR, payload: false });
        dispatch({ type: ALL_AWARDS, payload: null });

        dispatch({ type: ALL_AWARDS_LOADING, payload: true });
        API.get(process.env.REACT_APP_GRAD_AWARD_API_NAME, "/awards")
            .then(response => {
                let allAwards = {
                    currentAwards: [],
                    previousAwards: []
                }
                let data = response.data
                let date = new Date()
                for (const item of data) {
                    if( (new Date(item.eDate)).getTime() > (date).getTime() ){
                        allAwards.currentAwards.push(item)
                    } else {
                        allAwards.previousAwards.push(item)
                    }
                }

                dispatch({ type: ALL_AWARDS, payload: allAwards });
            })
            .catch(error => {
                console.error("Get All Awards Error: ", error);
                dispatch({type: ALL_AWARDS_ERROR, payload: true});

            })
            .finally(() => {
                dispatch({ type: ALL_AWARDS_LOADING, payload: false });
            });
    };
}


export function getAllNomineesForAllAwards(sortKey, academicProgram = "") {
    console.log("Calling getAllNomineesForAllAwards")
    const setAllNomineesStatus = (status) => setApiStatus(AWARD_NOMINEES_STATUS, status);
    return (dispatch) => {
        dispatch(setAllNomineesStatus('loading'));
        API.get(process.env.REACT_APP_AWARD_NOMINEE_REVIEW_API_NAME, `/getAllNominees/?academicProgram=`+ academicProgram, systemHeaders())
            .then(response => {
                if (response.data) {
                    dispatch({ type: AWARD_NOMINEES, payload: response.data });
                } else if (response.status !== undefined && response.status === 401) {
                    console.error("401 Response in getAllNominees:", response.status);
                    dispatch(setAllNomineesStatus('401'));
                } else {
                    console.error("no Response data error getAllNomineesForAllAwards:", response);
                    dispatch(setAllNomineesStatus('error'));
                }
            })
            .catch(error => {
                if (error === 'No current user' || error === 'Request failed with status code 401' || (error.response !== undefined && error.response.status !== undefined && error.response.status === 401)) {
                    dispatch(setAllNomineesStatus('401'));
                } else {
                    dispatch(setAllNomineesStatus('error'));
                }
            })
            .finally(() => sortKey && dispatch(updateAllNomineesSort(sortKey)))
    }
}

export function getAllCurrentAwardedNomineesForAllAwards(sortKey, academicProgram = "") {
    const setAllNomineesStatus = (status) => setApiStatus(AWARD_NOMINEES_STATUS, status);
    return (dispatch) => {
        dispatch(setAllNomineesStatus('loading'));
        API.get(process.env.REACT_APP_AWARD_NOMINEE_REVIEW_API_NAME, `/getAllCurrentAwardedNominees/?academicProgram=${academicProgram}`, systemHeaders())
            .then(response => {
                if (response.data) {
                    dispatch({ type: AWARD_NOMINEES_AWARDED, payload: response.data });
                    dispatch(setAllNomineesStatus('success'));

                } else if (response.status !== undefined && response.status === 401) {
                    console.error("401 Response in getAllNominees:", response.status);
                    dispatch(setAllNomineesStatus('401'));
                } else {
                    console.error("no Response data error getAllCurrentAwardedNomineesForAllAwards:", response);
                    dispatch(setAllNomineesStatus('error'));
                }
            })
            .catch(error => {
                if (error === 'No current user' || error === 'Request failed with status code 401' || (error.response !== undefined && error.response.status !== undefined && error.response.status === 401)) {
                    dispatch(setAllNomineesStatus('401'));
                } else {
                    dispatch(setAllNomineesStatus('error'));
                }
            })
            .finally(() => sortKey && dispatch(updateAllNomineesSort(sortKey)))
    }
}

export function getCurrentAwards(retryCount = 0, source = "any") {
    const setCurrentAwardStatus = (status) => setApiStatus(CURRENT_AWARDS_STATUS, status);
    return (dispatch) => {
        dispatch(setCurrentAwardStatus('loading'));
        API.get(process.env.REACT_APP_GRAD_AWARD_API_NAME, "/getCurrentAwards?source=" + source )
            .then(response => {
                if (response.data) {
                    const awards = (response.data.length > 0 ? sortBy(response.data, (award => (award.name.toUpperCase()))) : response.data);
                    dispatch(updateAwards(awards));
                    dispatch(setCurrentAwardStatus('success'));
                } else if (response.status!==undefined && response.status===401) {
                    dispatch(setCurrentAwardStatus('401'));
                } else {
                    dispatch(setCurrentAwardStatus('error'));
                }
            })
            .catch(error => {
                console.error("Error in getCurrentAwards:",error);
                if (error === 'No current user'|| error==='Request failed with status code 401'|| (error.response!==undefined && error.response.status!==undefined && error.response.status===401)) {
                    dispatch(setCurrentAwardStatus('401'));
                    setUser();
                    if(retryCount > 0){
                        getCurrentAwards(retryCount - 1);
                    }
                } else {
                    dispatch(setCurrentAwardStatus('error'));
                }
            });
    }
}

export function getPreviousAwards() {
    const setPreviousAwardStatus = (status) => setApiStatus(PREVIOUS_AWARDS_STATUS, status);
    return (dispatch) => {
        dispatch(setPreviousAwardStatus('loading'));
        API.get(process.env.REACT_APP_GRAD_AWARD_API_NAME, "/getPreviousAwards" )
            .then(response => {
                if (response.data) {
                    const awards = (response.data.length > 0 ? sortBy(response.data, (award => (award.name.toUpperCase()))) : response.data);
                    dispatch(updatePreviousAwards(awards));
                    dispatch(setPreviousAwardStatus('success'));
                } else if (response.status!==undefined && response.status===401) {
                    dispatch(setPreviousAwardStatus('401'));
                } else {
                    dispatch(setPreviousAwardStatus('error'));
                }
            })
            .catch(error => {
                console.error("Error in getPreviousAwards:",error);
                if (error === 'No current user'|| error==='Request failed with status code 401'|| (error.response!==undefined && error.response.status!==undefined && error.response.status===401)) {
                    // dispatch(setPreviousAwardStatus('401'));
                    setUser();
                } else {
                    dispatch(setPreviousAwardStatus('error'));
                }
            });
    }
}

function updatePreviousAwards(awards) {
    return {
        type: "PREVIOUS_AWARDS",
        payload: awards
    }
}

export function updateAllNomineesSort(sortKey) {
    return {
        type: AWARD_NOMINEES_SORT_KEY,
        payload: sortKey
    }
}

export function changePreviousAwardsCheckbox(a) {
    return {
        type: SHOW_PREVIOUS_AWARDS,
        payload: a
    }
}

function updateAwards(awards) {
    return {
        type: CURRENT_AWARDS,
        payload: awards
    }
}

function updateSystemAward(award) {
    return {
        type: SYSTEM_AWARD,
        payload: award
    }
}

function updateScoringCriteria(criteria) {
    return {
        type: SCORING_CRITERIA,
        payload: criteria
    }
}

function updateTypeOfGPA(types) {
    return {
        type: TYPES_OF_GPA,
        payload: types
    }
}

export function setSystemAwardValues(award) {
    return (dispatch) => {
        let updateSystemAwardValues = (detailedAward) => {
            const { nominationInfo, reviewInfo } = detailedAward; 
            nominationInfo && nominationInfo.typeOfGPA && dispatch(updateTypeOfGPA(nominationInfo.typeOfGPA));
            reviewInfo && reviewInfo.rubric && reviewInfo.rubric.scores && dispatch(updateScoringCriteria(reviewInfo.rubric.scores));
            dispatch(updateSystemAward(detailedAward))      
        }

        // Check to see if additional informtion is needed.
        if(( !award.reviewInfo || !award.nominationInfo)  && Object.entries(award).length !== 0){
            return API.get(process.env.REACT_APP_GRAD_AWARD_API_NAME, `/award/${window.btoa(award.pk)}`)
            .then(response => {
                let detailedAward = response.data;
                updateSystemAwardValues(detailedAward)   
            })
        } else {
            updateSystemAwardValues(award)
        }
    }
}

export function getAwardInfo(awardKey, disableEncoding = false) {
    return dispatch => {
        dispatch({ type: AWARD_INFO_STATUS, payload: "loading" });
        API.get(process.env.REACT_APP_GRAD_AWARD_API_NAME, `/award/${disableEncoding ? awardKey : window.btoa(awardKey)}`)
            .then(response => {
                if(response.status === 401) {
                    dispatch({ type: AWARD_INFO_STATUS, payload: "401" });
                } else if(!isEmpty(response.data)) {
                    dispatch({ type: AWARD_INFO, payload: response.data });
                    dispatch({ type: AWARD_INFO_STATUS, payload: "success" });
                } else {
                    console.error("Get Award Info Error: Missing response data");
                    dispatch({ type: AWARD_INFO_STATUS, payload: "error" });
                }
            })
            .catch(error => {
                console.error("Get Award Info Error: ", error);
                const status = (error.response && error.response.status) ? error.response.status : null;
                if(["No current user", "Request failed with status code 401"].includes(error) || status === 401) {
                    dispatch({ type: AWARD_INFO_STATUS, payload: "401" });
                } else {
                    dispatch({ type: AWARD_INFO_STATUS, payload: "error" });
                }
            });
    }
}