import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import App from '../components/';
import { clearUser, setUser, setA11yMessage } from '../actions';
import { getCurrentAwards } from '../../Common/actions'

const mapStateToProps = (state, ownProps) => {
    return {
        user: state.user,
        userIsLoading: state.userIsLoading,
        currentAwards: state.currentAwards.list,
        previousAwards: state.currentAwards.prevList,
        currentAwardsStatus: state.currentAwards.status,
        allAwards: typeof state.currentAwards.list === "object" ? state.currentAwards.list.concat(state.currentAwards.prevList) : [],
        systemAward: state.systemAward,
        breadcrumbTrail: state.breadcrumbTrail,
        allowedRolesAll: getAllRoles(state.nominatorRoles, state.user.applicationRoles ? state.user.applicationRoles.split(",") : []),
        allowedRolesReviewer: state.user.applicationRoles ? state.user.applicationRoles.split(",").filter(role => !role.includes("NOMINATOR")) : [],
        a11yMessage: state.a11yMessage,
        isNominator: (state.nominatorRoles.length > 0)
    };
};

const getAllRoles = (nominatorRoles = [], currentAwards = []) => {
    const allowedRolesAll = process.env.REACT_APP_ALLOWED_ROLES_ALL.split(",")
    const allowedRoles = [ ...allowedRolesAll, ...nominatorRoles, ...currentAwards ];
    return allowedRoles.map(o => o.toUpperCase())
}

const mapDispatchToProps = (dispatch) => {
    return {
        clearUser: () => dispatch(clearUser()),
        setUser: () => dispatch(setUser()),
        getCurrentAwards: () => dispatch(getCurrentAwards()),
        setA11yMessage: (a11yMessage) => dispatch(setA11yMessage(a11yMessage))
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));

