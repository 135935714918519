import {API, Auth} from "aws-amplify";
import {
  GRADUATE_FACULTY_BY_EMPLID_DATA,
  GRADUATE_FACULTY_BY_EMPLID_ERROR,
  GRADUATE_FACULTY_BY_EMPLID_PROCESSING,
  GRADUATE_FACULTY_SEARCH_ERROR,
  GRADUATE_FACULTY_SEARCH_PROCESSING,
  GRADUATE_FACULTY_SEARCH_QUERY,
  GRADUATE_FACULTY_SEARCH_RESULTS,
  SAVE_REVIEWER_ERROR,
  SAVE_REVIEWER_INCOMPLETE,
  SAVE_REVIEWER_PROCESSING,
  SAVE_REVIEWER_SUCCESS,
  INVITE_REVIEWER_TO_AWARD_DATE,
  INVITE_REVIEWER_TO_AWARD_ERROR,
  INVITE_REVIEWER_TO_AWARD_PROCESSING,
  INVITE_REVIEWER_TO_AWARD_SUCCESS,
  SEARCH_ALL_REVIEWERS,
  CHECKBOX_STATUS,
  ALL_REVIEWERS,
  ALL_REVIEWERS_ERROR
} from "../actions-index";
import {redirectToLogOut} from "../util/util";

export function searchReviewers(query) {
  return {
    type: SEARCH_ALL_REVIEWERS,
    payload: query
  };
}

export function toggleShowInactive(showInactive) {
  return {
    type: CHECKBOX_STATUS,
    payload: showInactive
  };
}

export function filterOnDropdown(filterOnCollegeDropdown, filterType) {
  return {
    type: filterType,
    payload: filterOnCollegeDropdown
  };
}

export function retrieveAllReviewers() {

  return dispatch => {
       dispatch({ type: ALL_REVIEWERS_ERROR, payload: false });
       dispatch({ type: ALL_REVIEWERS, payload: null });
    API.get(
      process.env.REACT_APP_AWARD_REVIEWER_API_NAME,
      `/grad/award/reviewers`
    )
      .then(response => {
        dispatch({ type: ALL_REVIEWERS, payload: response });
      })
      .catch(error => {
        if (error === 'No current user' || error==='Request failed with status code 401'|| (error.response!==undefined && error.response.status!==undefined && error.response.status===401)) {
          Auth.signOut() && redirectToLogOut();
        } else {
          dispatch({type: ALL_REVIEWERS_ERROR, payload: error});
        }
      })
      .finally(() => {
      });
  }
}

export function searchGraduateFaculty(query) {
  return dispatch => {
    dispatch({ type: GRADUATE_FACULTY_SEARCH_QUERY, payload: query });

    // clean-up prior state
    dispatch({ type: GRADUATE_FACULTY_SEARCH_ERROR, payload: false });
    dispatch({ type: GRADUATE_FACULTY_SEARCH_RESULTS, payload: [] });

    dispatch({ type: GRADUATE_FACULTY_SEARCH_PROCESSING, payload: true });
    API.get(process.env.REACT_APP_AWARD_REVIEWER_API_NAME, `/grad/faculty/search/${query}`)
    .then(response => {
      dispatch({ type: GRADUATE_FACULTY_SEARCH_RESULTS, payload: response });
    })
    .catch(error => {
      console.error("Search Graduate Faculty Error: ", error);
      if (error === 'No current user'|| error==='Request failed with status code 401'|| (error.response!==undefined && error.response.status!==undefined && error.response.status===401)) {
        Auth.signOut() && redirectToLogOut();
      } else {
        dispatch({type: GRADUATE_FACULTY_SEARCH_ERROR, payload: true});
      }
    })
    .finally(() => {
      dispatch({ type: GRADUATE_FACULTY_SEARCH_PROCESSING, payload: false });
    });
  };
}

export function resetSearchGraduateFacultyError() {
  return dispatch => {
    dispatch({ type: GRADUATE_FACULTY_SEARCH_ERROR, payload: false });
  };
}

export function findGraduateFacultyByEmplid(emplid) {
  return dispatch => {
    // clean-up prior state
    dispatch({ type: GRADUATE_FACULTY_BY_EMPLID_ERROR, payload: false });
    dispatch({ type: GRADUATE_FACULTY_BY_EMPLID_DATA, payload: null });

    dispatch({ type: GRADUATE_FACULTY_BY_EMPLID_PROCESSING, payload: true });
    API.get(process.env.REACT_APP_AWARD_REVIEWER_API_NAME, `/grad/faculty/${emplid}`)
    .then(response => {
      dispatch({ type: GRADUATE_FACULTY_BY_EMPLID_DATA, payload: response });
    })
    .catch(error => {
      console.error("Find Graduate Faculty By Emplid Error: ", error);
      if (error === 'No current user'|| error==='Request failed with status code 401'|| (error.response!==undefined && error.response.status!==undefined && error.response.status===401)) {
        Auth.signOut() && redirectToLogOut();
      } else {
        dispatch({type: GRADUATE_FACULTY_BY_EMPLID_ERROR, payload: true});
      }
    })
    .finally(() => {
      dispatch({ type: GRADUATE_FACULTY_BY_EMPLID_PROCESSING, payload: false });
    });
  };
}

export function saveReviewer(reviewer) {
  return dispatch => {
    // clean-up prior state
    dispatch({ type: SAVE_REVIEWER_ERROR, payload: false });
    dispatch({ type: SAVE_REVIEWER_INCOMPLETE, payload: false });
    dispatch({ type: SAVE_REVIEWER_SUCCESS, payload: false });

    dispatch({ type: SAVE_REVIEWER_PROCESSING, payload: true });
    API.put(process.env.REACT_APP_AWARD_REVIEWER_API_NAME, "/grad/award/reviewer", { body: reviewer })
    .then(response => {
      if(response.message === "Error" || response.unprocessedItems.length > 0) {
        // not all reviewer information was updated
        dispatch({ type: SAVE_REVIEWER_INCOMPLETE, payload: true });
      } else {
        dispatch({ type: SAVE_REVIEWER_SUCCESS, payload: true });
      }
    })
    .catch(error => {
      console.error("Save Reviewer Error: ", error);
      if (error === 'No current user'|| error==='Request failed with status code 401'|| (error.response!==undefined && error.response.status!==undefined && error.response.status===401)) {
        Auth.signOut() && redirectToLogOut();
      } else {
        dispatch({type: SAVE_REVIEWER_ERROR, payload: true});
      }
    })
    .finally(() => {
      dispatch({ type: SAVE_REVIEWER_PROCESSING, payload: false });
    });
  };
}

export function resetSaveReviewerState() {
  return dispatch => {
    dispatch({ type: SAVE_REVIEWER_ERROR, payload: false });
    dispatch({ type: SAVE_REVIEWER_INCOMPLETE, payload: false });
    dispatch({ type: SAVE_REVIEWER_SUCCESS, payload: false });
  };
}

export function inviteReviewerToAward(emplid, awardKey, toEmailAddress, subject, body) {
  return dispatch => {
    // clean-up prior state
    dispatch({ type: INVITE_REVIEWER_TO_AWARD_ERROR, payload: false });
    dispatch({ type: INVITE_REVIEWER_TO_AWARD_SUCCESS, payload: false });
    dispatch({ type: INVITE_REVIEWER_TO_AWARD_DATE, payload: null });

    dispatch({ type: INVITE_REVIEWER_TO_AWARD_PROCESSING, payload: true });
    API.post(process.env.REACT_APP_AWARD_REVIEWER_API_NAME, `/grad/award/${window.btoa(awardKey)}/reviewer/${emplid}/invite`, { body: { toEmailAddress, subject, body } })
    .then((response) => {
      if(response.inviteDate) { // capture the new invite date if the backend was able to update it 
        dispatch({ type: INVITE_REVIEWER_TO_AWARD_DATE, payload: response.inviteDate });
      }
      dispatch({ type: INVITE_REVIEWER_TO_AWARD_SUCCESS, payload: true });
    })
    .catch(error => {
      console.error("Invite Reviewer To Award Error: ", error);
      if (error === 'No current user'|| error==='Request failed with status code 401'|| (error.response!==undefined && error.response.status!==undefined && error.response.status===401)) {
        Auth.signOut() && redirectToLogOut();
      } else {
        dispatch({type: INVITE_REVIEWER_TO_AWARD_ERROR, payload: true});
      }
    })
    .finally(() => {
      dispatch({ type: INVITE_REVIEWER_TO_AWARD_PROCESSING, payload: false });
    });
  };
}

export function resetInviteReviewerToAwardState() {
  return dispatch => {
    dispatch({ type: INVITE_REVIEWER_TO_AWARD_ERROR, payload: false });
    dispatch({ type: INVITE_REVIEWER_TO_AWARD_SUCCESS, payload: false });
    dispatch({ type: INVITE_REVIEWER_TO_AWARD_DATE, payload: null });
  };
}