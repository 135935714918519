import React from 'react'
import PropTypes from 'prop-types'
import { Caption, Body2 } from 'osu-react-components'

class NomineeReviewInformation extends React.Component {
    render() {
        const { nomineeReview, className, gpaTypes, fileArray, showfileArray } = this.props;
        const { advisorName, nominatedFor, gpaWaiver, nomineeId ,shortPlanDescription,applicationNumber} = nomineeReview;
        const convertYN = (str = '') => str === 'Y' ? 'Yes' : str === 'N' ? 'No' : 'Not Applicable';
        let pcdFlag = convertYN(nomineeReview.pcdFlag);
        if(pcdFlag === "Not Applicable") pcdFlag = null;

        return <div className={className}>
            <section className="flex-fill pb-2 pb-md-0">
                <Caption color="gray">Nominee</Caption>
                <Body2 className="d-flex flex-column">
                    <span> Nominee ID: {nomineeId}</span>
                    {applicationNumber && <span> Application #: {applicationNumber}</span>}
                    <span>Nomination(s): {nominatedFor && typeof nominatedFor === 'string' ? nominatedFor : Array.isArray(nominatedFor) ? nominatedFor.map((nom, index) => ` ${nom}${index !== nominatedFor.length - 1 ? ', ' : ''}`) : "No Roles Found"}</span>
                    {gpaWaiver && <span>GPA Waiver: {convertYN(gpaWaiver)}</span>}
                </Body2>
            </section>
            <section className="flex-fill pb-2 pb-md-0">
                <Caption color="gray">Academic</Caption>
                <Body2 className="d-flex flex-column">
                    <span>Program: {shortPlanDescription}</span>
                    {Array.isArray(gpaTypes) && gpaTypes.map((gpa, index) =>
                        <span key={`gpa[${index}]`}>{gpa.description}: {gpa.field && nomineeReview.hasOwnProperty(gpa.field) ? nomineeReview[gpa.field] : '-'}</span>
                    )}
                    <span>Dissertation Advisor: {advisorName}</span>
                    {pcdFlag && <span>Post Candidacy (PCD): {pcdFlag}</span>}
                </Body2>
            </section>
            {showfileArray && <section className="flex-fill pb-2 pb-md-0">
                <Caption color="gray">Documents</Caption>
                 {Array.isArray(fileArray) && fileArray.length > 0 ? fileArray : <Body2>No files available</Body2>}
            </section>}
        </div>
    }
}

NomineeReviewInformation.defaultProps = {
    showfileArray: true
};

NomineeReviewInformation.propTypes = {
    nomineeReview: PropTypes.shape({
        nominations: PropTypes.array,
        gpaWaiver: PropTypes.bool,
        advisorName: PropTypes.string,
        pcdFlag: PropTypes.oneOf(["Y", "N"])
    }),
    className: PropTypes.string, 
    gpaTypes: PropTypes.array,
    fileArray: PropTypes.array,
    showfileArray: PropTypes.bool
};

export default NomineeReviewInformation