import React, { useEffect, useState, Fragment } from "react";
import PropTypes from 'prop-types'
import { Body1, Body2, Icon, Table, OSUButton } from 'osu-react-components';
import { InputGroup, Button, Input, Label, CustomInput, InputGroupButtonDropdown, UncontrolledAlert } from "reactstrap";
import { AWARD_OPTIONS, EMPLID_SEARCH, ZEROTH_ELEMENT_COUNT, ALL_PROGRAMS } from "../constants";
import { CURRENT_AWARDS, GRADUATE_PROGRAMS, TERMS } from "../../actions-index";
import { GPA_ELIGIBILITY_WAIVER_LIMITED, GPA_ELIGIBILITY_WAIVER_NONE, GPA_ELIGIBILITY_WAIVER_PETITION, NOMINATIONS_LIMITED_BY_COLLEGE, NOMINATIONS_LIMITED_BY_PROGRAM } from "../../AwardManagement/constants";
import { ACTION_STATE_LOADING, ACTION_STATE_SUCCESS, STUDENT_STATUS_CURRENT } from "../../util/constants";
import { isEmpty } from "lodash";
import { OSULoading } from 'osu-react-components'



const AddStudent = (props = {}) => {
    const { isAdmin, isNominator, clearAddStudentSearchData, submitNominations, submitNominationsResults, submitAddStudentSearchData, addStudentSearchData, getDropDownMetadata, awardEligibilityMap, awardInfo, dropDownMetadata, getAwardInfo, studentStatus, graduateProgram, nominatorRole, nominatedBy } = props;
    const { status:awardStatus = "", data:award = {} } = awardInfo;
    let [state, setState] = useState({[AWARD_OPTIONS]: "", [CURRENT_AWARDS]: "", [GRADUATE_PROGRAMS]: "", [TERMS]: undefined, [EMPLID_SEARCH]: "", studentSelections: [], awardEligibility: null, capsAndWaivers: null });
    useEffect(() => {
      getDropDownMetadata()
      if(isNominator) {
        setState((existing) => ({ ...existing, [GRADUATE_PROGRAMS]: graduateProgram }))
      }
      return function cleanup() {
        setState( (existing) => ({ ...existing, studentSelections: []}) )
        clearAddStudentSearchData()
      };
    }, [])

    useEffect(() => {
      console.log("GraduatePrograms state updated", state[GRADUATE_PROGRAMS]);
    }, [state[GRADUATE_PROGRAMS]])

    useEffect(() => {
      if(nominatorRole && !isEmpty(state[CURRENT_AWARDS]) && awardStatus === ACTION_STATE_SUCCESS) {
        let capsAndWaivers = null;
        let capsLabel = "Caps";
        const { capsAndWaivers: allCapsAndWaivers = {}, eligibility = {}, nominationInfo = {} } = award;
        const showWaivers = (eligibility.gpaEligibilityWaiver === GPA_ELIGIBILITY_WAIVER_LIMITED);
        
        const nominatorRoleTokens = nominatorRole.split("-"); // `${ROLE}-${PROGRAM}-${COLLEGE}`;
        let capsAndWaiversKey = null;
        if(nominationInfo.nominationsLimitedBy === NOMINATIONS_LIMITED_BY_PROGRAM) capsAndWaiversKey = nominatorRoleTokens[1];
        if(nominationInfo.nominationsLimitedBy === NOMINATIONS_LIMITED_BY_COLLEGE) {
          capsLabel = "Allocations";
          capsAndWaiversKey = nominatorRoleTokens[2];
        }

        if(capsAndWaiversKey) {
          capsAndWaivers = (allCapsAndWaivers[capsAndWaiversKey] || null);
          while(capsAndWaivers && capsAndWaivers.combine !== null) {
            capsAndWaivers = (allCapsAndWaivers[capsAndWaivers.combine] || null);
          }
          if(capsAndWaivers) capsAndWaivers = {
            caps: 0, waivers: 0, usedCaps: 0, usedWaivers: 0, // set defaults to ensure all fields are present
            ...capsAndWaivers, capsLabel, showWaivers
          };
        }
        
        setState((existing) => ({ ...existing, capsAndWaivers }));
      } else if(state.capsAndWaivers !== null) {
        setState((existing) => ({ ...existing, capsAndWaivers: null }));
      }
    }, [awardStatus, nominatorRole]);

    const onSelectChange = (action) => {
      let actionFunction;
      switch (action) {
        case "onAwardChange":
          actionFunction = onAwardChange;
          break;
        case "onTermChange":
        case "onProgramChange":
          actionFunction = onSearchChange;
          break;  
        case "onAwardOptionChange":
          actionFunction = handleOnChange;
          break;
        default:
          actionFunction = (key, event) => { console.log( action, key, event.target.value ) }
          break;
      }
      return actionFunction;
    }

    const onAwardChange = (key, event, resetList = []) => { 
      let selectedOption = event.target.value;
      getAwardInfo(selectedOption);
      handleOnChange(key, event, resetList);
      const awardEligibility = (awardEligibilityMap[selectedOption] || null);
      setState((existing) => ({ ...existing, awardEligibility }));
    }

    const onSearchChange = (key, event, resetList = []) => { 
      let selectedOption = event.target.value;
      const searchParams = createSearchParams(key, selectedOption);
      if(searchParams.term && searchParams.program){
        searchParams.program = searchParams.program === ALL_PROGRAMS ? "" : searchParams.program
        console.log("serachParams", JSON.parse(JSON.stringify(searchParams)));
        setState( (existing) => ({ ...existing, studentSelections: []}) )
        submitAddStudentSearchData(searchParams, isAdmin);
      }
      handleOnChange(key, event, resetList);
    }

    const handleOnChange = (key, event, resetList = []) => {
      let selectedOption = event.target.value;
      let resetObject = resetList.reduce((obj, attribute) => ({...obj, [attribute]: ""}), {})
      setState( (existing) => ({ ...existing, [key]: selectedOption, ...resetObject}) )
    }

    const handleOnSearch = (event) => {
      if(event.type === "click" || event.key === "Enter") {
        event.preventDefault();
        //TODO:Implement search call from container, got to check the state to make sure a search isn't being run
        const searchParams = createSearchParams(EMPLID_SEARCH, state[EMPLID_SEARCH]);
        console.log("serachParams", JSON.parse(JSON.stringify(searchParams)));
        setState( (existing) => ({ ...existing, studentSelections: []}) )
        submitAddStudentSearchData(searchParams, isAdmin);
      }
    }

    const handleOnChangeStudentSelection = (data) => (event) => {
      console.log("handleOnChangeStudentSelection", data);
      const { value, checked } =  event.target;
      let students = [...state.studentSelections]
      if(checked){
        students.push(data)
      } else {
        let itemLocation = students.findIndex( (selection) => `${selection.emplid}:${selection.plan}` === value )
        ~itemLocation && students.splice(itemLocation, 1) //only remove if the item is found
      }
      setState( (existing) => ({ ...existing, studentSelections: students}) );
    }

    const handleOnClickSubmitNominations = (event) => {
      event.preventDefault()
      let studentSelections = state.studentSelections.map(selection => Object.assign({}, selection))
      for (const selection of studentSelections) {
          selection.nominatedByEmplid = nominatedBy["nominatedByEmplid"];
          selection.nominatedByNameN = nominatedBy["nominatedByNameN"];
          selection.nominatedDate = nominatedBy["nominatedDate"];        
      }
      let submissionParams = { studentStatus, awardOption: state[AWARD_OPTIONS], award: state[CURRENT_AWARDS], studentSelections }
      submitNominations(submissionParams);
      console.log("Sumbmission", JSON.parse(JSON.stringify(submissionParams)))
      let searchReset = {studentSelections: [], [TERMS]: "", [EMPLID_SEARCH]: ""}
      if(isAdmin){
        searchReset[GRADUATE_PROGRAMS] = ""
      }
      setState( (existing) => ({ ...existing, ...searchReset}) );
      
      clearAddStudentSearchData()
    }

    const createSearchParams = (key, value) => {
      const { [GRADUATE_PROGRAMS] : program, [TERMS] : term, [EMPLID_SEARCH] : emplid } = state;
      const { studentStatus } = props;
      let keyVariable = ""
      if(key === GRADUATE_PROGRAMS) {
        keyVariable = "program"
      } else if( key === TERMS) {
        keyVariable = "term"
      } else if ( key === EMPLID_SEARCH){
        keyVariable = "emplid"
      } else {
        throw new Error("Create search params invalid key:", key)
      }
      return { emplid, term, program, studentStatus, [keyVariable]: value };
    }
    
    const buildAddStudentSearchTableData = (searchData = []) => {
      return searchData.map( (data) => {
        let {name, emplid, plan} = data
        return {
          checkbox: <CustomInput onChange={handleOnChangeStudentSelection(data)} required className="text-center" aria-label={`Select student ${name} for award`} type="checkbox" name="studentSelection" id={`${emplid}:${plan}`} value={`${emplid}:${plan}`} />,
          ...data
        }
      })
    }

    const buildAddStudentSearchTableDataKeys = (studentType) => {
      const isCurrent = studentType === STUDENT_STATUS_CURRENT;
      const {standardWidth, checkboxWidth} = isCurrent ? {standardWidth: 32, checkboxWidth: 4}: {standardWidth: 19, checkboxWidth: 5}

      let tableDataKeys = [
        {
          key: 'checkbox',
          label: ' ',
          width: checkboxWidth,
          className: 'text-center'
        },
        {
          key: 'emplid',
          label: 'EMPLID',
          width: standardWidth,
          className: 'align-self-end text-center'
        },
        {
          key: 'plan',
          label: 'Academic Program',
          width: standardWidth,
          className: 'align-self-end text-center'
        }
      ];

      if(isCurrent){
        let currentGPAKey = {
          key: 'cumGpa',
          label: 'CC GPA',
          width: standardWidth,
          className: 'align-self-end text-center'
        }
        tableDataKeys.push(currentGPAKey)
      } else {
        let name = {
          key: 'name',
          label: 'Name',
          width: standardWidth,
          className: 'align-self-end text-center'
        }
        tableDataKeys.splice(1, 0, name);
        let newAdmitGPAKey = {
          key: 'externalUgrdGpa',
          label: 'Under Graduate GPA',
          width: standardWidth,
          className: 'align-self-end text-center'
        }
        tableDataKeys.push(newAdmitGPAKey)

        let applicationNumberKey = {
          key: 'applicationNbr',
          label: 'Application #',
          width: standardWidth,
          className: 'align-self-end text-center'
        }
        tableDataKeys.splice(2, 0, applicationNumberKey);
      }   

      return tableDataKeys;
    }

    const createAlerts = ({ processedNominations = [], unprocessedNominations = [] }) => {
      const alerts = []
      const createAlert = (key, color, text, items) => (
        <div key={key}>
          <UncontrolledAlert color={color} fade={false}>
            {text + ": " + items}
          </UncontrolledAlert>
        </div>
      )
      if(!isEmpty(processedNominations)){
        alerts.push(createAlert("processed", "success", "The following nominations were accepted", processedNominations))
      }
      if(!isEmpty(unprocessedNominations)){
        alerts.push(createAlert("unprocessed", "warning", "The following nominations were rejected, please check the nominations for the award", unprocessedNominations))
      }
      return alerts;
    }

    const createDropDown = (metadata, index) => {
      const {key, label, required, action, resetList, options, defaultOptionIndex} = metadata;
      if(state[key] === undefined && defaultOptionIndex && options.length >= defaultOptionIndex + ZEROTH_ELEMENT_COUNT){
        setState( (existing) => ({ ...existing, [key]: options[defaultOptionIndex].value}) )
      }
      return (
        <Fragment key={key + "" + index}>
          <Label htmlFor={key} className="d-inline-block mt-1">
            <Body1 className={`mr-1 ${required && "required"}`}> {typeof label === "function"? label(studentStatus): label} </Body1>
          </Label>
          <Input id={key}
            name={key} 
            type="select"
            aria-label={`List of ${key} available`}                           
            onChange={(event) => onSelectChange(action)(key, event, resetList)}
            value={state[key]}
          >
            <option value="">Select...</option>
            {options.map( (option, index) => {
              return <option key={`option-${key + index}`} value={option.value}>{option.label}</option>
            })}
          </Input>       
        </Fragment>              
      )
    }
    
    const NOMINATE_START_INDEX = 0
    const NOMINATE_END_INDEX = 2
    
    const SEARCH_START_INDEX = 2
    const SEARCH_END_INDEX = isAdmin ? 4 : 3;
    return (
    <div>
        <h1>{graduateProgram && `${graduateProgram} - `}Add {studentStatus === STUDENT_STATUS_CURRENT ? "Current": "New Admit" } Student - {isAdmin ? "ADMIN" : props.isNominator ? "Nominator" : "YOU ARE NOT SUPPOSED TO BE HERE!"}</h1>
        {createAlerts(submitNominationsResults.data || {})}
        <form>
          <h2 className="mt-4">Nominate For:</h2>
          {dropDownMetadata.slice(NOMINATE_START_INDEX, NOMINATE_END_INDEX).map(createDropDown)}

          {state.capsAndWaivers &&
            <Fragment>
              <Body1 className="mt-2 mb-1">{state.capsAndWaivers.capsLabel} and Waivers</Body1>
              <Body2 className="ml-1">{state.capsAndWaivers.caps} {state.capsAndWaivers.capsLabel}, {state.capsAndWaivers.usedCaps} Used</Body2>
              {state.capsAndWaivers.showWaivers &&
                <Body2 className="ml-1">{state.capsAndWaivers.waivers} Eligibility Waivers, {state.capsAndWaivers.usedWaivers} Used</Body2>
              }
            </Fragment>
          }

          {state.awardEligibility && 
            <Fragment>
              <Body1 className="mt-2 mb-1">Award Eligibility</Body1>
              <Body2 className="ml-1">GPA Requirement: {state.awardEligibility.minimumGpaRequired === true ? state.awardEligibility.gpaRequirement : "None"}</Body2>
              {(state.awardEligibility.minimumGpaRequired === true && state.awardEligibility.gpaEligibilityWaiver) &&
                <Body2 className="ml-1">GPA Override:&nbsp;
                  {state.awardEligibility.gpaEligibilityWaiver === GPA_ELIGIBILITY_WAIVER_NONE && "No override option available."}
                  {state.awardEligibility.gpaEligibilityWaiver === GPA_ELIGIBILITY_WAIVER_LIMITED && "Requires use of a GPA waiver."}
                  {state.awardEligibility.gpaEligibilityWaiver === GPA_ELIGIBILITY_WAIVER_PETITION && "Requires written petition to Graduate School."}
                </Body2>
              }
            </Fragment>
          }

          <h2 className="mt-4">Search by:</h2>
          {dropDownMetadata.slice(SEARCH_START_INDEX, SEARCH_END_INDEX).map(createDropDown)}
          { isAdmin && (
            <Fragment>
              <p>OR</p>
              <Label htmlFor={EMPLID_SEARCH} className="d-inline-block mt-1">
                <Body1 className="mr-1">Student ID</Body1>
              </Label>
              <InputGroup>
                <InputGroupButtonDropdown addonType="prepend">
                  <Button color="secondary" onClick={(event) => handleOnSearch(event)}>
                    <Icon color="white" type="search"/>
                  </Button>
                </InputGroupButtonDropdown>
                <Input 
                  id={EMPLID_SEARCH} 
                  name={EMPLID_SEARCH} 
                  value={state[EMPLID_SEARCH]} 
                  onChange={(event) => handleOnChange(EMPLID_SEARCH, event, [GRADUATE_PROGRAMS, TERMS])}
                  onKeyDown={(event) => handleOnSearch(event)} 
                  type="text" 
                  placeholder="EMPLID" 
                />
              </InputGroup>
            </Fragment>
          )}
          <h2 className="mt-4">Students</h2>
          { !addStudentSearchData.state  && <div className="mb-2">Please provide search terms for students</div>}
          { addStudentSearchData.state === ACTION_STATE_LOADING &&  <OSULoading text="Searching for students..."/> }
          { addStudentSearchData.state === ACTION_STATE_SUCCESS &&
            <Table 
              className="mb-4"
              dataKeys={buildAddStudentSearchTableDataKeys(studentStatus)} 
              data={buildAddStudentSearchTableData(addStudentSearchData.data)} 
              noDataMessage="There is no student data at this time." 
            />
          }
          <div>
            {submitNominationsResults.state === ACTION_STATE_LOADING && <OSULoading text="Saving..."/>}
            <OSUButton 
              disabled={!state[CURRENT_AWARDS] || !state[AWARD_OPTIONS] || isEmpty(state.studentSelections)} 
              ariaLabel="Nominate selected students to selected award" 
              color="blue" 
              onClick={handleOnClickSubmitNominations}>
                Submit Nominations
            </OSUButton>
            
          </div>
        </form>
    </div>
    
    );
};

AddStudent.propTypes = {
  studentStatus: PropTypes.string
}

AddStudent.defaultProps = {
  studentStatus: STUDENT_STATUS_CURRENT
}

export default AddStudent;