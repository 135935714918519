import { AWARD_NOMINEES_STATE, REVIEW_ALERT_DISMISSED } from "../actions-index";

export function awardNomineesState(state = "", action = {}) {
    return (action.type === AWARD_NOMINEES_STATE ? action.payload : state);
}

export function reviewAlertDismissed(state = false, action = {}) {
    return action.type === REVIEW_ALERT_DISMISSED ? action.payload : state;
}

export * from './ScoreSheetContent/reducers'
export * from './Evaluation/reducers'
export * from './ReviewersContent/reducers'
export * from './NomineesContent/reducers'
export * from './PrimaryReviewContent/reducers'
export * from './SecondaryReviewContent/reducers'

