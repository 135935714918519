import React from 'react'
import PropTypes from 'prop-types'
import { Card, Label, Modal, ModalHeader, ModalBody, Alert, ModalFooter, Input, FormFeedback } from 'reactstrap'
import { Heading6, Body1, Body2, OSULoading, OSUError, Typography, Heading5, OSUButton, Caption } from 'osu-react-components'
import '../evaluation.css'
import { find } from 'lodash'
import Criteria from '../components/Criteria'
import { Link } from "react-router-dom";
import NomineeReviewInformation from '../../components/Information';
import NavigationPrompt from "../../../Common/NavigationPrompt/components";
import _ from "lodash";
import moment from "moment";
import { redirectToLogOut } from "../../../util/util";
import { Auth } from "aws-amplify";
import {PAGE_TITLE_POSTFIX} from "../../../constants";

class EvaluationItem extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            pageTitle: "Review Score Sheet",
            currentCriteria: null,
            ...this.getInitialValues(props),
            formInputInvalid: {},
            commentLength: 512,
            privateRemarkLength: 1000,
            hasUnsavedChanges: false,
            setPageFocus: false,
            isOpen: false
        };
    }

    componentWillMount() {
        document.title = this.state.pageTitle + PAGE_TITLE_POSTFIX;
    }

    componentDidMount() {
        this.props.setA11yMessage(`Navigated to the ${this.state.pageTitle} page`);

        const { match, award, user, userAdminActingOnBehalfOf } = this.props;

        if (match && match.params && match.params.id && match.params.shortPlan && award && user) {
            const emplId = userAdminActingOnBehalfOf.emplid ? userAdminActingOnBehalfOf.emplid : user.emplId;
            this.props.getNomineeReview({ emplId: emplId, nomineeId: match.params.id, awardKey: award.pk, shortPlan: match.params.shortPlan });
            this.updateBreadcrumbTrail()
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.pageContent && this.state.setPageFocus === false) {
            this.pageContent.focus();
            this.setState({ setPageFocus: true });
        }

        const prevId = prevProps.match && prevProps.match.params && prevProps.match.params.id;
        //const prevShortPlan = prevProps.match && prevProps.match.params && prevProps.match.params.shortPlan;
        const currentId = this.props.match && this.props.match.params && this.props.match.params.id;
        const currentShortPlan = this.props.match && this.props.match.params && this.props.match.params.shortPlan;

        if (this.props.nomineeReview && (prevProps.nomineeReview !== this.props.nomineeReview)) {
            const { award, nomineeReview } = this.props;
            this.setState(this.getInitialValues(this.props));
            award.name && nomineeReview.name && this.updateBreadcrumbTrail()
        }
        if (currentId && prevId !== currentId) {
            this.props.getNomineeReview({ emplId: this.props.user.emplId, nomineeId: currentId, awardKey: this.props.award.pk, shortPlan: currentShortPlan });
            this.props.award.name && this.props.nomineeReview.name && this.updateBreadcrumbTrail();
            this.setState({ hasUnsavedChanges: false }); // new nominee, clear unsaved changes flag
        }
        if (prevProps.updateSuccess === false && this.props.updateSuccess === true) {
            this.setState({ hasUnsavedChanges: false }); // scores saved, clear unsaved changes flag
        }
    }

    updateBreadcrumbTrail() {
        this.props.updateBreadcrumbTrail(this.props.breadcrumbTrail);
    }

    getInitialValues(props) {
        const { nomineeReview = {} } = props;
        const { scoringInfo = [] } = nomineeReview;
        const { remark = '', comment = '' } = nomineeReview;
        const { lastEditedBy = '', lastEditTime = '' } = nomineeReview;
        const { shortPlan } = nomineeReview;
        const userScores = Array.isArray(scoringInfo) && scoringInfo.length > 0
            ? scoringInfo.filter(criteria =>
                nomineeReview.hasOwnProperty(criteria.field) && nomineeReview[criteria.field].hasOwnProperty('value'))
                .map((criteria) => ({
                    [`${criteria.field}`]: nomineeReview[criteria.field]
                })).reduce(function (result, current) {
                    return Object.assign(result, current);
                }, {}) : [];

        return {
            scores: userScores,
            shortPlan,
            remark,
            comment,
            lastEditedBy,
            lastEditTime
        }
    }

    componentWillUnmount() {
        this.props.updateBreadcrumbTrail([])
    }

    updateScores(value, field, invalid = false) {
        this.setState((state, props) => {
            invalid ? state.formInputInvalid[field] = true : delete state.formInputInvalid[field];
            return {
                formInputInvalid: state.formInputInvalid,
                scores: {
                    ...state.scores,
                    [field]: {
                        ...state.scores[field],
                        value
                    }
                },
                hasUnsavedChanges: true
            }
        })
    }

    renderCriteriaFields(criteria = [], weightedScore, rubric, reviewEndDateHasPassed = true, rankedScore = null) {
        const { currentCriteria, scores } = this.state;
        const { minScore, maxScore } = rubric;
        const criteriaKey = currentCriteria ? currentCriteria : criteria.length > 0 ? criteria[0].field : null;
        const activeCriteria = find(criteria, o => !!o.field && o.field === criteriaKey);
        const reviewEndDateHasPassedText = reviewEndDateHasPassed ? "Review period has ended, scores are locked." : ""
        const criteriaInputs = criteria.map((item, index) => {
            const currentScore = scores && scores.hasOwnProperty(item.field) && scores[item.field].hasOwnProperty('value') && scores[item.field].value !== 0 ? scores[item.field].value : "";
            const active = item.field === criteriaKey;
            //the following regex matches on cases: "", range "1-99"
            const invalidatedScore = (input) => {
                return (input.toString().trim() && input < minScore) || (input > maxScore) || !(input.toString().replace(/^0+/, '').match(/^$|^(?!00)[0-9]{2}$|^[1-9]$/gm))  ? true :
                                                                                                                                                        rankedScore !== null && !input  ? true : false;
            }

            const openSection = () => this.setState({ currentCriteria: item.field });
            return <div key={`open-section-${index}`} >
                <div className="hat">{`When button is focused or clicked, it will alter the page to display the scoring rules and evaluation criteria for ${item.name}. Use inner input to modify ${item.name} score.`}</div>
                <div role="button" aria-label={`Click to review ${item.name}`} className={`criteria-input-wrapper mb-1 pr-2 py-2 pl-4${active ? ' active' : ''}`} onFocus={openSection} onClick={openSection}>
                    <Typography variant={active ? 'subtitle2' : 'body2'} className="w-100 d-flex flex-column mr-2">
                        <Label for={`${item.field}Score`}>{item.name}</Label>
                        <Input invalid={invalidatedScore(currentScore)}
                            defaultValue={currentScore}
                            disabled={reviewEndDateHasPassed}
                            onChange={(e) => this.updateScores(e.target.value, item.field, invalidatedScore(e.target.value))} min={minScore} max={maxScore} placeholder={`${minScore} - ${maxScore}`} name={`${item.field}Score`} id={`${item.field}Score`} />
                        <FormFeedback>{`Please enter a score between ${minScore} and ${maxScore}`}</FormFeedback>
                    </Typography>
                </div>
            </div>
        });


        return <div className="pr-4 d-flex flex-row">
            <div key="criteria-sidebar" className="criteria-sidebar mr-2">
                {criteriaInputs}
                <Body1 className="pl-4">
                    Weighted Score: {weightedScore}
                </Body1>
                <br />
                <Caption className="pl-4"><strong>{reviewEndDateHasPassedText}</strong></Caption>
            </div>
            {activeCriteria && <Criteria key="criteria-details" className="criteria-details ml-2" {...activeCriteria} />}
        </div>
    }

    updateComment(key, comment, length) {
        this.setState((state) => {
            const invalid = (comment && comment.length) ? comment.length > length : false;
            invalid ? state.formInputInvalid[key] = true : delete state.formInputInvalid[key];
            return {
                [`${key}`]: comment,
                formInputInvalid: state.formInputInvalid,
                hasUnsavedChanges: true
            };
        })
    }

    renderScoresheetForm(scoringInfo, nomineeReview, rubric, submissionValues, reviewEndDateHasPassed) {
        const { comment, remark, commentLength, formInputInvalid, privateRemarkLength } = this.state;
        const { updateError, updateLoading, userAdminActingOnBehalfOf } = this.props;
        const remarkId = "remark";
        const commentId = "comment";
        const invalidRemark = formInputInvalid[remarkId] ? true : false;
        const invalidComment = formInputInvalid[commentId] ? true : false;
        const adminIsActingOnBehalfOfUser = !_.isEmpty(userAdminActingOnBehalfOf);
        if (updateLoading) {
            return <OSULoading text="Updating score sheet" />
        } else if (updateError) {
            return <OSUError ariaLabel="Error updating score sheet, resubmit form" text="Error updating score sheet" onClick={() => this.props.updateNomineeReviewScoresheet(submissionValues)} />
        }
        return <div>
            {this.renderCriteriaFields(scoringInfo, nomineeReview.hasOwnProperty('finalWeightedScore') ? nomineeReview.finalWeightedScore : 'None', rubric, reviewEndDateHasPassed, submissionValues.rankedScore)}
            <hr />
            <div className="d-flex flex-row px-4 comment-wrapper">
                <Body1 className="comment-box">
                    <div className="d-flex justify-content-between">
                        <Label for="remark">Private Remark</Label>
                        {remark && <Body2 color={invalidRemark ? 'red' : 'gray'}>{remark.length || 0} / {privateRemarkLength}</Body2>}
                    </div>
                    <FormFeedback>{`Please shorten your remark to under ${privateRemarkLength} characters.`}</FormFeedback>
                    <Input rows="5" invalid={invalidRemark} value={remark} type="textarea" name="text" disabled={reviewEndDateHasPassed || adminIsActingOnBehalfOfUser} id={remarkId} onChange={(e) => this.updateComment(remarkId, e.target.value, privateRemarkLength)} />
                </Body1>
                <Body1 className="comment-box">
                    <div className="d-flex justify-content-between">
                        <Label for="comment">Public Comment</Label>
                        {comment && <Body2 color={invalidComment ? 'red' : 'gray'}>{comment.length || 0} / {commentLength}</Body2>}
                    </div>
                    <FormFeedback>{`Please shorten your comment to under ${commentLength} characters.`}</FormFeedback>
                    <Input rows="5" invalid={invalidComment} value={comment} type="textarea" name="text" disabled={reviewEndDateHasPassed} id={commentId} onChange={(e) => this.updateComment(commentId, e.target.value, commentLength)} />
                </Body1>
            </div>
        </div>
    }

    unsuccessfulScreen({ status, emplId, nomineeId, awardKey }) {
        if (status === 'loading') {
            return <OSULoading className="px-4" text="Loading nominee review" />
        } else if (status === '401') {
            Auth.signOut() && redirectToLogOut();
            //return <OSUError ariaLabel="You are logged out. Close your browser and login again." text="You are logged out. Close your browser and login again." actionText="Login" onClick={() => redirectToLogIn()} />
        }
        return <OSUError ariaLabel="Click to reload nominee review" className="px-4" text="Error occurred while loading nominee review" onClick={() => this.props.getNomineeReview({ emplId, nomineeId, awardKey })} />

    }

    render() {
        const { nomineeReview, nomineeReviewStatus, gpaTypes, user, updateError, updateLoading, award, previous, next, currentId, userAdminActingOnBehalfOf } = this.props;
        const { scores, comment, remark, formInputInvalid, lastEditedBy, lastEditTime } = this.state;
        const { name, nomineeId, scoringInfo, shortPlan, shortPlanDescription, rankedScore } = nomineeReview;
        if (!nomineeReviewStatus.includes('success') || !nomineeReview) {
            return this.unsuccessfulScreen({ status: nomineeReviewStatus, emplId: user ? user.emplId : null, nomineeId: currentId, awardKey: award ? award.pk : null, shortPlan: shortPlan ? shortPlan : null })
        }
        if(this.props.isOpen && !this.state.isOpen){
            this.setState({isOpen:true})
        }
        const submissionValues = {
            scores,
            comment,
            remark,
            awardKey: award.pk,
            nomineeId,
            shortPlan,
            emplId: userAdminActingOnBehalfOf,
            rankedScore
        };
        const reviewEndDateHasPassed = this.props.award ? moment(moment().format()).isAfter(moment(this.props.award.reviewEndDate, "YYYY-MM-DDTHH:mm:ssZ")) : true;

        const invalidateScore = _.keys(formInputInvalid).length > 0 || updateError || updateLoading || reviewEndDateHasPassed;

        const bodyText = "You have successfully updated the scoresheet, AND recalculated all ranked scores."

        let fileArray = [];
        try { //likely to trigger while we figure out the data.                
            nomineeReview.documentName.map((file) => {
                return fileArray.push(<Link className='documentFlex' target={file.documentId} rel="noopener noreferrer" to={{
                    pathname: "/ViewPdf/" + file.documentId,
                    search: `?name=${name}&plan=${shortPlanDescription || shortPlan}`
                }}>{file.documentName}
                </Link>)
            });
        }
        catch (err) {
            console.log("Document data is not valid");
        }

        const message = "The score sheet has unsaved changes which will not be applied until the Save button is clicked. " +
            "Please confirm that you want to leave the page.";

        // find the breadcrumb representing the back button
        let backBreadcrumb = null;
        if (this.props.breadcrumbTrail && this.props.breadcrumbTrail.length > 0) {
            // filter the active breadcrumb
            const breadcrumbs = _.filter(this.props.breadcrumbTrail, breadcrumb => {
                return breadcrumb.active !== true;
            });
            if (breadcrumbs.length > 0) {
                // use the last breadcrumb
                backBreadcrumb = breadcrumbs[breadcrumbs.length - 1];
            }
        }
        return (
            <div ref={(el) => this.pageContent = el} tabIndex="-1" aria-labelledby="pageHeader">
                {this.props.nomineeReview &&
                    <NavigationPrompt prompt={this.state.hasUnsavedChanges} history={this.props.history} header="Unsaved Changes" message={message} cancelButtonLabel="Cancel" confirmButtonLabel="Confirm" />
                }

                <Modal  fade scrollable={true} isOpen={this.state.isOpen} onClosed={() => this.setState({ isOpen: false })}>
                    <ModalHeader>
                        Calculating Ranked Scores
                    </ModalHeader>
                    <ModalBody>
                        {this.props.assignPacketsToReviewersProcessing ?
                            (<div>
                                {!this.props.assignPacketsToReviewersProcessingMessage ?
                                    <OSULoading text="Processing... This might take long time to process... Hang on there..." /> : <OSULoading text={this.props.assignPacketsToReviewersProcessingMessage} />}
                            </div>
                            ) :
                            (<div>
                                {this.props.assignPacketsToReviewersError &&
                                    <Alert color="danger" toggle={() => this.props.resetAssignPacketsToSelectedReviewersFlags()}>
                                        There was an error assigning packets
                                    {!this.props.assignPacketsToReviewersErrorMessage ?
                                            (<span>.<br />Please retry to see if it resolves the issue.</span>) :
                                            (<span>:<br />{this.props.assignPacketsToReviewersErrorMessage}</span>)
                                        }
                                    </Alert>
                                }
                                <Body2>{bodyText}</Body2>
                            </div>)
                        }
                    </ModalBody>
                    <ModalFooter>
                        {!this.props.assignPacketsToReviewersProcessing && [
                            <OSUButton ariaLabel="No" key="no" color="gray" solid uppercase={false} className="mr-1" onClick={() => { this.setState({ isOpen: false }) }}>Okay!</OSUButton>,
                        ]}
                    </ModalFooter>
                </Modal>


                <Card className="py-4">
                    <Heading6 id="pageHeader" className="px-4">{this.state.pageTitle}</Heading6>
                    <hr />
                    <Heading5 className="px-4 mb-2">
                        {`${name}${nomineeReview.displayShortPlan ? ` (${nomineeReview.displayShortPlan})` : ''}`}
                    </Heading5>
                    <NomineeReviewInformation fileArray={fileArray} className="px-4 d-flex flex-column flex-md-row" gpaTypes={gpaTypes} nomineeReview={nomineeReview} />
                    <hr />

                    {this.renderScoresheetForm(scoringInfo, nomineeReview, award && award.reviewInfo && award.reviewInfo.rubric ? award.reviewInfo.rubric : null, submissionValues, reviewEndDateHasPassed)}
                    <hr />

                    {lastEditedBy && <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Last reviewed by: {lastEditedBy} on {lastEditTime}</div>}
                    <hr />

                    <div className="d-flex flex-row px-4">
                        {backBreadcrumb &&
                            <OSUButton solid ariaLabel={`Go back to ${backBreadcrumb.text}`} path={backBreadcrumb.path}>Back</OSUButton>
                        }
                        <OSUButton disabled={!previous} ariaLabel={!next ? `No previous nominee available` : `Go to view for the previous nominee`} outline className={`flex-basis-10 mr-4 ml-auto${!previous ? ' not-allowed' : ''}`} path={previous && { pathname: `/nominee-reviews/evaluation/${previous}`, state: { tabIdentifier: this.props.tabIdentifier } }}>Previous</OSUButton>
                        <OSUButton disabled={!next} ariaLabel={!next ? `No next nominee available` : `Go to view for the next nominee`} outline className={`flex-basis-10 mr-4${!next ? ' not-allowed' : ''}`} path={next && { pathname: `/nominee-reviews/evaluation/${next}`, state: { tabIdentifier: this.props.tabIdentifier } }}>Next</OSUButton>
                        <OSUButton disabled={invalidateScore} ariaLabel="Save and stay on current screen" className={`flex-basis-10${invalidateScore ? ' not-allowed' : ''}`} onClick={() => this.props.updateNomineeReviewScoresheet(submissionValues)}>Save</OSUButton>
                    </div>
                </Card>
            </div>
        );
    }
}

EvaluationItem.propTypes = {
    nomineeReview: PropTypes.shape({
        name: PropTypes.string,
        program: PropTypes.string,
        nominations: PropTypes.array,
        gpaWaiver: PropTypes.bool,
        gradGPA: PropTypes.number,
        undergradGPA: PropTypes.number,
        academicScore: PropTypes.number,
        characteristicsScore: PropTypes.number,
        experienceRelatedScore: PropTypes.number,
        experienceUnRelatedScore: PropTypes.number,
        overallImpressionScore: PropTypes.number,
        weightedScore: PropTypes.number,
        primaryReviewer: PropTypes.string,
        documents: PropTypes.string,
        notes: PropTypes.string,
        scoringInfo: PropTypes.array,
        rankedScore: PropTypes.string
    }),
    getNomineeReview: PropTypes.func,
    award: PropTypes.shape({
        awardKey: PropTypes.string,
        name: PropTypes.string
    }),
    updateBreadcrumbTrail: PropTypes.func
};

export default EvaluationItem