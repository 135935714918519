import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import React, { useRef, useState } from "react";
import { Typography } from 'osu-react-components'
import "./index.scss"

const FileUpload = (props = {}) => {
  const { multiple, inputProps, children, uploadFiles, id, name, className, disabled, ...rest } = props;
  const identifier = id
  const [fileName, setFileName] = useState('No file uploaded')
  const fileRef = useRef({})
  
  const addFiles = (event) => {
    event.preventDefault()
    let fileName = ''
    let response = event.target.files || [];

    if (!multiple) {
      response = response.length > 0 ? response[0] : { name: '' };
      fileName += response.name
    } else if (response) {
      fileName += [...response].map(file => file.name).join()
    }

    setFileName(fileName);
    uploadFiles(response);
  };
  
  
  const onClick = (event) => {
    event.preventDefault()

    const element = fileRef ? ReactDOM.findDOMNode(fileRef.current) : null

    if(!element) return null
    
    element.click()
  };

  return (
    <div className={`d-flex align-items-center ${className || ''}`.trim()} {...rest}>
      <label className="d-flex pr-2 mb-0" htmlFor={identifier + "fileupload"} id={identifier + "buttonlabel"}>
        <button
          disabled={disabled}
          className="file-button"
          onClick={onClick}
          aria-controls={identifier + "filename"}
        >
          Upload file
        </button>
      </label>
      <input
        disabled={disabled}
        onChange={addFiles}
        ref={fileRef}
        style={{ display: "none" }}
        type="file"
        accept="application/pdf"
        id={identifier + "fileupload"}
      />
      <Typography variant="body2" id={identifier + "filename"}>{fileName}</Typography>
    </div>
  );
};

FileUpload.propTypes = {
  id: PropTypes.string.isRequired
}


export default FileUpload
