import React, { Fragment, useContext } from 'react'
import PropTypes from 'prop-types'
import { Typography } from 'osu-react-components'
import { FormEnvironmentContext } from '../context'
import ManageFiles from './ManageFiles'
import { NOMINEE_FILE_NAMES } from '../../constants'

function Packet (props = {}) {
    const { saveChanges, divider, nomineeName }  = props
    const { requireDocumentUpload } = useContext(FormEnvironmentContext)
    
    if(!requireDocumentUpload) return null

    return (
      <Fragment>
        <Typography className="mb-2" variant="subtitle2">
          Nomination Packet
        </Typography>
        <ManageFiles updateFiles={(values) => saveChanges({ nomineePacketFiles: values })} label={NOMINEE_FILE_NAMES.packet} name={nomineeName} type="packet-files" id="packet-files" />
        {divider && <hr />}
      </Fragment>
    );
}
Packet.defaultProps = {
    divider: true
}

Packet.propTypes = {
    saveChanges: PropTypes.func
}


export default Packet