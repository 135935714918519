// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_content_delivery_bucket": "nomination-system-20190617115147-hostingbucket-master",
    "aws_content_delivery_bucket_region": "us-east-2",
    "aws_content_delivery_url": "https://dle3f31md81uw.cloudfront.net"
};


export default awsmobile;
