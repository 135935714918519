import React from 'react'
import PropTypes from 'prop-types'
import { Body2,  OSULoading, OSUButton, Caption, Subtitle2  } from 'osu-react-components'
import { Alert, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import ScoreDistribution from "../../components/ScoreDistribution";

class ReviewerDetails extends React.Component {



    constructor(props) {
        super(props);
        this.state = {

            isOpen: false
        };
    }


    calculateRankedScores = (isOpen) => {
        this.setState({
            isOpen: isOpen,
            processingRankedScores: false
        });
    }

    render() {
        const { emplid, name, email, phone, jobTitle, shortPlans, className, stats, systemAward, percent } = this.props;
        if (this.state.isOpen && !this.state.processingRankedScores) {
            this.props.calculateRankedScores({awardKey:systemAward.pk, emplId: emplid});
            this.setState({ processingRankedScores: true });
        }

        let bodyText = "Error: There was an error calculating ranked score, please try again";

        if(percent === "100%") {
            bodyText = "Successfully calculated " + name + "'s Ranked Scores";
        }


        return <div className={className}>
            <div className="d-flex">
                <section className="flex-fill pb-md-0 pr-1" style={{ minWidth: "9rem" }}>
                    <Caption color="gray">Reviewer</Caption>
                    <Body2 className="d-flex flex-column">
                        {emplid && <span>Employee ID: {emplid}</span>}
                        {email && <Subtitle2>Email: {<a href={`mailto:${email}`} aria-label={`E-mail ${name} at ${email}`} >{email}</a>}</Subtitle2>}
                        {phone && <span>Phone: {phone}</span>}
                    </Body2>
                </section>
                <section className="flex-fill pb-md-0">
                    <Caption color="gray">Academic</Caption>
                    <Body2 className="d-flex flex-column">
                        {Array.isArray(shortPlans) && <span key={`shortPlans`}>{`Academic Plans: ${shortPlans.join(", ")}`}</span>}
                        {jobTitle && <span>Job Title: {jobTitle}</span>}
                    </Body2>
                </section>
            </div>
            {stats &&
                <section className="d-flex flex-fill pt-2">
                    <ScoreDistribution reviewCompletionDate={systemAward.reviewCompletionDate} onCalculateRankedScores={this.calculateRankedScores} rankedScoresEnabled={percent === "100%"} reviewerStats={stats} />
                </section>
            }
            <Modal scrollable isOpen={this.state.isOpen} onClosed={() => this.setState({ isOpen: false })}>
                <ModalHeader>
                    Calculating Ranked Scores
                </ModalHeader>
                <ModalBody>
                    {this.props.assignPacketsToReviewersProcessing ?
                        (<div>
                            {!this.props.assignPacketsToReviewersProcessingMessage ?
                                <OSULoading text="Processing... This might take long time to process... Hang on there..." /> : <OSULoading text={this.props.assignPacketsToReviewersProcessingMessage} />}
                        </div>
                        ) :
                        (<div>
                            {this.props.assignPacketsToReviewersError &&
                                <Alert color="danger" toggle={() => this.props.resetAssignPacketsToSelectedReviewersFlags()}>
                                    There was an error assigning packets
                                    {!this.props.assignPacketsToReviewersErrorMessage ?
                                        (<span>.<br />Please retry to see if it resolves the issue.</span>) :
                                        (<span>:<br />{this.props.assignPacketsToReviewersErrorMessage}</span>)
                                    }
                                </Alert>
                            }
                            <Body2>{bodyText}</Body2>
                        </div>)
                    }
                </ModalBody>
                <ModalFooter>
                    {!this.props.assignPacketsToReviewersProcessing && [
                        <OSUButton ariaLabel="No" key="no" color="gray" solid uppercase={false} className="mr-1" onClick={() => { this.setState({ isOpen: false }) }}>Okay!</OSUButton>,
                    ]}
                </ModalFooter>
            </Modal>

        </div>
    }
}

ReviewerDetails.defaultProps = {
    className: ''
};

ReviewerDetails.propTypes = {
    className: PropTypes.string,
    emplid: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string,
    jobTitle: PropTypes.string,
    shortPlans: PropTypes.arrayOf(PropTypes.string)
};

export default ReviewerDetails