export const CURRENT_AWARDS = "CURRENT_AWARDS";
export const GRADUATE_PROGRAMS = "GRADUATE_PROGRAMS";
export const TERMS = "TERMS";
export const ZEROTH_ELEMENT_COUNT = 1;
export const AWARD_OPTIONS = "AWARD_OPTIONS";
export const SUCCESS = "success";
export const EMPLID_SEARCH = "EMPLID_SEARCH";
export const STUDENT_STATUS_CURRENT = "ENROLLED";
export const ALL_PROGRAMS = "ALL PROGRAMS";

export const OPTIONS_CONFIGURATION = [
  {
    key: CURRENT_AWARDS,
    label: "Awards",
    required: true,
    action: "onAwardChange",
    resetList: [AWARD_OPTIONS],
    dataStructure: {
     noDataLabel: "No Award Available",
     label: "name",
     value: "pk"
    }
  },
  {
    key: AWARD_OPTIONS,
    label: "Award Option",
    required: true,
    action: "onAwardOptionChange",
    resetList: [],
    dataStructure: {
      noDataLabel: "No Award Options Available",
      label: "awardResultLabel",
      value: "awardResultABBV"
    }
  }, 
  {
    key: TERMS,
    label: (studentStatus) => studentStatus === STUDENT_STATUS_CURRENT ? "Enrollment Term" : "Admit Term", 
    action: "onTermChange",
    resetList: [EMPLID_SEARCH],
    dataStructure: {
      noDataLabel: "No Academic Terms Available",
      label: "short-descr",
      value: "strm"
    }
  },
  {
    key: GRADUATE_PROGRAMS,
    adminOnly: true, 
    label: "Academic Program",
    action: "onProgramChange",
    resetList: [EMPLID_SEARCH],
    dataStructure: {
      noDataLabel: "No Academic Programs Available"
    }
  }  
];

export const NOMINEE_FILE_NAMES = {
  petition: 'GPA Petition',
  packet: 'Packet'
}