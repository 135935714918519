import React, { Component, Fragment } from "react";
import { Form, FormGroup, Input, Label } from "reactstrap";
import PropTypes from "prop-types";
import { Body1 } from "osu-react-components";
import { cloneDeep } from "lodash";
import { EVENT_RESET_STATE } from "../../constants";

class ReviewerEmail extends Component {
    componentWillMount() {
        this.setComponentState();
        document.addEventListener(EVENT_RESET_STATE, this.setComponentState);
    }

    componentDidMount() {
        this.validate();
    }

    shouldComponentUpdate(nextProps) {
        return nextProps.isActive; // re-render only when active
    }

    componentWillUnmount() {
        document.removeEventListener(EVENT_RESET_STATE, this.setComponentState);
    }

    setComponentState = () => {
        this.setState({
            award: this.props.award
        });
    }

    onFormInputChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        const award = cloneDeep(this.state.award);
        award[name] = value;
        this.setState({ award }, () => this.validate());
    }

    validate = () => {
        this.props.onValidate(true, this.state.award);
    }

    render() {
        const { readOnly } = this.props;
        const { award } = this.state;
        const { reviewInviteSubject, reviewInviteBody } = award;
        return (
            <section>
                <h2 className="heading6" data-testid="reviewerEmailHeader">Reviewer Email</h2>
                <hr />
                <Form id="reviewerEmailForm" data-testid="reviewerEmailForm" noValidate>
                    <FormGroup data-testid="reviewInviteSubjectFormGroup">
                        <Label for="reviewInviteSubject">
                            <Body1>Subject:</Body1>
                        </Label>
                        {readOnly ?
                            (<Body1 id="reviewInviteSubject" name="reviewInviteSubject" dataTestId="reviewInviteSubject">{reviewInviteSubject}</Body1>) :
                            (<Fragment>
                                <Input id="reviewInviteSubject" name="reviewInviteSubject" data-testid="reviewInviteSubject" type="text" 
                                    value={reviewInviteSubject} onChange={e => this.onFormInputChange(e)} />
                            </Fragment>)
                        }
                    </FormGroup>
                    <FormGroup data-testid="reviewInviteBodyFormGroup">
                        <Label for="reviewInviteBody" className="mb-0">
                        <Body1>Body:</Body1>
                        </Label>
                        {readOnly ?
                            (<Body1 id="reviewInviteBody" name="reviewInviteBody" dataTestId="reviewInviteBody">{reviewInviteBody}</Body1>) :
                            (<Fragment>
                                <Input id="reviewInviteBody" name="reviewInviteBody" data-testid="reviewInviteBody" type="textarea" rows="15" 
                                    style={{ resize: "vertical" }} value={reviewInviteBody} onChange={e => this.onFormInputChange(e)} />
                            </Fragment>)
                        }
                    </FormGroup>
                </Form>
            </section>
        );
    }
}

ReviewerEmail.defaultProps = {
    isActive: false,
    inReview: false,
    readOnly: false
}

ReviewerEmail.propTypes = {
    award: PropTypes.object.isRequired,
    isActive: PropTypes.bool,
    inReview: PropTypes.bool,
    onValidate: PropTypes.func.isRequired,
    readOnly: PropTypes.bool
}

export default ReviewerEmail;