import React from 'react'
import { Body2, Body1, Subtitle2 } from 'osu-react-components'
import PropTypes from 'prop-types'

const CommentFeed = (props) => {
    const { comments, className, hideHeader } = props
    return <div className={className}>
        {!hideHeader && <Subtitle2 key="comment-section-heading" className="pb-1">
            Comments
        </Subtitle2>}
        {Array.isArray(comments) && comments.length > 0 ? comments.map((item, index) => {
            const comment = (className) => <Comment className={className} key={'comment' + index} {...item} />

            if (index === comments.length - 1) {
                return comment('pb-2')
            }
            return [comment(), <hr key={'divider' + index} />]
        })  :
        <Body2>No comments are available.</Body2>
    }
    </div>
}

CommentFeed.defaultProps = {
    hideHeader: false
}

CommentFeed.propTypes = {
    comments: PropTypes.array
}

export default CommentFeed

export const Comment = ({ author, text, className, message }) => <Body1 className={className} >
    <Body2 color="gray">
        {author}
    </Body2>
    {text}
    <br/>
    <Body2 color="red">
        {message}
    </Body2>
</Body1>