import React from 'react'
import { Caption, Heading4, Heading5, Heading6, Body1, Table, OSUButton, OSULoading, OSUError } from 'osu-react-components'
import { CustomInput } from 'reactstrap'
import PropTypes from 'prop-types'
import { find, isEmpty } from 'lodash'
import moment from "moment"
import sanitizeHtml from "sanitize-html-react";
import Select from "react-select";
import {redirectToLogOut} from "../../../util/util";
import {Auth} from "aws-amplify";
import {PAGE_TITLE_POSTFIX} from '../../../constants';

class AwardSwitchboard extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            awardKey: props.systemAward.pk || null,
            pageTitle: "Award Selection",
            setPageFocus: false
        };
    }

    componentWillMount() {
        document.title = this.state.pageTitle + PAGE_TITLE_POSTFIX;
    }

    componentDidMount() {
        this.props.setA11yMessage(`Navigated to the Nominee Review ${this.state.pageTitle} page`);
        this.props.getPreviousAwards();

        if(isEmpty(this.props.status)) this.props.getCurrentAwards();
        const isAdmin = this.props.user.applicationRoles.includes("ADMIN");
            this.setState({ expandAll: !isAdmin, collapseAll: isAdmin });
    }

    componentDidUpdate() {
        if(this.pageContent && this.state.setPageFocus === false) {
            this.pageContent.focus();
            this.setState({ setPageFocus: true });
        }
    }

    async confirmAwardSelection(selectedAward) {
        await Promise.resolve(this.props.setSystemAwardValues(selectedAward));
        return this.props.history.push('/nominee-reviews');
    }

    render() {
        const { awards, status, allowedRoles } = this.props;
        if (status === 'loading') {
            return <OSULoading text="Loading awards" />
        }else if (status === '401') {
            Auth.signOut() && redirectToLogOut();
            //return <OSUError ariaLabel="You are logged out. Close your browser and login again." text="You are logged out. Close your browser and login again." actionText="Login" onClick={() => redirectToLogIn()} />
        } else if (status === 'error') {
            return <OSUError text="Error getting awards" onClick={() => this.props.getCurrentAwards()} />
        }

        const selectedAward = find(awards, award => award.pk === this.state.awardKey);

        const disableAwardSelection = this.state.awardKey === null || isEmpty(selectedAward);

        if(this.state.expandAll || this.state.collapseAll){
            this.setState({
                expandAll: false,
                collapseAll: false
            });
        }


        const dataKeys = [{
            key: "selectButton",
            width: 3
        }, {
            key: "award",
            width: 97
        }];


        let filteredAwards = []

        if(!allowedRoles.includes("ADMIN")){
            filteredAwards = awards.filter(award => {
                return allowedRoles.some(role => role === award.pk);
            });
        } else {
            filteredAwards = awards;
        }
        const data = filteredAwards.map((award, index) => {
            const { externalUrl, name } = award;
            const awardKey = award.pk;
            const { reviewStartDate, reviewCompletionDate, reviewEndDate, reviewCommitteeMeetingStartDate, reviewCommitteeMeetingEndDate, } = award;
            const dateFormat = "MM/DD/YYYY hh:mm A";
            const reviewStartDateFormatted = moment(reviewStartDate).format(dateFormat);
            const reviewCompletionDateFormatted = moment(reviewCompletionDate).format(dateFormat);
            const reviewEndDateFormatted = moment(reviewEndDate).format(dateFormat);
            const reviewCommitteeMeetingStartDateFormatted = moment(reviewCommitteeMeetingStartDate).format(dateFormat);
            const reviewCommitteeMeetingEndDateFormatted = moment(reviewCommitteeMeetingEndDate).format(dateFormat);
            const description = award.description ? award.description : "Thank you for serving as a reviewer.  Contact the Graduate School with questions and concerns about the review process or policies.";
            let sanitizedDescription = description ?
                sanitizeHtml(description, { allowedTags: ["a", "b", "br"], allowedAtrributes: { a: [ "href" ] }, allowedSchemesByTag: { a: [ "mailto" ]} }) : null;
            if(sanitizedDescription && externalUrl) sanitizedDescription += "<br /><br />";
            return {
                selectButton: <CustomInput aria-label={`Select Award ${name}`} type="radio" name="select" id={`award${index}`} checked={this.state.awardKey === awardKey} readOnly />,
                award: <Body1 key={awardKey}>
                    {name}
                    <Caption color="gray" className="ml-3 d-block">Start reviews: <span className="d-inline-block" style={{ color: "black"}}>{reviewStartDateFormatted}</span></Caption>
                    <Caption color="gray" className="ml-3 d-block">Review Completion Deadline, Start Composite Reviews: <span className="d-inline-block" style={{ color: "black"}}>{reviewCompletionDateFormatted}</span></Caption>
                    <Caption color="gray" className="ml-3 d-block">Composite Review Ends (scores are locked): <span className="d-inline-block" style={{ color: "black"}}>{reviewEndDateFormatted}</span></Caption>
                    <Caption color="gray" className="ml-3 d-block">Committee Meeting: <span className="d-inline-block" style={{ color: "black"}}>{reviewCommitteeMeetingStartDateFormatted} - {reviewCommitteeMeetingEndDateFormatted}</span></Caption>
                </Body1>,
                expandedContent: (
                    <p className="px-4 py-2">
                        {sanitizedDescription ? (<span dangerouslySetInnerHTML={{ __html: sanitizedDescription }} />) : null}
                        {externalUrl ? <span>Please see the <a rel="noopener noreferrer" href={award.externalUrl} target="_blank">Reviewer Guidelines</a> for details on the review process.</span> : null}
                    </p>
                ),
                onRowClick: async () => {
                    this.setState({ awardKey })
                    await Promise.resolve(this.props.setSystemAwardValues(find(awards, award => award.pk === awardKey)));
                }
            }
        });

        const previousAwards = this.props.previousAwards.map((previousAward, index) => {

            return ({
                ...previousAward,
                key: previousAward.name || previousAward.awardKey,
                label: previousAward.name || previousAward.awardKey,
            })
        });
        return <div ref={(el) => this.pageContent = el} tabIndex="-1" aria-label={this.state.pageTitle}>
                        <Heading4>Welcome to the Graduate School Fellowship and Awards system!</Heading4>
                        <Heading4>Thank you for your participation.</Heading4>
                        <br/>

            <Heading5>Please select an award to review:</Heading5>
            <Table className="mb-4" multiOpen noHeader expandAll={this.state.expandAll} collapseAll={this.state.collapseAll} expandable data={data} dataKeys={dataKeys} />
            <OSUButton onClick={() => this.confirmAwardSelection(selectedAward)} ariaLabel="Review Award" disabled={disableAwardSelection}>Review Award</OSUButton>
            <br/>
            <br/>
            <br/>
            {(previousAwards && previousAwards.length && <div style={{height:"80%",width:"40%"}}><Heading6>Previous Awards</Heading6><Select aria-label="Previous Awards" options={previousAwards} onChange={e => this.confirmAwardSelection(e)}></Select></div>) || " "}
        </div>
    }
}

AwardSwitchboard.defaultProps = {

};

AwardSwitchboard.propTypes = {
    awards: PropTypes.array,
    previousAwards: PropTypes.array
};

export default AwardSwitchboard
