import { SEARCH_SCORE_SHEET, NOMINEE_REVIEW_LIST, NOMINEE_LIST_STATUS, ADD_NOMINEE_LIST_FILTER, ADD_NOMINEE_LIST_SORT_KEY, NOMINEE_REVIEWER_STATS } from '../../actions-index'
import { API } from "aws-amplify";
import { setApiStatus } from "../../redux-helpers";
import { systemHeaders } from "../../util/util";
import { isEmpty } from 'lodash';

export function searchScoreSheet(query) {
    return {
        type: SEARCH_SCORE_SHEET,
        payload: query
    }
}

export function getNomineeReviewList(awardKey, sortKey = null) {
    const setNomineeListStatus = (status) => setApiStatus(NOMINEE_LIST_STATUS, status)
    return (dispatch) => {
        dispatch(setNomineeListStatus('loading'))

        API.get(process.env.REACT_APP_AWARD_NOMINEE_REVIEW_API_NAME, `/getReviewerNominees/${btoa(awardKey)}`, systemHeaders())
            .then(response => {
                if (response.data) {
                    dispatch(receiveNomineeList(response.data.nominees));
                    dispatch({ type: NOMINEE_REVIEWER_STATS, payload: response.data.reviewerStats });
                } else if (response.status !== undefined && response.status === 401) {
                    console.error("401 Response in getNomineeReviewList:", response.status);
                    dispatch(setNomineeListStatus('401'));
                } else {
                    console.error("no Response data error getNomineeReviewList:", response);
                    dispatch(setNomineeListStatus('error'));
                }
            })
            .catch(error => {
                console.log("Error in getNomineeReviewList:", error);
                if (error === 'No current user' || error === 'Request failed with status code 401' || (error.response !== undefined && error.response.status !== undefined && error.response.status === 401)) {
                    dispatch(setNomineeListStatus('401'));
                } else {
                    dispatch(setNomineeListStatus('error'));
                }
            })
            .finally(() => sortKey && dispatch(updateNomineeReviewListSortKey(sortKey)))
    }
}

export function calculateRankedScores(scoringData) {

    const {awardKey, nomineeId, emplId, shortPlan, scores, remark, comment} = scoringData;

    const setNomineeListStatus = (status) => setApiStatus(NOMINEE_LIST_STATUS, status)
    return (dispatch) => {
        dispatch(setNomineeListStatus('loading'))
        const params = {
            ...systemHeaders(),
            body: {
                awardKey: window.btoa(awardKey),
                nomineeId,
                reviewerActedOnBehalfOf: {emplid:emplId},
                shortPlan,
                ...scores,
                remark: isEmpty(remark) ? undefined : remark,
                comment: isEmpty(comment) ? undefined : comment
            }
        };
        API.post(process.env.REACT_APP_AWARD_NOMINEE_REVIEW_API_NAME, `/calculateRankedScores`, params)
            .then(response => {
                if (response.data) {
                    dispatch(receiveNomineeList(response.data.nominees));
                    dispatch({ type: NOMINEE_REVIEWER_STATS, payload: response.data.reviewerStats });
                    // dispatch(dispatch({ type: NOMINEE_REVIEW, payload: response.data }));
                    // dispatch(updateScoreStatus('success'));
                } else if (response.status !== undefined && response.status === 401) {
                    console.error("401 Response in setNomineeListStatus:", response.status);
                    // dispatch(updateScoreStatus('401'));
                } else {
                    console.error("no Response data error setNomineeListStatus:", response);
                    // dispatch(updateScoreStatus('error'));
                }
            })
            .catch(error => {
                console.error("Error in setNomineeListStatus:", error);
                if (error === 'No current user' || error === 'Request failed with status code 401' || (error.response !== undefined && error.response.status !== undefined && error.response.status === 401)) {
                    // dispatch(updateScoreStatus('401'));
                } else {
                    // dispatch(updateScoreStatus('error'));
                }
            })
            .finally(() => dispatch(updateNomineeReviewListSortKey({key:"random", direction:"asc"})))

    }
}

function receiveNomineeList(nomineeData) {
    return {
        type: NOMINEE_REVIEW_LIST,
        payload: nomineeData
    }
}

export function addNomineeListFilter(filter) {
    return {
        type: ADD_NOMINEE_LIST_FILTER,
        payload: filter
    }
}

export function updateNomineeReviewListSortKey(sortKey) {
    return {
        type: ADD_NOMINEE_LIST_SORT_KEY,
        payload: sortKey
    }
}