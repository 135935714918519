import React from 'react';
import { Table, OSUButton, Body1, PaginationWrapper, Icon } from 'osu-react-components';
import PropTypes from 'prop-types';
import { chunk } from 'lodash';
import * as Globals from "../../../constants";
const USER_SCORE_FIELD_DEFAULT = 0;

//my reviews list
class EvaluationList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            rowsPerPage: 10,
            dataIndex: 0
        };
    }

    dataKeys = (scoringCriteria = [], rankedScoreIsPresent = false) => {
        // const { sortKey } = this.props;

        // const generateButton = (label, type, nestedProperty) => <OSUButton ariaLabel={`Sort by ${label}`} className="p-0" color="black" uppercase={false} link variant="subtitle2"
        //     onClick={() => this.props.updateSortKey({ key: type, nestedProperty })}>
        //     {label}
        //     {sortKey && sortKey.key && sortKey.key === type && <Icon className="pl-1" type={sortKey.direction === 'desc' ? 'sort-down' : 'sort-up'} />}
        // </OSUButton>;

        const dataKeys = window.innerWidth > Globals.MOBILE_MAXIMUM_RESOLUTION ? [{
            key: 'name',
            label: "Name",
            width: 20,
            className: 'align-self-end'
        }, ...scoringCriteria.map(col => {
            return {
                key: col.field,
                label: col.name,
                width: 65 / scoringCriteria.length,
                className: 'align-self-end text-center'
            }
        }),
        {
            key: 'finalWeightedScore',
            label: 'Weighted Score',
            width: 8,
            className: 'align-self-end text-center'
        },
        {
            key: 'rankedScore',
            label: 'Ranked Score',
            width: 7,
            className: 'align-self-end text-center'
        }
        ] : //if mobile width
            rankedScoreIsPresent ? [{
                key: 'name',
                label: "Name",
                width: 70,
                className: 'align-self-end'
            }, {
                key: 'rankedScore',
                label: 'Ranked Score',
                width: 30,
                className: 'align-self-end text-center'
            }
            ] : //if no ranked score present
                [{
                    key: 'name',
                    label: 'Name',
                    width: 70,
                    className: 'align-self-end'
                }, {
                    key: 'finalWeightedScore',
                    label: 'Weighted Score',
                    width: 30,
                    className: 'align-self-end text-center'
                }];
        return dataKeys;
    };

    determineValueMatchForRankedScore = (data) => {
        this.props.dismissDuplicateScoreAlert();

        const sortedData = data.sort((a, b) => {
            return a.rankedScore - b.rankedScore;
        });
        const valueMatchedData = sortedData.map((element, index) => {
            if (sortedData[index + 1] && element.rankedScore) {
                //I beilieve that element.finalWeightedScore could be a string so we are coercing it to be equal
                // eslint-disable-next-line eqeqeq
                if (element.finalWeightedScore == sortedData[index + 1].finalWeightedScore) {
                    for (let i = index; i < sortedData.length; i++) {
                        //I beilieve that element.finalWeightedScore could be a string so we are coercing it to be equal
                        // eslint-disable-next-line eqeqeq
                        if (element.finalWeightedScore == sortedData[i].finalWeightedScore && (element.rankedScore - sortedData[i].rankedScore < -99 || element.jsxClassName === "value-match-red-row-highlight")) {
                            element.jsxClassName = "value-match-red-row-highlight";
                            sortedData[i].jsxClassName = "value-match-red-row-highlight";
                            this.props.openDuplicateScoreAlert();
                        }
                    }
                }
            }
            return element;
        });

        return valueMatchedData;
    }

    convertNomineesToJsx = (data, reviewerEmail) => {
        const determineNumber = (val, truncateValue = 5) => typeof val === 'string' || (typeof val === 'number' && val > 0) ? val.toString().substring(0, truncateValue) : '-';
        const newData = this.determineValueMatchForRankedScore(data);
        const nominees = newData.map((nom) => {
            const awards = this.props.scoringCriteria.map((col) => {
                if (col.field) {
                    const userScore = nom[col.field] || USER_SCORE_FIELD_DEFAULT;
                    return {
                        [col.field]: <Body1>{determineNumber(userScore.value).toString()}</Body1>
                    }
                } else {
                    return undefined
                }
            }).reduce(function (result, current) {
                return Object.assign(result, current);
            }, {});

            return {
                nomineeId: nom.nomineeId,
                name: <OSUButton className={'p-0 text-left ' + nom.jsxClassName} uppercase={false}
                    variant={'body1'}
                    ariaLabel={`Navigate to evaluation for ${nom.nomineeId} , ${nom.shortPlan}`}
                    path={{
                        pathname: `/nominee-reviews/evaluation/${nom.nomineeId}/${nom.shortPlan}`,
                        state: {
                            tabIdentifier: this.props.tabIdentifier
                        }
                    }}>
                    {nom.name}
                    {(nom.comment || nom.remark) && <sup><Icon size="xs" className="pl-1" type="commenting-o" color="gray" /></sup>}
                    {(nom.lastEditedBy && (!nom.lastEditedBy.includes(reviewerEmail))) && <sup><Icon size="xs" className="pl-1" type="asterisk" color="black" /></sup>}

                </OSUButton>,
                ...awards,
                finalWeightedScore: <Body1>{determineNumber(nom.finalWeightedScore)}</Body1>,
                rankedScore: <Body1 className={nom.jsxClassName}>{determineNumber(nom.rankedScore, 6)}</Body1>
            }
        });

        return nominees
    };

    setDataIndex = dataIndex => {
        this.setState({ dataIndex });
    };

    setRowsPerPage = rowsPerPage => {
        this.setState({ rowsPerPage });
    };


    render() {
        const { nominees, scoringCriteria, reviewerEmail } = this.props;
        if (!nominees || !Array.isArray(nominees) || !scoringCriteria || scoringCriteria.length < 1) {
            return null
        }
        const rankedScoreIsPresent = nominees[0] && nominees[0].rankedScore;
        const rowsPerPage = this.state.rowsPerPage;
        const dataIndex = this.state.dataIndex;
        const dataKeys = this.dataKeys(this.props.scoringCriteria, rankedScoreIsPresent);
        const jsxList = this.convertNomineesToJsx(nominees, reviewerEmail);
        const chunkedData = jsxList && jsxList.length > 0 ?
            chunk(jsxList, rowsPerPage || jsxList.length) : [{}];

            return <PaginationWrapper
            persist
            totalPageCount={chunkedData.length}
            rowsPerPageOptions={[10, 20, 30]}
            rowsPerPage={rowsPerPage}
            updateDataIndex={this.setDataIndex}
            updateRowsPerPage={this.setRowsPerPage}
            dataIndex={dataIndex}
            resultsData={{ shownResults: nominees.length, totalResults: nominees.length }}
            showOptionalCount={true}
        >

            <Table sortable hover={false}

                paginationData={{
                    rowsPerPage: rowsPerPage || 9999,
                    // TODO: Fix this in a more elegant way
                    dataIndex: dataIndex,
                }}
                defaultUnsorted={!nominees[0] || !nominees[0].rankedScore || Number(nominees[0].rankedScore) <= 0 }
                defaultSortColumn={dataKeys.length - 1} defaultSortDirection={"desc"}
                headerVariant="subtitle2" data={jsxList} dataKeys={dataKeys} />
        </PaginationWrapper>
    }
}

EvaluationList.propTypes = {
    reviewerEmail: PropTypes.string,
    nominees: PropTypes.array,
    scoringCriteria: PropTypes.arrayOf(PropTypes.shape({
        field: PropTypes.string.isRequired,
        evaluationGuideLines: PropTypes.arrayOf(PropTypes.string).isRequired,
        ranges: PropTypes.object
    })).isRequired,
    tabIdentifier: PropTypes.shape({
        index: PropTypes.number,
        type: PropTypes.string
    })
};

export default EvaluationList