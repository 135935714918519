import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import NominationsCardAwardEligibility from "../components/AwardEligibility";
import { updateNominationCardForm } from "../../../actions-index";

const mapStateToProps = (state = {}, props = {}) => {
    const { nomineeCard = {}, awardInfo = { data: { awardResultInfo: { awardResults: [] }}} } = state
    let nominatedFor;
    if(nomineeCard.form && (nomineeCard.form.nominatedFor || nomineeCard.form.nominatedFor === "")) {
        console.log("Getting nominatedFor from FORM")
        nominatedFor = nomineeCard.form.nominatedFor
    } else if (nomineeCard.record && (nomineeCard.record.nominatedFor || nomineeCard.record.nominatedFor === "")){
        console.log("Getting nominatedFor from RECORD")
        nominatedFor = nomineeCard.record.nominatedFor
    } 

    return {
        awardName: awardInfo.data ? awardInfo.data.name : '',
        nominatedFor: !!nomineeCard.form && !!nomineeCard.form.nominatedFor ? nomineeCard.form.nominatedFor : nominatedFor
    };
};

const mapDispatchToProps = (dispatch) => ({
    saveChanges: (changes) => dispatch(updateNominationCardForm(changes))
}); 

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NominationsCardAwardEligibility));
