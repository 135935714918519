import React from 'react'
import { Table, Body2, Icon, Caption, OSUButton } from 'osu-react-components';
import CommentFeed from '../../../Common/CommentFeed/components';
import PropTypes from 'prop-types';
import NomineeReviewInformation from '../../components/Information';
const _ = require("lodash");

function EvaluationDetails(props) {
    const { order, reviewers = [], nomineeId, scoringCriteria, hideEvaluation, hideNomineeInfo, maskReviewers, shortPlan } = props;
    const displayedCriteria = _.cloneDeep(scoringCriteria);
    const sortedReviewers = Array.isArray(reviewers) ?
        Array.isArray(order) ?
            order.map(key => {
                return reviewers.find(o => o.type === key)
            }) :
            _.orderBy(reviewers, "type") :
        reviewers;
    const expandedDataKeys = generateKeys(sortedReviewers, maskReviewers, hideNomineeInfo, nomineeId, shortPlan);
    const determineNumber = (val) => typeof val === 'string' || (typeof val === 'number' && val > 0) ? val : '-';

    let finalWeightedScoreExists = false;
    let rankedScoreExists = false;

    displayedCriteria.forEach(criteria => {
        if (criteria.field === 'finalWeightedScore') {
            finalWeightedScoreExists = true;
        }
        if (criteria.field === 'rankedScore') {
            rankedScoreExists = true;
        }
    });

    if (!finalWeightedScoreExists) {
        displayedCriteria.push({
            name: !rankedScoreExists && !props.maskReviewers ? "Weighted Score" : <div className="font-weight-bold">Weighted Score</div>,
            field: "finalWeightedScore",
            minimumEligibilityScore: 0,
            ranges: displayedCriteria[0].ranges
        });
    }
    if (!rankedScoreExists && !props.maskReviewers) {
        displayedCriteria.push({
            name: <div className="font-weight-bold">Ranked Score</div>,
            field: "rankedScore",
            minimumEligibilityScore: 0,
            ranges: displayedCriteria[0].ranges
        });

    }

    const reviewersWithRankedAndWeightedScore = addRankedAndWeightedScoresRows(sortedReviewers);

    const toFixed = (num, fixed) => {
        var re = new RegExp('^-?\\d+(?:.\\d{0,' + (fixed || -1) + '})?');
        return num.toString().match(re)[0];
    }

    const data = displayedCriteria.map(criteria => {


        const buildCriteriaAndFinalScoreDataJsx = () => {
            // const criteriaWeightedScore = props[criteria.field] || "-";
            let criteriaWeightedScore = {value: 0, flag: false};

            if (criteria.field === "rankedScore") {
                return {
                    name: <Body2>{criteria.name}</Body2>,
                    finalScore: <Body2 className="d-flex justify-content-center">
                        {determineNumber(Number (props.finalRankedScore))}
                    </Body2>
                }
            }


            props.reviewers.forEach(reviewer => {
                if(criteria.field === "finalWeightedScore"){
                    criteriaWeightedScore.value += reviewer[criteria.field];
                } else {
                    if(reviewer[criteria.field].value && criteriaWeightedScore.value !== "-"){
                        criteriaWeightedScore.value += Number (reviewer[criteria.field].value);
                    } else {
                        criteriaWeightedScore.value = "-";

                    }
                    criteriaWeightedScore.flag = criteriaWeightedScore.flag || reviewer[criteria.field].flag;
                }
            });
            criteriaWeightedScore.value = Number (toFixed((criteriaWeightedScore.value && typeof criteriaWeightedScore.value === "number" ? criteriaWeightedScore.value/props.reviewers.length : 0), 2));

            const warning = criteriaWeightedScore && criteriaWeightedScore.value !== 0 && criteriaWeightedScore.value < criteria.minimumEligibilityScore;

            return {
                name: <Body2>{criteria.name}</Body2>,
                finalScore: <Body2 className="d-flex justify-content-center">
                    {determineNumber(criteriaWeightedScore.value)}
                    {warning && <Caption color="gray">
                        <Icon className={`pl-2 pr-1`} color="gray" type="info" />
                        ({criteria.minimumEligibilityScore} min)
                    </Caption>}
                </Body2>
            }
        };

        const totalCriteriaRowWithAllScores = reviewersWithRankedAndWeightedScore.map(review => {
            const score = review.hasOwnProperty(criteria.field) ? review[criteria.field] : null;
            return {
                ...buildCriteriaAndFinalScoreDataJsx(),
                [review.type]: <Body2>{determineNumber(score.value)}</Body2>
            }
        });

        const reducedScores = totalCriteriaRowWithAllScores.reduce(function (result, current) {
            return Object.assign(result, current);
        }, {});

        return reducedScores
    });

    const comments = Array.isArray(reviewersWithRankedAndWeightedScore) ? reviewersWithRankedAndWeightedScore.filter(item => (item.comment && typeof item.comment === 'string' && item.comment.trim()) || (item.lastEditedBy && item.lastEditTime)).map(reviewer => {
        let message = "Last reviewed by " + reviewer.lastEditedBy + " on " + reviewer.lastEditTime;
        if (!reviewer.lastEditedBy || reviewer.lastEditedBy.match(reviewer.email)) {
            message = ""
        }
        if (message !== "" || reviewer.comment) {
            return {
                author: reviewer.name,
                text: reviewer.comment,
                message: message
            }
        } else {
            return [];
        }

    }).flat() : [];
    return <div className="px-4 py-3" >
        {!hideNomineeInfo && <NomineeReviewInformation className="d-flex flex-column flex-md-row" nomineeReview={props} showfileArray={false} />}
        {!hideNomineeInfo && <hr />}
        {!hideEvaluation && <Table rowHeight={1} headerVariant="subtitle2" hover={false} border={false} data={data} dataKeys={expandedDataKeys} />}
        {!hideEvaluation && <hr />}
        {<CommentFeed key={`comments${nomineeId}section`} comments={comments} />}
    </div>
}


function addRankedAndWeightedScoresRows(sortedReviewers) {

    return sortedReviewers.map(reviewer => {
        return ({
            ...reviewer,
            finalWeightedScore: {
                value: reviewer.finalWeightedScore,
                flag: true,
            },
            rankedScore: {
                value: reviewer.rankedScore,
                flag: true,
            }
        });
    });

}

function generateKeys(reviewerScores = [], maskReviewers = false, hideNomineeInfo, nomineeId, shortPlan) {
    let expandedDataKeys = (arr = []) => [
        {
            key: 'name',
            label: 'Criteria',
            width: arr.length > 0 ? 30 : 85
        },
        ...arr,
        !maskReviewers && {
            key: 'finalScore',
            label: 'Average',
            width: 15,
            className: 'text-center'
        }
    ];
    const formattedReviewers = reviewerScores.length > 0 ? reviewerScores.map(review => {
        let icon = null;
        // review.rankedScore != 0 is intentional I beielve to deal with string "0"
        // eslint-disable-next-line eqeqeq
        if (review.rankedScore && review.rankedScore != 0 && review.rankedScore !== "-") icon = (<Icon type="check" color="green" size="xs" className="ml-1" ariaLabel="Reviews are complete and in range." />);
        // if(review.validQuintile === false) icon = (<Icon type="exclamation-triangle" color="red" size="xs" className="ml-1" ariaLabel="Scores out of assigned ranges." />);

        let labelContent = hideNomineeInfo ? (<a href={`mailto:${review.email}`} aria-label={`E-mail ${review.name} at ${review.email}`} >{review.email.substring(0, review.email.indexOf('@'))}</a>)
            : (<OSUButton className={'p-0 text-left'} uppercase={false}
                variant={'body1'}
                ariaLabel={`Navigate to evaluation for `}
                path={{
                    pathname: `/nominee-reviews/evaluation/${nomineeId}/${shortPlan}`,
                    state: {
                        tabIdentifier: { index: 0, type: "scoresheet", title: "My Reviews" },
                        userAdminActingOnBehalfOf: review
                    }
                }}>{review.name}</OSUButton>);
        // if (maskReviewers) {
        //     switch (review.type) {
        //         case "PRIMARY":
        //             labelContent = "Composite Reviewer";
        //             break;
        //         case "SECONDARY1":
        //             labelContent = "Reviewer 1";
        //             break;
        //         case "SECONDARY2":
        //             labelContent = "Reviewer 2";
        //             break;
        //         default:
        //             labelContent = "Reviewer"; // shouldn't happen
        //     }
        // }
        let label = (<span>{labelContent}{icon}</span>);
        return {
            key: review.type,
            label: label,
            width: 55 / reviewerScores.length,
            className: 'text-center'
        }
    }) : [];

    return expandedDataKeys(formattedReviewers, hideNomineeInfo);
}

export default EvaluationDetails

EvaluationDetails.defaultProps = {
    hideEvaluation: false,
    hideNomineeInfo: true
};

EvaluationDetails.propTypes = {
    hideEvaluation: PropTypes.bool,
    reviewers: PropTypes.array.isRequired,
    scoringCriteria: PropTypes.arrayOf(PropTypes.shape({
        field: PropTypes.string.isRequired,
        evaluationGuideLines: PropTypes.arrayOf(PropTypes.string).isRequired,
        ranges: PropTypes.object
    })).isRequired,
    nomineeId: PropTypes.string
};