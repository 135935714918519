import React, { useContext } from "react";
import PropTypes from 'prop-types'
import Select from "react-select";
import { FormGroup, Label } from "reactstrap";
import useAwardDropdown from "../hooks/useAwardDropdownOptions";
import { FormEnvironmentContext } from "../context";

const matchValueToOptions = (options, value) =>{
  return options.find((opt) => opt.value === value);
}
function AwardSelect(props = {}) {
  const { label, value, onChange, id, selectProps, ...rest } = props;
  const { loading, nominatedForOptions } = useAwardDropdown();
  const { readonly } = useContext(FormEnvironmentContext)

  return (
    <FormGroup {...rest}>
      <Label for={id}>{label}</Label>
      <Select
        value={matchValueToOptions(nominatedForOptions, value)}
        onChange={(selection) => onChange(selection.value)}
        isLoading={loading}
        id={id}
        options={nominatedForOptions}
        {...selectProps}
        isDisabled={selectProps.isDisabled || readonly}
      />
    </FormGroup>
  );
}

AwardSelect.defaultProps = {
  selectProps: {}
}

AwardSelect.propTypes = {
    selectProps: PropTypes.object,
    label: PropTypes.string,
    value: PropTypes.string, 
    id: PropTypes.string, 
    onChange: PropTypes.func
}

export default AwardSelect;
