import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import ScoreSheetContent from "../components";
import { searchScoreSheet, addNomineeListFilter, getNomineeReviewList, calculateRankedScores, updateNomineeReviewListSortKey, clearNomineeReview } from '../../../actions-index'
import { map, intersectionBy } from 'lodash'

const mapStateToProps = (state, ownProps) => {
    const { nomineeReview, nomineeReviewScoreSheetList, systemAward, scoringCriteria } = state
    const { filters = [], list = [], status = "", query = "" } = nomineeReviewScoreSheetList

    const gpaTypes = systemAward && systemAward.nominationInfo && Array.isArray(systemAward.nominationInfo.typeOfGPA) ? systemAward.nominationInfo.typeOfGPA : []
    const weightedScoreTypes = [{
        description: "Weighted Score",
        field: "finalWeightedScore",
    }];

    let gpaRanges = [
        { min: 1, max: 20},
        { min: 20.01, max: 40},
        { min: 44, max: 60},
        { min: 60.01, max: 80},
        { min: 80.01, max: 100},

    ]

    if(systemAward && systemAward.reviewInfo && systemAward.reviewInfo.rubric.scores[0]) {
        gpaRanges = [];
        for(const prop in systemAward.reviewInfo.rubric.scores[0].ranges) {
            gpaRanges.push(systemAward.reviewInfo.rubric.scores[0].ranges[prop]);
        }
    }

    return {
        systemAward: state.systemAward,
        query,
        nomineeReview: nomineeReview,
        nomineeReviewList: list,
        nomineeListStatus: status,
        filters,
        reviewerStats: nomineeReviewScoreSheetList.reviewerStats,
        scoreSheetCSVHeaders: scoreSheetHeaders(scoringCriteria, gpaTypes),
        scoringCriteria,
        awardKey: systemAward.pk,
        scoringCriteriaOptions: Array.isArray(scoringCriteria) ? createScoringCategoryFilters(scoringCriteria, filters) : {},
        gpaOptions: Array.isArray(gpaTypes) && gpaTypes.length > 0 ? createGpaOptions(gpaTypes, filters) : {},
        weightedScoreFilterOptions: Array.isArray(gpaTypes) && gpaTypes.length > 0 ? createWeighedScoreFilterOptions(weightedScoreTypes, filters, gpaRanges) : {},
        nominationOptions: systemAward && systemAward.awardResultInfo && Array.isArray(systemAward.awardResultInfo.awardOptions) && systemAward.awardResultInfo.awardOptions.length > 1 ? createNominationOptions(systemAward.awardResultInfo.awardOptions, filters) : {},
        sortKey: state.sortKey,
        tabIdentifier: {
            index: ownProps.tabIndex,
            type: 'scoresheet',
            title: ownProps.title
        },
    };
};

const scoreSheetHeaders = (scoringCriteria = [], gpaTypes) => {
    const scoringCriteriaCols = Array.isArray(scoringCriteria) ? scoringCriteria.map(criteria => ({
        label: criteria.name,
        key: `${criteria.field}.value`
    })) : []

    const gpaCols = Array.isArray(gpaTypes) ? gpaTypes.map(type => ({
        label: type.description,
        key: type.field
    })) : []

    const columns = [
        { label: "Name", key: "name" },
        { label: "Nominee ID", key: "nomineeId" },
        { label: "Academic Program", key: "displayShortPlan" },
        { label: "Advisor", key: "advisorName" },
        { label: "GPA Waiver", key: "gpaWaiver" },
        ...gpaCols,
        ...scoringCriteriaCols,
        { label: "Nominations", key: "nominatedFor" },
        { label: "Weighted Score", key: "finalWeightedScore" },
        { label: "Ranked Score", key: "rankedScore" },

    ]

    return columns
}

const matchingFilters = ({ filters = [], type, key, scoring = false, min, max }) => filters.filter(item =>
    item.type === type && key === item.key
    && (scoring ? min === item.min && max === item.max : true)
)
const groupedNumericFilters = ({ filters, options, type }) => {
    const matchingFields = intersectionBy(filters, options, 'key')
    const matchingOptions = []

    if (options) {
        options.filter(opt => opt.options).forEach(opt => {
            opt.options.forEach(item => {
                const { key, min, max } = item.value
                if (matchingFilters({ filters: matchingFields, type, key, scoring: true, min, max }).length > 0) {
                    matchingOptions.push(item)
                }
            })
        })
    }

    return matchingOptions
}

const createNominationOptions = (list = [], filters = []) => {
    const type = 'awardOptions'
    const matchingValues = []

    const options = list.filter(opt => opt.attributes).map(opt => {
        const { awardResultABBV, AwardResultName } = opt.attributes
        return {
            value: {
                singleSelect: true,
                type: 'awardOptions',
                key: awardResultABBV
            },
            label: AwardResultName
        }
    })
    options.filter(opt => opt.value).forEach(opt => {
        const { value } = opt

        if (matchingFilters({ filters, key: value.key, type }).length > 0) {
            matchingValues.push(opt)
        }
    })

    return {
        type,
        options,
        value: matchingValues
    }
}

const createGpaOptions = (list, filters) => {
    const type = 'gpa'
    const options = list.map(gpa => {
        const gpaRanges = [
            { min: 4, max: 4},
            { min: 3.800, max: 3.999 },
            { min: 3.600, max: 3.799 },
            { min: 3.400, max: 3.599 },
            { min: 3.200, max: 3.399 },
            { min: 3.000, max: 3.199 },
            { min: 0.000, max: 2.999 },

        ];
        return {
            label: gpa.description,
            key: gpa.field,
            options: gpaRanges.map(range => ({
                value: {
                    singleSelect: true,
                    type,
                    key: gpa.field,
                    ...range,
                },
                label: range.min === range.max ? `${range.max}` : `${range.min} - ${range.max}`
            }))
        }
    })

    return {
        type,
        options,
        value: groupedNumericFilters({ filters, options, type })
    }
}

const createWeighedScoreFilterOptions = (list, filters, gpaRanges) => {
    const type = 'finalWeightedScore';
    
    const options = list.map(gpa => {
        return {
            label: gpa.description,
            key: gpa.field,
            options: gpaRanges.map(range => ({
                value: {
                    singleSelect: true,
                    type,
                    key: gpa.field,
                    min: range.min - .99, //min is .99 lower than the display value so that our filters capture all possible values, without us needing to display [0,20] [20.01,40], et cetera
                    max: range.max,
                },

                label: range.min === range.max ? `${range.max}` : `${range.min} - ${range.max}`
            }))
        }
    })

    return {
        type,
        options,
        value: groupedNumericFilters({ filters, options, type })
    }
}


const createScoringCategoryFilters = (list = [], filters) => {
    const type = 'rubric'

    const categoryOptions = Array.isArray(list) ? list.map(criteria => {
        const mappedScores = map(criteria.ranges, (val, key) => {
            return {
                label: criteria.name + ` (${key})`,
                value: {
                    singleSelect: true,
                    type,
                    key: criteria.field,
                    min: val.min,
                    max: val.max
                }
            }
        })

        return {
            label: criteria.name,
            key: criteria.field,
            options: mappedScores
        }
    }) : []

    return {
        type,
        options: categoryOptions,
        value: groupedNumericFilters({ filters, options: categoryOptions, type })
    }
}


const mapDispatchToProps = (dispatch) => {
    return {
        searchScoreSheet: (query) => dispatch(searchScoreSheet(query)),
        getNomineeReviewList: (awardKey, sortKey) => dispatch(getNomineeReviewList(awardKey, sortKey)),
        calculateRankedScores: (awardKey) => dispatch(calculateRankedScores(awardKey)),
        addNomineeListFilter: (filter) => dispatch(addNomineeListFilter(filter)),
        updateNomineeReviewListSortKey: (values) => dispatch(updateNomineeReviewListSortKey(values)),
        clearNomineeReview: () => dispatch(clearNomineeReview())
    }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ScoreSheetContent));

