import React from 'react';
import { Table, Body2, Icon, OSULoading, OSUError, OSUButton, PaginationWrapper } from 'osu-react-components';
import PropTypes from 'prop-types';
import { chunk, find, uniqBy } from 'lodash';
import EvaluationDetails from '../../Evaluation/components/Details';
import { Row, Input, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap'; 
import Select from 'react-select';
import { Link } from "react-router-dom";
import EllipsisWithTooltip from 'react-ellipsis-with-tooltip';
import '../ResultsContent.css';
import * as Globals from '../../../constants';
import moment from "moment";
import IconLegend from "../../components/IconLegend";
import { redirectToLogOut } from "../../../util/util";
import { Auth } from "aws-amplify";

const FINAL_WEIGHTED_SCORE_WIDTH = 20;




class ResultsContent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {

            listOfSelectedNominees: [],
            refreshIds: [],
            rowsPerPage: 10,
            dataIndex: 0,
            expandAll: false,
            processingFinalRankedScores: true
        };
        this.refreshDocs = this.refreshDocs.bind(this);
        this.finalWeightedScoreSelectRef = React.createRef();
    }

    isAfterMeetingStartTime(){
        return moment().isAfter(moment(this.props.systemAward.reviewCommitteeMeetingStartDate, "YYYY-MM-DDTHH:mm:ssZ"));
    } 
    //TODO: REMOVE THE ADDITION OF THE FOUR HOURS ONCE THE GRAD SCHOOL HAS DIRECT ACCESS TO SET DATES VIA ADMIN PANEL
    isBeforeMeetingEndTime() {
        return moment().isBefore(moment(this.props.systemAward.reviewCommitteeMeetingEndDate, "YYYY-MM-DDTHH:mm:ssZ").add(4, 'hours'));
    }
    

    componentDidMount() {
        this.props.awardKey && this.getAllNominees(this.props.awardKey)

        this.props.awardKey && this.getAwardInfo(this.props.awardKey);
    }

    getAllNominees(awardKey) {
        const key = 'name';
        const direction = 'asc';

        this.props.getAllNominees(awardKey, { key, direction });
    }

    dataKeys = (gpaTypes) => {

        const gpaDataKeys = Array.isArray(gpaTypes) ? gpaTypes.map(type => (
            {
                key: type.field,
                label: type.description,
                className: 'align-self-end text-center',
                width: 20 / gpaTypes.length
            }
        )) : [];

        const doesFinalWeightedScoreExist = this.props.showWeightedScore || (this.isAfterMeetingStartTime() && this.isBeforeMeetingEndTime());
        const weightedScoreAdjustmentValue = doesFinalWeightedScoreExist ? FINAL_WEIGHTED_SCORE_WIDTH : 0;
        const numberSortFormatter = (val) => (isNaN(val) ? 0 : Number(val));
        const keys = Globals.MOBILE_MAXIMUM_RESOLUTION < window.innerWidth ? [
        {
            key: 'selected',
            label: 'Selected',

            className: 'align-self-end min-width-0',
            width: 10 - (weightedScoreAdjustmentValue * .25) // 10 or 5
        },     
        {
            key: 'name',
            label: 'Name',

            className: 'align-self-end min-width-0',
            width: 30 - (weightedScoreAdjustmentValue * .5) // 30 or 20
        }, 
        ...gpaDataKeys, // 20 total
        ...doesFinalWeightedScoreExist ? [{
            key: 'finalRankedScore',
            label: 'Final Ranked Score',
            className: 'align-self-end text-center',
            width: FINAL_WEIGHTED_SCORE_WIDTH, // 0 or 20
            sortFormatter: numberSortFormatter
        }] : [],
        {
            key: 'nominatedFor',
            label: 'Nomination',
            className: 'align-self-start text-center',
            width: 20 - (weightedScoreAdjustmentValue * .125) // 15 or 17.5
        },
        {
            key: 'awarded',
            label: 'Awarded',
            className: 'align-self-start text-center',
            width: 20 - (weightedScoreAdjustmentValue * .125) // 15 or 17.5
        }
    ]


            :
            [{
                key: 'name',
                label: "Name",

                className: 'align-self-start',
                width: 70
            }
            ]
            ;
        return keys
    };

    componentDidUpdate(event) {
        if (this.state.refreshIds && this.props.docList) {
            let refIds = this.state.refreshIds;
            let docLst = this.props.docList;
            refIds = refIds.filter(ref => ref !== docLst.nomineeId);
            if (this.state.refreshIds.length !== refIds.length) {
                let docUpdates = [];
                docUpdates.push(docLst);
                if (this.state.docUpdates) {
                    docUpdates = docUpdates.concat(this.state.docUpdates);
                }

                this.setState({
                    refreshIds: refIds,
                    docUpdates: docUpdates
                })
                // this.componentDidMount();
            }
        }

        // set aria-label on Final Weighted Score filter dropdown (cannot set directly when isSearchable=false)
        if (this.finalWeightedScoreSelectRef.current) {
            var finalWeightedScoreSelectInputRef = this.finalWeightedScoreSelectRef.current.select.inputRef;
            if (!finalWeightedScoreSelectInputRef.getAttribute("aria-label")) {
                finalWeightedScoreSelectInputRef.setAttribute("aria-label", "Filter table by Final Weighted Score");
            }
        }

        // when status is successful, clear the selected award to grant and the selected nominees
        if(event.status === "loading" && this.props.status === "success") {
            this.setState({ selectedAwardToGrant : null, listOfSelectedNominees: [] });
        }
    }

    refreshDocs(nomineeId, shortPlan, awardKey, applicationNumber) {
        console.log("refreshing: " + nomineeId + ",applicationNumber: " + applicationNumber);

        this.props.refreshDocumentList(nomineeId, shortPlan, awardKey, applicationNumber);

        let refreshingIds = this.state.refreshIds;
        if (!refreshingIds.includes(nomineeId)) {
            refreshingIds.push(nomineeId);
            this.setState(
                {
                    refreshIds: refreshingIds
                }
            )
        }
    }

    convertedData(nominees = [], nomineesDetails = []) {
        if (!Array.isArray(nominees)) {
            return []
        }
        const jsxNominees = nominees.map((nom, index) => {
            const hasComments = Array.isArray(nom.reviewers) ? nom.reviewers.filter(review => review.comment && review.comment.toString().trim()).length > 0 : false;
            const nomGpas = Array.isArray(this.props.gpaTypes) ? this.props.gpaTypes.filter(gpa => !!gpa.field).map((gpa, index) => {
                return {
                    [gpa.field]: nom.hasOwnProperty(gpa.field) ? nom[gpa.field] : '-'
                }
            }) : [];

            const expansionAction = (index) => this.props.updateNomineesDetails({ ...nom, index })
            let match = find(nomineesDetails, detail => detail.nomineeId === nom.nomineeId && detail.displayShortPlan === nom.displayShortPlan)
            if (!match && nomineesDetails) {
                match = nom;
            }
            let fileArray = [];
            let docUpdate = null;
            if (this.state.docUpdates) {
                docUpdate = this.state.docUpdates.find(function (upd) {
                    return upd.nomineeId === nom.nomineeId;
                })
            }
            if (docUpdate && docUpdate.documentData && docUpdate.documentData.uploadedFile) {
                if (Array.isArray(nom.uploadedFile)) {
                    nom.uploadedFile = nom.uploadedFile.concat(docUpdate.documentData.uploadedFile);
                }
                else {
                    nom.uploadedFile = docUpdate.documentData.uploadedFile;
                }
                nom.uploadedFile = uniqBy(nom.uploadedFile, 'documentId');
            }

            if (this.props.isAdmin) {
                var refIcon = <i className={this.state.refreshIds.includes(nom.nomineeId) ? "fa fa-refresh fa-spin" : "fa fa-refresh"} aria-hidden="true" onClick={() => this.refreshDocs(nom.nomineeId, nom.shortPlan, this.props.awardKey, nom.applicationNumber)}></i>
            }
            let awarded = "";
 
            const award = this.props.getCurrentAwardInfoResult;
            if(award && award.awardResultInfo && award.awardResultInfo.awardResults) {
                award.awardResultInfo.awardResults.map(awardResult => {
                    const nomineeSK = "NOMINEE#" + nom.nomineeId + "#" + nom.shortPlan;
                    if(awardResult.nomineeSK === nomineeSK){
                        awarded = awardResult.awardResultABBV;
                    }
                    return nomineeSK;
                })
            }
            if (Array.isArray(nom.uploadedFile) && nom.uploadedFile.every(file =>  file.documentId && file.documentId > 0 )) {
                nom.uploadedFile.map((file, index) => {
                    const documentId = file.documentId.toString();
                    return fileArray.push(
                        <div key={documentId}>
                            <Link className='documentFlex' target={documentId} rel="noopener noreferrer" to={{
                                pathname: "/ViewPdf/" + documentId,
                                search: `?name=${nom.name}&plan=${nom.shortPlanDescription || nom.shortPlan}`
                            }}>{file.documentName}
                            </Link>
                        </div>);
                }
                );
            }
            fileArray = <div className='outerRefresh'>{refIcon && refIcon}<div className={refIcon && 'innerRefresh'}>{fileArray}</div></div>;
            const finalRankedScore = nom.hasOwnProperty('finalRankedScore') ? nom.finalRankedScore.toString().substring(0, 6) : '-';
            const rankedScoresAreAllPresentForEveryReviewer = (reviewer) => reviewer.rankedScore !== 0 && reviewer.rankedScore !== undefined;
            // const hasScoreRangeFlag = ((this.props.isAdmin === true || this.props.showWeightedScore === true) && nom.reviewers.length > 0 && typeof find(nom.reviewers, reviewer => reviewer.validQuintile === false) !== "undefined");
            const hasScoreRangeCheck = ((this.props.isAdmin === true || this.props.showWeightedScore === true)  && nom.reviewers.length > 0 && nom.reviewers.every(rankedScoresAreAllPresentForEveryReviewer));
            let hasScoreMinFlag = false;
            const isAdmin = this.props.isAdmin;
            const isAfterMeetingStartTime = this.isAfterMeetingStartTime();
            const isBeforeMeetingEndTime = this.isBeforeMeetingEndTime();

            this.props.scoringCriteria.forEach(criteria => {
                let average = 0;
                let everyReviewerHasDataPresent = true;
            nom.reviewers.forEach(reviewer => {
                if(reviewer[criteria.field]){
                    const value =  Number (reviewer[criteria.field].value)
                    average += value;
                    everyReviewerHasDataPresent = value > 0 ? everyReviewerHasDataPresent : false;
                } else {
                    const value = Number (reviewer.rankedScore)
                    average += value;
                    everyReviewerHasDataPresent = value > 0 ? everyReviewerHasDataPresent : false;

                }
                

                });
                if((isAdmin || (isAfterMeetingStartTime && isBeforeMeetingEndTime) ) && everyReviewerHasDataPresent && average / nom.reviewers.length < criteria.minimumEligibilityScore) {
                    hasScoreMinFlag = true
                }
            });

            const isDisabled = (nominee, listOfSelectedNominees) => {
                const maxSelectedRows = 100;
                const tooManyReviewersSelected = this.state.listOfSelectedNominees.length >= maxSelectedRows;
                let nomineeIsNotCurrentlySelected = true;
                listOfSelectedNominees.forEach(selectedNominee =>{
                    if(selectedNominee.nomineeId === nominee.nomineeId){
                        nomineeIsNotCurrentlySelected = false;
                    }
                })
                return tooManyReviewersSelected && nomineeIsNotCurrentlySelected;
            }
            let lastEditedByAdmin = false;

            nom.reviewers.forEach(reviewer => {
                if (reviewer.lastEditedBy && !reviewer.lastEditedBy.match(reviewer.email)) {
                    lastEditedByAdmin = true;
                }
            });
            return {
                innerExpandedAction: expansionAction,
                selected: <div style={{ 'textAlign': 'left' }} ><label htmlFor={`select-${nom.emplid}`} className="sr-only">{`Select ${nom.name}`}</label>
                <input id={`select-${nom.emplid}`} type="checkbox" onChange={event => this.addToSelectedNomineeList(event, nom)} disabled={isDisabled(nom, this.state.listOfSelectedNominees)} >
                </input></div>,

                name: <div style={{ wordWrap: "break-word" }}>
                    {nom.name}
                    {/* {hasScoreRangeFlag && <sup><Icon type="exclamation-triangle" color="red" size="xs" className="px-1" ariaLabel="Scores out of assigned ranges." /></sup>} */}
                    {hasScoreRangeCheck && <sup><Icon type="check" color="green" size="xs" className="px-1" ariaLabel="Reviews are complete and in range." /></sup>}
                    {hasScoreMinFlag && <sup><Icon size="xs" className="pl-1" type="info" color="gray" ariaLabel="Category minimum qualifying score not met." /></sup>}
                    {hasComments && <sup><Icon size="xs" className="pl-1" type="commenting-o" color="gray" ariaLabel="Comments available to view." /></sup>}
                    {lastEditedByAdmin && <sup><Icon size="xs" className="pl-1" type="asterisk" color="black" ariaLabel="Admin has made a change on your behalf" /></sup>}

                </div>,
                displayShortPlan: <EllipsisWithTooltip placement="bottom">{nom.displayShortPlan}</EllipsisWithTooltip>,
                nominatedFor:nom.nominatedFor,
                ...nomGpas.reduce(function (result, current) {
                    return Object.assign(result, current);
                }, {}),
                finalRankedScore: finalRankedScore,
                awarded:awarded,
                expandedContent: (<EvaluationDetails {...match} hideNomineeInfo={false} finalRankedScore={finalRankedScore} hideEvaluation={!this.props.isAdmin && !isAfterMeetingStartTime} reloadAction={expansionAction} nomineeId={nom.nomineeId} scoringCriteria={this.props.scoringCriteria} maskReviewers={!this.props.isAdmin && !isAfterMeetingStartTime} />),
                documents: fileArray
            }
        })

        return jsxNominees
    }

    addToSelectedNomineeList(event, selectedNominee) {

        let newNomineeList = this.state.listOfSelectedNominees;
        const nomineeIdList = this.state.listOfSelectedNominees.map(nomineeFromList => {
            return nomineeFromList.nomineeId;
        })
        if(nomineeIdList.includes(selectedNominee.nomineeId)){
            newNomineeList.forEach((nom, index) => {
                if (nom.nomineeId === selectedNominee.nomineeId) {
                    if(nomineeIdList.length === 1){
                        newNomineeList = [];
                    } else{
                        newNomineeList.splice(index,1);

                    }
                } 
            })
        } else {
            newNomineeList.push(selectedNominee);
        }
        this.setState({listOfSelectedNominees:newNomineeList})
    }

    async calculateFinalRankedScoresWithModal(awardKey) {
        this.setState({ isOpen: true });

        await this.props.calculateFinalRankedScores(awardKey)
        // this.setState({ processingFinalRankedScores: false, isOpen: true, scoresNeedUpdated: false, updatedData: await data });
        
    }

    async getAwardInfo(awardKey) {
        await this.props.getCurrentAwardInfo(awardKey)
    }

    unsuccessfulScreen(status) {
        const errorScreen = this.props.awardKey ?
            <OSUError ariaLabel="Error getting nominees, click to retry getting nominees" text="Could not fetch nominees for award." actionText="Retry" onClick={() => this.getAllNominees(this.props.awardKey)} />
            : <OSUError ariaLabel="No award found. Navigate to new view for award selection." text="Could not verify your award." actionText="Select Award" onClick={() => this.props.history.push('/nominee-reviews/award-selection')} />;

        //console.log("refreshing status");
        //console.log(this.state.refreshing);
        if (status === 'loading') {
            return <OSULoading text="Updating Award Status of Selected Nominees, Please wait." />
        }
        if (status === '401') {
            Auth.signOut() && redirectToLogOut();
            //return <OSUError ariaLabel="You are logged out. Close your browser and login again." text="You are logged out. Close your browser and login again." actionText="Login" onClick={() => redirectToLogIn()} />
        }

        return errorScreen
    }

    displayWeightedScoreFilter(handleFilter, handleFilterProps, defaultScoreValue, weightedScoreRanges) {
        if (window.innerWidth > Globals.MOBILE_MAXIMUM_RESOLUTION && this.props.isAdmin) {
            return (<div className="col-4 px-0 d-flex">
                <Select placeholder="Final Ranked Score" ref={this.finalWeightedScoreSelectRef} isClearable value={defaultScoreValue} isSearchable={false} className="w-100 pr-2" onChange={range => handleFilter({ ...handleFilterProps, filter: range, type: "rubric" })} options={weightedScoreRanges} />
            </div>)
        }
        return undefined;
    }

    displayNominationsFilter(handleFilter, handleFilterProps, defaultScoreValue, data) {
        if (window.innerWidth > Globals.MOBILE_MAXIMUM_RESOLUTION && this.props.isAdmin) {
            return (<div className="col-2 px-0 d-flex">
                <Select isClearable value={this.props.defaultShortPlanValue} className="w-100 pr-2" aria-label="Nominations" placeholder="Nominations" onChange={program => handleFilter({ ...handleFilterProps, filter: program, type: "nominatedFor" })} options={data} />
            </div>)
        }
        return undefined;
    }

    grantAwardToSelected(handleFilter, handleFilterProps, defaultScoreValue, data) {
        if (window.innerWidth > Globals.MOBILE_MAXIMUM_RESOLUTION && this.props.isAdmin) {
            const options = [];
            for(const item of data) {
                options.push({ label: `Award ${item.label}`, value: item.value.key})
            }
            options.push({ label: "Not Awarded", value: "NOT_AWARDED_SELECTED" });
            options.push({ label: "Remove", value: "REMOVE_SELECTED" });
            options.push({ label: "Unaward", value: "UNAWARD_SELECTED" });
            return (
                <div className="col-6 px-0 d-flex">
                    <Select isClearable value={this.state.selectedAwardToGrant || ""} className="w-100 pr-2" aria-label="Update Status of Selected..." placeholder="Update Status of Selected..." onChange={event => this.setSelectedAwardToGrant(event)} options={options} />
                    <div className="col-4">
                        <OSUButton uppercase={false} disabled={!this.state.selectedAwardToGrant || this.state.listOfSelectedNominees.length < 1} ariaLabel="Update Status" onClick={event => this.triggerAwardGrant(this.state.selectedAwardToGrant)}>Update Status</OSUButton>
                    </div>
                </div>
            );
        }
        return undefined;
    }

    setSelectedAwardToGrant(event) {
        this.setState({selectedAwardToGrant : event})
    }

    triggerAwardGrant(selectedAward) {
        this.props.grantAwardToSelected(this.props.awardKey, this.state.listOfSelectedNominees, selectedAward.value, this.props.systemAward);
        this.setState({systemAwardNeedsRefreshed:true})
    }

    searchbarStyle() {
        return window.innerWidth > Globals.MOBILE_MAXIMUM_RESOLUTION ? "col-5 pl-0 pr-2" : "pl-0 pr-2"
    }

    setDataIndex = dataIndex => {
        this.setState({ dataIndex });
    };



    setRowsPerPage = rowsPerPage => {
        this.setState({ rowsPerPage });
    };

    expandAllContent() {
        this.setState({ expandAll: true, collapseAll: false });
    }

    collapseAllContent() {
        this.setState({ expandAll: false, collapseAll: true });
    }

    toggle = () => {
        this.setState({
            isOpen: !this.state.isOpen
        });
        window.location.reload();
    }

    render() {
        const { handleFilter, nominees, nomineesDetails, status, defaultScoreValue, gpaTypes, filters, query, weightedScoreRanges, addAwardNomineesFilter, searchAwardNominees } = this.props;
        const data = this.state.updatedData ?  this.convertedData(this.state.updatedData, nomineesDetails) : this.convertedData(nominees, nomineesDetails);
        const rowsPerPage = this.state ? this.state.rowsPerPage : this.props.rowsPerPage;
        const dataIndex = this.state.dataIndex;
        const handleFilterProps = {
            activeFilters: filters,
            filterHandler: addAwardNomineesFilter
        };
        if (this.state.expandAll || this.state.collapseAll) {
            this.setState({
                expandAll: false,
                collapseAll: false
            });
        }

        if (status !== 'success') {
            if (!this.state.refreshing) {
                if (this.state.refreshing) {
                    this.setState({
                        refreshing: false
                    })
                }
                return this.unsuccessfulScreen(status)
            }
        } else if (this.state.systemAwardNeedsRefreshed) {
            let shortSystemAward = this.props.systemAward;
            delete shortSystemAward.nominationInfo;
            this.props.setSystemAwardValues(shortSystemAward);
            this.setState({systemAwardNeedsRefreshed:false})
        }

        const chunkedData = data && data.length > 0 ?
            chunk(data, rowsPerPage || data.length) : [{}];

        let icons = [];
        const searchbarLabel = `Search by Nominee ID or Name${this.props.isAdmin ? " or Reviewer Name.#" : ""}`;

        let nominations = [];
        this.props.nominees.forEach(nominee => {
            let nomination = {};
            const nominationLabels = nominations.map(nomination => nomination.label);
            if(!nominationLabels.includes(nominee.nominatedFor)){
                    nomination = {
                        label: nominee.nominatedFor,
                        value: {
                            key: nominee.nominatedFor,
                            singleSelect: true,
                            type: "nominatedFor"
                        }
                };
                nominations.push(nomination);
            }

        });

        return <div>
            <IconLegend icons={icons} />
            <Body2 className="mb-1 align-items-bottom"><span className="pt-4">Filter Content &nbsp;&nbsp;&thinsp; | </span>
                <OSUButton uppercase={false} variant="body2" ariaLabel="Clear all filters" link onClick={() => this.props.clearAllFilters(filters, addAwardNomineesFilter, searchAwardNominees)}>Clear All</OSUButton>
                <div className="float-right"><OSUButton uppercase={false} variant="body2" ariaLabel="Collapse all inner tables" link onClick={() => this.collapseAllContent()}>Collapse All</OSUButton></div>

                <div className="float-right"><OSUButton uppercase={false} variant="body2" ariaLabel="Expand all inner tables" link onClick={() => this.expandAllContent()}>Expand All</OSUButton>|</div></Body2>
            <Row className="no-gutters align-items-center">
                <InputGroup className={this.searchbarStyle()}>
                    <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                            <Icon type="search" color="gray" />
                        </InputGroupText>
                    </InputGroupAddon>
                    <Input type="text" aria-label={searchbarLabel} placeholder={searchbarLabel} value={query} onChange={(e) => searchAwardNominees(e.target.value)} />
                </InputGroup>
                {this.displayWeightedScoreFilter(handleFilter, handleFilterProps, defaultScoreValue, weightedScoreRanges)}
                {this.displayNominationsFilter(handleFilter, handleFilterProps, defaultScoreValue, nominations)}

            </Row>

            <PaginationWrapper
                persist
                totalPageCount={chunkedData.length}
                updateDataIndex={this.setDataIndex}
                updateRowsPerPage={this.setRowsPerPage}
                dataIndex={dataIndex}
                rowsPerPageOptions={[10, 20, 30]}
                rowsPerPage={rowsPerPage}
                resultsData={{ shownResults: data.length, totalResults: data.length }}
                showOptionalCount={true}
            >
                <Table multiOpen sortable expandable expandAll={this.state.expandAll} collapseAll={this.state.collapseAll}
                    paginationData={{
                        rowsPerPage: rowsPerPage || 9999,
                        dataIndex: dataIndex,
                    }}
                    hover={false} headerVariant="subtitle2" 
                    data={data} 
                    dataKeys={this.dataKeys(gpaTypes)} />
            </PaginationWrapper>
            <br/>
            {this.grantAwardToSelected(handleFilter, handleFilterProps, defaultScoreValue, nominations)}

        </div>
    }
}

ResultsContent.defaultProps = {
    nominees: [],
    nomineesDetails: [],
    gpaTypes: [],
    dataIndex: 0
};

ResultsContent.propTypes = {
    nominees: PropTypes.array,
    nomineesDetails: PropTypes.array,
    gpaTypes: PropTypes.array,
    awardKey: PropTypes.string.isRequired,
    status: PropTypes.string,
    filters: PropTypes.array,
    query: PropTypes.string,
    scoringCriteria: PropTypes.array,
    programDropdown: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.shape({
            singleSelect: PropTypes.bool,
            type: '',
            key: ''
        })
    })),
    defaultScoreValue: PropTypes.array,
    defaultShortPlanValue: PropTypes.array
};

export default ResultsContent