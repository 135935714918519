import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import Awarded from "../components/Awarded";
import { getCurrentAwardInfo, changeAwardeeResponseStatuses, dismissReviewAlert, sendAwardLetterEmail, changePreviousAwardsCheckboxForAwarded, getAllCurrentAwardedNomineesForAllAwards, updateAllNomineesSort, updateNomineesDetails, searchAwardNominees, addAwardNomineesFilter, refreshDocumentList, calculateFinalRankedScores, grantAwardToSelected } from "../../actions-index";
import { forEach, uniq, cloneDeep } from 'lodash'
import { getCurrentAwards, getPreviousAwards } from '../../Common/actions';


const mapStateToProps = (state, ownProps) => {
    const { parentContainer, location } = ownProps;
    const { awardedNominees, currentAwards } = state;
    const { status = '', isPreviousAwardsBoxCheckedForAwarded } = state.awardNominees;
    const { list = [], details = [], sortKey = {}, filters = [], query, _list = [] } = awardedNominees;
    const academicProgramContext = location.pathname.split("/")[4];
    const allAwards = {
        list: typeof state.currentAwards.list === "object" ? state.currentAwards.list.concat(state.currentAwards.prevList) : []
    }
    const programDropdown = awardedNominees ? constructShortPlanKeys(awardedNominees.list) : [];
    const systemAward = state.systemAward || {};
    const nominees = Array.isArray(list) ? beautifyNomineeRows(filterNomineesByParentContainer(parentContainer, combineNomineeRows(list, _list))) : [];
    const filteredAwards = isPreviousAwardsBoxCheckedForAwarded ? filterAwardsByParentContainer(allAwards, parentContainer) : filterAwardsByParentContainer(currentAwards, parentContainer);
    const filterLists =  buildFilterLists(_list, filteredAwards, isPreviousAwardsBoxCheckedForAwarded);
    const filteredNominees = nominees &&  currentAwards.list && currentAwards.list.length > 0 ? nominees.filter(nominee => currentAwards.list.some(award => nominee.pk === award.pk) || isPreviousAwardsBoxCheckedForAwarded) : [];
    return {
        filterLists,
        isPreviousAwardsBoxCheckedForAwarded,
        systemAward,
        currentAwards,
        getCurrentAwardInfoResult: awardedNominees.getCurrentAwardInfoResult,
        processingFinalRankedScores: state.processingFinalRankedScores,
        nominees: nominees,
        nomineesDetails: Array.isArray(details) ? details : [],
        gpaTypes: Array.isArray(state.gpaTypes) ? state.gpaTypes : [],
        docList: awardedNominees.docList,
        awardKey: systemAward.pk || null,
        status,
        sortKey,
        filters,
        academicProgramContext,
        query,
        scoringCriteria: state.scoringCriteria,
        programDropdown,
        defaultShortPlanValue: getShortPlanValue(filters, programDropdown),
        nomineesCsvHeaders: getNomineesCsvHeaders(),
        nomineesCsvData: getNomineesCsvData(filteredNominees, parentContainer, currentAwards),
    };
};

function beautifyNomineeRows(nominees) {
    const formattedNominees = nominees.map(nominee => {
        nominee.acceptanceResult = nominee.acceptanceResult ? nominee.acceptanceResult.charAt(0).toUpperCase() + nominee.acceptanceResult.slice(1) : nominee.acceptanceResult;
        return nominee;
    });

    return formattedNominees;
}

function filterAwardsByParentContainer(awards, parentContainer) {
    let filteredAwards = [];
    if(parentContainer === "Recruiting" && awards.list) {
        filteredAwards = awards.list.filter(award => award.studentType === "NEWADMIT")
    }
    if(parentContainer === "CurrentStudent" && awards.list) {
        filteredAwards = awards.list.filter(award => award.studentType === "ENROLLED")
    }
    return {
        list: filteredAwards
    }
}

function buildFilterLists(nominees, awardList, isPreviousAwardsBoxCheckedForAwarded) {
    let accepted = [];
    let combinedAwards = [];
    let shortPlans = [];
    let colleges = [];
    let awards = [];
    const filteredNominees = nominees.filter(nominee => awardList.list.some(award => nominee.pk === award.pk));
    filteredNominees.forEach(nominee => {
        const nominationLabels = combinedAwards.map(nomination => nomination.label);
        const shortPlanLabels = shortPlans.map(shortPlan => shortPlan.label);
        const collegeLabels = colleges.map(college => college.label);
        const awardLabels = awards.map(award => award.label);
        if (!nominationLabels.includes(nominee.temporaryAwardedDisplayValue)) {
            combinedAwards.push({
                label: nominee.temporaryAwardedDisplayValue,
                value: {
                    key: nominee.temporaryAwardedDisplayValue,
                    singleSelect: false,
                    type: "temporaryAwardedDisplayValue"
                }
            });
        }
        if (!shortPlanLabels.includes(nominee.shortPlan)) {
            shortPlans.push({
                label: nominee.shortPlan,
                value: {
                    key: nominee.shortPlan,
                    singleSelect: false,
                    type: "academicProgram"
                }
            });
        }


        if (!collegeLabels.includes(nominee.college)) {
            colleges.push({
                label: nominee.college,
                value: {
                    key: nominee.college,
                    singleSelect: false,
                    type: "college"
                }
            });
        }


        let label = nominee.pk ? nominee.pk : "";
        if (typeof awardList.list === "object") {
            awardList.list.forEach(award => {
                let endDate = new Date() - new Date(award.eDate);
                if (nominee.pk === award.pk && (endDate < 0 || isPreviousAwardsBoxCheckedForAwarded)) {
                    label = award.name;
                    if (!awardLabels.includes(label)) {
                        awards.push({
                            label: label,
                            value: {
                                key: nominee.pk,
                                singleSelect: false,
                                type: "pk"
                            }
                        });
                    }
                }
            });
        };

    });


    accepted = [
        {
            label: "Accepted",
            value: {
                key: "accepted",
                singleSelect: false,
                type: "acceptanceResult"
            }
        },
        {
            label: "<BLANK>",
            value: {
                key: "clear",
                singleSelect: false,
                type: "acceptanceResult"
            }
        },
        {
            label: "Declined",
            value: {
                key: "declined",
                singleSelect: false,
                type: "acceptanceResult"
            }
        },

        {
            label: "No Response",
            value: {
                key: "no response",
                singleSelect: false,
                type: "acceptanceResult"
            }
        }
    ]


    return {
        combinedAwards,
        accepted,
        shortPlans,
        colleges,
        awards,
    }
}


function getNomineesCsvHeaders() {
    const buildCsvHeader = (label, key) => {
        return { label, key };
    };
    const csvHeaders = [
        buildCsvHeader("Response", "acceptanceResult"),
        buildCsvHeader("Award Result", "temporaryAwardedDisplayValue"),

        buildCsvHeader("Admission Term", "admitTerm"),
        buildCsvHeader("Application Number", "applicationNbr"),
        buildCsvHeader("Award Key", "awardKey"),
        buildCsvHeader("Birth Country", "birthCountry"),
        buildCsvHeader("Career", "career"),
        buildCsvHeader("College", "college"),
        buildCsvHeader("ID", "data"),
        buildCsvHeader("Email", "email"),
        buildCsvHeader("External Graduate GPA", "externalGradGpa"),
        buildCsvHeader("External Undergraduate GPA", "externalUgrdGpa"),
        buildCsvHeader("First Name", "firstName"),
        buildCsvHeader("Last Name", "lastName"),
        buildCsvHeader("Display Name", "nameDisplay"),
        buildCsvHeader("Nomination", "nominatedFor"),
        buildCsvHeader("Nominee ID", "nomineeId"),
        buildCsvHeader("PCD Flag", "pcdFlag"),
        buildCsvHeader("Program Status", "programStatus"),
        buildCsvHeader("Program Status Description", "programStatusDescr"),
        buildCsvHeader("Academic Program", "shortPlan"),
        buildCsvHeader("Academic Program Description", "shortPlanDescription"),
        buildCsvHeader("Nominee ID Key", "sk"),
        buildCsvHeader("Status", "status"),
        buildCsvHeader("stdntCarNbr", "stdntCarNbr"),

        buildCsvHeader("Academic Year", "awardKeyAcademicYear"),
        buildCsvHeader("Award Term", "awardKeyAwardTerm"),
        buildCsvHeader("Category", "awardKeyCategory"),
        buildCsvHeader("Award Name", "awardName"),
        buildCsvHeader("Student Status", "studentType"),
        // buildCsvHeader("Award Result Name", "awardResultName"),
        buildCsvHeader("Degree", "degree"),
        buildCsvHeader("Country of Citizenship", "citizenshipCountry"),
    ];
    return csvHeaders;
}

function getNomineesCsvData(nominees, parentContainer, currentAwards) {
    let data = [];
    forEach(nominees, nominee => {

        nominee.studentType = parentContainer === "CurrentStudent" ? "CURRENT STUDENT" : "NEW ADMIT";

        let currentAward = Array.isArray(currentAwards.list) ? currentAwards.list.filter(award => nominee.awardKey && nominee.awardKey.includes(award.pk)) : undefined;
        nominee.awardName = "";
        currentAward.forEach(award => {
            console.log(award)
            nominee.awardName += award.name + ", ";
            nominee.awardResultName += award !== undefined ? award.awardResultName : "";
        });
        nominee.awardName = nominee.awardName.slice(0, -2) // remove last two characters, a trailing ", "

        data.push(nominee);
    });
    return data;
}


function filterNomineesByParentContainer(parentContainer, nominees) {
    if (parentContainer === "CurrentStudent") {
        const newNomineeList = [];
        nominees.forEach(nominee => {
            if (nominee.cumGpa > -0.01) {
                newNomineeList.push(nominee);
            }
        })
        return newNomineeList;
    } else if (parentContainer === "Recruiting") {
        const newNomineeList = [];
        nominees.forEach(nominee => {
            if (!nominee.cumGpa) {
                newNomineeList.push(nominee);
            }
        })
        return newNomineeList;
    }

    return nominees;
}

function combineNomineeRows(list, unfilteredList) {
    let combinedNominees = [];
    let nominees = cloneDeep(unfilteredList);
    nominees.forEach(nominee => {
        let deconstructedAwardKey = nominee.pk.split("#");

        nominee.awardKeyAcademicYear = deconstructedAwardKey[deconstructedAwardKey.length - 3];
        nominee.awardKeyAwardTerm = deconstructedAwardKey[deconstructedAwardKey.length - 2];
        nominee.awardKeyCategory = deconstructedAwardKey[deconstructedAwardKey.length - 1];
        if (nominee.isAwardCombined) {

            let nomineeAlreadyExists = false;
            combinedNominees.forEach(combinedNominee => {
                if (nominee.sk === combinedNominee.sk && combinedNominee.isAwardCombined && (combinedNominee.awardLetterPk === nominee.awardLetterPk)) {
                    nomineeAlreadyExists = true;

                    let combinedNomineeShortListOfAwards = combinedNominee.temporaryAwardedDisplayValue.split(",");
                    if (!combinedNomineeShortListOfAwards.includes(nominee.temporaryAwardedDisplayValue)) {
                        combinedNominee.awardKey = combinedNominee.awardKey + ", " + nominee.awardKey;
                        combinedNominee.awardKeyCategory = combinedNominee.awardKeyCategory + ", " + nominee.awardKeyCategory;
                        combinedNominee.awardName = combinedNominee.awardName + ", " + nominee.awardName;
                        combinedNominee.temporaryAwardedDisplayValue = combinedNominee.temporaryAwardedDisplayValue + ", " + nominee.temporaryAwardedDisplayValue;
                    }
                }
            })
            if (!nomineeAlreadyExists && list.map(a => a.nomineeId).includes(nominee.nomineeId) && list.map(a => a.awardKey).includes(nominee.awardKey)) {
                combinedNominees.push(nominee);
            }

        } else if ( list.map(a => a.nomineeId).includes(nominee.nomineeId) && list.map(a => a.awardKey).includes(nominee.awardKey)) {
            combinedNominees.push(nominee);

        }
    })


    return combinedNominees;
}

function getShortPlanValue(filters = [], shortPlans = []) {
    if (!Array.isArray(filters) || !Array.isArray(shortPlans) || shortPlans.length < 1 || filters.length < 1) {
        return []
    }
    const matches = [];

    shortPlans.filter(plan => plan.value).forEach(plan => {
        filters.filter(o => o.type === 'displayShortPlan' || o.type === 'nominatedFor').forEach(filter => {
            const { value } = plan
            if (value.key === filter.key) {
                matches.push(plan)
            }
        })
    });

    return matches
}
const constructShortPlanKeys = (nominees = []) => {
    const convert = (shortplan = '') => shortplan.includes('-') ? shortplan.split("-")[0] : shortplan
    const shortPlans = [];
    if (nominees && nominees.length > 0) {
        uniq(nominees.filter(o => o.nominatedFor && typeof o.nominatedFor === 'string').map(nom => convert(nom.nominatedFor))).sort();
    }
    const dropdownVals = shortPlans.map(plan => ({
        label: plan,
        value: {
            singleSelect: true,
            type: 'nominatedFor',
            key: plan
        }
    }));

    return dropdownVals
};

const mapDispatchToProps = (dispatch) => {
    return {
        getAllCurrentAwardedNomineesForAllAwards: (sortKey, academicProgram) => dispatch(getAllCurrentAwardedNomineesForAllAwards(sortKey, academicProgram)),
        updateSortKey: (sortKey) => dispatch(updateAllNomineesSort(sortKey)),
        updateNomineesDetails: (values) => dispatch(updateNomineesDetails(values)),
        searchAwardNominees: (query) => dispatch(searchAwardNominees(query)),
        addAwardNomineesFilter: (filter) => dispatch(addAwardNomineesFilter(filter)),
        refreshDocumentList: (nomineeId, shortPlan, awardKey, applicationNumber) => dispatch(refreshDocumentList(nomineeId, shortPlan, awardKey, applicationNumber)),
        calculateFinalRankedScores: (awardKey) => dispatch(calculateFinalRankedScores(awardKey)),
        grantAwardToSelected: (awardKey, nominees, awarded, systemAward) => dispatch(grantAwardToSelected(awardKey, nominees, awarded, systemAward)),
        getCurrentAwardInfo: (awardKey) => dispatch(getCurrentAwardInfo(awardKey)),
        sendAwardLetterEmail: (nominees, sortKey) => dispatch(sendAwardLetterEmail(nominees, sortKey)),
        dismissReviewAlert: () => dispatch(dismissReviewAlert()),
        changePreviousAwardsCheckboxForAwarded: (e) => dispatch(changePreviousAwardsCheckboxForAwarded(e)),
        changeAwardeeResponseStatuses: (nominees, responseType, sortKey) => dispatch(changeAwardeeResponseStatuses(nominees, responseType, sortKey)),
        getCurrentAwards: () => dispatch(getCurrentAwards(1)),
        getPreviousAwards: () => dispatch(getPreviousAwards())
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Awarded));

