import React, { Component } from 'react';
import '../Header.css';
import { Icon, OSUButton, Overline, Body2 } from 'osu-react-components';
import { isEmpty } from 'lodash';
import {
    Dropdown, Collapse, Navbar, NavbarToggler, NavItem, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem
} from 'reactstrap';
import PropTypes from "prop-types";

class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            collapseIsOpen: false,
            openMenuKey: '',
            redirectPath: null
        };
    }

    componentDidMount() {
        if(this.props.getCurrentAwards()){
            const retryable = 2;
            this.props.getCurrentAwards(retryable).then(a => {return a})
            .catch(error => {
                console.error("Get getCurrentAwards error (likely no current user): ", error);
                if (!isEmpty(this.props.user)) this.props.clearUser();
                if (this.props.location.pathname !== "/signout") this.props.history.push("/signout");
            });
        }
    }

    createAwardDropdown = (arr, titleKey, label, path) => arr.map((item, index) => {
        return <OSUButton key={`AwardLink[${index}]`} variant={`body2`} className="text-nowrap text-left w-100" uppercase={false} ariaLabel={`Go to ${label} for ${item[titleKey]}`} link
            onClick={() => this.setAwardAndNavigateToNewPage(item, path)}>
            {item[titleKey]}
        </OSUButton>
    });

    setAwardAndNavigateToNewPage(item, path) {
        // in React 4.0 changes to props.history.push(path) do not rerender a component if path is the same as this.props.history.location.pathname, this fixes a header nav issue with how our awards share a common url
        
        if(this.props.history.location.pathname === path){
            this.props.history.push("/")
        }
        return Promise.resolve(this.props.setSystemAwardValues(item))
            .then(() => this.props.history.push(path))
    }

    renderHoverSwitchboardDropdown = (values) => {
        const { pathName } = this.props
        const { label, key, onClick, path, mobileMenuOpen } = values
        const isOpen = this.state.openMenuKey === key
        const navClass = `nav-item${mobileMenuOpen ? ' mt-2' : ''}`
        const active = (path = "") => pathName.indexOf(`/${path}`) === 0 ? true : false
        const determineColor = (path = "") => active(path) ? "black" : "gray"
        const determineVariant = (path = "") => active(path) ? "subtitle2" : "body2"

        return <NavItem>
            <Dropdown isOpen={isOpen} onClick={onClick} onMouseEnter={() => this.setState({ openMenuKey: key })} onMouseLeave={() => this.setState({ openMenuKey: '' })} toggle={() => this.setState({ openMenuKey: '' })} inNavbar className="d-flex justify-content-end">
                <DropdownToggle color="link"  style={{ textDecoration: 'none' }} className="d-flex align-items-center p-0">
                    <OSUButton link path={`/reviewer-management/`} uppercase={false} ariaLabel={`Go to ${label}`} className={navClass} color={determineColor(path)} variant={determineVariant(path)}>
                        {label}
                        <Icon className="pl-1" color={determineColor(path)} type="caret-down" />
                    </OSUButton>
                </DropdownToggle>
                {this.renderHoverMenus(key)}
            </Dropdown>
        </NavItem>
    }

    renderHoverMenus = (key) => {
        const awardsExist = Array.isArray(this.props.awards) && this.props.awards.length > 0
        switch (key) {
            case 'nominee-reviews':
                return <DropdownMenu className="mt-0" onMouseLeave={() => this.setState({ openMenuKey: '' })}>
                    {awardsExist && this.createAwardDropdown(this.props.awards, 'name', 'Nominee Review', `/nominee-reviews`)}
                    {awardsExist && <DropdownItem divider />}
                    <OSUButton variant={`body2`} className="text-nowrap text-left w-100" uppercase={false} ariaLabel={`Go to switchboard for nominee reviews`} link path={`/nominee-reviews/award-selection`}>View All Awards</OSUButton>
                </DropdownMenu>
            case 'reviewer-management':
                return <DropdownMenu path={`/reviewer-management`} className="mt-0" onMouseLeave={() => this.setState({ openMenuKey: '' })}>
                    {awardsExist && this.createAwardDropdown(this.props.awards, 'name', 'Committee', '/reviewer-management/committee')}
                    {awardsExist && <DropdownItem divider />}
                    <OSUButton variant={`body2`} className="text-nowrap text-left w-100" uppercase={false} ariaLabel={`Go to All Reviewers`} link path={`/reviewer-management/all-reviewers`}>All Reviewers</OSUButton>
                </DropdownMenu>
            default:
                return undefined
        }
    }

    nomineeReviewHeader(mobileMenuOpen) {
        return ((this.props.isAdmin || this.props.isReviewer) ?
            this.renderHoverSwitchboardDropdown({
                label: 'Nominee Review',
                key: 'nominee-reviews',
                onClick: () => { this.props.history.push('/nominee-reviews/award-selection') },
                path: 'nominee-reviews',
                mobileMenuOpen
            }) : null);
    }

    reviewerManagementHeader(mobileMenuOpen) {
        return (this.props.isAdmin ? 
            this.renderHoverSwitchboardDropdown({
                label: 'Reviewer Management',
                key: 'reviewer-management',
                path: 'reviewer-management',
                mobileMenuOpen
            }) : null);
    }

    renderNavItems(pathName, mobileMenuOpen) {
        const navClass = `nav-item${mobileMenuOpen ? ' mt-2' : ''}`;
        const { collapseIsOpen } = this.state;
        const feeMatchUrl = !isEmpty(process.env.REACT_APP_FEE_MATCH_URL) ? process.env.REACT_APP_FEE_MATCH_URL : "https://gradfeematch.osu.edu";

        const getLinkColor = (linkPath) => {
            return pathName === linkPath ? "black" : "gray";
        }
        
        const awardManagementPath = "/award-management/";
        const awardManagementNavItem = this.props.isAdmin ? 
            (<NavItem key="award-management-button">
                <OSUButton ariaLabel="Go to Award Management" className={navClass} link path={awardManagementPath}
                    uppercase={false} variant={"body2"} color={getLinkColor(awardManagementPath)}>
                    Award Management
                </OSUButton>
            </NavItem>) : null;
            
        const nominationsPath = "/nominations/";
        const nominationsNavItem = ((this.props.isAdmin || this.props.isNominator) ?
            (<NavItem key="nominations-button">
                <OSUButton ariaLabel="Go to Fellowships &amp; Awards" className={navClass} link path={nominationsPath}
                    uppercase={false} variant={"body2"} color={getLinkColor(nominationsPath)}>
                    Fellowships &amp; Awards
                </OSUButton>
            </NavItem>) : null);

        const nomineeReviewPath = "/nominee-reviews/award-selection";
        const nomineeReviewNavItem = ((this.props.isAdmin || this.props.isReviewer) ?
            (<NavItem key="nominee-review-button">
                <OSUButton ariaLabel="Go to Nominee Review" className={navClass} link path={nomineeReviewPath}
                    uppercase={false} variant={"body2"} color={getLinkColor(nomineeReviewPath)}>Nominee Review</OSUButton>
            </NavItem>) : null);

        const reviewerManagementPath = "/reviewer-management/";
        const reviewerManagementNavItem = (this.props.isAdmin ? 
            (<NavItem key="reviewer-management-button" >
                <OSUButton ariaLabel="Go to Reviewer Management" className={navClass} link path={reviewerManagementPath}
                    uppercase={false} variant={"body2"} color={getLinkColor(reviewerManagementPath)}>Reviewer Management</OSUButton>
            </NavItem>) : null);
        
        const navBar = (mobileMenuOpen) ? // mobile
            (<ul className="navbar-nav">
                {nominationsNavItem}
                {nomineeReviewNavItem}
                {reviewerManagementNavItem}
                {awardManagementNavItem}
            </ul>) : // web
            (<ul className="navbar-nav mr-auto">
                {nominationsNavItem}
                {this.nomineeReviewHeader(mobileMenuOpen)}
                {this.reviewerManagementHeader(mobileMenuOpen)}
                {awardManagementNavItem}
            </ul>);

        return <Collapse isOpen={collapseIsOpen} navbar>
            {navBar}
            <ul className="navbar-nav">
                <NavItem key="fee-match-button">
                    <OSUButton ariaLabel="Go to Fee Match" className={navClass} link url={feeMatchUrl} uppercase={false} variant={"body2"}>Fee Match</OSUButton>
                </NavItem>
                <NavItem key="forms-button">
                    <OSUButton ariaLabel="Go to Grad Forms" className={navClass} link url="https://gradforms.osu.edu" uppercase={false} variant={"body2"}>Grad Forms</OSUButton>
                </NavItem>
                <NavItem key="feedback-button">
                    <OSUButton ariaLabel="Go to Feedback" className={navClass} link url="http://u.osu.edu/ociowebapps/?apporigin=Grad%20Awards" uppercase={false} variant={"body2"}><Icon className="pr-1" type="comment" color="blue" /> Feedback</OSUButton>
                </NavItem>
            </ul>
        </Collapse >
    }

    render() {
        const { user, handleLogin, handleLogOut, pathName = "", userIsLoading, isAdmin, isNominator, isReviewer } = this.props;
        const mobileMenuOpen = !!this.props.screenSize ? this.props.screenSize < 992 : false;
        const roles = [];
        if(isAdmin) roles.push("Admin");
        if(isNominator) roles.push("Nominator");
        if(isReviewer) roles.push("Reviewer");
        const rolesDisplay = roles.length > 0 ? roles.join(", ") : "No role found";
        const userActions = isEmpty(user) || userIsLoading
            ? <OSUButton ariaLabel={userIsLoading ? "Logging in to Grad Award System" : "Log in to Grad Award System"} color="blue" className={`${mobileMenuOpen ? '' : 'h-100 '}nav-link text-nowrap`} href="/login" onClick={handleLogin}><Icon className="pr-1" type="lock" color="white" />Log In</OSUButton>
            : <div className="pl-4 border-left text-right mr-4">
                <Overline className="system-award" color="gray">{rolesDisplay}</Overline>
                <UncontrolledDropdown inNavbar className="d-flex justify-content-end">
                    <DropdownToggle nav caret className="d-flex align-items-center p-0 pt-1">
                        <Body2 className="pr-1">{user.name || 'No user found'}</Body2>
                    </DropdownToggle>
                    <DropdownMenu right>
                        <NavItem>
                            <OSUButton ariaLabel={"Log Out"} className="w-100 text-left" variant="body1" color="blue" link uppercase={false} onClick={handleLogOut}><Icon className="pr-1" type="lock" color="blue" />Log Out</OSUButton>
                        </NavItem>
                    </DropdownMenu>
                </UncontrolledDropdown>
            </div>;

        return <div className={`header-wrapper${mobileMenuOpen ? '' : ' align-items-center'}`}>
            <Navbar light className="d-flex w-100 justify-content-between" expand="lg">
                <NavbarToggler onClick={() => this.setState((state) => ({ collapseIsOpen: !state.collapseIsOpen }))} />
                {mobileMenuOpen ? userActions : null}
                {this.renderNavItems(pathName, mobileMenuOpen)}
            </Navbar>
            {!mobileMenuOpen ? userActions : null}
        </div>
    }
}

export default Header

Header.propTypes = {
    isAdmin: PropTypes.bool,
    isReviewer: PropTypes.bool,
    isNominator: PropTypes.bool,
    handleLogin: PropTypes.func,
    handleLogOut: PropTypes.func,
    pathName: PropTypes.string,
    userIsLoading: PropTypes.bool,
    user: PropTypes.shape({
        applicationRoles: PropTypes.string,
        name: PropTypes.string
    }),
    awards: PropTypes.array,
    systemAward: PropTypes.shape({
        awardKey: PropTypes.string,
        name: PropTypes.string
    }),
    getCurrentAwards: PropTypes.func,
    setSystemAwardValues: PropTypes.func
}
