import React from 'react';
import { Collapse, Nav, Navbar, NavItem, NavLink, NavbarToggler, TabContent, TabPane } from 'reactstrap';
import { Typography } from 'osu-react-components';
import classnames from 'classnames';
import NomineesContent from '../../NomineesContent/containers';
import PrimaryReviewContent from '../../PrimaryReviewContent/containers';
import ScoreSheetContent from '../../ScoreSheetContent/containers';
import ReviewersContent from '../../ReviewersContent/containers';
import SecondaryReviewContent from '../../SecondaryReviewContent/containers';
import ResultsContent from '../../ResultsContent/containers'

export default class ScoringTabs extends React.Component {
    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
        this.state = {
            activeTab: props.tabIndex || 0,
            isNavbarOpen: false
        };
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }

    renderNavLink = (values) => {
        const { title, tabId } = values
        const active = this.state.activeTab === tabId
        return <NavItem key={tabId}>
            <NavLink aria-label={`Open tab for ${title}`} className={classnames({ active })}
                onClick={() => { this.toggle(tabId); }}>
                <Typography color={active ? 'black' : 'gray'} variant={'body2'}>{title}</Typography>
            </NavLink>
        </NavItem >
    };

    scoringTabData = (tabIndex) => {
        const filterProps = {
            handleFilter: this.props.handleFilter,
            clearAllFilters: this.props.clearAllFilters
        };

        const showWeightedScore = this.props.showAllNomineesColumn;
        return [
            {
                title: 'My Reviews',
                type: 'reviewer',
                tabIndex,
                component: (tabIndex) => <ScoreSheetContent openDuplicateScoreAlert={this.props.openDuplicateScoreAlert} dismissDuplicateScoreAlert={this.props.dismissDuplicateScoreAlert} tabIndex={tabIndex} title={'My Reviews'} {...filterProps} user={this.props.user} key={'score-sheet-tab'} />
            }, {
                title: 'My Composite Reviews',
                type: 'reviewer',
                tabIndex,
                component: (tabIndex) => <PrimaryReviewContent title={'My Composite Reviews'} tabIndex={tabIndex} {...filterProps} getWeightedScoreDefaultValue={this.props.getWeightedScoreDefaultValue} weightedScoreRanges={this.props.weightedScoreRanges} user={this.props.user} key={'primary-review-tab'} />
            }, {
                title: 'My Other Reviews',
                type: 'reviewer',
                tabIndex,
                component: (tabIndex) => <SecondaryReviewContent title={'My Other Reviews'} tabIndex={tabIndex} {...filterProps} getWeightedScoreDefaultValue={this.props.getWeightedScoreDefaultValue} weightedScoreRanges={this.props.weightedScoreRanges} user={this.props.user} key={'secondary-review-tab'} />
            },
            {
                title: 'All Nominees',
                type: 'both',
                tabIndex,
                component: (tabIndex) => <NomineesContent tabIndex={tabIndex} {...filterProps} getWeightedScoreDefaultValue={this.props.getWeightedScoreDefaultValue} showWeightedScore={showWeightedScore} weightedScoreRanges={this.props.weightedScoreRanges} isReviewer={this.props.isReviewer} isAdmin={this.props.isAdmin} user={this.props.user} key={'all-nominees-tab'} />
            },
            {
                title: 'All Reviewers',
                type: 'admin',
                component: (tabIndex) => <ReviewersContent tabIndex={tabIndex} {...filterProps} key={'all-reviewers-tab'} />
            },
            {
                title: 'Results',
                type: 'admin',
                tabIndex,
                component: (tabIndex) => <ResultsContent tabIndex={tabIndex} {...filterProps} getWeightedScoreDefaultValue={this.props.getWeightedScoreDefaultValue} showWeightedScore={showWeightedScore} weightedScoreRanges={this.props.weightedScoreRanges} isReviewer={this.props.isReviewer} isAdmin={this.props.isAdmin} user={this.props.user} key={'all-nominees-tab'} />
            },
        ]
    };

    render() {
        const { isAdmin, isReviewer } = this.props;
        const scoringTabData = this.scoringTabData();
        const expandNavbar = this.props.screenSize >= 576; // expand when the screen size is larger than small devices
        const renderTabViews = (tabData) => <div>
            <Navbar light expand={expandNavbar}>
                <NavbarToggler onClick={() => this.setState({ isNavbarOpen: !this.state.isNavbarOpen })} />
                <Collapse isOpen={this.state.isNavbarOpen} navbar>
                    <Nav navbar tabs={expandNavbar} style={{ width: "100%" }}>
                        {tabData.map(tab => this.renderNavLink(tab))}
                    </Nav>
                </Collapse>
            </Navbar>
            <TabContent className="mt-2" activeTab={this.state.activeTab}>
                {tabData.map((tab, index) => <TabPane key={index} tabId={tab.tabId}>
                    {tab.component(tab.tabId)}
                    </TabPane>)}
            </TabContent>
        </div>;

        if (isAdmin && isReviewer) {
            return renderTabViews(scoringTabData.map((item, index) => ({ ...item, tabId: index })))
        } else if (isAdmin) {
            const adminTabs = scoringTabData
                .filter(data => data.type === 'admin' || data.type === 'both')
                .map((item, index) => ({ ...item, tabId: index }));

            return renderTabViews(adminTabs)
        } else if (isReviewer) {
            const reviewerTabs = scoringTabData
                .filter(data => data.type === 'reviewer' || data.type === 'both')
                .map((item, index) => ({ ...item, tabId: index }))

            return renderTabViews(reviewerTabs)
        } else {
            return null
        }

    }
}
