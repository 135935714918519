import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { find } from "lodash";
import { submitNominations, submitAddStudentSearchData, clearAddStudentSearchData, getDropDownMetadata, getAwardInfo, TERMS, CURRENT_AWARDS, GRADUATE_PROGRAMS } from "../../actions-index";
import AddStudent from "../components/AddStudent";
import { transformDropdownMetadata, transformOptionsForAward, filterAwardsForAddStudent, filterSearchOnProgramStatus, transformTerms } from "../transform";
import { ALL_PROGRAMS, AWARD_OPTIONS } from "../constants";
import { STUDENT_STATUS_CURRENT, STUDENT_STATUS_NEW_ADMIT } from "../../util/constants";

const mapStateToProps = (state, props) => {
    let studentStatus = props.path.includes('recruiting') ? STUDENT_STATUS_NEW_ADMIT : STUDENT_STATUS_CURRENT;
    let graduateProgram = props.match.params.shortPlan;
    const nominatorRole = find(state.nominatorRoles, role => role.startsWith(`${process.env.REACT_APP_USER_ROLE_NOMINATOR}-${graduateProgram}`));
    
    const awards = filterAwardsForAddStudent(state.currentAwards._list, studentStatus, props.isAdmin);
    const awardEligibilityMap = {};
    awards.forEach(award => {
      awardEligibilityMap[award.pk] = (award.eligibility || null);
    });
    
    const awardInfo = state.awardInfo;
    let nominatedBy = {};
    let usereEmplid = props.user.osuid;
    nominatedBy.nominatedByEmplid = usereEmplid;
    let emailAddress = props.user.email;
    nominatedBy.nominatedByNameN = emailAddress.substring(0, emailAddress.indexOf("@"));
    let newDate = new Date();
    nominatedBy.nominatedDate = newDate;

    let dropDownMetadata = transformDropdownMetadata(props.isAdmin, [
      { key: CURRENT_AWARDS, data: awards },
      { key: AWARD_OPTIONS, data: transformOptionsForAward(awardInfo) },
      { key: TERMS, data: transformTerms(state.terms.data, props.isAdmin, studentStatus) },
      { key: GRADUATE_PROGRAMS, data: [ALL_PROGRAMS, ...Object.keys(state.graduatePrograms.data || {})] }
    ]);
    // console.log("DropdownMetadata", dropDownMetadata);

    return {
        awardEligibilityMap,
        awardInfo,
        dropDownMetadata,
        studentStatus,
        graduateProgram,
        nominatorRole,
        addStudentSearchData: filterSearchOnProgramStatus(state.addStudentSearchData, props.isAdmin),
        submitNominationsResults: state.submitNominationsResults,
        userIsLoading: state.userIsLoading,
        nominatedBy,
        ...props
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
      getDropDownMetadata: () => dispatch(getDropDownMetadata()),
      getAwardInfo: (awardKey) => dispatch(getAwardInfo(awardKey)),
      submitAddStudentSearchData: (searchParams, isAdmin) => dispatch(submitAddStudentSearchData(searchParams, isAdmin)),
      clearAddStudentSearchData: () => dispatch(clearAddStudentSearchData()),
      submitNominations: (submitParams) => dispatch(submitNominations(submitParams))
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)((AddStudent)));
