import React, { Component, Fragment } from "react";
import { Alert, FormFeedback, FormGroup, Input, Label, UncontrolledTooltip } from "reactstrap";
import { Body1, Caption, Icon } from "osu-react-components";
import PropTypes from "prop-types";
import RequiredIf from "react-required-if";
import moment from "moment";
import { forOwn, isEqual, mapValues, omit, values } from "lodash";
import { FORM_FEEDBACK_SR_PREFIX } from "../../../util/constants";
import "../AwardDates.css";

const LABEL_COLUMN_MIN_WIDTH = "15rem";
const INPUT_WIDTH = "15rem";
const PREVIOUS_DATE_LABEL = "Previous";
const REVIEW_DATES = ["reviewStartDate", "reviewCompletionDate", "reviewEndDate", "reviewCommitteeMeetingStartDate", "reviewCommitteeMeetingEndDate", "reviewAccessEndDate"];
const AWARD_LETTER_DATES = ["awardLetterSendOptionDate", "awardLetterSentDate"];

class AwardDates extends Component {
    constructor(props) {
        super(props);
        this.state = {
            info: {
                nominationStartDate: "Date when students can be added to awards.",
                nominationEndDate: "Students can no longer be added to awards (except by administrator).",
                reviewStartDate: "Admins can add nominees to award and assign nominees to reviewers up to 1 hour prior to this date.\n\n" + 
                    "Nominees must be assigned prior to this date.\n\n" + 
                    "Reviewers can see award on dashboard and begin their reviews.",
                reviewCompletionDate: "Reviewers should have their initial reviews completed so they can begin doing composite reviews.",
                reviewEndDate: "Reviewers are no longer able to edit scores.",
                reviewCommitteeMeetingStartDate: "Reviewers can now see the final ranked score on the All Nominees tab.",
                reviewCommitteeMeetingEndDate: "Reviewers will no longer have access to view the award.",
                reviewAccessEndDate: "Reviewers will no longer have access to log in to system for this award.\n\n" + 
                    "This date will be locked after packets are assigned.",
                awardPostedDate: "Date that nominators can see who received an award and view the letter.",
                awardLetterSendOptionDate: "Date that nominators can send the award letters to the nominee.",
                awardLetterSentDate: "Date award letters are sent to nominees if they have not previously been sent.",
                acceptanceDeadlineDate: "Date nominees are no longer able to accept/decline the award online.  After this date nominees must contact the Graduate School.",
                awardEndDate: "The award is now a Previous Award."
            },
            invalid: {},
            hasPastDate: false,
            dates: {
                nominationStartDate: "",
                nominationEndDate: "",
                reviewStartDate: "",
                reviewCompletionDate: "",
                reviewEndDate: "",
                reviewCommitteeMeetingStartDate: "",
                reviewCommitteeMeetingEndDate: "",
                reviewAccessEndDate: "",
                awardPostedDate: "",
                awardLetterSendOptionDate: "",
                awardLetterSentDate: "",
                acceptanceDeadlineDate: "",
                awardEndDate: ""
            },
            dateWindowLocked: false
        };
    }

    componentWillMount() {
        this.setDates();
    }

    componentDidUpdate(prevProps) {
        if(!isEqual(prevProps.dates, this.props.dates)) {
            this.setDates();
        }
        if(prevProps.showReviewDates !== this.props.showReviewDates) {
            this.validate();
        }
        if(prevProps.showAwardLetterDates !== this.props.showAwardLetterDates) {
            this.validate();
        }
    }

    setDates = () => {
        if(this.props.dates) {
            const dates = this.formatDates(this.props.dates);
            const nominationStartDate = (this.props.dates.nominationStartDate ? moment(this.props.dates.nominationStartDate) : null);
            const dateWindowLocked = (nominationStartDate && nominationStartDate.isValid() && nominationStartDate.isBefore());
            this.setState({
                dates,
                dateWindowLocked,
                invalid: this.setInvalid(dates),
                hasPastDate: this.hasPastDate(dates)
            }, () => {
                this.validate();
            });
        }
    }

    formatDates = (dates) => {
        const formattedDates = {};
        forOwn(dates, (value, key) => {
            let formattedDate = value;
            if(!!value) {
                const date = moment(value);
                if(date.isValid()) {
                    formattedDate = date.format(this.props.dateFormat);
                }
            }
            formattedDates[key] = formattedDate;
        });
        return formattedDates;
    }

    parseDate = (date) => {
        return moment(date, this.props.dateFormat, true);
    }

    setInvalid = (dates) => {
        const invalid = {};
        forOwn(dates, (value, key) => {
            invalid[key] = !(this.parseDate(value).isValid());
        });
        return invalid;
    };

    hasPastDate = (dates) => {
        let hasPastDate = false;
        const now = moment();
        forOwn(dates, value => {
            if(value) {
                const date = this.parseDate(value);
                if(date.isValid() && date.isBefore(now)) {
                    hasPastDate = true;
                }
            }
        });
        return hasPastDate
    }

    onDateChange = (event) => {
        const input = event.target;
        const dates = { ...this.state.dates, [input.name]: input.value };
        this.setState({ dates, hasPastDate: this.hasPastDate(dates) });
    }

    onDateBlur = (event, beforeDateName, afterDateName) => {
        const input = event.target;
        const dateName = input.name;
        const dateValue = input.value;

        // date is invalid - mark the date as invalid
        const date = this.parseDate(dateValue);
        const dateInvalid = !date.isValid();
        const invalid = { ...this.state.invalid, [dateName]: dateInvalid };

        // date and before date are both valid, but the date is the same or earlier than the before date - mark the date as invalid
        const beforeDateValue = beforeDateName ? this.state.dates[beforeDateName] : null;
        if(!dateInvalid && beforeDateValue) {
            const beforeDate = this.parseDate(beforeDateValue);
            if(beforeDate.isValid()) {
                const beforeDateInvalid = beforeDate.isSameOrAfter(date);
                invalid[dateName] = beforeDateInvalid;
            }
        }

        // the date and after date are both valid, but the date is the same or later than the after date - mark the after date as invalid
        const afterDateValue = afterDateName ? this.state.dates[afterDateName] : null;
        if(!dateInvalid && afterDateValue) {
            const afterDate = this.parseDate(afterDateValue);
            if(afterDate.isValid()) {
                const afterDateInvalid = afterDate.isSameOrBefore(date);
                invalid[afterDateName] = afterDateInvalid;
            }
        }

        this.setState({ invalid }, () => this.validate());
    }

    filterDateKeysByVisibility = (obj) => {
        let filteredObj = { ...obj };
        if(this.props.showReviewDates === false) filteredObj = omit(filteredObj, REVIEW_DATES);
        if(this.props.showAwardLetterDates === false) filteredObj = omit(filteredObj, AWARD_LETTER_DATES);
        return filteredObj;
    }

    validate = () => {
        let isValid = values(this.filterDateKeysByVisibility(this.state.invalid)).every(invalid => (invalid === false));
        const dates = mapValues(this.filterDateKeysByVisibility(this.state.dates), (value, key) => {
            const date = this.parseDate(value);
            if(!date.isValid()) isValid = false; // extra validation to prevent "Invalid Date" (shouldn't happen)
            return date.isValid() ? date.toDate() : null;
        });
        this.props.onValidate(isValid, isValid ? dates : null);
    }

    render() {
        return (
            <FormGroup data-testid="awardDatesFormGroup">
                <div className="d-flex flex-wrap my-2">
                    <Caption color="gray" className="flex-column">Format: {this.props.dateFormat}&nbsp;</Caption>
                    <Caption color="gray" className="flex-column">(Example: {moment().format(this.props.dateFormat)})</Caption>
                </div>
                {!this.props.readOnly &&
                    <Alert aria-live="polite" data-testid="pastDateWarning" color="warning" isOpen={this.state.hasPastDate && !this.state.dateWindowLocked} style={{ maxWidth: "30rem" }}>
                        Warning: One or more dates are in the past.
                    </Alert>
                }

                {/* Nomination Start */}
                <FormGroup data-testid="nominationStartDateFormGroup" className="d-flex flex-wrap">
                    <div className="flex-column" style={{ minWidth: LABEL_COLUMN_MIN_WIDTH }}>
                        <Label id="nominationStartDateLabel" for="nominationStartDate" className="d-inline-block">
                            <Body1 className={`${this.props.readOnly ? "" : " required"}`}>Nomination Start:</Body1>
                        </Label>
                        <span id="nominationStartDateInfoTooltip" data-testid="nominationStartDateInfoTooltip" role="tooltip" tabIndex="0"
                            aria-labelledby="nominationStartDateLabel nominationStartDateInfo" className="d-inline-block p-1">
                            <Icon type="info-circle" color="blue" aria-hidden="true" />
                            <span id="nominationStartDateInfo" className="sr-only">{this.state.info.nominationStartDate}</span>
                            <UncontrolledTooltip placement="top" target="nominationStartDateInfoTooltip" innerClassName="date-info-tooltip">{this.state.info.nominationStartDate}</UncontrolledTooltip>
                        </span>
                        {this.props.previousDates && this.props.previousDates.nominationStartDate &&
                            <Caption color="gray" id="nominationStartDatePrevious" dataTestId="nominationStartDatePrevious">
                                {`${PREVIOUS_DATE_LABEL}: ${moment(this.props.previousDates.nominationStartDate).format(this.props.dateFormat)}`}
                            </Caption>
                        }
                    </div>
                    <div className="flex-column">
                        {this.props.readOnly ?
                            (<Body1 id="nominationStartDate" name="nominationStartDate" dataTestId="nominationStartDate" className="mt-2">
                                {this.state.dates.nominationStartDate}
                            </Body1>) :
                            (<Fragment>
                                <Input id="nominationStartDate" name="nominationStartDate" data-testid="nominationStartDate" type="text" style={{ width: INPUT_WIDTH }}
                                    value={this.state.dates.nominationStartDate} invalid={this.state.invalid.nominationStartDate} disabled={this.state.dateWindowLocked}
                                    aria-required={true} aria-invalid={this.state.invalid.nominationStartDate} aria-labelledby="nominationStartDateLabel nominationStartDatePrevious"
                                    onChange={e => this.onDateChange(e)} onBlur={e => this.onDateBlur(e, null, "nominationEndDate")} />
                                <FormFeedback aria-live="polite">{FORM_FEEDBACK_SR_PREFIX}Nomination Start must be a valid date.</FormFeedback>
                            </Fragment>)
                        }
                    </div>
                </FormGroup>

                {/* Nomination End */}
                {/* Note: If not reviewed, no after date check onDateBlur because Awards Posted is allowed to be before Nomination End. */}
                <FormGroup data-testid="nominationEndDateFormGroup" className="d-flex flex-wrap">
                    <div className="flex-column" style={{ minWidth: LABEL_COLUMN_MIN_WIDTH }}>
                        <Label id="nominationEndDateLabel" for="nominationEndDate" className="d-inline-block">
                            <Body1 className={`${this.props.readOnly ? "" : " required"}`}>Nomination End:</Body1>
                        </Label>
                        <span id="nominationEndDateInfoTooltip" data-testid="nominationEndDateInfoTooltip" role="tooltip" tabIndex="0"
                            aria-labelledby="nominationEndDateLabel nominationEndDateInfo" className="d-inline-block p-1">
                            <Icon type="info-circle" color="blue" aria-hidden="true" />
                            <span id="nominationEndDateInfo" className="sr-only">{this.state.info.nominationEndDate}</span>
                            <UncontrolledTooltip placement="top" target="nominationEndDateInfoTooltip" innerClassName="date-info-tooltip">{this.state.info.nominationEndDate}</UncontrolledTooltip>
                        </span>
                        {this.props.previousDates && this.props.previousDates.nominationEndDate &&
                            <Caption color="gray" id="nominationEndDatePrevious" dataTestId="nominationEndDatePrevious">
                                {`${PREVIOUS_DATE_LABEL}: ${moment(this.props.previousDates.nominationEndDate).format(this.props.dateFormat)}`}
                            </Caption>
                        }
                    </div>
                    <div className="flex-column">
                        {this.props.readOnly ? 
                            (<Body1 id="nominationEndDate" name="nominationEndDate" dataTestId="nominationEndDate" className="mt-2">
                                {this.state.dates.nominationEndDate}
                            </Body1>) :
                            (<Fragment>
                                <Input id="nominationEndDate" name="nominationEndDate" data-testid="nominationEndDate" type="text" style={{ width: INPUT_WIDTH }}
                                    value={this.state.dates.nominationEndDate} invalid={this.state.invalid.nominationEndDate} aria-required={true}
                                    aria-invalid={this.state.invalid.nominationEndDate} aria-labelledby="nominationEndDateLabel nominationEndDatePrevious" onChange={e => this.onDateChange(e)}
                                    onBlur={e => this.onDateBlur(e, "nominationStartDate", (this.props.showReviewDates === true ? "reviewStartDate" : null))} />
                                <FormFeedback aria-live="polite">{FORM_FEEDBACK_SR_PREFIX}Nomination End must be a valid date later than Nomination Start.</FormFeedback>
                            </Fragment>)
                        }
                    </div>
                </FormGroup>
                    
                {this.props.showReviewDates === true &&
                    <Fragment>
                        {/* Review Start */}
                        <FormGroup data-testid="reviewStartDateFormGroup" className="d-flex flex-wrap">
                            <div className="flex-column" style={{ minWidth: LABEL_COLUMN_MIN_WIDTH }}>
                                <Label id="reviewStartDateLabel" for="reviewStartDate" className="d-inline-block">
                                    <Body1 className={`${this.props.readOnly ? "" : " required"}`}>Review Start:</Body1>
                                </Label>
                                <span id="reviewStartDateInfoTooltip" data-testid="reviewStartDateInfoTooltip" role="tooltip" tabIndex="0"
                                    aria-labelledby="reviewStartDateLabel reviewStartDateInfo" className="d-inline-block p-1">
                                    <Icon type="info-circle" color="blue" aria-hidden="true" />
                                    <span id="reviewStartDateInfo" className="sr-only">{this.state.info.reviewStartDate}</span>
                                    <UncontrolledTooltip placement="top" target="reviewStartDateInfoTooltip" innerClassName="date-info-tooltip">{this.state.info.reviewStartDate}</UncontrolledTooltip>
                                </span>
                                {this.props.previousDates && this.props.previousDates.reviewStartDate &&
                                    <Caption color="gray" id="reviewStartDatePrevious" dataTestId="reviewStartDatePrevious">
                                        {`${PREVIOUS_DATE_LABEL}: ${moment(this.props.previousDates.reviewStartDate).format(this.props.dateFormat)}`}
                                    </Caption>
                                }
                            </div>
                            <div className="flex-column">
                                {this.props.readOnly ?
                                    (<Body1 id="reviewStartDate" name="reviewStartDate" dataTestId="reviewStartDate" className="mt-2">
                                        {this.state.dates.reviewStartDate}
                                    </Body1>) :
                                    (<Fragment>
                                        <Input id="reviewStartDate" name="reviewStartDate" data-testid="reviewStartDate" type="text" style={{ width: INPUT_WIDTH }}
                                            value={this.state.dates.reviewStartDate} invalid={this.state.invalid.reviewStartDate} aria-required={true}
                                            aria-invalid={this.state.invalid.reviewStartDate} aria-labelledby="reviewStartDateLabel reviewStartDatePrevious"
                                            onChange={e => this.onDateChange(e)} onBlur={e => this.onDateBlur(e, "nominationEndDate", "reviewCompletionDate")} />
                                        <FormFeedback aria-live="polite">{FORM_FEEDBACK_SR_PREFIX}Review Start must be a valid date later than Nomination End.</FormFeedback>
                                    </Fragment>)
                                }
                            </div>
                        </FormGroup>

                        {/* Initial Review Completion */}
                        <FormGroup data-testid="reviewCompletionDateFormGroup" className="d-flex flex-wrap">
                            <div className="flex-column" style={{ minWidth: LABEL_COLUMN_MIN_WIDTH }}>
                                <Label id="reviewCompletionDateLabel" for="reviewCompletionDate" className="d-inline-block">
                                    <Body1 className={`${this.props.readOnly ? "" : " required"}`}>Initial Review Completion:</Body1>
                                </Label>
                                <span id="reviewCompletionDateInfoTooltip" data-testid="reviewCompletionDateInfoTooltip" role="tooltip" tabIndex="0"
                                    aria-labelledby="reviewCompletionDateLabel reviewCompletionDateInfo" className="d-inline-block p-1">
                                    <Icon type="info-circle" color="blue" aria-hidden="true" />
                                    <span id="reviewCompletionDateInfo" className="sr-only">{this.state.info.reviewCompletionDate}</span>
                                    <UncontrolledTooltip placement="top" target="reviewCompletionDateInfoTooltip" innerClassName="date-info-tooltip">{this.state.info.reviewCompletionDate}</UncontrolledTooltip>
                                </span>
                                {this.props.previousDates && this.props.previousDates.reviewCompletionDate &&
                                    <Caption color="gray" id="reviewCompletionDatePrevious" dataTestId="reviewCompletionDatePrevious">
                                        {`${PREVIOUS_DATE_LABEL}: ${moment(this.props.previousDates.reviewCompletionDate).format(this.props.dateFormat)}`}
                                    </Caption>
                                }
                            </div>
                            <div className="flex-column">
                                {this.props.readOnly ?
                                    (<Body1 id="reviewCompletionDate" name="reviewCompletionDate" dataTestId="reviewCompletionDate" className="mt-2">
                                        {this.state.dates.reviewCompletionDate}
                                    </Body1>) :
                                    (<Fragment>
                                        <Input id="reviewCompletionDate" name="reviewCompletionDate" data-testid="reviewCompletionDate" type="text" style={{ width: INPUT_WIDTH }}
                                            value={this.state.dates.reviewCompletionDate} invalid={this.state.invalid.reviewCompletionDate} aria-required={true}
                                            aria-invalid={this.state.invalid.reviewCompletionDate} aria-labelledby="reviewCompletionDateLabel reviewCompletionDatePrevious"
                                            onChange={e => this.onDateChange(e)} onBlur={e => this.onDateBlur(e, "reviewStartDate", "reviewEndDate")} />
                                        <FormFeedback aria-live="polite">{FORM_FEEDBACK_SR_PREFIX}Initial Review Completion must be a valid date later than Review Start.</FormFeedback>
                                    </Fragment>)
                                }
                            </div>
                        </FormGroup>

                        {/* Composite Review End */}
                        <FormGroup data-testid="reviewEndDateFormGroup" className="d-flex flex-wrap">
                            <div className="flex-column" style={{ minWidth: LABEL_COLUMN_MIN_WIDTH }}>
                                <Label id="reviewEndDateLabel" for="reviewEndDate" className="d-inline-block">
                                    <Body1 className={`${this.props.readOnly ? "" : " required"}`}>Composite Review End:</Body1>
                                </Label>
                                <span id="reviewEndDateInfoTooltip" data-testid="reviewEndDateInfoTooltip" role="tooltip" tabIndex="0"
                                    aria-labelledby="reviewEndDateLabel reviewEndDateInfo" className="d-inline-block p-1">
                                    <Icon type="info-circle" color="blue" aria-hidden="true" />
                                    <span id="reviewEndDateInfo" className="sr-only">{this.state.info.reviewEndDate}</span>
                                    <UncontrolledTooltip placement="top" target="reviewEndDateInfoTooltip" innerClassName="date-info-tooltip">{this.state.info.reviewEndDate}</UncontrolledTooltip>
                                </span>
                                {this.props.previousDates && this.props.previousDates.reviewEndDate &&
                                    <Caption color="gray" id="reviewEndDatePrevious" dataTestId="reviewEndDatePrevious">
                                        {`${PREVIOUS_DATE_LABEL}: ${moment(this.props.previousDates.reviewEndDate).format(this.props.dateFormat)}`}
                                    </Caption>
                                }
                            </div>
                            <div className="flex-column">
                                {this.props.readOnly ?
                                    (<Body1 id="reviewEndDate" name="reviewEndDate" dataTestId="reviewEndDate" className="mt-2">
                                        {this.state.dates.reviewEndDate}
                                    </Body1>) :
                                    (<Fragment>
                                        <Input id="reviewEndDate" name="reviewEndDate" data-testid="reviewEndDate" type="text" style={{ width: INPUT_WIDTH }} 
                                            value={this.state.dates.reviewEndDate} invalid={this.state.invalid.reviewEndDate} aria-required={true}
                                            aria-invalid={this.state.invalid.reviewEndDate} aria-labelledby="reviewEndDateLabel reviewEndDatePrevious"
                                            onChange={e => this.onDateChange(e)} onBlur={e => this.onDateBlur(e, "reviewCompletionDate", "reviewCommitteeMeetingStartDate")} />
                                        <FormFeedback aria-live="polite">{FORM_FEEDBACK_SR_PREFIX}Composite Review End must be a valid date later than Initial Review Completion.</FormFeedback>
                                    </Fragment>)
                                }
                            </div>
                        </FormGroup>

                        {/* Review Committee Start */}
                        <FormGroup data-testid="reviewCommitteeMeetingStartDateFormGroup" className="d-flex flex-wrap">
                            <div className="flex-column" style={{ minWidth: LABEL_COLUMN_MIN_WIDTH }}>
                                <Label id="reviewCommitteeMeetingStartDateLabel" for="reviewCommitteeMeetingStartDate" className="d-inline-block">
                                    <Body1 className={`${this.props.readOnly ? "" : " required"}`}>Review Committee Start:</Body1>
                                </Label>
                                <span id="reviewCommitteeMeetingStartDateInfoTooltip" data-testid="reviewCommitteeMeetingStartDateInfoTooltip" role="tooltip" tabIndex="0"
                                    aria-labelledby="reviewCommitteeMeetingStartDateLabel reviewCommitteeMeetingStartDateInfo" className="d-inline-block p-1">
                                    <Icon type="info-circle" color="blue" aria-hidden="true" />
                                    <span id="reviewCommitteeMeetingStartDateInfo" className="sr-only">{this.state.info.reviewCommitteeMeetingStartDate}</span>
                                    <UncontrolledTooltip placement="top" target="reviewCommitteeMeetingStartDateInfoTooltip" innerClassName="date-info-tooltip">{this.state.info.reviewCommitteeMeetingStartDate}</UncontrolledTooltip>
                                </span>
                                {this.props.previousDates && this.props.previousDates.reviewCommitteeMeetingStartDate &&
                                    <Caption color="gray" id="reviewCommitteeMeetingStartDatePrevious" dataTestId="reviewCommitteeMeetingStartDatePrevious">
                                        {`${PREVIOUS_DATE_LABEL}: ${moment(this.props.previousDates.reviewCommitteeMeetingStartDate).format(this.props.dateFormat)}`}
                                    </Caption>
                                }
                            </div>
                            <div className="flex-column">
                                {this.props.readOnly ?
                                    (<Body1 id="reviewCommitteeMeetingStartDate" name="reviewCommitteeMeetingStartDate" dataTestId="reviewCommitteeMeetingStartDate" className="mt-2">
                                        {this.state.dates.reviewCommitteeMeetingStartDate}
                                    </Body1>) :
                                    (<Fragment>
                                        <Input id="reviewCommitteeMeetingStartDate" name="reviewCommitteeMeetingStartDate" data-testid="reviewCommitteeMeetingStartDate"
                                            type="text" style={{ width: INPUT_WIDTH }} value={this.state.dates.reviewCommitteeMeetingStartDate} 
                                            invalid={this.state.invalid.reviewCommitteeMeetingStartDate} aria-required={true} aria-invalid={this.state.invalid.reviewCommitteeMeetingStartDate}
                                            aria-labelledby="reviewCommitteeMeetingStartDateLabel reviewCommitteeMeetingStartDatePrevious"
                                            onChange={e => this.onDateChange(e)} onBlur={e => this.onDateBlur(e, "reviewEndDate", "reviewCommitteeMeetingEndDate")} />
                                        <FormFeedback aria-live="polite">{FORM_FEEDBACK_SR_PREFIX}Review Committee Start must be a valid date later than Composite Review End.</FormFeedback>
                                    </Fragment>)
                                }
                            </div>
                        </FormGroup>

                        {/* Review Committee End */}
                        <FormGroup data-testid="reviewCommitteeMeetingEndDateFormGroup" className="d-flex flex-wrap">
                            <div className="flex-column" style={{ minWidth: LABEL_COLUMN_MIN_WIDTH }}>
                                <Label id="reviewCommitteeMeetingEndDateLabel" for="reviewCommitteeMeetingEndDate" className="d-inline-block">
                                    <Body1 className={`${this.props.readOnly ? "" : " required"}`}>Review Committee End:</Body1>
                                </Label>
                                <span id="reviewCommitteeMeetingEndDateInfoTooltip" data-testid="reviewCommitteeMeetingEndDateInfoTooltip" role="tooltip" tabIndex="0"
                                    aria-labelledby="reviewCommitteeMeetingEndDateLabel reviewCommitteeMeetingEndDateInfo" className="d-inline-block p-1">
                                    <Icon type="info-circle" color="blue" aria-hidden="true" />
                                    <span id="reviewCommitteeMeetingEndDateInfo" className="sr-only">{this.state.info.reviewCommitteeMeetingEndDate}</span>
                                    <UncontrolledTooltip placement="top" target="reviewCommitteeMeetingEndDateInfoTooltip" innerClassName="date-info-tooltip">{this.state.info.reviewCommitteeMeetingEndDate}</UncontrolledTooltip>
                                </span>
                                {this.props.previousDates && this.props.previousDates.reviewCommitteeMeetingEndDate &&
                                    <Caption color="gray" id="reviewCommitteeMeetingEndDatePrevious" dataTestId="reviewCommitteeMeetingEndDatePrevious">
                                        {`${PREVIOUS_DATE_LABEL}: ${moment(this.props.previousDates.reviewCommitteeMeetingEndDate).format(this.props.dateFormat)}`}
                                    </Caption>
                                }
                            </div>
                            <div className="flex-column">
                                {this.props.readOnly ?
                                    (<Body1 id="reviewCommitteeMeetingEndDate" name="reviewCommitteeMeetingEndDate" dataTestId="reviewCommitteeMeetingEndDate" className="mt-2">
                                        {this.state.dates.reviewCommitteeMeetingEndDate}
                                    </Body1>) :
                                    (<Fragment>
                                        <Input id="reviewCommitteeMeetingEndDate" name="reviewCommitteeMeetingEndDate" data-testid="reviewCommitteeMeetingEndDate"
                                            type="text" style={{ width: INPUT_WIDTH }} value={this.state.dates.reviewCommitteeMeetingEndDate}
                                            invalid={this.state.invalid.reviewCommitteeMeetingEndDate} aria-required={true} aria-invalid={this.state.invalid.reviewCommitteeMeetingEndDate}
                                            aria-labelledby="reviewCommitteeMeetingEndDateLabel reviewCommitteeMeetingEndDatePrevious"
                                            onChange={e => this.onDateChange(e)} onBlur={e => this.onDateBlur(e, "reviewCommitteeMeetingStartDate", "reviewAccessEndDate")} />
                                        <FormFeedback aria-live="polite">{FORM_FEEDBACK_SR_PREFIX}Review Committee End must be a valid date later than Review Committee Start.</FormFeedback>
                                    </Fragment>)
                                }
                            </div>
                        </FormGroup>

                        {/* Reviewer Access End */}
                        <FormGroup data-testid="reviewAccessEndDateFormGroup" className="d-flex flex-wrap">
                            <div className="flex-column" style={{ minWidth: LABEL_COLUMN_MIN_WIDTH }}>
                                <Label id="reviewAccessEndDateLabel" for="reviewAccessEndDate" className="d-inline-block">
                                    <Body1 className={`${this.props.readOnly ? "" : " required"}`}>Reviewer Access End:</Body1>
                                </Label>
                                <span id="reviewAccessEndDateInfoTooltip" data-testid="reviewAccessEndDateInfoTooltip" role="tooltip" tabIndex="0"
                                    aria-labelledby="reviewAccessEndDateLabel reviewAccessEndDateInfo" className="d-inline-block p-1">
                                    <Icon type="info-circle" color="blue" aria-hidden="true" />
                                    <span id="reviewAccessEndDateInfo" className="sr-only">{this.state.info.reviewAccessEndDate}</span>
                                    <UncontrolledTooltip placement="top" target="reviewAccessEndDateInfoTooltip" innerClassName="date-info-tooltip">{this.state.info.reviewAccessEndDate}</UncontrolledTooltip>
                                </span>
                                {this.props.previousDates && this.props.previousDates.reviewAccessEndDate &&
                                    <Caption color="gray" id="reviewAccessEndDatePrevious" dataTestId="reviewAccessEndDatePrevious">
                                        {`${PREVIOUS_DATE_LABEL}: ${moment(this.props.previousDates.reviewAccessEndDate).format(this.props.dateFormat)}`}
                                    </Caption>
                                }
                            </div>
                            <div className="flex-column">
                                {this.props.readOnly ?
                                    (<Body1 id="reviewAccessEndDate" name="reviewAccessEndDate" dataTestId="reviewAccessEndDate" className="mt-2">
                                        {this.state.dates.reviewAccessEndDate}
                                    </Body1>) :
                                    (<Fragment>
                                        <Input id="reviewAccessEndDate" name="reviewAccessEndDate" data-testid="reviewAccessEndDate"
                                            type="text" style={{ width: INPUT_WIDTH }} value={this.state.dates.reviewAccessEndDate}
                                            invalid={this.state.invalid.reviewAccessEndDate} aria-required={true} aria-invalid={this.state.invalid.reviewAccessEndDate}
                                            aria-labelledby="reviewAccessEndDateLabel reviewAccessEndDatePrevious" disabled={this.props.inReview}
                                            onChange={e => this.onDateChange(e)} onBlur={e => this.onDateBlur(e, "reviewCommitteeMeetingEndDate", "awardPostedDate")} />
                                        <FormFeedback aria-live="polite">{FORM_FEEDBACK_SR_PREFIX}Reviewer Access End must be a valid date later than Review Committee End.</FormFeedback>
                                    </Fragment>)
                                }
                            </div>
                        </FormGroup>
                    </Fragment>
                }

                {/* Awards Posted */}
                {/* Note: If reviewed, must be later than Review Committee End because Reviewer Access End could be extended to provide additional time for system access. */}
                {/* Note: If not reviewed, must be later than Nomination Start (rather than Nomination End) because the award is allowed to be posted within the nomination window. */}
                <FormGroup data-testid="awardPostedDateFormGroup" className="d-flex flex-wrap">
                    <div className="flex-column" style={{ minWidth: LABEL_COLUMN_MIN_WIDTH }}>
                        <Label id="awardPostedDateLabel" for="awardPostedDate" className="d-inline-block">
                            <Body1 className={`${this.props.readOnly ? "" : " required"}`}>Awards Posted:</Body1>
                        </Label>
                        <span id="awardPostedDateInfoTooltip" data-testid="awardPostedDateInfoTooltip" role="tooltip" tabIndex="0"
                            aria-labelledby="awardPostedDateLabel awardPostedDateInfo" className="d-inline-block p-1">
                            <Icon type="info-circle" color="blue" aria-hidden="true" />
                            <span id="awardPostedDateInfo" className="sr-only">{this.state.info.awardPostedDate}</span>
                            <UncontrolledTooltip placement="top" target="awardPostedDateInfoTooltip" innerClassName="date-info-tooltip">{this.state.info.awardPostedDate}</UncontrolledTooltip>
                        </span>
                        {this.props.previousDates && this.props.previousDates.awardPostedDate &&
                            <Caption color="gray" id="awardPostedDatePrevious" dataTestId="awardPostedDatePrevious">
                                {`${PREVIOUS_DATE_LABEL}: ${moment(this.props.previousDates.awardPostedDate).format(this.props.dateFormat)}`}
                            </Caption>
                        }
                    </div>
                    <div className="flex-column">
                        {this.props.readOnly ?
                            (<Body1 id="awardPostedDate" name="awardPostedDate" dataTestId="awardPostedDate" className="mt-2">
                                {this.state.dates.awardPostedDate}
                            </Body1>) :
                            (<Fragment>
                                <Input id="awardPostedDate" name="awardPostedDate" data-testid="awardPostedDate"
                                    type="text" style={{ width: INPUT_WIDTH }} value={this.state.dates.awardPostedDate}
                                    invalid={this.state.invalid.awardPostedDate} aria-required={true} aria-invalid={this.state.invalid.awardPostedDate}
                                    aria-labelledby="awardPostedDateLabel awardPostedDatePrevious" onChange={e => this.onDateChange(e)}
                                    onBlur={e => {
                                        this.onDateBlur(e, 
                                            (this.props.showReviewDates === true ? "reviewCommitteeMeetingEndDate" : "nominationStartDate"), 
                                            (this.props.showAwardLetterDates === true ? "awardLetterSendOptionDate" : "acceptanceDeadlineDate"));
                                    }} />
                                <FormFeedback aria-live="polite">{FORM_FEEDBACK_SR_PREFIX}Awards Posted must be a valid date later than {this.props.showReviewDates === true ? "Review Committee End" : "Nomination Start"}.</FormFeedback>
                            </Fragment>)
                        }
                    </div>
                </FormGroup>

                {this.props.showAwardLetterDates === true &&
                    <Fragment>
                        {/* Award Letters Send Option */}
                        <FormGroup data-testid="awardLetterSendOptionDateFormGroup" className="d-flex flex-wrap">
                            <div className="flex-column" style={{ minWidth: LABEL_COLUMN_MIN_WIDTH }}>
                                <Label id="awardLetterSendOptionDateLabel" for="awardLetterSendOptionDate" className="d-inline-block">
                                    <Body1 className={`${this.props.readOnly ? "" : " required"}`}>Award Letters Send Option:</Body1>
                                </Label>
                                <span id="awardLetterSendOptionDateInfoTooltip" data-testid="awardLetterSendOptionDateInfoTooltip" role="tooltip" tabIndex="0"
                                    aria-labelledby="awardLetterSendOptionDateLabel awardLetterSendOptionDateInfo" className="d-inline-block p-1">
                                    <Icon type="info-circle" color="blue" aria-hidden="true" />
                                    <span id="awardLetterSendOptionDateInfo" className="sr-only">{this.state.info.awardLetterSendOptionDate}</span>
                                    <UncontrolledTooltip placement="top" target="awardLetterSendOptionDateInfoTooltip" innerClassName="date-info-tooltip">{this.state.info.awardLetterSendOptionDate}</UncontrolledTooltip>
                                </span>
                                {this.props.previousDates && this.props.previousDates.awardLetterSendOptionDate &&
                                    <Caption color="gray" id="awardLetterSendOptionDatePrevious" dataTestId="awardLetterSendOptionDatePrevious">
                                        {`${PREVIOUS_DATE_LABEL}: ${moment(this.props.previousDates.awardLetterSendOptionDate).format(this.props.dateFormat)}`}
                                    </Caption>
                                }
                            </div>
                            <div className="flex-column">
                                {this.props.readOnly ?
                                    (<Body1 id="awardLetterSendOptionDate" name="awardLetterSendOptionDate" dataTestId="awardLetterSendOptionDate" className="mt-2">
                                        {this.state.dates.awardLetterSendOptionDate}
                                    </Body1>) :
                                    (<Fragment>
                                        <Input id="awardLetterSendOptionDate" name="awardLetterSendOptionDate" data-testid="awardLetterSendOptionDate"
                                            type="text" style={{ width: INPUT_WIDTH }} value={this.state.dates.awardLetterSendOptionDate}
                                            invalid={this.state.invalid.awardLetterSendOptionDate} aria-required={true} aria-invalid={this.state.invalid.awardLetterSendOptionDate}
                                            aria-labelledby="awardLetterSendOptionDateLabel awardLetterSendOptionDatePrevious" 
                                            onChange={e => this.onDateChange(e)} onBlur={e => this.onDateBlur(e, "awardPostedDate", "awardLetterSentDate")} />
                                        <FormFeedback aria-live="polite">{FORM_FEEDBACK_SR_PREFIX}Awards Letter Send Option must be a valid date later than Awards Posted.</FormFeedback>
                                    </Fragment>)
                                }
                            </div>
                        </FormGroup>

                        {/* Award Letters Sent */}
                        <FormGroup data-testid="awardLetterSentDateFormGroup" className="d-flex flex-wrap">
                            <div className="flex-column" style={{ minWidth: LABEL_COLUMN_MIN_WIDTH }}>
                                <Label id="awardLetterSentDateLabel" for="awardLetterSentDate" className="d-inline-block">
                                    <Body1 className={`${this.props.readOnly ? "" : " required"}`}>Award Letters Sent:</Body1>
                                </Label>
                                <span id="awardLetterSentDateInfoTooltip" data-testid="awardLetterSentDateInfoTooltip" role="tooltip" tabIndex="0"
                                    aria-labelledby="awardLetterSentDateLabel awardLetterSentDateInfo" className="d-inline-block p-1">
                                    <Icon type="info-circle" color="blue" aria-hidden="true" />
                                    <span id="awardLetterSentDateInfo" className="sr-only">{this.state.info.awardLetterSentDate}</span>
                                    <UncontrolledTooltip placement="top" target="awardLetterSentDateInfoTooltip" innerClassName="date-info-tooltip">{this.state.info.awardLetterSentDate}</UncontrolledTooltip>
                                </span>
                                {this.props.previousDates && this.props.previousDates.awardLetterSentDate &&
                                    <Caption color="gray" id="awardLetterSentDatePrevious" dataTestId="awardLetterSentDatePrevious">
                                        {`${PREVIOUS_DATE_LABEL}: ${moment(this.props.previousDates.awardLetterSentDate).format(this.props.dateFormat)}`}
                                    </Caption>
                                }
                            </div>
                            <div className="flex-column">
                                {this.props.readOnly ?
                                    (<Body1 id="awardLetterSentDate" name="awardLetterSentDate" dataTestId="awardLetterSentDate" className="mt-2">
                                        {this.state.dates.awardLetterSentDate}
                                    </Body1>) :
                                    (<Fragment>
                                        <Input id="awardLetterSentDate" name="awardLetterSentDate" data-testid="awardLetterSentDate"
                                            type="text" style={{ width: INPUT_WIDTH }} value={this.state.dates.awardLetterSentDate}
                                            invalid={this.state.invalid.awardLetterSentDate} aria-required={true} aria-invalid={this.state.invalid.awardLetterSentDate}
                                            aria-labelledby="awardLetterSentDateLabel awardLetterSendOptionDatePrevious" onChange={e => this.onDateChange(e)}
                                            onBlur={e => this.onDateBlur(e, "awardLetterSendOptionDate", "acceptanceDeadlineDate")} />
                                        <FormFeedback aria-live="polite">{FORM_FEEDBACK_SR_PREFIX}Awards Letter Sent must be a valid date later than Award Letters Send Option.</FormFeedback>
                                    </Fragment>)
                                }
                            </div>
                        </FormGroup>
                    </Fragment>
                }

                {/* Acceptance Deadline */}
                <FormGroup data-testid="acceptanceDeadlineDateFormGroup" className="d-flex flex-wrap">
                    <div className="flex-column" style={{ minWidth: LABEL_COLUMN_MIN_WIDTH }}>
                        <Label id="acceptanceDeadlineDateLabel" for="acceptanceDeadlineDate" className="d-inline-block">
                            <Body1 className={`${this.props.readOnly ? "" : " required"}`}>Acceptance Deadline:</Body1>
                        </Label>
                        <span id="acceptanceDeadlineDateInfoTooltip" data-testid="acceptanceDeadlineDateInfoTooltip" role="tooltip" tabIndex="0"
                            aria-labelledby="acceptanceDeadlineDateLabel acceptanceDeadlineDateInfo" className="d-inline-block p-1">
                            <Icon type="info-circle" color="blue" aria-hidden="true" />
                            <span id="acceptanceDeadlineDateInfo" className="sr-only">{this.state.info.acceptanceDeadlineDate}</span>
                            <UncontrolledTooltip placement="top" target="acceptanceDeadlineDateInfoTooltip" innerClassName="date-info-tooltip">{this.state.info.acceptanceDeadlineDate}</UncontrolledTooltip>
                        </span>
                        {this.props.previousDates && this.props.previousDates.acceptanceDeadlineDate &&
                            <Caption color="gray" id="acceptanceDeadlineDatePrevious" dataTestId="acceptanceDeadlineDatePrevious">
                                {`${PREVIOUS_DATE_LABEL}: ${moment(this.props.previousDates.acceptanceDeadlineDate).format(this.props.dateFormat)}`}
                            </Caption>
                        }
                    </div>
                    <div className="flex-column">
                        {this.props.readOnly ?
                            (<Body1 id="acceptanceDeadlineDate" name="acceptanceDeadlineDate" dataTestId="acceptanceDeadlineDate" className="mt-2">
                                {this.state.dates.acceptanceDeadlineDate}
                            </Body1>) :
                            (<Fragment>
                                <Input id="acceptanceDeadlineDate" name="acceptanceDeadlineDate" data-testid="acceptanceDeadlineDate"
                                    type="text" style={{ width: INPUT_WIDTH }} value={this.state.dates.acceptanceDeadlineDate}
                                    invalid={this.state.invalid.acceptanceDeadlineDate} aria-required={true} aria-invalid={this.state.invalid.acceptanceDeadlineDate}
                                    aria-labelledby="acceptanceDeadlineDateLabel acceptanceDeadlineDatePrevious" onChange={e => this.onDateChange(e)}
                                    onBlur={e => this.onDateBlur(e, (this.props.showAwardLetterDates === true ? "awardLetterSentDate" : "awardPostedDate"), "awardEndDate")} />
                                <FormFeedback aria-live="polite">{FORM_FEEDBACK_SR_PREFIX}Acceptance Deadline must be a valid date later than {this.props.showAwardLetterDates === true ? "Award Letters Sent" : "Awards Posted"}.</FormFeedback>
                            </Fragment>)
                        }
                    </div>
                </FormGroup>

                {/* Award End */}
                <FormGroup data-testid="awardEndDateFormGroup" className="d-flex flex-wrap">
                    <div className="flex-column" style={{ minWidth: LABEL_COLUMN_MIN_WIDTH }}>
                        <Label id="awardEndDateLabel" for="awardEndDate" className="d-inline-block">
                            <Body1 className={`${this.props.readOnly ? "" : " required"}`}>Award End:</Body1>
                        </Label>
                        <span id="awardEndDateInfoTooltip" data-testid="awardEndDateInfoTooltip" role="tooltip" tabIndex="0" aria-labelledby="awardEndDateLabel awardEndDateInfo" className="d-inline-block p-1">
                            <Icon type="info-circle" color="blue" aria-hidden="true" />
                            <span id="awardEndDateInfo" className="sr-only">{this.state.info.awardEndDate}</span>
                            <UncontrolledTooltip placement="top" target="awardEndDateInfoTooltip" innerClassName="date-info-tooltip">{this.state.info.awardEndDate}</UncontrolledTooltip>
                        </span>
                        {this.props.previousDates && this.props.previousDates.awardEndDate &&
                            <Caption color="gray" id="awardEndDatePrevious" dataTestId="awardEndDatePrevious">
                                {`${PREVIOUS_DATE_LABEL}: ${moment(this.props.previousDates.awardEndDate).format(this.props.dateFormat)}`}
                            </Caption>
                        }
                    </div>
                    <div className="flex-column">
                        {this.props.readOnly ?
                            (<Body1 id="awardEndDate" name="awardEndDate" dataTestId="awardEndDate" className="mt-2">
                                {this.state.dates.awardEndDate}
                            </Body1>) :
                            (<Fragment>
                                <Input id="awardEndDate" name="awardEndDate" data-testid="awardEndDate" type="text" style={{ width: INPUT_WIDTH }} 
                                    value={this.state.dates.awardEndDate} invalid={this.state.invalid.awardEndDate} disabled={this.state.dateWindowLocked}
                                    aria-required={true} aria-invalid={this.state.invalid.awardEndDate} aria-labelledby="awardEndDateLabel awardEndDatePrevious"
                                    onChange={e => this.onDateChange(e)} onBlur={e => this.onDateBlur(e, "acceptanceDeadlineDate", null)} />
                                <FormFeedback aria-live="polite">{FORM_FEEDBACK_SR_PREFIX}Award End Date must be a valid date later than Acceptance Deadline.</FormFeedback>
                            </Fragment>)
                        }
                    </div>
                </FormGroup>
            </FormGroup>
        );
    }
}

AwardDates.defaultProps = {
    dateFormat: "M/D/YYYY h:mmA",
    showReviewDates: false,
    showAwardLetterDates: false,
    inReview: false
}

AwardDates.propTypes = {
    dateFormat: PropTypes.string,
    dates: PropTypes.shape({
        nominationStartDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]).isRequired,
        nominationEndDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]).isRequired,
        reviewStartDate: RequiredIf(PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]), props => props.showReviewDates === true),
        reviewCompletionDate: RequiredIf(PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]), props => props.showReviewDates === true),
        reviewEndDate: RequiredIf(PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]), props => props.showReviewDates === true),
        reviewCommitteeMeetingStartDate: RequiredIf(PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]), props => props.showReviewDates === true),
        reviewCommitteeMeetingEndDate: RequiredIf(PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]), props => props.showReviewDates === true),
        reviewAccessEndDate: RequiredIf(PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]), props => props.showReviewDates === true),
        awardPostedDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]).isRequired,
        awardLetterSendOptionDate: RequiredIf(PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]), props => props.showAwardLetterDates === true),
        awardLetterSentDate: RequiredIf(PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]), props => props.showAwardLetterDates === true),
        acceptanceDeadlineDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]).isRequired,
        awardEndDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]).isRequired
    }),
    previousDates: PropTypes.shape({
        nominationStartDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]).isRequired,
        nominationEndDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]).isRequired,
        reviewStartDate: RequiredIf(PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]), props => props.showReviewDates === true),
        reviewCompletionDate: RequiredIf(PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]), props => props.showReviewDates === true),
        reviewEndDate: RequiredIf(PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]), props => props.showReviewDates === true),
        reviewCommitteeMeetingStartDate: RequiredIf(PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]), props => props.showReviewDates === true),
        reviewCommitteeMeetingEndDate: RequiredIf(PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]), props => props.showReviewDates === true),
        reviewAccessEndDate: RequiredIf(PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]), props => props.showReviewDates === true),
        awardPostedDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]).isRequired,
        awardLetterSendOptionDate: RequiredIf(PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]), props => props.showAwardLetterDates === true),
        awardLetterSentDate: RequiredIf(PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]), props => props.showAwardLetterDates === true),
        acceptanceDeadlineDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]).isRequired,
        awardEndDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]).isRequired
    }),
    onValidate: PropTypes.func,
    readOnly: PropTypes.bool,
    showReviewDates: PropTypes.bool,
    showAwardLetterDates: PropTypes.bool,
    inReview: PropTypes.bool
}

export default AwardDates;