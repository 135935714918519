import {
    ALL_AWARDS, ALL_AWARDS_ERROR, ALL_AWARDS_LOADING,
    AWARD_INFO, AWARD_INFO_STATUS,
    CURRENT_AWARDS, SYSTEM_AWARD, TYPES_OF_GPA, SCORING_CRITERIA, CURRENT_AWARDS_STATUS
} from "../actions-index";

export function allAwards(state = null, action = {}) {
    return action.type === ALL_AWARDS ? action.payload : state;
}

export function allAwardsError(state = false, action = {}) {
    return action.type === ALL_AWARDS_ERROR ? action.payload : state;
}

export function allAwardsLoading(state = false, action = {}) {
    return action.type === ALL_AWARDS_LOADING ? action.payload : state;
}

export function currentAwards(state = [], action) {
    switch (action.type) {
        case CURRENT_AWARDS:
            return {
                ...state,
                list: action.payload,
                _list: action.payload,
                status: 'success'
            }
        case "PREVIOUS_AWARDS":
            return {
                ...state,
                prevList: action.payload,
                _prevList: action.payload,
                status: 'success'
            }
        case CURRENT_AWARDS_STATUS:
            return {
                ...state,
                status: action.payload
            }
        default:
            return state
    }
}

export function systemAward(state = {}, action) {
    switch (action.type) {
        case SYSTEM_AWARD:
            return action.payload
        default:
            return state
    }
}

export function gpaTypes(state = [], action) {
    switch (action.type) {
        case TYPES_OF_GPA:
            return action.payload
        default:
            return state
    }
}


export function scoringCriteria(state = [], action) {
    switch (action.type) {
        case SCORING_CRITERIA:
            return action.payload
        default:
            return state
    }
}

export function awardInfo(state = {}, action = {}) {
    switch(action.type) {
        case AWARD_INFO:
            return { ...state, data: action.payload };
        case AWARD_INFO_STATUS:
            return { ...state, status: action.payload };
        default:
            return state;
    }
}