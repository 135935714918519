import {withRouter} from "react-router-dom";
import {connect} from 'react-redux';
import PdfView from '../components/index';
import { getViewDocument } from '../actions';

const mapStateToProps = (state, ownProps) => {
    const { systemAward } = state
    let defaultAwardPk = systemAward.pk

    return {
        viewDocument: state.viewDocument,
        viewDocumentIsLoading: state.viewDocumentIsLoading,
        viewDocumentIsError: state.viewDocumentIsError,
        defaultAwardPk: defaultAwardPk
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getViewDocument: (documentId,documentSaveName,awardPK) => dispatch(getViewDocument(documentId,documentSaveName,awardPK))
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PdfView));

