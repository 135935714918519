import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import NomineesContent from "../components";
import { getAllNominees, updateAllNomineesSort, updateNomineesDetails, searchAwardNominees, addAwardNomineesFilter, refreshDocumentList, calculateFinalRankedScores } from "../../../actions-index";
import { forEach, uniq } from 'lodash'
import {setSystemAwardValues} from '../../../Common/actions'

const mapStateToProps = (state, ownProps) => {
    const { getWeightedScoreDefaultValue } = ownProps;
    const { awardNominees } = state;
    const { list = [], details = [], status = '', sortKey = {}, filters = [], query } = awardNominees;
    const programDropdown = constructShortPlanKeys(awardNominees.list);
    const systemAward = state.systemAward || {};
    const reviewInfo = systemAward.reviewInfo || {};
    const rubric = reviewInfo.rubric || {};
    const scores = rubric.scores || [];
    const nominees = Array.isArray(list) ?  list.filter(nominee => nominee && nominee.status && (nominee.status.includes("AWARDED") || nominee.status === "NOMINATED")).map(nominee => {
        const nom = nominee;

        nominee.reviewers =  nominee.reviewers ? nominee.reviewers.map(reviewer => {return {...reviewer, ...reviewer.reviewer}}): [];

        const det = nominee;
        const res = {...nom, ...det};
        return res;
    }) : [];
    return {
        systemAward,
        processingFinalRankedScores: state.processingFinalRankedScores,
        nominees,
        nomineesCsvHeaders: getNomineesCsvHeaders(scores),
        nomineesCsvData: getNomineesCsvData(scores, Array.isArray(nominees)  ? nominees : []),
        nomineesDetails: Array.isArray(details) ? details : [],
        gpaTypes: Array.isArray(state.gpaTypes) ? state.gpaTypes : [],
        docList: awardNominees.docList,
        awardKey: systemAward.pk || null,
        status,
        sortKey,
        filters,
        query,
        scoringCriteria: state.scoringCriteria,
        programDropdown,
        defaultScoreValue: getWeightedScoreDefaultValue(filters),
        defaultShortPlanValue: getShortPlanValue(filters, programDropdown)
    };
};

function getNomineesCsvHeaders(scores) {
    const buildCsvHeader = (label, key) => {
        return { label, key };
    };
    const csvHeaders = [
        buildCsvHeader("Name", "name"),
        buildCsvHeader("ID", "id"),
        buildCsvHeader("Name.#", "nameN"),
        buildCsvHeader("Nomination(s)", "nominatedFor"),
        buildCsvHeader("Academic Program", "program"),
        buildCsvHeader("Cumulative GPA", "gpa"),
        buildCsvHeader("Reviewer Ranked Score", "rankedScore"),
        buildCsvHeader("Final Ranked Score", "finalRankedScore"),
        buildCsvHeader("Reviewer Name", "reviewerName"),
        buildCsvHeader("Reviewer Name.#", "reviewerNameN"),
        buildCsvHeader("Reviewer Type", "reviewerType"),

    ];
    forEach(scores, score => {
        csvHeaders.push(buildCsvHeader(score.name, score.field));
    });
    csvHeaders.push(buildCsvHeader("Weighted Score", "weightedScore"));
    csvHeaders.push(buildCsvHeader("Below Min Score", "belowMinScore"));
    return csvHeaders;
}

function getNomineesCsvData(scores, nominees) {
    let data = [];
    forEach(nominees, nominee => {
        let belowMinScore = false;
        for (let i = 0; i < scores.length; i++) {
            let average = 0;

            const score = scores[i];
            if(nominee.reviewers && Array.isArray(nominees.reviewers) && nominees.reviewers.length > 0 ){

                nominee.reviewers.forEach(reviewer => {
                    const nomineeScore = reviewer[score.field];
                    if(!nomineeScore || nomineeScore.value === 0 || nomineeScore.value === "-" || average === "-") {
                        average = "-";
                    } else {
                        average += Number(nomineeScore.value);
    
                    }
                })
                if(average !== "-") {
                    average = average / nominee.reviewers.length;
                }
                if (average < score.minimumEligibilityScore) {
                    belowMinScore = true;
                }
            }

        }

        let nomineeData = {
            name: nominee.name,
            id: nominee.nomineeId,
            nameN: nominee.nameN,
            nominatedFor: nominee.nominatedFor,
            program: nominee.displayShortPlan,
            finalWeightedScore: nominee.finalWeightedScore,
            belowMinScore: belowMinScore ? "Y" : "N",
            finalRankedScore: nominee.finalRankedScore,
            gpa: nominee.cumGpa
        };
        if (nominee.reviewers && nominee.reviewers.length > 0) {
            forEach(nominee.reviewers, reviewer => {
                let nomineeReviewerData = {
                    ...nomineeData,
                    reviewerName: reviewer.name,
                    rankedScore: reviewer.rankedScore,
                    reviewerNameN: reviewer.email ? reviewer.email.substring(0, reviewer.email.indexOf("@")) : "",
                    reviewerType: reviewer.type === "PRIMARY" ? "Composite" : reviewer.type.includes("SECONDARY") ? "Other" : ""
                };
                forEach(scores, score => {
                    const nomineeReviewerScore = reviewer[score.field];
                    nomineeReviewerData[score.field] = (nomineeReviewerScore && nomineeReviewerScore.value) ? nomineeReviewerScore.value : "";
                });
                nomineeReviewerData.weightedScore = reviewer.finalWeightedScore || "";
                data.push(nomineeReviewerData);
            });
        } else {
            data.push(nomineeData);
        }
    });
    return data;
}

function getShortPlanValue(filters = [], shortPlans = []) {
    if (!Array.isArray(filters) || !Array.isArray(shortPlans) || shortPlans.length < 1 || filters.length < 1) {
        return []
    }
    const matches = [];

    shortPlans.filter(plan => plan.value).forEach(plan => {
        filters.filter(o => o.type === 'displayShortPlan').forEach(filter => {
            const { value } = plan
            if (value.key === filter.key) {
                matches.push(plan)
            }
        })
    });

    return matches
}
const constructShortPlanKeys = (nominees = []) => {
    const convert = (shortplan = '') => shortplan.includes('-') ? shortplan.split("-")[0] : shortplan
    const shortPlans = Array.isArray(nominees) ? uniq(nominees.filter(o => o && o.displayShortPlan && typeof o.displayShortPlan === 'string').map(nom => convert(nom.displayShortPlan))).sort() : [];
    const dropdownVals = shortPlans.map(plan => ({
        label: plan,
        value: {
            singleSelect: true,
            type: 'displayShortPlan',
            key: plan
        }
    }));

    return dropdownVals
};

const mapDispatchToProps = (dispatch) => {
    return {
        getAllNominees: (awardKey, sortKey) => dispatch(getAllNominees(awardKey, sortKey)),
        updateSortKey: (sortKey) => dispatch(updateAllNomineesSort(sortKey)),
        updateNomineesDetails: (values) => dispatch(updateNomineesDetails(values)),
        searchAwardNominees: (query) => dispatch(searchAwardNominees(query)),
        addAwardNomineesFilter: (filter) => dispatch(addAwardNomineesFilter(filter)),
        refreshDocumentList: (nomineeId, shortPlan, awardKey, applicationNumber) => dispatch(refreshDocumentList(nomineeId, shortPlan, awardKey, applicationNumber)),
        calculateFinalRankedScores: (awardKey) => dispatch(calculateFinalRankedScores(awardKey)),
        setSystemAwardValues: (systemAward) => dispatch(setSystemAwardValues(systemAward))

    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NomineesContent));

