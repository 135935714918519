import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import AwardSwitchboard from '../components';
import { getCurrentAwards, setSystemAwardValues, getPreviousAwards } from '../../actions';

const mapStateToProps = (state, ownProps) => {
    const { currentAwards } = state;
    return {
        awards: currentAwards.list || [],
        previousAwards: currentAwards.prevList || [],
        systemAward: state.systemAward,
        status: currentAwards.status
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getCurrentAwards: () => dispatch(getCurrentAwards(0, "/nominee-reviews/award-selection")),
        setSystemAwardValues: (award) => dispatch(setSystemAwardValues(award)),
        getPreviousAwards: () => dispatch(getPreviousAwards())
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AwardSwitchboard));

