import React, { Component } from "react";

export default class A11yMessage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            a11yMessage: null
        }
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.text) {
            this.setState({ a11yMessage: nextProps.text })
            setTimeout(() => {
                this.setState({ a11yMessage: null })
            }, 500);
        }
    }

    render() {
        return (
            <div className="sr-only" role="status" aria-live="polite" aria-atomic="true">
                {this.state.a11yMessage}
            </div>
        );
    }
};