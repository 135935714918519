import React, { Component } from "react";
import { Alert, Card, CardBody, CardTitle, Label, Input, InputGroup, InputGroupAddon, InputGroupText } from "reactstrap";
import { Heading5, OSUButton, OSULoading, Table } from "osu-react-components";
import _ from "lodash";
import "../ReviewerManagement.css";
import {PAGE_TITLE_POSTFIX} from "../../constants";

export default class AddReviewer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageTitle: "Add Reviewer",
            searchQuery: "",
            searchResultsKeys: [
                { key: "name", label: "Name", width: 34 },
                { key: "nameDotN", label: "Name.#", width: 33 },
                { key: "emplid", label: "Employee ID", width: 33 }
            ]
        }
    }

    componentWillMount() {
        document.title = this.state.pageTitle +  + PAGE_TITLE_POSTFIX;
    }

    componentDidMount() {
        this.props.setA11yMessage(`Navigated to the ${this.state.pageTitle} page`);
        this.pageContent.focus();
    }
    
    componentDidUpdate(prevProps) {
        if(prevProps.searchProcessing === true && this.props.searchProcessing === false) {
            this.props.setA11yMessage(`Search returned ${this.props.searchResults.length} results`)
        }

        if(prevProps.searchResults.length === 0 && this.props.searchResults.length > 0) {
            _.forEach(this.props.searchResults, reviewer => {
                const name = `${reviewer.lastName}, ${reviewer.firstName}${reviewer.middleInitial ? (" " + reviewer.middleInitial) : ""}`;
                reviewer.name = (
                    <OSUButton className="p-0 text-left" uppercase={false} variant="body1" ariaLabel={`Navigate to reviewer details for ${name}`}
                        path={{
                            pathname: `/reviewer-management/reviewer/${reviewer.emplid}`,
                            state: { breadcrumb: { path: "/reviewer-management/add-reviewer", text: "Add Reviewer" } }
                        }}
                    >{name}</OSUButton>  
                );
            });
        }
    }

    handleSearchInputKeyPress = (e) => {
        if(e.charCode === 13) { // Enter
            document.getElementById("searchButton").click();
        }
    }

    render() {
        return (
            <div ref={(el) => { this.pageContent = el; }} tabIndex="-1" aria-labelledby="pageHeader">
                <Card>
                    <CardTitle className="rounded-top">
                        <Heading5 id="pageHeader" className="mt-2 ml-4">
                            {this.state.pageTitle}
                        </Heading5>
                        <hr className="mt-2" />
                    </CardTitle>
                    <CardBody className="px-4 pt-0" style={{minHeight: "36.5rem"}}>
                        {this.props.searchError &&
                            <div className="d-flex flex-column flex-md-row">
                                <Alert data-testid="search-error" color="danger" className="w-100" toggle={this.props.resetSearchGraduateFacultyError}>An error occurred while processing your search</Alert>
                            </div>
                        }
                        <div className="d-flex flex-column flex-md-row" style={{minWidth: "16rem"}}>
                            <InputGroup>
                                <Label for="search-input" hidden>Search for Reviewer</Label>
                                <InputGroupAddon addonType="prepend">
                                    <InputGroupText>
                                        <i className="fa fa-search" />
                                    </InputGroupText>
                                </InputGroupAddon>
                                <Input id="search-input" data-testid="search-input" type="text" defaultValue={this.props.searchQuery} 
                                    placeholder={this.props.screenSize >= 520 ? "Search by Last Name, Name.#, or Employee ID" : "Search Text"} className="mr-2" 
                                    onChange={(e) => { this.setState({searchQuery: e.target.value}) }} onKeyPress={this.handleSearchInputKeyPress} />
                                <OSUButton solid color="blue" id="searchButton" ariaLabel="Search Graduate Faculty" disabled={this.state.searchQuery === ""} onClick={() => this.props.searchGraduateFaculty(this.state.searchQuery)}>Search</OSUButton>
                            </InputGroup>
                        </div>
                        <div className="d-flex flex-column flex-md-row">
                            {this.props.searchProcessing &&
                                <OSULoading dataTestId="search-processing" text="Search processing..." className="mt-4 mx-auto" />
                            }
                            {!this.props.searchProcessing &&
                                <Table dataTestId="search-results" className="search-results" headerVariant="subtitle2" dataKeys={this.state.searchResultsKeys} data={this.props.searchResults} noDataMessage="No Results" />
                            }
                        </div>
                    </CardBody>
                </Card>
            </div>
        );
    }
};