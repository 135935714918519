import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { updateNominationCardForm } from "../../../actions-index";
import NominationsCardForm from "../components/Form";
import { submitNominationCardForm } from "../../actions";
import { matchCurrentRecordToAwardedSk } from '../transform'
import { isEmpty, pick } from "lodash";
import { ACTION_STATE_ERROR, ACTION_STATE_LOADING } from "../../../util/constants";

const mapStateToProps = (state = {}, props = {}) => {
    const { nomineeCard = {}, awardInfo = { data: { awardResultInfo: { awardResults: [] }}} } = state
    const { record = { }, form = {}, submission = {} } = (nomineeCard || {})
    const { data: awardData = {} } = awardInfo;
    const { nominationInfo = {} } = awardData;
    const { additionalQuestions = [] } = nominationInfo;
    let questions = {};
    if(additionalQuestions.length > 0) {
        const nomineeQuestions = (!isEmpty(record.additionalQuestions) ? pick(record.additionalQuestions, additionalQuestions) : {});
        const awardQuestions = {};
        additionalQuestions.forEach(additionalQuestion => {
            awardQuestions[additionalQuestion] = "";
        });
        questions = { ...awardQuestions, ...nomineeQuestions };
    }
    let awardResults = awardInfo.data && awardInfo.data.awardResultInfo ? awardInfo.data.awardResultInfo.awardResults : []
    let nomineeSK = nomineeCard.record && nomineeCard.record.sk
    let nomineeName = nomineeCard.record && (nomineeCard.record.nameDisplay || nomineeCard.record.name)
    const awardedOption = matchCurrentRecordToAwardedSk(awardResults, nomineeSK) || { awardResultABBV: '' }
    const adminComments = (form.adminComments || record.adminComments) || undefined
    const nomineeAward = 
        form.hasOwnProperty('nomineeAward') 
        ? form.nomineeAward 
        : record.hasOwnProperty('updatedNomineeAwarded') 
        ? record.updatedNomineeAwarded 
        : awardedOption.awardResultABBV
    let formStages = Array.isArray(submission.stages) ? submission.stages : []
    let formStatuses = []
    formStages.forEach(stage => {
        if(submission[stage]) {
            formStatuses.push(submission[stage])
        }
    })
    let formAlert = {}
    if(formStatuses.includes(ACTION_STATE_ERROR)) {
        formAlert.text = submission.errorMessage || 'There was an error saving your form. Please review your changes and try saving again.'
        formAlert.color = 'danger'
        formAlert.type = 'error'
    } else if (formStatuses.length > 0 && formStatuses.every(status => status === 'success')) {
        formAlert.text = 'Success! Your changes have been saved.'
        formAlert.color = 'success'
        formAlert.type = 'success'
    }
    let nominatedByEmplid = record.hasOwnProperty('nominatedByEmplid') ? record.nominatedByEmplid : null;
    let nominatedByNameDotN = record.hasOwnProperty('nominatedByNameN') ? record.nominatedByNameN : null;
    let nominatedByDate = record.hasOwnProperty('nominatedDate') ? record.nominatedDate : null;

    return {
        nomineeAward,
        awardLastUpdated: '',
        adminComments,
        nomineeName,
        nominatedByEmplid,
        nominatedByNameDotN,
        nominatedByDate,
        formStatuses,
        formContainsLoading: submission.status === 'loading' || formStatuses.includes(ACTION_STATE_LOADING),
        formAlert,
        formIsEmpty: isEmpty(form),
        loadingText: formStatuses.includes(ACTION_STATE_LOADING) ? submission.description : '',
        questions,
        programStatus: (record.programStatusDescr || null)
    };
};

const mapDispatchToProps = (dispatch) => ({
    saveChanges: (changes) => dispatch(updateNominationCardForm(changes)),
    submitForm: () => dispatch(submitNominationCardForm())
}); 

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NominationsCardForm));
