import React, { Component } from 'react';
import { OSUButton, Body1, OSULoading, Heading6 } from 'osu-react-components';
import "../AwardAcceptance.css";
import Error from './Error';
import logo from '../logo.png';
import { ACTION_STATE_LOADING } from "../../util/constants";

class AwardAcceptance extends Component {
    constructor(props) {
        super(props);
        const params = new URLSearchParams(this.props.location.search);
        const token = params.get('token');
        sessionStorage.setItem('token', token);
        this.submitOnClick = this.submitOnClick.bind(this);

        this.state = {};
    }

    componentDidMount(prevProps) {
        console.log("The component did indeed mount: ", this.props);
        if (!Object.keys(this.props.tokenData).length) {
            this.props.validateToken(sessionStorage.getItem('token'));
        }
    }

    componentDidUpdate(prevProps) {
        console.log("component did update: ", this.props);

        console.log("state: ", this.state);
    }

    radioOnClick(event) {
        console.log("clicked: ", event);
        this.setState({
            awardSelection: event
        })
    }

    submitOnClick(event) {
        this.props.submitAcceptance(sessionStorage.getItem('token'), this.state.awardSelection);

        this.setState({
            isSubmitted: true
        });
    }

    renderCompletion() {
        if (this.props.tokenData.studentType === "NEWADMIT" && this.state.awardSelection === "accepted") {
            return <div className="errorOrLoadingText">
                <img src={logo} alt='OSU Logo' className='logo' />
                <Heading6>Welcome to the Ohio State University, and congratulations on your Fellowship!</Heading6>
                <Body1>Please expect to receive further documentation, <br />including the paperwork necessary to set up your stipend payments and other concerns, in late July.</Body1>
            </div>
        }
        else if (this.props.tokenData.studentType !== "NEWADMIT" && this.state.awardSelection === "accepted") {
            return <div className="errorOrLoadingText">
                <img src={logo} alt='OSU Logo' className='logo' />
                <Heading6>Thank you for accepting your award.</Heading6>
                <Body1>If you have any further questions regarding your award please contact the Graduate School at <br /><a href="mailto:grad-schoolfellowships@osu.edu">grad-schoolfellowships@osu.edu</a>.</Body1>
            </div>
        }
        else if (this.state.awardSelection === "declined") {
            return <div className="errorOrLoadingText">
                <img src={logo} alt='OSU Logo' className='logo' />
                <Heading6>Thank you for your your response.</Heading6>
                <Body1>We appreciate your time, and wish you well in your coming endeavors.</Body1>
            </div>
        }

    }

    render() {
        let { status, name, plan, terminationDate, acceptanceResult } = this.props.tokenData;
        let { awardSelection } = this.state;
        let acceptanceState = this.props.acceptanceState;
        let tokenState = this.props.tokenState;
        console.log("acceptance state: ", this.props.acceptanceState);
        console.log("acceptance data: ", this.props.acceptancedata);
        console.log("acceptanceResult: ", acceptanceResult);

        if (this.props.acceptanceState === "error")
            status = "error";

        if (acceptanceResult)
            status = "alreadyDone";


        return <div className="ml-3">
            {acceptanceState !== ACTION_STATE_LOADING && tokenState !== ACTION_STATE_LOADING  ? <div>
                {this.state.isSubmitted && acceptanceState !== "error" ? this.renderCompletion() : <div>
                    {status === "error" || status === "invalid" || status === "expired" || status === "alreadyDone" ? <Error error={status} terminationDate={terminationDate}></Error> : <div>

                        <h1 className="formHeadingsLarge">Welcome {name}</h1>
                        <Body1>{plan}</Body1>
                        <br />
                        <Body1 className="display-linebreak">Congratulations on receiving your award from the Graduate School. For details regarding your award please see the letter that was attached in your email. If you have any questions regarding your award details please contact the Graduate School at <a href="mailto:grad-schoolfellowships@osu.edu">grad-schoolfellowships@osu.edu</a></Body1>
                        <br />
                        <h2 className="formHeadingsMedium">Please provide the information below to accept/decline your award by the deadline listed in your letter.</h2>
                        <input type="radio" value="accepted" name="awardChoice" onClick={() => this.radioOnClick("accepted")} /> Accept my award
                            <br />
                        <input type="radio" value="declined" name="awardChoice" onClick={() => this.radioOnClick("declined")} /> Decline my award
                            <br />
                        <OSUButton ariaLabel="Submit Award Acceptance" className="submitButton" onClick={this.submitOnClick} disabled={!awardSelection}>Submit</OSUButton>
                    </div>}
                </div>}
            </div> : <OSULoading text="Loading..." />}
        </div>
    }
}

export default AwardAcceptance;
