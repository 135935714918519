import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import AwardManagement from "../components";
import { getCurrentAwards, getPreviousAwards, setSystemAwardValues } from "../../actions-index";

const mapStateToProps = (state, props) => {
    const { currentAwards = {} } = state;
    return {
        awards: currentAwards.list || [],
        previousAwards: currentAwards.prevList || [],
        awardsStatus: currentAwards.status,
        props
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getCurrentAwards: () => dispatch(getCurrentAwards()),
        getPreviousAwards: () => dispatch(getPreviousAwards()),
        setSystemAwardValues: (award) => dispatch(setSystemAwardValues(award)),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AwardManagement));
