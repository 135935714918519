import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import CurrentStudent from "../components/CurrentStudent";
import { clearAllFilters, handleFilter } from "../../NomineeReview/util";
import { dismissReviewAlert } from "../../actions-index";
import withAwardDates from "./withAwardDates";

const mapStateToProps = (state, ownProps) => {
    return {
        reviewAlertDismissed: state.reviewAlertDismissed,
        systemAward: state.systemAward,
        handleFilter: handleFilter,
        clearAllFilters: clearAllFilters,
        currentAwards: state.currentAwards,
        parentContainer: "CurrentStudent"
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        dismissReviewAlert: () => dispatch(dismissReviewAlert())
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withAwardDates(CurrentStudent)));
