import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import ManageCommittee from "../components/ManageCommittee";
import { findReviewerNomineesByAward } from "../../../actions-index";

const mapStateToProps = (state, props) => {
  return {
    nominees: state.awardReviewerNominees,
    nomineesError: state.awardReviewerNomineesError,
    nomineesLoading: state.awardReviewerNomineesLoading,
    props
  };
};

const mapDispatchToProps = dispatch => {
  return {
    findNominees: (awardKey, reviewerId) => dispatch(findReviewerNomineesByAward(awardKey, reviewerId))
  };
};

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(ManageCommittee));
