import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import NominationsTab from "../components/NominationsTab";
import { getCurrentAwardInfo, getAllNomineesForAllAwards, changePreviousAwardsCheckbox, updateAllNomineesSort, updateNomineesDetails, searchAwardNominees, addAwardNomineesFilter, refreshDocumentList, calculateFinalRankedScores, grantAwardToSelected } from "../../actions-index";
import { forEach, uniq } from 'lodash'
import { filterNomineesByParentContainer } from "../transform";
import { getCurrentAwards, getPreviousAwards } from '../../Common/actions';

const mapStateToProps = (state, ownProps) => {
    const { parentContainer, location } = ownProps;
    const { awardNominees, currentAwards } = state;
    const allAwards = {
        list: typeof state.currentAwards.list === "object" ? state.currentAwards.list.concat(state.currentAwards.prevList) : []
    }
    const { isPreviousAwardsBoxChecked } = awardNominees;
    const academicProgramContext = location.pathname.split("/")[4];
    const { list = [], details = [], status = '', sortKey = {}, filters = [], query, _list = [] } = awardNominees;
    const programDropdown = constructShortPlanKeys(awardNominees.list);
    const systemAward = state.systemAward || {};
    const nominees = Array.isArray(list) && Array.isArray(currentAwards.list) ? filterNomineesByParentContainer(parentContainer, list) : [];
    const filteredAwards = isPreviousAwardsBoxChecked ? filterAwardsByParentContainer(allAwards, parentContainer) : filterAwardsByParentContainer(currentAwards, parentContainer);
    const filterLists =  buildFilterLists(_list, filteredAwards, isPreviousAwardsBoxChecked);
    const filteredNominees = nominees &&  currentAwards.list && currentAwards.list.length > 0 ? nominees.filter(nominee => currentAwards.list.some(award => nominee.pk === award.pk) || isPreviousAwardsBoxChecked) : [];
    return {
        filterLists,
        currentAwards,
        isPreviousAwardsBoxChecked,
        getCurrentAwardInfoResult: awardNominees.getCurrentAwardInfoResult,
        processingFinalRankedScores: state.processingFinalRankedScores,
        nominees: nominees,
        nomineesDetails: Array.isArray(details) ? details : [],
        gpaType: parentContainer === "CurrentStudent" ? "cumGpa" : "externalUgrdGpa",
        docList: awardNominees.docList,
        awardKey: systemAward.pk || null,
        status,
        academicProgramContext,
        sortKey,
        awardNominees: state.awardNominees,
        filters,
        query,
        fullList: _list,
        scoringCriteria: state.scoringCriteria,
        programDropdown,
        defaultShortPlanValue: getShortPlanValue(filters, programDropdown),
        nomineesCsvHeaders: getNomineesCsvHeaders(),
        nomineesCsvData: getNomineesCsvData(filteredNominees, parentContainer, currentAwards),
    };
};

function filterAwardsByParentContainer(awards, parentContainer) {
    let filteredAwards = [];
    if(parentContainer === "Recruiting" && awards.list) {
        filteredAwards = awards.list.filter(award => award.studentType === "NEWADMIT")
    }
    if(parentContainer === "CurrentStudent" && awards.list) {
        filteredAwards = awards.list.filter(award => award.studentType === "ENROLLED")
    }
    return {
        list: filteredAwards
    }
}

function buildFilterLists(nominees, awardList, isPreviousAwardsBoxChecked) {
    let nominations = [];
    let statuses = [];
    let shortPlans = [];
    let colleges = [];
    let awards = [];
    const filteredNominees = nominees.filter(nominee => awardList.list.some(award => nominee.pk === award.pk));
    filteredNominees.forEach(nominee => {
        const nominationLabels = nominations.map(nomination => nomination.label);
        const statusLabels = statuses.map(status => status.label);
        const shortPlanLabels = shortPlans.map(shortPlan => shortPlan.label);
        const collegeLabels = colleges.map(college => college.label);
        const awardLabels = awards.map(award => award.label);

        if (!nominationLabels.includes(nominee.nominatedFor)) {
            nominations.push({
                label: nominee.nominatedFor,
                value: {
                    key: nominee.nominatedFor,
                    singleSelect: false,
                    type: "nominatedFor"
                }
            });
        }

        if (!statusLabels.includes(nominee.status)) {
            statuses.push({
                label: nominee.status,
                value: {
                    key: nominee.status,
                    singleSelect: false,
                    type: "status"
                }
            });
        }

        if (!shortPlanLabels.includes(nominee.shortPlan)) {
            shortPlans.push({
                label: nominee.shortPlan,
                value: {
                    key: nominee.shortPlan,
                    singleSelect: false,
                    type: "shortPlan"
                }
            });
        }

        if (!collegeLabels.includes(nominee.college)) {
            colleges.push({
                label: nominee.college,
                value: {
                    key: nominee.college,
                    singleSelect: false,
                    type: "college"
                }
            });
        }

        let label = nominee.pk;
        if (typeof awardList.list === "object") {
            awardList.list.forEach(award => {
                let endDate = new Date() - new Date(award.eDate);
                if (nominee.pk === award.pk && (endDate < 0 || isPreviousAwardsBoxChecked)) {
                    label = award.name;
                    if (!awardLabels.includes(label)) {
                        awards.push({
                            label: label,
                            value: {
                                key: nominee.pk,
                                singleSelect: false,
                                type: "pk"
                            }
                        });
                    }
                }
            });
        };

    });
    return {
        nominations,
        statuses,
        shortPlans,
        colleges,
        awards,
    }
}

function getNomineesCsvHeaders() {
    const buildCsvHeader = (label, key) => {
        return { label, key };
    };
    const csvHeaders = [
        buildCsvHeader("Admission Term", "admitTerm"),
        buildCsvHeader("Application Number", "applicationNbr"),
        buildCsvHeader("Award Key", "pk"),
        buildCsvHeader("Birth Country", "birthCountry"),
        buildCsvHeader("Career", "career"),
        buildCsvHeader("College", "college"),
        buildCsvHeader("ID", "data"),
        buildCsvHeader("Multiple Nominations", "multipleNominations"),
        buildCsvHeader("Email", "email"),
        buildCsvHeader("External Graduate GPA", "externalGradGpa"),
        buildCsvHeader("External Undergaaduate GPA", "externalUgrdGpa"),
        buildCsvHeader("First Name", "firstName"),
        buildCsvHeader("Last Name", "lastName"),
        buildCsvHeader("Display Name", "nameDisplay"),
        buildCsvHeader("Nomination", "nominatedFor"),
        buildCsvHeader("Nominee ID", "nomineeId"),
        buildCsvHeader("PCD Flag", "pcdFlag"),
        buildCsvHeader("Program Status", "programStatus"),
        buildCsvHeader("Program Status Descriptiion", "programStatusDescr"),
        buildCsvHeader("Academic Program", "shortPlan"),
        buildCsvHeader("Academic Program Description", "shortPlanDescription"),
        buildCsvHeader("Nominee ID Key", "sk"),
        buildCsvHeader("Status", "status"),
        buildCsvHeader("stdntCarNbr", "stdntCarNbr"),

        buildCsvHeader("Academic Year", "awardKeyAcademicYear"),
        buildCsvHeader("Award Term", "awardKeyAwardTerm"),
        buildCsvHeader("Category", "awardKeyCategory"),
        buildCsvHeader("Award Name", "awardName"),
        buildCsvHeader("Student Status", "studentType"),
        // buildCsvHeader("Award Result Name", "awardResultName"),
        buildCsvHeader("Degree", "degree"),
        buildCsvHeader("Country of Citizenship", "citizenshipCountry"),

    ];
    return csvHeaders;
}

function getNomineesCsvData(nominees, parentContainer, currentAwards) {
    let data = [];
    forEach(nominees, nominee => {
        let deconstructedAwardKey = nominee.awardKey.split("#");

        nominee.awardKeyAcademicYear = deconstructedAwardKey[deconstructedAwardKey.length - 3];
        nominee.awardKeyAwardTerm = deconstructedAwardKey[deconstructedAwardKey.length - 2];
        nominee.awardKeyCategory = deconstructedAwardKey[deconstructedAwardKey.length - 1];
        nominee.studentType = parentContainer === "CurrentStudent" ? "CURRENT STUDENT" : "NEW ADMIT";

        let emplid = nominee.data;
        let emplidList = nominees.map(nominee => nominee.data); //data on nominee record is emplid
        let firstIndex = emplidList.indexOf(emplid);
        let lastIndex = emplidList.lastIndexOf(emplid);
        nominee.multipleNominations = firstIndex !== lastIndex ? "YES" : "";

        let currentAward = Array.isArray(currentAwards.list) ? currentAwards.list.find(award => award.pk === nominee.pk) : undefined;
        nominee.awardName = currentAward !== undefined ? currentAward.name : "";
        nominee.awardResultName = currentAward !== undefined ? currentAward.awardResultName : "";
        data.push(nominee);
    });
    return data;
}

function getShortPlanValue(filters = [], shortPlans = []) {
    if (!Array.isArray(filters) || !Array.isArray(shortPlans) || shortPlans.length < 1 || filters.length < 1) {
        return []
    }
    const matches = [];

    shortPlans.filter(plan => plan.value).forEach(plan => {
        filters.filter(o => o.type === 'displayShortPlan' || o.type === 'nominatedFor').forEach(filter => {
            const { value } = plan
            if (value.key === filter.key) {
                matches.push(plan)
            }
        })
    });

    return matches
}
const constructShortPlanKeys = (nominees = []) => {
    const convert = (shortplan = '') => shortplan.includes('-') ? shortplan.split("-")[0] : shortplan
    const shortPlans = uniq(nominees.filter(o => o.nominatedFor && typeof o.nominatedFor === 'string').map(nom => convert(nom.nominatedFor))).sort()
    const dropdownVals = shortPlans.map(plan => ({
        label: plan,
        value: {
            singleSelect: true,
            type: 'nominatedFor',
            key: plan
        }
    }));

    return dropdownVals
};

const mapDispatchToProps = (dispatch) => {
    return {
        getAllNomineesForAllAwards: (sortKey, academicProgram) => dispatch(getAllNomineesForAllAwards(sortKey, academicProgram)),
        updateSortKey: (sortKey) => dispatch(updateAllNomineesSort(sortKey)),
        updateNomineesDetails: (values) => dispatch(updateNomineesDetails(values)),
        searchAwardNominees: (query) => dispatch(searchAwardNominees(query)),
        addAwardNomineesFilter: (filter) => dispatch(addAwardNomineesFilter(filter)),
        refreshDocumentList: (nomineeId, shortPlan, awardKey, applicationNumber) => dispatch(refreshDocumentList(nomineeId, shortPlan, awardKey, applicationNumber)),
        calculateFinalRankedScores: (awardKey) => dispatch(calculateFinalRankedScores(awardKey)),
        grantAwardToSelected: (awardKey, nominees, awarded, systemAward) => dispatch(grantAwardToSelected(awardKey, nominees, awarded, systemAward)),
        getCurrentAwardInfo: (awardKey) => dispatch(getCurrentAwardInfo(awardKey)),
        changePreviousAwardsCheckbox: (e) => dispatch(changePreviousAwardsCheckbox(e)),
        getCurrentAwards: () => dispatch(getCurrentAwards(0, "/nominee-reviews/award-selection")),
        getPreviousAwards: () => dispatch(getPreviousAwards())
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NominationsTab));

