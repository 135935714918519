import { VIEW_DOCUMENT, VIEW_DOCUMENT_ERROR, VIEW_DOCUMENT_LOADING} from '../actions-index';

export function viewDocument(state = {}, action) {
    switch (action.type) {
        case VIEW_DOCUMENT:
            return action.payload
        default:
            return state
    }
}

export function viewDocumentIsError(state = false, action) {
    switch (action.type) {
        case VIEW_DOCUMENT_ERROR:
            return action.payload
        default:
            return state
    }
}

export function viewDocumentIsLoading(state = false, action) {
    switch (action.type) {
        case VIEW_DOCUMENT_LOADING:
            return action.payload
        default:
            return state
    }
}