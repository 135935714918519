import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { DELETE_AWARD_STATE, deleteAward, findReviewersByAward, getAwardInfo, getGraduatePrograms, updateAward, UPDATE_AWARD_STATE } from "../../../actions-index";
import { buildAction } from "../../../util/util";
import AwardDetails from "../components";

const mapStateToProps = (state, props) => {
    const { data: awardData = null, status: awardStatus = "" } = state.awardInfo;
    const { data: graduatePrograms = null, state: graduateProgramsState = "" } = state.graduatePrograms;
    return {
        award: awardData,
        awardStatus,
        deleteAwardState: state.deleteAwardState,
        graduatePrograms,
        graduateProgramsState,
        reviewers: state.awardReviewers,
        reviewersError: state.awardReviewersError,
        reviewersLoading: state.awardReviewersLoading,
        updateAwardState: state.updateAwardState,
        props
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        deleteAward: (awardKey) => dispatch(deleteAward(awardKey)),
        getAwardDetails: (awardKey) => dispatch(getAwardInfo(awardKey)),
        getGraduatePrograms: () => dispatch(getGraduatePrograms()),
        findReviewersByAward: (awardKey) => dispatch(findReviewersByAward(awardKey)),
        resetDeleteAwardState: () => dispatch(buildAction(DELETE_AWARD_STATE, "")),
        resetUpdateAwardState: () => dispatch(buildAction(UPDATE_AWARD_STATE, "")),
        updateAward: (award) => dispatch(updateAward(award))
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AwardDetails));
