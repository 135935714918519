import React from 'react'
import { Body2, OSUError, OSUButton, Icon, OSULoading } from 'osu-react-components'
import PropTypes from 'prop-types'
import ReviewSheetTable from '../../ReviewSheetTable/components'
import { Row, Input, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap'
import IconLegend from "../../components/IconLegend";
import { redirectToLogOut} from "../../../util/util";
import {Auth} from "aws-amplify";

class PrimaryReviewContent extends React.Component {
    constructor(props) {
        super(props);
        this.finalWeightedScoreSelectRef = React.createRef();
    }

    componentDidMount() {
        this.fetchPrimaryReviewList(this.props.awardKey)
    }

    componentDidUpdate() {
        // set aria-label on Final Weighted Score filter dropdown (cannot set directly when isSearchable=false)
        if(this.finalWeightedScoreSelectRef.current) {
            var finalWeightedScoreSelectInputRef = this.finalWeightedScoreSelectRef.current.select.inputRef;
            if(!finalWeightedScoreSelectInputRef.getAttribute("aria-label")) {
                finalWeightedScoreSelectInputRef.setAttribute("aria-label", "Filter table by Final Weighted Score");
            }
        }
    }

    fetchPrimaryReviewList(awardKey) {
        const key = this.props.sortKey && this.props.sortKey.key ? this.props.sortKey.key : 'random'
        const direction = this.props.sortKey && this.props.sortKey.direction ? this.props.sortKey.direction : ''

        this.props.getPrimaryReviewList(awardKey, { key, direction })
    }

    unsuccessfulScreen(status) {
        const errorScreen = this.props.awardKey ?  <OSUError ariaLabel="Error occurred. Reload primary reviewers for this award." text="Could not fetch primary reviewers for award." actionText="Retry" onClick={() => this.fetchPrimaryReviewList(this.props.awardKey)} />
        : <OSUError ariaLabel="Error occurred. Navigate to award selection." text="Could not verify award." actionText="Go to Award Selection" path={'/nominee-reviews/award-selection'} />

        if (status === 'loading') {
            return <OSULoading text="Loading primary reviewers for award" />
        }else if(status==='401'){
            Auth.signOut() && redirectToLogOut();
            //return <OSUError ariaLabel="You are logged out. Close your browser and login again." text="You are logged out. Close your browser and login again." actionText="Login" onClick={() => redirectToLogIn()} />
        }
        return errorScreen
    }

    render() {
        const { updateSortKey, noOfReviewers, sortKey, updatePrimaryReviewDetails, primaryReviewList, primaryReviewDetails, primaryReviewListStatus, query, scoringCriteria, addPrimaryReviewFilter, filters, searchPrimaryReviewList } = this.props
        if (primaryReviewListStatus !== 'success') {
            return this.unsuccessfulScreen(primaryReviewListStatus)
        }
        
        // const handleFilterProps = {
        //     activeFilters: filters,
        //     filterHandler: addPrimaryReviewFilter
        // };
        return <div>
            <IconLegend icons={[
                // { type: "bell", ariaLabel: "Other reviews are submitted.", color: "gray", size: "xs" },
                { type: "check", ariaLabel: "Reviews are complete and in range.", color: "green", size: "xs" },
                // { type: "exclamation-triangle", ariaLabel: "Scores out of assigned ranges.", color: "red", size: "xs" },
                // { type: "info", ariaLabel: "Category minimum qualifying score not met.", color: "gray", size: "xs" },
                { type: "commenting-o", ariaLabel: "Comments available to view.", color: "gray", size: "xs" },
                { type: "asterisk", ariaLabel: "Reviewed by admin.", color: "black", size: "xs" }
            ]} />
            <Body2 className="mb-1 d-flex align-items-center">Filter Content | <OSUButton uppercase={false} variant="body2" ariaLabel="Clear all filters" link onClick={() => this.props.clearAllFilters(filters, addPrimaryReviewFilter, searchPrimaryReviewList)}>Clear All</OSUButton></Body2>
            <Row className="no-gutters align-items-center">
                <InputGroup className="col-4 pl-0 pr-2">
                    <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                            <Icon type="search" color="gray" />
                        </InputGroupText>
                    </InputGroupAddon>
                    <Input type="text" aria-label="Search by Nominee ID or Name" placeholder={`Search by Nominee ID or Name`} value={query} onChange={(e) => searchPrimaryReviewList(e.target.value)} />
                </InputGroup>
                <div className="col-4 px-0 d-flex">
                    {/* <Select placeholder="Final Weighted Score" ref={this.finalWeightedScoreSelectRef} isClearable value={defaultScoreValue} isSearchable={false} className="w-100 pr-2" onChange={range => handleFilter({ ...handleFilterProps, filter: range, type: "rubric" })} options={weightedScoreRanges} /> */}
                </div>
            </Row>
            <ReviewSheetTable tabIdentifier={this.props.tabIdentifier} noOfReviewers={noOfReviewers} sortKey={sortKey} updateSortKey={updateSortKey} list={primaryReviewList} details={primaryReviewDetails} updateDetails={updatePrimaryReviewDetails} scoringCriteria={scoringCriteria} />
        </div>
    }
}


PrimaryReviewContent.defaultProps = {
    primaryReviewList: []
};

PrimaryReviewContent.propTypes = {
    tabIdentifier: PropTypes.shape({
        index: PropTypes.number,
        type: PropTypes.string
    }),
    primaryReviewList: PropTypes.array
};

export default PrimaryReviewContent