import React, { Component } from 'react';
import { Route } from "react-router-dom";
import { ProtectedRoute, Breadcrumb, OSULoading, SessionTimer } from 'osu-react-components';
import { isEmpty, intersection, toInteger } from 'lodash';
import ReviewProcess from '../../NomineeReview/containers/ReviewProcess'
import EvaluationItem from '../../NomineeReview/Evaluation/containers/Item';
import Recruiting from '../../Nominations/containers/Recruiting';
import OSUNavBar from 'react-osu-navbar';
import '../App.css';

import SignInContainer from '../../Authentication/containers/SignIn';
import SignOutContainer from '../../Authentication/containers/SignOut';
import Amplify, { Auth } from 'aws-amplify';
import aws_exports from '../../aws-exports';
import apiConfig from '../../api-config';
import CustomAuthStorage from '../../util/CustomAuthStorage';
import Header from '../../Common/Header/containers';
import AwardSwitchboard from '../../Common/AwardSwitchboard/containers';
import AllReviewers from '../../ReviewerManagement/containers/AllReviewers';
import ReviewerSwitchboard from '../../ReviewerManagement/containers/ReviewerSwitchboard';
import CommitteeTabs from '../../ReviewerManagement/CommitteeTabs/containers';
import AddReviewer from '../../ReviewerManagement/containers/AddReviewer';
import ReviewerDetails from '../../ReviewerManagement/containers/ReviewerDetails';
import AwardManagement from '../../AwardManagement/containers/';
import CreateAward from '../../AwardManagement/containers/CreateAward';
import AwardDetails from '../../AwardManagement/AwardDetails/containers';
import ViewPdf from '../../PDFView/containers';
import AwardAcceptance from '../../AwardAcceptance/containers'
import UnauthorizedError from "./UnauthorizedError";
import A11yMessage from "./A11yMessage";
import Nominations from "../../Nominations/containers";
import AddStudent from '../../Nominations/containers/AddStudent';
import Footer from "../../Common/Footer/components";
import NominationsCard from '../../Nominations/Card/containers';
import CurrentStudent from '../../Nominations/containers/CurrentStudent';

const NO_ROLES_ALLOWED = "";

const userRoleAttributeKey = process.env.REACT_APP_USER_ROLE_ATTRIBUTE_KEY;
const allowedRolesAdmin = process.env.REACT_APP_ALLOWED_ROLES_ADMIN ? process.env.REACT_APP_ALLOWED_ROLES_ADMIN.split(",") : NO_ROLES_ALLOWED;
const timeoutInMinutes = !isEmpty(process.env.REACT_APP_SESSION_TIMEOUT_IN_MINUTES) ? toInteger(process.env.REACT_APP_SESSION_TIMEOUT_IN_MINUTES) : 60;

Auth.configure({ storage: new CustomAuthStorage(apiConfig.customAuthStorage) });
Amplify.configure({ ...aws_exports, ...apiConfig });

export default class App extends Component {
    constructor() {
        super();
        this.state = {
            shouldLogOutAllActiveTabs: false,
            screenSize: null
        };

    }

    componentDidMount() {
        this.updateResponsiveness();
        window.addEventListener("resize", this.updateResponsiveness.bind(this));
        if (this.props.location.pathname !== "/signout" && this.props.location.pathname !== "/signin" && this.props.location.pathname !== "/award-acceptance") {
            !isEmpty(this.props.user) ? this.handleLoggedInUserFlow() : this.props.setUser();
        }
    }

    componentDidUpdate(prevProps) {
        if (!isEmpty(this.props.user) && prevProps.user !== this.props.user) {
            this.handleLoggedInUserFlow()
        }
    }

    handleLoginClick = () => { this.props.setUser(); }
    handleLogoutClick = () => {
        if(!this.state.shouldLogOutAllActiveTabs){
            this.setState({shouldLogOutAllActiveTabs:true})

        }
    }

    async handleLoggedInUserFlow() {
        if (this.props.location.pathname === '/nominations/' ||
            this.props.location.pathname === '/nominee-reviews/award-selection' || 
            this.props.location.pathname === '/nominee-reviews/') {
            return null
        }
        if (this.props.location.pathname === '/' || this.props.location.pathname === '/signin') {
            if(this.props.isNominator && this.props.allowedRolesReviewer.length === 0) {
                this.props.history.push('/nominations/');
            } else {
                this.props.history.push('nominee-reviews/award-selection');
            }
        }

        const { currentAwards, currentAwardsStatus, getCurrentAwards, user } = this.props
        const promiseCallCurrentAwards = new Promise(function (resolve, reject) {
            if (user && (!Array.isArray(currentAwards) || currentAwards.length < 1) && currentAwardsStatus !== 'loading') {
                resolve(getCurrentAwards())
            }
        });

        await promiseCallCurrentAwards;
        if(this.props.isNominator && this.props.allowedRolesReviewer.length === 0) {
            this.props.history.push('/nominations/');
        } else if(isEmpty(this.props.systemAward)) {
            return this.props.history.push('/nominee-reviews/award-selection');
        } else {
            return this.props.history.push('/nominee-reviews/');
        }
    }

    updateResponsiveness() {
        this.setState({ screenSize: window.innerWidth });
    }

    signOut = () => {
        Auth.signOut();
        // other browser windows require clearing the user and redirecting to signout
        if (!isEmpty(this.props.user)) this.props.clearUser();
        if (this.props.location.pathname !== "/signout") this.props.history.push("/signout");
    };

    render() {
        const { systemAward, user, allowedRolesAll, allowedRolesReviewer, currentAwardsStatus, setA11yMessage, isNominator } = this.props;
        const userRoles = user && user[`${userRoleAttributeKey}`] ? user[`${userRoleAttributeKey}`].split(",") : [];
        const isAdmin = intersection(userRoles, allowedRolesAdmin).length > 0;
        const isReviewer = systemAward && systemAward.pk && typeof systemAward.pk === 'string' ? userRoles.includes(systemAward.pk.toUpperCase()) : false;
        const errorComponent = !isEmpty(user) ? UnauthorizedError : () => (<h2>Please Log In</h2>);

        const path = this.props.history.location.pathname;

        const exceptionPaths = ["/award-acceptance"];

        let shouldDisplayHeader = true;
        
        exceptionPaths.forEach(exceptionPath => {
            if(exceptionPath.includes(path)){
                shouldDisplayHeader = false;
            }
        })
        return (
            <SessionTimer shouldLogOutAllActiveTabs={this.state.shouldLogOutAllActiveTabs} timeoutInMinutes={timeoutInMinutes} signOut={this.signOut} enabled={!isEmpty(this.props.user)}>
                <div className="App  main-container">
                    <A11yMessage text={this.props.a11yMessage} />
                    <OSUNavBar />
                    {shouldDisplayHeader && <Header systemAward={systemAward} isAdmin={isAdmin} isReviewer={isReviewer || this.props.allowedRolesReviewer.length >  0} isNominator={isNominator} pathName={this.props.location.pathname}
                        handleLogin={this.handleLoginClick} handleLogOut={this.handleLogoutClick} screenSize={this.state.screenSize} />}
                    <main id="page-content" className="app-body mb-5">
                        {!isEmpty(this.props.breadcrumbTrail) && <Breadcrumb className="mb-3" pathOptions={this.props.breadcrumbTrail} />}
                        <Route exact path='/signin' render={() => <SignInContainer />} />
                        <Route exact path='/signout' render={() => <SignOutContainer />} />
                        {currentAwardsStatus === 'loading' && !isAdmin && !isEmpty(this.props.user)
                            ? <OSULoading text="Loading awards" />
                            : renderRoutes(user, isAdmin, isReviewer, isNominator, allowedRolesAll, this.state.screenSize, errorComponent, setA11yMessage, allowedRolesReviewer)}
                    </main>

                    <Footer pathName={this.props.location.pathname || ""} />
                </div>
            </SessionTimer>
        );
    }
}

const renderRoutes = (user, isAdmin, isReviewer, isNominator, allowedRolesAll, screenSize, errorComponent, setA11yMessage, allowedRolesReviewer) => [
    <ProtectedRoute
        path="/nominee-reviews"
        key="/nominee-reviews"
        component={ReviewProcess}
        errorComponent={errorComponent}
        user={user}
        isAdmin={isAdmin}
        isReviewer={isReviewer}
        // userIsLoading={this.props.userIsLoading}
        userRoleAttributeKey={userRoleAttributeKey}
        allowedRoles={allowedRolesReviewer}
        screenSize={screenSize}
        setA11yMessage={setA11yMessage}
    />,
    <ProtectedRoute
        path="/nominee-reviews/award-selection"
        key="/nominee-reviews/award-selection"
        component={AwardSwitchboard}
        errorComponent={errorComponent}
        user={user}
        // userIsLoading={this.props.userIsLoading}
        userRoleAttributeKey={userRoleAttributeKey}
        allowedRoles={allowedRolesReviewer}
        setA11yMessage={setA11yMessage}
    />,
    <ProtectedRoute
        path="/nominee-reviews/evaluation/:id/:shortPlan"
        key="/nominee-reviews/evaluation/:id/:shortPlan"
        component={EvaluationItem}
        errorComponent={errorComponent}
        user={user}
        // userIsLoading={this.props.userIsLoading}
        userRoleAttributeKey={userRoleAttributeKey}
        allowedRoles={allowedRolesReviewer}
        setA11yMessage={setA11yMessage}
    />,
    <ProtectedRoute
        path='/ViewPdf/:id'
        key='/ViewPdf/:id'
        component={ViewPdf}
        errorComponent={errorComponent}
        user={user}
        userRoleAttributeKey={userRoleAttributeKey}
        allowedRoles={allowedRolesAll}
        setA11yMessage={setA11yMessage}
    />,
    <ProtectedRoute
        path="/reviewer-management/all-reviewers"
        key="/reviewer-management/all-reviewers"
        component={AllReviewers}
        errorComponent={errorComponent}
        user={user}
        // userIsLoading={this.props.userIsLoading}
        userRoleAttributeKey={userRoleAttributeKey}
        allowedRoles={allowedRolesAll}
        setA11yMessage={setA11yMessage}
    />,
    <ProtectedRoute
        path="/reviewer-management/"
        key="/reviewer-management/"
        component={ReviewerSwitchboard}
        errorComponent={errorComponent}
        user={user}
        // userIsLoading={this.props.userIsLoading}
        userRoleAttributeKey={userRoleAttributeKey}
        allowedRoles={allowedRolesAll}
        setA11yMessage={setA11yMessage}
    />,
    <ProtectedRoute
        path="/reviewer-management/committee"
        key="/reviewer-management/committee"
        component={CommitteeTabs}
        errorComponent={errorComponent}
        user={user}
        // userIsLoading={this.props.userIsLoading}
        userRoleAttributeKey={userRoleAttributeKey}
        allowedRoles={allowedRolesAll}
        screenSize={screenSize}
        setA11yMessage={setA11yMessage}
    />,
    <ProtectedRoute
        path="/reviewer-management/add-reviewer"
        key="/reviewer-management/add-reviewer"
        component={AddReviewer}
        errorComponent={errorComponent}
        user={user}
        // userIsLoading={this.props.userIsLoading}
        userRoleAttributeKey={userRoleAttributeKey}
        allowedRoles={allowedRolesAll}
        screenSize={screenSize}
        setA11yMessage={setA11yMessage}
    />,
    <ProtectedRoute
        path="/reviewer-management/reviewer/:emplid"
        key="/reviewer-management/reviewer/:emplid"
        component={ReviewerDetails}
        errorComponent={errorComponent}
        user={user}
        // userIsLoading={this.props.userIsLoading}
        userRoleAttributeKey={userRoleAttributeKey}
        allowedRoles={allowedRolesAll}
        setA11yMessage={setA11yMessage}
    />,
    <ProtectedRoute
        path="/award-management/"
        key="/award-management/"
        component={AwardManagement}
        errorComponent={errorComponent}
        user={user}
        // userIsLoading={this.props.userIsLoading}
        userRoleAttributeKey={userRoleAttributeKey}
        allowedRoles={allowedRolesAll}
        setA11yMessage={setA11yMessage}
    />,
    <ProtectedRoute
        path="/award-management/create-award"
        key="/award-management/create-award"
        component={CreateAward}
        errorComponent={errorComponent}
        user={user}
        // userIsLoading={this.props.userIsLoading}
        userRoleAttributeKey={userRoleAttributeKey}
        allowedRoles={allowedRolesAll}
        setA11yMessage={setA11yMessage}
    />,
    <ProtectedRoute
        path="/award-management/award-details/:awardKey"
        key="/award-management/award-details/:awardKey"
        component={AwardDetails}
        errorComponent={errorComponent}
        user={user}
        // userIsLoading={this.props.userIsLoading}
        userRoleAttributeKey={userRoleAttributeKey}
        allowedRoles={allowedRolesAll}
        setA11yMessage={setA11yMessage}
    />,
    <ProtectedRoute
        path="/nominations/competitions/recruiting/:shortPlan"
        key="/nominations/competitions/recruiting/:shortPlan"
        component={Recruiting}
        errorComponent={errorComponent}
        user={user}
        isAdmin={isAdmin}
        isNominator={isNominator}
        // userIsLoading={this.props.userIsLoading}
        userRoleAttributeKey={userRoleAttributeKey}
        allowedRoles={allowedRolesAll}
        setA11yMessage={setA11yMessage}
    />,
    <ProtectedRoute
        path="/nominations/competitions/recruiting/:shortPlan/add"
        key="/nominations/competitions/recruiting/:shortPlan/add"
        component={AddStudent}
        errorComponent={errorComponent}
        user={user}
        isAdmin={isAdmin}
        isNominator={isNominator}
        // userIsLoading={this.props.userIsLoading}
        userRoleAttributeKey={userRoleAttributeKey}
        allowedRoles={allowedRolesAll}
        setA11yMessage={setA11yMessage}
    />,
    <ProtectedRoute
    path="/nominations/competitions/currentstudent/:shortPlan"
    key="/nominations/competitions/currentstudent/:shortPlan"
    component={CurrentStudent}
    errorComponent={errorComponent}
    user={user}
    isAdmin={isAdmin}
    isNominator={isNominator}
    // userIsLoading={this.props.userIsLoading}
    userRoleAttributeKey={userRoleAttributeKey}
    allowedRoles={allowedRolesAll}
    setA11yMessage={setA11yMessage}
/>,
    <ProtectedRoute
        path="/nominations/competitions/currentstudent/:shortPlan/add"
        key="/nominations/competitions/currentstudent/:shortPlan/add"
        component={AddStudent}
        errorComponent={errorComponent}
        user={user}
        isAdmin={isAdmin}
        isNominator={isNominator}
        // userIsLoading={this.props.userIsLoading}
        userRoleAttributeKey={userRoleAttributeKey}
        allowedRoles={allowedRolesAll}
        setA11yMessage={setA11yMessage}
    />,
    <ProtectedRoute
        path="/nominations/"
        key="/nominations/"
        component={Nominations}
        errorComponent={errorComponent}
        user={user}
        isAdmin={isAdmin}
        isNominator={isNominator}
        // userIsLoading={this.props.userIsLoading}
        userRoleAttributeKey={userRoleAttributeKey}
        allowedRoles={allowedRolesAll}
        setA11yMessage={setA11yMessage}
    />,
    <ProtectedRoute
        path="/nominations/card/:nomineeId/:shortPlan/:awardKey/"
        key="/nominations/card/:nomineeId/:shortPlan/:awardKey/"
        component={NominationsCard}
        errorComponent={errorComponent}
        user={user}
        isAdmin={isAdmin}
        isNominator={isNominator}
        // userIsLoading={this.props.userIsLoading}
        userRoleAttributeKey={userRoleAttributeKey}
        allowedRoles={allowedRolesAll}
        setA11yMessage={setA11yMessage}
    />,
    <Route path="/award-acceptance" key="/award-acceptance" component={AwardAcceptance}/>
];
