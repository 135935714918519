import { API, Auth} from "aws-amplify";
import axios from "axios";
import { isEmpty } from "lodash";
import { 
  getCurrentAwards, getGraduatePrograms, DELETE_NOMINEE_DOCUMENT_STATE, TERMS_STATE, TERMS, ADD_STUDENT_SEARCH_DATA_STATE, ADD_STUDENT_SEARCH_DATA,
  SUBMIT_NOMINATIONS_RESULTS_STATE, SUBMIT_NOMINATIONS_RESULTS, UPLOAD_NOMINEE_PACKET_ID, UPLOAD_NOMINEE_PACKET_STATE, UPLOAD_NOMINEE_PETITION_ID,
  UPLOAD_NOMINEE_PETITION_STATE, NOMINEE_CARD_PACKET, NOMINEE_CARD_FORM, NOMINEE_CARD_RECORD, NOMINEE_CARD_FORM_CLEAR, NOMINEE_CARD_FORM_SUBMISSION, 
  DELETE_NOMINEE_DOCUMENT, grantAwardToSelected, UPDATE_NOMINEE_CARD_AWARDED, NOMINEE_CARD_SAVE, NOMINEE_CARD_INFO, REFRESH_NOMINEE_STATE, REFRESHED_NOMINEE,
  AWARD_NOMINEES_STATUS
} from "../actions-index";
import { ACTION_STATE_ERROR, ACTION_STATE_LOADING, ACTION_STATE_SUCCESS } from "../util/constants";
import { buildAction, handleUnauthenticated, systemHeaders } from "../util/util";
import { NOMINATION_CARD_POST_VALIDATION } from "./Card/constants";
import { matchCurrentRecordToAwardedSk } from "./Card/transform";
import { transformAddStudentSearchData } from "./transform";
import { NOMINEE_FILE_NAMES, SUCCESS } from "./constants";
import { getAllCurrentAwardedNomineesForAllAwards } from "../Common/actions"
import { setApiStatus } from "../redux-helpers";


export function getNomineeCardInfo(nomineeId, shortPlan, base64AwardKey){
  return dispatch => {
    dispatch(buildAction(NOMINEE_CARD_INFO, { status: ACTION_STATE_LOADING }));

    API.get(process.env.REACT_APP_GRAD_AWARD_API_NAME,  `/nominee/${nomineeId}/${shortPlan}/${base64AwardKey}`, {})
    .then(response => {
      if(response.message === "Error") {
        dispatch(buildAction(NOMINEE_CARD_INFO, { status: ACTION_STATE_ERROR }));
      } else {
        let nominee = response.data;
        dispatch(buildAction(NOMINEE_CARD_INFO, { status: ACTION_STATE_SUCCESS, data: nominee  }));
        storeNomineeCardData(nominee)
      }
    })
    .catch(error => {
        console.error("Get Nominee Card Info Error: ", error);
        if (error === 'No current user'|| error==='Request failed with status code 401'|| (error.response!==undefined && error.response.status!==undefined && error.response.status===401)) {
          Auth.signOut();
        } else {
          dispatch(buildAction(NOMINEE_CARD_INFO, { status: ACTION_STATE_ERROR }));
        }
    })
  }
}

export function refreshNominee(awardKey, nomineeId, shortPlan, applicationNumber) {
  return async dispatch => {
    dispatch(buildAction(REFRESHED_NOMINEE, null));
    dispatch(buildAction(REFRESH_NOMINEE_STATE, ACTION_STATE_LOADING));
    await API.post(process.env.REACT_APP_AWARD_NOMINEE_REVIEW_API_NAME, `/refreshNominee`, {
      ...systemHeaders(),
      body: {
          awardKey: window.btoa(awardKey),
          nomineeId,
          shortPlan,
          applicationNumber
      }
    })
    .then((response) => {
      dispatch(buildAction(REFRESHED_NOMINEE, ((response.data && response.data.length > 0) ? response.data[0] : null)));
      dispatch(buildAction(REFRESH_NOMINEE_STATE, ACTION_STATE_SUCCESS));
    })
    .catch(error => {
      console.error("Failed to refresh the nominee", error);
      handleUnauthenticated(error, () => {
        dispatch(buildAction(REFRESH_NOMINEE_STATE, ACTION_STATE_ERROR));
      });
    });
  };
}

export function getTerms() {
    return (dispatch) => {
        dispatch(buildAction(TERMS_STATE, ACTION_STATE_LOADING));
        
        API.get(process.env.REACT_APP_GRAD_AWARD_API_NAME,  "/getTerms?career=GRAD&future=Y", {})
        .then(response => {
            if(response.message === "Error") {
                dispatch(buildAction(TERMS_STATE, ACTION_STATE_ERROR));
            } else {
                dispatch(buildAction(TERMS_STATE, ACTION_STATE_SUCCESS));
                let terms = _addPreviousTerm(response.data);
                dispatch(buildAction(TERMS, terms));
            }
        })
        .catch(error => {
            console.error("Get Terms Error: ", error);
            if (error === 'No current user'|| error==='Request failed with status code 401'|| (error.response!==undefined && error.response.status!==undefined && error.response.status===401)) {
                Auth.signOut();
            } else {
                dispatch(buildAction(TERMS_STATE, ACTION_STATE_ERROR));
            }
        })

    };
}

const _addPreviousTerm = (terms = []) => {
  let resultTerms = [...terms]
  if(!isEmpty(resultTerms)) {
    let currentTermSTRM = terms[0].strm;
    let previousTerm =  {
      "short-descr": "Previous Term"
    }
    previousTerm.strm = currentTermSTRM % 10 === 4 ?  currentTermSTRM - 2 : currentTermSTRM - 4 //Business logic for the previous term was provided by mowery.32 
    resultTerms.unshift(previousTerm);
  }
  return resultTerms
};

export function getDropDownMetadata() {
  return (dispatch, getState) => {
    const {currentAwards} = getState()
    if(!currentAwards || !currentAwards._list){
      dispatch(getCurrentAwards())
    }
      dispatch(getTerms())
      dispatch(getGraduatePrograms())
  };
}

export function clearAddStudentSearchData() {
  return (dispatch) => {
    dispatch(buildAction(ADD_STUDENT_SEARCH_DATA_STATE, ""));
    dispatch(buildAction(ADD_STUDENT_SEARCH_DATA, []));
  }

}

export function submitAddStudentSearchData({emplid, term, program, studentStatus}, isAdmin){
  let postBody = {emplid, term, program, studentStatus}
  return (dispatch) => {
    dispatch(buildAction(ADD_STUDENT_SEARCH_DATA_STATE, ACTION_STATE_LOADING));
        
    API.post(process.env.REACT_APP_GRAD_AWARD_API_NAME, '/searchNewNominees', { body: postBody })
    .then(response => {
        if(response.data.message === "Error") {
            dispatch(buildAction(ADD_STUDENT_SEARCH_DATA_STATE, ACTION_STATE_ERROR));
        } else {
            dispatch(buildAction(ADD_STUDENT_SEARCH_DATA_STATE, ACTION_STATE_SUCCESS));
            let searchResults = response.data && response.data.results;
            dispatch(buildAction(ADD_STUDENT_SEARCH_DATA, transformAddStudentSearchData(searchResults, program, isAdmin)));
        }
    })
    .catch(error => {
        console.error("Add Student Search Error: ", error);
        if (error === 'No current user'|| error==='Request failed with status code 401'|| (error.response!==undefined && error.response.status!==undefined && error.response.status===401)) {
            Auth.signOut();
        } else {
            dispatch(buildAction(ADD_STUDENT_SEARCH_DATA_STATE, ACTION_STATE_ERROR));
        }
    })
  }
}

export function submitNominations({studentStatus, award, awardOption, studentSelections, nominatedBy}){
  return (dispatch) => {
    dispatch(buildAction(SUBMIT_NOMINATIONS_RESULTS_STATE, ACTION_STATE_LOADING));
    
    
    API.post(process.env.REACT_APP_GRAD_AWARD_API_NAME, "/createNominee", { body: {studentStatus, award, awardOption, studentSelections, nominatedBy} })
    .then(response => {
        if(response.message === "Error") {
            dispatch(buildAction(SUBMIT_NOMINATIONS_RESULTS_STATE, ACTION_STATE_ERROR));
        } else {
            dispatch(buildAction(SUBMIT_NOMINATIONS_RESULTS_STATE, ACTION_STATE_SUCCESS));
            dispatch(buildAction(SUBMIT_NOMINATIONS_RESULTS, response.data));
        }
    })
    .catch(error => {
        console.error("Submit Nominations Error: ", error);
        if (error === 'No current user'|| error==='Request failed with status code 401'|| (error.response!==undefined && error.response.status!==undefined && error.response.status===401)) {
            Auth.signOut();
        } else {
            dispatch(buildAction(SUBMIT_NOMINATIONS_RESULTS_STATE, ACTION_STATE_ERROR));
        }
    })
  }
}

export function updatePacket({ deletePacket }) {
  return (dispatch) => {
    let newPacket = {
      deletePacket
    }

    dispatch(buildAction(NOMINEE_CARD_PACKET, newPacket))
  }
}

export function updateNominationCardForm(values) {
  return (dispatch) => {
    if(isEmpty(values)) {
      dispatch(buildAction(NOMINEE_CARD_FORM_CLEAR, { status: 'cleared' }))
    }
    dispatch(buildAction(NOMINEE_CARD_FORM, values))
  }
}

export function changeAwardeeResponseStatuses(nominees, responseType, sortKey) {
  const setAllNomineesStatus = (status) => setApiStatus(AWARD_NOMINEES_STATUS, status);

  const awardKeys = [...new Set(nominees.map(nominee => {
      return nominee.awardLetterPk;
  }))];
  
  
  return (dispatch) => {
    dispatch(setAllNomineesStatus('loading'));

      awardKeys.forEach(awardKey => {
  
          let params = {
              ...systemHeaders(),
              body: {
                  nominees : nominees.map(nominee => {
                      return {
                          pk: nominee.pk,
                          awardLetterPk: nominee.awardLetterPk,
                          sk: nominee.sk
                      }
                  }),
                  responseType,
                  awardKey
              }
          };
            API.post(process.env.REACT_APP_AWARD_NOMINEE_REVIEW_API_NAME, "/changeAwardeeResponse", params)
            .then(response => {
              setTimeout(() => {
                dispatch(getAllCurrentAwardedNomineesForAllAwards(sortKey));
                dispatch(setAllNomineesStatus('success'));

            }, 3000);
              return response;
        });
    });
  }
}

export function submitNominationCardForm() {  
  return async (dispatch, getState) => {
    const { nomineeCard = {}, awardInfo = {} } = getState() || {}
    dispatch(buildAction(NOMINEE_CARD_FORM_SUBMISSION, { status: ACTION_STATE_LOADING }))
    
    let awardResults = awardInfo.data && awardInfo.data.awardResultInfo ? awardInfo.data.awardResultInfo.awardResults : []
    
    const record = nomineeCard.record || {}
    const cardForm = nomineeCard.form || {}
    
    let nomineeSK = record && record.sk
    const actualAwardResult = matchCurrentRecordToAwardedSk(awardResults, nomineeSK)
    const { gpaPetitionFiles = {}, nomineePacketFiles = {}, ...rest } = cardForm
    
    
    let response = {}
    const nomineeUpdates = prepareNomineeUpdates({ ...rest })
    
    let cardStepDocuments = (!!gpaPetitionFiles.action || nomineePacketFiles.action) 
    ? cardStepDocumentManagement({gpaPetitionFiles, nomineePacketFiles }) 
    : []
    
    try {
      if(cardForm.hasOwnProperty('nomineeAward')) {
        await cardStepAwardChange({ 
          dispatch, 
          nominee: { nomineeId: record.nomineeId, shortPlan: record.shortPlan }, 
          awardKey: record.awardKey, 
          actualAwardResult, 
          nomineeAward: cardForm.nomineeAward 
        })
      }
      
      for (const step of cardStepDocuments) {        
        await step(dispatch)
      }

      dispatch(buildAction(NOMINEE_CARD_FORM_SUBMISSION, { stage: NOMINEE_CARD_SAVE, status: ACTION_STATE_LOADING }))
      dispatch(buildAction(NOMINEE_CARD_SAVE, { status: ACTION_STATE_LOADING }));
      
      response = await cardStepSaveUpdates(record.sk, record.pk, true, nomineeUpdates) // updateStatus set to true to push the update determination to the backend
      if(response && response.data && response.data.nominee) {
        dispatch(buildAction(NOMINEE_CARD_RECORD, response.data.nominee))
        dispatch(buildAction(NOMINEE_CARD_SAVE, { status: SUCCESS }));
      } else {
        throw new Error('Unexpected response from nominee card')
      }
    } catch (error) {
      let message = ''
      console.error('Failure in saving nominee card', error)
      if(!!error.response && !!error.response.data && !!error.response.data.error) {
        message = error.response.data.error
      }
      dispatch(buildAction(NOMINEE_CARD_SAVE, { status: ACTION_STATE_ERROR, errorMessage: message }));
      dispatch(buildAction(NOMINEE_CARD_FORM_SUBMISSION, { stage: NOMINEE_CARD_SAVE, status: ACTION_STATE_ERROR }))
    }
  }
}

function cardStepDocumentManagement({ gpaPetitionFiles = {}, nomineePacketFiles = {} }) {
  let actions = []
  
  if(gpaPetitionFiles.action === 'upload') {
    actions.push(uploadNomineePetition(gpaPetitionFiles.awardKey, gpaPetitionFiles.nomineeId, gpaPetitionFiles.file, gpaPetitionFiles.shortPlan))
  } else if (gpaPetitionFiles.action === 'delete') {
    actions.push(deleteNomineeDocument(gpaPetitionFiles.awardKey, gpaPetitionFiles.nomineeId, gpaPetitionFiles.documentId, gpaPetitionFiles.shortPlan))
  }

  if(nomineePacketFiles.action === 'upload') {
    actions.push(uploadNomineePacket(nomineePacketFiles.awardKey, nomineePacketFiles.nomineeId, nomineePacketFiles.file, nomineePacketFiles.shortPlan))
  } else if (nomineePacketFiles.action === 'delete') {
    actions.push(deleteNomineeDocument(nomineePacketFiles.awardKey, nomineePacketFiles.nomineeId, nomineePacketFiles.documentId, nomineePacketFiles.shortPlan))
  }

  return actions
}

async function cardStepAwardChange({ dispatch, nominee, awardKey, actualAwardResult, nomineeAward }) {
  let awardHasBeenUpdated = false
  dispatch(buildAction(NOMINEE_CARD_FORM_SUBMISSION, { stage: UPDATE_NOMINEE_CARD_AWARDED, status: ACTION_STATE_LOADING }))
  if(!!nomineeAward && (nomineeAward !== actualAwardResult)) {
    awardHasBeenUpdated = true
    await dispatch(grantAwardToSelected(awardKey, [nominee], nomineeAward, null, true))
  } else if(!!actualAwardResult && !nomineeAward)  {
    awardHasBeenUpdated = true
    await dispatch(grantAwardToSelected(awardKey, [nominee], 'REMOVE_SELECTED', null, true))
  }
  
  return {
    awardHasBeenUpdated
  }
}

function prepareNomineeUpdates(updates = {}) {
  let nomineeUpdates = {}
  for(const change in updates) {
    let newValue = updates[change]
    const { allowedValues, allowedType  } = NOMINATION_CARD_POST_VALIDATION[change] || {}
    if(Array.isArray(allowedValues) && allowedValues.includes(newValue)) {
      nomineeUpdates[change] = newValue
    } else if (!!allowedType && typeof newValue === allowedType) {
      nomineeUpdates[change] = newValue
    }
  }
  return nomineeUpdates
}

async function cardStepSaveUpdates(sk, pk, updateStatus, nomineeUpdates = {}) {
  let body = {
    sk,
    pk,
    updateStatus,
    nomineeUpdates
  }

  return API.post(process.env.REACT_APP_GRAD_AWARD_API_NAME, "/updateNominee", { body })
}

export function storeNomineeCardData(nomineeData) {
  return (dispatch) => {
    dispatch(buildAction(NOMINEE_CARD_RECORD, nomineeData))
  }
}

function getPacketPlaceholder({ fileType, awardKey, nomineeId, documentName, shortPlan }) {
  let docName = ""
  if(!!documentName) {
    docName = documentName
  } else if ((fileType === NOMINEE_FILE_NAMES.petition || fileType === NOMINEE_FILE_NAMES.packet) && awardKey && nomineeId && shortPlan) {
    docName = `${fileType}-${window.btoa(awardKey)}-${nomineeId}-${shortPlan}`
  } else {
    console.error("Could not construct document name:", JSON.stringify( { fileType, awardKey, nomineeId, documentName, shortPlan } ));
    return new Error(`Could not construct document name`); 
  }
  return API.get(process.env.REACT_APP_GRAD_AWARD_API_NAME, `/getEdmS3DocumentPlaceholder/${docName}`, {})
}

async function uploadDocumentToEdm({ fileType = '', awardKey = '', nomineeId = '', document = {}, shortPlan = '' }) {
  try {
    const { data: placeholder = {} } = await getPacketPlaceholder({ fileType, awardKey, nomineeId, shortPlan })
    if(!placeholder.url) {
      throw new Error("Placeholder is missing url");
    } 
    if(!placeholder.name) {
      throw new Error("Placeholder is missing name");
    } 
    await axios.put(placeholder.url, document, { headers: { "Content-Type": document.type } })
    return await uploadPacketToEdmUsingName(fileType, placeholder.name, awardKey, nomineeId, shortPlan)
  } catch (error) {
    console.error('Failed to upload document to edm', error)
    throw error
  }
}

function uploadPacketToEdmUsingName(fileType = '', name, awardKey, nomineeId, shortPlan) {
  let body = { awardKey, nomineeId, shortPlan }
  let documentEndpoint = ''
  if(fileType === NOMINEE_FILE_NAMES.petition) {
    documentEndpoint = `/uploadNomineePetition`
    body.petitionName = name
    body.petitionType = 'GPA'
  } else if (fileType === NOMINEE_FILE_NAMES.packet) {
    documentEndpoint = `/uploadNomineePacket`
    body.packetName = name
  } else {
    return new Error(`File type was unexpected, expected one of ${NOMINEE_FILE_NAMES.petition} or ${NOMINEE_FILE_NAMES.packet}`);
  }

  return API.post(process.env.REACT_APP_GRAD_AWARD_API_NAME, documentEndpoint, { body })
}

function uploadNomineePacket(awardKey, nomineeId, packet, shortPlan) {
  return async dispatch => {    
    try {
      dispatch(buildAction(NOMINEE_CARD_FORM_SUBMISSION, { stage: UPLOAD_NOMINEE_PACKET_ID, status: ACTION_STATE_LOADING }))

      const { data } = await uploadDocumentToEdm({ fileType: NOMINEE_FILE_NAMES.packet, awardKey, nomineeId, document: packet, shortPlan })
      if(!data.documentId) {
        throw new Error('No document id returned from edm call')
      }
      dispatch(buildAction(UPLOAD_NOMINEE_PACKET_ID, { documentId: data.documentId, nomineeId }));
    } catch (error) {
      console.error("Failed to upload nominee packet", error);
      dispatch(buildAction(UPLOAD_NOMINEE_PACKET_STATE, { stage: UPLOAD_NOMINEE_PACKET_ID, status: ACTION_STATE_ERROR }))
    }
  };
}

function uploadNomineePetition(awardKey, nomineeId, petition, shortPlan) {
  return async dispatch => {
    try {
      dispatch(buildAction(NOMINEE_CARD_FORM_SUBMISSION, { stage: UPLOAD_NOMINEE_PETITION_ID, status: ACTION_STATE_LOADING }))
      const { data } = await uploadDocumentToEdm({ fileType: NOMINEE_FILE_NAMES.petition, awardKey, nomineeId, document: petition, shortPlan })
      if(!data.documentId) {
        throw new Error('No document id returned from edm call')
      }
      
      dispatch(buildAction(UPLOAD_NOMINEE_PETITION_ID, { documentId: data.documentId, nomineeId }));
    } catch (error) {
      dispatch(buildAction(UPLOAD_NOMINEE_PETITION_STATE, { stage: UPLOAD_NOMINEE_PETITION_ID, status: ACTION_STATE_ERROR }))
    }
  };
}

function deleteNomineeDocument(awardKey, nomineeId, documentId, shortPlan) {
  return async dispatch => {
    dispatch(buildAction(NOMINEE_CARD_FORM_SUBMISSION, { stage: documentId, status: ACTION_STATE_LOADING }))
    dispatch(buildAction(DELETE_NOMINEE_DOCUMENT_STATE, { stage: documentId, status: ACTION_STATE_LOADING }));
    
    await API.post(process.env.REACT_APP_GRAD_AWARD_API_NAME, "/deleteNomineeDocument", { body: { awardKey, nomineeId, documentId, shortPlan} })
    .then((response) => {
      dispatch(buildAction(DELETE_NOMINEE_DOCUMENT, { nomineeId, documentId }));
    })
    .catch(error => {
      console.error("Failed to delete the nominee document", error);
      dispatch(buildAction(DELETE_NOMINEE_DOCUMENT_STATE, { stage: documentId, status: ACTION_STATE_ERROR }));
    });
  };
}