import { SEARCH_SCORE_SHEET, NOMINEE_REVIEW_LIST, REVIEWERS, NOMINEE_LIST_STATUS, ADD_NOMINEE_LIST_FILTER, ADD_NOMINEE_LIST_SORT_KEY, NOMINEE_REVIEWER_STATS } from '../../actions-index'
import { sortList, constructFilters, constructSort, searchList, searchListByQuery } from '../../redux-helpers';

export function nomineeReviewScoreSheetList(state = {}, action) {
    const { _list } = state
    const searchableProperties = ['name', 'nomineeId']

    switch (action.type) {
        case NOMINEE_REVIEW_LIST:
            return {
                ...state,
                list: action.payload,
                _list: action.payload,
                status: 'success'
            }
        case NOMINEE_REVIEWER_STATS:
            return {
                ...state,
                reviewerStats: action.payload
            }
        case NOMINEE_LIST_STATUS:
            return {
                ...state,
                status: action.payload
            }
        case SEARCH_SCORE_SHEET:
            const searchedList = searchList({ query: action.payload, list: _list, searchableProperties, filters: state.filters, sortKey: state.sortKey, filteringAction: addNomineeListFilters })

            return {
                ...state,
                query: action.payload,
                list: searchedList
            }
        case ADD_NOMINEE_LIST_SORT_KEY:
            const newSortKey = constructSort(state.sortKey, action.payload)
            
            return {
                ...state,
                sortKey: newSortKey,
                list: sortList(state.list, newSortKey)
            }
        case ADD_NOMINEE_LIST_FILTER:
            const allFilters = constructFilters(state.filters, action.payload)
            const filteredList = addNomineeListFilters({ list: _list, filters: allFilters, query: state.query, sortKey: state.sortKey, searchableProperties })

            return {
                ...state,
                filters: allFilters,
                list: filteredList
            }
        default:
            return state
    }
}

function addNomineeListFilters(values) {
    const { list, filters, query, sortKey, searchableProperties } = values
    let nomineeList = Array.isArray(list) ? list.filter(item => {
        const matchingNomProperties = filters.filter((filter, index) => {
            if (filter.type === 'rubric' && item.hasOwnProperty(filter.key) && item[filter.key].hasOwnProperty('value')) {
                const score = item[filter.key].value
                return score >= filter.min && score <= filter.max
            } else if (filter.type === 'gpa' && item.hasOwnProperty(filter.key)) {
                const gpa = item[filter.key]
                return gpa >= filter.min && gpa <= filter.max
            } else if (filter.type === 'awardOptions' && item.hasOwnProperty('nominatedFor')) {
                return item.nominatedFor === filter.key
            } else if (filter.type === 'finalWeightedScore' && item.hasOwnProperty(filter.key)) {
                const score = item.finalWeightedScore
                return score >= filter.min && score <= filter.max
            }
            return false
        })
        
        return matchingNomProperties.length === filters.length
    }) : []

    if (query) {
        nomineeList = searchListByQuery({ query, list: nomineeList, searchableProperties })
    }
    if (sortKey) {
        nomineeList = sortList(nomineeList, sortKey)
    }

    return nomineeList
}

export function reviewers(state = [], action) {
    switch (action.type) {
        case REVIEWERS:
            return action.payload
        default:
            return state
    }
}
