import { combineReducers } from "redux";
import { flavor } from "./ProtectedExample/reducers";
import * as appReducers from "./App/reducers";
import * as awardManagementReducers from "./AwardManagement/reducers";
import * as nomineeReviewReducers from './NomineeReview/reducers';
import * as commonReducers from './Common/reducers'
import * as reviewerManagementReducers from './ReviewerManagement/reducers';
import * as committeeTabsReducers from './ReviewerManagement/CommitteeTabs/reducers';
import * as viewPdfReducers from './PDFView/reducers';
import * as nominationsReducers from './Nominations/reducers.js';
import * as tokenValidationReducers from './AwardAcceptance/reducers';

export default combineReducers({
  flavor,
  ...appReducers,
  ...awardManagementReducers,
  ...nomineeReviewReducers,
  ...commonReducers,
  ...reviewerManagementReducers,
  ...committeeTabsReducers,
  ...viewPdfReducers,
  ...nominationsReducers,
  ...tokenValidationReducers
})
