import { CREATE_AWARD_KEY, CREATE_AWARD_STATE, GRADUATE_PROGRAMS,
    GRADUATE_PROGRAMS_STATE, DELETE_AWARD_STATE, UPDATE_AWARD_STATE } from "../actions-index";
import { ACTION_STATE_SUCCESS } from "../util/constants";
import { isEmpty } from "lodash";

export function createAward(state = {}, action = {}) {
    const { payload, type } = action;
    switch(type) {
        case CREATE_AWARD_KEY:
            return {
                key: payload,
                state: !isEmpty(payload) ? ACTION_STATE_SUCCESS : state.state
            };
        case CREATE_AWARD_STATE:
            return { ...state, state: payload };
        default:
            return state;
    }
}

export function deleteAwardState(state = "", action = {}) {
    return (action.type === DELETE_AWARD_STATE ? action.payload : state);
}

export function updateAwardState(state = "", action = {}) {
    return (action.type === UPDATE_AWARD_STATE ? action.payload : state);
}

export function graduatePrograms(state = {}, action = {}) {
    const { payload, type } = action;
    switch(type) {
        case GRADUATE_PROGRAMS:
            return { ...state, data: payload };
        case GRADUATE_PROGRAMS_STATE:
            return { ...state, state: payload };
        default:
            return state;
    }
}