/*jshint loopfunc: true */
import {API, Auth} from "aws-amplify";

import {
  ASSIGN_PACKETS_TO_REVIEWERS_ERROR,
  ASSIGN_PACKETS_TO_REVIEWERS_ERROR_MESSAGE,
  ASSIGN_PACKETS_TO_REVIEWERS_PROCESSING,
  ASSIGN_PACKETS_TO_REVIEWERS_PROCESSING_MESSAGE,
  ASSIGN_PACKETS_TO_REVIEWERS_SUCCESS,
  AWARD_REVIEWERS,
  AWARD_REVIEWERS_ERROR,
  AWARD_REVIEWERS_LOADING,
  AWARD_REVIEWER_NOMINEES,
  AWARD_REVIEWER_NOMINEES_ERROR,
  AWARD_REVIEWER_NOMINEES_LOADING,
  COMMITTEE_REVIEWERS_STATUS_CHANGE_ERROR,
  COMMITTEE_REVIEWERS_STATUS_CHANGE,
  COMMITTEE_REVIEWERS_STATUS_CHANGE_LOADING,
  DISTRIBUTE_REVIEWER_PACKETS_ERROR,
  DISTRIBUTE_REVIEWER_PACKETS_PROCESSING,
  DISTRIBUTE_REVIEWER_PACKETS_SUCCESS,
  TRANSFER_REVIEWER_PACKETS_ERROR,
  TRANSFER_REVIEWER_PACKETS_PROCESSING,
  TRANSFER_REVIEWER_PACKETS_SUCCESS,
  TRANSFER_REVIEWER_NOMINEE_PACKETS_ERROR,
  TRANSFER_REVIEWER_NOMINEE_PACKETS_PROCESSING,
  TRANSFER_REVIEWER_NOMINEE_PACKETS_SUCCESS
} from "../../actions-index";
import { redirectToLogOut} from "../../util/util";

export function findReviewersByAward(awardKey) {
  return dispatch => {
    // clean-up prior state
    dispatch({ type: AWARD_REVIEWERS_ERROR, payload: false });
    dispatch({ type: AWARD_REVIEWERS, payload: [] });

    dispatch({ type: AWARD_REVIEWERS_LOADING, payload: true });
    API.get(process.env.REACT_APP_AWARD_REVIEWER_API_NAME, `/grad/award/${window.btoa(awardKey)}/reviewers`,{})
      .then(response => {
        dispatch({ type: AWARD_REVIEWERS, payload: response });
      })
      .catch(error => {
        console.error("Find Reviewers By Award Error: ", error);
        if (error === 'No current user'|| error==='Request failed with status code 401'|| (error.response!==undefined && error.response.status!==undefined && error.response.status===401)) {
          Auth.signOut() && redirectToLogOut();
        } else {
          dispatch({type: AWARD_REVIEWERS_ERROR, payload: true});
        }
      })
      .finally(() => {
        dispatch({ type: AWARD_REVIEWERS_LOADING, payload: false });
      });
  }
}

export function findReviewerNomineesByAward(awardKey, reviewerId) {
  return dispatch => {

    // clean-up prior state
    dispatch({ type: AWARD_REVIEWER_NOMINEES_ERROR, payload: false });
    dispatch({ type: AWARD_REVIEWER_NOMINEES, payload: [] });

    dispatch({ type: AWARD_REVIEWER_NOMINEES_LOADING, payload: true });
    API.get(process.env.REACT_APP_AWARD_NOMINEE_REVIEW_API_NAME, `/getReviewerNominees/${btoa(awardKey)}?reviewer=${reviewerId}`,{})
      .then(response => {
        const nominees = response.data && response.data.nominees ? response.data.nominees : [];
        dispatch({ type: AWARD_REVIEWER_NOMINEES, payload: nominees });
      })
      .catch(error => {
        console.error("Find Reviewer Nominees By Award Error: ", error);
        if (error === 'No current user'|| error==='Request failed with status code 401'|| (error.response!==undefined && error.response.status!==undefined && error.response.status===401)) {
          Auth.signOut() && redirectToLogOut();
        } else {
          dispatch({type: AWARD_REVIEWER_NOMINEES_ERROR, payload: true});
        }
      })
      .finally(() => {
        dispatch({ type: AWARD_REVIEWER_NOMINEES_LOADING, payload: false });
      });
  };
}

export function changeStatusOfReviewers(status, awardKey, reviewers) {
  const params = {
    body: reviewers
  };
  return dispatch => {
    // clean-up prior state
    dispatch({ type: COMMITTEE_REVIEWERS_STATUS_CHANGE_ERROR, payload: false });
    dispatch({ type: COMMITTEE_REVIEWERS_STATUS_CHANGE, payload: [] });

    dispatch({ type: COMMITTEE_REVIEWERS_STATUS_CHANGE_LOADING, payload: true });
    API.post(process.env.REACT_APP_AWARD_REVIEWER_API_NAME, `/grad/award/${btoa(awardKey)}/status/${status.value}`, params)
      .then(response => {
        dispatch({ type: COMMITTEE_REVIEWERS_STATUS_CHANGE, payload: response });
        window.location.reload();
      })
      .catch(error => {
        console.error("Find Reviewers By Award Error: ", error);
        if (error === 'No current user'|| error==='Request failed with status code 401'|| (error.response!==undefined && error.response.status!==undefined && error.response.status===401)) {
          Auth.signOut() && redirectToLogOut();
        } else {
          dispatch({type: COMMITTEE_REVIEWERS_STATUS_CHANGE_ERROR, payload: true});
        }
      })
      .finally(() => {
        findReviewersByAward(awardKey);

        dispatch({ type: COMMITTEE_REVIEWERS_STATUS_CHANGE_LOADING, payload: false });
      });
  }
}

export function assignPacketsToSelectedReviewers(awardKey){
  return dispatch => {
    dispatch({ type: ASSIGN_PACKETS_TO_REVIEWERS_PROCESSING, payload: true });
    API.post(process.env.REACT_APP_AWARD_NOMINEE_REVIEW_API_NAME, `/requestAssignAwardReviewersToNominees/${btoa(awardKey)}`, { body: {} })
    .then(async response => {
      if (response.status === "success") {
        console.log("requestAssignAwardReviewersToNominees-->success",response);
        //need to call api get status.
        let processing=true;
        let index = 0;
        while(processing) {
          index= index+1;
          //console.log("check status -->",index);
           let statusres = await API.get(process.env.REACT_APP_AWARD_NOMINEE_REVIEW_API_NAME, `/getAssignAwardReviewersToNomineesStatus/${btoa(awardKey)}`, {});
             console.log("status response :",statusres);
            if(statusres.data.data==='PROCESSING'){
              processing=true;
              dispatch({ type: ASSIGN_PACKETS_TO_REVIEWERS_PROCESSING_MESSAGE, payload: statusres.data.details.info });
              dispatch({ type: ASSIGN_PACKETS_TO_REVIEWERS_PROCESSING, payload: true });
              return;
            }
            if(statusres.data.data==='COMPLETE'){
              processing=false;
              //dispatch({ type: ASSIGN_PACKETS_TO_REVIEWERS_PROCESSING_MESSAGE, payload: statusres.data.details.info });
              dispatch({ type: ASSIGN_PACKETS_TO_REVIEWERS_SUCCESS, payload: true });
              return;
            }
            if(statusres.data.data==='ERROR'){
              processing=false;
              dispatch({ type: ASSIGN_PACKETS_TO_REVIEWERS_ERROR_MESSAGE, payload: statusres.data.details.error });
              dispatch({ type: ASSIGN_PACKETS_TO_REVIEWERS_ERROR, payload: true });
              return;
            }
        }
      } else {
        console.log("requestAssignAwardReviewersToNominees-->failure",response)

        dispatch({ type: ASSIGN_PACKETS_TO_REVIEWERS_ERROR, payload: true });
        if(response.data) {
          dispatch({ type: ASSIGN_PACKETS_TO_REVIEWERS_ERROR_MESSAGE, payload: response.data });
        }
      }
    })
    .catch(error => {
      console.error("Assign Packets to Selected Reviewers Error: ", error);
      if (error === 'No current user'|| error==='Request failed with status code 401'|| (error.response!==undefined && error.response.status!==undefined && error.response.status===401)) {
        dispatch({type: ASSIGN_PACKETS_TO_REVIEWERS_ERROR, payload: true});
        Auth.Signout()
      } else {
        dispatch({type: ASSIGN_PACKETS_TO_REVIEWERS_ERROR, payload: true});
      }
    })
    .finally(() => {
      dispatch({ type: ASSIGN_PACKETS_TO_REVIEWERS_PROCESSING, payload: false });
    });
  };
}

export function resetAssignPacketsToSelectedReviewersFlags() {
  return dispatch => {
    dispatch({ type: ASSIGN_PACKETS_TO_REVIEWERS_ERROR, payload: false });
    dispatch({ type: ASSIGN_PACKETS_TO_REVIEWERS_ERROR_MESSAGE, payload: null });
    dispatch({ type: ASSIGN_PACKETS_TO_REVIEWERS_PROCESSING_MESSAGE, payload: null });
    dispatch({ type: ASSIGN_PACKETS_TO_REVIEWERS_SUCCESS, payload: false });
  };
}

export function distributeReviewerPackets(awardKey, reviewerId) {
  return dispatch => {
    // clean-up prior state
    dispatch({ type: DISTRIBUTE_REVIEWER_PACKETS_ERROR, payload: false });
    dispatch({ type: DISTRIBUTE_REVIEWER_PACKETS_SUCCESS, payload: false });

    dispatch({ type: DISTRIBUTE_REVIEWER_PACKETS_PROCESSING, payload: true });
    API.post(process.env.REACT_APP_AWARD_NOMINEE_REVIEW_API_NAME,
      `/distributeReviewerPackets/${btoa(awardKey)}/${reviewerId}`,
      { body: {} }
    )
      .then((response) => {
        if (response.status === "success") {
          dispatch({ type: DISTRIBUTE_REVIEWER_PACKETS_SUCCESS, payload: true });
        } else {
          dispatch({ type: DISTRIBUTE_REVIEWER_PACKETS_ERROR, payload: true });
        }
      })
      .catch(error => {
        console.error("Distribute Reviewer Packets Error: ", error);
        if (error === 'No current user'|| error==='Request failed with status code 401' || (error.response!==undefined && error.response.status!==undefined && error.response.status===401)) {
          Auth.signOut() && redirectToLogOut();
        } else {
          dispatch({type: DISTRIBUTE_REVIEWER_PACKETS_ERROR, payload: true});
        }
      })
      .finally(() => {
        dispatch({ type: DISTRIBUTE_REVIEWER_PACKETS_PROCESSING, payload: false });
      });
  };
}

export function resetDistributeReviewerPacketsFlags() {
  return dispatch => {
    dispatch({ type: DISTRIBUTE_REVIEWER_PACKETS_ERROR, payload: false });
    dispatch({ type: DISTRIBUTE_REVIEWER_PACKETS_SUCCESS, payload: false });
  };
}

export function transferReviewerPackets(awardKey, fromReviewerId, toReviewerId) {
  return dispatch => {
    // clean-up prior state
    dispatch({ type: TRANSFER_REVIEWER_PACKETS_ERROR, payload: false });
    dispatch({ type: TRANSFER_REVIEWER_PACKETS_SUCCESS, payload: false });

    dispatch({ type: TRANSFER_REVIEWER_PACKETS_PROCESSING, payload: true });
    API.post(process.env.REACT_APP_AWARD_NOMINEE_REVIEW_API_NAME,
      `/transferReviewerPackets/${btoa(awardKey)}/${fromReviewerId}/${toReviewerId}`,
      { body: {} }
    )
      .then((response) => {
        if (response.status === "success") {
          dispatch({ type: TRANSFER_REVIEWER_PACKETS_SUCCESS, payload: true });
        } else {
          dispatch({ type: TRANSFER_REVIEWER_PACKETS_ERROR, payload: true });
        }
      })
      .catch(error => {
        console.error("Transfer Reviewer Packets Error: ", error);
        if (error === 'No current user'|| error==='Request failed with status code 401' || (error.response!==undefined && error.response.status!==undefined && error.response.status===401)) {
          Auth.signOut() && redirectToLogOut();
        } else {
          dispatch({type: TRANSFER_REVIEWER_PACKETS_ERROR, payload: true});
        }
      })
      .finally(() => {
        dispatch({ type: TRANSFER_REVIEWER_PACKETS_PROCESSING, payload: false });
      });
  };
}

export function resetTransferReviewerPacketsFlags() {
  return dispatch => {
    dispatch({ type: TRANSFER_REVIEWER_PACKETS_ERROR, payload: false });
    dispatch({ type: TRANSFER_REVIEWER_PACKETS_SUCCESS, payload: false });
  };
}

export function transferReviewerNomineePackets(awardKey, nomineeId, fromReviewerId, toReviewerId) {
  return dispatch => {
    // clean-up prior state
    dispatch({ type: TRANSFER_REVIEWER_NOMINEE_PACKETS_ERROR, payload: false });
    dispatch({ type: TRANSFER_REVIEWER_NOMINEE_PACKETS_SUCCESS, payload: false });

    dispatch({ type: TRANSFER_REVIEWER_NOMINEE_PACKETS_PROCESSING, payload: true });
    API.post(process.env.REACT_APP_AWARD_NOMINEE_REVIEW_API_NAME,
      `/transferReviewerNomineePackets/${btoa(awardKey)}/${nomineeId}/${fromReviewerId}/${toReviewerId}`,
      { body: {} }
    )
      .then((response) => {
        if (response.status === "success") {
          dispatch({ type: TRANSFER_REVIEWER_NOMINEE_PACKETS_SUCCESS, payload: true });
        } else {
          dispatch({ type: TRANSFER_REVIEWER_NOMINEE_PACKETS_ERROR, payload: true });
        }
      })
      .catch(error => {
        console.error("Transfer Reviewer Nominee Packets Error: ", error);
        if (error === 'No current user'|| error==='Request failed with status code 401' || (error.response!==undefined && error.response.status!==undefined && error.response.status===401)) {
          Auth.signOut() && redirectToLogOut();
        } else {
          dispatch({type: TRANSFER_REVIEWER_NOMINEE_PACKETS_ERROR, payload: true});
        }
      })
      .finally(() => {
        dispatch({ type: TRANSFER_REVIEWER_NOMINEE_PACKETS_PROCESSING, payload: false });
      });
  };
}

export function resetTransferReviewerNomineePacketsFlags() {
  return dispatch => {
    dispatch({ type: TRANSFER_REVIEWER_NOMINEE_PACKETS_ERROR, payload: false });
    dispatch({ type: TRANSFER_REVIEWER_NOMINEE_PACKETS_SUCCESS, payload: false });
  };
}
