import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import ReviewerDetails from "../components/Details";
import { calculateRankedScores } from '../../../actions-index'

const mapStateToProps = (state) => {
    return {
        systemAward: state.systemAward
    };
};

const mapDispatchToProps = (dispatch) => {
    
    return {
        calculateRankedScores: (awardKey) => dispatch(calculateRankedScores(awardKey)),

    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ReviewerDetails));

