import { useMemo } from 'react'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { NOMINEE_FILE_NAMES } from '../../constants'
import { getPacket } from '../transform'

const usePacket = (name = '') => {
    const nomineeRecord = useSelector((state) => {
        let record = { }
        if(state.nomineeCard && state.nomineeCard.record) {
            const { documentNames, uploadedFile } = state.nomineeCard.record
            record = {
                documentNames, 
                uploadedFile
            }
        }

        return record
    })
    
    let packet = {
        documentId: '', 
        documentName: '',
        lastModifiedDate: '',
        lastModifiedBy: ''
    }

    let record = getPacket(name, nomineeRecord)
    packet = Object.assign(packet, record)
    
    if(name === NOMINEE_FILE_NAMES.petition) {
        packet.documentId = record.id
        packet.documentName = record.name
    }

    const { lastModifiedDate, lastModifiedBy } = packet

    const caption = useMemo(() => {
        if(!lastModifiedBy) return ''
        let str = `${name} last updated by ${lastModifiedBy}`
        if(moment(lastModifiedDate).isValid()) {
            str += ` on ${moment(lastModifiedDate).format("MM/DD/YYYY h:mm A")}`
        }
        return str
    }, [lastModifiedDate, lastModifiedBy])

    packet.caption = caption
    
    return packet
}

export default usePacket