import React, { Component, Fragment } from "react";
import { CustomInput } from "reactstrap";
import Select from "react-select";
import { Body1, Caption, Icon, OSUButton, OSUError, OSULoading, Table } from "osu-react-components";
import moment from "moment";
import sanitizeHtml from "sanitize-html-react";
import { find } from 'lodash'


const AWARD_DATE_FORMAT = "MM/DD/YYYY hh:mm A";

export default class AwardManagement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageTitle: "Award Management",
            selectedAwardKey: null
        }
    }

    componentWillMount() {
        document.title = this.state.pageTitle;
    }

    componentDidMount() {
        this.props.setA11yMessage(`Navigated to the ${this.state.pageTitle} page`);
        this.pageContent.focus();
        if(this.props.awardsStatus !== "loading") { // app component also loads current awards
            this.props.getCurrentAwards();
        }
        this.props.getPreviousAwards();
    }

    buildAwardsTableData = (awards) => {
        return awards.map(award => {
            const { pk, externalUrl, name } = award;
            const awardKey = window.btoa(pk);
            const description = award.description || "Thank you for serving as a reviewer.  Contact the Graduate School with questions and concerns about the review process or policies.";
            const sanitizedDescription = sanitizeHtml(description, { allowedTags: ["a", "b", "br"], allowedAtrributes: { a: [ "href" ] }, allowedSchemesByTag: { a: [ "mailto" ]} });
            return {
                awardSelection: <CustomInput aria-label={`Select ${name} award`} type="radio" name="awardSelection" id={awardKey} checked={this.state.selectedAwardKey === window.atob(awardKey) } readOnly />,
                awardDetails: (
                    <Body1 key={awardKey}>
                        {name}
                        <Caption color="gray" className="ml-3 d-block">
                            <span className="d-flex flex-wrap">
                                <span>Start reviews:&nbsp;</span>
                                <span style={{ color: "black"}}>{moment(award.reviewStartDate).format(AWARD_DATE_FORMAT)}</span>
                            </span>
                        </Caption>
                        <Caption color="gray" className="ml-3 d-block">
                            <span className="d-flex flex-wrap">
                                <span>Review Completion Deadline, Start Composite Reviews:&nbsp;</span>
                                <span style={{ color: "black"}}>{moment(award.reviewCompletionDate).format(AWARD_DATE_FORMAT)}</span>
                            </span>
                        </Caption>
                        <Caption color="gray" className="ml-3 d-block">
                            <span className="d-flex flex-wrap">
                                <span>Composite Review Ends (scores are locked):&nbsp;</span>
                                <span style={{ color: "black"}}>{moment(award.reviewEndDate).format(AWARD_DATE_FORMAT)}</span>
                            </span>
                        </Caption>
                        <Caption color="gray" className="ml-3 d-block">
                            <span className="d-flex flex-wrap">
                                <span>Committee Meeting:&nbsp;</span>
                                <span style={{ color: "black"}}>
                                    {moment(award.reviewCommitteeMeetingStartDate).format(AWARD_DATE_FORMAT)} - {moment(award.reviewCommitteeMeetingEndDate).format(AWARD_DATE_FORMAT)}
                                </span>
                            </span>
                        </Caption>
                    </Body1>
                ),
                expandedContent: (
                    <p className="px-4 py-2">
                        <span dangerouslySetInnerHTML={{ __html: sanitizedDescription }} />
                        {externalUrl &&
                            <span>
                                <br /><br />
                                Please see the <a data-testid="externalUrl" rel="noopener noreferrer" href={externalUrl} target="_blank">Reviewer Guidelines</a> for details on the review process.
                            </span>
                        }
                    </p>
                ),
                onRowClick: async () => {
                    let decodedAwardKey = window.atob(awardKey)
                    this.setState({ selectedAwardKey: decodedAwardKey})
                    await Promise.resolve(this.props.setSystemAwardValues(find(awards, award => award.pk === decodedAwardKey)));
                }
            };
        });
    }

    buildPreviousAwardsOptions = (previousAwards) => {
        return previousAwards.map(previousAward => {
            return { label: previousAward.name, value: previousAward.pk };
        });
    }
    onViewEditAwardDetails = () => {
        if(this.state.selectedAwardKey !== null) this.props.history.push(`/award-management/award-details/${window.btoa(this.state.selectedAwardKey)}`);
    }
    
    onPreviousAwardSelection = (previousAward) => {
        this.props.history.push(`/award-management/award-details/${window.btoa(previousAward.value)}`);
    }

    renderPageContent = () => {
        if(this.props.awardsStatus === "loading") {
            return (<OSULoading text="Loading awards" />);
        } else if(this.props.awardsStatus === "401") {
            // Auth.signOut();
            // redirectToLogOut();
            console.log("test authorizer redirect failure 2");
        } else if(this.props.awardsStatus === "error") {
            return (<OSUError text="Error loading awards" ariaLabel="Reload awards" onClick={() => this.props.getCurrentAwards()} />);
        } else {
            const awardsTableDataKeys = [{ key: "awardSelection", width: 3 }, { key: "awardDetails", width: 97 }];
            const awardsTableData = this.buildAwardsTableData(this.props.awards);
            const previousAwardOptions = this.buildPreviousAwardsOptions(this.props.previousAwards);
            const previousAwardSelectStyles = {
                container: provided => ({ ...provided, maxWidth: "28rem" })
            };
            return (
                <div>
                    <OSUButton link ariaLabel="Create New Award" path="/award-management/create-award" className="mb-2">
                        <Icon type="plus" color="blue" size="sm" /> Create New Award
                    </OSUButton>
                    <h2 data-testid="awardSelectionHeader" className="heading5">Please select an award to view or edit:</h2>
                    <Table noHeader expandable={awardsTableData.length > 0} multiOpen dataKeys={awardsTableDataKeys} data={awardsTableData} noDataMessage="There are no awards to select from at this time." ></Table>
                    <OSUButton solid ariaLabel="View / Edit Award Details" disabled={this.state.selectedAwardKey === null} onClick={this.onViewEditAwardDetails} className="mt-5">View / Edit Award Details</OSUButton>
                    {previousAwardOptions.length > 0 &&
                        <Fragment>
                            <h2 data-testid="previousAwardSelectionHeader" className="heading6 mt-5">Previous Awards</h2>
                            <Select aria-label="Previous Awards" options={previousAwardOptions} onChange={this.onPreviousAwardSelection} styles={previousAwardSelectStyles} />
                        </Fragment>
                    }
                </div>
            );
        }
    }

    render() {
        return (
            <div data-testid="pageContent" ref={(el) => { this.pageContent = el; }} tabIndex="-1" aria-labelledby="pageHeader">
                <h1 id="pageHeader" data-testid="pageHeader" className="sr-only">{this.state.pageTitle}</h1>
                {this.renderPageContent()}
            </div>
        );
    }
}