
export const SET_A11Y_MESSSAGE = "SET_A11Y_MESSSAGE";
export const UPDATE_BREADCRUMB_TRAIL = "UPDATE_BREADCRUMB_TRAIL";
export const SET_USER = "SET_USER";
export const USER_CONFIRMED = "USER_CONFIRMED";
export const USER_IS_LOADING = "USER_IS_LOADING";
export const SYSTEM_EMPLID = 'SYSTEM_EMPLID';
export const UPDATE_FLAVOR = "UPDATE_FLAVOR";
export const SCORING_CRITERIA = "SCORING_CRITERIA";
export const NOMINEE_REVIEW = "NOMINEE_REVIEW";
export const UPDATE_SCORES_STATUS = 'UPDATE_SCORES_STATUS';
export const NOMINEE_REVIEW_STATUS = 'NOMINEE_REVIEW_STATUS';
export const AWARD_NOMINEES = "AWARD_NOMINEES";
export const AWARD_NOMINEES_DETAILS = 'AWARD_NOMINEES_DETAILS';
export const AWARD_NOMINEES_QUERY = 'AWARD_NOMINEES_QUERY';
export const AWARD_NOMINEES_FILTER = 'AWARD_NOMINEES_FILTER';
export const AWARD_NOMINEES_STATUS = "AWARD_NOMINEES_STATUS";
export const AWARD_NOMINEES_AWARDED = "AWARD_NOMINEES_AWARDED";
export const AWARD_NOMINEES_SORT_KEY = "AWARD_NOMINEES_SORT_KEY";
export const NOMINEE_REVIEW_LIST = "NOMINEE_REVIEW_LIST";
export const NOMINEE_REVIEWER_STATS = 'NOMINEE_REVIEWER_STATS'
export const ADD_NOMINEE_LIST_FILTER = 'ADD_NOMINEE_LIST_FILTER';
export const ADD_NOMINEE_LIST_SORT_KEY = 'ADD_NOMINEE_LIST_SORT_KEY';
export const NOMINEE_LIST_STATUS = "NOMINEE_LIST_STATUS";
export const NOMINEES_CSV_HEADERS = "NOMINEES_CSV_HEADERS";
export const PRIMARY_REVIEWS_LIST = "PRIMARY_REVIEWS_LIST";
export const SEARCH_PRIMARY_REVIEWS_LIST = "SEARCH_PRIMARY_REVIEWS_LIST";
export const ADD_PRIMARY_REVIEWS_LIST_FILTER = 'ADD_PRIMARY_REVIEWS_LIST_FILTER';
export const ADD_PRIMARY_REVIEWS_SORT_KEY = 'ADD_PRIMARY_REVIEWS_SORT_KEY';
export const PRIMARY_REVIEWS_STATUS = "PRIMARY_REVIEWS_STATUS";
export const PRIMARY_REVIEWS_HEADERS = "PRIMARY_REVIEWS_HEADERS";
export const SECONDARY_REVIEWS_LIST = "SECONDARY_REVIEWS_LIST";
export const SEARCH_SECONDARY_REVIEWS_LIST = "SEARCH_SECONDARY_REVIEWS_LIST";
export const SECONDARY_REVIEWS_STATUS = "SECONDARY_REVIEWS_STATUS";
export const ADD_SECONDARY_REVIEWS_SORT_KEY = 'ADD_SECONDARY_REVIEWS_SORT_KEY';
export const ADD_SECONDARY_REVIEWS_LIST_FILTER = 'ADD_SECONDARY_REVIEWS_LIST_FILTER';
export const SECONDARY_REVIEW_DETAILS = 'SECONDARY_REVIEW_DETAILS';
export const ASSIGNED_REVIEWERS = 'ASSIGNED_REVIEWERS';
export const ASSIGNED_REVIEWERS_STATUS = 'ASSIGNED_REVIEWERS_STATUS';
export const ASSIGNED_REVIEWERS_QUERY = 'ASSIGNED_REVIEWERS_QUERY';
export const ADD_ASSIGNED_REVIEWERS_FILTERS = 'ADD_ASSIGNED_REVIEWERS_FILTERS';
export const ADD_ASSIGNED_REVIEWERS_SORT_KEY = 'ADD_ASSIGNED_REVIEWERS_SORT_KEY';
export const ALL_AWARDS = "ALL_AWARDS";
export const ALL_AWARDS_ERROR = "ALL_AWARDS_ERROR";
export const ALL_AWARDS_LOADING = "ALL_AWARDS_LOADING";
export const CURRENT_AWARDS = "CURRENT_AWARDS";
export const CURRENT_AWARDS_STATUS = 'CURRENT_AWARDS_STATUS';
export const PREVIOUS_AWARDS_STATUS = 'PREVIOUS_AWARDS_STATUS';
export const SYSTEM_AWARD = "SYSTEM_AWARD";
export const VIEW_DOCUMENT = "VIEW_DOCUMENT";
export const VIEW_DOCUMENT_LOADING = "VIEW_DOCUMENT_LOADING";
export const VIEW_DOCUMENT_ERROR = "VIEW_DOCUMENT_ERROR";
export const VIEW_DOCUMENT_ID = "VIEW_DOCUMENT_ID"
export const SEARCH_SCORE_SHEET = "SEARCH_SCORE_SHEET";
export const GRADUATE_FACULTY_BY_EMPLID_DATA = "GRADUATE_FACULTY_BY_EMPLID_DATA";
export const GRADUATE_FACULTY_BY_EMPLID_ERROR = "GRADUATE_FACULTY_BY_EMPLID_ERROR";
export const GRADUATE_FACULTY_BY_EMPLID_PROCESSING = "GRADUATE_FACULTY_BY_EMPLID_PROCESSING";
export const TYPES_OF_GPA = "TYPES_OF_GPA";
export const PRIMARY_REVIEW_DETAILS = "PRIMARY_REVIEW_DETAILS";
export const REVIEWERS = "REVIEWERS";
export const GRADUATE_FACULTY_SEARCH_ERROR = "GRADUATE_FACULTY_SEARCH_ERROR";
export const GRADUATE_FACULTY_SEARCH_PROCESSING = "GRADUATE_FACULTY_SEARCH_PROCESSING";
export const GRADUATE_FACULTY_SEARCH_QUERY = "GRADUATE_FACULTY_SEARCH_QUERY";
export const GRADUATE_FACULTY_SEARCH_RESULTS = "GRADUATE_FACULTY_SEARCH_RESULTS";
export const SEARCH_ALL_REVIEWERS = "SEARCH_ALL_REVIEWERS";
export const SAVE_REVIEWER_ERROR = "SAVE_REVIEWER_ERROR";
export const SAVE_REVIEWER_INCOMPLETE = "SAVE_REVIEWER_INCOMPLETE";
export const SAVE_REVIEWER_PROCESSING = "SAVE_REVIEWER_PROCESSING";
export const SAVE_REVIEWER_SUCCESS = "SAVE_REVIEWER_SUCCESS";
export const COLLEGE_SELECTION = "COLLEGE_SELECTION";
export const DORG_SELECTION = "DORG_SELECTION";
export const PROGRAM_OF_STUDY_SELECTION = "PROGRAM_OF_STUDY_SELECTION";
export const AWARDS_SELECTION = "AWARDS_SELECTION";
export const ACTIVE_USER_SELECTION = "ACTIVE_USER_SELECTION";
export const CHECKBOX_STATUS = "CHECKBOX_STATUS";
export const ALL_REVIEWERS_ERROR = "ALL_REVIEWERS_ERROR";
export const ALL_REVIEWERS = "ALL_REVIEWERS";
export const INVITE_REVIEWER_TO_AWARD_ERROR = "INVITE_REVIEWER_TO_AWARD_ERROR";
export const INVITE_REVIEWER_TO_AWARD_DATE = "INVITE_REVIEWER_TO_AWARD_DATE";
export const INVITE_REVIEWER_TO_AWARD_PROCESSING = "INVITE_REVIEWER_TO_AWARD_PROCESSING";
export const INVITE_REVIEWER_TO_AWARD_SUCCESS = "INVITE_REVIEWER_TO_AWARD_SUCCESS";
export const AWARD_REVIEWERS = "AWARD_REVIEWERS";
export const AWARD_REVIEWERS_ERROR = "AWARD_REVIEWERS_ERROR";
export const AWARD_REVIEWERS_LOADING = "AWARD_REVIEWERS_LOADING";
export const AWARD_REVIEWER_NOMINEES = "AWARD_REVIEWER_NOMINEES";
export const AWARD_REVIEWER_NOMINEES_ERROR = "AWARD_REVIEWER_NOMINEES_ERROR";
export const AWARD_REVIEWER_NOMINEES_LOADING = "AWARD_REVIEWER_NOMINEES_LOADING";
export const CLEAR_ALL_FILTERS = "CLEAR_ALL_FILTERS";
export const COMMITTEE_REVIEWERS_STATUS_CHANGE_ERROR ="COMMITTEE_REVIEWERS_STATUS_CHANGE_ERROR";
export const COMMITTEE_REVIEWERS_STATUS_CHANGE = "COMMITTEE_REVIEWERS_STATUS_CHANGE";
export const COMMITTEE_REVIEWERS_STATUS_CHANGE_LOADING = "COMMITTEE_REVIEWERS_STATUS_CHANGE";
export const DISTRIBUTE_REVIEWER_PACKETS_ERROR = "DISTRIBUTE_REVIEWER_PACKETS_ERROR";
export const DISTRIBUTE_REVIEWER_PACKETS_PROCESSING = "DISTRIBUTE_REVIEWER_PACKETS_PROCESSING";
export const DISTRIBUTE_REVIEWER_PACKETS_SUCCESS = "DISTRIBUTE_REVIEWER_PACKETS_SUCCESS";
export const TRANSFER_REVIEWER_PACKETS_ERROR = "TRANSFER_REVIEWER_PACKETS_ERROR";
export const TRANSFER_REVIEWER_PACKETS_PROCESSING = "TRANSFER_REVIEWER_PACKETS_PROCESSING";
export const TRANSFER_REVIEWER_PACKETS_SUCCESS = "TRANSFER_REVIEWER_PACKETS_SUCCESS";
export const TRANSFER_REVIEWER_NOMINEE_PACKETS_ERROR = "TRANSFER_REVIEWER_NOMINEE_PACKETS_ERROR";
export const TRANSFER_REVIEWER_NOMINEE_PACKETS_PROCESSING = "TRANSFER_REVIEWER_NOMINEE_PACKETS_PROCESSING";
export const TRANSFER_REVIEWER_NOMINEE_PACKETS_SUCCESS = "TRANSFER_REVIEWER_NOMINEE_PACKETS_SUCCESS";
export const AWARD_DOCUMENT_REFRESH = "AWARD_DOCUMENT_REFRESH";
export const SAVE_AWARD_INFO = "SAVE_AWARD_INFO";
export const REVIEW_ALERT_DISMISSED = "REVIEW_ALERT_DISMISSED";
export const ASSIGN_PACKETS_TO_REVIEWERS_ERROR = "ASSIGN_PACKETS_TO_REVIEWERS_ERROR";
export const ASSIGN_PACKETS_TO_REVIEWERS_ERROR_MESSAGE = "ASSIGN_PACKETS_TO_REVIEWERS_ERROR_MESSAGE";
export const ASSIGN_PACKETS_TO_REVIEWERS_PROCESSING_MESSAGE= "ASSIGN_PACKETS_TO_REVIEWERS_PROCESSING_MESSAGE";
export const ASSIGN_PACKETS_TO_REVIEWERS_PROCESSING = "ASSIGN_PACKETS_TO_REVIEWERS_PROCESSING";
export const ASSIGN_PACKETS_TO_REVIEWERS_SUCCESS = "ASSIGN_PACKETS_TO_REVIEWERS_SUCCESS";
export const AWARD_INFO = "AWARD_INFO";
export const AWARD_INFO_STATUS = "AWARD_INFO_STATUS";
export const CREATE_AWARD_KEY = "CREATE_AWARD_KEY";
export const CREATE_AWARD_STATE = "CREATE_AWARD_STATE";
export const DELETE_AWARD_STATE = "DELETE_AWARD_STATE";
export const UPDATE_AWARD_STATE = "UPDATE_AWARD_STATE";
export const NOMINATOR_PROGRAMS = "NOMINATOR_PROGRAMS";
export const NOMINATOR_ROLES = "NOMINATOR_ROLES";
export const GRADUATE_PROGRAMS = "GRADUATE_PROGRAMS";
export const GRADUATE_PROGRAMS_STATE = "GRADUATE_PROGRAMS_STATE";
export const TERMS = "TERMS";
export const TERMS_STATE = "TERMS_STATE";
export const ADD_STUDENT_SEARCH_DATA_STATE = "CADD_STUDENT_SEARCH_DATA_STATE";
export const ADD_STUDENT_SEARCH_DATA = "ADD_STUDENT_SEARCH_DATA";
export const SUBMIT_NOMINATIONS_RESULTS_STATE = "SUBMIT_NOMINATIONS_RESULTS_STATE";
export const SUBMIT_NOMINATIONS_RESULTS = "SUBMIT_NOMINATIONS_RESULTS";
export const CREATE_TOKEN_STATE = "CREATE_TOKEN_STATE";
export const CREATE_TOKEN_DATA = "CREATE_TOKEN_DATA";
export const CREATE_ACCEPTANCE_DATA = "CREATE_ACCEPTANCE_DATA";
export const CREATE_ACCEPTANCE_STATE = "CREATE_ACCEPTANCE_STATE";

export const AWARD_NOMINEES_STATE = "AWARD_NOMINEES_STATE";
export const UNAWARD_NOMINEES_STATE = "UNAWARD_NOMINEES_STATE";
export const NOMINEE_CARD_RECORD = "NOMINEE_CARD_RECORD";
export const NOMINEE_CARD_FORM = "NOMINEE_CARD_FORM";
export const NOMINEE_CARD_FORM_SUBMISSION = "NOMINEE_CARD_FORM_SUBMISSION";
export const NOMINEE_CARD_FORM_CLEAR = 'NOMINEE_CARD_FORM_CLEAR';
export const NOMINEE_CARD_PACKET = "NOMINEE_CARD_PACKET";
export const NOMINEE_CARD_SAVE = "NOMINEE_CARD_SAVE";
export const UPLOAD_NOMINEE_PACKET_ID = "UPLOAD_NOMINEE_PACKET_ID";
export const UPLOAD_NOMINEE_PACKET_STATE = "UPLOAD_NOMINEE_PACKET_STATE";
export const UPLOAD_NOMINEE_PETITION_ID = "UPLOAD_NOMINEE_PETITION_ID";
export const UPLOAD_NOMINEE_PETITION_STATE = "UPLOAD_NOMINEE_PETITION_STATE";
export const DELETE_NOMINEE_DOCUMENT_STATE = "DELETE_NOMINEE_DOCUMENT_STATE";
export const DELETE_NOMINEE_DOCUMENT = 'DELETE_NOMINEE_DOCUMENT'
export const UPDATE_NOMINEE_CARD_AWARDED = 'UPDATE_NOMINEE_CARD_AWARDED'
export const NOMINEE_CARD_INFO = 'NOMINEE_CARD_INFO'
export const REFRESH_NOMINEE_STATE = "REFRESH_NOMINEE_STATE";
export const REFRESHED_NOMINEE = "REFRESHED_NOMINEE";

export const SHOW_PREVIOUS_AWARDS = "SHOW_PREVIOUS_AWARDS";
export const SHOW_PREVIOUS_AWARDS_FOR_AWARDED = "SHOW_PREVIOUS_AWARDS_FOR_AWARDED";

/* Export each action file below */
export * from "./App/actions";
export * from "./AwardManagement/actions";
export * from "./AwardAcceptance/actions";
export * from "./ProtectedExample/actions";
export * from "./NomineeReview/actions";
export * from "./Common/actions";
export * from "./ReviewerManagement/actions";
export * from "./ReviewerManagement/CommitteeTabs/actions";
export * from "./ReviewerManagement/actions";
export * from "./PDFView/actions";
export * from "./Nominations/actions";
