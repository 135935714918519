import { SET_A11Y_MESSSAGE, SET_USER, USER_IS_LOADING, UPDATE_BREADCRUMB_TRAIL, NOMINATOR_PROGRAMS, NOMINATOR_ROLES } from "../actions-index";
import { Auth } from 'aws-amplify';
import { forEach, startsWith } from "lodash";

import { buildAction, redirectToLogIn } from '../util/util';

export function setA11yMessage(a11yMessage) {
    return { type: SET_A11Y_MESSSAGE, payload: a11yMessage };
}

export function updateBreadcrumbTrail(pathObjs) {
    return {
        type: UPDATE_BREADCRUMB_TRAIL,
        payload: pathObjs
    }
}

export function userIsLoading(isLoading) {
    return {
        type: USER_IS_LOADING,
        payload: isLoading
    }
}

export function recievedSetUser(user) {
    return {
        type: SET_USER,
        payload: user
    }
}

export function setUser() {
    return function (dispatch) {
        dispatch(userIsLoading(true));
        return Auth.currentSession()
            .then((session) => {
                const user = session.idToken.payload;
                const userRoles = (user[process.env.REACT_APP_USER_ROLE_ATTRIBUTE_KEY] ? user[process.env.REACT_APP_USER_ROLE_ATTRIBUTE_KEY].split(",") : []);
                const nominatorRolePrefix = `${process.env.REACT_APP_USER_ROLE_NOMINATOR}-`;
                const nominatorRoles = [];
                const nominatorPrograms = [];
                forEach(userRoles, userRole => {
                    if(startsWith(userRole, nominatorRolePrefix)) {
                        nominatorRoles.push(userRole);
                        const nominatorRoleParts = userRole.split("-");
                        if(nominatorRoleParts[1]) nominatorPrograms.push(nominatorRoleParts[1]);
                    }
                });
                dispatch(buildAction(NOMINATOR_PROGRAMS, nominatorPrograms));
                dispatch(buildAction(NOMINATOR_ROLES, nominatorRoles));
                dispatch(recievedSetUser(user)); // user be set after nominator roles or isNominator logic will not function properly
            })
            .catch((error) => {
                if (error === 'No current user' || error==='Request failed with status code 401'|| (error.response!==undefined && error.response.status!==undefined && error.response.status===401)) {
                    redirectToLogIn();
                }
            }).finally(() => {
                dispatch(userIsLoading(false));
            });
    }
}

export function clearUser() {
    return dispatch => {
        dispatch(recievedSetUser({}));
    };
}