import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { Form, FormFeedback, FormGroup, Input, Label } from "reactstrap";
import { Body1 } from "osu-react-components";
import { cloneDeep, values } from "lodash";
import { EVENT_RESET_STATE, GPA_ELIGIBILITY_WAIVER, GPA_ELIGIBILITY_WAIVER_PETITION, GPA_MAX_LENGTH, GPA_REGEX, NO, YES } from "../../constants";
import { FORM_FEEDBACK_SR_PREFIX } from "../../../util/constants";

class Eligibility extends Component {
    componentWillMount() {
        this.setComponentState();
        document.addEventListener(EVENT_RESET_STATE, this.setComponentState);
    }

    componentDidMount() {
        this.validate();
    }

    shouldComponentUpdate(nextProps) {
        return nextProps.isActive; // re-render only when active
    }

    componentWillUnmount() {
        document.removeEventListener(EVENT_RESET_STATE, this.setComponentState);
    }

    setComponentState = () => {
        const { award } = this.props;
        const eligibility = this.getEligibility(award);
        this.setState({
            award,
            invalid: {
                gpaRequirement: (eligibility.minimumGpaRequired === true ? !this.validateGpa(eligibility.gpaRequirement) : false)
            }
        });
    }

    getEligibility = (award = {}) => {
        const { eligibility = {} } = award;
        if(!eligibility.minimumGpaRequired) eligibility.minimumGpaRequired = false;
        if(!eligibility.gpaRequirement) eligibility.gpaRequirement = "";
        if(!eligibility.gpaEligibilityWaiver) eligibility.gpaEligibilityWaiver = null;
        if(!eligibility.petitionDisclaimer) eligibility.petitionDisclaimer = "";
        if(!eligibility.usCitizenRequired) eligibility.usCitizenRequired = false;
        return eligibility;
    }

    validate = () => {
        const isValid = values(this.state.invalid).every(invalid => (invalid === false));
        this.props.onValidate(isValid, isValid ? this.state.award : null);
    }

    validateRequired = (value) => {
        return (value && value.length > 0);
    }

    validateGpa = (gpa) => {
        return (this.validateRequired(gpa) && gpa.length <= GPA_MAX_LENGTH && GPA_REGEX.test(gpa));
    }

    onPetitionDisclaimerChange = (event) => {
        const award = cloneDeep(this.state.award);
        award.eligibility.petitionDisclaimer = event.target.value;
        this.setState({ award }, () => this.validate());
    }

    onGPARequirementChange = (event) => {
        const award = cloneDeep(this.state.award);
        award.eligibility.gpaRequirement = event.target.value;
        const invalid = {...this.state.invalid, gpaRequirement: !this.validateGpa(event.target.value)};
        this.setState({ award, invalid }, () => this.validate());
    }

    onUSCitizenRequiredChange = (event) => {
        const award = cloneDeep(this.state.award);
        award.eligibility.usCitizenRequired = event.target.checked;
        this.setState({ award }, () => this.validate());
    }

    render() {
        const { isLocked, readOnly } = this.props;
        const { award, invalid } = this.state;
        const eligibility = this.getEligibility(award);
        return (
            <section>
                <h2 className="heading6" data-testid="eligibilityHeader">Eligibility</h2>
                <hr />
                <Form id="eligibilityForm" data-testid="eligibilityForm" noValidate style={{ marginTop: "2rem" }}>
                    {eligibility.minimumGpaRequired === true &&
                        <Fragment>
                            {eligibility.gpaEligibilityWaiver &&
                                <Fragment>
                                    <Body1 dataTestId="gpaEligibilityWaiver" className="my-3">{GPA_ELIGIBILITY_WAIVER[eligibility.gpaEligibilityWaiver]}</Body1>
                                    {eligibility.gpaEligibilityWaiver === GPA_ELIGIBILITY_WAIVER_PETITION &&
                                        <FormGroup data-testid="petitionDisclaimerFormGroup" className="mt-3">
                                            <Label for="petitionDisclaimer"><Body1>Petition Disclaimer (displayed when submitting):</Body1></Label>
                                            {readOnly ?
                                                (<Body1 id="petitionDisclaimer" name="petitionDisclaimer" dataTestId="petitionDisclaimer" className="ml-2">
                                                    {eligibility.petitionDisclaimer}
                                                </Body1>) :
                                                (<Fragment>
                                                    <Input id="petitionDisclaimer" name="petitionDisclaimer" data-testid="petitionDisclaimer" type="textarea"
                                                        rows="4" value={eligibility.petitionDisclaimer} style={{ maxWidth: "22rem", resize: "vertical" }}
                                                        onChange={this.onPetitionDisclaimerChange} />
                                                </Fragment>)
                                            }
                                        </FormGroup>
                                    }
                                </Fragment>
                            }
                            <FormGroup data-testid="gpaRequirementFormGroup">
                                <Label for="gpaRequirement" className="d-inline-block mb-0 mr-2">
                                    <Body1 className={(isLocked || readOnly) ? null : "required"}>GPA Requirement:</Body1>
                                </Label>
                                {(isLocked || readOnly) ?
                                    (<Body1 id="gpaRequirement" name="gpaRequirement" dataTestId="gpaRequirement" className="d-inline-block">
                                        {eligibility.gpaRequirement}
                                    </Body1>) :
                                    (<Fragment>
                                        <Input id="gpaRequirement" name="gpaRequirement" data-testid="gpaRequirement" type="text" maxLength={GPA_MAX_LENGTH}
                                            defaultValue={eligibility.gpaRequirement} aria-required="true" invalid={invalid.gpaRequirement} aria-invalid={invalid.gpaRequirement}
                                            style={{ width: "5.7rem" }} className="d-inline-block" onBlur={this.onGPARequirementChange} />
                                        <FormFeedback aria-live="polite">{FORM_FEEDBACK_SR_PREFIX}GPA is required and must be a number between 0 and 4.</FormFeedback>
                                    </Fragment>)
                                }
                            </FormGroup>
                        </Fragment>
                    }
                    <FormGroup data-testid="usCitizenRequiredFormGroup">
                        <Label for="usCitizenRequired" className="d-inline-block mb-0">
                            <Body1 className="d-inline-block">U.S. Citizen Required:</Body1>
                        </Label>
                        {(isLocked || readOnly) ?
                            (<Body1 id="usCitizenRequired" name="usCitizenRequired" dataTestId="usCitizenRequired" className="d-inline-block ml-2">
                                {eligibility.usCitizenRequired === true ? YES : NO}
                            </Body1>) :
                            (<Fragment>
                                <Input id="usCitizenRequired" name="usCitizenRequired" data-testid="usCitizenRequired" type="checkbox"
                                    checked={eligibility.usCitizenRequired} className="d-inline-block position-relative ml-2 mt-0" onChange={this.onUSCitizenRequiredChange} />
                            </Fragment>)
                        }
                    </FormGroup>
                </Form>
            </section>
        );
    }
}

Eligibility.defaultProps = {
    isActive: false,
    inReview: false,
    readOnly: false,
    isLocked: false
}

Eligibility.propTypes = {
    award: PropTypes.object.isRequired,
    isActive: PropTypes.bool,
    inReview: PropTypes.bool,
    onValidate: PropTypes.func.isRequired,
    readOnly: PropTypes.bool,
    isLocked: PropTypes.bool
}

export default Eligibility;