import React from "react";
import { Link } from "react-router-dom";
import {
  Heading5,
  Heading6,
  Table,
  PaginationWrapper,
  OSUButton,
  OSULoading
} from "osu-react-components";
import {
  Row, Col
} from "reactstrap";
import { chunk, isEmpty } from "lodash";
import ComplexFilter from "../../Common/ComplexFilter/components/ComplexFilter.js";
import EllipsisWithTooltip from 'react-ellipsis-with-tooltip';

import * as Globals from '../../constants'
const COLLEGE = "COLLEGE";
const DORG = "DORG";
const PROGRAM_OF_STUDY = "PROGRAM OF STUDY";
const AWARDS = "AWARDS";


class AllReviewers extends React.Component {

  componentWillMount() {
    document.title = this.state.pageTitle + Globals.PAGE_TITLE_POSTFIX;
    if(!this.props.allAwards || isEmpty(this.props.allAwards)){
      this.props.getAllAwards();
    }
  }

  componentDidMount() {
    this.props.setA11yMessage(`Navigated to the ${this.state.pageTitle} page`);
    this.pageContent.focus();
  }

  dataKeys = () => {

    const dataKeys = [
      {
        key: "linkName",
        label: "Name",
        width: 75,
        className: "align-self-end min-width-0"
      },
      {
        key: "committesServed",
        label: "Commitees Served",
        width: 25,
        className: "align-self-end text-center min-width-0"
      }
    ];

    if (window.innerWidth > Globals.MOBILE_MAXIMUM_RESOLUTION) {
      return [
        {
          key: "linkName",
          label: "Name",
          width: 25,
          className: "align-self-end min-width-0"
        },
        {
          key: "renderedDorg",
          label: "D-Org",
          width: 20,
          className: "align-self-end min-width-0"
        },
        {
          key: "renderedCollege",
          label: "College",
          width: 15,
          className: "align-self-end min-width-0"
        },
        {
          key: "committesServed",
          label: "Commitees Served",
          width: 10,
          className: "align-self-end text-center min-width-0"
        },
        {
          key: "renderedComments",
          label: "Comments",
          width: 30,
          className: "align-self-end min-width-0"
        }
      ];
    }
    return dataKeys;
  }


  addNewReviewer = () => {
    return <Heading6 className="mb-2">{`ALL Reviewers`}</Heading6>;
  };

  collegeDropdownResults = () => {
    return Array.from(
      new Set(this.state.reviewers.map(reviewer => reviewer.college.toString()))
    ).sort();
  };

  dorgDropdownResults = () => {
    return Array.from(
      new Set(
        this.state.reviewers.map(
          reviewer => (reviewer.dorg ? reviewer.dorg : reviewer.dOrg)
        )
      )
    ).sort();
  };

  programOfStudyDropdownResults = () => {
    const awards = [];
    this.state.reviewers.forEach(function (reviewer) {
      if (reviewer.shortPlans) {
        awards.push(reviewer.shortPlans);
      }
    });
    return Array.from(new Set(awards.reduce((acc, val) => acc.concat(val), []))).sort();
  };

  awardsDropdownResults = () => {
    const awards = [];
    this.state.reviewers.forEach(function (reviewer) {
      if (reviewer.stats) {
        awards.push(Object.keys(reviewer.stats));
      }
    });
    let uniqueAwardKeys = Array.from(new Set(awards.reduce((acc, val) => acc.concat(val), []))).sort();
    let awardTitles = this.props.allAwards.filter( award => uniqueAwardKeys.includes(award.pk) ).map(award => ({ label: award.name, value: award.pk}))
    return awardTitles;
  };

  activeUserDropdownResults = () => {
    const awards = [];
    this.state.reviewers.forEach(function (reviewer) {
      if (reviewer.stats) {
        awards.push(Object.keys(reviewer.stats));
      }
    });
    return Array.from(new Set(awards.reduce((acc, val) => acc.concat(val), []))).sort();
  };

  setDataIndex = dataIndex => {
    this.setState({ dataIndex });
  };

  setRowsPerPage = rowsPerPage => {
    this.setState({ rowsPerPage });
  };

  toggle() {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen
    });
  }

  linkReviewerDetails = filteredReviewers => {
    if (filteredReviewers.length === 0) {
      return [];
    }
    const linkedReviewerDetails = filteredReviewers.map(reviewer => {
      reviewer.linkName = (
        <Link to={{
          pathname: "/reviewer-management/reviewer/" + reviewer.emplid,
          state: {
            breadcrumb: {
              path: "/reviewer-management/all-reviewers", text: "All Reviewers"
            }
          }
        }}>
          {reviewer.name}
        </Link>
      );
      reviewer.renderedDorg = (<EllipsisWithTooltip placement="bottom">{reviewer.dorg}</EllipsisWithTooltip>)
      reviewer.renderedCollege = (<EllipsisWithTooltip placement="bottom">{reviewer.college}</EllipsisWithTooltip>)
      reviewer.renderedComments = ((<EllipsisWithTooltip placement="bottom">{reviewer.comments || ''}</EllipsisWithTooltip>))

      return reviewer;
    });
    return linkedReviewerDetails;
  };

  filteredReviewers = (query, allReviewers) => {
    // replace allReviewers with empty array if the data hasn't pulled yet
    allReviewers = allReviewers ? allReviewers : [];
    const reviewers = this.filterOnDropDownSelections(
      this.filterInactiveReviewers(
        this.filterOnNamesAndComments(allReviewers, query)
      )
    );
    reviewers.forEach(function (reviewer) {
      reviewer.dorg = reviewer.dOrg;
      reviewer.committesServed = reviewer.stats
        ? Object.keys(reviewer.stats).length
        : 0;
    });
    return reviewers;
  };

  filterInactiveReviewers(reviewers) {
    const filteredReviewers = reviewers.filter(reviewer => {
      return (reviewer.active && this.state.activeUserDropdownSelection === "Active")
        || (!reviewer.active && this.state.activeUserDropdownSelection === "Inactive")
        || (this.state.activeUserDropdownSelection === "All")
        || (this.state.activeUserDropdownSelection === "")
        ? reviewer : null;
    });
    return filteredReviewers;
  }

  filterOnNamesAndComments(reviewers, query) {
    const filteredReviewers = reviewers.filter(reviewer => {
      if (query && query !== "") {
        return (
          (reviewer.name && reviewer.name.toLowerCase().includes(query.toLowerCase())) ||
          ((reviewer.comments &&
            reviewer.comments.toLowerCase().includes(query.toLowerCase())) ||
            (reviewer.emplid && reviewer.emplid.toLowerCase().includes(query.toLowerCase())))
        );
      }
      return reviewer;
    });
    return filteredReviewers;
  }

  filterOnDropDownSelections(reviewers) {

    let filteredReviewers = reviewers.slice();
    filteredReviewers = this.state.collegeDropdownSelection
      ? this.filterReviewersForDropdown(
        filteredReviewers,
        this.state.collegeDropdownSelection,
        COLLEGE
      )
      : filteredReviewers;
    filteredReviewers = this.state.dorgDropdownSelection
      ? this.filterReviewersForDropdown(
        filteredReviewers,
        this.state.dorgDropdownSelection,
        DORG
      )
      : filteredReviewers;
    filteredReviewers = this.state.programOfStudyDropdownSelection
      ? this.filterReviewersForDropdown(
        filteredReviewers,
        this.state.programOfStudyDropdownSelection,
        PROGRAM_OF_STUDY
      )
      : filteredReviewers;
    filteredReviewers = this.state.awardsDropdownSelection
      ? this.filterReviewersForDropdown(
        filteredReviewers,
        this.state.awardsDropdownSelection,
        AWARDS
      )
      : filteredReviewers;
    return filteredReviewers;
  }

  filterReviewersForDropdown(reviewers, selection, dropdownType) {
    const filteredReviewers = [];
    reviewers.forEach(function (reviewer) {
      if (dropdownType === COLLEGE && reviewer.college === selection) {
        filteredReviewers.push(reviewer);
      } else if (dropdownType === DORG && reviewer.dOrg === selection) {
        filteredReviewers.push(reviewer);
      } else if (dropdownType === PROGRAM_OF_STUDY) {
        reviewer.shortPlans.forEach(function (program) {
          if (program === selection) {
            filteredReviewers.push(reviewer);
          }
        });
      } else if (dropdownType === AWARDS && reviewer.stats) {
        Object.keys(reviewer.stats).forEach(function (award) {
          if (award === selection.value) {
            filteredReviewers.push(reviewer);
          }
        });
      }
    });
    return filteredReviewers;
  }


  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);

    this.state = {
      pageTitle: "All Reviewers",
      dataIndex: 0,
      rowsPerPage: 10,
      reviewersLoading: true,
      previousFilteredResultCount: 0,
      filteredResultCount: 0,
      tooltipOpen: false,
      allReviewers: [],
      reviewers: [],
      collegeDropdownSelection: "",
      dorgDropdownSelection: "",
      programOfStudyDropdownSelection: "",
      awardsDropdownSelection: "",
      activeUserDropdownSelection: "",
    }


  }


  updateFilterState() {
    const state = this.state;
    if (this.filterStateHasChanged(state)) {
      if (this.state.dataIndex !== 0) {
        this.setState({ dataIndex: 0 });
      }
      state.collegeDropdownSelection = this.props.filterOnCollegeDropdown
        ? this.props.filterOnCollegeDropdown
        : "";
      state.dorgDropdownSelection = this.props.filterOnDorgDropdown
        ? this.props.filterOnDorgDropdown
        : "";
      state.programOfStudyDropdownSelection = this.props
        .filterOnProgramOfStudyDropdown
        ? this.props.filterOnProgramOfStudyDropdown
        : "";
      state.awardsDropdownSelection = this.props.filterOnAwardsDropdown;
      state.activeUserDropdownSelection = this.props.filterOnActiveUserDropdown
        ? this.props.filterOnActiveUserDropdown
        : "";
      this.setState(state);
    }

  }

  validateLoadingState() {
    if (this.state.reviewers.length > 0 && this.state.reviewersLoading)
      this.setState({
        ...this.state,
        reviewersLoading: false
      });
  }

  filterStateHasChanged() {
    return (
      this.state.collegeDropdownSelection !== this.props.filterOnCollegeDropdown ||
      this.state.dorgDropdownSelection !== this.props.filterOnDorgDropdown ||
      this.state.programOfStudyDropdownSelection !== this.props.filterOnProgramOfStudyDropdown ||
      this.state.awardsDropdownSelection !== this.props.filterOnAwardsDropdown ||
      this.state.activeUserDropdownSelection !== this.props.filterOnActiveUserDropdown)

  }

  updateRenderedReviewerState(renderableReviewers) {
    const state = this.state;
    if (!state.reviewers || state.reviewers.length !== renderableReviewers.length) {
      state.reviewers = renderableReviewers;
      this.setState(state);
    }
  }

  render() {
    const {
      allReviewers,
      searchReviewers,
      query,
    } = this.props;

    const filters = [];
    const collegeFilter = {
      action: this.props.collegeFilterAction,
      name: "College",
      selection: this.props.filterOnCollegeDropdown,
      results: this.collegeDropdownResults(),
    }
    const dorgFilter = {
      action: this.props.dorgFilterAction,
      name: "D-Org",
      selection: this.props.filterOnDorgDropdown,
      results: this.dorgDropdownResults(),
    }
    const programOfStudyFilter = {
      action: this.props.programOfStudyFilterAction,
      name: "Program Of Study",
      selection: this.props.filterOnProgramOfStudyDropdown,
      results: this.programOfStudyDropdownResults(),
    }
    const awardsFilter = {
      action: this.props.awardsFilterAction,
      name: "Awards",
      selection: this.props.filterOnAwardsDropdown,
      results: this.awardsDropdownResults(),
    }
    const activeUserFilter = {
      action: this.props.activeUserFilterAction,
      name: "Employment Status",
      selection: this.props.filterOnActiveUserDropdown,
      results: ["Active", "All", "Inactive"],
    }
    filters.push(collegeFilter);
    filters.push(dorgFilter);
    filters.push(programOfStudyFilter);
    filters.push(awardsFilter);
    filters.push(activeUserFilter);
    const filteredReviewers = this.filteredReviewers(query, allReviewers);
    const renderableReviewers = this.linkReviewerDetails(filteredReviewers);
    this.updateFilterState();
    this.updateRenderedReviewerState(renderableReviewers);

    const previousFilteredResultCount = this.state.filteredResultCount;
    const filteredResultCount = allReviewers ? allReviewers.length - filteredReviewers.length : 0;

    const chunkedData = renderableReviewers.length > 0 ?
      chunk(renderableReviewers, this.state.rowsPerPage || renderableReviewers.length) : [{}];
    if (this.state.dataIndex !== 0 && ((previousFilteredResultCount !== filteredResultCount) || this.filterStateHasChanged())) {

      this.setState({ dataIndex: 0, filteredResultCount: filteredResultCount });

    }

    this.validateLoadingState();

    if (this.state.reviewersLoading) {
      return (
        <div ref={(el) => { this.pageContent = el; }} tabIndex="-1" aria-labelledby="pageHeader">
          <Row>
            <Col xs="8" ><Heading5 xs="8" id="pageHeader" className="mb-4">{this.state.pageTitle}</Heading5></Col>
            <Col align="right">
              <OSUButton
                solid
                color="blue"
                ariaLabel="Add New Reviewer"
                path={{ pathname: "/reviewer-management/add-reviewer" }}
              >
                Add New Reviewer
          </OSUButton>
            </Col>
          </Row>

          <br />
          <div>
            <ComplexFilter
              filters={filters}
              searchReviewers={searchReviewers}
              query={query}
              clearAll={this.clearAll}
            />
            <OSULoading text="Loading..." />

          </div>
        </div>
      )

    } else {

      return (
        <div ref={(el) => { this.pageContent = el; }} tabIndex="-1" aria-labelledby="pageHeader">
          <Row>
            <Col xs="8" ><Heading5 xs="8" id="pageHeader" className="mb-4">{this.state.pageTitle}</Heading5></Col>
            <Col align="right">
              <OSUButton
                solid
                color="blue"
                ariaLabel="Add New Reviewer"
                path={{ pathname: "/reviewer-management/add-reviewer" }}
              >
                Add New Reviewer
          </OSUButton>
            </Col>
          </Row>

          <br />
          <div>
            <ComplexFilter
              filters={filters}
              searchReviewers={searchReviewers}
              query={query}
              clearAll={this.clearAll}
            />

            <PaginationWrapper
              persist
              totalPageCount={chunkedData.length}
              updateDataIndex={this.setDataIndex}
              updateRowsPerPage={this.setRowsPerPage}
              dataIndex={this.state.dataIndex}
              resultsData={{ shownResults: renderableReviewers.length, totalResults: allReviewers ? allReviewers.length : 0 }}
              showOptionalCount={true}
              rowsPerPageOptions={[10, 20, 30]}
              rowsPerPage={this.state.rowsPerPage}
            >
              <Table
                sortable
                paginationData={{
                  rowsPerPage: this.state.rowsPerPage,
                  dataIndex: this.state.dataIndex,
                }}
                headerVariant="subtitle2"
                data={renderableReviewers}
                dataKeys={this.dataKeys()}
              />
            </PaginationWrapper>
          </div>
        </div>
      );
    }
  }
}

export default AllReviewers;
