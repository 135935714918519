import React from 'react';
import { Body1, Caption, Heading6 } from 'osu-react-components';
import { chunk, map } from 'lodash';
import PropTypes from 'prop-types';

class Criteria extends React.Component {
    render() {
        const { ranges, name, weight, evaluationGuideLines, field } = this.props;
        const prettyRanges = this.roundRangesUpToWholeNumber(ranges)
        const rulesJsx = chunk(map(prettyRanges, (val, key) => {
            return <Body1 className="rules-item mr-2" key={`${key}${name}`}>
                <span className="range mr-3">{key}</span>
                <span className="description">{val.description}</span>
            </Body1>
        }), 2);
        return <div data-testid={`${name}-component`} className={this.props.className}>
            <Heading6>{name}</Heading6>
            <Body1>Weighted at {weight}%</Body1>
            <hr />
            <Caption color="gray">Scoring Rules</Caption>
            <div className="mb-4 d-flex flex-column">
                {rulesJsx.map((group, index) => <div key={`${index}`} className="d-flex flex-column flex-lg-row">
                    {group}
                </div>
                )}
            </div>
            <Caption color="gray">Evaluation Criteria</Caption>
            {Array.isArray(evaluationGuideLines) && evaluationGuideLines.length > 0 ? <ul className="criteria-list">
                {evaluationGuideLines.map((item, index) => <li key={field + index}>
                    <Body1>- {item}</Body1>
                </li>)}
            </ul> : <Body1>No criteria found</Body1>}
        </div>
    }

    roundRangesUpToWholeNumber = (ranges) => {
        let prettyRanges = {};
        prettyRanges =  Object.keys(ranges).sort((element1, element2) => element1 > element2? -1: element1 <= element2 ? 1 : 0 ).reduce( (accumulator, range) => {
            
            const ceilingOfMinimum = Math.ceil(ranges[range].min).toString();
            const ceilingOfMaximum = Math.ceil(ranges[range].max).toString();
            return  { ...accumulator, [ceilingOfMinimum + " - " + ceilingOfMaximum]: ranges[range] } 
        }, prettyRanges);
        return prettyRanges;
    }
}

Criteria.propTypes = {
    className: PropTypes.string,
    name: PropTypes.string.isRequired,
    field: PropTypes.string.isRequired,
    evaluationGuideLines: PropTypes.arrayOf(PropTypes.string).isRequired,
    ranges: PropTypes.object
};

export default Criteria