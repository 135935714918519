import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { determineGpaEligibility } from "../transform";
import { updateNominationCardForm } from "../../../actions-index";
import GPARequired from "../components/GPARequired";
import { convertStringToBool } from "../../../util/util";

const mapStateToProps = (state = {}, props = {}) => {
    const { nomineeCard = {}, awardInfo = { data: { awardResultInfo: { awardOptions: [] }}} } = state
    const { record = { }, form = {} } = (nomineeCard || {})
    const { cumGpa, externalUgrdGpa } = (record || {})

    const { data = {}, status = '' } = awardInfo
    const { eligibility = {}, studentStatus } = data
    const meetsGpaRequirement = determineGpaEligibility({ studentStatus, minimumGpa: eligibility.gpaRequirement, cumGpa, externalUgrdGpa })
    const gpaEligibilityOverride = (form.gpaEligibilityOverride || record.gpaEligibilityOverride) || undefined
    const gpaWaiver = (form.gpaWaiver || record.gpaWaiver) || undefined
    const gpaPetitionApproval = (form.gpaPetitionApproval || record.gpaPetitionApproval) || undefined
    
    return {
        meetsGpaRequirement,
        gpaWaiver: convertStringToBool(gpaWaiver),
        gpaEligibilityWaiver: eligibility.gpaEligibilityWaiver,
        gpaEligibilityOverride: convertStringToBool(gpaEligibilityOverride),
        loading: status === 'loading',
        gpaPetitionApproval,
        ...data
    };
};

const mapDispatchToProps = (dispatch) => ({
    saveChanges: (changes) => dispatch(updateNominationCardForm(changes))
}); 

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(GPARequired));
