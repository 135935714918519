import { AWARD_NOMINEES_STATUS, AWARD_NOMINEES_SORT_KEY, AWARD_NOMINEES_DETAILS, AWARD_NOMINEES_FILTER, AWARD_NOMINEES_QUERY, AWARD_NOMINEES, AWARD_DOCUMENT_REFRESH, SAVE_AWARD_INFO, UPDATE_NOMINEE_CARD_AWARDED,  SHOW_PREVIOUS_AWARDS, SHOW_PREVIOUS_AWARDS_FOR_AWARDED } from "../../actions-index";
import { API } from "aws-amplify";
import { filter } from "lodash";
import { setApiStatus } from "../../redux-helpers";
import { systemHeaders, redirectToLogIn, buildAction } from '../../util/util';
import { getAllCurrentAwardedNomineesForAllAwards } from '../../Common/actions'
export function getAllNominees(awardKey, sortKey) {
    const setAllNomineesStatus = (status) => setApiStatus(AWARD_NOMINEES_STATUS, status);
    return (dispatch) => {
        dispatch(setAllNomineesStatus('loading'));

        API.get(process.env.REACT_APP_AWARD_NOMINEE_REVIEW_API_NAME, `/getAwardNominees/${window.btoa(awardKey)}`, systemHeaders())
            .then(response => {
                if (response.data) {
                    dispatch({ type: AWARD_NOMINEES, payload: response.data });
                } else if (response.status !== undefined && response.status === 401) {
                    console.error("401 Response in getAllNominees:", response.status);
                    dispatch(setAllNomineesStatus('401'));
                } else {
                    console.error("no Response data error getAllNominees:", response);
                    dispatch(setAllNomineesStatus('error'));
                }
            })
            .catch(error => {
                if (error === 'No current user' || error === 'Request failed with status code 401' || (error.response !== undefined && error.response.status !== undefined && error.response.status === 401)) {
                    dispatch(setAllNomineesStatus('401'));
                } else {
                    dispatch(setAllNomineesStatus('error'));
                }
            })
            .finally(() => sortKey && dispatch(updateAllNomineesSortForActiveReview(sortKey)))
    }
}


export function getCurrentAwardInfo(awardKey) {
    const setCurrentAwardStatus = (status) => setApiStatus("CURRENT_AWARDS_STATUS", status);
    return (dispatch) => {
        dispatch(setCurrentAwardStatus('loading'));
        API.get(process.env.REACT_APP_GRAD_AWARD_API_NAME, "/award/" + window.btoa(awardKey))
            .then(response => {
                if (response.data && response.data) {
                    dispatch(saveAwardInfo(response.data));
                    dispatch(setCurrentAwardStatus('success'));
                } else if (response.status !== undefined && response.status === 401) {
                    dispatch(setCurrentAwardStatus('401'));
                } else {
                    dispatch(setCurrentAwardStatus('error'));
                }
            })
            .catch(error => {
                console.error("Error in getCurrentAwards:", error);
                if (error === 'No current user' || error === 'Request failed with status code 401' || (error.response !== undefined && error.response.status !== undefined && error.response.status === 401)) {
                    dispatch(setCurrentAwardStatus('401'));
                } else {
                    dispatch(setCurrentAwardStatus('error'));
                }
            });
    }
}

export function sendAwardLetterEmail(nominees, sortKey) {

    const setAllNomineesStatus = (status) => setApiStatus(AWARD_NOMINEES_STATUS, status)

    const awardKeys = [...new Set(nominees.map(nominee => {
        return nominee.awardLetterPk;
    }))]


    return (dispatch) => {

        awardKeys.forEach(awardKey => {
            dispatch(setAllNomineesStatus('loading'));

            console.log("refreshing Document list");
            let params = {};
            let pathParam = `/sendAwardLetterEmail`;
            let resp = {};

            params = {
                ...systemHeaders(),
                body: {
                    nominees : nominees.map(nominee => {
                        return {
                            pk: nominee.pk,
                            awardLetterPk: nominee.awardLetterPk,
                            sk: nominee.sk
                        }
                    })
                }
            };

            API.post(process.env.REACT_APP_AWARD_NOMINEE_REVIEW_API_NAME, "/updateSendingStatus", params)
            .then(response => {
                params = {
                    ...systemHeaders(),
                    body: {
                        awardPK: awardKey,
                        nomineeSKCollection: nominees.map(nominee => {
                            return `NOMINEE#${nominee.nomineeId}#${nominee.shortPlan}`;
                        }),
                    }
                }

                API.post(process.env.REACT_APP_GRAD_AWARD_API_NAME, pathParam, params)
                .then(response => {
                    if (response) {

                        setTimeout(() => {
                            dispatch(getAllCurrentAwardedNomineesForAllAwards(sortKey));
                        }, 3000);

                    } else if (response.status !== undefined && response.status === 401) {
                        console.error("401 Response in refreshDocumentList:", response.status);
                        redirectToLogIn();
                    } else {
                        console.error("no Response data error refreshDocumentList:", response);
                        dispatch(receiveDocumentRefreshList(resp));
                    }
                })
                .catch(error => {
                    console.error("Error in refreshDocumentList:", error);
                    if (error === 'No current user' || error === 'Request failed with status code 401' || (error.response !== undefined && error.response.status !== undefined && error.response.status === 401)) {
                        redirectToLogIn();
                    } else {
                        dispatch(receiveDocumentRefreshList(resp));
                    }
                })

            })


        })
    }


}




export function grantAwardToSelected(awardKey, nominees, awarded, systemAward, nomineeCardFlag) {
    const setAllNomineesStatus = (status) => setApiStatus(AWARD_NOMINEES_STATUS, status)

    const getAssociatedAwardsForNominees = (systemAward) => {
        let awardsList = [];
        nominees.forEach(nominee => {
            const nomineeSk = "NOMINEE#" + nominee.nomineeId + "#" + nominee.shortPlan;
            systemAward.awardResultInfo.awardResults.forEach(awardResult => {
                if (nomineeSk === awardResult.nomineeSK) {
                    awardsList.push(awardResult.awardResultABBV);
                }
            })
        });
        return awardsList;
    }

    return async (dispatch) => {
        dispatch(setAllNomineesStatus('loading'));

        console.log("refreshing Document list");
        let params = {};
        let pathParam = `/grantAwardToSelected`;

        if (awarded === "REMOVE_SELECTED") {
            pathParam = `/unawardNominees`;
            params = {
                ...systemHeaders(),
                body: {
                    awardKey,
                    nominees: nominees.map(nominee => {
                        return `NOMINEE#${nominee.nomineeId}#${nominee.shortPlan}`;
                    })
                }
            }
        } else if (awarded === "UNAWARD_SELECTED") {
            pathParam = `/unawardNominees`;
            params = {
                ...systemHeaders(),
                body: {
                    awardKey,
                    nominees: nominees.map(nominee => {
                        return `NOMINEE#${nominee.nomineeId}#${nominee.shortPlan}`;
                    }),
                    awardResults: getAssociatedAwardsForNominees(systemAward, nominees)
                }
            }
        } else if (awarded === "NOT_AWARDED_SELECTED") {
            pathParam = `/unawardNominees`;
            const nomineesToSetNotAwarded = filter(nominees, nominee => {
                return (["NOMINATED", "PENDING"].includes(nominee.status));
            }).map(nominee => {
                return `NOMINEE#${nominee.nomineeId}#${nominee.shortPlan}`;
            });
            if(nomineesToSetNotAwarded.length === 0) { // no changes, but appear to update anyway
                dispatch(setAllNomineesStatus('loading'));
                setTimeout(() => dispatch(setAllNomineesStatus('success')), 500);
                return;
            }
            params = {
                ...systemHeaders(),
                body: {
                    awardKey,
                    nominees: nomineesToSetNotAwarded,
                    status: "NOT AWARDED"
                }
            }
        } else {
            pathParam = `/awardNominees`;
            params = {
                ...systemHeaders(),
                body: {
                    awardKey,
                    nominees: nominees.map(nominee => {
                        return `NOMINEE#${nominee.nomineeId}#${nominee.shortPlan}`;
                    }),
                    awardResult: awarded
                }
            };

        }

        let resp = {};
        await API.post(process.env.REACT_APP_GRAD_AWARD_API_NAME, pathParam, params)
            .then( response => {
                if (response) {
                    nomineeCardFlag && dispatch(buildAction(UPDATE_NOMINEE_CARD_AWARDED, {
                        awarded: awarded === "REMOVE_SELECTED" ? '' : awarded,
                        nomineeId: nominees[0] && nominees[0].nomineeId
                    }));
                    setTimeout(() => {
                        dispatch(setAllNomineesStatus('success'));
                    }, 3000);

                } else if (response.status !== undefined && response.status === 401) {
                    console.error("401 Response in refreshDocumentList:", response.status);
                    redirectToLogIn();
                } else {
                    console.error("no Response data error refreshDocumentList:", response);
                    dispatch(receiveDocumentRefreshList(resp));
                }
            })
            .catch(error => {
                console.error("Error in refreshDocumentList:", error);
                if (error === 'No current user' || error === 'Request failed with status code 401' || (error.response !== undefined && error.response.status !== undefined && error.response.status === 401)) {
                    redirectToLogIn();
                } else {
                    dispatch(receiveDocumentRefreshList(resp));
                    dispatch(buildAction(UPDATE_NOMINEE_CARD_AWARDED, { status: 'error'}));
                }
            })
    }

}


export function refreshDocumentList(nomineeId, shortPlan, awardKey, applicationNumber) {
    //const setAllNomineesStatus = (status) => setApiStatus(AWARD_NOMINEES_STATUS, status)

    return (dispatch) => {
        //dispatch(setAllNomineesStatus('docLoading'));
        console.log("refreshing Document list");
        const params = {
            ...systemHeaders(),
            body: {
                awardKey: window.btoa(awardKey),
                nomineeId,
                shortPlan,
                applicationNumber
            }
        };

        let resp = {
            nomineeId: nomineeId
        };

        API.post(process.env.REACT_APP_AWARD_NOMINEE_REVIEW_API_NAME, `/refreshNominee`, params)
            .then(response => {
                if (response.data) {
                    resp.documentData = (response.data && response.data[0] && response.data[0].uploadedFile) ? response.data[0].uploadedFile : {};
                    dispatch(receiveDocumentRefreshList(resp));
                } else if (response.status !== undefined && response.status === 401) {
                    console.error("401 Response in refreshDocumentList:", response.status);
                    redirectToLogIn();
                } else {
                    console.error("no Response data error refreshDocumentList:", response);
                    dispatch(receiveDocumentRefreshList(resp));
                }
            })
            .catch(error => {
                console.error("Error in refreshDocumentList:", error);
                if (error === 'No current user' || error === 'Request failed with status code 401' || (error.response !== undefined && error.response.status !== undefined && error.response.status === 401)) {
                    redirectToLogIn();
                } else {
                    dispatch(receiveDocumentRefreshList(resp));
                }
            })
    }
}


export function calculateFinalRankedScores(awardKey) {
    const setAllNomineesStatus = (status) => setApiStatus(AWARD_NOMINEES_STATUS, status);
    return (dispatch) => {
        dispatch(setAllNomineesStatus('loading'));

        const params = {
            ...systemHeaders(),
            body: {
                awardKey: window.btoa(awardKey)
            }
        };

        API.post(process.env.REACT_APP_AWARD_NOMINEE_REVIEW_API_NAME, `/calculateFinalRankedScores`, params)
            .then(response => {
                dispatch(setAllNomineesStatus('success'));

                if (response.data) {
                    response.data = response.data.map(nomineeRecord => {
                        return nomineeRecord.data;
                    })
                    dispatch({ type: AWARD_NOMINEES, payload: response.data });
                } else if (response.status !== undefined && response.status === 401) {
                    dispatch(setAllNomineesStatus('error'));

                    console.error("401 Response in refreshDocumentList:", response.status);
                    redirectToLogIn();
                } else {
                    dispatch(setAllNomineesStatus('error'));

                    console.error("no Response data error refreshDocumentList:", response);
                }
            })
            .catch(error => {
                console.error("Error in refreshDocumentList:", error);
                if (error === 'No current user' || error === 'Request failed with status code 401' || (error.response !== undefined && error.response.status !== undefined && error.response.status === 401)) {
                    redirectToLogIn();
                } else {
                }
            })
            .finally(() => dispatch(updateAllNomineesSortForActiveReview("name")))

    }
}

export function updateAllNomineesSortForActiveReview(sortKey) {
    return {
        type: AWARD_NOMINEES_SORT_KEY,
        payload: sortKey
    }
}


export function updateNomineesDetails(values) {
    return {
        type: AWARD_NOMINEES_DETAILS,
        payload: values
    }
}


export function searchAwardNominees(query) {
    return {
        type: AWARD_NOMINEES_QUERY,
        payload: query
    }
}


export function addAwardNomineesFilter(filter) {
    return {
        type: AWARD_NOMINEES_FILTER,
        payload: filter
    }
}

export function receiveDocumentRefreshList(docList) {
    return {
        type: AWARD_DOCUMENT_REFRESH,
        payload: docList
    }
}

export function saveAwardInfo(award) {
    return {
        type: SAVE_AWARD_INFO,
        payload: award
    }
}


export function changePreviousAwardsCheckbox(flag) {
    return {
        type: SHOW_PREVIOUS_AWARDS,
        payload: flag
    }
}
export function changePreviousAwardsCheckboxForAwarded(flag) {
    return {
        type: SHOW_PREVIOUS_AWARDS_FOR_AWARDED,
        payload: flag
    }
}