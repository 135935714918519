import React from 'react';
import { Alert } from 'reactstrap';
import ScoringTabs from '../containers/ScoringTabs';
import { Heading5, Subtitle2 } from 'osu-react-components';
import Moment from 'moment';
import {PAGE_TITLE_POSTFIX} from "../../constants";
import {OSUButton} from "osu-react-components";

const DATE_FORMAT = "dddd, MMMM Do [at] h:mmA";

class CurrentStudent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pageTitle: `Current Student Competition Nominees - ` + this.props.location.pathname.split("/")[4],
            reviewAlertColor: "info",
            reviewAlertMessage: "",
            reviewAlertOpen: false,
            duplicateScoreAlertColor: "info",
            duplicateScoreAlertMessage: "",
            duplicateScoreAlertOpen: false
        };
    }

    componentWillMount() {
        document.title = this.state.pageTitle  + PAGE_TITLE_POSTFIX;
    }

    componentDidMount() {
        this.props.setA11yMessage(`Navigated to the ${this.state.pageTitle} page`);
        this.pageContent.focus();

        if(!this.props.reviewAlertDismissed) {
            const reviewCompletionDate = this.getSystemAwardDate("reviewCompletionDate");
            const reviewCompletionDateMinus3Days = reviewCompletionDate === null ? null : reviewCompletionDate.clone().subtract(3, "days");
            const reviewEndDate = this.getSystemAwardDate("reviewEndDate");
            const reviewCommitteeMeetingStartDate = this.getSystemAwardDate("reviewCommitteeMeetingStartDate");
            if(this.isDateBetween(reviewCompletionDateMinus3Days, reviewCompletionDate)) { // within 3 days of review completion date
                this.setState({
                    reviewAlertColor: "warning",
                    reviewAlertMessage: `The review completion date is approaching. Please be sure to have all your scores ` + 
                        `in by ${reviewCompletionDate.format(DATE_FORMAT)} to ensure composite reviewers have time to review.`,
                    reviewAlertOpen: true
                });
            } else if(this.isDateBetween(reviewCompletionDate, reviewEndDate)) { // between review completion and end dates
                this.setState({ 
                    reviewAlertColor: "danger",
                    reviewAlertMessage: ([
                        <Subtitle2 key="alertHeader">Composite Review Period</Subtitle2>,
                        <p key="alertBody">
                            All reviews must remain complete and in range for nominees scores to be available.
                            When making changes immediately update scores to keep all reviews complete and in range.<br />
                            Scores locked for editing on {reviewEndDate.format(DATE_FORMAT)}.
                        </p>
                    ]),
                    reviewAlertOpen: true
                });
            } else if(this.isDateBetween(reviewEndDate, reviewCommitteeMeetingStartDate)) { // between review end and committee meeting start dates
                this.setState({ 
                    reviewAlertColor: "info",
                    reviewAlertMessage: `Scores are now locked in preparation for committee meeting, ${reviewCommitteeMeetingStartDate.format(DATE_FORMAT)}.`,
                    reviewAlertOpen: true
                });
            }
        }
        if(!this.props.duplicateScoreAlertDismissed) {
                this.setState({ 
                    duplicateScoreAlertColor: "warning",
                    duplicateScoreAlertMessage: ([
                        <Subtitle2 key="alertHeader">Duplicate Review Scores</Subtitle2>,
                        <p key="alertBody">
                            You have two or more duplicate review scores between rank levels. This has resulted in the scores being randomly assigned at the ranked level. Please review the highlighted rows and update scores if desired.

                        </p>
                    ]),
                    // duplicateScoreAlertOpen: true
                });
        }
    }

    getSystemAwardDate = (dateName) => {
        let date = null;
        let dateValue = this.props.systemAward[dateName];
        if(dateValue) {
            date = Moment(dateValue);
            if(date.isValid() === false) date = null;
        }
        return date;
    };

    isDateBetween = (startDate, endDate) => {
        let isBetween = false;
        const date = Moment();
        if(startDate && endDate && date.isSameOrAfter(startDate) && date.isBefore(endDate)) isBetween = true;
        return isBetween;
    };

    dismissReviewAlert = () => {
        this.setState({ reviewAlertOpen: false });
        this.props.dismissReviewAlert();
    };

    dismissDuplicateScoreAlert = () => {
        this.setState({ duplicateScoreAlertOpen: false });
        // this.props.dismissDuplicateScoreAlert();
    };

    openDuplicateScoreAlert = () => {
        this.setState({ duplicateScoreAlertOpen: true });

    };
    render() {

        const { user, isAdmin, isReviewer, handleFilter, clearAllFilters, screenSize } = this.props;

        return (
            <div ref={(el) => { this.pageContent = el; }} tabIndex="-1" aria-labelledby="pageHeader">
                <OSUButton className="float-right" ariaLabel="Add Student" onClick={() => {return this.props.history.push(`${this.props.match.url}/add`);}} disabled={false} color="blue">Add Student</OSUButton>

                <Alert data-testid="reviewAlert" color={this.state.reviewAlertColor} isOpen={this.state.reviewAlertOpen} 
                    toggle={this.dismissReviewAlert}>
                    {this.state.reviewAlertMessage}
                </Alert>
                <Alert data-testid="duplicateScoreAlert" color={this.state.duplicateScoreAlertColor} isOpen={this.state.duplicateScoreAlertOpen} 
                    toggle={this.dismissDuplicateScoreAlert}>
                    {this.state.duplicateScoreAlertMessage}
                </Alert>
                <Heading5 id="pageHeader" className="mb-2">{this.state.pageTitle}</Heading5>
                <ScoringTabs parentContainer={this.props.parentContainer} handleFilter={handleFilter} clearAllFilters={clearAllFilters} user={user} isAdmin={isAdmin} isReviewer={isReviewer} screenSize={screenSize} dismissDuplicateScoreAlert={this.dismissDuplicateScoreAlert} openDuplicateScoreAlert={this.openDuplicateScoreAlert} />

            </div>
        );
    }
}

export default CurrentStudent