import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import Header from '../components';
import { getCurrentAwards, setSystemAwardValues } from '../../actions';

const mapStateToProps = (state, ownProps) => {
    const applicationRoles = state.user.applicationRoles ? state.user.applicationRoles.split(",").filter(role => !role.includes("NOMINATOR")) : []
    return {
        awards: state.currentAwards.list && applicationRoles ? filterAwards(state.currentAwards.list, applicationRoles) : [],
        systemAward: state.systemAward,
        user: state.user,
        userIsLoading: state.userIsLoading
    };
};


const filterAwards = (awards, allowedRoles) => {
    if(allowedRoles.includes("ADMIN")){
        return awards;
    } else {
        return awards.filter(award => {
            return allowedRoles.some(role => role === award.pk);
        });
    }

}


const mapDispatchToProps = (dispatch) => {
    return {
        getCurrentAwards: () => dispatch(getCurrentAwards()),
        setSystemAwardValues: (award) => dispatch(setSystemAwardValues(award))
    };
};


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));

