import React from "react";
import {
  Icon
} from "osu-react-components";
import {
  Label,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Button,
  Col,
  Row
} from "reactstrap";
import Select from "react-select";
import { isPlainObject } from "lodash";

import * as Globals from '../../../constants'
export const CLEAR_ALL = "";

class ComplexFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      shouldClearAllFields: false
    };
  }


  populateDropdowns(dropdownResults) {
    const labelValueDropdown = dropdownResults.map(result => {
      if(isPlainObject(result)){
        return {
          value: result.value,
          label: result.label
        }
      }
      return ({
        value: result,
        label: result.replace(/_/gi, " ")
      })
    });
    return labelValueDropdown;
  }


  setDropdownSelectedValues(filters) {
    const dropdownValues = [];
    filters.forEach(filter => {
      dropdownValues.push(filter.selection === "" ? null : { value: filter.selection, label: filter.selection });
    });
    return dropdownValues;
  }

  clearAllFieldsButtonClicked(filters, searchReviewers) {
    filters.forEach(filter => {
      filter.action(CLEAR_ALL)
    });
    searchReviewers(CLEAR_ALL);
  }

  componentDidMount() {
    this.setState({
      ...this.state,
      shouldClearFilters: true,
    });
  }

  assignColumnValue(column, dropdownValues) {
    if (column === "College") {
      return dropdownValues.collegeValue
    } else if (column === "D-Org") {
      return dropdownValues.dorgValue
    } else if (column === "Academic Program") {
      return dropdownValues.programOfStudyValue
    } else if (column === "Awards" || column === "Status") {
      return dropdownValues.awardsValue
    }
  }

  createSearchbox(query, searchReviewers) {
    if (window.innerWidth > Globals.TABLET_MAXIMUM_RESOLUTION) {
      return (<Col data-testid="searchBox" style={{paddingTop: 3, paddingLeft: 0, paddingRight: 3, maxWidth: "40%", minWidth: "40%" }}>              <InputGroup className="mr-5">
        <Label for="search-reviewers" hidden>Search Reviewers</Label>
        <InputGroupAddon addonType="prepend">
          <InputGroupText>
            <Icon type="search" color="gray" />
          </InputGroupText>
        </InputGroupAddon>
        <Input
          id="search-reviewers"
          type="text"
          value={query}
          placeholder={`Search by Employee ID, Name, or Comments`}
          onChange={e => searchReviewers(e.target.value)}
        />
      </InputGroup>
      </Col>);
    } else {
      return (<Col data-testid="searchBox" style={{paddingTop: 3, paddingLeft: 0, paddingRight: 3, maxWidth: "100%", minWidth: "100%" }}>              <InputGroup className="mr-5">
        <Label for="search-reviewers" hidden>Search Reviewers</Label>
        <InputGroupAddon addonType="prepend">
          <InputGroupText>
            <Icon type="search" color="gray" />
          </InputGroupText>
        </InputGroupAddon>
        <Input
          id="search-reviewers"
          type="text"
          value={query}
          placeholder={`Employee Search`}
          onChange={e => searchReviewers(e.target.value)}
        />
      </InputGroup>
      </Col>);
    }
  }

createFilterDropdown(filter, dropdownValues) {
  if (filter) {
    if (window.innerWidth > Globals.TABLET_MAXIMUM_RESOLUTION) {
      return (
        <Col data-testid="select1" style={{paddingTop: 3,  paddingLeft: 0, paddingRight: 3, maxWidth: "20%", minWidth: "20%" }}>
          <Select isClearable={true} aria-label={filter.name}
            onChange={e => filter.action(e)}
            value={this.assignColumnValue(filter.name, dropdownValues)}
            placeholder={filter.name}
            options={this.populateDropdowns(filter.results)}
            isMulti={filter.multiSelectable === "yes"}
          />
        </Col>);
    } else if(window.innerWidth > Globals.MOBILE_MAXIMUM_RESOLUTION) {
      return (<Col data-testid="select1" style={{paddingTop: 3,  paddingLeft: 0, paddingRight: 3, maxWidth: "33%", minWidth: "33%" }}>
      <Select isClearable={true} aria-label={filter.name}
        onChange={e => filter.action(e)}
        value={this.assignColumnValue(filter.name, dropdownValues)}
        placeholder={filter.name}
        options={this.populateDropdowns(filter.results)}
        isMulti={filter.multiSelectable === "yes"}
      />
    </Col>);
        } else {
      return (undefined)
    }
  } else {
    return undefined
  }

};

render() {


  const {
    query,
    searchReviewers,
    filterActions,
    filters
  } = this.props;

  const dropdownValues = this.setDropdownSelectedValues(filters);

  if (this.state.shouldClearFilters) {
    this.setState({
      ...this.state,
      shouldClearFilters: false,
    });
    this.clearAllFieldsButtonClicked(filters, searchReviewers);
  };


  const filter1 = filters ? filters[0] : null
  const filter2 = filters ? filters[1] : null
  const filter3 = filters ? filters[2] : null
  const filter4 = filters ? filters[3] : null
  const filter5 = filters ? filters[4] : null

  return (
    <div>
      <Row style={{ paddingLeft: 15, paddingRight: 15, paddingBottom: 0, paddingTop: 3 }}>
        <Col style={{ paddingLeft: 0, paddingRight: 0 }}>
          Filter Content &nbsp;&nbsp;&nbsp;|
            <Button color="link" type="submit" onClick={() => this.clearAllFieldsButtonClicked(
            filterActions.collegeFilterAction,
            filterActions.dorgFilterAction,
            filterActions.programOfStudyFilterAction,
            filterActions.awardsFilterAction,
            filterActions.activeUserFilterAction,
            searchReviewers)}>
            <div>Clear All</div>
          </Button>
        </Col>
      </Row>
      <Row style={{ paddingLeft: 15, paddingRight: 15, paddingBottom: 0, paddingTop: 3 }}>
        {this.createSearchbox(query, searchReviewers)}

        {this.createFilterDropdown(filter1, dropdownValues)}
        {this.createFilterDropdown(filter2, dropdownValues)}
        {this.createFilterDropdown(filter3, dropdownValues)}
        {this.createFilterDropdown(filter4, dropdownValues)}
        {this.createFilterDropdown(filter5, dropdownValues)}

      </Row>
    </div>
  );
}
    }

export default ComplexFilter;