import React, { Fragment, useContext } from "react";
import { FormEnvironmentContext } from "../context";
import { Typography } from "osu-react-components";
import AwardSelect from "./AwardSelect";
import GPARequired from "../containers/GPARequired";
import moment from "moment";
import { isEmpty } from "lodash";

function AwardEligibility(props = {}) {
  const { nominatedFor, saveChanges, awardName, divider, nominatedByEmplid, nominatedByNameDotN, nominatedByDate, programStatus } = props;
  const { minimumGpaRequired, studentStatus } = useContext(FormEnvironmentContext);

  return (
    <Fragment>
      {awardName && <Typography className="mb-2" variant="subtitle1">{awardName}</Typography>}
      <AwardSelect
        id="nominatedForSelect"
        label="Nominated for"
        value={nominatedFor}
        onChange={(value) => {
          saveChanges({
            nominatedFor: value
          })
        }
        }
        selectProps={{
          isDisabled: (!['WITHDRAWN', 'PENDING', 'NOMINATED'].includes(studentStatus))
      }}
      />
      {nominatedByEmplid &&
        <Typography variant="caption" color="gray">
          Nominated by {nominatedByNameDotN} ({nominatedByEmplid})
          {nominatedByDate ? ` on ${formatNominatorDate(nominatedByDate)}` : ""}
          {programStatus && ` with Admissions Status of "${programStatus}"`}
        </Typography>
      }

      {minimumGpaRequired && (
        <Fragment>
          <hr />
          <GPARequired />
        </Fragment>
      )}
           
      {divider && <hr />}
    </Fragment>
  );
}

const formatNominatorDate = (dateString = null) => {
  let formattedDate = "";
  if(!isEmpty(dateString)) {
      const date = moment(dateString);
      if(date.isValid()) formattedDate = date.format("MM/DD/YYYY hh:mm A");
  }
  return formattedDate;
}

AwardEligibility.defaultProps = {
  divider: true
}

export default AwardEligibility;
