import {
  GRADUATE_FACULTY_BY_EMPLID_DATA,
  GRADUATE_FACULTY_BY_EMPLID_ERROR,
  GRADUATE_FACULTY_BY_EMPLID_PROCESSING,
  GRADUATE_FACULTY_SEARCH_ERROR,
  GRADUATE_FACULTY_SEARCH_PROCESSING,
  GRADUATE_FACULTY_SEARCH_QUERY,
  GRADUATE_FACULTY_SEARCH_RESULTS,
  SAVE_REVIEWER_ERROR,
  SAVE_REVIEWER_INCOMPLETE,
  SAVE_REVIEWER_PROCESSING,
  SAVE_REVIEWER_SUCCESS,
  SEARCH_ALL_REVIEWERS,
  INVITE_REVIEWER_TO_AWARD_DATE,
  INVITE_REVIEWER_TO_AWARD_ERROR,
  INVITE_REVIEWER_TO_AWARD_PROCESSING,
  INVITE_REVIEWER_TO_AWARD_SUCCESS,
  COLLEGE_SELECTION,
  DORG_SELECTION,
  PROGRAM_OF_STUDY_SELECTION,
  AWARDS_SELECTION,
  CHECKBOX_STATUS,
  ALL_REVIEWERS,
  ALL_REVIEWERS_ERROR,
  CLEAR_ALL_FILTERS,
  ACTIVE_USER_SELECTION
} from "../actions-index";

export function allReviewersQuery(state = "", action) {
  switch (action.type) {
    case SEARCH_ALL_REVIEWERS:
      return action.payload;
    default:
      return state;
  }
}

export function checkStateOfCheckboxToShowInactive(state = false, action) {
  switch (action.type) {
    case CHECKBOX_STATUS:
      if (!state) {
        action.payload = true; //checked is true
      } else {
        action.payload = false;
      }
      return action.payload;
    default:
      return state;
  }
}

export function applyCollegeDropdownFilter(state = "", action) {
  switch (action.type) {
    case COLLEGE_SELECTION:
      return action.payload ? action.payload.value : "";
    case CLEAR_ALL_FILTERS:
        return state;
    default:
      return state;
  }
}

export function applyActiveUserDropdownFilter(state = "", action) {
  switch (action.type) {

    case ACTIVE_USER_SELECTION:
        return action.payload ? action.payload.value : "";

    default:
      return state;
  }
}


export function applyDorgDropdownFilter(state = "", action) {
  switch (action.type) {

    case DORG_SELECTION:
        return action.payload ? action.payload.value : "";

    default:
      return state;
  }
}

export function applyProgramOfStudyDropdownFilter(state = "", action) {
  switch (action.type) {

    case PROGRAM_OF_STUDY_SELECTION:
        return action.payload ? action.payload.value : "";

    default:
      return state;
  }
}

export function applyAwardsDropdownFilter(state = "", action) {
  switch (action.type) {

    case AWARDS_SELECTION:
        return action.payload ? action.payload.value : "";
    default:
      return state;
  }
}

export function graduateFacultySearchError(state = false, action = {}) {
  return action.type === GRADUATE_FACULTY_SEARCH_ERROR ? action.payload : state;
}

export function graduateFacultySearchProcessing(state = false, action = {}) {
  return action.type === GRADUATE_FACULTY_SEARCH_PROCESSING
    ? action.payload
    : state;
}

export function graduateFacultySearchQuery(state = "", action = {}) {
  return action.type === GRADUATE_FACULTY_SEARCH_QUERY ? action.payload : state;
}

export function graduateFacultySearchResults(state = [], action = {}) {
  return action.type === GRADUATE_FACULTY_SEARCH_RESULTS
    ? action.payload
    : state;
}

export function allReviewersResult(state = null, action = {}) {
  return action.type === ALL_REVIEWERS
    ? action.payload
    : state;
}

export function allReviewersError(state = false, action = {}) {
  return action.type === ALL_REVIEWERS_ERROR
    ? action.payload
    : state;
}

export function graduateFacultyByEmplidData(state = null, action = {}) {
  return action.type === GRADUATE_FACULTY_BY_EMPLID_DATA ? action.payload : state;
}

export function graduateFacultyByEmplidError(state = false, action = {}) {
  return action.type === GRADUATE_FACULTY_BY_EMPLID_ERROR ? action.payload : state;
}

export function graduateFacultyByEmplidProcessing(state = false, action = {}) {
  return action.type === GRADUATE_FACULTY_BY_EMPLID_PROCESSING ? action.payload : state;
}

export function saveReviewerError(state = false, action = {}) {
  return action.type === SAVE_REVIEWER_ERROR ? action.payload : state;
}

export function saveReviewerIncomplete(state = false, action = {}) {
  return action.type === SAVE_REVIEWER_INCOMPLETE ? action.payload : state;
}

export function saveReviewerProcessing(state = false, action = {}) {
  return action.type === SAVE_REVIEWER_PROCESSING ? action.payload : state;
}

export function saveReviewerSuccess(state = false, action = {}) {
  return action.type === SAVE_REVIEWER_SUCCESS ? action.payload : state;
}

export function inviteReviewerToAwardDate(state = null, action = {}) {
  return action.type === INVITE_REVIEWER_TO_AWARD_DATE ? action.payload : state;
}

export function inviteReviewerToAwardError(state = false, action = {}) {
  return action.type === INVITE_REVIEWER_TO_AWARD_ERROR ? action.payload : state;
}

export function inviteReviewerToAwardProcessing(state = false, action = {}) {
  return action.type === INVITE_REVIEWER_TO_AWARD_PROCESSING ? action.payload : state;
}

export function inviteReviewerToAwardSuccess(state = false, action = {}) {
  return action.type === INVITE_REVIEWER_TO_AWARD_SUCCESS ? action.payload : state;
}
