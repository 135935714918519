import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import CreateAward from "../components/CreateAward";
import { CREATE_AWARD_KEY, CREATE_AWARD_STATE, createAward, getPreviousAwards } from "../../actions-index";
import { buildAction } from "../../util/util";

const mapStateToProps = (state, props) => {
    const { currentAwards = {} } = state;
    const { key: createAwardKey = null, state: createAwardState = "" } = state.createAward;
    return {
        previousAwards: currentAwards.prevList || [],
        awardsStatus: currentAwards.status,
        createAwardKey,
        createAwardState,
        props
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        createAward: (award) => dispatch(createAward(award)),
        getPreviousAwards: () => dispatch(getPreviousAwards()),
        clearCreateAwardKey: () => dispatch(buildAction(CREATE_AWARD_KEY, null)),
        resetCreateAwardState: () => dispatch(buildAction(CREATE_AWARD_STATE, ""))
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CreateAward));
