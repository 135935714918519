import { AWARD_NOMINEES_QUERY, AWARD_NOMINEES_FILTER, AWARD_NOMINEES_AWARDED, TERMS, TERMS_STATE, ADD_STUDENT_SEARCH_DATA, ADD_STUDENT_SEARCH_DATA_STATE,
    SUBMIT_NOMINATIONS_RESULTS, SUBMIT_NOMINATIONS_RESULTS_STATE, AWARD_NOMINEES_STATUS, UPLOAD_NOMINEE_PACKET_ID,
    UPLOAD_NOMINEE_PETITION_ID, NOMINEE_CARD_PACKET, NOMINEE_CARD_RECORD, NOMINEE_CARD_FORM,
    NOMINEE_CARD_FORM_CLEAR, NOMINEE_CARD_FORM_SUBMISSION, DELETE_NOMINEE_DOCUMENT, UPDATE_NOMINEE_CARD_AWARDED,
    UPLOAD_NOMINEE_PACKET_STATE, NOMINEE_CARD_SAVE, UPLOAD_NOMINEE_PETITION_STATE, DELETE_NOMINEE_DOCUMENT_STATE, NOMINEE_CARD_INFO, REFRESH_NOMINEE_STATE,
    REFRESHED_NOMINEE
} from "../actions-index";
import { ACTION_STATE_SUCCESS } from "../util/constants";
import { isEmpty } from "lodash";
import { sortList, constructFilters, searchList, searchListByQuery } from '../redux-helpers';
import { removeDocumentFromRecord, replaceDocumentsInRecord } from "./Card/transform";
import { NOMINEE_FILE_NAMES } from "./constants";
import { mergeNomineeRecords } from "./transform";


export function terms(state = {}, action = {}) {
    const { payload, type } = action;
    switch(type) {
        case TERMS:
            return {
                data: payload,
                state: !isEmpty(payload) ? ACTION_STATE_SUCCESS : state.state
            };
        case TERMS_STATE:
            return { ...state, state: payload };
        default:
            return state;
    }
}

export function addStudentSearchData(state = {}, action = {}) {
    const { payload, type } = action;
    switch(type) {
        case ADD_STUDENT_SEARCH_DATA:
            return {
                data: payload,
                state: !isEmpty(payload) ? ACTION_STATE_SUCCESS : state.state
            };
        case ADD_STUDENT_SEARCH_DATA_STATE:
            return { ...state, state: payload };
        default:
            return state;
    }
}

export function submitNominationsResults(state = {}, action = {}) {
    const { payload, type } = action;
    switch(type) {
        case SUBMIT_NOMINATIONS_RESULTS:
            return {
                data: payload,
                state: !isEmpty(payload) ? ACTION_STATE_SUCCESS : state.state
            };
        case SUBMIT_NOMINATIONS_RESULTS_STATE:
            return { ...state, state: payload };
        default:
            return state;
    }
}


function filterAwardNominees(values) {
    const { list, filters, query, sortKey, searchableProperties } = values
    let nomineeList = Array.isArray(list) ? getNomineesMatchingConditions(list, filters) : []

    if (query) {
        nomineeList = searchListByQuery({ query, list: nomineeList, searchableProperties })
    }
    if (sortKey) {
        nomineeList = sortList(nomineeList, sortKey)
    }

    return nomineeList
}

export function awardedNominees(state = {}, action) {
    const searchableProperties = ['name', 'nomineeId', { key: 'reviewers', nestedProperty: 'email', formatter: value => { return value.replace("@osu.edu", ""); } }];

    switch (action.type) {


            case AWARD_NOMINEES_STATUS:
                return {
                    ...state,
                    status: action.payload
                };
            case AWARD_NOMINEES_AWARDED:
                return {
                    ...state,
                    list: action.payload,
                    _list: action.payload,
                    status: 'success'
                };
            case AWARD_NOMINEES_FILTER:
                    const allFilters = constructFilters(state.filters || [], action.payload)
                    const filteredList = filterAwardNominees({ list: state._list, filters: allFilters, query: state.query, searchableProperties, sortKey: state.sortKey })
                    return {
                        ...state,
                        filters: allFilters,
                        list: filteredList
                    }
            case AWARD_NOMINEES_QUERY:
                    const searchedList = searchList({ query: action.payload, list: state._list, searchableProperties, filters: state.filters, sortKey: state.sortKey, filteringAction: filterAwardNominees })
                    return {
                        ...state,
                        query: action.payload,
                        list: searchedList
                    }
        default:
            return state
    }
}

export function nomineeCard(state = {}, action = {}) {
    const { packet = {}, record = {} } = state

    switch (action.type) {
        case NOMINEE_CARD_INFO: {
            const recordSk = record && record.sk
            const recordPk = record && record.pk
            const { status, data } = action.payload;
            const { sk, pk } = (data || {})

            let newRecord = {}
            if((recordSk === sk) && (recordPk === pk)) {
                newRecord = Object.assign(record, (data || {}))
            } else {
                newRecord = (data || {})
            }

            return {
                ...state,
                status: status || state.status,
                record: newRecord
            }
        }


        case NOMINEE_CARD_RECORD: {
            let newRecord = mergeNomineeRecords(record, action.payload)

            return {
                ...state,
                record: newRecord
            }
        }

        case NOMINEE_CARD_PACKET: {
            return {
                ...state,
                packet: {
                    ...packet,
                    ...action.payload
                }
            }
        }
        case NOMINEE_CARD_FORM: {
            return {
                ...state,
                form: {
                    ...state.form,
                    ...action.payload
                }
            }
        }
        case NOMINEE_CARD_SAVE: {
            const { payload } = action
            let submission = state.submission || {}
            submission[action.type] = payload.status || 'success'
            if(payload.status === 'error' && payload.errorMessage) {
                submission.errorMessage = payload.errorMessage
            }

            return {
                ...state,
                submission
            }
        }
        case NOMINEE_CARD_FORM_CLEAR: {
            return {
                ...state,
                form: {},
                submission: {}
            }
        }
        case NOMINEE_CARD_FORM_SUBMISSION: {
            const { payload } = action
            let submission = state.submission || {}
            if(payload.stage) {
                submission[payload.stage] = payload.status
                submission.status = ''
                submission.stages = [...(submission.stages || []), payload.stage]
            } else {
                submission = {
                    ...submission,
                    ...action.payload
                }
            }

            return {
                ...state,
                submission: {
                    ...submission
                }
            }
        }
        case UPLOAD_NOMINEE_PETITION_ID:
        case UPLOAD_NOMINEE_PACKET_ID: {
            const { payload } = action
            let submission = state.submission || {}
            if(payload.documentId) {
                submission[action.type] = 'success'
            }

            return {
                ...state,
                submission,
                record: (!record || record.nomineeId !== payload.nomineeId)
                    ? record
                    : replaceDocumentsInRecord(
                        record,
                        payload.documentId,
                        action.type === UPLOAD_NOMINEE_PETITION_ID ? NOMINEE_FILE_NAMES.petition : NOMINEE_FILE_NAMES.packet
                    )
            }
        }
        case DELETE_NOMINEE_DOCUMENT_STATE:
        case UPLOAD_NOMINEE_PETITION_STATE:
        case UPLOAD_NOMINEE_PACKET_STATE: {
            const { payload } = action
            if(!payload.stage) {
                return state
            }
            let submission = state.submission || {}
            if(payload.status) {
                submission[payload.stage] = payload.status
            }

            return {
                ...state,
                submission
            }
        }
        case DELETE_NOMINEE_DOCUMENT: {
            const { payload } = action
            let submission = state.submission || {}
            if(payload.documentId) {
                submission[payload.documentId] = 'success'
            }

            return {
                ...state,
                submission,
                record: (!record || record.nomineeId !== payload.nomineeId)
                    ? record
                    : removeDocumentFromRecord(record, payload.documentId)
            }
        }
        case UPDATE_NOMINEE_CARD_AWARDED: {
            const { payload = {} } = action
            let submission = state.submission || {}
            submission[UPDATE_NOMINEE_CARD_AWARDED] = payload.status || 'complete'

            if(payload && record.nomineeId === payload.nomineeId && payload.hasOwnProperty('awarded')) {
                submission[UPDATE_NOMINEE_CARD_AWARDED] = 'success'
                return {
                    ...state,
                    record: {
                        ...record,
                        updatedNomineeAwarded: payload.awarded
                    },
                    submission
                }
            }
            return {
                ...state,
                submission
            }
        }
        default:
            return state
    }
}

function getNomineesMatchingConditions(list = [], filters = []) {
    function onlyUnique(value, index, self) {
        return self.indexOf(value) === index;
      }
    const uniqueFilters = filters.filter(onlyUnique);
    const newList = list.filter(item => {
        const matchingNomProperties = uniqueFilters.filter((filter, index) => {
            const { type, key } = filter
            if (type === "temporaryAwardedDisplayValue" && key) {
                
                const filterAwardAbbreviationList = key.split(",");
                const itemAwardAbbreviationList = item[type].split(",");
                let oneOrMoreKeyMatches = false;

                filterAwardAbbreviationList.forEach(filterAward => {
                    oneOrMoreKeyMatches = oneOrMoreKeyMatches || itemAwardAbbreviationList.includes(filterAward.trim());
                })
                return oneOrMoreKeyMatches;

            } else if (filter.type === 'rubric' && item.hasOwnProperty(filter.key)) {
                const score = item[`${filter.key}`];
                return score >= filter.min && score <= filter.max
            } else if (type === "academicProgram" && key && item.hasOwnProperty("shortPlan")) {
                return item["shortPlan"] === key;
            } else if (type === "acceptanceResult" && key) {
                const itemValue = item[type];
                if(key === "clear"){
                    return itemValue === "" || itemValue === "Clear Response" || itemValue === undefined;
                } else if(item.hasOwnProperty(type)) {
                    return itemValue.toLowerCase() === key.toLowerCase();
                }
            } else if (type === "college" && key) {
                return item["college"] === key;

            } else if (type === "pk" && key) {
                return item["pk"] === key;

            }
                return false
        })

        let uniqueTypes = filters.map(filter => filter.type).filter(onlyUnique);
        return matchingNomProperties.length === uniqueTypes.length;
    })
    return newList
}

export function refreshNomineeState(state = "", action = {}) {
    return (action.type === REFRESH_NOMINEE_STATE ? action.payload : state);
}

export function refreshedNominee(state = null, action = {}) {
    return (action.type === REFRESHED_NOMINEE ? action.payload : state);
}
