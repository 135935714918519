import React, { Fragment, useContext } from "react";
import { Typography } from "osu-react-components";
import { FormGroup, Input, Label } from "reactstrap";
import { capitalize } from "lodash";
import {
  GPA_ELIGIBILITY_WAIVER_LIMITED,
  GPA_ELIGIBILITY_WAIVER_NONE,
  GPA_ELIGIBILITY_WAIVER_PETITION,
} from "../../../AwardManagement/constants";
import { FormEnvironmentContext } from "../context";
import EligibilityPetition from "./EligibilityPetition";

function GPARequired(props = {}) {
  const { meetsGpaRequirement, gpaPetitionApproval, gpaEligibilityOverride, saveChanges, gpaWaiver } = props;
  const { gpaRequirement, gpaEligibilityWaiver, isAdmin, readonly } = useContext(FormEnvironmentContext)
  const setFormValues = (field, value) => {
    saveChanges({
      [field]: value
    })
  }

  let children = null;
  if (meetsGpaRequirement) {
    let str = `Meets Eligibility Requirements.`;
    if (
      [
        GPA_ELIGIBILITY_WAIVER_LIMITED,
        GPA_ELIGIBILITY_WAIVER_PETITION,
      ].includes(gpaEligibilityWaiver)
    ) {
      str += ` No ${capitalize(gpaEligibilityWaiver)} required`;
    }
    children = <span>{str}</span>;
  } else if (gpaEligibilityWaiver === GPA_ELIGIBILITY_WAIVER_LIMITED) {
    children = <EligibilityLimited 
      gpaWaiver={gpaWaiver}  
      setGpaWaiver={(checked) => setFormValues('gpaWaiver', checked ? 'Y' : 'N')}/>
  } else if (gpaEligibilityWaiver === GPA_ELIGIBILITY_WAIVER_PETITION) {
    children = <EligibilityPetition 
      gpaPetitionApproval={gpaPetitionApproval}  
      setGpaPetitionApproval={(value) => setFormValues('gpaPetitionApproval', value)}
      setPetitionFiles={(values) => setFormValues('gpaPetitionFiles', values)}
    />
  } else if (gpaEligibilityWaiver === GPA_ELIGIBILITY_WAIVER_NONE) {
    children = <span>Not Eligible</span>;
  }

  return (
    <Fragment>
      <div className="d-flex flex-fill mb-1">
        <Typography variant="subtitle2">
          GPA Eligibility Requirement: {gpaRequirement}
        </Typography>
      </div>
      {children}
      {(isAdmin || gpaEligibilityOverride) &&  <GPAOverride 
        disabled={!isAdmin || readonly}
        checked={gpaEligibilityOverride} 
        onChange={(checked) => setFormValues('gpaEligibilityOverride', checked ? 'TRUE' : 'FALSE')}/>}
    </Fragment>
  );
}

function EligibilityLimited(props = {}) {
  const { gpaWaiver, setGpaWaiver } = props

  return (
    <Fragment>
      <Typography>Waiver Required:</Typography>
      <FormGroup check>
        <Label for="use-waiver" check>
          <Input
            checked={gpaWaiver}
            value="gpaWaiver"
            onChange={(event = {}) => setGpaWaiver(event.target.checked)}
            type="checkbox"
            id="use-waiver"
          />
          Use Waiver
        </Label>
      </FormGroup>
    </Fragment>
  );
}

function GPAOverride(props = {}) {
  const { disabled, checked, onChange } = props
  
  return (
    <FormGroup className="mt-3 pr-2" check>
      <Label for="eligibility-override" check>
        <Input
          disabled={disabled}
          checked={!!checked}
          value="eligibility-override"
          onChange={(event) => onChange(event.target.checked)}
          type="checkbox"
          id="eligibility-override"
        />
        Grad School GPA Eligibility Override
      </Label>
    </FormGroup>
  );
}

export default GPARequired;
