import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import NominationsCard from "../components";
import { getNomineeCardInfo, updateBreadcrumbTrail, updateNominationCardForm, REFRESH_NOMINEE_STATE, REFRESHED_NOMINEE } from "../../../actions-index";
import { getAwardInfo } from "../../../Common/actions";
import { isEmpty } from "lodash";
import { formatBreadcrumbTrail, determineReadonly } from "../transform";
import { buildAction } from "../../../util/util"

const mapStateToProps = (state = {}, props = {}) => {
    const { nomineeCard = {}, awardInfo = {}, nominatorPrograms, refreshNomineeState } = state
    const { studentStatus, requireDocumentUpload, eligibility, nominationStartDate, nominationEndDate, awardEndDate, capsAndWaivers, nominationInfo } = awardInfo.data || {}
    const { isAdmin, isNominator, location, match = { params: {} } } = props
    const { nomineeId, shortPlan, awardKey } = (match.params || {})
    
    let redirectPath = null
    let breadcrumbTrail = formatBreadcrumbTrail(studentStatus, nomineeCard.record && nomineeCard.record.name, isAdmin ? 'ADMIN' : shortPlan) 

    if((!nomineeId || !shortPlan || !awardKey) || isEmpty(location.state)) {
        redirectPath = `/nominations`
        if(breadcrumbTrail.length === 1 && breadcrumbTrail[0].path) {
            redirectPath = breadcrumbTrail[0].path
        } else if(breadcrumbTrail.length === 3 && breadcrumbTrail[1].path) {
            redirectPath = breadcrumbTrail[1].path
        }
    }

    const authorized = isAdmin || (isNominator && Array.isArray(nominatorPrograms) && nominatorPrograms.includes(shortPlan))
    if(!authorized) {
        redirectPath = '/'
    }

    const { readonly = false, readonlyDescription = '' } = determineReadonly({ isAdmin, nominationStartDate, nominationEndDate, awardEndDate })
    let formConstants = {
        ...(eligibility || {}),
        requireDocumentUpload,
        nomineeId,
        awardKey: nomineeCard.record ? nomineeCard.record.awardKey : '',
        isAdmin,
        readonly,
        readonlyDescription,
        studentStatus: nomineeCard.record ? nomineeCard.record.status : '',
        shortPlan
    }

    let recordAwardKey = nomineeCard.record ? window.btoa(nomineeCard.record.awardKey) : ''
    let changeInParams = !nomineeCard.record || (
            (recordAwardKey !== awardKey) 
            || (nomineeId !== nomineeCard.record.nomineeId) 
            || (shortPlan !== nomineeCard.record.shortPlan))

    

    return {
        breadcrumbTrail,
        awardKey,
        redirectPath,
        formConstants,
        loading: (awardInfo && awardInfo.status === 'loading') || (nomineeCard && nomineeCard.status === 'loading'),
        capsAndWaivers,
        eligibility,
        nominationInfo,
        college: (nomineeCard.record ? nomineeCard.record.college : null),
        shortPlan: (nomineeCard.record ? nomineeCard.record.shortPlan : null),
        refreshNomineeCard: changeInParams && !!location.state,
        refreshNomineeState
    };
};

const mapDispatchToProps = (dispatch) => ({
    getNominee: (nomineeId, shortPlan, awardKey) => dispatch(getNomineeCardInfo(nomineeId, shortPlan, awardKey)),
    getAwardInfo: (awardKey) => dispatch(getAwardInfo(awardKey, true)),
    clearForm: () => dispatch(updateNominationCardForm()),
    resetNomineeRefresh: () => dispatch(() => {
        dispatch(buildAction(REFRESHED_NOMINEE, null));
        dispatch(buildAction(REFRESH_NOMINEE_STATE, ""));
    }),
    updateBreadcrumbTrail: (pathObj) => dispatch(updateBreadcrumbTrail(pathObj))
}); 

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NominationsCard));
