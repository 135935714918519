import React, { Component, Fragment } from "react";
import { Alert, Form, FormFeedback, FormGroup, Input, Label } from "reactstrap";
import Select from "react-select";
import PropTypes from "prop-types";
import { Body1, Caption, Icon, OSUButton } from "osu-react-components";
import { cloneDeep, find, forEach, forOwn, isEmpty, isEqual, keys, sum, values } from "lodash";
import { EVENT_RESET_STATE, MINIMUM_ELIGIBILITY_SCORE_MAX_LENGTH } from "../../constants";
import { FORM_FEEDBACK_SR_PREFIX } from "../../../util/constants";

class ReviewInformation extends Component {
    componentWillMount() {
        this.setComponentState();
        document.addEventListener(EVENT_RESET_STATE, this.setComponentState);
    }

    componentDidMount() {
        this.validate();
    }

    shouldComponentUpdate(nextProps) {
        return nextProps.isActive; // re-render only when active
    }

    componentWillUnmount() {
        document.removeEventListener(EVENT_RESET_STATE, this.setComponentState);
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevState.invalidCategoryWeights === false && this.state.invalidCategoryWeights === true) {
            this.categoryAlerts.focus();
        }
    }

    setComponentState = () => {
        const { award, readOnly } = this.props;
        if(!award.reviewInfo) award.reviewInfo = {};
        const { reviewInfo } = award;
        if(!reviewInfo.rubric) award.reviewInfo.rubric = {};
        const { rubric } = reviewInfo;
        if(!rubric.scores) award.reviewInfo.rubric.scores = [];
        const { scores } = rubric;
        const ranges = (scores.length > 0 ? scores[0].ranges : {});
        const rangeCount = keys(ranges).length;

        const numberOfReviewersSelectOptions = [
            this.buildSelectOption(1),
            this.buildSelectOption(2),
            this.buildSelectOption(3)
        ];
        let numberOfReviewersSelectedOption = find(numberOfReviewersSelectOptions, ["value", reviewInfo.noOfReviewers]);
        if(!numberOfReviewersSelectedOption) numberOfReviewersSelectedOption = find(numberOfReviewersSelectOptions, ["value", 3]); // default value
        
        const numberOfScoringRangesSelectOptions = [
            this.buildSelectOption(1, 1, ["1-99"]),
            this.buildSelectOption(3, 3, ["1-30", "30.01-60", "60.01-99"]),
            this.buildSelectOption(5, 5, ["1-20", "20.01-40", "40.01-60", "60.01-80", "80.01-99"])
        ];
        let numberOfScoringRangesSelectedOption = find(numberOfScoringRangesSelectOptions, ["value", rangeCount]);
        if(!numberOfScoringRangesSelectedOption) numberOfScoringRangesSelectedOption = find(numberOfScoringRangesSelectOptions, ["value", 5]); // default value

        const numberOfRubricCategoriesSelectOptions = [
            this.buildSelectOption(1),
            this.buildSelectOption(2),
            this.buildSelectOption(3),
            this.buildSelectOption(4),
            this.buildSelectOption(5)
        ]
        let numberOfRubricCategoriesSelectedOption = find(numberOfRubricCategoriesSelectOptions, ["value", scores.length]);
        if(!numberOfRubricCategoriesSelectedOption) numberOfRubricCategoriesSelectedOption = find(numberOfRubricCategoriesSelectOptions, ["value", 1]); // default value

        if(readOnly === false) {
            award.reviewInfo.rubric.scores = this.adjustRubricScoresToLength(scores, numberOfRubricCategoriesSelectedOption.value, numberOfScoringRangesSelectedOption.data);
        }
        const invalidCategories = [];
        for(let i = 0; i < numberOfRubricCategoriesSelectedOption.value; i++) {
            invalidCategories.push(false);
        }

        this.setState({
            award,
            invalidCategories,
            invalidCategoryWeights: false,
            numberOfReviewersSelectedOption,
            numberOfReviewersSelectOptions,
            numberOfRubricCategoriesSelectedOption,
            numberOfRubricCategoriesSelectOptions,
            numberOfScoringRangesSelectedOption,
            numberOfScoringRangesSelectOptions
        });
    }

    buildSelectOption = (value, label, data) => {
        const option = { label: label || value, value: value };
        if(data) option.data = data;
        return option;
    }

    adjustRubricScoresToLength = (scores, length, ranges) => {
        const adjustedScores = [...scores];
        const lengthDelta = length - scores.length;
        switch(Math.sign(lengthDelta)) {
            case -1:
                adjustedScores.length = length;
                break;
            case 1:
                for(let i = 0; i < lengthDelta; i++) {
                    adjustedScores.push(this.buildNewRubricScore(this.buildScoringRanges(ranges), (scores.length + i)));
                }
                break;
            case 0:
            default:
        }
        return adjustedScores;
    }

    buildScoringRanges = (ranges) => {
        const scoringRanges = {};
        forEach(ranges, range => {
            const rangeTokens = range.split("-");
            scoringRanges[range] = { min: Number(rangeTokens[0]), max: Number(rangeTokens[1]), description: "" };
        });
        return scoringRanges;
    }

    buildNewRubricScore = (scoringRanges, categoryIndex) => {
        return {
            name: "",
            field: `category-${categoryIndex}`,
            weight: 0,
            minimumEligibilityScore: 0,
            ranges: scoringRanges,
            evaluationGuideLines: [""]
        }
    }

    getInvalidCategoryWeights = (scores) => {
        const weights = scores.map(score => {
            return score.weight;
        });
        return (sum(weights) !== 100);
    }

    onNumberOfReviewersChange = (selectedOption) => {
        const award = cloneDeep(this.state.award);
        award.reviewInfo.noOfReviewers = selectedOption.value;
        this.setState({ award, numberOfReviewersSelectedOption: selectedOption }, () => this.validate());
    }

    onNumberOfScoringRangesChange = (selectedOption) => {
        const award = cloneDeep(this.state.award);
        const scoringRanges = this.buildScoringRanges(selectedOption.data);
        forEach(award.reviewInfo.rubric.scores, score => {
            score.ranges = scoringRanges;
        });
        this.setState({ award, numberOfScoringRangesSelectedOption: selectedOption }, () => this.validate());
    }

    onNumberOfRubricCategoriesChange = (selectedOption) => {
        const award = cloneDeep(this.state.award);
        award.reviewInfo.rubric.scores = this.adjustRubricScoresToLength(award.reviewInfo.rubric.scores, selectedOption.value, this.state.numberOfScoringRangesSelectedOption.data);
        const invalidCategories = [...this.state.invalidCategories];
        const lengthDelta = award.reviewInfo.rubric.scores.length - invalidCategories.length;
        switch(Math.sign(lengthDelta)) {
            case -1:
                invalidCategories.length = award.reviewInfo.rubric.scores.length;
                break;
            case 1:
                for(let i = 0; i < lengthDelta; i++) {
                    invalidCategories.push(false);
                }
                break;
            case 0:
            default:
        }
        const invalidCategoryWeights = this.getInvalidCategoryWeights(award.reviewInfo.rubric.scores);
        this.setState({ award, invalidCategories, invalidCategoryWeights, numberOfRubricCategoriesSelectedOption: selectedOption }, () => this.validate());
    }

    onCategoryValidate = (index, isValid, category) => {
        const invalidCategories = [...this.state.invalidCategories];
        invalidCategories[index] = !isValid;
        const state = {invalidCategories };
        if(isValid && !!category) {
            const award = cloneDeep(this.state.award);
            award.reviewInfo.rubric.scores[index] = category;
            state.award = award;
            state.invalidCategoryWeights = this.getInvalidCategoryWeights(award.reviewInfo.rubric.scores);
        }
        this.setState(state, () => this.validate());
    }

    validate = () => {
        const isValid = !!this.state.numberOfReviewersSelectedOption && 
            !!this.state.numberOfScoringRangesSelectedOption && 
            !!this.state.numberOfRubricCategoriesSelectedOption && 
            values(this.state.invalidCategories).every(invalid => (invalid === false)) &&
            this.state.invalidCategoryWeights === false &&
            this.validateAward();
        this.props.onValidate(isValid, isValid ? this.state.award : null);
    }

    validateAward = () => {
        const { award } = this.state;
        const scores = award.reviewInfo.rubric.scores;
        const validNumberOfReviewers = !!award.reviewInfo.noOfReviewers;
        let validScores = true;
        forEach(scores, score => {
            if(isEmpty(score.name) || !score.weight || isEmpty(score.ranges)) validScores = false;
            forOwn(score.ranges, range => {
                if(!range.min || !range.max) validScores = false;
            });
        });
        return validNumberOfReviewers && validScores;
    }

    setAriaRequired = (elementId) => {
        const element = document.getElementById(elementId);
        if(element) {
            const ariaRequired = element.getAttribute("aria-required");
            if(!ariaRequired || ariaRequired !== true) element.setAttribute("aria-required", true);
        }
    }

    setAriaDescribedby = (elementId, describedbyId) => {
        const element = document.getElementById(elementId);
        if(element) {
            const ariaDescribedby = element.getAttribute("aria-describedby");
            if(!ariaDescribedby) element.setAttribute("aria-describedby", describedbyId);
        }
    }

    render() {
        const { inReview, readOnly } = this.props;
        const numberSelectStyles = {
            container: provided => ({ ...provided, maxWidth: "9rem" })
        };
        const scores = this.state.award.reviewInfo.rubric.scores;
        if(!(this.props.inReview || this.props.readOnly)) { // hack to add aria attributes to react-select
            this.setAriaRequired("numberOfReviewersSelect");
            this.setAriaRequired("numberOfScoringRangesSelect");
            this.setAriaDescribedby("numberOfScoringRangesSelect", "numberOfScoringRangesWarning");
            this.setAriaRequired("numberOfRubricCategoriesSelect");
            this.setAriaDescribedby("numberOfRubricCategoriesSelect", "numberOfRubricCategoriesWarning");
        }
        return (
            <section>
                <h2 className="heading6" data-testid="reviewInformationHeader">Review Information</h2>
                <hr />
                <Form id="reviewInformationForm" data-testid="reviewInformationForm" noValidate>
                    <FormGroup data-testid="numberOfReviewersFormGroup">
                        <Label for="numberOfReviewers" className="d-inline-block">
                            <Body1 className={`mr-1${(inReview || readOnly) ? "" : " required"}`}>Number of Reviewers per Nominee:</Body1>
                        </Label>
                        {(inReview || readOnly) ?
                            (<Body1 id="numberOfReviewers" name="numberOfReviewers" dataTestId="numberOfReviewers" className="d-inline-block">
                                {this.state.numberOfReviewersSelectedOption.label}
                            </Body1>) :
                            (<Fragment>
                                <Select id="numberOfReviewers" inputId="numberOfReviewersSelect" name="numberOfReviewers" aria-label="Number of Reviewers per Nominee" 
                                    options={this.state.numberOfReviewersSelectOptions} value={this.state.numberOfReviewersSelectedOption} styles={numberSelectStyles} 
                                    onChange={selectedOption => this.onNumberOfReviewersChange(selectedOption)} />
                            </Fragment>)
                        }
                    </FormGroup>
                    <FormGroup tag="fieldset" data-testid="rubricDetailsFormGroup">
                        <legend>
                            <Body1 className="font-weight-bold">Rubric Details</Body1>
                            <hr />
                        </legend>
                        <FormGroup data-testid="numberOfScoringRangesFormGroup">
                            <Label for="numberOfScoringRanges" className="d-inline-block">
                                <Body1 className={`mr-1${(inReview || readOnly) ? "" : " required"}`}>Number of Scoring Ranges:</Body1>
                            </Label>
                            {(inReview || readOnly) ?
                                (<Body1 id="numberOfScoringRanges" name="numberOfScoringRanges" dataTestId="numberOfScoringRanges" className="d-inline-block">
                                    {this.state.numberOfScoringRangesSelectedOption.label}
                                </Body1>) :
                                (<Fragment>
                                    <Select id="numberOfScoringRanges" inputId="numberOfScoringRangesSelect" name="numberOfScoringRanges" aria-label="Number of Scoring Ranges"
                                        options={this.state.numberOfScoringRangesSelectOptions} value={this.state.numberOfScoringRangesSelectedOption} styles={numberSelectStyles} 
                                        onChange={selectedOption => this.onNumberOfScoringRangesChange(selectedOption)} />
                                    <Caption id="numberOfScoringRangesWarning">Warning: Changing the Number of Scoring Ranges will generate new ranges and clear Range Descriptions for each Category.</Caption>
                                </Fragment>)
                            }
                        </FormGroup>
                        <FormGroup data-testid="numberOfRubricCategoriesFormGroup">
                            <Label for="numberOfRubricCategories" className="d-inline-block">
                                <Body1 className={`mr-1${(inReview || readOnly) ? "" : " required"}`}>Number of Rubric Categories:</Body1>
                            </Label>
                            {(inReview || readOnly) ?
                                (<Body1 id="numberOfRubricCategories" name="numberOfRubricCategories" dataTestId="numberOfRubricCategories" className="d-inline-block">
                                    {this.state.numberOfRubricCategoriesSelectedOption.label}
                                </Body1>) :
                                (<Fragment>
                                    <Select id="numberOfRubricCategories" inputId="numberOfRubricCategoriesSelect" name="numberOfRubricCategories" aria-label="Number of Rubric Categories" 
                                        options={this.state.numberOfRubricCategoriesSelectOptions} value={this.state.numberOfRubricCategoriesSelectedOption} styles={numberSelectStyles} 
                                        onChange={selectedOption => this.onNumberOfRubricCategoriesChange(selectedOption)} />
                                    <Caption id="numberOfRubricCategoriesWarning">Warning: Changing the Number of Rubric Categories will add or remove Categories.</Caption>
                                </Fragment>)
                            }
                        </FormGroup>
                        <div data-testid="categoryAlerts" ref={(el) => { this.categoryAlerts = el; }} tabIndex="-1">
                            <Alert data-testid="invalidCategoryWeightsAlert" color="danger" isOpen={this.state.invalidCategoryWeights === true}><b>Error:</b> The total of all category weights must equal 100.</Alert>
                        </div>
                        {scores.length > 0 && scores.map((score, index) => {
                            return (<Category key={`category${index}`} index={index} data={score} onValidate={this.onCategoryValidate} readOnly={readOnly} inReview={inReview} />)
                        })}
                    </FormGroup>
                </Form>
            </section>
        );
    }
}

ReviewInformation.defaultProps = {
    isActive: false,
    inReview: false,
    readOnly: false
}

ReviewInformation.propTypes = {
    award: PropTypes.object.isRequired,
    isActive: PropTypes.bool,
    inReview: PropTypes.bool,
    onValidate: PropTypes.func.isRequired,
    readOnly: PropTypes.bool
}

export default ReviewInformation;

class Category extends Component {
    componentWillMount() {
        this.setComponentState();
    }

    componentDidMount() {
        this.validate();
    }

    componentDidUpdate(prevProps) {
        if(!isEqual(prevProps.data, this.props.data)) {
            const category = this.props.data;
            this.setState({ category, invalid: this.buildInvalid(category) }, () => this.validate());
            // need to manually set the weight input value because it uses a combination of defaultValue and onBlur instead of value and onChange
            document.getElementById(`category${this.state.number}Weight`).value = category.weight;
        }
    }

    setComponentState = () => {
        const category = this.props.data;
        const invalid = this.buildInvalid(category);
        this.setState({
            category,
            expanded: false,
            invalid,
            number: (this.props.index + 1)
        });
    }

    buildInvalid = (category) => {
        return {
            name: !this.validateRequired(category.name),
            weight: !this.validateWeight(category.weight),
            minimumEligibilityScore: !this.validateMinimumEligibilityScore(category.minimumEligibilityScore)
        }
    }

    toggleExpanded = (event) => {
        event.preventDefault();
        this.setState({ expanded: !this.state.expanded });
    }

    validateRequired = (value) => {
        return (value && value.length > 0);
    }

    validateNumber = (number) => {
        return !isNaN(number) && /^[0-9]+$/.test(number);
    }

    validateWeight = (weight) => {
        return this.validateNumber(weight) && weight > 0 && weight <= 100;
    }

    validateMinimumEligibilityScore = (minimumEligibilityScore) => {
        return this.validateNumber(minimumEligibilityScore) && minimumEligibilityScore >= 0 && minimumEligibilityScore <= 99;
    }

    onFormInputChange = (name, value, validator = this.validateRequired) => {
        const isValid = validator(value);
        const invalid = { ...this.state.invalid, [name]: !isValid };
        const category = cloneDeep(this.state.category);
        category[name] = value;
        this.setState({ category, invalid }, () => this.validate());
    }

    onMinimumEligibilityScoreChange = (event) => {
        const value = event.target.value;
        if(isNaN(value) || value.length > MINIMUM_ELIGIBILITY_SCORE_MAX_LENGTH) {
            event.target.value = value.slice(0, -1); // suppress the last value change
        } else {
            this.onFormInputChange("minimumEligibilityScore", Number(value), this.validateMinimumEligibilityScore);
        }
    }

    onRangeDescriptionChange = (range, description) => {
        const category = cloneDeep(this.state.category);
        category.ranges[range].description = description;
        this.setState({ category }, () => this.validate());
    }

    onEvaluationGuidelineChange = (index, evaluationGuideline) => {
        const category = cloneDeep(this.state.category);
        category.evaluationGuideLines[index] = evaluationGuideline;
        this.setState({ category }, () => this.validate());
    }

    addEvaluationGuideline = () => {
        const category = cloneDeep(this.state.category);
        category.evaluationGuideLines.push("");
        this.setState({ category }, () => this.validate());
    }

    removeEvaluationGuideline = (index) => {
        const category = cloneDeep(this.state.category);
        category.evaluationGuideLines.splice(index, 1);
        this.setState({ category }, () => this.validate());
    }

    validate = () => {
        const isValid = values(this.state.invalid).every(invalid => (invalid === false));
        this.props.onValidate(this.props.index, isValid, isValid ? this.state.category : null);
    }

    render() {
        const { inReview, readOnly } = this.props;
        const { category, expanded, invalid, number } = this.state;
        const isInvalid = values(this.state.invalid).includes(true);
        return (
            <FormGroup data-testid={`category${number}FormGroup`}>
                <Body1 id={`category${number}Label`} dataTestId={`category${number}Label`} className="award-category-label d-inline-block">
                    Category {number}: {category.name} {(invalid.weight === false) && <Fragment>(Weighted at {category.weight}%)</Fragment>}
                    {isInvalid && 
                        <span data-testid={`category${number}Invalid`} style={{ color: "red" }} className="ml-1" aria-live="polite">
                            <i role="img" aria-label="Error: " className="fas fab fa fa-exclamation-circle" />
                            <span className="sr-only">Category {number} is </span>Invalid
                        </span>
                    }
                </Body1>
                <button aria-label={(expanded ? "Hide" : "Show")  + ` Category ${number} Details`} 
                    aria-controls={`category${number}Details`} aria-expanded={expanded} 
                    onClick={this.toggleExpanded} className="btn d-inline-block float-right link typography typography-button">
                    <Icon type={`chevron-${expanded ? "down" : "up"}`} />
                </button>
                <hr />
                <section id={`category${number}Details`} data-testid={`category${number}Details`} aria-labelledby={`category${number}Label`}
                    className={`${expanded ? "" : "d-none"} mx-3`}>
                    <FormGroup data-testid={`category${number}NameFormGroup`} className="d-flex flex-wrap">
                        <Label for={`category${number}Name`} className="flex-column inline-center">
                            <Body1 className={`mr-1${readOnly ? "" : " required"}`}>Name:</Body1>
                        </Label>
                        {readOnly ?
                            (<Body1 id={`category${number}Name`} name={`category${number}Name`} dataTestId={`category${number}Name`} className="flex-column">
                                {category.name}
                            </Body1>) :
                            (<Fragment>
                                <Input id={`category${number}Name`} name={`category${number}Name`} data-testid={`category${number}Name`}
                                    type="text" value={category.name} invalid={invalid.name} aria-required="true" aria-invalid={invalid.name}
                                    maxLength="30" style={{ maxWidth: "30rem" }} className="flex-column" onChange={e => this.onFormInputChange("name", e.target.value)} />
                                <FormFeedback aria-live="polite">{FORM_FEEDBACK_SR_PREFIX}Name is required.</FormFeedback>
                            </Fragment>)
                        }
                    </FormGroup>
                    <FormGroup data-testid={`category${number}WeightFormGroup`} className="d-flex flex-wrap">
                        <Label for={`category${number}Weight`} className="flex-column inline-center">
                            <Body1 className={`mr-1${(inReview || readOnly) ? "" : " required"}`}>Weight:</Body1>
                        </Label>
                        {(inReview || readOnly) ?
                            (<Body1 id={`category${number}Weight`} name={`category${number}Weight`} dataTestId={`category${number}Weight`} className="flex-column">
                                {category.weight}
                            </Body1>) :
                            (<Fragment>
                                <Input id={`category${number}Weight`} name={`category${number}Weight`} data-testid={`category${number}Weight`}
                                    type="text" defaultValue={category.weight} invalid={invalid.weight} aria-required="true" aria-invalid={invalid.weight}
                                    aria-describedby={`category${number}WeightCaption`} style={{ maxWidth: "6rem" }} className="flex-column"
                                    onBlur={e => this.onFormInputChange("weight", Number(e.target.value), this.validateWeight)} />
                                <FormFeedback aria-live="polite">{FORM_FEEDBACK_SR_PREFIX}Weight must be a number between 1 and 100.</FormFeedback>
                                <div style={{ flexBasis: "100%" }}>
                                    <Caption id={`category${number}WeightCaption`}>The total of all category weights must equal 100.</Caption>
                                </div>
                            </Fragment>)
                        }
                    </FormGroup>
                    <FormGroup data-testid={`category${number}MinimumEligibilityScoreFormGroup`} className="d-flex flex-wrap">
                        <Label for={`category${number}MinimumEligibilityScore`} className="flex-column inline-center">
                            <Body1 className={`mr-1${readOnly ? "" : " required"}`}>Minimum Qualifying Score:</Body1>
                        </Label>
                        {readOnly ?
                            (<Body1 id={`category${number}MinimumEligibilityScore`} name={`category${number}MinimumEligibilityScore`} dataTestId={`category${number}MinimumEligibilityScore`} className="flex-column">
                                {category.minimumEligibilityScore}
                            </Body1>) :
                            (<Fragment>
                                <Input id={`category${number}MinimumEligibilityScore`} name={`category${number}MinimumEligibilityScore`} data-testid={`category${number}MinimumEligibilityScore`}
                                    type="text" value={category.minimumEligibilityScore} invalid={invalid.minimumEligibilityScore} style={{ maxWidth: "6rem" }} aria-required="true"
                                    aria-invalid={invalid.minimumEligibilityScore} className="flex-column" maxLength={MINIMUM_ELIGIBILITY_SCORE_MAX_LENGTH}
                                    onChange={this.onMinimumEligibilityScoreChange} />
                                <FormFeedback aria-live="polite">{FORM_FEEDBACK_SR_PREFIX}Minimum Qualifying Score must be a number between 0 and 99.</FormFeedback>
                            </Fragment>)
                        }
                    </FormGroup>
                    <FormGroup tag="fieldset" data-testid={`category${number}RangesFormGroup`}>
                        <legend><Body1>Range Descriptions:</Body1></legend>
                        {Object.keys(category.ranges).sort().map(key => {
                            const value = category.ranges[key];
                            return (
                                <FormGroup data-testid={`category${number}Range${key}FormGroup`} key={`category${number}Range${key}`} className="d-flex flex-wrap mx-2">
                                    <Label for={`category${number}Range${key}`}  data-testid={`category${number}Range${key}Label`} className="flex-column inline-center" style={{ minWidth: "5rem"}}>
                                        <Body1>{key}:&nbsp;</Body1>
                                    </Label>
                                    {readOnly ?
                                        (<Body1 id={`category${number}Range${key}`} name={`category${number}Range${key}`} dataTestId={`category${number}Range${key}`} className="flex-column">
                                            {value.description}
                                        </Body1>) :
                                        (<Fragment>
                                            <Input id={`category${number}Range${key}`} name={`category${number}Range${key}`} data-testid={`category${number}Range${key}`}
                                                type="textarea" maxLength="150" rows="2" value={value.description} className="flex-column"
                                                style={{ maxWidth: "40rem" }} onChange={e => this.onRangeDescriptionChange(key, e.target.value)} />
                                        </Fragment>)
                                    }
                                </FormGroup>
                            );
                        })}
                    </FormGroup>
                    <FormGroup tag="fieldset" data-testid={`category${number}EvaluationGuidelinesFormGroup`}>
                        <legend><Body1>Evaluation Criteria:</Body1></legend>
                        {category.evaluationGuideLines.map((evaluationGuideline, index) => {
                            const guidelineNumber = (index + 1);
                            return (
                                <FormGroup data-testid={`category${number}EvaluationGuideline${guidelineNumber}FormGroup`} key={`category${number}EvaluationGuideline${guidelineNumber}`} className="d-flex">
                                    <Label for={`category${number}EvaluationGuideline${guidelineNumber}`}  data-testid={`category${number}EvaluationGuideline${guidelineNumber}Label`} className="flex-column inline-center sr-only">
                                        <Body1>Criteria {guidelineNumber}:&nbsp;</Body1>
                                    </Label>
                                    {readOnly ?
                                        (<Body1 id={`category${number}EvaluationGuideline${guidelineNumber}`} name={`category${number}EvaluationGuideline${guidelineNumber}`} dataTestId={`category${number}EvaluationGuideline${guidelineNumber}`} className="flex-column">
                                            {evaluationGuideline}
                                        </Body1>) :
                                        (<Fragment>
                                            <Input id={`category${number}EvaluationGuideline${guidelineNumber}`} name={`category${number}EvaluationGuideline${guidelineNumber}`} data-testid={`category${number}EvaluationGuideline${guidelineNumber}`}
                                                type="textarea" maxLength="500" rows="3" value={evaluationGuideline} className="flex-column"
                                                style={{ maxWidth: "45rem" }} onChange={e => this.onEvaluationGuidelineChange(index, e.target.value)} />
                                            {category.evaluationGuideLines.length !== 1 &&
                                                <OSUButton link type="button" ariaLabel={`Remove Evaluation Criteria ${guidelineNumber}`} className="flex-column inline-center" onClick={() => this.removeEvaluationGuideline(index)}>
                                                    <Icon type="times" color="red" size="sm" />
                                                </OSUButton>
                                            }
                                        </Fragment>)
                                    }
                                </FormGroup>
                            );
                        })}
                        {!readOnly && category.evaluationGuideLines.length < 10 &&
                            <OSUButton link type="button" ariaLabel="Add Evaluation Criteria" onClick={() => this.addEvaluationGuideline()}>
                                <Icon type="plus" color="blue" size="sm" /> Add Criteria
                            </OSUButton>
                        }
                    </FormGroup>
                </section>
            </FormGroup>
        );
    }
}

Category.defaultProps = {
    inReview: false,
    readOnly: false
}

Category.propTypes = {
    data: PropTypes.shape({
        name: PropTypes.string.isRequired,
        field: PropTypes.string.isRequired,
        weight: PropTypes.number.isRequired,
        minimumEligibilityScore: PropTypes.number.isRequired,
        ranges: PropTypes.objectOf(PropTypes.shape({
            min: PropTypes.number.isRequired,
            max: PropTypes.number.isRequired,
            description: PropTypes.string.isRequired
        })).isRequired,
        evaluationGuideLines: PropTypes.arrayOf(PropTypes.string).isRequired
    }).isRequired,
    index: PropTypes.number.isRequired,
    inReview: PropTypes.bool,
    onValidate: PropTypes.func.isRequired,
    readOnly: PropTypes.bool
}