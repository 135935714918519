import React from 'react'
import { Body2, OSUError, OSUButton, Icon, OSULoading } from 'osu-react-components'
import PropTypes from 'prop-types'
import ReviewSheetTable from '../../ReviewSheetTable/components'
import { Row, Input, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap'
import IconLegend from "../../components/IconLegend";
import { redirectToLogOut} from "../../../util/util";
import {Auth} from "aws-amplify";

class SecondaryReviewContent extends React.Component {
    constructor(props) {
        super(props);
        this.finalWeightedScoreSelectRef = React.createRef();
    }
    
    componentDidMount() {
        this.fetchSecondaryReviewList(this.props.awardKey)
    }

    componentDidUpdate() {
        // set aria-label on Final Weighted Score filter dropdown (cannot set directly when isSearchable=false)
        if(this.finalWeightedScoreSelectRef.current) {
            var finalWeightedScoreSelectInputRef = this.finalWeightedScoreSelectRef.current.select.inputRef;
            if(!finalWeightedScoreSelectInputRef.getAttribute("aria-label")) {
                finalWeightedScoreSelectInputRef.setAttribute("aria-label", "Filter table by Final Weighted Score");
            }
        }
    }

    fetchSecondaryReviewList(awardKey) {
        const key = this.props.sortKey && this.props.sortKey.key ? this.props.sortKey.key : 'random'
        const direction = this.props.sortKey && this.props.sortKey.direction ? this.props.sortKey.direction : 'asc'

        this.props.fetchSecondaryReviewList(awardKey, { key, direction })
    }

    unsuccessfulScreen(status) {
        const errorScreen = !this.props.awardKey ?
            <OSUError ariaLabel="Error occured. Navigate to award selection." text="Could not verify award." actionText="Go to Award Selection" path={'/nominee-reviews/award-selection'} />
            : <OSUError ariaLabel="Error occured. Reload secondary reviewers for this award." text="Could not fetch secondary reviewers for award." actionText="Retry" onClick={() => this.fetchSecondaryReviewList(this.props.awardKey)} />

        if (status === 'loading') {
            return <OSULoading text="Loading nominees for award" />
        }else if(status==='401'){
            Auth.signOut() && redirectToLogOut();
            //return <OSUError ariaLabel="You are logged out. Close your browser and login again." text="You are logged out. Close your browser and login again." actionText="Login" onClick={() => redirectToLogIn()} />
        }
        return errorScreen
    }

    render() {
        const { list, filters, status, scoringCriteria, noOfReviewers, updateSortKey, sortKey, updateSecondaryReviewDetails, details, query, searchSecondaryReviewList, addSecondaryReviewFilter } = this.props
        if (status !== 'success') {
            return this.unsuccessfulScreen(status)
        }
        // const handleFilterProps = {
        //     activeFilters: filters,
        //     filterHandler: addSecondaryReviewFilter
        // };
        return <div>
            <IconLegend icons={[
                { type: "check", ariaLabel: "Reviews are complete and in range.", color: "green", size: "xs" },
                // { type: "exclamation-triangle", ariaLabel: "Scores out of assigned ranges.", color: "red", size: "xs" },
                // { type: "info", ariaLabel: "Category minimum qualifying score not met.", color: "gray", size: "xs" },
                { type: "commenting-o", ariaLabel: "Comments available to view.", color: "gray", size: "xs" },
                { type: "asterisk", ariaLabel: "Reviewed by admin.", color: "black", size: "xs" }

            ]} />
            <Body2 className="mb-1 d-flex align-items-center">Filter Content | <OSUButton uppercase={false} variant="body2" ariaLabel="Clear all filters" link onClick={() => this.props.clearAllFilters(filters, addSecondaryReviewFilter, searchSecondaryReviewList)}>Clear All</OSUButton></Body2>
            <Row className="no-gutters align-items-center">
                <InputGroup className="col-4 pl-0 pr-2">
                    <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                            <Icon type="search" color="gray" />
                        </InputGroupText>
                    </InputGroupAddon>
                    <Input type="text" aria-label="Search by Nominee ID or Name" placeholder={`Search by Nominee ID or Name`} value={query} onChange={(e) => searchSecondaryReviewList(e.target.value)} />
                </InputGroup>
                <div className="col-4 px-0 d-flex">
                    {/* <Select placeholder="Final Weighted Score" ref={this.finalWeightedScoreSelectRef} isClearable value={defaultScoreValue} isSearchable={false} className="w-100 pr-2" onChange={range => handleFilter({ ...handleFilterProps, filter: range, type: "rubric" })} options={weightedScoreRanges} /> */}
                </div>
            </Row>
            <ReviewSheetTable tabIdentifier={this.props.tabIdentifier} noOfReviewers={noOfReviewers} sortKey={sortKey} updateSortKey={updateSortKey} list={list} details={details} updateDetails={updateSecondaryReviewDetails} scoringCriteria={scoringCriteria} />
        </div>
    }
}


SecondaryReviewContent.defaultProps = {

};

SecondaryReviewContent.propTypes = {
    tabIdentifier: PropTypes.shape({
        index: PropTypes.number,
        type: PropTypes.string
    }),
    primaryReviewList: PropTypes.array
};

export default SecondaryReviewContent