import React, {Component} from 'react';
import  { Auth } from 'aws-amplify';
import { redirectToLogIn } from '../../util/util';
import { OSULoading, OSUError } from 'osu-react-components'

class SignIn extends Component {

    state = {
        isSigningIn: true,
        signInFailed: false,
    };

    handleLoginClick = () => {
        redirectToLogIn();
    };

    componentDidMount(){
        Auth.currentSession()
        .then((session) => {
            this.props.setUser(session.idToken.payload);
            this.setState({
                isSigningIn: false
            })
        })
        .catch((error) => {
            console.log("Error in SignIn:",error);
            setTimeout(() => {
                Auth.currentSession()
                .then((session) => {
                    console.log("Error in Signin set timeout:",error);
                    this.props.setUser(session.idToken.payload);
                    this.setState({
                        isSigningIn: false
                    })

                })
                        .catch((error) => {
                            console.log("Error in Signin set timeout error error:",error);
                            this.setState({
                                isSigningIn: false,
                                signInFailed: true
                            })
                        });
            }, 4500);// wait 4.5s for call to expire before failure
            
        });
    }

    render(){
        if(this.state.isSigningIn){
            return <OSULoading text="Signing in"/>
        } else if(this.state.signInFailed) {
            return <OSUError ariaLabel="Error signing in." text="Error signing in." onClick={this.handleLoginClick} />
        }
        return null
    }
}

export default SignIn;