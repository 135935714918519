import {
    ASSIGN_PACKETS_TO_REVIEWERS_ERROR,
    ASSIGN_PACKETS_TO_REVIEWERS_ERROR_MESSAGE,
    ASSIGN_PACKETS_TO_REVIEWERS_PROCESSING,
    ASSIGN_PACKETS_TO_REVIEWERS_PROCESSING_MESSAGE,
    ASSIGN_PACKETS_TO_REVIEWERS_SUCCESS,
    AWARD_REVIEWERS,
    AWARD_REVIEWERS_ERROR,
    AWARD_REVIEWERS_LOADING,
    AWARD_REVIEWER_NOMINEES, 
    AWARD_REVIEWER_NOMINEES_ERROR, 
    AWARD_REVIEWER_NOMINEES_LOADING,
    COLLEGE_SELECTION,
    DORG_SELECTION,
    PROGRAM_OF_STUDY_SELECTION,
    AWARDS_SELECTION,
    CLEAR_ALL_FILTERS,
    SEARCH_ALL_REVIEWERS,
    COMMITTEE_REVIEWERS_STATUS_CHANGE,
    DISTRIBUTE_REVIEWER_PACKETS_ERROR,
    DISTRIBUTE_REVIEWER_PACKETS_PROCESSING,
    DISTRIBUTE_REVIEWER_PACKETS_SUCCESS,
    TRANSFER_REVIEWER_PACKETS_ERROR,
    TRANSFER_REVIEWER_PACKETS_PROCESSING,
    TRANSFER_REVIEWER_PACKETS_SUCCESS,
    TRANSFER_REVIEWER_NOMINEE_PACKETS_ERROR,
    TRANSFER_REVIEWER_NOMINEE_PACKETS_PROCESSING,
    TRANSFER_REVIEWER_NOMINEE_PACKETS_SUCCESS
} from "../../actions-index";

export function awardReviewers(state = [], action = {}) {
    return action.type === AWARD_REVIEWERS ? action.payload : state;
}

export function awardReviewersError(state = false, action = {}) {
    return action.type === AWARD_REVIEWERS_ERROR ? action.payload : state;
}

export function awardReviewersLoading(state = false, action = {}) {
    return action.type === AWARD_REVIEWERS_LOADING ? action.payload : state;
}

export function awardReviewerNominees(state = [], action = {}) {
    return action.type === AWARD_REVIEWER_NOMINEES ? action.payload : state;
}

export function awardReviewerNomineesError(state = false, action = {}) {
    return action.type === AWARD_REVIEWER_NOMINEES_ERROR ? action.payload : state;
}

export function awardReviewerNomineesLoading(state = false, action = {}) {
    return action.type === AWARD_REVIEWER_NOMINEES_LOADING ? action.payload : state;
}

// export function applyDropdownFilter(state = [], action) {

//   switch (action.type) {
//     case COLLEGE_SELECTION:
//       return {
//         ...state,
//         list: action.payload,
//         _list: action.payload,
//         filterOnCollegeDropdown: action.payload ? action.payload.value : ""
//     }
//     case DORG_SELECTION:
//         return {
//           ...state,
//           list: action.payload,
//           _list: action.payload,
//           filterOnDorgDropdown: action.payload ? action.payload.value : ""
//       }
//     case PROGRAM_OF_STUDY_SELECTION:
//         return {
//           ...state,
//           list: action.payload,
//           _list: action.payload,
//           filterOnProgramOfStudyDropdown: action.payload ? action.payload.value : ""
//       }
//     case AWARDS_SELECTION:
//         return {
//           ...state,
//           list: action.payload,
//           _list: action.payload,
//           filterOnAwardsDropdown: action.payload ? action.payload.value : ""
//       }
//     case CLEAR_ALL_FILTERS:
//       return state;
//     default:
//       return state;
//   }
// }

export function applyCollegeDropdownFilter(state = "", action) {
    switch (action.type) {
      case COLLEGE_SELECTION:
        return action.payload ? action.payload.value : "";
      case CLEAR_ALL_FILTERS:
      default:
        return state;
    }
  }
  
  export function applyDorgDropdownFilter(state = "", action) {
    switch (action.type) {
  
      case DORG_SELECTION:
          return action.payload ? action.payload.value : "";
  
      default:
        return state;
    }
  }
  
  export function applyProgramOfStudyDropdownFilter(state = "", action) {
    switch (action.type) {
  
      case PROGRAM_OF_STUDY_SELECTION:
          return action.payload ? action.payload.value : "";
  
      default:
        return state;
    }
  }
  
  export function applyAwardsDropdownFilter(state = "", action) {
    switch (action.type) {
  
      case AWARDS_SELECTION:
        return action.payload
          
      default:
        return state;
    }
  }

  export function allReviewersQuery(state = "", action) {
    switch (action.type) {
      case SEARCH_ALL_REVIEWERS:
        return action.payload;
      default:
        return state;
    }
  }

export function assignPacketsToReviewersError(state = false, action = {}) {
    return action.type === ASSIGN_PACKETS_TO_REVIEWERS_ERROR ? action.payload : state;
}

export function assignPacketsToReviewersErrorMessage(state = null, action = {}) {
  return action.type === ASSIGN_PACKETS_TO_REVIEWERS_ERROR_MESSAGE ? action.payload : state;
}

export function assignPacketsToReviewersProcessing(state = false, action = {}) {
    return action.type === ASSIGN_PACKETS_TO_REVIEWERS_PROCESSING ? action.payload : state;
}
export function assignPacketsToReviewersProcessingMessage(state = false, action = {}) {
    return action.type === ASSIGN_PACKETS_TO_REVIEWERS_PROCESSING_MESSAGE ? action.payload : state;
}

export function assignPacketsToReviewersSuccess(state = false, action = {}) {
    return action.type === ASSIGN_PACKETS_TO_REVIEWERS_SUCCESS ? action.payload : state;
}

export function handleMultiStatusChange(state = [], action){
    return action.type === COMMITTEE_REVIEWERS_STATUS_CHANGE ? action.payload : state;
}
export function distributeReviewerPacketsError(state = false, action = {}) {
    return action.type === DISTRIBUTE_REVIEWER_PACKETS_ERROR ? action.payload : state;
}

export function distributeReviewerPacketsProcessing(state = false, action = {}) {
    return action.type === DISTRIBUTE_REVIEWER_PACKETS_PROCESSING ? action.payload : state;
}

export function distributeReviewerPacketsSuccess(state = false, action = {}) {
    return action.type === DISTRIBUTE_REVIEWER_PACKETS_SUCCESS ? action.payload : state;
}

export function transferReviewerPacketsError(state = false, action = {}) {
    return action.type === TRANSFER_REVIEWER_PACKETS_ERROR ? action.payload : state;
}

export function transferReviewerPacketsProcessing(state = false, action = {}) {
    return action.type === TRANSFER_REVIEWER_PACKETS_PROCESSING ? action.payload : state;
}

export function transferReviewerPacketsSuccess(state = false, action = {}) {
    return action.type === TRANSFER_REVIEWER_PACKETS_SUCCESS ? action.payload : state;
}

export function transferReviewerNomineePacketsError(state = false, action = {}) {
    return action.type === TRANSFER_REVIEWER_NOMINEE_PACKETS_ERROR ? action.payload : state;
}

export function transferReviewerNomineePacketsProcessing(state = false, action = {}) {
    return action.type === TRANSFER_REVIEWER_NOMINEE_PACKETS_PROCESSING ? action.payload : state;
}

export function transferReviewerNomineePacketsSuccess(state = false, action = {}) {
    return action.type === TRANSFER_REVIEWER_NOMINEE_PACKETS_SUCCESS ? action.payload : state;
}
