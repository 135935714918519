import React, { Component } from "react";
import { Prompt } from "react-router-dom";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Body2, OSUButton } from "osu-react-components";
import PropTypes from "prop-types";

class NavigationPrompt extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modalOpen: false,
            navigationConfirmed: false,
            navigationLocation: null
        };
    }

    closeModal = (callback) => {
        const state = { modalOpen: false };
        !callback ? this.setState(state) : this.setState(state, callback);
    }

    onNavigation = (navigationLocation) => {
        if(this.props.prompt && !this.state.navigationConfirmed) {
            this.showModal(navigationLocation);
            return false;
        } else {
            return true;
        }
    }

    onNavigationConfirmation = () => this.closeModal(() => {
        if(this.state.navigationLocation) {
            document.querySelector('body').classList.remove('modal-open'); //known bug in react strap https://github.com/reactstrap/reactstrap/issues/1323 could be removed if reactstrap is updated to 8.5.x
            this.setState(
                { navigationConfirmed: true },
                () => this.props.history.push(this.state.navigationLocation)
            );
        }
    });

    showModal = (navigationLocation) => {
        this.setState({ modalOpen: true, navigationLocation });
    }

    render() {
        return (
            <div>
                <Prompt when={this.props.prompt} message={this.onNavigation} />
                <Modal data-testid={this.props.dataTestId} isOpen={this.state.modalOpen}>
                    <ModalHeader>{this.props.header}</ModalHeader>
                    <ModalBody><Body2>{this.props.message}</Body2></ModalBody>
                    <ModalFooter>
                        <OSUButton ariaLabel={this.props.cancelButtonLabel} color="gray" solid uppercase={false} className="mr-1" onClick={() => this.closeModal()}>{this.props.cancelButtonLabel}</OSUButton>
                        <OSUButton ariaLabel={this.props.confirmButtonLabel} color="blue" solid uppercase={false} onClick={this.onNavigationConfirmation}>{this.props.confirmButtonLabel}</OSUButton>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }
}

NavigationPrompt.defaultProps = {
    cancelButtonLabel: "Cancel",
    confirmButtonLabel: "Confirm",
    dataTestId: "navigationPrompt"
}

NavigationPrompt.propTypes = {
    cancelButtonLabel: PropTypes.string,
    confirmButtonLabel: PropTypes.string,
    dataTestId: PropTypes.string,
    header: PropTypes.string.isRequired,
    history: PropTypes.object.isRequired,
    message: PropTypes.string.isRequired,
    prompt: PropTypes.bool.isRequired
}

export default NavigationPrompt;