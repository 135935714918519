import React from 'react';
import { Table, Body2, Caption, Icon, OSUButton, OSULoading, PaginationWrapper } from 'osu-react-components';
import PropTypes from 'prop-types';
import { chunk } from 'lodash';
import { Row, Input, InputGroup, InputGroupAddon, InputGroupText, Alert } from 'reactstrap';
import Select from 'react-select';
import * as Globals from '../../constants';
import PDFLink from '../../Common/PDFLink';
import moment from "moment";
import { CSVLink } from 'react-csv';

class Awarded extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            nominees: [],
            nomineesDetails: [],
            gpaTypes: [],
            dataIndex: 0,
            reviewAlertColor: "info",
            rowsPerPage: 10,
            listOfSelectedNominees: [],
            reviewAlertOpen: false,
            reviewAlertMessage: "Award letters for the selecting nominee(s) are being sent. Please check back or refresh your screen to see updated status."
        };

    }

    setDataIndex = dataIndex => {
        this.setState({ dataIndex });
    };

    componentDidMount() {
        if(!(this.props.currentAwards && this.props.currentAwards.prevList))
        this.props.getPreviousAwards();
        if(this.props.academicProgramContext === "ADMIN")
        this.props.getCurrentAwards();
        this.getAllNominees();
    }


    getAllNominees() {
        const key = 'name';
        const direction = 'asc';
        this.props.getAllCurrentAwardedNomineesForAllAwards({ key, direction }, this.props.academicProgramContext);
    }
    dataKeys = () => {

        const keys = Globals.MOBILE_MAXIMUM_RESOLUTION < window.innerWidth ? [
            {
                key: 'selected',
                label: 'Selected',

                className: 'align-self-top min-width-0',
                width: 12.5
            },
            {
                key: 'name',
                label: 'Name',

                className: 'align-self-top min-width-0',
                width: 27.5
            },
            {
                key: 'data',
                label: 'Employee ID',

                className: 'align-self-top min-width-0',
                width: 15
            },
            {
                key: 'temporaryAwardedDisplayValue',
                label: 'Awarded',

                className: 'align-self-top min-width-0',
                width: 15
            },
            {
                key: 'letter',
                label: 'Letter',

                className: 'align-self-end min-width-0',
                width: 17.5
            },
            {
                key: 'acceptanceResult',
                label: "Response",

                className: 'align-self-top',
                width: 12.5
            }
        ]
            :
            [{
                key: 'name',
                label: "Name",

                className: 'align-self-start',
                width: 100
            }
            ]
            ;
        return keys
    };

    setRowsPerPage = rowsPerPage => {
        this.setState({ rowsPerPage });
    };


    addToSelectedNomineeList(event, selectedNominee) {
        let newNomineeList = this.state.listOfSelectedNominees;
        const nomineeIdList = this.state.listOfSelectedNominees.map(nomineeFromList => {
            return nomineeFromList.nomineeId;
        })
        if (nomineeIdList.includes(selectedNominee.nomineeId)) {
            newNomineeList.forEach((nom, index) => {
                if (nom.nomineeId === selectedNominee.nomineeId) {
                    if (nomineeIdList.length === 1) {
                        newNomineeList = [];
                    } else {
                        newNomineeList.splice(index, 1);

                    }
                }
            })
        } else {
            newNomineeList.push(selectedNominee);
        }
        this.setState({ listOfSelectedNominees: newNomineeList })
    }

    searchbarStyle() {
        return "pl-2 col-4 d-flex";
    }

    displayResponseFilter(handleFilter, handleFilterProps, defaultScoreValue, weightedScoreRanges) {
        if (window.innerWidth > Globals.MOBILE_MAXIMUM_RESOLUTION) {
            return (<div className="col-4 px-2 d-flex">
                <Select isMulti aria-label="Response" placeholder="Response" isClearable className="w-100 pr-2" onChange={program => handleFilter({ ...handleFilterProps, filter: program, type: "acceptanceResult" })} options={weightedScoreRanges.sort((left, right) => {return left.label > right.label ? 1 : left.label === right.label ? 0 : -1})} />
            </div>)
        }
        return undefined;
    }

    displayAwardedFilter(handleFilter, handleFilterProps, defaultScoreValue, data) {
        if (window.innerWidth > Globals.MOBILE_MAXIMUM_RESOLUTION) {
            return (<div className="col-4 px-2 d-flex">
                <Select isMulti isClearable className="w-100 pr-2" aria-label="Awarded" placeholder="Awarded" onChange={program => handleFilter({ ...handleFilterProps, filter: program, type: "temporaryAwardedDisplayValue" })} options={data.sort((left, right) => {return left.label > right.label ? 1 : left.label === right.label ? 0 : -1})} />
            </div>)
        }
        return undefined;
    }

    displayAcademicProgramFilter(handleFilter, handleFilterProps, defaultScoreValue, data) {
        if (window.innerWidth > Globals.MOBILE_MAXIMUM_RESOLUTION) {
            return (<div className="col-4 px-2 d-flex">
                <Select isMulti isClearable className="w-100 pr-2" aria-label="Academic Program" placeholder="Academic Program" onChange={program => handleFilter({ ...handleFilterProps, filter: program, type: "academicProgram" })} options={data.sort((left, right) => {return left.label > right.label ? 1 : left.label === right.label ? 0 : -1})} />
            </div>)
        }
        return undefined;
    }

    displayCollegeFilter(handleFilter, handleFilterProps, defaultScoreValue, data) {
        if (window.innerWidth > Globals.MOBILE_MAXIMUM_RESOLUTION) {
            return (<div className="col-6 d-flex pl-2">
                <Select isMulti isClearable className="w-100 pr-0" aria-label="College" placeholder="College" onChange={program => handleFilter({ ...handleFilterProps, filter: program, type: "college" })} options={data.sort((left, right) => {return left.label > right.label ? 1 : left.label === right.label ? 0 : -1})} />
            </div>)
        }
        return undefined;
    }

    displayAwardFilter(handleFilter, handleFilterProps, defaultScoreValue, data) {
        if (window.innerWidth > Globals.MOBILE_MAXIMUM_RESOLUTION) {
            return (<div className="col-6 d-flex pl-2">
                <Select isMulti isClearable className="w-100 pr-0" aria-label="Award" placeholder="Award" onChange={program => handleFilter({ ...handleFilterProps, filter: program, type: "pk" })} options={data.sort((left, right) => {return left.label > right.label ? 1 : left.label === right.label ? 0 : -1})} />
            </div>)
        }
        return undefined;
    }

    triggerUpdateNomineeResponse() {
        const response = this.state.responseDropdownValue.label;
        const nominees = this.state.listOfSelectedNominees;
        this.props.changeAwardeeResponseStatuses(nominees, response);
        this.setState({ listOfSelectedNominees: [] });

    }

    setResponseToChangeForSelectedNominees(response) {
        this.setState({ responseDropdownValue: response });
    }

    sendAwardLetter(nominees, responseOptions) {

        return (<div className="col-12 px-0 d-flex">

            {this.props.isAdmin && <Select isClearable value={this.state.responseDropdownValue || ""} className="w-100 pr-2" aria-label="Change Awardee Response" placeholder="Change Awardee Response" onChange={event => this.setResponseToChangeForSelectedNominees(event)} options={responseOptions} />}
            {this.props.isAdmin && <div className="col-4">
                <OSUButton uppercase={false} color={"gray"} disabled={this.state.listOfSelectedNominees.length === 0} ariaLabel="Change Awardee Response" onClick={event => this.triggerUpdateNomineeResponse()}>Change Awardee Response</OSUButton></div>}


            <div className="col-3">
                <OSUButton uppercase={false} className="float-right" disabled={this.state.listOfSelectedNominees.length === 0} ariaLabel="Send Award Letter" onClick={event => this.sendAwardLetterEmail(nominees)}>Send Award Letter</OSUButton></div>


        </div>)
    }

    sendAwardLetterEmail(nominees) {
        this.props.sendAwardLetterEmail(nominees);
        this.setState({ reviewAlertOpen: true });
        window.scrollTo(0, 0);
    }

    dismissReviewAlert = () => {
        this.setState({ reviewAlertOpen: false });
        this.props.dismissReviewAlert();
    };

    changePreviousAwardsCheckbox = (event) => {
        this.props.changePreviousAwardsCheckboxForAwarded(event.target.checked);
    }


    render() {
        const { handleFilter, currentAwards, nominees, gpaTypes, filters, nomineesCsvHeaders, nomineesCsvData, query, defaultScoreValue, addAwardNomineesFilter, searchAwardNominees, isAdmin } = this.props;
        const rowsPerPage =  this.state.rowsPerPage;

        const dataIndex = this.state.dataIndex;
        const data = nominees &&  currentAwards.list && currentAwards.list.length > 0 ? nominees.filter(nominee => currentAwards.list.some(award => nominee.pk === award.pk) || this.props.isPreviousAwardsBoxCheckedForAwarded) : [];
        const handleFilterProps = {
            activeFilters: filters,
            filterHandler: addAwardNomineesFilter
        };
        if (this.state.expandAll || this.state.collapseAll) {
            this.setState({
                expandAll: false,
                collapseAll: false
            });
        }

        const isDisabled = (nominee) => {

            if (this.props.isAdmin) {
                return false;
            }
            const nomineeHasBeenSent = !!nominee.awardLetterSentDate;
            const awardIsNotSendable = !nominee.isAwardSendable;
            return nomineeHasBeenSent || awardIsNotSendable;
        }

        const chunkedData = data && data.length > 0 ?
            chunk(data, rowsPerPage || data.length) : [{}];

        const searchbarLabel = "EMPLID, Name"

        const {combinedAwards,accepted,shortPlans,colleges,awards} = this.props.filterLists;



        data.forEach(dataElement => {
            if (dataElement.nomineeTemporaryDocumentIdForAwardedTab && (this.props.isAdmin || dataElement.isAwardPosted)) {
                const documentId = dataElement.nomineeTemporaryDocumentIdForAwardedTab ? dataElement.nomineeTemporaryDocumentIdForAwardedTab.toString() : "0";
                let sentDate = "";
                if (dataElement.awardLetterSentDate) {
                    sentDate = dataElement.awardLetterSentDate === "sending" ? "\nsending" : "\nSent " + moment(dataElement.awardLetterSentDate).format("MM-DD-YY HH:mm");
                }
                try {
                    //likely to trigger while we figure out the data.
                    dataElement.letter = (
                        <div>
                            <PDFLink
                                className="documentFlex"
                                key={documentId}
                                documentId={documentId}
                                name={dataElement.name}
                                shortPlan={dataElement.shortPlan}
                                awardKey={dataElement.awardLetterPk || dataElement.pk}
                            >
                                Award Letter
          
                    </PDFLink>
                            <Caption>
                                {sentDate}
                            </Caption>
                        </div>
                    );
                } catch (err) {
                    console.log("Document data is not valid");
                }
            }
            dataElement.selected = (<div style={{ 'textAlign': 'left' }} ><label htmlFor={`select-${dataElement.emplid}`} className="sr-only">{`Select ${dataElement.name}`}</label>
                <input id={`select-${dataElement.emplid}`} type="checkbox" onChange={event => this.addToSelectedNomineeList(event, dataElement)} disabled={isDisabled(dataElement)} >
                </input></div>);


        })

        const resultsData = () => {
            return data.length > 0 ? { shownResults: data.length, totalResults: data.length } : {};
        }
        if (this.props.status && this.props.status === "loading") {
            return (<OSULoading dataTestId="loading" text="Loading..." />);
        } else {

            return <div>
                <Alert data-testid="reviewAlert" color={this.state.reviewAlertColor} isOpen={this.state.reviewAlertOpen}
                    toggle={this.dismissReviewAlert}>
                    {this.state.reviewAlertMessage}
                </Alert>
                <Body2 className="mb-1 align-items-bottom"><span className="pt-4">Filter Content &nbsp;&nbsp;&thinsp; | </span>
                    <OSUButton uppercase={false} variant="body2" ariaLabel="Clear all filters" link onClick={() => this.props.clearAllFilters(filters, addAwardNomineesFilter, searchAwardNominees)}>Clear All</OSUButton>
                </Body2>

                <CSVLink aria-label="Download table as CSV file" filename={`${new Date().toLocaleDateString()}-awarded-nominees.csv`} headers={nomineesCsvHeaders} data={nomineesCsvData}>
                            <Icon color="blue" className="mr-2" type="download" />Download CSV
                </CSVLink>
                <Row className="pb-1">
                    <InputGroup className={this.searchbarStyle()}>
                        <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                                <Icon type="search" color="gray" />
                            </InputGroupText>
                        </InputGroupAddon>
                        <Input type="text" aria-label={searchbarLabel} placeholder={searchbarLabel} value={query} onChange={(e) => searchAwardNominees(e.target.value)} />
                    </InputGroup>
                    {
                        <div className ="col-8 d-flex px-0">
                            {isAdmin && this.displayCollegeFilter(handleFilter, handleFilterProps, defaultScoreValue, colleges)}
                            {this.displayAwardFilter(handleFilter, handleFilterProps, defaultScoreValue, awards)}
                        </div>
                    }


                </Row>
                <Row>
                    {this.displayAwardedFilter(handleFilter, handleFilterProps, defaultScoreValue, combinedAwards)}
                    {this.displayResponseFilter(handleFilter, handleFilterProps, defaultScoreValue, accepted)}
                    {this.displayAcademicProgramFilter(handleFilter, handleFilterProps, defaultScoreValue, shortPlans)}
                </Row>
                <div className="col-6 px-3 d-flex"><Input 
            checked={this.props.isPreviousAwardsBoxCheckedForAwarded}
            value="previousAwardsCheckBox"
            onChange={(event = {}) => this.changePreviousAwardsCheckbox(event)}
            type="checkbox"
            id="previous-awards-checkbox"
          />
          Show Nominees for Previous Awards 
          </div> 
                <PaginationWrapper
                    persist
                    totalPageCount={chunkedData.length}
                    updateDataIndex={this.setDataIndex}
                    updateRowsPerPage={this.setRowsPerPage}
                    dataIndex={dataIndex}
                    rowsPerPageOptions={[10, 20, 30]}
                    rowsPerPage={rowsPerPage}
                    resultsData={resultsData()}
                    showOptionalCount={true}
                >
                    <Table multiOpen sortable expandAll={this.state.expandAll} collapseAll={this.state.collapseAll}
                        paginationData={{
                            rowsPerPage: rowsPerPage || 9999,
                            dataIndex: dataIndex,
                        }}
                        sortKeys={["name", "data", "temporaryAwardedDisplayValue", "response"]}
                        hover={false} headerVariant="subtitle2"
                        defaultSortColumn={1} //name
                        data={data}
                        dataKeys={this.dataKeys(gpaTypes)} />
                </PaginationWrapper>
                <br />
                {this.sendAwardLetter(this.state.listOfSelectedNominees, accepted)}

            </div>
        }
    }
}


Awarded.defaultProps = {
    nominees: [],
    nomineesDetails: [],
    gpaTypes: [],
    dataIndex: 0
};

Awarded.propTypes = {
    nominees: PropTypes.array,
    nomineesDetails: PropTypes.array,
    gpaTypes: PropTypes.array,
    awardKey: PropTypes.string,
    status: PropTypes.string,
    filters: PropTypes.array,
    query: PropTypes.string,
    scoringCriteria: PropTypes.array,
    programDropdown: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.shape({
            singleSelect: PropTypes.bool,
            type: '',
            key: ''
        })
    })),
    defaultScoreValue: PropTypes.array,
    defaultShortPlanValue: PropTypes.array
};

export default Awarded