import { API } from "aws-amplify";
import { AWARD_NOMINEES_STATE, REVIEW_ALERT_DISMISSED, UNAWARD_NOMINEES_STATE } from "../actions-index";
import { ACTION_STATE_ERROR, ACTION_STATE_LOADING, ACTION_STATE_SUCCESS, API_RESPONSE_MESSAGE_ERROR } from "../util/constants";
import { buildAction, handleUnauthenticated } from "../util/util";

export function awardNominees(awardKey, awardResult, nominees) {
    return dispatch => {
        dispatch(buildAction(AWARD_NOMINEES_STATE, ACTION_STATE_LOADING));
        API.post(process.env.REACT_APP_GRAD_AWARD_API_NAME, "/awardNominees", { body: { awardKey, awardResult, nominees }})
        .then(response => {
            if(response.message === API_RESPONSE_MESSAGE_ERROR) {
                dispatch(buildAction(AWARD_NOMINEES_STATE, ACTION_STATE_ERROR));
            } else {
                dispatch(buildAction(AWARD_NOMINEES_STATE, ACTION_STATE_SUCCESS));
            }
        })
        .catch(error => {
            console.error("Award Nominees Error: ", error);
            handleUnauthenticated(error, () => {
                dispatch(buildAction(AWARD_NOMINEES_STATE, ACTION_STATE_ERROR));
            });
        });
    };
}

export function dismissReviewAlert() {
    return dispatch => {
        dispatch({ type: REVIEW_ALERT_DISMISSED, payload: true });
    };
}

export function unawardNominees(awardKey, nominees, awardResults = null) {
    /*  
        awardResults is an array of award result for each nominee
        - when included, nominees are unawarded from the award and their status is set to unawarded
        - when not included, nominees are removed from the award and their status is set back to nominated
        - if an award result for a nominee is not a non-empty string, then the nominee will be skipped
    */
    return dispatch => {
        dispatch(buildAction(UNAWARD_NOMINEES_STATE, ACTION_STATE_LOADING));
        API.post(process.env.REACT_APP_GRAD_AWARD_API_NAME, "/unawardNominees", { body: { awardKey, nominees, awardResults }})
        .then(response => {
            if(response.message === API_RESPONSE_MESSAGE_ERROR) {
                dispatch(buildAction(UNAWARD_NOMINEES_STATE, ACTION_STATE_ERROR));
            } else {
                dispatch(buildAction(UNAWARD_NOMINEES_STATE, ACTION_STATE_SUCCESS));
            }
        })
        .catch(error => {
            console.error("Unaward Nominees Error: ", error);
            handleUnauthenticated(error, () => {
                dispatch(buildAction(UNAWARD_NOMINEES_STATE, ACTION_STATE_ERROR));
            });
        });
    };
}

export * from './Evaluation/actions'
export * from './ScoreSheetContent/actions'
export * from './ReviewersContent/actions'
export * from './NomineesContent/actions'
export * from './PrimaryReviewContent/actions'
export * from './SecondaryReviewContent/actions'