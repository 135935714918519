import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import { setUser } from '../../App/actions';
import SignIn from '../components/SignIn';

const mapStateToProps = (state) => {
    return {
        user: state.user,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setUser: () => dispatch(setUser())
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SignIn));
