import React, { Component } from "react";
import { Form } from "reactstrap";
import PropTypes from "prop-types";
import moment from "moment";
import { cloneDeep, forOwn, mapValues } from "lodash";
import AwardDates from "../../../Common/AwardDates/components";
import { AWARD_DATE_FORMAT, EVENT_RESET_STATE } from "../../constants";

class Dates extends Component {
    componentWillMount() {
        this.setComponentState();
        document.addEventListener(EVENT_RESET_STATE, this.setComponentState);
    }

    shouldComponentUpdate(nextProps) {
        return nextProps.isActive; // re-render only when active
    }

    componentWillUnmount() {
        document.removeEventListener(EVENT_RESET_STATE, this.setComponentState);
    }

    setComponentState = () => {
        const { award } = this.props;
        this.setState({ 
            award,
            dates: {
                nominationStartDate: award.nominationStartDate,
                nominationEndDate: award.nominationEndDate,
                reviewStartDate: award.reviewStartDate,
                reviewCompletionDate: award.reviewCompletionDate,
                reviewEndDate: award.reviewEndDate,
                reviewCommitteeMeetingStartDate: award.reviewCommitteeMeetingStartDate,
                reviewCommitteeMeetingEndDate: award.reviewCommitteeMeetingEndDate,
                reviewAccessEndDate: award.reviewAccessEndDate,
                awardPostedDate: award.awardPostedDate,
                awardLetterSendOptionDate: award.awardLetterSendOptionDate,
                awardLetterSentDate: award.awardLetterSentDate,
                acceptanceDeadlineDate: award.acceptanceDeadlineDate,
                awardEndDate: award.awardEndDate
            }
        });
    }

    onAwardDatesValidate = (isValid, awardDates) => {
        let award = null;
        if(isValid) {
            const dates = mapValues(awardDates, value => {
                return moment(value).format(AWARD_DATE_FORMAT);
            });
            this.setState({ dates });
            award = cloneDeep(this.state.award);
            forOwn(dates, (value, key) => {
                award[key] = value;
            });
        }
        this.props.onValidate(isValid, award);
    }
    
    render() {
        const { award } = this.state;
        const { requireReview = true, sendAwardLetter = true } = award;
        return (
            <section>
                <h2 className="heading6" data-testid="datesHeader">Dates</h2>
                <hr />
                <Form id="datesForm" data-testid="datesForm" noValidate>
                    <AwardDates dates={this.state.dates} onValidate={this.onAwardDatesValidate} readOnly={this.props.readOnly} showReviewDates={requireReview} showAwardLetterDates={sendAwardLetter} inReview={this.props.inReview} />
                </Form>
            </section>
        );
    }
}

Dates.defaultProps = {
    isActive: false,
    inReview: false,
    readOnly: false
}

Dates.propTypes = {
    award: PropTypes.object.isRequired,
    isActive: PropTypes.bool,
    inReview: PropTypes.bool,
    onValidate: PropTypes.func.isRequired,
    readOnly: PropTypes.bool
}

export default Dates;