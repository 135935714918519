import React from 'react'
import { Table, Body2, OSULoading, OSUError, Icon, OSUButton, PaginationWrapper } from 'osu-react-components'
import PropTypes from 'prop-types';
import { Col, Input, InputGroup, InputGroupAddon, InputGroupText, Row } from 'reactstrap'
import Select from 'react-select'
import ReviewerDetails from '../containers/Details';
import EllipsisWithTooltip from 'react-ellipsis-with-tooltip';
import * as Globals from '../../../constants'
import IconLegend from "../../components/IconLegend";
import { chunk, endsWith, isString } from "lodash";
import {redirectToLogOut} from "../../../util/util";
import {Auth} from "aws-amplify";

class ReviewersContent extends React.Component {
    componentDidMount() {
        this.findAssignedReviewersByAward(this.props.awardKey)
    }

    constructor(props) {

        super(props);
        this.state = {
            selectedReviewers: [],
            dataIndex: 0,
            rowsPerPage: 10,
            value: '',
            copied: false,
        };

    }

    findAssignedReviewersByAward(awardKey) {
        const key = this.props.sortKey && this.props.sortKey.key ? this.props.sortKey.key : 'name'
        const direction = this.props.sortKey && this.props.sortKey.direction ? this.props.sortKey.direction : 'asc'
        this.props.findAssignedReviewersByAward(awardKey, { key, direction })
    }

    generateButton = (label, type, nestedProperty = null) => {
        /*const { sortKey } = this.props
        const matchingSort = sortKey && sortKey.key === type && (nestedProperty ? nestedProperty === sortKey.nestedProperty : true)*/

        return <OSUButton ariaLabel={`Sort by ${label}`} className="p-0" color="black" uppercase={false} link variant="subtitle2"
            onClick={() => this.props.updateAssignedReviewerSortKey({ key: type, nestedProperty })}>
            {label}
        </OSUButton>
    };

    dataKeys = () => {
        const percentSortFormatter = (val) => {
            // format string percentage as float for sort
            if(val && isString(val) && endsWith(val, "%")) {
                const numVal = val.substring(0, val.length - 1);
                if(!isNaN(numVal)) val = parseFloat(numVal);
            }
            return val;
        };
        return window.innerWidth < Globals.MOBILE_MAXIMUM_RESOLUTION ?
            [
                {
                    key: 'name',
                    label: 'Name',
                    className: 'align-self-end min-width-0',
                    width: 80
                }
                , {
                    key: 'percent',
                    label: 'Percent Complete',
                    className: 'align-self-end text-center',
                    width: 20,
                    sortFormatter: percentSortFormatter
                }
            ] :
            [{
                key: 'name',
                label: 'Name',
                className: 'align-self-end min-width-0',
                width: 20
            }, {
                key: 'dOrg',
                label: 'D-Org',
                className: 'align-self-end min-width-0',
                width: 20
            }, {
                key: 'college',
                label: 'College',
                className: 'align-self-end min-width-0',
                width: 20
            }, {
                key: 'packets',
                label: '# Packets',
                className: 'align-self-end text-center',
                width: 10
            }, {
                key: 'primary',
                label: '# Composite',
                className: 'align-self-end text-center',
                width: 12
            }, {
                key: 'percent',
                label: '% Complete',
                className: 'align-self-end text-center',
                width: 10,
                sortFormatter: percentSortFormatter
            }, {
                key: 'status',
                label: 'Status',
                className: 'align-self-end text-center',
                width: 8
            }]
    };


    jsxReviewers = (reviewers = []) => reviewers.map(reviewer => {

        const { dOrg, college, stats, name, scoresAreRanked } = reviewer;
        const percent = stats && stats.hasOwnProperty('percentCompleted') ? stats.percentCompleted : '-';
        return {
            ...reviewer,
            dOrg: <EllipsisWithTooltip placement="bottom">{dOrg}</EllipsisWithTooltip>,
            name: <EllipsisWithTooltip placement="bottom">{name}</EllipsisWithTooltip>,
            college: <EllipsisWithTooltip placement="bottom">{college}</EllipsisWithTooltip>,
            packets: stats && stats.hasOwnProperty('totalNominees') ? stats.totalNominees : '-',
            primary: stats && stats.hasOwnProperty('totalPrimaryNominees') ? stats.totalPrimaryNominees : '-',
            percent,
            status: (stats && stats.hasOwnProperty('notEvaluatedCount') && scoresAreRanked  && (stats.notEvaluatedCount === 0)) ? <Icon size="xs" className="pl-1" type="check" color="green" >true</Icon> : 
                                                                                                                                            <div></div>,// <Icon color="red" type="exclamation-triangle" className="ml-1" >false</Icon>,
            expandedContent: <ReviewerDetails className={`px-4 py-3`} {...reviewer} percent={percent}/>
        }
    });

    unsuccessfulScreen(status) {
        if (status === 'loading') {
            return <OSULoading text="Loading all reviewers..." />
        } else if(status==='401'){
            Auth.signOut() && redirectToLogOut();
            //return <OSUError ariaLabel="You are logged out. Close your browser and login again." text="You are logged out. Close your browser and login again." actionText="Login" onClick={() => redirectToLogIn()} />
        } else if (!this.props.awardKey) {
            return <OSUError ariaLabel="Error occurred. Navigate to award selection." text="Could not verify award." actionText="Go to Award Selection" path={'/nominee-reviews/award-selection'} />
        }
        return <OSUError ariaLabel="Error occurred. Reload all reviewers." text="Could not load all reviewers." actionText="Reload" onClick={() => this.findAssignedReviewersByAward(this.props.awardKey)} />
    }

    setDataIndex = dataIndex => {
        this.setState({ dataIndex });
    };

    displayAcademicProgramFilter(handleFilter, handleFilterProps, shortPlans) {

        if (window.innerWidth > Globals.MOBILE_MAXIMUM_RESOLUTION) {
            return (<Select aria-label="Academic Program" isClearable value={shortPlans.value} className="fb-20" placeholder="Academic Program" onChange={o => handleFilter({ ...handleFilterProps, filter: o, type: shortPlans.type })} options={shortPlans.options} />)
        }
        return undefined

    }

    displayCollegeFilter(handleFilter, handleFilterProps, colleges) {

        if (window.innerWidth > Globals.MOBILE_MAXIMUM_RESOLUTION) {
            return (<Select aria-label="College" isClearable value={colleges.value} className="fb-20" placeholder="College" onChange={o => handleFilter({ ...handleFilterProps, filter: o, type: colleges.type })} options={colleges.options} />)
        }
        return undefined

    }

    displayDorgFilter(handleFilter, handleFilterProps, dOrgs) {

        if (window.innerWidth > Globals.MOBILE_MAXIMUM_RESOLUTION) {
            return (<Select aria-label="D-Org" isClearable value={dOrgs.value} className="fb-20" placeholder="D-Org" onChange={o => handleFilter({ ...handleFilterProps, filter: o, type: dOrgs.type })} options={dOrgs.options} />)
        }
        return undefined
    }


    searchbarStyle() {
        return window.innerWidth > Globals.MOBILE_MAXIMUM_RESOLUTION ? "col-4 pl-0 pr-2" : "pl-0 pr-2"
    }


    setRowsPerPage = rowsPerPage => {
        this.setState({ rowsPerPage });
    };


    render() {
        const { reviewers, status, query, filters, searchAssignedReviewers, addAssignedReviewerFilter, reviewerFilters, handleFilter } = this.props;
        const rowsPerPage = this.state.rowsPerPage;
        const dataIndex = this.state.dataIndex;
        const { shortPlans, dOrgs, colleges } = filters;

        if (status !== 'success') {
            return this.unsuccessfulScreen(status)
        }
        const handleFilterProps = {
            activeFilters: reviewerFilters,
            filterHandler: addAssignedReviewerFilter
        };
        const chunkedData =
            reviewers.length > 0 && rowsPerPage
                ? chunk(this.jsxReviewers(reviewers), rowsPerPage)
                : [this.jsxReviewers(reviewers)];
        return <div>
            <IconLegend icons={[
                { type: "check", ariaLabel: "Reviews are complete and ranked.", color: "green", size: "xs" },
                // { type: "exclamation-triangle", ariaLabel: "Scores out of assigned ranges.", color: "red", size: "xs" }
            ]} />
            <Body2 className="mb-1 d-flex align-items-center">Filter Content | <OSUButton uppercase={false} variant="body2" ariaLabel="Clear all filters" link onClick={() => this.props.clearAllFilters(reviewerFilters, addAssignedReviewerFilter, searchAssignedReviewers)}>Clear All</OSUButton></Body2>
            <Row><Col style={{ paddingTop: 3, paddingLeft: 0, paddingRight: 3, maxWidth: "40%", minWidth: "40%" }}>
                <InputGroup >
                    <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                            <Icon type="search" color="gray" />
                        </InputGroupText>
                    </InputGroupAddon>
                    <Input type="text" aria-label="Search by Employee ID or Name" placeholder={`Search by Employee ID or Name`} value={query} onChange={(e) => searchAssignedReviewers(e.target.value)} />
                </InputGroup>
            </Col>
                <Col style={{ paddingTop: 3, paddingLeft: 0, paddingRight: 3, maxWidth: "20%", minWidth: "20%" }}>{this.displayAcademicProgramFilter(handleFilter, handleFilterProps, shortPlans)}</Col>
                <Col style={{ paddingTop: 3, paddingLeft: 0, paddingRight: 3, maxWidth: "20%", minWidth: "20%" }}>{this.displayCollegeFilter(handleFilter, handleFilterProps, colleges)}</Col>
                <Col style={{ paddingTop: 3, paddingLeft: 0, paddingRight: 3, maxWidth: "20%", minWidth: "20%" }}>{this.displayDorgFilter(handleFilter, handleFilterProps, dOrgs)}</Col>
            </Row>
            <PaginationWrapper
                persist
                totalPageCount={chunkedData.length}
                updateDataIndex={this.setDataIndex}
                updateRowsPerPage={this.setRowsPerPage}
                dataIndex={dataIndex}
                rowsPerPageOptions={[10, 20, 30]}
                rowsPerPage={rowsPerPage}
                resultsData={{ shownResults: reviewers.length, totalResults: reviewers.length }}
                showOptionalCount={true}
            >
                <Table expandable sortable multiOpen hover={false}
                    paginationData={{
                        rowsPerPage: rowsPerPage || 9999,
                        dataIndex: dataIndex,
                    }}
                    headerVariant="subtitle2" data={this.jsxReviewers(reviewers)} dataKeys={this.dataKeys()} />

            </PaginationWrapper>
        </div>
    }
}


ReviewersContent.defaultProps = {
    query: '',
    filters: {
        shortPlans: {
            options: []
        },
        dOrgs: {
            options: []
        },
        colleges: {
            options: []
        }
    },
    rowsPerPage: 10,
    dataIndex: 0
};

ReviewersContent.propTypes = {
    reviewers: PropTypes.array,
    awardKey: PropTypes.string,
    query: PropTypes.string,
    status: PropTypes.oneOf(['', 'error', 'loading', 'success']),
    findAssignedReviewersByAward: PropTypes.func,
    searchAssignedReviewers: PropTypes.func,
    addAssignedReviewerFilter: PropTypes.func,
    updateAssignedReviewerSortKey: PropTypes.func,
    filters: PropTypes.shape({
        shortPlans: PropTypes.shape({
            type: PropTypes.string,
            options: PropTypes.arrayOf(PropTypes.shape({
                label: PropTypes.string,
                value: PropTypes.shape({
                    key: PropTypes.string,
                    singleSelect: PropTypes.bool
                })
            }))
        }),
        dOrgs: PropTypes.shape({
            type: PropTypes.string,
            options: PropTypes.arrayOf(PropTypes.shape({
                label: PropTypes.string,
                value: PropTypes.shape({
                    key: PropTypes.string,
                    singleSelect: PropTypes.bool
                })
            }))
        }),
        colleges: PropTypes.shape({
            type: PropTypes.string,
            options: PropTypes.arrayOf(PropTypes.shape({
                label: PropTypes.string,
                value: PropTypes.shape({
                    key: PropTypes.string,
                    singleSelect: PropTypes.bool
                })
            }))
        })
    })
};

export default ReviewersContent