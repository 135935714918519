import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import ReviewersContent from "../components";
import { findAssignedReviewersByAward, searchAssignedReviewers, addAssignedReviewerFilter, updateAssignedReviewerSortKey } from "../../../actions-index";
import { uniq } from 'lodash'

const mapStateToProps = (state, ownProps) => {
    const { systemAward, assignedReviewers } = state
    return {
        reviewers: Array.isArray(assignedReviewers.list) ? assignedReviewers.list : [],
        status: assignedReviewers.status || '',
        query: assignedReviewers.query,
        awardKey: systemAward.pk || "",
        reviewerFilters: Array.isArray(assignedReviewers.filters) ? assignedReviewers.filters : [],
        filters: determineFilters(assignedReviewers.list, assignedReviewers.filters),
        sortKey: assignedReviewers.sortKey
    };
};

const determineFilters = (list = [], filters = []) => {
    const shortPlans = uniq(list.map(nom => nom.shortPlans).reduce((a, b) => a.concat(b), []));
    const dOrgs = uniq(list.filter(nom => nom.dOrg).map(nom => nom.dOrg))
    const colleges = uniq(list.filter(nom => nom.college).map(nom => nom.college))
    const constructSelect = (list = [], type = '') => {
        const options = list.sort().map(o => ({
            label: o,
            value: {
                singleSelect: true,
                type,
                key: o
            }
        }))

        return {
            type,
            options,
            value: getActiveListValues(options, filters, type)
        }
    }

    return {
        shortPlans: constructSelect(shortPlans, 'shortPlans'),
        dOrgs: constructSelect(dOrgs, 'dOrg'),
        colleges: constructSelect(colleges, 'college')
    }
}

function getActiveListValues(list = [], filters = [], type = '') {
    if (!Array.isArray(list) || !Array.isArray(filters)) {
        return []
    }
    let matches = []
    list.filter(item => item.value).forEach(item => {
        filters.filter(o => o.type === type).forEach(filter => {
            const { value } = item
            if (value.key === filter.key) {
                matches.push(item)
            }
        })
    })
    return matches
}

const mapDispatchToProps = (dispatch) => {
    return {
        findAssignedReviewersByAward: (award, sortKey) => dispatch(findAssignedReviewersByAward(award, sortKey)),
        searchAssignedReviewers: (query) => dispatch(searchAssignedReviewers(query)),
        addAssignedReviewerFilter: (filter) => dispatch(addAssignedReviewerFilter(filter)),
        updateAssignedReviewerSortKey: (sortKey) => dispatch(updateAssignedReviewerSortKey(sortKey))
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ReviewersContent));

