import React, { Component } from "react";
import { Card, CardBody, CardTitle, Collapse } from "reactstrap";
import PropTypes from "prop-types";
import { Body2, Icon, OSUButton } from "osu-react-components";
import moment from "moment";
import { isEmpty } from "lodash";

class AwardDates extends Component {
    render() {
        const { awards } = this.props;
        return (
            <Card data-testid="award-dates" style={{ width: "20rem" }}>
                <CardTitle className="rounded-top mb-1">
                    <h2 data-testid="award-dates-header" className="heading6 pl-3 pt-3 pb-1">Award Dates &amp; Guidelines</h2>
                    <hr className="m-0" />
                </CardTitle>
                <CardBody className="pt-0 px-2">
                    {awards.length === 0 &&
                        <span className="pl-2" data-testid="no-awards-message">No Awards</span>
                    }
                    {awards.length > 0 &&
                        awards.map((award, index) => {
                            return (<AwardDate key={`awardDate${index + 1}`} award={award} index={index} />)
                        })
                    }
                </CardBody>
            </Card>
        );
    }
}

AwardDates.defaultProps = {
    awards: []
}

AwardDates.propTypes = {
    awards: PropTypes.arrayOf(PropTypes.object)
}

export default AwardDates;

class AwardDate extends Component {
    constructor(props) {
        super(props);
        const index = this.props.index;
        this.state = {
            toggleId: `award${index}DatesToggle`,
            collapseId: `award${index}DatesCollapse`,
            isCollapseOpen: false
        }
    }

    componentDidMount() {
        // hack to add aria-controls to OSUButton
        const toggle = document.getElementById(this.state.toggleId);
        if(toggle) toggle.setAttribute("aria-controls", this.state.collapseId);
    }

    toggleCollapse = () => {
        this.setState({ isCollapseOpen: !this.state.isCollapseOpen });
    }

    formatAwardDate = (dateString = null) => {
        let formattedDate = "";
        if(!isEmpty(dateString)) {
            const date = moment(dateString);
            if(date.isValid()) formattedDate = date.format("MM/DD/YYYY hh:mm A");
        }
        return formattedDate;
    }

    render() {
        const { award, index } = this.props;
        const { name, externalUrl } = award;
        const nominationStartDate = this.formatAwardDate(award.nominationStartDate);
        const nominationEndDate = this.formatAwardDate(award.nominationEndDate);
        const awardPostedDate = this.formatAwardDate(award.awardPostedDate);
        const awardLetterSentDate = this.formatAwardDate(award.awardLetterSentDate);
        const acceptanceDeadlineDate = this.formatAwardDate(award.acceptanceDeadlineDate);
        const toggleLabel = `${this.state.isCollapseOpen ? "Hide" : "Show"} ${name} Dates`;
        const toggleIcon = `chevron-${this.state.isCollapseOpen ? "down" : "up"}`;
        const awardDateClassName = "d-flex justify-content-between";
        return (
            <div>
                <h3 data-testid={`award${index}-dates-header`} className="d-inline typography typography-body2">
                    <span className="sr-only">Award: </span>{name}
                </h3>
                <OSUButton id={this.state.toggleId} link ariaLabel={toggleLabel} onClick={this.toggleCollapse} className="d-inline p-1">
                    <Icon type={toggleIcon} color="gray" size="sm" />
                </OSUButton>
                <Collapse id={this.state.collapseId} isOpen={this.state.isCollapseOpen} aria-expanded={this.state.isCollapseOpen} className="mb-2">
                    <div className="px-2">
                        {nominationStartDate !== "" &&
                            <div className={awardDateClassName}>
                                <Body2>Nomination Start:</Body2>
                                <Body2 dataTestId={`award${index}-nominationStartDate`}>{nominationStartDate}</Body2>
                            </div>
                        }
                        {nominationEndDate !== "" &&
                            <div className={awardDateClassName}>
                                <Body2>Nomination End:</Body2>
                                <Body2 dataTestId={`award${index}-nominationEndDate`}>{nominationEndDate}</Body2>
                            </div>
                        }
                        {awardPostedDate !== "" &&
                            <div className={awardDateClassName}>
                                <Body2>Award Posted:</Body2>
                                <Body2 dataTestId={`award${index}-awardPostedDate`}>{awardPostedDate}</Body2>
                            </div>
                        }
                        {awardLetterSentDate !== "" &&
                            <div className={awardDateClassName}>
                                <Body2>Award Letter Sent:</Body2>
                                <Body2 dataTestId={`award${index}-awardLetterSentDate`}>{awardLetterSentDate}</Body2>
                            </div>
                        }
                        {acceptanceDeadlineDate !== "" &&
                            <div className={awardDateClassName}>
                                <Body2>Acceptance Deadline:</Body2>
                                <Body2 dataTestId={`award${index}-acceptanceDeadlineDate`}>{acceptanceDeadlineDate}</Body2>
                            </div>
                        }
                        {externalUrl &&
                            <div className="d-flex justify-content-center">
                                <a data-testid={`award${index}-guidelines`} rel="noopener noreferrer" href={externalUrl} target="_blank">
                                    Competition Guidelines<span className="sr-only"> (Opens New Window)</span>
                                </a>
                            </div>
                        }
                    </div>
                </Collapse>
                <hr className="mt-0 mb-1" />
            </div>
        );
    }
}

AwardDate.propTypes = {
    award: PropTypes.object.isRequired,
    index: PropTypes.number.isRequired
}