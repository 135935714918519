import React, { useContext } from "react"
import PropTypes from 'prop-types'
import { Typography, OSUButton, OSULoading } from 'osu-react-components'
import { Alert, FormGroup, Input, Label } from "reactstrap";
import AwardSelect from "./AwardSelect";
import { FormEnvironmentContext } from "../context";
import Questions from "./Questions";
import Packet from "../components/Packet";
import Award from "../containers/AwardEligibility";
import '../index.scss'
import { useEffect } from "react";
import { useRef } from "react";

function Form(props = {}) {
    const { saveChanges, awardLastUpdated, nomineeAward, adminComments, submitForm, formContainsLoading, loadingText, formIsEmpty, formAlert, nomineeName, questions, nominatedByEmplid, nominatedByNameDotN, nominatedByDate, programStatus } = props
    const { isAdmin, readonly, readonlyDescription, studentStatus } = useContext(FormEnvironmentContext);
    const questionsDisabled = (readonly || (studentStatus === "AWARDED" && !isAdmin));
    const alertRef = useRef();
    useEffect(() => {
      const alertEl = alertRef.current;
      if (alertEl && ["success", "error"].includes(formAlert.type)) {
        alertEl && alertEl.scrollIntoView({ behavior: "smooth" });
      }
    }, [formAlert.type]);

    const handleCardSubmission = (event) => {
        event && event.preventDefault()
        if(!formIsEmpty) {
          submitForm() //params mean nothing here
        }
    }
    
    if(formContainsLoading) {
        return <OSULoading text={loadingText || 'Submitting Form...'} />
    }
    
    return (
      <form className="d-flex flex-column nom-card-form" onSubmit={handleCardSubmission}>
        <CustomFormAlert ref={alertRef} readonly={readonly} readonlyDescription={readonlyDescription} alert={formAlert} />
        <Award nomineeName={nomineeName} saveChanges={saveChanges} nominatedByEmplid={nominatedByEmplid} nominatedByNameDotN={nominatedByNameDotN} nominatedByDate={nominatedByDate} programStatus={programStatus} />
        {/* <Nominator nominatedByEmplid={nominatedByEmplid} nominatedByNameDotN={nominatedByNameDotN} nominatedByDate={nominatedByDate} /> */}
        <Questions disabled={questionsDisabled} questions={questions} saveChanges={saveChanges} />
        <Packet nomineeName={nomineeName} saveChanges={saveChanges} />
        <AwardSelect
            id="awardedTo"
            label="Awarded:"
            value={nomineeAward}
            onChange={award => saveChanges({ nomineeAward: award })}
            selectProps={{
                isDisabled: !isAdmin || (['WITHDRAWN', 'PENDING', 'NOT AWARDED'].includes(studentStatus))
            }}
        />
        {awardLastUpdated && <Typography variant="body2" className="mt-1">Awarded last updated by {awardLastUpdated}</Typography>}
        {isAdmin && <Comments readonly={readonly} className="mt-3" value={adminComments} onChange={comments => saveChanges({ adminComments: comments })} />}
        <Typography>Current Status: {studentStatus}</Typography>
        <OSUButton disabled={readonly || formIsEmpty} type="submit" className="ml-auto">
            Update Status and Save Changes
        </OSUButton>
      </form>
    );
}

const Comments = ({ value, onChange, readonly, ...rest }) => {
  return (
    <FormGroup disabled={readonly} {...rest}>
      <Label for="text-area-nom-card">Internal Graduate School Comments</Label>
      <Input
        disabled={readonly}
        value={value}
        onChange={(event) => {
          onChange(event.target.value);
        }}
        type="textarea"
        name="text"
        id="text-area-nom-card"
      />
    </FormGroup>
  );
};

const CustomFormAlert = React.forwardRef((props = {}, ref) => {
  const { readonly, readonlyDescription, alert } = props;
  let text = alert.text;
  if (readonly && readonlyDescription) {
    text = readonlyDescription;
  }

  return (
    <Alert innerRef={ref} className={text ? "d-block" : "d-none"} color={alert.color}>
      {text}
    </Alert>
  );
});

Form.defaultProps = {
    student: {},
    status: '',
    nominatedFor: ''
}

Form.propTypes = {
    status: PropTypes.string
}

export default Form