import { ASSIGNED_REVIEWERS, ASSIGNED_REVIEWERS_STATUS, ASSIGNED_REVIEWERS_QUERY, ADD_ASSIGNED_REVIEWERS_FILTERS, ADD_ASSIGNED_REVIEWERS_SORT_KEY } from '../../actions-index'
import { searchList, searchListByQuery, sortList, constructFilters, constructSort } from '../../redux-helpers';

export function assignedReviewers(state = {}, action) {
    const { _list } = state
    const searchableProperties = ['name', 'emplid']

    switch (action.type) {
        case ASSIGNED_REVIEWERS:
            return {
                ...state,
                list: action.payload,
                _list: action.payload,
                status: 'success'
            }
        case ASSIGNED_REVIEWERS_STATUS:
            return {
                ...state,
                status: action.payload
            }
        case ASSIGNED_REVIEWERS_QUERY: {
            const searchedList = searchList({ query: action.payload, list: _list, searchableProperties, filters: state.filters, filteringAction: addAssignedReviewerFilters, sortKey: state.sortKey })

            return {
                ...state,
                query: action.payload,
                list: searchedList
            }
        }
        case ADD_ASSIGNED_REVIEWERS_FILTERS:
            const allFilters = constructFilters(state.filters, action.payload)
            const filteredList = addAssignedReviewerFilters({ list: _list, filters: allFilters, query: state.query, sortKey: state.sortKey, searchableProperties })

            return {
                ...state,
                filters: allFilters,
                list: filteredList
            }
        case ADD_ASSIGNED_REVIEWERS_SORT_KEY:
            const newSortKey = constructSort(state.sortKey, action.payload)

            return {
                ...state,
                sortKey: newSortKey,
                list: sortList(state.list, newSortKey)
            }
        default:
            return state
    }
}

function addAssignedReviewerFilters(values) {
    const { list, filters, query, sortKey, searchableProperties } = values
    let assignedReviewerList = Array.isArray(list) ? list.filter(item => {
        const matchingNomProperties = filters.filter((filter) => {
            const { type, key } = filter
            if (type === 'college' || type === 'dOrg') {
                return item[type] === key
            } else if (type === 'shortPlans') {
                return item[type].includes(key)
            }
            return false
        })

        return matchingNomProperties.length === filters.length
    }) : []

    if (query) {
        assignedReviewerList = searchListByQuery({ query, list: assignedReviewerList, searchableProperties })
    }
    if (sortKey) {
        assignedReviewerList = sortList(assignedReviewerList, sortKey)
    }

    return assignedReviewerList
}
