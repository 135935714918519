import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import AllReviewers from "../components/AllReviewers";
import {
  searchReviewers,
  filterOnDropdown,
  retrieveAllReviewers
} from "../actions";
import PropTypes from "prop-types";
import {
  COLLEGE_SELECTION,
  DORG_SELECTION,
  PROGRAM_OF_STUDY_SELECTION,
  AWARDS_SELECTION,
  ACTIVE_USER_SELECTION
} from "../../actions-index";
import { getAllAwards } from "../../Common/actions";

const mapStateToProps = (state, ownProps) => {
  return {
    systemAward: state.systemAward,
    allAwards: state.allAwards? [].concat(state.allAwards.currentAwards).concat(state.allAwards.previousAwards) : [],
    query: state.allReviewersQuery,
    showInactive: state.checkStateOfCheckboxToShowInactive,
    filterOnCollegeDropdown: state.applyCollegeDropdownFilter,
    filterOnDorgDropdown: state.applyDorgDropdownFilter,
    filterOnActiveUserDropdown: state.applyActiveUserDropdownFilter,
    filterOnProgramOfStudyDropdown: state.applyProgramOfStudyDropdownFilter,
    filterOnAwardsDropdown: state.applyAwardsDropdownFilter,
    allReviewers: state.allReviewersResult,
    allReviewersError: state.allReviewersError
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getAllAwards: () => dispatch(getAllAwards()),
    searchReviewers: query => dispatch(searchReviewers(query)),
    allReviewersDispatch: dispatch(retrieveAllReviewers()),
    collegeFilterAction: filterOnCollegeDropdown =>
      dispatch(filterOnDropdown(filterOnCollegeDropdown, COLLEGE_SELECTION)),
    dorgFilterAction: filterOnDorgDropdown =>
      dispatch(filterOnDropdown(filterOnDorgDropdown, DORG_SELECTION)),
    activeUserFilterAction: e =>
      dispatch(filterOnDropdown(e, ACTIVE_USER_SELECTION)),
    programOfStudyFilterAction: filterOnProgramOfStudyDropdown =>
      dispatch(
        filterOnDropdown(
          filterOnProgramOfStudyDropdown,
          PROGRAM_OF_STUDY_SELECTION
        )
      ),
    awardsFilterAction: filterOnAwardsDropdown =>
      dispatch(filterOnDropdown(filterOnAwardsDropdown, AWARDS_SELECTION))
  };
};

AllReviewers.propTypes = {
  allReviewers: PropTypes.array,
  allAwardsError: PropTypes.array,
  reviewers: PropTypes.array,
  query: PropTypes.string,
  dropdownFilter: PropTypes.string,
  filterOnCollegeDropdown: PropTypes.string,
  filterOnDorgDropdown: PropTypes.string,
  filterOnProgramOfStudyDropdown: PropTypes.string,
  filterOnAwardsDropdown: PropTypes.string
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(AllReviewers)
);
