import React from 'react';
import { CSVLink } from 'react-csv';
import { Table, Body2, Icon, OSULoading, OSUError, OSUButton, PaginationWrapper, Typography } from 'osu-react-components';
import PropTypes from 'prop-types';
import { chunk, find, uniqBy } from 'lodash';
import EvaluationDetails from '../../Evaluation/components/Details';
import { Row, Input, InputGroup, InputGroupAddon, InputGroupText, Alert, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Select from 'react-select';
import { Link } from "react-router-dom";
import EllipsisWithTooltip from 'react-ellipsis-with-tooltip';
import '../NomineesContent.css';
import * as Globals from '../../../constants';
import moment from "moment";
import IconLegend from "../../components/IconLegend";
import { redirectToLogOut } from "../../../util/util";
import { Auth } from "aws-amplify";

const FINAL_WEIGHTED_SCORE_WIDTH = 20;




class NomineesContent extends React.Component {



    constructor(props) {
        super(props);
        this.state = {
            refreshIds: [],
            rowsPerPage: 10,
            dataIndex: 0,
            expandAll: false,
            processingFinalRankedScores: true
        };
        this.refreshDocs = this.refreshDocs.bind(this);
        this.finalWeightedScoreSelectRef = React.createRef();
    }

    isAfterMeetingStartTime(){
        return moment().isAfter(moment(this.props.systemAward.reviewCommitteeMeetingStartDate, "YYYY-MM-DDTHH:mm:ssZ"));
    } 
    //TODO: REMOVE THE ADDITION OF THE FOUR HOURS ONCE THE GRAD SCHOOL HAS DIRECT ACCESS TO SET DATES VIA ADMIN PANEL
    isBeforeMeetingEndTime() {
        return moment().isBefore(moment(this.props.systemAward.reviewCommitteeMeetingEndDate, "YYYY-MM-DDTHH:mm:ssZ").add(4, 'hours'));
    }
    

    componentDidMount() {
        this.props.awardKey && this.getAllNominees(this.props.awardKey)
    }

    getAllNominees(awardKey) {
        const key = 'name';
        const direction = 'asc';

        this.props.getAllNominees(awardKey, { key, direction });
    }

    dataKeys = (gpaTypes) => {

        const gpaDataKeys = Array.isArray(gpaTypes) ? gpaTypes.map(type => (
            {
                key: type.field,
                label: type.description,
                className: 'align-self-end text-center',
                width: 20 / gpaTypes.length
            }
        )) : [];


        const doesFinalWeightedScoreExist = this.props.showWeightedScore || (this.isAfterMeetingStartTime() && this.isBeforeMeetingEndTime());
        const weightedScoreAdjustmentValue = !doesFinalWeightedScoreExist ? FINAL_WEIGHTED_SCORE_WIDTH : 0;
        const numberSortFormatter = (val) => (isNaN(val) ? 0 : Number(val));
        const keys = Globals.MOBILE_MAXIMUM_RESOLUTION < window.innerWidth ? [{
            key: 'name',
            label: 'Name',

            className: 'align-self-end min-width-0',
            width: 30 + (weightedScoreAdjustmentValue * .5)
        }, {
            key: 'displayShortPlan',
            label: 'Academic Program',

            className: 'align-self-end min-width-0',
            width: 15 + (weightedScoreAdjustmentValue * .25)
        },
        ...gpaDataKeys,
        ...doesFinalWeightedScoreExist ? [{
            key: 'finalRankedScore',
            label: 'Final Ranked Score',
            className: 'align-self-end text-center',
            width: FINAL_WEIGHTED_SCORE_WIDTH,
            sortFormatter: numberSortFormatter
        }] : [],
        {
            key: 'documents',
            label: 'Supporting Documents',
            className: 'align-self-end min-width-0',
            width: 15 + (weightedScoreAdjustmentValue * .25)
        }]


            :
            [{
                key: 'name',
                label: "Name",

                className: 'align-self-start',
                width: 70
            },
            ...this.props.showWeightedScore ? [{
                key: 'finalRankedScore',
                label: 'Final Ranked Score',
                className: 'align-self-start text-center',
                width: 30,
                sortFormatter: numberSortFormatter
            }] : [{
                key: 'displayShortPlan',
                label: 'Academic Program',

                className: 'align-self-start ellipsis',
                width: 30
            }]
            ]
            ;
        return keys
    };

    componentDidUpdate(event) {
        if (this.state.refreshIds && this.props.docList) {
            let refIds = this.state.refreshIds;
            let docLst = this.props.docList;
            refIds = refIds.filter(ref => ref !== docLst.nomineeId);
            if (this.state.refreshIds.length !== refIds.length) {
                let docUpdates = [];
                docUpdates.push(docLst);
                if (this.state.docUpdates) {
                    docUpdates = docUpdates.concat(this.state.docUpdates);
                }

                this.setState({
                    refreshIds: refIds,
                    docUpdates: docUpdates
                })
                // this.componentDidMount();
            }
        }

        // set aria-label on Final Weighted Score filter dropdown (cannot set directly when isSearchable=false)
        if (this.finalWeightedScoreSelectRef.current) {
            var finalWeightedScoreSelectInputRef = this.finalWeightedScoreSelectRef.current.select.inputRef;
            if (!finalWeightedScoreSelectInputRef.getAttribute("aria-label")) {
                finalWeightedScoreSelectInputRef.setAttribute("aria-label", "Filter table by Final Weighted Score");
            }
        }
    }

    refreshDocs(nomineeId, shortPlan, awardKey, applicationNumber) {
        console.log("refreshing: " + nomineeId + ",applicationNumber: " + applicationNumber);

        this.props.refreshDocumentList(nomineeId, shortPlan, awardKey, applicationNumber);

        let refreshingIds = this.state.refreshIds;
        if (!refreshingIds.includes(nomineeId)) {
            refreshingIds.push(nomineeId);
            this.setState(
                {
                    refreshIds: refreshingIds
                }
            )
        }
    }

    convertedData(nominees = [], nomineesDetails = []) {
        if (!Array.isArray(nominees)) {
            return []
        }
        const jsxNominees = nominees.map((nom, index) => {
            const hasComments = Array.isArray(nom.reviewers) ? nom.reviewers.filter(review => review.comment && review.comment.toString().trim()).length > 0 : false;
            const nomGpas = Array.isArray(this.props.gpaTypes) ? this.props.gpaTypes.filter(gpa => !!gpa.field).map((gpa, index) => {
                return {
                    [gpa.field]: nom.hasOwnProperty(gpa.field) ? nom[gpa.field] : '-'
                }
            }) : [];

            const expansionAction = (index) => this.props.updateNomineesDetails({ ...nom, index })
            let match = find(nomineesDetails, detail => detail.nomineeId === nom.nomineeId && detail.displayShortPlan === nom.displayShortPlan)
            if (!match && nomineesDetails) {
                match = nom;
            }
            let fileArray = [];
            let docUpdate = null;
            if (this.state.docUpdates) {
                docUpdate = this.state.docUpdates.find(function (upd) {
                    return upd.nomineeId === nom.nomineeId;
                })
            }
            if (docUpdate && docUpdate.documentData && docUpdate.documentData.uploadedFile) {
                if (Array.isArray(nom.uploadedFile)) {
                    nom.uploadedFile = nom.uploadedFile.concat(docUpdate.documentData.uploadedFile);
                }
                else {
                    nom.uploadedFile = docUpdate.documentData.uploadedFile;
                }
                nom.uploadedFile = uniqBy(nom.uploadedFile, 'documentId');
            }

            /* if (this.props.isAdmin) {
                var refIcon = <i className={this.state.refreshIds.includes(nom.nomineeId) ? "fa fa-refresh fa-spin" : "fa fa-refresh"} aria-hidden="true" onClick={() => this.refreshDocs(nom.nomineeId, nom.shortPlan, this.props.awardKey, nom.applicationNumber)}></i>
            } */
            if (Array.isArray(nom.uploadedFile) && nom.uploadedFile.every(file =>  file.documentId && file.documentId > 0 )) {
                nom.uploadedFile.map((file, index) => {
                    const documentId = file.documentId.toString();
                    return fileArray.push(
                        <div key={documentId}>
                            <Link className='documentFlex' target={documentId} rel="noopener noreferrer" to={{
                                pathname: "/ViewPdf/" + documentId,
                                search: `?name=${nom.name}&plan=${nom.shortPlanDescription || nom.shortPlan}`
                            }}>{file.documentName}
                            </Link>
                        </div>);
                }
                );
            }
            fileArray = <div className='outerRefresh'>{/* {refIcon && refIcon} */}<div /* className={refIcon && 'innerRefresh'} */>{fileArray}</div></div>;
            const finalRankedScore = nom.hasOwnProperty('finalRankedScore') ? nom.finalRankedScore.toString().substring(0, 6) : '-';
            const rankedScoresAreAllPresentForEveryReviewer = (reviewer) => reviewer.rankedScore !== 0 && reviewer.rankedScore !== undefined;
            nom.reviewers = nom.reviewers ? nom.reviewers : [];
            // const hasScoreRangeFlag = ((this.props.isAdmin === true || this.props.showWeightedScore === true) && nom.reviewers.length > 0 && typeof find(nom.reviewers, reviewer => reviewer.validQuintile === false) !== "undefined");
            const hasScoreRangeCheck = ((this.props.isAdmin === true || this.props.showWeightedScore === true) && nom.reviewers.length > 0 && nom.reviewers.every(rankedScoresAreAllPresentForEveryReviewer));
            let hasScoreMinFlag = false;
            const isAdmin = this.props.isAdmin;
            const isAfterMeetingStartTime = this.isAfterMeetingStartTime();
            const isBeforeMeetingEndTime = this.isBeforeMeetingEndTime();

            this.props.scoringCriteria.forEach(criteria => {
                let average = 0;
                let everyReviewerHasDataPresent = true;
            nom.reviewers.forEach(reviewer => {
                if(reviewer[criteria.field]){
                    const value =  Number (reviewer[criteria.field].value)
                    average += value;
                    everyReviewerHasDataPresent = value > 0 ? everyReviewerHasDataPresent : false;
                } else {
                    const value = Number (reviewer.rankedScore)
                    average += value;
                    everyReviewerHasDataPresent = value > 0 ? everyReviewerHasDataPresent : false;

                }
                

                });
                if((isAdmin || (isAfterMeetingStartTime && isBeforeMeetingEndTime) ) && everyReviewerHasDataPresent && average / nom.reviewers.length < criteria.minimumEligibilityScore) {
                    hasScoreMinFlag = true
                }
            });


            let lastEditedByAdmin = false;

            nom.reviewers.forEach(reviewer => {
                if (reviewer.lastEditedBy && !reviewer.lastEditedBy.match(reviewer.email)) {
                    lastEditedByAdmin = true;
                }
            });
            return {
                innerExpandedAction: expansionAction,
                name: <div style={{ wordWrap: "break-word" }}>
                    {nom.name}
                    {/* {hasScoreRangeFlag && <sup><Icon type="exclamation-triangle" color="red" size="xs" className="px-1" ariaLabel="Scores out of assigned ranges." /></sup>} */}
                    {hasScoreRangeCheck && <sup><Icon type="check" color="green" size="xs" className="px-1" ariaLabel="Reviews are complete and in range." /></sup>}
                    {hasScoreMinFlag && <sup><Icon size="xs" className="pl-1" type="info" color="gray" ariaLabel="Category minimum qualifying score not met." /></sup>}
                    {hasComments && <sup><Icon size="xs" className="pl-1" type="commenting-o" color="gray" ariaLabel="Comments available to view." /></sup>}
                    {lastEditedByAdmin && <sup><Icon size="xs" className="pl-1" type="asterisk" color="black" ariaLabel="Admin has made a change on your behalf" /></sup>}

                </div>,
                displayShortPlan: <EllipsisWithTooltip placement="bottom">{nom.displayShortPlan}</EllipsisWithTooltip>,
                ...nomGpas.reduce(function (result, current) {
                    return Object.assign(result, current);
                }, {}),
                finalRankedScore: finalRankedScore,
                expandedContent: (<EvaluationDetails {...match} hideNomineeInfo={false} finalRankedScore={finalRankedScore} hideEvaluation={!this.props.isAdmin && !isAfterMeetingStartTime} reloadAction={expansionAction} nomineeId={nom.nomineeId} scoringCriteria={this.props.scoringCriteria} maskReviewers={!this.props.isAdmin && !isAfterMeetingStartTime} />),
                documents: fileArray
            }
        })

        return jsxNominees
    }
    async calculateFinalRankedScoresWithModal(awardKey) {
        this.setState({ isOpen: true });

        await this.props.calculateFinalRankedScores(awardKey)        
    }

    unsuccessfulScreen(status) {
        const errorScreen = this.props.awardKey ?
            <OSUError ariaLabel="Error getting nominees, click to retry getting nominees" text="Could not fetch nominees for award." actionText="Retry" onClick={() => this.getAllNominees(this.props.awardKey)} />
            : <OSUError ariaLabel="No award found. Navigate to new view for award selection." text="Could not verify your award." actionText="Select Award" onClick={() => this.props.history.push('/nominee-reviews/award-selection')} />;

        //console.log("refreshing status");
        //console.log(this.state.refreshing);
        if (status === 'loading') {
            return <OSULoading text="Loading nominees for award" />
        }
        if (status === '401') {
            Auth.signOut() && redirectToLogOut();
            //return <OSUError ariaLabel="You are logged out. Close your browser and login again." text="You are logged out. Close your browser and login again." actionText="Login" onClick={() => redirectToLogIn()} />
        }

        return errorScreen
    }

    displayAcademicProgramFilter(handleFilter, handleFilterProps, programDropdown) {

        if (window.innerWidth > Globals.MOBILE_MAXIMUM_RESOLUTION) {
            return (<div className="col-3 px-0 d-flex">
                <Select isClearable value={this.props.defaultShortPlanValue} className="w-100 pr-2" aria-label="Filter table by academic program" placeholder="Academic Program" onChange={program => handleFilter({ ...handleFilterProps, filter: program, type: "displayShortPlan" })} options={programDropdown} />
            </div>)
        }
        return undefined

    }

    displayWeightedScoreFilter(handleFilter, handleFilterProps, defaultScoreValue, weightedScoreRanges) {
        if (window.innerWidth > Globals.MOBILE_MAXIMUM_RESOLUTION && this.props.isAdmin) {
            return (<div className="col-4 px-0 d-flex">
                <Select placeholder="Final Ranked Score" ref={this.finalWeightedScoreSelectRef} isClearable value={defaultScoreValue} isSearchable={false} className="w-100 pr-2" onChange={range => handleFilter({ ...handleFilterProps, filter: range, type: "rubric" })} options={weightedScoreRanges} />
            </div>)
        }
        return undefined;
    }

    searchbarStyle() {
        return window.innerWidth > Globals.MOBILE_MAXIMUM_RESOLUTION ? "col-5 pl-0 pr-2" : "pl-0 pr-2"
    }

    setDataIndex = dataIndex => {
        this.setState({ dataIndex });
    };



    setRowsPerPage = rowsPerPage => {
        this.setState({ rowsPerPage });
    };

    expandAllContent() {
        this.setState({ expandAll: true, collapseAll: false });
    }

    collapseAllContent() {
        this.setState({ expandAll: false, collapseAll: true });
    }

    toggle = () => {
        this.setState({
            isOpen: !this.state.isOpen
        });
        window.location.reload();
    }

    render() {
        const { systemAward, handleFilter, nominees, nomineesCsvHeaders, nomineesCsvData, nomineesDetails, status, defaultScoreValue, gpaTypes, filters, query, programDropdown, weightedScoreRanges, addAwardNomineesFilter, searchAwardNominees } = this.props;
        if(this.state.updatedData) {
            
        }
        const data = this.state.updatedData ?  this.convertedData(this.state.updatedData, nomineesDetails) : this.convertedData(nominees, nomineesDetails);
        const rowsPerPage = this.state ? this.state.rowsPerPage : this.props.rowsPerPage;
        const dataIndex = this.state.dataIndex;
        const handleFilterProps = {
            activeFilters: filters,
            filterHandler: addAwardNomineesFilter
        };

        if (this.state.expandAll || this.state.collapseAll) {
            this.setState({
                expandAll: false,
                collapseAll: false
            });
        }

        if (status !== 'success') {
            if (!this.state.refreshing) {
                if (this.state.refreshing) {
                    this.setState({
                        refreshing: false
                    })
                }
                return this.unsuccessfulScreen(status)
            }
        }

        const chunkedData = data && data.length > 0 ?
            chunk(data, rowsPerPage || data.length) : [{}];

        let icons = (this.props.isAdmin || this.props.showWeightedScore) ?
            [
                { type: "check", ariaLabel: "Reviews are complete and in range.", color: "green", size: "xs" },
                // { type: "exclamation-triangle", ariaLabel: "Scores out of assigned ranges.", color: "red", size: "xs" },
                { type: "info", ariaLabel: "Category minimum qualifying score not met.", color: "gray", size: "xs" },
                { type: "commenting-o", ariaLabel: "Comments available to view.", color: "gray", size: "xs" },
                { type: "asterisk", ariaLabel: "Reviewed by admin.", color: "black", size: "xs" }

            ] :
            [
                { type: "commenting-o", ariaLabel: "Comments available to view.", color: "gray", size: "xs" },
                { type: "asterisk", ariaLabel: "Reviewed by admin.", color: "black", size: "xs" }
            ];

        const searchbarLabel = `Search by Nominee ID or Name${this.props.isAdmin ? " or Reviewer Name.#" : ""}`;

        let finalRankedScoresAreDisabled = (nominees.length === 0);
        nominees.forEach(nominee => {
            if (!nominee.reviewers || nominee.reviewers.length === 0) {
                finalRankedScoresAreDisabled = true;
            } else {
                nominee.reviewers.forEach(reviewer => {
                    if (!reviewer.rankedScore) {
                        finalRankedScoresAreDisabled = true;
                    }
                });
            }
        });


        let bodyText = "Error: There was an error calculating one or more final ranked scores, please try again";
        if (this.props.status === "success" && this.props.nominees[0]) {
            bodyText = "Successfully calculated Final Ranked Scores";
        }

                //if nominees final weighted scores don't coincide with ranked scores, we need to recalculate


        let staleReviewerCount = nominees.reduce((accumulator, currentValue) => {
            let total = 0;
            currentValue.reviewers.forEach(reviewer => {
                total += Number (reviewer.rankedScore);
            });
            const reviewerCount = currentValue.reviewers.length;

            if((total / reviewerCount) > (currentValue.finalRankedScore + .01) || (total / reviewerCount) < (currentValue.finalRankedScore - .01)) {
                accumulator += 1;
            }
            return accumulator;
        }, 0);
        let calculateFinalRankedScoresButtonLabel = "Calculate Final Ranked Scores";
        let finalRankedScoresButtonColor = "green";

        if(this.state.scoresNeedUpdated && staleReviewerCount > 0){
            calculateFinalRankedScoresButtonLabel = "Calculate Final Ranked Scores (" + staleReviewerCount + " SCORES NEED UPDATED)";
            finalRankedScoresButtonColor = "red";
        } else if(staleReviewerCount > 0 && nominees[0].finalRankedScore) {
            this.setState({scoresNeedUpdated:true});
        }
        return <div>
            <IconLegend icons={icons} />
            <Body2 className="mb-1 align-items-bottom"><span className="pt-4">Filter Content &nbsp;&nbsp;&thinsp; | </span>
                <OSUButton uppercase={false} variant="body2" ariaLabel="Clear all filters" link onClick={() => this.props.clearAllFilters(filters, addAwardNomineesFilter, searchAwardNominees)}>Clear All</OSUButton>
                <div className="float-right"><OSUButton uppercase={false} variant="body2" ariaLabel="Collapse all inner tables" link onClick={() => this.collapseAllContent()}>Collapse All</OSUButton></div>

                <div className="float-right"><OSUButton uppercase={false} variant="body2" ariaLabel="Expand all inner tables" link onClick={() => this.expandAllContent()}>Expand All</OSUButton>|</div></Body2>
            <Row className="no-gutters align-items-center">
                <InputGroup className={this.searchbarStyle()}>
                    <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                            <Icon type="search" color="gray" />
                        </InputGroupText>
                    </InputGroupAddon>
                    <Input type="text" aria-label={searchbarLabel} placeholder={searchbarLabel} value={query} onChange={(e) => searchAwardNominees(e.target.value)} />
                </InputGroup>
                {this.displayAcademicProgramFilter(handleFilter, handleFilterProps, programDropdown)}
                {this.displayWeightedScoreFilter(handleFilter, handleFilterProps, defaultScoreValue, weightedScoreRanges)}
            </Row>

            <Modal scrollable fade isOpen={this.state.isOpen} onClosed={() => this.setState({ isOpen: false })}>
                <ModalHeader toggle={this.toggle}>
                    Calculate Final Ranked Scores
                        </ModalHeader>
                <ModalBody>
                    {this.state.assignPacketsToReviewersProcessing ?
                        (<div>
                            {!this.props.assignPacketsToReviewersProcessingMessage ?
                                <OSULoading text="Processing... This might take long time to process... Hang on there..." /> : <OSULoading text={this.props.assignPacketsToReviewersProcessingMessage} />}
                        </div>
                        ) :
                        (<div>
                            {this.props.assignPacketsToReviewersError &&
                                <Alert color="danger" toggle={() => this.props.resetAssignPacketsToSelectedReviewersFlags()}>
                                    There was an error assigning packets
                                    {!this.props.assignPacketsToReviewersErrorMessage ?
                                        (<span>.<br />Please retry to see if it resolves the issue.</span>) :
                                        (<span>:<br />{this.props.assignPacketsToReviewersErrorMessage}</span>)
                                    }
                                </Alert>
                            }
                            <Body2>{bodyText}</Body2>
                        </div>)
                    }
                </ModalBody>
                <ModalFooter>
                    {!this.props.assignPacketsToReviewersProcessing && [
                        <OSUButton ariaLabel="No" key="no" color="gray" solid uppercase={false} className="mr-1" onClick={this.toggle}>Okay!</OSUButton>,
                    ]}
                </ModalFooter>
            </Modal>

            {this.props.isAdmin &&
                <div>

                    <Typography variant={'button'} className="mt-3 mr-2 text-right">
                        <OSUButton color={finalRankedScoresButtonColor} disabled={finalRankedScoresAreDisabled} ariaLabel={calculateFinalRankedScoresButtonLabel} onClick={() => this.calculateFinalRankedScoresWithModal(systemAward.pk)}>{calculateFinalRankedScoresButtonLabel}</OSUButton>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                    <CSVLink aria-label="Download table as CSV file" filename={`${systemAward.name} Nominees.csv`} headers={nomineesCsvHeaders} data={nomineesCsvData}>
                            <Icon color="blue" className="mr-2" type="download" />Download CSV
                    </CSVLink>
                    </Typography>
                </div>
            }
            <PaginationWrapper
                persist
                totalPageCount={chunkedData.length}
                updateDataIndex={this.setDataIndex}
                updateRowsPerPage={this.setRowsPerPage}
                dataIndex={dataIndex}
                rowsPerPageOptions={[10, 20, 30]}
                rowsPerPage={rowsPerPage}
                resultsData={{ shownResults: data.length, totalResults: data.length }}
                showOptionalCount={true}
            >
                <Table multiOpen sortable expandable expandAll={this.state.expandAll} collapseAll={this.state.collapseAll}
                    paginationData={{
                        rowsPerPage: rowsPerPage || 9999,
                        dataIndex: dataIndex,
                    }}
                    sortKeys={["name", "displayShortPlan", "cumGpa", "finalRankedScore"]}
                    hover={false} headerVariant="subtitle2" 
                    data={data} 
                    dataKeys={this.dataKeys(gpaTypes)} />
            </PaginationWrapper>
        </div>
    }
}

NomineesContent.defaultProps = {
    nominees: [],
    nomineesDetails: [],
    gpaTypes: [],
    dataIndex: 0
};

NomineesContent.propTypes = {
    nominees: PropTypes.array,
    nomineesDetails: PropTypes.array,
    gpaTypes: PropTypes.array,
    awardKey: PropTypes.string.isRequired,
    status: PropTypes.string,
    filters: PropTypes.array,
    query: PropTypes.string,
    scoringCriteria: PropTypes.array,
    programDropdown: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.shape({
            singleSelect: PropTypes.bool,
            type: '',
            key: ''
        })
    })),
    defaultScoreValue: PropTypes.array,
    defaultShortPlanValue: PropTypes.array
};

export default NomineesContent