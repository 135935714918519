import React, { Fragment, useContext } from "react";
import { Typography } from "osu-react-components";
import Select from "react-select/";
import { FormGroup, Label } from "reactstrap";
import { PETITION_STATUS_OPTIONS } from '../constants';
import { NOMINEE_FILE_NAMES } from "../../constants";
import { FormEnvironmentContext } from "../context";
import ManageFiles from "./ManageFiles";

function EligibilityPetition(props = {}) {
  const { gpaPetitionApproval, setGpaPetitionApproval, setPetitionFiles, nomineeName } = props;
  const { petitionDisclaimer, isAdmin, readonly } = useContext(FormEnvironmentContext);
  const value = PETITION_STATUS_OPTIONS.find(option => option.value === gpaPetitionApproval)
  
  return (
    <Fragment>
      <Typography>Petition Required</Typography>
      {petitionDisclaimer && (
        <Typography variant="body2" className="py-2">
          {petitionDisclaimer}
        </Typography>
      )}
      <div className="d-flex">
        <ManageFiles updateFiles={(values) => setPetitionFiles(values)} label={NOMINEE_FILE_NAMES.petition} name={nomineeName} type="petition-files" id="petition-files" />
      </div>
      <div className="d-flex flex-column">
        <FormGroup className="d-flex flex-fill align-items-center">
          <Label className="mr-2 mb-0" for="petition-approval-dropdown">Petition Approval Status:</Label>
            <Select
              className="w-50"
              value={value}
              isDisabled={!isAdmin || readonly}
              id="petition-approval-dropdown"
              onChange={val => setGpaPetitionApproval(val.value)}
              options={PETITION_STATUS_OPTIONS}
            />
        </FormGroup>
      </div>
    </Fragment>
  );
}

export default EligibilityPetition;
