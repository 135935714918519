import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { transformStudentData } from "../transform";
import NomineeCardStudent from "../components/Student";
import { refreshNominee } from "../../../actions-index";

const mapStateToProps = (state = {}, props = {}) => {
    const { nomineeCard = {}, awardInfo = { data: { awardResultInfo: { awardOptions: [] } } }, refreshNomineeState, refreshedNominee } = state;
    const { data = {} } = awardInfo
    const { studentStatus } = data

    let studentData = {}
    let heading = '', subheading = '', program = '', hasNominee = false, awardKey = null, nomineeId = null, shortPlan = null;
    if(nomineeCard.record && nomineeCard.record.name) {
        if(refreshedNominee !== null) {
            studentData = transformStudentData(refreshedNominee, studentStatus);
        } else {
            studentData = transformStudentData(nomineeCard.record, studentStatus);
        }
        program += studentData.program
        heading += studentData.name
        hasNominee = true;
        awardKey = nomineeCard.record.awardKey;
        nomineeId = nomineeCard.record.nomineeId;
        shortPlan = nomineeCard.record.shortPlan;
    }
    return {
        ...studentData,
        heading,
        program,
        subheading,
        refreshNomineeState,
        hasNominee,
        awardKey,
        nomineeId,
        shortPlan
    };
};

const mapDispatchToProps = (dispatch) => ({
    refreshNominee: (awardKey, nomineeId, shortPlan) => dispatch(refreshNominee(awardKey, nomineeId, shortPlan))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NomineeCardStudent));