import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { Alert } from "reactstrap";
import { Body2, OSULoading } from "osu-react-components";

export default class Nominations extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageTitle: "Fellowships & Awards"
        }
    }

    componentWillMount() {
        document.title = this.state.pageTitle;
    }

    componentDidMount() {
        this.props.setA11yMessage(`Navigated to the ${this.state.pageTitle} page`);
        this.pageContent.focus();
    }

    renderPageContent = () => {
        const { isAdmin, isNominator, nominatorPrograms: programs, userIsLoading } = this.props;
        const { list:currentAwards = [], status:currentAwardsStatus = "" } = this.props.currentAwards ? this.props.currentAwards : [] ;
        const isLoading = (currentAwardsStatus === "loading" || userIsLoading);
        const isAuthorized = (isAdmin || isNominator);

        if(isLoading) {
            return (<OSULoading dataTestId="loading" text="Loading..." />);
        } else if(!isAuthorized) {
            return (
                <Fragment>
                    <h2 data-testid="unauthorized-header" className="heading5 mb-0">Unauthorized</h2>
                    <Body2>You are not authorized to view this page.</Body2>
                </Fragment>
            );
        } else {
            const linkDisabled = !(currentAwardsStatus === "success" && currentAwards.length > 0);
            const linkOnClick = (e) => { if(linkDisabled) e.preventDefault() };
            return (
                <Fragment>
                    <Alert color="info" data-testid="no-competitions-alert" isOpen={linkDisabled}>
                        There are currently no competitions.
                    </Alert>
                    <h2 data-testid="recruiting-competitions-header" className="heading5 mb-0">Recruiting Fellowship Competitions:</h2>
                    <Body2>Select your academic program to nominate new admits to a Fellowship competition.</Body2>
                    {this.props.isAdmin ? 
                        (<div className="mt-2">
                            <Link data-testid="recruiting-competitions-link" to="/nominations/competitions/recruiting/ADMIN"
                                aria-label="View Recruiting Fellowship Competitions for All Nominees" aria-disabled={linkDisabled}
                                onClick={linkOnClick}>ADMIN - All Nominees</Link>
                        </div>) : 
                        (<Fragment>
                            {programs.map((program, index) => {
                                return (
                                    <div key={`program${index + 1}`} className="mt-2">
                                        <Link data-testid={`${program}-recruiting-competitions-link`} to={`/nominations/competitions/recruiting/${program}`}
                                            aria-label={`View Recruiting Fellowship Competitions for ${program}`} aria-disabled={linkDisabled}
                                            onClick={linkOnClick}>{program}</Link>
                                    </div>
                                );
                            })}
                        </Fragment>)
                    }
                    
                    <h2 data-testid="competitions-header" className="heading5 mt-4">Current Student Competitions:</h2>
                    {this.props.isAdmin ? 
                        (<div className="mt-2">
                            <Link data-testid="competitions-link" to="/nominations/competitions/currentstudent/ADMIN" aria-label="View Current Student Competitions for All Nominees"
                                aria-disabled={linkDisabled} onClick={linkOnClick}>ADMIN - All Nominees</Link>
                        </div>) :
                        (<Fragment>
                            {programs.map((program, index) => {
                                return (
                                    <div key={`program${index + 1}`} className="mt-2">
                                        <Link data-testid={`${program}-competitions-link`} to={`/nominations/competitions/currentstudent/${program}`}
                                            aria-label={`View Current Student Competitions for ${program}`} aria-disabled={linkDisabled}
                                            onClick={linkOnClick}>{program}</Link>
                                    </div>
                                );
                            })}
                        </Fragment>)
                    }
                </Fragment>
            );
        }
    }

    render() {
        return (
            <div data-testid="pageContent" ref={(el) => { this.pageContent = el; }} tabIndex="-1" aria-labelledby="pageHeader">
                <h1 id="pageHeader" data-testid="pageHeader" className="heading5 sr-only">{this.state.pageTitle}</h1>
                {this.renderPageContent()}
            </div>
        );
    }
}