import { API } from "aws-amplify";
import { buildAction } from "../util/util";
import { ACTION_STATE_ERROR, ACTION_STATE_LOADING, ACTION_STATE_SUCCESS } from "../util/constants";
import { CREATE_TOKEN_DATA, CREATE_TOKEN_STATE, CREATE_ACCEPTANCE_DATA, CREATE_ACCEPTANCE_STATE } from "../actions-index";

export function validateToken(token) {
    return dispatch => {
        dispatch(buildAction(CREATE_TOKEN_STATE, ACTION_STATE_LOADING));

        API.post(process.env.REACT_APP_GRAD_AWARD_API_NAME + "-public-validate", "", { body: { token: token } })
            .then(response => {
                console.log("response: ", response);
                dispatch(buildAction(CREATE_TOKEN_STATE, ACTION_STATE_SUCCESS));
                dispatch(buildAction(CREATE_TOKEN_DATA, { status: response.status, ...response.data }));
            })
            .catch(error => {
                console.log("Token validation error: ", error.response);
                dispatch(buildAction(CREATE_TOKEN_STATE, ACTION_STATE_ERROR));
                if(error.response) 
                    dispatch(buildAction(CREATE_TOKEN_DATA, {  ...error.response.data, status: error.response.data.status, statusCode: error.response.status}));
            });
    };
}

export function submitAcceptance(token, acceptance) {
    return dispatch => {
        dispatch(buildAction(CREATE_ACCEPTANCE_STATE, ACTION_STATE_LOADING));

        API.post(process.env.REACT_APP_GRAD_AWARD_API_NAME + "-public-submit", "", { body: { token: token, acceptance: acceptance } })
            .then(response => {
                console.log("response: ", response);
                dispatch(buildAction(CREATE_ACCEPTANCE_STATE, ACTION_STATE_SUCCESS));
                dispatch(buildAction(CREATE_ACCEPTANCE_DATA, { status: response.status, ...response.data }));
            })
            .catch(error => {
                console.log("Token validation error: ", error.response);
                dispatch(buildAction(CREATE_ACCEPTANCE_STATE, ACTION_STATE_ERROR));
                if(error.response) 
                    dispatch(buildAction(CREATE_ACCEPTANCE_DATA, {  ...error.response.data, status: error.response.data.status, statusCode: error.response.status}));
            });
    };
}