import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import ReviewProcess from "../components";
import { clearAllFilters, handleFilter } from "../util";
import { dismissReviewAlert } from "../../actions-index";

const mapStateToProps = (state, ownProps) => {
    return {
        reviewAlertDismissed: state.reviewAlertDismissed,
        systemAward: state.systemAward,
        handleFilter: handleFilter,
        clearAllFilters: clearAllFilters
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        dismissReviewAlert: () => dispatch(dismissReviewAlert())
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ReviewProcess));
