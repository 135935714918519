import { API } from "aws-amplify";
import { CREATE_AWARD_KEY, CREATE_AWARD_STATE, DELETE_AWARD_STATE, GRADUATE_PROGRAMS, GRADUATE_PROGRAMS_STATE, UPDATE_AWARD_STATE } from "../actions-index";
import { ACTION_STATE_ERROR, ACTION_STATE_LOADING, ACTION_STATE_SUCCESS, API_RESPONSE_MESSAGE_ERROR } from "../util/constants";
import { buildAction, handleUnauthenticated } from "../util/util";

export function createAward(award) {
    return dispatch => {
        dispatch(buildAction(CREATE_AWARD_STATE, ACTION_STATE_LOADING));
        
        API.post(process.env.REACT_APP_GRAD_AWARD_API_NAME, "/award", { body: award })
        .then(response => {
            if(response.message === API_RESPONSE_MESSAGE_ERROR) {
                dispatch(buildAction(CREATE_AWARD_STATE, ACTION_STATE_ERROR));
            } else {
                dispatch(buildAction(CREATE_AWARD_STATE, ACTION_STATE_SUCCESS));
                dispatch(buildAction(CREATE_AWARD_KEY, response.data.awardKey));
            }
        })
        .catch(error => {
            console.error("Create Award Error: ", error);
            handleUnauthenticated(error, () => {
                dispatch(buildAction(CREATE_AWARD_STATE, ACTION_STATE_ERROR));
            });
        });
    };
}

export function deleteAward(awardKey) {
    return dispatch => {
        dispatch(buildAction(DELETE_AWARD_STATE, ACTION_STATE_LOADING));

        API.del(process.env.REACT_APP_GRAD_AWARD_API_NAME, `/award/${window.btoa(awardKey)}`)
        .then(response => {
            if(response.message === API_RESPONSE_MESSAGE_ERROR) {
                dispatch(buildAction(DELETE_AWARD_STATE, ACTION_STATE_ERROR));
            } else {
                dispatch(buildAction(DELETE_AWARD_STATE, ACTION_STATE_SUCCESS));
            }
        })
        .catch(error => {
            console.error("Delete Award Error: ", error);
            handleUnauthenticated(error, () => {
                dispatch(buildAction(DELETE_AWARD_STATE, ACTION_STATE_ERROR));
            });
        });
    };
}

export function updateAward(award) {
    return dispatch => {
        dispatch(buildAction(UPDATE_AWARD_STATE, ACTION_STATE_LOADING));

        API.put(process.env.REACT_APP_GRAD_AWARD_API_NAME, `/award/${window.btoa(award.pk)}`, { body: award })
        .then(response => {
            if(response.message === API_RESPONSE_MESSAGE_ERROR) {
                dispatch(buildAction(UPDATE_AWARD_STATE, ACTION_STATE_ERROR));
            } else {
                dispatch(buildAction(UPDATE_AWARD_STATE, ACTION_STATE_SUCCESS));
            }
        })
        .catch(error => {
            console.error("Update Award Error: ", error);
            handleUnauthenticated(error, () => {
                dispatch(buildAction(UPDATE_AWARD_STATE, ACTION_STATE_ERROR));
            });
        });
    };
}

export function getGraduatePrograms() {
    return dispatch => {
        dispatch(buildAction(GRADUATE_PROGRAMS_STATE, ACTION_STATE_LOADING));
        API.get(process.env.REACT_APP_GRAD_AWARD_API_NAME, "/getGraduatePrograms")
        .then(response => {
            if(response.message === API_RESPONSE_MESSAGE_ERROR) {
                dispatch(buildAction(GRADUATE_PROGRAMS_STATE, ACTION_STATE_ERROR));
            } else {
                dispatch(buildAction(GRADUATE_PROGRAMS_STATE, ACTION_STATE_SUCCESS));
                dispatch(buildAction(GRADUATE_PROGRAMS, response.data));
            }
        })
        .catch(error => {
            console.error("Get Graduate Programs Error: ", error);
            handleUnauthenticated(error, () => {
                dispatch(buildAction(CREATE_AWARD_STATE, ACTION_STATE_ERROR));
            });
        });
    };
}