import React, { Component } from "react";
import { Alert, Card, CardBody, CardTitle, Input, Label } from "reactstrap";
import Select from "react-select";
import Moment from "moment";
import { Body2, Caption, Heading5, Icon, OSUButton, OSUError, OSULoading, Subtitle1, Subtitle2, Table } from "osu-react-components";
import NavigationPrompt from "../../Common/NavigationPrompt/components";
import _ from "lodash";
import * as Constants from "../constants";
import {PAGE_TITLE_POSTFIX} from "../../constants";

const INVITE_DATE_FORMAT = "MM/DD/YY";

export default class ReviewerDetails extends Component {
    constructor(props) {
        super(props);
        const locationState = this.props.location.state || {};
        const breadcrumb = locationState.breadcrumb || { path: "/reviewer-management/all-reviewers", text: "All Reviewers" };
        const match = props.match ? props.match : {};
        const params = match.params ? match.params : {};
        this.state = {
            pageTitle: "Reviewer Details",
            breadcrumb,
            emplid: params.emplid,
            isAddReviewer: false,
            hasUnsavedChanges: false,
            awardStatusOptions: [
                { label: Constants.AWARD_STATUS_MAP[Constants.AWARD_STATUS_KEY_NONE], value: Constants.AWARD_STATUS_KEY_NONE },
                { label: Constants.AWARD_STATUS_MAP[Constants.AWARD_STATUS_KEY_INVITED], value: Constants.AWARD_STATUS_KEY_INVITED },
                { label: Constants.AWARD_STATUS_MAP[Constants.AWARD_STATUS_KEY_DECLINED], value: Constants.AWARD_STATUS_KEY_DECLINED },
                { label: Constants.AWARD_STATUS_MAP[Constants.AWARD_STATUS_KEY_ACCEPTED], value: Constants.AWARD_STATUS_KEY_ACCEPTED },
                { label: Constants.AWARD_STATUS_MAP[Constants.AWARD_STATUS_KEY_SELECTED], value: Constants.AWARD_STATUS_KEY_SELECTED },
                { label: Constants.AWARD_STATUS_MAP[Constants.AWARD_STATUS_KEY_ALTERNATE], value: Constants.AWARD_STATUS_KEY_ALTERNATE }
            ],
            currentCommitteesDataKeys: [
                { key: "name", label: "Current Committee(s)", width: 24 },
                { key: "recommendedBy", label: "Recommended By", width: 22, type: "text" },
                { key: "status", label: "Status", width: 30, type: "select" },
                { key: "invite", label: "Invite", width: 24, type: "checkbox" }
            ],
            previousCommitteesDataKeys: [
                { key: "name", label: "Previous Committee(s)", width: 40 },
                { key: "recommendedBy", label: "Recommended By", width: 32 },
                { key: "status", label: "Status", width: 28 }
            ],
            awardsProcessed: false,
            currentAwards: [],
            currentAwardsStatusSelectedOptions:[],
            currentAwardInviteSelection: "",
            currentAwardInviteEmailSubject: "",
            currentAwardInviteEmailBody: "",
            availableAwardSelectedOption: null,
            previousAwards: [],
            comments: ""
        }
    }

    componentWillMount() {
        document.title = this.state.pageTitle + PAGE_TITLE_POSTFIX;
    }

    componentDidMount() {
        this.props.setA11yMessage(`Navigated to the ${this.state.pageTitle} page`);
        if(this.pageContent) this.pageContent.focus();
        this.loadData();
        this.props.updateBreadcrumbTrail([
            this.state.breadcrumb,
            { text: `${this.state.pageTitle} - ${this.state.emplid}`, active: true }
        ]);
    }

    componentDidUpdate(prevProps) {
        if(prevProps.reviewer === null && this.props.reviewer !== null) { // recieved reviewer
            // If the reviewer is new (no DB records), then the reviewer is being added (Add Reviewer).
            // The reviewer must be saved to be added, so enable the Save button by indicating that there are unsaved changes.
            const isAddReviewer = this.props.reviewer.isNew === true;
            this.setState({ isAddReviewer, hasUnsavedChanges: isAddReviewer });
        }
        
        if(!this.state.awardsProcessed && this.props.reviewer && this.props.allAwards) { // data is loaded - process awards
            this.processAwards();
        }

        if(prevProps.inviteReviewerToAwardProcessing === false && this.props.inviteReviewerToAwardProcessing === true) { // send email processing started - focus on loader
            if(this.sendEmailInviteProcessingIcon) this.sendEmailInviteProcessingIcon.focus();
        }

        if(prevProps.inviteReviewerToAwardProcessing === true && this.props.inviteReviewerToAwardProcessing === false) { // send email processing complete - focus on alerts
            if(this.alerts) this.alerts.focus();
        }

        if(prevProps.inviteReviewerToAwardSuccess === false && this.props.inviteReviewerToAwardSuccess === true) { // send email successful - set invite date, clear email fields
            const state = { currentAwardInviteSelection: "", currentAwardInviteEmailSubject: "", currentAwardInviteEmailBody: "" };
            if(this.props.inviteReviewerToAwardDate !== null) {
                const awardKey = this.state.currentAwardInviteSelection;
                state.currentAwards = this.state.currentAwards.map(currentAward => {
                    if(awardKey === currentAward.awardKey) {
                        currentAward.inviteDate = this.props.inviteReviewerToAwardDate;
                    }
                    return currentAward;
                });
            }
            this.setState(state);
        }

        if(prevProps.saveReviewerProcessing === false && this.props.saveReviewerProcessing === true) { // save processing started - focus on loader
            if(this.saveProcessingIcon) this.saveProcessingIcon.focus();
        }

        if(prevProps.saveReviewerProcessing === true && this.props.saveReviewerProcessing === false) { // save processing complete - focus on alerts
            if(this.alerts) this.alerts.focus();
        }

        if(prevProps.saveReviewerSuccess === false && this.props.saveReviewerSuccess === true) { // save successful - now no unsaved changes, add reviewer no longer applies, no longer any new current awards
            const currentAwards = this.state.currentAwards.map(currentAward => {
                if(currentAward.isNew === true) {
                    currentAward.isNew = false;
                }
                return currentAward;
            });
            this.setState({ currentAwards, isAddReviewer: false, hasUnsavedChanges: false });
        }
    }

    componentWillUnmount() {
        this.props.updateBreadcrumbTrail([]);
        this.props.resetInviteReviewerToAwardState();
        this.props.resetSaveReviewerState();
    }

    loadData = () => {
        this.props.findGraduateFacultyByEmplid(this.state.emplid);
        this.props.getAllAwards();
    }

    processAwards = () => {
        const currentAwards = [];
        const previousAwards = [];
        _.forEach(this.props.reviewer.awards, award => {
            const currentAward = _.find(this.props.allAwards.currentAwards, ["pk", award.awardKey]);
            if (currentAward) {
                currentAwards.push({
                    awardKey: award.awardKey,
                    name: currentAward.name,
                    recommendedBy: (award.recommendedBy === null ? "" : award.recommendedBy), // input cannot handle null
                    status: award.status,
                    inviteDate: award.inviteDate,
                    inviteSubject: (_.isNil(currentAward.reviewInviteSubject) ? null : currentAward.reviewInviteSubject),
                    inviteBody: (_.isNil(currentAward.reviewInviteBody) ? null : currentAward.reviewInviteBody)
                });
            } else {
                const previousAward = _.find(this.props.allAwards.awards, ["pk", award.awardKey]);
                if (previousAward) {
                    if(award.status !== Constants.AWARD_STATUS_KEY_NONE) { // filter NONE status
                        previousAwards.push({
                            awardKey: award.awardKey,
                            name: previousAward.name,
                            recommendedBy: award.recommendedBy,
                            status: award.status
                        });
                    } else {
                        console.log(`Filtering award "${award.awardKey}"`);
                    }
                } else {
                    console.error(`Award not found: "${award.awardKey}"`);
                }
            }
        });
        this.setState({ awardsProcessed: true, currentAwards, previousAwards });
    }

    sendEmailInvite = (currentAward) => {
        this.props.inviteReviewerToAward(this.props.reviewer.emplid, currentAward.pk, this.props.reviewer.email, this.state.currentAwardInviteEmailSubject, this.state.currentAwardInviteEmailBody);
    }

    render() {
        const message = this.state.isAddReviewer === true ? 
            "The reviewer will not be added to the list of reviewers until the Save button is clicked. " + 
                "Please confirm that you want to leave the page." :
            "The reviewer has unsaved changes which will not be applied until the Save button is clicked. " +
                "Please confirm that you want to leave the page.";
        return (
            <div ref={(el) => { this.pageContent = el; }} tabIndex="-1" aria-label={this.state.pageTitle}>
                {this.props.reviewer &&
                    <NavigationPrompt prompt={this.state.hasUnsavedChanges} history={this.props.history} header="Unsaved Changes" message={message} cancelButtonLabel="Cancel" confirmButtonLabel="Confirm" />
                }
                {this.renderPageContent()}
            </div>
        );
    }

    renderPageContent = () => {
        if(this.props.reviewerProcessing === true || this.props.allAwardsLoading === true) {
            return (<OSULoading dataTestId="reviewerLoadingIcon" text="Loading Reviewer..." />);
        } else if(this.props.reviewerError === true || this.props.allAwardsError === true || !this.props.allAwards) {
            return (<OSUError dataTestId="reviewerErrorMessage" ariaLabel="Reload Reviewer" text="Reviewer cannot be loaded at this time." onClick={this.loadData} />);
        } else if(!this.props.reviewer) {
            return (<OSUError dataTestId="reviewerNotFoundMessage" ariaLabel="Go to All Reviewers" text="Reviewer cannot be found." actionText="Go to All Reviewers" path={`/reviewer-management/all-reviewers`} />);
        } else {
            const Alerts = (
                <div data-testid="alerts" ref={(el) => {this.alerts = el;}} tabIndex="-1">
                    <Alert data-testid="sendEmailInviteSuccessAlert" color="success" isOpen={this.props.inviteReviewerToAwardSuccess === true} toggle={this.props.resetInviteReviewerToAwardState}>
                        <Subtitle2 className="alert-heading">Send Email Invite</Subtitle2>
                        <p>The email invite has been successfully sent to the reviewer.</p>
                    </Alert>
                    <Alert data-testid="sendEmailInviteErrorAlert" color="danger" isOpen={this.props.inviteReviewerToAwardError === true} toggle={this.props.resetInviteReviewerToAwardState}>
                        <Subtitle2 className="alert-heading">Send Email Invite</Subtitle2>
                        <p>An error occurred while sending the email invite to the reviewer. Please retry to see if it resolves the issue.</p>
                    </Alert>
                    <Alert data-testid="saveReviewerSuccessAlert" color="success" isOpen={this.props.saveReviewerSuccess === true} toggle={this.props.resetSaveReviewerState}>
                        <Subtitle2 className="alert-heading">Save Reviewer</Subtitle2>
                        <p>The reviewer has been successfully saved.</p>
                    </Alert>
                    <Alert data-testid="saveReviewerErrorAlert" color="danger" isOpen={this.props.saveReviewerError === true} toggle={this.props.resetSaveReviewerState}>
                        <Subtitle2 className="alert-heading">Save Reviewer</Subtitle2>
                        <p>An error occurred while saving the reviewer. Please retry to see if it resolves the issue.</p>
                    </Alert>
                    <Alert data-testid="saveReviewerIncompleteAlert" color="danger" isOpen={this.props.saveReviewerIncomplete === true} toggle={this.props.resetSaveReviewerState}>
                        <Subtitle2 className="alert-heading">Save Reviewer</Subtitle2>
                        <p>Due to an error, the reviewer was only partially saved.  Retry to see if it resolves the issue or refresh the page to see the partial updates.</p>
                    </Alert>
                </div>
            );
            return (
                <div data-testid="pageContent">
                    {Alerts}
                    <Card>
                        <CardTitle className="rounded-top mb-0">
                            <Subtitle1 className="mt-2 ml-4">Reviewer</Subtitle1>
                            <Heading5 dataTestId="pageHeader" id="pageHeader" className="ml-4">
                                {`${this.props.reviewer.firstName} ${this.props.reviewer.middleInitial ? this.props.reviewer.middleInitial + ' ' : ''}${this.props.reviewer.lastName}`}
                                {this.props.reviewer.active === false &&
                                    <span data-testid="inactive" className="ml-1 text-danger">(inactive)</span>
                                }
                            </Heading5>
                            <hr className="mt-2 mb-0" />
                        </CardTitle>
                        <CardBody className="px-4" style={{ minHeight: "37.9rem" }}>
                            {this.renderReviewerInfo()}
                            <hr />
                            <div data-testid="saveReviewerLoadingIcon" ref={(el) => { this.saveProcessingIcon = el; }} tabIndex="-1" className={`mb-4 ${this.props.saveReviewerProcessing ? "" : "d-none"}`}>
                                <OSULoading text="Saving Reviewer..." />
                            </div>
                            <div data-testid="editableFields" className={this.props.saveReviewerProcessing ? "d-none" : ""}>
                                {this.renderCurrentCommittees()}
                                {this.renderAvailableAwards()}
                                {this.renderComments()}
                                <div className="float-right mb-2">
                                    <OSUButton solid color="blue" ariaLabel="Save Reviewer" 
                                        disabled={this.state.currentAwardInviteSelection !== "" || this.state.hasUnsavedChanges === false} 
                                        onClick={this.saveReviewer}>Save</OSUButton>
                                </div>
                            </div>
                            {this.renderPreviousCommittees()}
                        </CardBody>
                    </Card>
                </div>
            );
        }
    }

    renderReviewerInfo = () => {
        return (
            <div data-testid="reviewerInfo" className="d-flex">
                <div className="flex-column flex-fill flex-wrap">
                    <section className="section-item pb-2 pb-md-0">
                        <Caption color="gray">Employee ID</Caption>
                        <Body2 dataTestId="employeeId" className="d-flex flex-column">{this.props.reviewer.emplid}</Body2>
                    </section>
                    <section className="section-item pb-2 pb-md-0">
                        <Caption color="gray">Email</Caption>
                        <Body2 dataTestId="email" className="d-flex flex-column">
                            <a href={`mailto:${this.props.reviewer.email}`} aria-label={`Send email to ${this.props.reviewer.email}`}
                                className="word-wrap">{this.props.reviewer.email}</a>
                        </Body2>
                    </section>
                    <section className="section-item pb-2 pb-md-0">
                        <Caption color="gray">Phone</Caption>
                        <Body2 dataTestId="phone" className="d-flex flex-column">{this.props.reviewer.phone ? this.props.reviewer.phone : "Not Provided"}</Body2>
                    </section>
                    <section className="section-item pb-2 pb-md-0">
                        <Caption color="gray">Job Title</Caption>
                        <Body2 dataTestId="jobTitle" className="d-flex flex-column">{this.props.reviewer.jobTitle}</Body2>
                    </section>
                </div>
                <div className="flex-column flex-fill flex-wrap">
                    <section className="section-item pb-2 pb-md-0">
                        <Caption color="gray">College</Caption>
                        <Body2 dataTestId="college" className="d-flex flex-column">{this.props.reviewer.college}</Body2>
                    </section>
                    <section className="section-item pb-2 pb-md-0">
                        <Caption color="gray">D-Org</Caption>
                        <Body2 dataTestId="dOrg" className="d-flex flex-column">{this.props.reviewer.dOrg}</Body2>
                    </section>
                    <section className="section-item pb-2 pb-md-0">
                        <Caption color="gray">Academic Program(s)</Caption>
                        <Body2 dataTestId="academicPrograms" className="d-flex flex-column">{this.props.reviewer.shortPlans.join(", ")}</Body2>
                    </section>
                </div>
            </div>
        );
    }

    renderCurrentCommittees = () => {
        if (this.state.currentAwards.length === 0) {
            return (
                <div className="d-flex flex-column mb-2">
                    <div><Subtitle2>{this.state.currentCommitteesDataKeys[0].label}</Subtitle2></div>
                    <div><Body2>None</Body2></div>
                </div>
            );
        } else {
            const renderDataHeaderRow = () => {
                const renderDataHeaderColumns = () => {
                    return this.state.currentCommitteesDataKeys.map(dataKey => {
                        return (
                            <div key={`currentAwards.header.${dataKey.key}`} className="py-1 pr-2" style={{flexBasis: `${dataKey.width}%`}}>
                                <Subtitle2>{dataKey.label}</Subtitle2>
                            </div>
                        );
                    });
                };
                return (
                    <div className="d-flex">
                        {renderDataHeaderColumns()}
                    </div>
                );
            };
            const renderDataRows = () => {
                const renderDataColumns = (currentAward, index) => {
                    return this.state.currentCommitteesDataKeys.map(dataKey => {
                        const key = dataKey.key;
                        const label = dataKey.label;
                        const type = dataKey.type;
                        const name = `currentAwards${index}.${key}`;
                        const value = currentAward[key];
                        let dataColumn = null;
                        if(type === "text") {
                            dataColumn = (
                                <div>
                                    <Label for={name} hidden>{label}</Label>
                                    <Input id={name} data-testid={`currentAwards${index}.${dataKey.key}`} 
                                        type="text" name={name} value={this.state.currentAwards[index][key]}
                                        onChange={e => {
                                            const currentAwards = [...this.state.currentAwards];
                                            currentAwards[index][key] = e.target.value;
                                            this.setState({ currentAwards, hasUnsavedChanges: true });
                                        }}
                                    />
                                </div>
                            );
                        } else if(type === "select") {
                            if(currentAward.status === Constants.AWARD_STATUS_KEY_ASSIGNED) {
                                dataColumn = (<Body2 dataTestId={`currentAwards${index}.${dataKey.key}`}>{Constants.AWARD_STATUS_MAP[value] || null}</Body2>);
                            } else {
                                const defaultStatusOption = _.find(this.state.awardStatusOptions, ["value", currentAward.status]);
                                dataColumn = (
                                    <div>
                                        <form data-testid={`currentAwards${index}.${dataKey.key}Form`}>
                                            <Select inputId={name} name={name} options={this.state.awardStatusOptions} 
                                                className={name} classNamePrefix={name} aria-label={label}
                                                defaultValue={defaultStatusOption} value={this.state.currentAwardsStatusSelectedOptions[index]}
                                                onChange={e => {
                                                    // update the selected option for the component to render the selection
                                                    const currentAwardsStatusSelectedOptions = [...this.state.currentAwardsStatusSelectedOptions];
                                                    currentAwardsStatusSelectedOptions[index] = e;
                                                    // update the selected option value for data collection
                                                    const currentAwards = [...this.state.currentAwards];
                                                    currentAwards[index][key] = e.value;
                                                    this.setState({ currentAwardsStatusSelectedOptions, currentAwards, hasUnsavedChanges: true });
                                                }}
                                            />
                                        </form>
                                    </div>
                                );
                            }
                        } else if(type === "checkbox") {
                            if(currentAward.status === Constants.AWARD_STATUS_KEY_INVITED && currentAward.isNew !== true) {
                                let inviteDate = null;
                                if(currentAward.inviteDate) {
                                    inviteDate = Moment(currentAward.inviteDate).format(INVITE_DATE_FORMAT);
                                }
                                dataColumn = (
                                    <div>
                                        <div>
                                            <Label for={name} hidden>{label}</Label>
                                            <Input id={name} data-testid={`currentAwards${index}.${dataKey.key}`} type="checkbox" name={name} 
                                                checked={this.state.currentAwardInviteSelection === currentAward.awardKey}
                                                disabled={!["", currentAward.awardKey].includes(this.state.currentAwardInviteSelection)}
                                                className="position-relative mt-0 ml-0" // override form-check-input class
                                                onChange={e => { 
                                                    if(e.target.checked) {
                                                        let state = {currentAwardInviteSelection: currentAward.awardKey };
                                                        if(currentAward.inviteSubject !== null) state.currentAwardInviteEmailSubject = currentAward.inviteSubject;
                                                        if(currentAward.inviteBody !== null) state.currentAwardInviteEmailBody = currentAward.inviteBody;
                                                        this.setState(state);
                                                    } else {
                                                        this.setState({currentAwardInviteSelection: "", currentAwardInviteEmailSubject: "", currentAwardInviteEmailBody: ""});
                                                    }
                                                }}
                                            />
                                        </div>
                                        <Caption>{inviteDate}</Caption>
                                    </div>
                                );
                            }
                        } else {
                            dataColumn = (<Body2 dataTestId={`currentAwards${index}.${dataKey.key}`}>{value}</Body2>);
                        }
                        return (
                            <div key={`currentAwards${index}.${dataKey.key}`} className="py-1 pr-2" style={{flexBasis: `${dataKey.width}%`}}>
                                {dataColumn}
                            </div>
                        );
                    })
                };
                return this.state.currentAwards.map((currentAward, index) => {
                    return (
                        <div key={`currentAwards${index}`}>
                            <div className="d-flex align-items-center">
                                {renderDataColumns(currentAward, index)}
                            </div>
                            {this.state.currentAwardInviteSelection === currentAward.awardKey &&
                                <div className={`mx-5 mb-4 ${this.props.inviteReviewerToAwardProcessing ? "d-none" : "d-flex flex-column"}`}>
                                    <div className="flex-column mb-2 emailSubject">
                                        <Label for="emailSubject"><Body2>Subject:</Body2></Label>
                                        <Input data-testid="emailSubject" type="text" id="emailSubject" value={this.state.currentAwardInviteEmailSubject}
                                            onChange={(e) => this.setState({ currentAwardInviteEmailSubject: e.target.value })} />
                                    </div>
                                    <div className="flex-column mb-2">
                                        <Label for="emailBody"><Body2>Body:</Body2></Label>
                                        <Input data-testid="emailBody" type="textarea" id="emailBody" className="emailBody" value={this.state.currentAwardInviteEmailBody}
                                            onChange={(e) => this.setState({ currentAwardInviteEmailBody: e.target.value }) } />
                                    </div>
                                    <div className="flex-column">
                                        <OSUButton solid color="blue" ariaLabel="Send Email Invite" 
                                            disabled={this.state.currentAwardInviteEmailSubject === "" || 
                                                this.state.currentAwardInviteEmailBody === ""}
                                            onClick={() => this.sendEmailInvite(currentAward)}>
                                            Send Email Invite
                                        </OSUButton>
                                    </div>
                                </div>
                            }
                            {this.state.currentAwardInviteSelection === currentAward.awardKey &&
                                <div data-testid="sendEmailInviteLoadingIcon" ref={(el) => { this.sendEmailInviteProcessingIcon = el; }} tabIndex="-1" className={`mt-2 mb-4 ${this.props.inviteReviewerToAwardProcessing ? "" : "d-none"}`}>
                                    <OSULoading text="Sending Email Invite..." />
                                </div>
                            }
                        </div>
                    );
                });
            }
            return (
                <div data-testid="currentCommittees" className="custom-table w-100 mb-2">
                    {renderDataHeaderRow()}
                    {renderDataRows()}
                </div>
            );
        }
    }

    renderAvailableAwards = () => {
        const availableAwards = _.xorBy(this.props.allAwards.currentAwards.map(award => { award.awardKey = award.pk; return award;}), this.state.currentAwards, "awardKey");

        const availableAwardsHandleChange = (e) => {
            this.setState({ availableAwardSelectedOption: e })
        }
        if(availableAwards.length === 0 || this.props.reviewer.active === false) {
            return null;
        } else {
            const availableAwardOptions = availableAwards.map(award => {
                return { label: award.name, value: award.awardKey, data: award};
            });
            return(
                <div className="d-flex flex-wrap mb-4">
                    <div className="flex-fill" style={{minWidth: "14rem", maxWidth: "20rem"}}>
                        <form data-testid="availableAwardsForm">
                            <Select inputId="availableAwardsSelect" name="availableAwardsSelect" aria-label="Available Awards"
                                options={availableAwardOptions} value={this.state.availableAwardSelectedOption} 
                                placeholder="Available Awards..." isClearable={true} 
                                onChange={(e) => availableAwardsHandleChange(e)} />
                        </form>
                    </div>
                    {this.state.availableAwardSelectedOption !== null &&
                        <div className="flex-fill">
                            <OSUButton ariaLabel="Add Reviewer to Award Committee" link color="blue" uppercase={false} onClick={this.addReviewerToAvailableAwardCommittee}>
                                <Icon type="plus" color="blue" size="sm" /> Add To Committee
                            </OSUButton>
                        </div>
                    }
                </div>
            );
        }
    }

    addReviewerToAvailableAwardCommittee = () => {
        if(this.state.availableAwardSelectedOption !== null) {
            const availableAward = this.state.availableAwardSelectedOption.data;
            const currentAwards = [...this.state.currentAwards];
            currentAwards.push({
                isNew: true, // UI flag
                awardKey: availableAward.pk,
                name: availableAward.name,
                recommendedBy: "",
                status: Constants.AWARD_STATUS_KEY_NONE
            });
            this.setState({ currentAwards, availableAwardSelectedOption: null, hasUnsavedChanges: true });
        }
    }

    renderComments = () => {
        return (
            <div className="d-flex flex-column mb-4">
                <Label for="comments"><Body2>Comments:</Body2></Label>
                <Input data-testid="comments" type="textarea" id="comments" className="comments" defaultValue={this.props.reviewer.comments} onChange={e => { this.setState({ comments: e.target.value, hasUnsavedChanges: true }) }} />
            </div>
        );
    }

    saveReviewer = () => {
        // build updated reviewer

        const reviewer = _.cloneDeep(this.props.reviewer);
        delete reviewer.isNew; // UI flag
        _.forEach(this.state.currentAwards, currentAward => {
            console.log("Current award:", currentAward);
            const award = _.find(reviewer.awards, ["awardKey", currentAward.awardKey]);
            console.log("award", award)
            if(!award) { // add new award
                reviewer.awards.push({
                    awardKey: currentAward.awardKey,
                    recommendedBy: currentAward.recommendedBy,
                    status: currentAward.status
                });
            } else { // update existing award
                award.recommendedBy = currentAward.recommendedBy;
                award.status = currentAward.status;
            }
        });
        reviewer.comments = this.state.comments;
        this.props.saveReviewer(reviewer);
    }

    renderPreviousCommittees = () => {
        if (this.state.previousAwards.length === 0) {
            return null;
        } else {
            const previousCommittees = [];
            _.forEach(this.state.previousAwards, (award, index) => {
                previousCommittees.push({
                    name: (<Body2 dataTestId={`previousAwards${index}.name`} key={`previousAwards${index}.name`}>{award.name}</Body2>),
                    recommendedBy: (<Body2 dataTestId={`previousAwards${index}.recommendedBy`} key={`previousAwards${index}.recommendedBy`}>{award.recommendedBy}</Body2>),
                    status: (<Body2 dataTestId={`previousAwards${index}.status`} key={`previousAwards${index}.status`}>{Constants.AWARD_STATUS_MAP[award.status] || null}</Body2>)
                });
            });
            return (
                <div data-testid="previousCommittees" style={{clear: "both"}}>
                    <hr />
                    <Table dataKeys={this.state.previousCommitteesDataKeys} data={previousCommittees} headerVariant="subtitle2" rowHeight="1" border={false} hover={false} />
                </div>
            );
        }
    }
}