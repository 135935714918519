import { isEmpty } from "lodash";
import moment from "moment";
import { STUDENT_STATUS_NEWADMIT } from "../../AwardManagement/constants";
import { STUDENT_STATUS_CURRENT, STUDENT_STATUS_NEW_ADMIT } from "../../util/constants";
import { NOMINEE_FILE_NAMES } from "../constants";

const formatGpa = (gpa) => isNaN(parseFloat(gpa)) ? '-' : gpa

export function transformStudentData(data = {}, studentStatus) {
  if (isEmpty(data)) return {};
  const {
    nomineeId,
    cumGpa,
    externalGradGpa,
    externalUgrdGpa,
    applicationNumber,
    gender,
    // ethnicity,
    shortPlanDescription,
    shortPlan,
    name,
    birthCountry,
    citizenshipCountryDescr,
    degree
  } = data;

  let personal = [
    {
      left: "Student EMPLID",
      right: nomineeId,
    },
    {
      left: "Application Number",
      right: applicationNumber,
    },
    {
      left: "Gender",
      right: gender,
    },
    // {
    //   left: "Ethnicity",
    //   right: ethnicity,
    // },
    {
      left: "Birth Country",
      right: birthCountry
    },
    {
      left: "Citizenship Country",
      right: citizenshipCountryDescr
    }
  ];
  personal = personal.filter(data => !!data.right)

  let academic = []
  if (degree) {
    academic.push({left: "Degree",
    right: degree
    });
  }
  if (studentStatus === STUDENT_STATUS_CURRENT) {
    academic.push({
      left: "Cumulative GPA",
      right: formatGpa(cumGpa),
    });
  } else {
    academic.push({
      left: "Undergrad GPA",
      right: formatGpa(externalUgrdGpa),
    });
    if (externalUgrdGpa) {
      academic.push({
        left: "Graduate GPA",
        right: formatGpa(externalGradGpa),
      });
    }
  }
  const pcdFlag = (data.pcdFlag === "Y" ? "Yes" : (data.pcdFlag === "N" ? "No" : null));
  if(pcdFlag) {
    academic.push({
      left: "Post Candidacy (PCD)",
      right: pcdFlag
    });
  }

  let college = data.college;
  return {
    program: !!shortPlanDescription ? `${shortPlanDescription} (${shortPlan}) - ${college}` : shortPlan + " - " + college,
    name,
    sections: [
      {
        heading: "Personal",
        rows: personal,
      },
      {
        heading: "Academic",
        rows: academic,
      }
    ],
  };
}

export function formatNominatedForDropdown(awardOptions = []) {
  if(!Array.isArray(awardOptions)) return []
  
  let options = awardOptions
    .filter((option = {}) => option.attributes && option.attributes.awardResultName  && option.attributes.awardResultABBV)
    .map(option => ({
      label: `${option.attributes.awardResultName} (${option.attributes.awardResultABBV})`,
      value: option.attributes.awardResultABBV
    }))

  return [
    {
      label: `None`,
      value: ''
    },
    ...options
  ]
}


export function determineGpaEligibility({ studentStatus, minimumGpa, cumGpa, externalUgrdGpa  }) {
	let minimumRequirement = parseFloat(minimumGpa)
	let gpa = studentStatus === STUDENT_STATUS_NEW_ADMIT 
		? parseFloat(externalUgrdGpa)
		: studentStatus === STUDENT_STATUS_CURRENT
		? parseFloat(cumGpa)
		: NaN

	if(Number.isNaN(minimumRequirement) || Number.isNaN(gpa)) return false

    return (gpa >= minimumRequirement && gpa <= 4);
}

const getProperty = (name) => {
  if(name === NOMINEE_FILE_NAMES.packet) {
    return {
      property: 'uploadedFile',
      field: 'documentName'
    }
  } else if (name === NOMINEE_FILE_NAMES.petition) {
      return {
        property: 'documentNames',
        field: 'name'
      }
  }
    return {}
}
export function getPacket(name, nomineeRecord) {
  const findFiles = (field) => Array.isArray(nomineeRecord[field]) ? nomineeRecord[field] : []
  let findPacket = (arr, field) => arr.find(pkt => pkt[field] === name) || {}
  
  let packet = {}, files = []
  const { property, field } = getProperty(name)
  files = findFiles(property)

  packet = findPacket(files, field)

  return packet
}

export function removeDocumentFromRecord (record = {}, documentId = '') {
  let newRecord = Object.assign({ uploadedFile: [], documentNames: [] }, record)
  newRecord.uploadedFile = newRecord.uploadedFile.filter(document => document.documentId !== documentId)
  newRecord.documentNames = newRecord.documentNames.filter(document => document.id !== documentId)

  return newRecord
}

export function replaceDocumentsInRecord(record = {}, documentId = '', name = '') {
  let newRecord = Object.assign({ uploadedFile: [], documentNames: [] }, record)

  newRecord.uploadedFile = newRecord.uploadedFile.filter(document => document.documentName !== name)
  newRecord.uploadedFile.push({
    documentName: name,
    documentId
  })

  newRecord.documentNames = newRecord.documentNames.filter(document => document.name !== name)
  newRecord.documentNames.push({
    name: name,
    id: documentId
  })
  
  return newRecord
}

export function matchCurrentRecordToAwardedSk (awardResults, nomineeSK) {
  let awardedOption = { awardResultABBV: '' }
  if(Array.isArray(awardResults) && awardResults.length > 0) {
    const match = awardResults.find(result => result && result.nomineeSK === nomineeSK)
    if(!!match) awardedOption = match
  }
 
  return awardedOption
}

export function formatBreadcrumbTrail(studentStatus, name, shortPlan) {
  let breadcrumbTrail = [
    {
      path: "/nominations",
      text: "Fellowships & Awards",
    },
  ];
  let competitionPath = {};
  if (studentStatus === STUDENT_STATUS_NEWADMIT) {
    competitionPath.path = `/nominations/competitions/recruiting/${shortPlan}`;
    competitionPath.text = "Recruiting Fellowship Competition Nominees";
  } else if (studentStatus === STUDENT_STATUS_CURRENT) {
    competitionPath.path = `/nominations/competitions/currentstudent/${shortPlan}`;
    competitionPath.text = "Current Student Competition Nominees";
  }
  if (competitionPath.path && competitionPath.text) {
    breadcrumbTrail.push({
      ...competitionPath,
      active: !name
    })
  }

  if(name) {
    breadcrumbTrail.push({
      path: window.location.pathname,
      text: name,
      active: true
    })
  }


  return breadcrumbTrail;
}

export function determineReadonly({
  isAdmin,
  nominationStartDate,
  nominationEndDate,
  awardEndDate,
}) {
  const momentizedNomEndDate = moment(nominationEndDate);
  const momentizedNomStartDate = moment(nominationStartDate);
  const momentizedAwardEndDate = moment(awardEndDate);
  const formattedDate = (date) => date.format("MMMM Do YYYY [at] h:mm A");

  let description = "";
  let readonly = false;

  if (moment().isBefore(momentizedNomStartDate)) {
    readonly = true;
    description = `Form is disabled until nominations start on ${formattedDate(momentizedNomStartDate)}.`
  } else if (isAdmin) {
    readonly = moment().isAfter(momentizedAwardEndDate)
    description = `The award end date, ${formattedDate(momentizedAwardEndDate)}, has passed. Form is now disabled.`
  } else {
    readonly = moment().isAfter(momentizedNomEndDate);
    description = `The nomination period ended on ${formattedDate(momentizedNomEndDate)}. Form is now disabled.`;
  }

  return {
    readonly,
    readonlyDescription: description,
  };
}