import { PRIMARY_REVIEWS_LIST, PRIMARY_REVIEW_DETAILS, PRIMARY_REVIEWS_STATUS, SEARCH_PRIMARY_REVIEWS_LIST, ADD_PRIMARY_REVIEWS_LIST_FILTER, ADD_PRIMARY_REVIEWS_SORT_KEY } from '../../actions-index'
import { API } from "aws-amplify";
import { setApiStatus } from "../../redux-helpers";
import { systemHeaders} from '../../util/util'

export function updatePrimaryReviewDetails(values) {
    return {
        type: PRIMARY_REVIEW_DETAILS,
        payload: values
    }
}

export function getPrimaryReviewList(awardKey, sortKey) {
    const setPrimaryReviewStatus = (status) => setApiStatus(PRIMARY_REVIEWS_STATUS, status);

    return (dispatch) => {
        dispatch(setPrimaryReviewStatus('loading'));

        API.get(process.env.REACT_APP_AWARD_NOMINEE_REVIEW_API_NAME, `/getReviewerPrimaryNominees/${window.btoa(awardKey)}`, systemHeaders())
            .then(response => {
                if (response.data) {
                    dispatch(receivePrimaryReview(response.data));
                }else if(response.status!==undefined && response.status===401) {
                    console.error("401 Response in getPrimaryReviewList:",response.status);
                    dispatch(setPrimaryReviewStatus('401'));
                } else {
                    console.error("no Response data error getPrimaryReviewList:",response);
                    dispatch(setPrimaryReviewStatus('error'));
                }
            })
            .catch(error => {
                console.error("Error in getPrimaryReviewList:",error);
                if (error === 'No current user'|| error==='Request failed with status code 401'|| (error.response!==undefined && error.response.status!==undefined && error.response.status===401)) {
                    dispatch(setPrimaryReviewStatus('401'));
                } else {
                    dispatch(setPrimaryReviewStatus('error'));
                }
            })
            .finally(() => sortKey && dispatch(updatePrimaryReviewSortKey(sortKey)))
    }
}

export function searchPrimaryReviewList(query) {
    return {
        type: SEARCH_PRIMARY_REVIEWS_LIST,
        payload: query
    }
}

export function addPrimaryReviewFilter(filter) {
    return {
        type: ADD_PRIMARY_REVIEWS_LIST_FILTER,
        payload: filter
    }
}

export function updatePrimaryReviewSortKey(sortKey) {
    return {
        type: ADD_PRIMARY_REVIEWS_SORT_KEY,
        payload: sortKey
    }
}

function receivePrimaryReview(list) {
    return {
        type: PRIMARY_REVIEWS_LIST,
        payload: list
    }
}