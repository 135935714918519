import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Caption, Body2, OSUButton, Typography } from 'osu-react-components'
import { chunk } from 'lodash'
import { ACTION_STATE_LOADING, ACTION_STATE_SUCCESS } from "../../../util/constants";
import { useContext } from 'react';
import { FormEnvironmentContext } from '../context';

function Student(props = {}) {
    const { 
        heading,
        subheading,
        program,
        sections,
        divider,
        refreshNominee,
        refreshNomineeState,
        hasNominee,
        awardKey,
        nomineeId,
        shortPlan
    } = props
    const { readonly: readOnly, isAdmin } = useContext(FormEnvironmentContext)
    const groupedSections = chunk(sections, 2)
    const refreshingNominee = (refreshNomineeState === ACTION_STATE_LOADING);
    const refreshedNominee = (refreshNomineeState === ACTION_STATE_SUCCESS);
    
    return <Fragment>
        {heading && 
            <Typography className="mb-2" variant="heading6">
                {heading}
                {((isAdmin === true || hasNominee === true) && readOnly === false) &&
                    <Fragment>
                        <OSUButton link type="button" ariaLabel="Refresh Nominee" title="Refresh Nominee" color="black" className="ml-1"
                            onClick={() => { if(refreshingNominee === false) refreshNominee(awardKey, nomineeId, shortPlan); }}>
                            <i aria-hidden="true" className={`fa fa-refresh${refreshingNominee ? " fa-spin" : ""}`} />
                        </OSUButton>
                        <div aria-live="polite" className="sr-only">
                            {refreshingNominee && "Refreshing nominee..."}
                            {refreshedNominee && "Successfully refreshed nominee"}
                        </div>
                    </Fragment>
                }
            </Typography>
        }
        {subheading && <Typography className="mb-2" variant="subtitle1">{subheading}</Typography>}
        <hr />
        {program && <Typography className="mb-2" variant="subtitle1">{program}</Typography>}
        {groupedSections.map((row, rowIndex) => <div key={`student-row-${rowIndex}`} className="d-flex flex-column flex-md-row mb-2">
            {row.map((section, sectionIndex) => <Section key={`student-row#${rowIndex}-section#${sectionIndex}`} heading={section.heading} rows={section.rows} />)}
        </div>)}
        {divider && <hr />}
    </Fragment>
}

function Section (props = {}) {
    const { heading, rows = [] } = props

    return  <section className="flex-fill pb-2 pb-md-0">
        <Caption color="gray">{heading}</Caption>
        <Body2 className="d-flex flex-column">
            {rows.map((row, rowIndex) => {
                const { left, right } = row
                return <span key={`student-section-${rowIndex}`}>
                    {`${left}: ${right}`}
                </span>
            })}
        </Body2>
    </section>
}

Student.defaultProps = {
    divider: true
}

Student.propTypes = {
    heading: PropTypes.string,
    subheading: PropTypes.string,
    name: PropTypes.string,
    sections: PropTypes.arrayOf(PropTypes.shape({
        heading: PropTypes.string,
        rows: PropTypes.arrayOf(PropTypes.shape({
            left: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            right: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        }))
    }))
}

export default Student