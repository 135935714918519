
import React, { Component } from 'react';
import { Body1 } from 'osu-react-components';
import '../AwardAcceptance.css';
import logo from '../logo.png';

class Error extends Component {
    renderStudentType() {
        if(this.props.studentType === "NEWADMIT") {
            return <Body1>Thank you for your interest in pursuing graduate study at the Ohio State University.<br /><br />In accordance with national agreements, the deadline to accept your Fellowship offer was {this.props.terminationDate}. As this date has passed, you are no longer eligible to accept this Fellowship. If you believe you have received this message in error, please contact <a href="mailto:grad-schoolfellowships@osu.edu">grad-schoolfellowships@osu.edu</a>.</Body1>
        }
        else {
            return <Body1>The deadline to accept your offer was {this.props.terminationDate}. As this date has passed, you are no longer eligible to accept this offer. If you believe you have received this message in error, please contact <a href="mailto:grad-schoolfellowships@osu.edu">grad-schoolfellowships@osu.edu</a>.</Body1>
        }
    }

    render() {
        console.log("error state is: ", this.props.error);
        return <Body1 className='errorOrLoadingText'>
            {(this.props.error === "expired") && <div>
                <img src={logo} alt='OSU Logo' className='logo' />
                <h1 className='formHeadingsLarge'>Award letter period has ended</h1>
                {this.renderStudentType}
            </div>}
            {(this.props.error === "invalid" )  && <div>
                <img src={logo} alt='OSU Logo' className='logo' />
                <h1 className='formHeadingsLarge'>Award letter not found</h1>               
            </div>}
            {(this.props.error === "alreadyDone" )  && <div>
                <img src={logo} alt='OSU Logo' className='logo' />
                <h1 className='formHeadingsLarge'>This award letter has already been answered</h1>               
            </div>}
            {(this.props.error === "error" || this.props.error === undefined)  && <div>
                <img src={logo} alt='OSU Logo' className='logo' />
                <h1 className='formHeadingsLarge'>There was an error processing your request</h1>                
            </div>}
        </Body1>;
    }
}

export default Error;
