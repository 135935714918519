import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import withAwardDates from "./withAwardDates";
import Nominations from "../components";
import { filterCurrentAwardsForNominations } from "../transform";

const mapStateToProps = (state, props) => {
    return {
        currentAwards: filterCurrentAwardsForNominations(state.currentAwards),
        previousAwards: state.currentAwards,
        allAwards: state.allAwards,
        nominatorPrograms: state.nominatorPrograms,
        userIsLoading: state.userIsLoading,
        props
    };
};

const mapDispatchToProps = () => {
    return {};
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withAwardDates(Nominations)));
