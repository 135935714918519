import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { formatNominatedForDropdown } from '../transform'

const useAwardDropdownOptions = () => {
    const awardInfo = useSelector((state) => state.awardInfo) || {}
    const { status = '', data = { awardResultInfo: { awardOptions: [] }} } = awardInfo
    let awardOptions = data && data.awardResultInfo && data.awardResultInfo.awardOptions

    const nominatedForOptions = useMemo(() => formatNominatedForDropdown(awardOptions), [awardOptions])

    return {
        loading: status === 'loading',
        nominatedForOptions
    }
}

export default useAwardDropdownOptions