import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import AwardAcceptance from "../components";
import { validateToken, submitAcceptance } from "../../actions-index";

const mapStateToProps = (state, props) => {
    return {
        tokenData: state.validateToken,
        tokenState: state.tokenState,
        acceptanceData: state.acceptanceData,
        acceptanceState: state.acceptanceState,
        props
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        validateToken: (token) => dispatch(validateToken(token)),
        submitAcceptance: (token, acceptance) => dispatch(submitAcceptance(token, acceptance))
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AwardAcceptance));
