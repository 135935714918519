import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import EvaluationItem from '../components/Item';
import { getNomineeReview, updateNomineeReviewScoresheet, updateBreadcrumbTrail } from '../../../actions-index'

const mapStateToProps = (state, ownProps) => {
    let list = [];
    const id = ownProps.match && ownProps.match.params && ownProps.match.params.id;
    const shortPlan = ownProps.match && ownProps.match.params && ownProps.match.params.shortPlan;
    //console.log("DEBUG shortPlan:",shortPlan);
    const tabIndex = ownProps.location && ownProps.location.state && ownProps.location.state.tabIdentifier ? ownProps.location.state.tabIdentifier : {};
    const userAdminActingOnBehalfOf = ownProps.location && ownProps.location.state && ownProps.location.state.userAdminActingOnBehalfOf ? ownProps.location.state.userAdminActingOnBehalfOf : {};
    const { type, index, title } = tabIndex;
    if(type === 'primary') {
        list = state.primaryReviewList && Array.isArray(state.primaryReviewList.list) ? state.primaryReviewList.list : []
    } else if(type === 'secondary') {
        list = state.secondaryReviewList && Array.isArray(state.secondaryReviewList.list) ? state.secondaryReviewList.list : []
    } else if(type === 'scoresheet') {
        list = state.nomineeReviewScoreSheetList && Array.isArray(state.nomineeReviewScoreSheetList.list) ? state.nomineeReviewScoreSheetList.list : []
    }
    const breadcrumbTrail = [
        { path: "/nominee-reviews/award-selection", text: state.systemAward.name },
        ...title ? [{ path: { pathname: "/nominee-reviews", state: { initialTabIndex: index} }, text: `${title} Tab` } ]: [],
        { path: `/nominee-reviews/evaluation/${id}/${shortPlan}`, text: `${state.nomineeReview.name}`, active: true }
    ];

    return {
        nomineeReview: state.nomineeReview,
        breadcrumbTrail: state.nomineeReview && state.nomineeReview.name && state.systemAward && state.systemAward.name ? breadcrumbTrail : [],
        award: state.systemAward,
        gpaTypes: state.gpaTypes,
        tabIndex: index,
        nomineeReviewStatus: state.nomineeReviewStatus,
        updateLoading: state.updateScoresheetStatus && state.updateScoresheetStatus === 'loading' ? true : false,
        updateError: state.updateScoresheetStatus && state.updateScoresheetStatus === 'error' ? true : false,
        updateSuccess: state.updateScoresheetStatus && state.updateScoresheetStatus.includes('success') ? true : false,
        isOpen: state.updateScoresheetStatus && state.updateScoresheetStatus === "success with ranked data" ? true : false,
        tabIdentifier: tabIndex,
        userAdminActingOnBehalfOf: userAdminActingOnBehalfOf,
        currentId: id,
        ...getPreviousNext(list, id,shortPlan)
};
    };

const getPreviousNext = (list = [], id,shortPlan) => {
    if(!Array.isArray(list) || list.length < 1 || !id || !shortPlan) {
        return {
            previous: null,
            next: null
        }
    }
    const nomineeIndex = list.findIndex(el => el.nomineeId === id && el.shortPlan === shortPlan);
    const previousIndex = nomineeIndex - 1;
    const nextIndex = nomineeIndex + 1;

    return {
        previous: typeof list[previousIndex] !== 'undefined' && list[previousIndex].nomineeId ? list[previousIndex].nomineeId+"/"+list[previousIndex].shortPlan : null,
        next: typeof list[nextIndex] !== 'undefined' && list[nextIndex].nomineeId ? list[nextIndex].nomineeId+"/"+list[nextIndex].shortPlan : null
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        getNomineeReview: (values) => dispatch(getNomineeReview(values)),
        updateBreadcrumbTrail: (pathObj) => dispatch(updateBreadcrumbTrail(pathObj)),
        updateNomineeReviewScoresheet: (values) => dispatch(updateNomineeReviewScoresheet(values))
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EvaluationItem));

