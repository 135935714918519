import React from "react";
import {
  Heading5,
  Table,
  OSUButton,
  OSULoading,
  Body1,
  Body2
} from "osu-react-components";
import {
  Row, Col
} from "reactstrap";

import { CustomInput } from 'reactstrap'
import { find, isEmpty } from 'lodash'
import moment from "moment"
import {PAGE_TITLE_POSTFIX} from "../../constants";


class ReviewerSwitchboard extends React.Component {
  state = {
    pageTitle: "Award Selection",
    dataIndex: 0,
    rowsPerPage: 25,
    reviewersLoading: false,
    previousFilteredResultCount: 0,
    filteredResultCount: 0,
    tooltipOpen: false
  };

  componentWillMount() {
    document.title = this.state.pageTitle + PAGE_TITLE_POSTFIX;
  }

  componentDidMount() {
    this.props.setA11yMessage(`Navigated to the Reviewer Management ${this.state.pageTitle} page`);
    this.pageContent.focus();
  }

  async confirmAwardSelection(selectedAward) {
    await Promise.resolve(this.props.setSystemAwardValues(selectedAward));
    return this.props.history.push('/reviewer-management/committee');
  }


  render() {

    const { awards } = this.props

    if (awards && awards.length === 0) {
      return (
        <div ref={(el) => { this.pageContent = el; }} tabIndex="-1" aria-label={this.state.pageTitle}>
          <Row>
            <Col xs="8" ><Heading5 xs="8" id="pageHeader" className="mb-4">{`Please select an award committee to review:`}</Heading5></Col>
            <Col align="right">
              <OSUButton
                solid
                color="blue"
                ariaLabel="Add New Reviewer"
                path={{ pathname: "/reviewer-management/add-reviewer" }}
              >
                Add New Reviewer
          </OSUButton>
            </Col>
          </Row>

          <br />
          <div>

            <OSULoading text="Loading..." />

          </div>
        </div>
      )

    } else {

      const selectedAward = find(awards, o => o.pk === this.state.awardKey)
      const disableAwardSelection = this.state.awardKey === null || isEmpty(selectedAward)

      const dataKeys = [{
        key: "selectButton",
        width: 3
      }, {
        key: "award",
        width: 97
      }]

      const data = awards.map((award, index) => {
        const { pk: awardKey , name } = award
        const { nominationStartDate, nominationEndDate } = award
        const startDate = moment(nominationStartDate).format('MM/DD/YYYY hh:mm A')
        const endDate = moment(nominationEndDate).format('MM/DD/YYYY hh:mm A')

        return {
          selectButton: <CustomInput aria-label={`Select Award ${name}`} type="radio" name="select" id={`award${index}`} checked={this.state.awardKey === awardKey} readOnly />,
          award: <Body1 key={awardKey}>
            <Body2 color="gray">{startDate} - {endDate}</Body2>
            {name}
          </Body1>,
          onRowClick: async () => {
            this.setState({ awardKey })
            await Promise.resolve(this.props.setSystemAwardValues(find(awards, award => award.pk === awardKey)));
        }
        }
      })

      return (<div ref={(el) => { this.pageContent = el; }} tabIndex="-1" aria-label={this.state.pageTitle}>
                    <OSUButton
              link 
              ariaLabel="View All Reviewers"
              path={{ pathname: "/reviewer-management/all-reviewers" }}
            >View All Reviewers<br/>
        </OSUButton>
          <Heading5 xs="8" className="ml-0 p-0" id="pageHeader">{`Please select an award committee to review:`}</Heading5>

          <Table className="mb-4" noHeader data={data} dataKeys={dataKeys} />
          <OSUButton onClick={() => this.confirmAwardSelection(selectedAward)} ariaLabel={disableAwardSelection ? `Select Award above before proceeding` : `Confirm and Review Award ${selectedAward.description}`} disabled={disableAwardSelection}>Review Award</OSUButton>


      </div>)
    }
  }
}

export default ReviewerSwitchboard;
