import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import AddReviewer from "../components/AddReviewer";
import { searchGraduateFaculty, resetSearchGraduateFacultyError } from "../../actions-index";

const mapStateToProps = (state, props) => {
    return {
        searchError: state.graduateFacultySearchError,
        searchProcessing: state.graduateFacultySearchProcessing,
        searchQuery: state.graduateFacultySearchQuery,
        searchResults: state.graduateFacultySearchResults,
        props
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        searchGraduateFaculty: (query) => dispatch(searchGraduateFaculty(query)),
        resetSearchGraduateFacultyError: () => dispatch(resetSearchGraduateFacultyError())
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AddReviewer));
