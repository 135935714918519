import { NOMINEE_REVIEW, NOMINEE_REVIEW_STATUS, UPDATE_SCORES_STATUS } from '../../actions-index'
import { API } from "aws-amplify";
import { systemHeaders } from '../../util/util';
import { isEmpty } from 'lodash';
import { setApiStatus } from '../../redux-helpers';

export function getNomineeReview(values) {
    const { awardKey, nomineeId, shortPlan, emplId } = values;
    //console.log("getNomineeReview shortPlan",shortPlan);
    const updateNomineeReviewStatus = (status) => setApiStatus(NOMINEE_REVIEW_STATUS, status);

    return (dispatch) => {
        dispatch(updateNomineeReviewStatus('loading'));

        API.get(process.env.REACT_APP_AWARD_NOMINEE_REVIEW_API_NAME, `/getNomineeReviewScoresheet/${window.btoa(awardKey)}/${nomineeId}/${shortPlan}?emplIdOfReviewerActedOnBehalfOf=${emplId}`, systemHeaders())
            .then(response => {
                if (response.data) {
                    dispatch({ type: NOMINEE_REVIEW, payload: response.data });
                    dispatch(updateNomineeReviewStatus('success'));
                } else if (response.status !== undefined && response.status === 401) {
                    console.error("401 Response in getNomineeReview:", response.status);
                    dispatch(updateNomineeReviewStatus('401'));
                } else {
                    console.error("no Response data error getNomineeReview:", response);
                    dispatch(updateNomineeReviewStatus('error'));
                }
            })
            .catch(error => {
                console.log("Error in Evaluation action getNomineeReview:", error);
                if (error === 'No current user' || error === 'Request failed with status code 401' || (error.response !== undefined && error.response.status !== undefined && error.response.status === 401)) {
                    dispatch(updateNomineeReviewStatus('401'));
                } else {
                    dispatch(updateNomineeReviewStatus('error'));
                }
            })
    }
}

export function clearNomineeReview() {
    return dispatch => dispatch({ type: NOMINEE_REVIEW, payload: {} });
}

export function updateNomineeReviewScoresheet(values) {
    const { scores, awardKey, nomineeId, remark, comment, shortPlan, emplId } = values;
    //console.log("updateNomineeReviewScoresheet shortPlan",shortPlan);
    const updateScoreStatus = (status) => setApiStatus(UPDATE_SCORES_STATUS, status);

    return (dispatch) => {
        dispatch(updateScoreStatus('loading'));

        const params = {
            ...systemHeaders(),
            body: {
                awardKey: window.btoa(awardKey),
                nomineeId,
                reviewerActedOnBehalfOf: emplId,
                shortPlan,
                ...scores,
                remark: isEmpty(remark) ? undefined : remark,
                comment: isEmpty(comment) ? undefined : comment
            }
        };

        API.post(process.env.REACT_APP_AWARD_NOMINEE_REVIEW_API_NAME, `/updateNomineeReviewScoresheet`, params)
            .then(response => {
                if (response.data) {
                    dispatch(dispatch({ type: NOMINEE_REVIEW, payload: response.data }));

                    if (values.rankedScore) {

                        API.post(process.env.REACT_APP_AWARD_NOMINEE_REVIEW_API_NAME, `/calculateRankedScores`, params)
                            .then(response => {
                                if (response.data) {
                                    console.log("Ranked scores have been updated along with the scoresheet");
                                    dispatch(updateScoreStatus('success with ranked data'));
                                    dispatch(updateScoreStatus('success'));

                                } else if (response.status !== undefined && response.status === 401) {
                                    console.error("401 Response in updateNomineeReviewScoresheet:", response.status);
                                    dispatch(updateScoreStatus('401'));
                                } else {
                                    console.error("no Response data error updateNomineeReviewScoresheet:", response);
                                    dispatch(updateScoreStatus('error'));
                                }
                            })
                            .catch(error => {
                                console.error("Error in updateNomineeReviewScoresheet:", error);
                                if (error === 'No current user' || error === 'Request failed with status code 401' || (error.response !== undefined && error.response.status !== undefined && error.response.status === 401)) {
                                    dispatch(updateScoreStatus('401'));
                                } else {
                                    dispatch(updateScoreStatus('error'));
                                }
                            })

                    } else{
                        dispatch(updateScoreStatus('success'));
                    }

                } else if (response.status !== undefined && response.status === 401) {
                    console.error("401 Response in updateNomineeReviewScoresheet:", response.status);
                    dispatch(updateScoreStatus('401'));
                } else {
                    console.error("no Response data error updateNomineeReviewScoresheet:", response);
                    dispatch(updateScoreStatus('error'));
                }
            })
            .catch(error => {
                console.error("Error in updateNomineeReviewScoresheet:", error);
                if (error === 'No current user' || error === 'Request failed with status code 401' || (error.response !== undefined && error.response.status !== undefined && error.response.status === 401)) {
                    dispatch(updateScoreStatus('401'));
                } else {
                    dispatch(updateScoreStatus('error'));
                }
            })
    }
}