import { CREATE_TOKEN_DATA, CREATE_TOKEN_STATE, CREATE_ACCEPTANCE_STATE, CREATE_ACCEPTANCE_DATA } from "../actions-index";

export function validateToken(state = {}, action = {}) {
    return (action.type === CREATE_TOKEN_DATA ? action.payload : state);
}

export function tokenState(state = {}, action = {}) {
    return (action.type === CREATE_TOKEN_STATE ? action.payload : state);
}

export function acceptanceState(state = {}, action = {}) {
    return (action.type === CREATE_ACCEPTANCE_STATE ? action.payload : state);
}

export function acceptanceData(state={}, action = {}) {
    return (action.type === CREATE_ACCEPTANCE_DATA ? action.payload : state);
}