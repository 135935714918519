import React, { Component } from "react";

export default class UnauthorizedError extends Component {
    render() {
        return (
            <p data-testid={"mock-container-add-reviewer"}>
                It appears your login might have expired or you are not affiliated with a current review committee. <br/><br/>
                Please click on your name in the upper right corner of this screen and log out from GradAwards and then log back in.<br/><br/>
                If this is in error, please contact the <a href="mailto:Grad-SchoolFellowships@osu.edu">Graduate School</a>
                &nbsp;with a brief description of the information you need to access.
            </p>
        );
    }
}