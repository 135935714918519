import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {toggleShowInactive} from "../../actions"
import PropTypes from "prop-types";
import { COLLEGE_SELECTION, DORG_SELECTION, PROGRAM_OF_STUDY_SELECTION, AWARDS_SELECTION, 
  searchReviewers, filterOnDropdown, changeStatusOfReviewers, assignPacketsToSelectedReviewers } from "../../../actions-index";
import CommitteeSelection from "../components/committee-selection";

const mapStateToProps = (state, ownProps) => {
  return {
    systemAward: state.systemAward,
    query: state.allReviewersQuery,
    showInactive: state.checkStateOfCheckboxToShowInactive,
    filterOnCollegeDropdown: state.applyCollegeDropdownFilter,
    filterOnDorgDropdown: state.applyDorgDropdownFilter,
    filterOnProgramOfStudyDropdown: state.applyProgramOfStudyDropdownFilter,
    filterOnAwardsDropdown: state.applyAwardsDropdownFilter,
    allReviewers: state.allReviewersResult,
    allReviewersError: state.allReviewersError,
    assignPacketsToReviewersError: state.assignPacketsToReviewersError,
    assignPacketsToReviewersErrorMessage: state.assignPacketsToReviewersErrorMessage,
    assignPacketsToReviewersProcessing: state.assignPacketsToReviewersProcessing,
    assignPacketsToReviewersProcessingMessage: state.assignPacketsToReviewersProcessingMessage,

    reviewers: state.reviewers
  };
};

const mapDispatchToProps = dispatch => {
  return {
    searchReviewers: query => dispatch(searchReviewers(query)),
    toggleShowInactive: showInactive => dispatch(toggleShowInactive(showInactive)),
    assignPacketsToSelectedReviewers: awardKey => dispatch(assignPacketsToSelectedReviewers(awardKey)),
    changeStatusOfReviewers: (status, awardKey, reviewers) => dispatch(changeStatusOfReviewers(status, awardKey, reviewers)),
    collegeFilterAction: filterOnCollegeDropdown => dispatch(filterOnDropdown(filterOnCollegeDropdown, COLLEGE_SELECTION)),
    dorgFilterAction: filterOnDorgDropdown => dispatch(filterOnDropdown(filterOnDorgDropdown, DORG_SELECTION)),
    programOfStudyFilterAction: filterOnProgramOfStudyDropdown => dispatch(filterOnDropdown(filterOnProgramOfStudyDropdown,PROGRAM_OF_STUDY_SELECTION)),
    awardsFilterAction: filterOnAwardsDropdown => dispatch(filterOnDropdown(filterOnAwardsDropdown, AWARDS_SELECTION))
  };
};

CommitteeSelection.propTypes = {
  allReviewers: PropTypes.array,
  allAwardsError: PropTypes.array,
  reviewers: PropTypes.array,
  query: PropTypes.string,
  showInactive: PropTypes.boolean,
  dropdownFilter: PropTypes.string,
  filterOnCollegeDropdown: PropTypes.string,
  filterOnDorgDropdown: PropTypes.string,
  filterOnProgramOfStudyDropdown: PropTypes.string,
  filterOnAwardsDropdown: PropTypes.string
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(CommitteeSelection)
);
