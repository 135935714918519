export const ADDITIONAL_QUESTION_ADVISOR_NAME_NUMBER = "ADVISOR_NAME_NUMBER";
export const ADDITIONAL_QUESTION_DISSERTATION_TITLE = "DISSERTATION_TITLE";
export const ADDITIONAL_QUESTION_EXPECTED_GRADUATION_DATE = "EXPECTED_GRADUATION_DATE";
export const ADDITIONAL_QUESTIONS = {
    [ADDITIONAL_QUESTION_ADVISOR_NAME_NUMBER]: "Advisor Name.#",
    [ADDITIONAL_QUESTION_DISSERTATION_TITLE]: "Dissertation Title",
    [ADDITIONAL_QUESTION_EXPECTED_GRADUATION_DATE]: "Expected Graduation Date"
};
export const AWARD_DATE_FORMAT = "YYYY-MM-DDTHH:mm:ssZ";
export const AWARD_NAME_MAX_LENGTH = "75";
export const AWARD_NAME_REGEX = /^[a-zA-Z0-9 '-]*$/;
export const AWARD_TERM_FALL = "Fall";
export const AWARD_TERM_SPRING = "Spring";
export const AWARD_TERM_SUMMER = "Summer";
export const CAPS_AND_WAIVERS_MAX_LENGTH = "3";
export const GPA_ELIGIBILITY_WAIVER_LIMITED = "LIMITED";
export const GPA_ELIGIBILITY_WAIVER_NONE = "NONE";
export const GPA_ELIGIBILITY_WAIVER_PETITION = "PETITION";
export const GPA_ELIGIBILITY_WAIVER = {
    [GPA_ELIGIBILITY_WAIVER_NONE]: "None",
    [GPA_ELIGIBILITY_WAIVER_LIMITED]: "Limited",
    [GPA_ELIGIBILITY_WAIVER_PETITION]: "By Petition"
};
export const GPA_FIELD_CURRENT_CUMULATIVE = "ccgpa";
export const GPA_FIELD_EXTERNAL_UNDERGRAD = "externalUgrdGpa";
export const GPA_FIELDS = {
    [GPA_FIELD_CURRENT_CUMULATIVE]: "Graduate GPA",
    [GPA_FIELD_EXTERNAL_UNDERGRAD]: "Undergraduate GPA"
}
export const GPA_MAX_LENGTH = "5";
export const GPA_REGEX = /^([0-3]\.\d+)?([4](\.[0]+)?)?$/;
export const MINIMUM_ELIGIBILITY_SCORE_MAX_LENGTH = "2";
export const NO = "No";
export const NOMINATIONS_LIMITED_BY_COLLEGE = "COLLEGE";
export const NOMINATIONS_LIMITED_BY_GRAD_SCHOOL = "GRAD_SCHOOL";
export const NOMINATIONS_LIMITED_BY_PROGRAM = "PROGRAM";
export const NOMINATIONS_LIMITED_BY_NONE = "NONE";
export const NOMINATIONS_LIMITED_BY = {
    [NOMINATIONS_LIMITED_BY_PROGRAM]: "Academic Program",
    [NOMINATIONS_LIMITED_BY_COLLEGE]: "College",
    [NOMINATIONS_LIMITED_BY_GRAD_SCHOOL]: "Grad School Only",
    [NOMINATIONS_LIMITED_BY_NONE]: "No Limitation on Nominations"
};
export const EVENT_RESET_STATE = "resetstate";
export const STUDENT_STATUS_ENROLLED = "ENROLLED";
export const STUDENT_STATUS_NEWADMIT = "NEWADMIT";
export const STUDENT_STATUSES = {
    [STUDENT_STATUS_ENROLLED]: "Current Student",
    [STUDENT_STATUS_NEWADMIT]: "New Admit"
}
export const YES = "Yes";