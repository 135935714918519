import React, { Component } from 'react';
import './index.css';
import { OSULoading, Body1, OSUError } from 'osu-react-components'
import queryString from 'query-string'
import {PAGE_TITLE_POSTFIX} from "../../constants";

class ViewPdf extends Component {
    constructor(props) {
        super(props);
        let isiPad = /iPad|iPhone|iPod/.test(navigator.platform) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);
        console.log("isIpad: " + isiPad);
        console.log("navigator.platform: " + navigator.platform);
        console.log("maxTouchPoints: " + navigator.maxTouchPoints);
        this.state = {
            pageTitle: "PDF Document Viewer",
            isiPad: isiPad,
            data: null
        };
    }

    componentWillMount() {
        document.title = this.state.pageTitle + PAGE_TITLE_POSTFIX;
    }

    componentDidMount = () => {
        let awardPk = this.props.defaultAwardPk
        const awardPkOverride = queryString.parse(decodeURI(this.props.location.search)).awardPK
        if(awardPkOverride) {            
            awardPk = awardPkOverride
        }

        this.props.setA11yMessage(`Navigated to the ${this.state.pageTitle} page`);
        this.pageContent.focus();
        this.props.getViewDocument(this.props.match.params.id,queryString.parse(decodeURI(this.props.location.search)).name, awardPk);
    };
    
    componentDidUpdate() {
        if(this.props.viewDocument.url && this.state.isiPad) {
            window.location.assign(this.props.viewDocument.url)
        }
    }

    render() {
        if(this.state.isiPad === false) {
            return (
                <div ref={(el) => { this.pageContent = el; }} tabIndex="-1" aria-label={this.state.pageTitle} className="viewPdf">
                    {this.props.viewDocumentIsError && <OSUError ariaLabel="Error occurred. Reload pdf document." text="Could not fetch pdf document." actionText="Retry" onClick={() => this.props.getViewDocument(this.props.match.params.id,queryString.parse(decodeURI(this.props.location.search)).name, queryString.parse(decodeURI(this.props.location.awardPK)).name)} />}
                    {this.props.viewDocumentIsLoading && <OSULoading text="Loading Document.... This might take a minute. "></OSULoading>}
                    {!this.props.viewDocumentIsLoading && <div>
                        <Body1><strong>Nominee:</strong> {queryString.parse(decodeURI(this.props.location.search)).name}
                            &nbsp;
                            &nbsp;
                            &nbsp;
                            &nbsp;
                            &nbsp;
                            &nbsp;
                            &nbsp;
                            &nbsp;
                            &nbsp;
                            &nbsp;
                    <strong>Academic Plan</strong>: {queryString.parse(decodeURI(this.props.location.search)).plan}</Body1>
                        {this.props.viewDocument && <iframe title="PDF Viewer" id="pdfViewer" src={this.props.viewDocument.url} className="viewer" ></iframe>}
                    </div>}
                </div>
            );
                    }
                    else {
                        return (<div ref={(el) => { this.pageContent = el; }} tabIndex="-1" aria-label={this.state.pageTitle} className="viewPdf" >
                            {this.props.viewDocumentIsError && <OSUError ariaLabel="Error occurred. Reload pdf document." text="Could not fetch pdf document." actionText="Retry" onClick={() => this.props.getViewDocument(this.props.match.params.id,queryString.parse(decodeURI(this.props.location.search)).name, queryString.parse(decodeURI(this.props.location.awardPK)).name)} />}
                            {this.props.viewDocumentIsLoading && <OSULoading text="Loading Document.... This might take a minute. "></OSULoading>}</div>);
                    }
        }
}

export default ViewPdf;
