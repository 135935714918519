import React from "react";
import PropTypes from "prop-types";
import { Caption, Icon } from "osu-react-components";

const IconLegend = (props) => {
    return (
        <div data-testid="iconLegend" className={`d-flex flex-wrap${props.className ? ` ${props.className}` : ""}`}>
            {props.icons.map((icon, index) => {
                return (<Caption className="mr-2 d-inline" key={`icons[${index}]`} dataTestId={`icons[${index}]`}><Icon {...icon} /> {icon.ariaLabel}</Caption>);
            })}
        </div>
    );
}

IconLegend.propTypes = {
    className: PropTypes.string,
    icons: PropTypes.arrayOf(
        PropTypes.shape({
            ariaLabel: PropTypes.string.isRequired,
            type: PropTypes.string.isRequired
            // other Icon properties
        })
    ).isRequired
}

export default IconLegend