import React, { Component, Fragment } from "react";
import { Alert, Input, Label, UncontrolledTooltip } from "reactstrap";
import Select from "react-select";
import PropTypes from "prop-types";
import { Icon } from "osu-react-components";
import { CAPS_AND_WAIVERS_MAX_LENGTH, EVENT_RESET_STATE, GPA_ELIGIBILITY_WAIVER_LIMITED,
    NOMINATIONS_LIMITED_BY_COLLEGE, NOMINATIONS_LIMITED_BY_PROGRAM } from "../../constants";
import graduateColleges from "../data/graduateColleges.json";
import { cloneDeep, forOwn, keys, find, forEach, reject } from "lodash";

class CapsAndWaivers extends Component {
    componentWillMount() {
        this.setComponentState();
        document.addEventListener(EVENT_RESET_STATE, this.setComponentState);
    }

    componentDidMount() {
        this.validate();
    }

    shouldComponentUpdate(nextProps) {
        return nextProps.isActive; // re-render only when active
    }

    componentWillUnmount() {
        document.removeEventListener(EVENT_RESET_STATE, this.setComponentState);
    }

    setComponentState = () => {
        const { award } = this.props;
        const { eligibility = {}, nominationInfo = {} } = award;
        const { nominationsLimitedBy = null } = nominationInfo;

        const cappedByCollege = (nominationsLimitedBy === NOMINATIONS_LIMITED_BY_COLLEGE);
        const cappedByProgram = (nominationsLimitedBy === NOMINATIONS_LIMITED_BY_PROGRAM);
        const limitedWaivers = (eligibility.gpaEligibilityWaiver === GPA_ELIGIBILITY_WAIVER_LIMITED);

        const combineOptions = [];
        let label = "";
        if(cappedByCollege) {
            label = "College";
            forOwn(graduateColleges, (value, key) => {
                combineOptions.push(this.buildSelectOption(key, key));
            });
        }
        if(cappedByProgram) {
            label = "Academic Program";
            forOwn(this.props.graduatePrograms, (value, key) => {
                combineOptions.push(this.buildSelectOption(key, key));
            });
        }

        this.setState({ award, cappedByCollege, cappedByProgram, limitedWaivers, combineOptions, label });
    }

    buildSelectOption(label, value) {
        return { label, value };
    }

    validate = () => {
        this.props.onValidate(true, this.state.award);
    }

    buildInfo = (academicPlans = []) => {
        let info = null;
        if(academicPlans.length > 0) {
            info = "";
            forEach(academicPlans, academicPlan => {
                info += `${academicPlan.academicPlan} ${academicPlan.description}\n`;
            });
        }
        return info;
    }

    onCombineSelectChange = (key, selectedOption) => {
        const value = (selectedOption ? selectedOption.value : null);
        const award = cloneDeep(this.state.award);
        award.capsAndWaivers[key].combine = value;
        if(value !== null) { // clear caps and waivers on combine
            const capsInput = document.getElementById(`${key}CapsInput`);
            if(capsInput) { // don't update state if the input value can't be updated
                capsInput.value = 0;
                award.capsAndWaivers[key].caps = 0;
            }
            if(this.state.limitedWaivers) {
                const waiversInput = document.getElementById(`${key}WaiversInput`);
                if(waiversInput) { // don't update state if the input value can't be updated
                    waiversInput.value = 0;
                    award.capsAndWaivers[key].waivers = 0;
                }
            }
        }
        this.setState({ award }, () => this.validate());
    }

    selectCapsAndWaiversValue = (event) => {
        event.target.select();
    }

    isCapsAndWaiversValueValid = (value) => {
        return (value.length <= CAPS_AND_WAIVERS_MAX_LENGTH && !isNaN(value));
    }

    suppressInvalidCapsAndWaiversValue = (event) => {
        const value = event.target.value;
        if(!this.isCapsAndWaiversValueValid(value)) {
            event.preventDefault();
            if(value.length > 0) {
                event.target.value = value.slice(0, -1);
            }
        }
    }

    onCapsAndWaiversValueChange = (event, key, field) => {
        let value = event.target.value;
        if(value.length === 0) { // default to zero when empty
            value = 0;
            event.target.value = 0;
        }
        if(this.isCapsAndWaiversValueValid(value)) {
            const award = cloneDeep(this.state.award);
            award.capsAndWaivers[key][field] = Number(value);
            this.setState({ award }, () => this.validate());
        }
    }

    render() {
        const { readOnly } = this.props;
        const { award, cappedByCollege, cappedByProgram, limitedWaivers, combineOptions, label } = this.state;
        const { capsAndWaivers } = award;
        const hasCaps = (cappedByCollege || cappedByProgram);
        const combineLabel = "Combine";
        const capsLabel = (cappedByCollege ? "Allocations" : "Caps");
        const waiversLabel = "Waivers";
        return (
            <section>
                <h2 className="heading6" data-testid="capsAndWaiversHeader">{capsLabel} and Waivers</h2>
                <hr />
                <Alert color="info" data-testid="no-caps-alert" isOpen={!hasCaps}>
                    Not available for this award.
                </Alert>
                {hasCaps &&
                    <table id="capsAndWaiversTable" data-testid="capsAndWaiversTable" role="table">
                        <thead data-testid="capsAndWaiversTableHeader">
                            <tr role="row">
                                <th role="columnheader">{label}</th>
                                <th role="columnheader">{combineLabel}<span className="sr-only"> {label} {capsLabel} and Waivers</span></th>
                                <th role="columnheader">{capsLabel}</th>
                                <th role="columnheader"><span className="sr-only">{capsLabel} </span>Used</th>
                                {limitedWaivers && 
                                    <Fragment>
                                        <th role="columnheader">{waiversLabel}</th>
                                        <th role="columnheader"><span className="sr-only">{waiversLabel} </span>Used</th>
                                    </Fragment>
                                }
                            </tr>
                        </thead>
                        <tbody data-testid="capsAndWaiversTableBody">
                            {keys(capsAndWaivers).sort().map(key => {
                                const itemLabel = (cappedByCollege && typeof graduateColleges[key] !== "undefined") ? `${graduateColleges[key]} (${key})` : key;
                                const value = capsAndWaivers[key];
                                const { combine = null, caps = 0, waivers = 0, usedCaps = 0, usedWaivers = 0 } = value;
                                const info = (cappedByProgram && this.props.graduatePrograms !== null) ? this.buildInfo(this.props.graduatePrograms[key]) : null;
                                const inputStyle = { width: "4.9rem" };
                                const filteredCombineOptions = reject(combineOptions, ["value", key]);
                                const combineSelected = (find(filteredCombineOptions, ["value", combine]) || null);
                                return (
                                    <tr key={key} data-testid={`${key}Row`} role="row">
                                        <td data-testid={`${key}Column`} role="cell" data-header={label}>
                                            {itemLabel}
                                            {info && 
                                                <span id={`${key}InfoTooltip`} data-testid={`${key}InfoTooltip`} role="tooltip" tabIndex="0" aria-describedby={`${key}Info`} className="p-1">
                                                    <Icon type="info-circle" color="blue" aria-hidden="true" />
                                                    <span id={`${key}Info`} className="sr-only">{info}</span>
                                                    <UncontrolledTooltip placement="top" target={`${key}InfoTooltip`} innerClassName="caps-waivers-tooltip">{info}</UncontrolledTooltip>
                                                </span>
                                            }
                                        </td>
                                        <td data-testid={`${key}CombineColumn`} role="cell" data-header={combineLabel}>
                                            {readOnly ? 
                                                (<Fragment>{combineSelected ? combineSelected.value : ""}</Fragment>) :
                                                (<Select id={`${key}CombineSelect`} name={`${key}CombineSelect`} inputId={`${key}CombineSelectInput`} aria-label={`Select ${label} to combine ${capsLabel} and Waivers with ${itemLabel}`}
                                                    isClearable={true} placeholder="Combine" options={filteredCombineOptions} value={combineSelected} className="combine-select-container"
                                                    classNamePrefix="combine-select" onChange={selectedOption => this.onCombineSelectChange(key, selectedOption)} />)
                                            }
                                        </td>
                                        <td data-testid={`${key}CapsColumn`} role="cell" data-header={capsLabel}>
                                            {readOnly ? 
                                                (combine === null ? caps : "") :
                                                (combine === null ?
                                                    (<Fragment>
                                                        <Label for={`${key}CapsInput`} className="sr-only">{capsLabel} for {label} {itemLabel}</Label>
                                                        <Input id={`${key}CapsInput`} name={`${key}CapsInput`} type="text" maxLength={CAPS_AND_WAIVERS_MAX_LENGTH} defaultValue={caps}
                                                            style={inputStyle} onClick={this.selectCapsAndWaiversValue} onChange={this.suppressInvalidCapsAndWaiversValue}
                                                            onBlur={e => this.onCapsAndWaiversValueChange(e, key, "caps")} />
                                                    </Fragment>) :
                                                    (capsAndWaivers[combine].caps || 0)
                                                )
                                            }
                                        </td>
                                        <td data-testid={`${key}CapsUsedColumn`} role="cell" data-header="Used">
                                            {combine === null ? usedCaps : (readOnly ? "" : (capsAndWaivers[combine].usedCaps || 0))}
                                        </td>
                                        {limitedWaivers &&
                                            <Fragment>
                                                <td data-testid={`${key}WaiversColumn`} role="cell" data-header={waiversLabel}>
                                                    {readOnly ? 
                                                        (combine === null ? waivers : "") :
                                                        (combine === null ?
                                                            (<Fragment>
                                                                <Label for={`${key}WaiversInput`} className="sr-only">Waivers for {label} {itemLabel}</Label>
                                                                <Input id={`${key}WaiversInput`} name={`${key}WaiversInput`} type="text" maxLength={CAPS_AND_WAIVERS_MAX_LENGTH} defaultValue={waivers}
                                                                    style={inputStyle} onClick={this.selectCapsAndWaiversValue} onChange={this.suppressInvalidCapsAndWaiversValue}
                                                                    onBlur={e => this.onCapsAndWaiversValueChange(e, key, "waivers")} />
                                                            </Fragment>) :
                                                            (capsAndWaivers[combine].waivers || 0)
                                                        )
                                                    }
                                                </td>
                                                <td data-testid={`${key}WaiversUsedColumn`} role="cell" data-header="Used">
                                                    {combine === null ? usedWaivers : (readOnly ? "" : (capsAndWaivers[combine].usedWaivers || 0))}
                                                </td>
                                            </Fragment>
                                        }
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                }
            </section>
        );
    }
}

CapsAndWaivers.defaultProps = {
    isActive: false,
    graduatePrograms: null,
    readOnly: false
}

CapsAndWaivers.propTypes = {
    award: PropTypes.object.isRequired,
    isActive: PropTypes.bool,
    graduatePrograms: PropTypes.object,
    onValidate: PropTypes.func.isRequired,
    readOnly: PropTypes.bool
}

export default CapsAndWaivers;