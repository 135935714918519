import React, { Component, Fragment } from "react";
import { Form, FormFeedback, FormGroup, Input, Label } from "reactstrap";
import PropTypes from "prop-types";
import { Body1, Caption, OSUButton } from "osu-react-components";
import sanitizeHtml from "sanitize-html-react";
import moment from "moment";
import { cloneDeep, values } from "lodash";
import { ADDITIONAL_QUESTIONS, AWARD_NAME_MAX_LENGTH, AWARD_NAME_REGEX, EVENT_RESET_STATE, NOMINATIONS_LIMITED_BY, STUDENT_STATUSES } from "../../constants";
import { FORM_FEEDBACK_SR_PREFIX } from "../../../util/constants";

class AwardInformation extends Component {
    componentWillMount() {
        this.setComponentState();
        document.addEventListener(EVENT_RESET_STATE, this.setComponentState);
    }

    componentDidMount() {
        this.validate();
    }

    shouldComponentUpdate(nextProps) {
        return nextProps.isActive; // re-render only when active
    }

    componentWillUnmount() {
        document.removeEventListener(EVENT_RESET_STATE, this.setComponentState);
    }

    setComponentState = () => {
        const { award } = this.props;
        this.setState({
            award,
            invalid: {
                name: !this.validateName(award.name)
            }
        });
    }

    validateRequired = (value) => {
        return (value && value.length > 0);
    }

    validateName = (name) => {
        return (this.validateRequired(name) && name.length <= AWARD_NAME_MAX_LENGTH && AWARD_NAME_REGEX.test(name));
    }

    onFormInputChange = (event, validator = null) => {
        const state = {};
        const name = event.target.name;
        const value = event.target.value;
        const award = cloneDeep(this.state.award);
        award[name] = value;
        state.award = award;
        if(validator !== null) {
            state.invalid = { ...this.state.invalid, [name]: !validator(value)};
        }
        this.setState(state, () => this.validate());
    }

    validate = () => {
        const isValid = values(this.state.invalid).every(invalid => (invalid === false));
        this.props.onValidate(isValid, isValid ? this.state.award : null);
    }

    render() {
        const { isDeleted, readOnly } = this.props;
        const { award, invalid } = this.state;
        const { description, externalUrl, name, academicYear, category, awardTerm, nominationInfo } = award;
        const studentStatus = award.studentStatus ? STUDENT_STATUSES[award.studentStatus] : "";
        const sanitizedDescription = description ? 
            sanitizeHtml(description, { allowedTags: ["a", "b", "br"], allowedAtrributes: { a: [ "href" ] }, allowedSchemesByTag: { a: [ "mailto" ]} }) :
            "";
        const nominationStartDate = (award.nominationStartDate ? moment(award.nominationStartDate) : null);
        const showDeleteAwardButton = !(nominationStartDate && nominationStartDate.isValid() && nominationStartDate.isBefore());
        return (
            <section>
                <h2 className="heading6" data-testid="awardInformationHeader">Award Information</h2>
                <hr />
                <Form id="awardInformationForm" data-testid="awardInformationForm" noValidate>
                    <FormGroup data-testid="nameFormGroup" className="d-flex flex-wrap">
                        <Label for="name" className="flex-column inline-center">
                            <Body1 className={`mr-1${this.props.readOnly ? "" : " required"}`}>Name:</Body1>
                        </Label>
                        {readOnly ?
                            (<Body1 id="name" name="name" dataTestId="name" className="flex-column">{name}</Body1>) :
                            (<Fragment>
                                <Input id="name" name="name" data-testid="name" type="text" value={name} invalid={invalid.name} onChange={e => this.onFormInputChange(e, this.validateName)}
                                    maxLength={AWARD_NAME_MAX_LENGTH} style={{ maxWidth: "35rem" }} className="flex-column" aria-required="true" aria-invalid={invalid.name} />
                                <FormFeedback aria-live="polite">{FORM_FEEDBACK_SR_PREFIX}Name is required and can include only alphanumeric characters, dashes, spaces, and single quotes..</FormFeedback>
                            </Fragment>)
                        }
                    </FormGroup>
                    <Body1 className="mt-4 mb-2" dataTestId="awardInformation">Award Information:</Body1>
                    <Body1 className="mx-2" dataTestId="studentStatus">Student Status: {studentStatus}</Body1>
                    <Body1 className="mx-2" dataTestId="category">Category: {category}</Body1>
                    <Body1 className="mx-2" dataTestId="academicYear">Academic Year: {academicYear}</Body1>
                    <Body1 className="mx-2" dataTestId="awardTerm">Award Term: {awardTerm}</Body1>
                    <hr />
                    {nominationInfo &&
                        <Fragment>
                            <Body1 className="mt-2 mb-2" dataTestId="nominationsLimitedBy">Nominations Limited By: {(NOMINATIONS_LIMITED_BY[nominationInfo.nominationsLimitedBy] || "")}</Body1>
                            {nominationInfo.additionalQuestions &&
                                <Fragment>
                                    <Body1 className="mt-2" dataTestId="additionalQuestions">Additional Questions:</Body1>
                                    {nominationInfo.additionalQuestions.map((additionalQuestion, index) => {
                                        return (<Body1 className="mx-2" key={`additionalQuestion${index}`} dataTestId={`additionalQuestion${index}`}>{ADDITIONAL_QUESTIONS[additionalQuestion]}</Body1>);
                                    })}
                                </Fragment>
                            }
                            <hr />
                        </Fragment>
                    }
                    <Body1 dataTestId="onBaseNomineePacketInformation">OnBase Nominee Packet Information:</Body1>
                    <Body1 className="mt-3 mx-2" dataTestId="onBaseKeywords">Keywords:</Body1>
                    <Body1 className="mx-2" dataTestId="academicYearOnBaseKeyword">Academic Year: {academicYear}</Body1>
                    <Body1 className="mx-2" dataTestId="awardCategoryOnBaseKeyword">GRAD Award Category: {category}</Body1>
                    <Body1 className="mx-2" dataTestId="awardTermOnBaseKeyword">GRAD Award Term: {awardTerm}</Body1>
                    <hr />
                    <FormGroup data-testid="descriptionFormGroup">
                        <Label for="description" className="mb-0">
                            <Body1>Home page award description:</Body1>
                        </Label>
                        {readOnly ?
                            (<Body1 id="description" name="description">
                                <span data-testid="description" dangerouslySetInnerHTML={{ __html: sanitizedDescription }} />
                            </Body1>) :
                            (<Fragment>
                                <Caption>
                                    {'(Limited HTML available  to bold text, set line breaks and format email. Bold text by wrapping it with <b></b>. To enter line breaks use <br/>. Email example: <a href="mailto:name.#@osu.edu">Name</a>)'}
                                </Caption>
                                <Input id="description" name="description" data-testid="description" type="textarea" rows="10" style={{ resize: "vertical" }} 
                                    value={description} onChange={e => this.onFormInputChange(e)} />
                                <div className="mt-2">
                                    <Body1>Preview:</Body1>
                                    <Body1 className="px-2"><span data-testid="descriptionPreview" dangerouslySetInnerHTML={{ __html: sanitizedDescription }} /></Body1>
                                </div>
                            </Fragment>)
                        }
                    </FormGroup>
                    <FormGroup data-testid="externalUrlFormGroup">
                        <Label for="externalUrl" className="mt-2">
                            <Body1>Link to guidelines:</Body1>
                        </Label>
                        {readOnly ?
                            (<Body1 id="externalUrl" name="externalUrl" dataTestId="externalUrl">{externalUrl}</Body1>) :
                            (<Fragment>
                                <Input id="externalUrl" name="externalUrl" data-testid="externalUrl" type="text" value={externalUrl} onChange={e => this.onFormInputChange(e)}  />
                            </Fragment>)
                        }
                    </FormGroup>
                </Form>
                {(!readOnly && !isDeleted) &&
                    <Fragment>
                        <hr />
                        <OSUButton ariaLabel="Delete Award" onClick={() => this.props.deleteAward()} disabled={!showDeleteAwardButton} color="red"
                            className={`mt-2${!showDeleteAwardButton ? " d-none" : ""}`}>Delete Award</OSUButton>
                    </Fragment>
                }
            </section>
        );
    }
}

AwardInformation.defaultProps = {
    isActive: false,
    isDeleted: false,
    inReview: false,
    readOnly: false
}

AwardInformation.propTypes = {
    award: PropTypes.object.isRequired,
    isActive: PropTypes.bool,
    deleteAward: PropTypes.func.isRequired,
    isDeleted: PropTypes.bool,
    inReview: PropTypes.bool,
    onValidate: PropTypes.func.isRequired,
    readOnly: PropTypes.bool
}

export default AwardInformation;