
import React from 'react'
import EvaluationList from '../../Evaluation/components/List';
import ScoreDistribution from "../../components/ScoreDistribution";
import { Body2, Icon, Typography, OSULoading, OSUError, OSUButton } from 'osu-react-components'
import { Alert, Modal, ModalHeader, ModalBody, ModalFooter, Input, InputGroup, InputGroupAddon, InputGroupText, Row, Col } from 'reactstrap';
import { CSVLink } from "react-csv";
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types'
import Select from 'react-select'
import * as Globals from "../../../constants"
import IconLegend from "../../components/IconLegend";
import { redirectToLogOut } from "../../../util/util";
import { Auth } from "aws-amplify";

// disabling as of 1/8/20. We currently are not populating the data for this filter, so Brian has asked that it be remoed from the UI
const DISABLED_FLAG = false;

class ScoreSheetContent extends React.Component {



    constructor(props) {
        super(props);
        this.state = {
            dataIndex: 500,
            rowsPerPage: 10,
            dropdownOpen: '',
            shouldUpdateNomineeList: true,
            isOpen: false,
            sortKey: {key:"random", direction:"asc"}
        };
        this.categoryScoreSelectRef = React.createRef();
        this.gpaSelectRef = React.createRef();
        this.weightedScoreSelectRef = React.createRef();
    }

    toggleDropdown(key) {
        this.setState({ dropdownOpen: key });
    }

    shouldDisplayFilterDropdowns() {
        return window.innerWidth > Globals.MOBILE_MAXIMUM_RESOLUTION
    }


    componentDidMount() {
        this.props.sortKey ? this.updateSortKey(this.props.sortKey): this.updateSortKey({key:"random", direction:"asc"});

        this.props.awardKey && this.fetchNomineeReviews(this.props.awardKey)
        this.props.clearAllFilters(this.props.filters, this.props.addNomineeListFilter, this.props.searchScoreSheet)
    }

    componentDidUpdate() {
        // set aria-label on Category Score filter dropdown (cannot set directly when isSearchable=false)

        if (this.categoryScoreSelectRef.current) {
            var categoryScoreSelectInputRef = this.categoryScoreSelectRef.current.select.inputRef;
            if (!categoryScoreSelectInputRef.getAttribute("aria-label")) {
                categoryScoreSelectInputRef.setAttribute("aria-label", "Filter table by Category Score");
            }
        }

        // set aria-label on GPA filter dropdown (cannot set directly when isSearchable=false)
        if (this.gpaSelectRef.current) {
            var gpaSelectInputRef = this.gpaSelectRef.current.select.inputRef;
            if (!gpaSelectInputRef.getAttribute("aria-label")) {
                gpaSelectInputRef.setAttribute("aria-label", "Filter table by GPA");
            }
        }

        // set aria-label on Weighted Score filter dropdown (cannot set directly when isSearchable=false)
        if (this.weightedScoreSelectRef.current) {
            var weightedScoreSelectInputRef = this.weightedScoreSelectRef.current.select.inputRef;
            if (!weightedScoreSelectInputRef.getAttribute("aria-label")) {
                weightedScoreSelectInputRef.setAttribute("aria-label", "Filter table by Weighted Score");
            }
        }
        if (this.state.shouldUpdateNomineeList) {
            if (this.props.sortKey && this.props.sortKey.nestedProperty === undefined) {
                // this.updateSortKey({ key: this.props.sortKey.key, nestedProperty: "value", direction: this.props.sortKey.direction })
                if(this.state.sortKey.key !== this.props.sortKey.key){
                    this.setState({ sortKey: this.props.sortKey })

                }

            } else {
                this.setState({ shouldUpdateNomineeList: false })
            }
        }

        // merge updates from nominee evaluation
        if (!isEmpty(this.props.nomineeReview) && this.state.nomineeReviewList) {
            const nomineeList = [...this.state.nomineeReviewList];
            const nominee = this.props.nomineeReview;
            for (let i = 0; i < nomineeList.length; i++) {
                if (nomineeList[i].nomineeId === nominee.nomineeId) {
                    nomineeList[i] = nominee;
                    this.setState({ nomineeReviewList: nomineeList });
                    this.props.clearNomineeReview();
                    break;
                }
            }
        }
    }

    fetchNomineeReviews(awardKey) {
        // const key = this.props.sortKey && this.props.sortKey.key ? this.props.sortKey.key : 'random'
        // const direction = this.props.sortKey && this.props.sortKey.direction ? this.props.sortKey.direction : ''
        this.props.getNomineeReviewList(awardKey, this.state.sortKey)
    }

    setDataIndex = (dataIndex) => {
        this.setState({ dataIndex });
    };

    setRowsPerPage = (rowsPerPage) => {
        this.setState({ rowsPerPage });
    };

    updateSortKey = (values) => {
        this.props.updateNomineeReviewListSortKey(values)
        this.setState({ shouldUpdateNomineeList: true })
    };

    unsuccessfulView(status, awardKey) {
        if (status === 'loading') {
            return <OSULoading text="Loading nominee reviews" />
        } else if (status === '401') {
            Auth.signOut() && redirectToLogOut();
            //return <OSUError ariaLabel="You are logged out. Close your browser and login again." text="You are logged out. Close your browser and login again." actionText="Login" onClick={() => redirectToLogIn()} />
        } else if (awardKey) {
            return <OSUError ariaLabel="Reload nominee reviews" text="Error fetching nominee reviews" onClick={() => awardKey && this.fetchNomineeReviews(awardKey)} />
        }
        return <OSUError ariaLabel="Error occured. Navigate to award selection." text="Could not verify award." actionText="Go to Award Selection" path={'/nominee-reviews/award-selection'} />
    }

    generateSearchBar(query, searchScoreSheet) {
        if (this.shouldDisplayFilterDropdowns()) {
            return (<InputGroup className="fb-40 pr-2 pb-2">
                <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                        <Icon type="search" color="gray" />
                    </InputGroupText>
                </InputGroupAddon>
                <Input type="text" aria-label="Search by Nominee ID or Name" placeholder={`Search by Nominee ID or Name`} value={query} onChange={(e) => searchScoreSheet(e.target.value)} />
            </InputGroup>)
        } else {
            return (
                <InputGroup className="mr-5">
                    <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                            <Icon type="search" color="gray" />
                        </InputGroupText>
                    </InputGroupAddon>
                    <Input type="text" aria-label="Search by EMPLID or Name" placeholder={`Search by EMPLID or Name`} value={query} onChange={(e) => searchScoreSheet(e.target.value)} />
                </InputGroup>
            )
        }

    }

    calculateRankedScores = (returnVal) => {
        this.setState({
            isOpen: returnVal,
            processingRankedScores: false
        });
    }

    toggle = () => {
        this.setState({
          isOpen: !this.state.isOpen
        });
      }    

    render() {
        const { awardKey, query, searchScoreSheet, weightedScoreFilterOptions, scoreSheetCSVHeaders, scoringCriteria, scoringCriteriaOptions, nomineeListStatus, gpaOptions, nominationOptions, reviewerStats, filters, addNomineeListFilter, handleFilter, systemAward } = this.props
        const nomineeReviewList = this.state.nomineeReviewList && this.state.nomineeReviewList.length && this.state.nomineeReviewList[0] !== null ? this.state.nomineeReviewList : this.props.nomineeReviewList;
        let rankedScoresEnabled = true;
        nomineeReviewList.forEach(nominee => {
            if(!nominee.finalWeightedScore){
                rankedScoresEnabled = false;
            }
        });
        const reviewerEmail = this.props.user.email;
        if(this.state.isOpen && !this.state.processingRankedScores){
            this.props.calculateRankedScores({awardKey});
            this.setState({processingRankedScores: true});
        }
        if (nomineeListStatus !== 'success') {
            return this.unsuccessfulView(nomineeListStatus, awardKey)
        }
        const handleFilterProps = {
            activeFilters: filters,
            filterHandler: addNomineeListFilter
        };
        const showNominationsOptions = nominationOptions.options && Array.isArray(nominationOptions.options) && nominationOptions.options.length > 0
        
        let bodyText = "Error: There was an error calculating ranked score, please try again";
        let rankedScoresExist = false;
        let innerBodyText = "";
        if(nomineeReviewList && nomineeReviewList[0] && nomineeReviewList[0].rankedScore) {
            bodyText = "Successfully calculated your Ranked Scores.";
            rankedScoresExist = true;

            nomineeReviewList.forEach(nominee => {
                if(!nominee.rankedScore){
                    bodyText = "Error: There was an error calculating ranked score, please try again";
                    rankedScoresExist = false;
                }
            });


            const sortedData = nomineeReviewList.sort((a,b) => {
                return a.rankedScore - b.rankedScore;
            });

            const isOneOrMoreDuplicateScores = sortedData.some((element, index) => {
                return index > 0 && element.rankedScore && element.finalWeightedScore                       //fields exsist
                    && Math.abs(element.finalWeightedScore - sortedData[index-1].finalWeightedScore) < .01  // AND difference between rows is less than .01
                    && Math.abs(element.rankedScore - sortedData[index-1].rankedScore) > 99                 // AND difference between ranked scores is greater than 99
            });

            if (isOneOrMoreDuplicateScores) {
                innerBodyText = "WARNING: You have two or more duplicate review scores between rank levels. This has resulted in the scores being randomly assigned at the ranked level. Please review the highlighted rows and update scores if desired.";
            }

            if(this.state.sortKey && this.state.sortKey.key !== "rankedScore"){
                this.setState({sortKey:{key:"rankedScore", direction:"desc"}});

            }

        }
        return <div>
            {reviewerStats &&
                <ScoreDistribution rankedScoresExist={rankedScoresExist} onCalculateRankedScores={this.calculateRankedScores} rankedScoresEnabled={rankedScoresEnabled} reviewCompletionDate={systemAward.reviewCompletionDate} reviewerStats={reviewerStats} />
            }
            <IconLegend className="mt-2" icons={[
                { type: "commenting-o", ariaLabel: "Comments available to view.", color: "gray", size: "xs" },
                { type: "asterisk", ariaLabel: "Reviewed by admin.", color: "black", size: "xs" }

                ]} />

            <Row>
                <Col>

                    <Body2 className="mt-2 mb-1 d-flex align-items-center">Filter Content | <OSUButton uppercase={false} variant="body2" ariaLabel="Clear all filters" link onClick={() => this.props.clearAllFilters(filters, addNomineeListFilter, searchScoreSheet)}>Clear All</OSUButton></Body2>

                </Col>
                <Col>
                    {<Typography variant={'button'} className="mt-3 text-right">
                        <CSVLink aria-label="Download table as CSV file" filename={"score-sheet-nominees.csv"} data={nomineeReviewList} headers={scoreSheetCSVHeaders}>
                            <Icon color="blue" className="mr-2" type="download" />Download CSV
                        </CSVLink>
                    </Typography>}
                </Col>
            </Row>


            <Modal scrollable fade isOpen={this.state.isOpen} onClosed={() => this.setState({isOpen: false})}>
                        <ModalHeader toggle={this.toggle}>
                            Calculate Ranked Scores
                        </ModalHeader>
                        <ModalBody>
                            {this.props.assignPacketsToReviewersProcessing ?
                                (<div>
                                    {!this.props.assignPacketsToReviewersProcessingMessage ?
                                        <OSULoading text="Processing... This might take long time to process... Hang on there..." /> : <OSULoading text={this.props.assignPacketsToReviewersProcessingMessage} />}
                                </div>
                                ) :
                                (<div>
                                    {this.props.assignPacketsToReviewersError &&
                                        <Alert color="danger" toggle={() => this.props.resetAssignPacketsToSelectedReviewersFlags()}>
                                            There was an error assigning packets
                                    {!this.props.assignPacketsToReviewersErrorMessage ?
                                                (<span>.<br />Please retry to see if it resolves the issue.</span>) :
                                                (<span>:<br />{this.props.assignPacketsToReviewersErrorMessage}</span>)
                                            }
                                        </Alert>
                                    }
                                    <Body2>{bodyText}
                                    <br/>
                                    <br/>
                                    {innerBodyText}
                                    </Body2>
                                </div>)
                            }
                        </ModalBody>
                        <ModalFooter>
                            {!this.props.assignPacketsToReviewersProcessing && [
                                <OSUButton ariaLabel="No" key="no" color="gray" solid uppercase={false} className="mr-1" onClick={this.toggle}>Okay!</OSUButton>,
                            ]}
                        </ModalFooter>
                    </Modal>

            <div className="d-flex flex-wrap align-items-center">

                {this.generateSearchBar(query, searchScoreSheet)}
                {this.shouldDisplayFilterDropdowns() && <Select placeholder="Category Score" ref={this.categoryScoreSelectRef} value={scoringCriteriaOptions.value} isClearable={true} isSearchable={false} className="fb-20 pr-2 pb-2" onChange={filter => handleFilter({ ...handleFilterProps, filter, type: scoringCriteriaOptions.type })} options={scoringCriteriaOptions.options} />}
                {this.shouldDisplayFilterDropdowns() && <Select placeholder="GPA" ref={this.gpaSelectRef} value={gpaOptions.value} isClearable={true} isSearchable={false} className="fb-20 pr-2 pb-2" onChange={gpa => handleFilter({ ...handleFilterProps, filter: gpa, type: gpaOptions.type })} options={gpaOptions.options} />}
                {this.shouldDisplayFilterDropdowns() && <Select placeholder="Weighted Score" ref={this.weightedScoreSelectRef} value={weightedScoreFilterOptions.value} isClearable={true} isSearchable={false} className="fb-20 pr-2 pb-2" onChange={gpa => handleFilter({ ...handleFilterProps, filter: gpa, type: weightedScoreFilterOptions.type })} options={weightedScoreFilterOptions.options} />}
                {DISABLED_FLAG && showNominationsOptions && this.shouldDisplayFilterDropdowns() && <Select aria-label="Filter table by Nomination" value={nominationOptions.value} isClearable={true} className="fb-20 pr-2 pb-2" placeholder="Nomination" onChange={opt => handleFilter({ ...handleFilterProps, filter: opt, type: nominationOptions.type })} options={nominationOptions.options} />}
            </div>

                <EvaluationList calculateRankedScores={this.props.calculateRankedScores} openDuplicateScoreAlert={this.props.openDuplicateScoreAlert} dismissDuplicateScoreAlert={this.props.dismissDuplicateScoreAlert} reviewerEmail={reviewerEmail} tabIdentifier={this.props.tabIdentifier} updateSortKey={this.updateSortKey} sortKey={this.state.sortKey} nominees={nomineeReviewList} scoringCriteria={scoringCriteria} />
        </div>
    }
}


ScoreSheetContent.defaultProps = {
    nominationOptions: {
        options: [],
        type: '',
        value: []
    },
    gpaOptions: {
        options: [],
        type: '',
        value: []
    },
    scoringCriteriaOptions: {
        options: [],
        type: '',
        value: []
    }
}

ScoreSheetContent.propTypes = {
    query: PropTypes.string,
    nomineeReviewList: PropTypes.array,
    scoreSheetCSVHeaders: PropTypes.array,
    scoringCriteria: PropTypes.arrayOf(PropTypes.shape({
        field: PropTypes.string.isRequired,
        evaluationGuideLines: PropTypes.arrayOf(PropTypes.string).isRequired,
        ranges: PropTypes.object
    })).isRequired,
    getNomineeReviewList: PropTypes.func,
    searchScoreSheet: PropTypes.func,
    nominationOptions: PropTypes.shape({
        type: PropTypes.string,
        options: PropTypes.array,
        value: PropTypes.array
    }),
    gpaOptions: PropTypes.shape({
        type: PropTypes.string,
        options: PropTypes.array,
        value: PropTypes.array
    }),
    scoringCriteriaOptions: PropTypes.shape({
        type: PropTypes.string,
        options: PropTypes.array,
        value: PropTypes.array
    }),
    tabIdentifier: PropTypes.shape({
        index: PropTypes.number,
        type: PropTypes.string
    })
}

export default ScoreSheetContent