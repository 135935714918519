export function handleFilter({ filter = null, type = '', activeFilters = [], filterHandler }) {
    if (filter) {
        if(Array.isArray(filter)){
            filter.forEach(f => f.value && filterHandler(f.value))
        } else {
            filter.value && filterHandler(filter.value)
        }
    } else if (Array.isArray(activeFilters) && activeFilters.length > 0) {
        activeFilters.forEach(item => {
            if (item.type === type) {
                filterHandler({
                    ...item,
                    remove: true
                })
            }
        })
    }

}

export function clearAllFilters(filters = [], filterHandler, searchHandler = null) {
    if (searchHandler) {
        searchHandler('')
    }
    if (Array.isArray(filters) && filters.length > 0) {
        filters.forEach(item => filterHandler({
            ...item,
            remove: true
        }))
    }
}

export function updatedDetails(details = [], newDetail, uniqueId = '') {
    let existingDetail = {};

    const existingDetails = Array.isArray(details) ? details.filter(detail => {
        if (detail[`${uniqueId}`] === newDetail[`${uniqueId}`]) {
            existingDetail = detail;
            return false
        }
        return true
    }) : [];

    const updatedDetail = { ...existingDetail, ...newDetail };

    return existingDetails.concat([updatedDetail])
}