export const PETITION_STATUS_OPTIONS = [
    {
        label: 'Approved',
        value: 'APPROVED'
    },
    {
        label: 'Denied',
        value: 'DENIED'
    }
]

export const NOMINATION_CARD_POST_VALIDATION = {
    gpaEligibilityOverride: {
        allowedValues: ['TRUE', 'FALSE']
    },
    gpaWaiver: {
        allowedValues: ['Y', 'N']
    },
    nominatedFor: {
        allowedType: 'string'
    },
    gpaPetitionApproval: {
        allowedValues: PETITION_STATUS_OPTIONS.map(option => option.value)
    },
    adminComments: {
        allowedType: 'string'
    },
    additionalQuestions: {
        allowedType: 'object'
    }
}