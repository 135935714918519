import { Auth } from 'aws-amplify';

export function buildAction(type, payload) {
    return { type, payload };
}

export function redirectToLogIn() {

    const { oauth } = Auth.configure();

   Auth.federatedSignIn({customProvider:oauth.idp})
    .then(r => {
        console.log("Response from login success.");
    }).catch(err=> {
        console.err("Error from login:",err) ;
    });

}
export function redirectToLogOut() {
//    Auth.signOut().then(r => console.log("Response from sign out success.")).catch(err=>console.err("Error from sign out:",err) );
}


export const systemHeaders = () => {
    return {
        headers: {}
    }
};

export function isUnauthenticated(error) {
    const status = (error.response && error.response.status) ? error.response.status : null;
    const unauthorizedErrors = ["No current user", "Request failed with status code 401"];
    return (unauthorizedErrors.includes(error) || status === 401) ? true : false;
}

export function handleUnauthenticated(error, callback) {
    if(isUnauthenticated(error)) {
        Auth.signOut();
        redirectToLogOut();
    } else {
        callback();
    }
}

export function convertStringToBool(str) {
    if(str === 'TRUE' || str === 'true' || str === 'Y') {
        return true
    } else if(str === 'FALSE' || str === 'false' || str === 'N') {
        return false
    }

    return undefined
}