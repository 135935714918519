import React from 'react'
import moment from "moment";
import { filter, isEmpty } from "lodash";
import { OPTIONS_CONFIGURATION, STUDENT_STATUS_CURRENT } from "./constants";
import { OSUButton, Caption, Icon } from 'osu-react-components'

const _buildOptions = (dataList = [{}], dataStructure) => {
  let {label: labelAttribute, value: valueAttribute, noDataLabel} = dataStructure;
  let options = [{label: noDataLabel, value: ""}]
  if(!isEmpty(dataList[0])){
    options = dataList.map((data) => {
      return {
        label: labelAttribute ? data[labelAttribute] : data,
        value: valueAttribute ? data[valueAttribute] : data
      }
    });
  }
  return options;
}

export function transformTerms(terms = [], isAdmin = false, studentStatus){
  let resultTerms = terms;
  if(!isAdmin){
    if(studentStatus === STUDENT_STATUS_CURRENT){
      resultTerms = [
        ...terms.slice(1,2)
      ]
    } else {
      if(terms.length > 0) {
        const currentTerm = terms[1]; // current term is second to previous term
        const term = currentTerm.descr.split(" ")[0]; // i.e. `${term} ${year} Semester`
        switch(term) {
          case "Autumn":
            resultTerms = [terms[3], terms[4]]; // following year Summer and Autumn
            break;
          case "Spring":
            resultTerms = [terms[2], terms[3]]; // same year Summer and Autumn
            break;
          case "Summer":
            resultTerms = [terms[2], terms[4]]; // same year Autumn and next year Summer
            break;
          default:
            break;
          }

      }
    }
  }
  return resultTerms;
}

export function transformDropdownMetadata(isAdmin = false, dropDownsData = []){
  let transformedData = [];
  for(let dropDown of dropDownsData){
    let {key, adminOnly, label, required, action, resetList, defaultOptionIndex, dataStructure} = OPTIONS_CONFIGURATION.find( (config) => config.key === dropDown.key )
    if(!adminOnly || isAdmin){
      transformedData.push({key, label, required, action, resetList, defaultOptionIndex, options: _buildOptions(dropDown.data, dataStructure)});
    }
  }
  
  return transformedData;
}
export function filterSearchOnProgramStatus(searchResult = {}, isAdmin = false){
  const AC_PROGRAM_STATUS = "AC"
  let data = searchResult.data || []
  // let filteredData = data; //GFN-395: request for all users (Nominators and Admins) to be able to serch for any student and may need reverted later.
  let filteredData = data.filter(item => isAdmin || item.programStatus !== AC_PROGRAM_STATUS)
  if(filteredData.length && filteredData[0].name){
    filteredData.sort((a,b) => {
      var nameA = a.name.toUpperCase();
      var nameB = b.name.toUpperCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
  
      return 0;
    })
  }
  return {state: searchResult.state, data: filteredData};
}

export function transformOptionsForAward(awardInfo){
  const SUCCESS = "success";
  if(!isEmpty(awardInfo) && awardInfo.status !== SUCCESS){
    return [{awardResultLabel: "Loading...", awardResultABBV: ""}]
  }

  let optionsForAward = (awardInfo && awardInfo.data && awardInfo.data.awardResultInfo && awardInfo.data.awardResultInfo.awardOptions) || [];
  let awardOptionsData = optionsForAward.map(element => { 
    let item = {...element["attributes"]}
    item.awardResultLabel = `${item.awardResultName} (${item.awardResultABBV})`
    return item
  })

  return awardOptionsData;
}

export function filterAwardsForAddStudent(awards = [], studentStatus, isAdmin = false){
  const GRAD_SCHOOL = "GRAD_SCHOOL"
  return awards.filter( (award) => {
    let result = false
    let equalStudentStatus =  award.studentStatus === studentStatus
    let nominationStartIsBeforeNow = moment(award.nominationStartDate).isBefore(moment())
    let nominationEndIsAfterNow = moment().isBefore(moment(award.nominationEndDate))
    let nominationsLimitedToGradSchool = award.nominationsLimitedBy === GRAD_SCHOOL
    if(equalStudentStatus && nominationStartIsBeforeNow && nominationEndIsAfterNow){
      if(nominationsLimitedToGradSchool){
        if(isAdmin){
          result = true
        }
      } else {
        result = true
      }
    }
    return result
  })
}

export function filterCurrentAwardsForNominations(currentAwards) {
  // filter where the current date is past the nomination start date minus 7 days
  if(currentAwards && currentAwards.list && currentAwards.list.length > 0) {
    const list = filter(currentAwards.list, currentAward => {
      const nominationStartDate = (currentAward.nominationStartDate ? moment(currentAward.nominationStartDate) : null);
      return (nominationStartDate && nominationStartDate.isValid() && nominationStartDate.subtract(7, "days").isBefore());
    });
    return { ...currentAwards, list };
  }
  return currentAwards;
}

export function filterNomineesByParentContainer (parentContainer, nominees) {
  let emplidList = nominees.map(nominee => nominee.data) //data on nominee record is emplid
  console.log("emplidList", emplidList)
  if(parentContainer === "CurrentStudent") {
      const newNomineeList = [];
      nominees.forEach(nominee =>{
          if(nominee.cumGpa > -0.01){
            let emplid = nominee.data;
            let firstIndex = emplidList.indexOf(emplid);
            let lastIndex = emplidList.lastIndexOf(emplid);
            nominee.multipleNominations = firstIndex !== lastIndex;
            newNomineeList.push(formatNomineesDataToJsx(nominee));
          }
      })
      return newNomineeList;
  } else if (parentContainer === "Recruiting"){
      const newNomineeList = [];
      nominees.forEach(nominee =>{
          if(nominee.cumGpa === undefined){
              let emplid = nominee.data;
              let firstIndex = emplidList.indexOf(emplid);
              let lastIndex = emplidList.lastIndexOf(emplid);
              nominee.multipleNominations = firstIndex !== lastIndex;
              newNomineeList.push(formatNomineesDataToJsx(nominee));
          }
      })
      return newNomineeList;
  }

  return nominees;
}

export function formatNomineesDataToJsx(nominee = {}) {
  let awardKey = window.btoa(nominee.awardKey)
  let pathname = `/nominations/card/${nominee.nomineeId}/${nominee.shortPlan}/${awardKey}`
  let newNominee = {
    ...nominee,
    name: (
      <><OSUButton
        className={"p-0 text-left"}
        uppercase={false}
        variant={"body1"}
        ariaLabel={`${nominee.name}`}
        path={{
          pathname,
          state: {
            ...nominee,
          },
        }}
      >
        {nominee.name}
      </OSUButton>
      {nominee.multipleNominations && 
       <Caption className="mt-1" key={`icons[${nominee.data}]`}><Icon type="info-circle" /> Multiple Nominations</Caption>}
      </>
    ),
  };

  return newNominee
}

export function mergeNomineeRecords (originalRecord = {}, newRecord = {}) {
  const recordSk = originalRecord && originalRecord.sk
  const recordPk = originalRecord && originalRecord.pk
  const { sk, pk } = (newRecord || {})

  let mergedRecord = {}
  if((recordSk === sk) && (recordPk === pk)) {
    mergedRecord = { ...originalRecord, ...newRecord }
  } else {
    mergedRecord = newRecord
  }

  return mergedRecord
}

export function transformAddStudentSearchData(searchData = [], shortPlan, isAdmin) {
  let dataArray = Array.isArray(searchData) ? searchData : [searchData]
  let results = dataArray.filter( item => {
    let itemShortPlan = item.plan && item.plan.split("-")[0]
    return isAdmin || (itemShortPlan.toUpperCase() === shortPlan.toUpperCase())
  })
  return results;
}