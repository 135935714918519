import React, {Component} from "react";

export default class SignOut extends Component {
    componentDidMount() {
        this.props.setSystemAwardValues({}); // clear system award
    }

    render() {
        return (
            <h2>
                You have been logged out.
            </h2>
        );
    }
};