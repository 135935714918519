import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { Form, FormFeedback, FormGroup, Input, Label } from "reactstrap";
import { Body1, Icon, OSUButton } from "osu-react-components";
import { cloneDeep, forEach, isEqual, values } from "lodash";
import { EVENT_RESET_STATE } from "../../constants";
import { FORM_FEEDBACK_SR_PREFIX } from "../../../util/constants";

class AwardResults extends Component {
    componentWillMount() {
        this.setComponentState();
        document.addEventListener(EVENT_RESET_STATE, this.setComponentState);
    }

    componentDidMount() {
        this.validate();
    }

    shouldComponentUpdate(nextProps) {
        return nextProps.isActive; // re-render only when active
    }

    componentWillUnmount() {
        document.removeEventListener(EVENT_RESET_STATE, this.setComponentState);
    }

    setComponentState = () => {
        const { award } = this.props;

        // ensure valid data and at least one award option
        if(!award.awardResultInfo) award.awardResultInfo = {};
        if(!award.awardResultInfo.awardOptions) award.awardResultInfo.awardOptions = [];
        if(!award.awardResultInfo.awardOptions[0]) award.awardResultInfo.awardOptions[0] = this.buildNewAwardOption();

        const invalidAwardOptions = [];
        forEach(award.awardResultInfo.awardOptions, () => {
            invalidAwardOptions.push(false);
        });

        this.setState({
            award,
            invalidAwardOptions
        });
    }

    buildNewAwardOption = () => {
        return { attributes: { awardResultName: "", awardResultABBV: "", awardResultNumberYears: "" } };
    }

    onAwardOptionValidate = (index, isValid, awardOption) => {
        const invalidAwardOptions = [...this.state.invalidAwardOptions];
        invalidAwardOptions[index] = !isValid;
        const state = { invalidAwardOptions };
        if(isValid && !! awardOption) {
            const award = cloneDeep(this.state.award);
            award.awardResultInfo.awardOptions[index] = awardOption;
            state.award = award;
        }
        this.setState(state, () => this.validate());
    }

    onAwardOptionRemove = (index) => {
        const award = cloneDeep(this.state.award);
        const number = (index + 1);
        const focusNumber = (number === award.awardResultInfo.awardOptions.length ? (number - 1) : number);
        award.awardResultInfo.awardOptions.splice(index, 1);
        const invalidAwardOptions = [...this.state.invalidAwardOptions];
        invalidAwardOptions.splice(index, 1);
        this.setState({ award, invalidAwardOptions }, () => {
            this.validate();
            // move focus to the input of the award option name of the same number, or previous number if the same number is not available
            document.getElementById(`awardOption${focusNumber}Name`).focus();
        });
    }

    onAwardOptionAdd = () => {
        const award = cloneDeep(this.state.award);
        award.awardResultInfo.awardOptions.push(this.buildNewAwardOption());
        const invalidAwardOptions = [...this.state.invalidAwardOptions];
        invalidAwardOptions.push(false);
        this.setState({ award, invalidAwardOptions }, () => {
            this.validate();
            // move focus to the input of the new award option name
            document.getElementById(`awardOption${award.awardResultInfo.awardOptions.length}Name`).focus();
        });
    }

    validate = () => {
        const isValid = values(this.state.invalidAwardOptions).every(invalid => (invalid === false));
        this.props.onValidate(isValid, isValid ? this.state.award : null);
    }

    render() {
        const { readOnly, isLocked } = this.props;
        const { award } = this.state;
        const { awardOptions } = award.awardResultInfo;
        return (
            <section>
                <h2 className="heading6" data-testid="awardResultsHeader">Award Results</h2>
                <hr />
                <Form id="awardResultsForm" data-testid="awardResultsForm" noValidate style={{ marginTop: "2rem" }}>
                    <h3 data-testid="awardOptionsHeader"><Body1>Award Result Options</Body1></h3>
                    <div style={{ maxWidth: "35rem" }}>
                        <hr />
                        {awardOptions.map((awardOption, index) => {
                            return (
                                <AwardOption key={`awardOption${index}`} index={index} data={awardOption} isLocked={isLocked} readOnly={readOnly}
                                    onValidate={this.onAwardOptionValidate} onRemove={(awardOptions.length > 1) ? this.onAwardOptionRemove : null} />
                            )
                        })}
                        {(!isLocked && !readOnly) &&
                            <OSUButton link uppercase={false} type="button" ariaLabel="Add Award Result Option" onClick={() => this.onAwardOptionAdd()}>
                                <Icon type="plus" color="blue" size="sm" /> Result Option
                            </OSUButton>
                        }
                    </div>
                </Form>
            </section>
        );
    }
}

AwardResults.defaultProps = {
    isActive: false,
    inReview: false,
    readOnly: false,
    isLocked: false
}

AwardResults.propTypes = {
    award: PropTypes.object.isRequired,
    isActive: PropTypes.bool,
    inReview: PropTypes.bool,
    onValidate: PropTypes.func.isRequired,
    readOnly: PropTypes.bool,
    isLocked: PropTypes.bool
}

export default AwardResults;

class AwardOption extends Component {
    componentWillMount() {
        this.setComponentState();
    }

    componentDidMount() {
        this.validate();
    }

    componentDidUpdate(prevProps) {
        if(!isEqual(prevProps.data, this.props.data)) {
            this.setComponentState();
        }
    }

    setComponentState = () => {
        const awardOption = this.props.data;
        const { attributes = {} } = awardOption;
        const { awardResultName = "", awardResultABBV = "", awardResultNumberYears = "" } = attributes;
        this.setState({
            awardOption,
            invalid: {
                awardResultName: !this.validateRequired(awardResultName),
                awardResultABBV: !this.validateRequired(awardResultABBV),
                awardResultNumberYears: !this.validateOptionalNumberString(awardResultNumberYears)
            },
            number: (this.props.index + 1)
        });
    }

    validateRequired = (value) => {
        return (value && value.length > 0);
    }

    validateOptionalNumberString = (value) => {
        return (!value || (value && !isNaN(value)));
    }

    validate = () => {
        const isValid = values(this.state.invalid).every(invalid => (invalid === false));
        this.props.onValidate(this.props.index, isValid, isValid ? this.state.awardOption : null);
    }

    onFormInputChange = (name, value, validator = this.validateRequired) => {
        const isValid = validator(value);
        const invalid = { ...this.state.invalid, [name]: !isValid };
        const state = { invalid };
        const awardOption = cloneDeep(this.state.awardOption);
        awardOption.attributes[name] = value;
        state.awardOption = awardOption;
        this.setState(state, () => this.validate());
    }

    render() {
        const { index, isLocked, onRemove, readOnly } = this.props;
        const { awardOption, invalid, number } = this.state;
        const { attributes = {} } = awardOption;
        const { awardResultName = "", awardResultABBV = "", awardResultNumberYears = "" } = attributes;
        const label = `Result ${number}: ${awardResultName}`;
        return (
            <Fragment>
                <FormGroup tag="fieldset" data-testid={`awardOption${number}FormGroup`} className="mb-0">
                    <legend className="sr-only">{label}</legend>
                    <h4 data-testid={`awardOption${number}Header`}>
                        <Body1 className="mb-3">
                            {label}
                            {(!isLocked && !readOnly && onRemove) &&
                                <OSUButton link type="button" ariaLabel={`Remove Result ${number}`} className="mb-1" onClick={() => onRemove(index)}>
                                    <Icon type="times" color="red" size="sm" />
                                </OSUButton>
                            }
                        </Body1>
                    </h4>
                    <FormGroup data-testid={`awardOption${number}NameFormGroup`} className="d-flex flex-wrap ml-2">
                        <Label for={`awardOption${number}Name`} className="flex-column inline-center">
                            <Body1 className={`mr-1${readOnly ? "" : " required"}`}>Name:</Body1>
                        </Label>
                        {readOnly ?
                            (<Body1 id={`awardOption${number}Name`} name={`awardOption${number}Name`} dataTestId={`awardOption${number}Name`} className="flex-column">
                                {awardResultName}
                            </Body1>) :
                            (<Fragment>
                                <Input id={`awardOption${number}Name`} name={`awardOption${number}Name`} data-testid={`awardOption${number}Name`}
                                    type="text" value={awardResultName} invalid={invalid.awardResultName} aria-required="true" aria-invalid={invalid.awardResultName}
                                    style={{ maxWidth: "30rem" }} className="flex-column" onChange={e => this.onFormInputChange("awardResultName", e.target.value)} />
                                <FormFeedback aria-live="polite">{FORM_FEEDBACK_SR_PREFIX}<span className="sr-only">Result {number} </span>Name is required.</FormFeedback>
                            </Fragment>)
                        }
                    </FormGroup>
                    <FormGroup data-testid={`awardOption${number}ABBVFormGroup`} className="d-flex flex-wrap ml-2">
                        <Label for={`awardOption${number}ABBV`} className="flex-column inline-center">
                            <Body1 className={`mr-1${(isLocked || readOnly) ? "" : " required"}`}>Abbreviation:</Body1>
                        </Label>
                        {(isLocked || readOnly) ?
                            (<Body1 id={`awardOption${number}ABBV`} name={`awardOption${number}ABBV`} dataTestId={`awardOption${number}ABBV`} className="flex-column">
                                {awardResultABBV}
                            </Body1>) :
                            (<Fragment>
                                <Input id={`awardOption${number}ABBV`} name={`awardOption${number}ABBV`} data-testid={`awardOption${number}ABBV`} type="text" value={awardResultABBV}
                                    invalid={invalid.awardResultABBV} aria-required="true" aria-invalid={invalid.awardResultABBV} disabled={isLocked}
                                    style={{ maxWidth: "6rem" }} className="flex-column" onChange={e => {
                                        if(!isLocked) this.onFormInputChange("awardResultABBV", e.target.value);
                                    }} />
                                <FormFeedback aria-live="polite">{FORM_FEEDBACK_SR_PREFIX}<span className="sr-only">Result {number} </span>Abbreviation is required.</FormFeedback>
                            </Fragment>)
                        }
                    </FormGroup>
                    <FormGroup data-testid={`awardOption${number}NumberYearsFormGroup`} className="d-flex flex-wrap ml-2">
                        <Label for={`awardOption${number}NumberYears`} className="flex-column inline-center">
                            <Body1 className="mr-1">Number of years:</Body1>
                        </Label>
                        {(isLocked || readOnly) ?
                            (<Body1 id={`awardOption${number}NumberYears`} name={`awardOption${number}NumberYears`} dataTestId={`awardOption${number}NumberYears`} className="flex-column">
                                {awardResultNumberYears}
                            </Body1>) :
                            (<Fragment>
                                <Input id={`awardOption${number}NumberYears`} name={`awardOption${number}NumberYears`} data-testid={`awardOption${number}NumberYears`}
                                    type="text" value={awardResultNumberYears} invalid={invalid.awardResultNumberYears} aria-invalid={invalid.awardResultNumberYears}
                                    disabled={isLocked} style={{ maxWidth: "4rem" }} className="flex-column" onChange={e => {
                                        if(!isLocked) this.onFormInputChange("awardResultNumberYears", e.target.value, this.validateOptionalNumberString);
                                    }} />
                                <FormFeedback aria-live="polite">{FORM_FEEDBACK_SR_PREFIX}<span className="sr-only">Result {number} </span>Years must be a number.</FormFeedback>
                            </Fragment>)
                        }
                    </FormGroup>
                    
                </FormGroup>
                <hr className="mt-0" />
            </Fragment>
        );
    }
}

AwardOption.defaultProps = {
    isLocked: false,
    readOnly: false
}

AwardOption.propTypes = {
    data: PropTypes.shape({
        attributes: PropTypes.shape({
            awardResultName: PropTypes.string.isRequired,
            awardResultABBV: PropTypes.string.isRequired,
            awardResultNumberYears: PropTypes.string.isRequired
        }).isRequired
    }),
    index: PropTypes.number.isRequired,
    isLocked: PropTypes.bool,
    onValidate: PropTypes.func.isRequired,
    onRemove: PropTypes.func,
    readOnly: PropTypes.bool
}