import { VIEW_DOCUMENT, VIEW_DOCUMENT_ERROR, VIEW_DOCUMENT_LOADING } from '../actions-index';
// import testData from './testdata.json';
import { API } from "aws-amplify";
import { systemHeaders} from "../util/util";

export function viewDocument(data) {
    return {
        type: VIEW_DOCUMENT,
        payload: data
    }
}

export function viewDocumentIsLoading(isLoading) {
    return {
        type: VIEW_DOCUMENT_LOADING,
        payload: isLoading
    }
}

export function viewDocumentIsError(isError) {
    return {
        type: VIEW_DOCUMENT_ERROR,
        payload: isError
    }
}

export function getViewDocument(documentId,documentSaveName,awardPK) {
    return dispatch => {
        dispatch(viewDocumentIsLoading(true));
        API.get(process.env.REACT_APP_AWARD_NOMINEE_REVIEW_API_NAME, "/getEdmViewDocument/" + documentId+"/"+documentSaveName+"/"+encodeURIComponent(awardPK),systemHeaders())
            .then(response => {
                console.log("getViewDocument response:");
                if(response)
                dispatch(viewDocument(response));
            })
            .catch(error => {
                console.log("getViewDocument response error:",error);
                dispatch(viewDocumentIsError(true));
            })
            .finally(() => {
                dispatch(viewDocumentIsLoading(false));
            });
    }
}
