import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { isEmpty, keys, pick, trim, truncate } from "lodash";
import { Caption, Subtitle2 } from "osu-react-components";
import { FormGroup, Label, Input } from "reactstrap";
import { ADDITIONAL_QUESTIONS, ADDITIONAL_QUESTION_EXPECTED_GRADUATION_DATE } from "../../../AwardManagement/constants";

const INPUT_MAX_LENGTH = "300";
const INPUT_MIN_WIDTH = "17rem";

class Questions extends Component {
    constructor(props) {
        super(props);
        const additionalQuestionKeys = keys(ADDITIONAL_QUESTIONS);
        this.state = { questions: pick(this.props.questions, additionalQuestionKeys) };
    }

    onQuestionChange = (questionKey, questionValue) => {
        if(!this.props.disabled) this.setState({ questions: { ...this.state.questions, [questionKey]: questionValue } });
    }

    onQuestionBlur = (questionKey, questionValue) => {
        if(!this.props.disabled) {
            this.setState(
                { questions: { ...this.state.questions, [questionKey]: truncate(trim(questionValue), { length: INPUT_MAX_LENGTH, omission: "" }) } }, // trim and truncate to max length
                () => this.props.saveChanges({ additionalQuestions: this.state.questions })
            );
        }
    }

    render() {
        const { questions } = this.state;
        if(!isEmpty(questions)) {
            return (
                <Fragment>
                    <FormGroup tag="fieldset" data-testid="questionsFormGroup" style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.1)" }}>
                        <legend>
                            <Subtitle2>Additional Questions (required for nomination)</Subtitle2>
                        </legend>
                        {keys(questions).map((questionKey, index) => {
                            const label = ADDITIONAL_QUESTIONS[questionKey];
                            const value = questions[questionKey];
                            return (
                                <FormGroup key={`question${index}FormGroup`} data-testid={`question${index}FormGroup`}>
                                    <Label>{label}</Label>
                                    <Input type="text" id={`question${index}`} name={`question${index}`} value={value}
                                        onBlur={e => this.onQuestionBlur(questionKey, e.target.value)}
                                        onChange={e => this.onQuestionChange(questionKey, e.target.value)}
                                        disabled={this.props.disabled} style={{ minWidth: INPUT_MIN_WIDTH }}
                                        maxLength={INPUT_MAX_LENGTH} />
                                    {questionKey === ADDITIONAL_QUESTION_EXPECTED_GRADUATION_DATE &&
                                        <Caption color="gray" className="flex-column">Format: Term Year (Example: Spring 2021)</Caption>
                                    }
                                </FormGroup>
                            );
                        })}
                    </FormGroup>
                </Fragment>
            );
        }
        return null;
    }
}

Questions.defaultProps = {
    disabled: false,
    questions: {},
    saveChanges: () => {}
}

Questions.propTypes = {
    disabled: PropTypes.bool,
    questions: PropTypes.object,
    saveChanges: PropTypes.func
}

export default Questions;