import React, { Component } from "react";
import { Alert, Collapse, Nav, Navbar, NavItem, NavLink, NavbarToggler, TabContent, TabPane } from "reactstrap";
import classnames from "classnames";
import { Heading5, OSUError, Subtitle2, Typography, OSUButton } from "osu-react-components";
import CommitteeSelection from "../containers/committee-selection";
import ManageCommittee from "../containers/ManageCommittee";
import "./committee-tabs.css";
import Moment from "moment";
import {PAGE_TITLE_POSTFIX} from "../../../constants";


export default class CommitteeTabs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: "1",
            isNavbarOpen: false,
            pageTitle: this.props.systemAward.name
        };
    }

    componentWillMount() {
        document.title = `${this.state.pageTitle} Committee ${PAGE_TITLE_POSTFIX}`;
    }

    componentDidMount() {
        this.props.setA11yMessage(`Navigated to the ${this.state.pageTitle} Committee page`);
        this.pageContent.focus();
        this.findReviewers();
    }

    componentWillUnmount() {
        this.clearFlags();
    }

    findReviewers = () => {
        this.props.findReviewersByAward(this.props.systemAward.pk);
    }

    toggleTab = (tab) => {
        if (this.state.activeTab !== tab) {
            this.setState({ activeTab: tab });
        }
    }

    setFocusOnAlerts = () => {
        if (this.alerts) this.alerts.focus();
    }

    clearFlags = () => {
        this.props.resetDistributeReviewerPacketsFlags();
        this.props.resetTransferReviewerPacketsFlags();
        this.props.resetTransferReviewerNomineePacketsFlags();
    }

    renderNavLink = (values) => {
        const { title, tabId } = values;
        const active = (this.state.activeTab === tabId);
        return (
            <NavItem key={tabId}>
                <NavLink data-testid={`navLink-${tabId}`} aria-label={`Open tab for ${title}`} className={classnames({ active })} onClick={() => { this.toggleTab(tabId); }}>
                    <Typography color={active ? "black" : "gray"} variant={"body2"}>{title}</Typography>
                </NavLink>
            </NavItem>
        );
    };

    getSystemAwardReviewCommitteeMeetingEndDate = () => {
        const { reviewCommitteeMeetingEndDate } = this.props.systemAward;
        return reviewCommitteeMeetingEndDate ?
            (Moment(reviewCommitteeMeetingEndDate).isValid ? Moment(reviewCommitteeMeetingEndDate) : null) :
            null;
    };

    render() {
        const Alerts = (
            <div ref={(el) => { this.alerts = el; }} tabIndex="-1">
                {this.props.awardReviewersError === true &&
                    <OSUError simple text="Failed to load committee members." ariaLabel="Reload" onClick={this.findReviewers} />
                }
                <Alert data-testid="distributeNomineesSuccessAlert" color="success" isOpen={this.props.distributeReviewerPacketsSuccess === true} toggle={this.props.resetDistributeReviewerPacketsFlags}>
                    <Subtitle2 className="alert-heading">Distribute Nominees</Subtitle2>
                    <p>Packets have been successfully distributed among assigned committee members.</p>
                </Alert>
                <Alert data-testid="distributeNomineesErrorAlert" color="danger" isOpen={this.props.distributeReviewerPacketsError === true} toggle={this.props.resetDistributeReviewerPacketsFlags}>
                    <Subtitle2 className="alert-heading">Distribute Nominees</Subtitle2>
                    <p>An error occurred while distributing packets among assigned committee members.</p>
                </Alert>
                <Alert data-testid="swapNomineesSuccessAlert" color="success" isOpen={this.props.transferReviewerPacketsSuccess === true} toggle={this.props.resetTransferReviewerPacketsFlags}>
                    <Subtitle2 className="alert-heading">Swap Nominees</Subtitle2>
                    <p>Packets have been successfully transferred between committee members.</p>
                </Alert>
                <Alert data-testid="swapNomineesErrorAlert" color="danger" isOpen={this.props.transferReviewerPacketsError === true} toggle={this.props.resetTransferReviewerPacketsFlags}>
                    <Subtitle2 className="alert-heading">Swap Nominees</Subtitle2>
                    <p>An error occurred while transferring packets between committee members.</p>
                </Alert>
                <Alert data-testid="transferNomineeSuccessAlert" color="success" isOpen={this.props.transferReviewerNomineePacketsSuccess === true} toggle={this.props.resetTransferReviewerNomineePacketsFlags}>
                    <Subtitle2 className="alert-heading">Transfer Nominee</Subtitle2>
                    <p>Packet has been successfully transferred between committee members.</p>
                </Alert>
                <Alert data-testid="transferNomineeErrorAlert" color="danger" isOpen={this.props.transferReviewerNomineePacketsError === true} toggle={this.props.resetTransferReviewerNomineePacketsFlags}>
                    <Subtitle2 className="alert-heading">Transfer Nominee</Subtitle2>
                    <p>An error occurred while transferring the packet between committee members. Please verify the target committee member is not already reviewing the selected nominee.</p>
                </Alert>
                <Alert data-testid="assignPacketsSuccessAlert" color="success" isOpen={this.props.assignPacketsToReviewersSuccess === true} toggle={this.props.resetAssignPacketsToSelectedReviewersFlags}>
                    <Subtitle2 className="alert-heading">Assign Packets</Subtitle2>
                    <p>Packets have been successfully assigned.</p>
                </Alert>
            </div>
        );
        const Tabs = [
            { tabId: "1", title: "Committee Selection", component: <CommitteeSelection {...this.props} findReviewers={this.findReviewers} setFocusOnAlerts={this.setFocusOnAlerts} /> }
        ];
        const reviewCommitteeMeetingEndDate = this.getSystemAwardReviewCommitteeMeetingEndDate();
        if(reviewCommitteeMeetingEndDate !== null && Moment().isBefore(reviewCommitteeMeetingEndDate)) {
            Tabs.push({ tabId: "2", title: "Manage Committee", component: <ManageCommittee {...this.props} findReviewers={this.findReviewers} setFocusOnAlerts={this.setFocusOnAlerts} clearFlags={this.clearFlags} /> });
        }
        const expandNavbar = (Tabs.length === 1 || this.props.screenSize >= 576); // expand when there is only one tab or the screen size is larger than small devices
        return (
            <div data-testid="pageContent" ref={(el) => { this.pageContent = el; }} tabIndex="-1" aria-labelledby="pageHeader">
                {Alerts}
                <div className="d-flex flex-wrap mb-2">
                    <div className="flex-fill">
                        <Heading5 dataTestId="pageHeader" id="pageHeader" className="mb-4">{this.state.pageTitle}</Heading5>
                    </div>
                    <div className="flex-fill" align="right">
                        <OSUButton solid color="blue" ariaLabel="Add New Reviewer" 
                            path={{ pathname: "/reviewer-management/add-reviewer" }}>Add New Reviewer</OSUButton>
                    </div>
                </div>
                <Navbar light expand={expandNavbar}>
                    <NavbarToggler onClick={() => this.setState({ isNavbarOpen: !this.state.isNavbarOpen })} />
                    <Collapse isOpen={this.state.isNavbarOpen} navbar>
                        <Nav className="tabs-no-text-select" navbar tabs={expandNavbar} style={{ width: "100%" }}>
                            {Tabs.map(tab => this.renderNavLink(tab))}
                        </Nav>
                    </Collapse>
                </Navbar>
                <TabContent activeTab={this.state.activeTab}>
                    {Tabs.map((tab, index) =>
                        <TabPane key={index} tabId={tab.tabId}>
                            {tab.component}
                        </TabPane>
                    )}
                </TabContent>
            </div>
        );
    }
};