export const AWARD_STATUS_KEY_ACCEPTED = "ACCEPTED";
export const AWARD_STATUS_KEY_ALTERNATE = "ALTERNATE";
export const AWARD_STATUS_KEY_ASSIGNED = "ASSIGNED";
export const AWARD_STATUS_KEY_DECLINED = "DECLINED";
export const AWARD_STATUS_KEY_INVITED = "INVITED";
export const AWARD_STATUS_KEY_NONE = "NONE";
export const AWARD_STATUS_KEY_SELECTED = "SELECTED";
export const AWARD_STATUS_LABEL_ACCEPTED = "Accepted";
export const AWARD_STATUS_LABEL_ALTERNATE = "Alternate";
export const AWARD_STATUS_LABEL_ASSIGNED = "Assigned";
export const AWARD_STATUS_LABEL_DECLINED = "Declined";
export const AWARD_STATUS_LABEL_INVITED = "Invited";
export const AWARD_STATUS_LABEL_NONE = "None";
export const CLEAR_ALL = "";
export const AWARD_STATUS_LABEL_SELECTED = "Selected";
export const AWARD_STATUS_MAP = {
    [AWARD_STATUS_KEY_ACCEPTED]: AWARD_STATUS_LABEL_ACCEPTED,
    [AWARD_STATUS_KEY_ALTERNATE]: AWARD_STATUS_LABEL_ALTERNATE,
    [AWARD_STATUS_KEY_ASSIGNED]: AWARD_STATUS_LABEL_ASSIGNED,
    [AWARD_STATUS_KEY_DECLINED]: AWARD_STATUS_LABEL_DECLINED,
    [AWARD_STATUS_KEY_INVITED]: AWARD_STATUS_LABEL_INVITED,
    [AWARD_STATUS_KEY_NONE]: AWARD_STATUS_LABEL_NONE,
    [AWARD_STATUS_KEY_SELECTED]: AWARD_STATUS_LABEL_SELECTED
};