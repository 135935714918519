import React, { PureComponent } from "react"
import PropTypes from 'prop-types'
import { Alert } from "reactstrap";
import { OSULoading, Subtitle2 } from 'osu-react-components'
import CapsAndWaiversAlert from "../components/CapsAndWaiversAlert";
import withAwardDates from "../../containers/withAwardDates";
import Student from "../containers/Student";
import NominationsCardForm from "../containers/Form";
import { Redirect } from "react-router-dom";
import { FormEnvironmentContext } from "../context";
import { ACTION_STATE_ERROR } from "../../../util/constants";

class Card extends PureComponent {
    componentDidMount() {
        const { getAwardInfo, awardKey, getNominee, location, breadcrumbTrail, refreshNomineeCard } = this.props

        if(refreshNomineeCard) {
            let stateLocation = location.state || {}
            let { nomineeId, shortPlan  } = stateLocation
            getNominee(nomineeId, shortPlan, awardKey)
        }

        if(!!breadcrumbTrail && this.props.updateBreadcrumbTrail) {
            this.props.updateBreadcrumbTrail(breadcrumbTrail)
        }

        getAwardInfo(awardKey)
    }

    componentDidUpdate(prevProps) {
        const prevLength = prevProps.breadcrumbTrail ? prevProps.breadcrumbTrail.length : 0
        const nextLength = this.props.breadcrumbTrail ? this.props.breadcrumbTrail.length : 0
        const getTrailText = (arr) => arr.map(item => item.path + item.text).join() 
        const oldText = getTrailText(prevProps.breadcrumbTrail || [])
        const newText = getTrailText(this.props.breadcrumbTrail || [])

        if((prevLength !== nextLength) || (oldText !== newText)) {
            this.props.updateBreadcrumbTrail(this.props.breadcrumbTrail)
        }

        // refresh nominee failure
        if(prevProps.refreshNomineeState !== ACTION_STATE_ERROR && this.props.refreshNomineeState === ACTION_STATE_ERROR) {
            this.alerts.focus();
        }
    }

    componentWillUnmount() {
        this.props.clearForm()
        this.props.resetNomineeRefresh();
        this.props.updateBreadcrumbTrail([])
    }

    render() {
        const { redirectPath, formConstants, loading, capsAndWaivers = {}, eligibility = {}, nominationInfo = {}, college = "", shortPlan, refreshNomineeState, resetNomineeRefresh } = this.props;
        const { gpaEligibilityWaiver } = eligibility;
        const { nominationsLimitedBy } = nominationInfo;
        if(redirectPath) {
            return <Redirect to={redirectPath} />
        }
        if(loading) {
            return <OSULoading text="Loading nominee card" />
        }
        return (
          <FormEnvironmentContext.Provider value={formConstants}>
            <div ref={(el) => { this.alerts = el; }} tabIndex="-1">
                <Alert color="danger" isOpen={refreshNomineeState === ACTION_STATE_ERROR} toggle={resetNomineeRefresh}>
                    <Subtitle2>Refresh Nominee Failure</Subtitle2>
                    <p className="mb-0">Failed to refresh the nominee. Please retry to see if it resolves the issue.</p>
                </Alert>
            </div>
            <CapsAndWaiversAlert academicProgram={shortPlan} capsAndWaivers={capsAndWaivers} college={college} gpaEligibilityWaiver={gpaEligibilityWaiver} nominationsLimitedBy={nominationsLimitedBy} />
            <Student />
            <NominationsCardForm />
          </FormEnvironmentContext.Provider>
        );
    }
}

Card.defaultProps = {
    formConstants: {}
}

Card.propTypes = {
    formConstants: PropTypes.object,
    getAwardInfo: PropTypes.func,
    initializeNominee: PropTypes.func,
    awardKey: PropTypes.string
}

export default withAwardDates(Card)