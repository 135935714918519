import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import CommitteeTabs from "../components";
import { distributeReviewerPackets, findReviewersByAward, resetDistributeReviewerPacketsFlags, 
  resetTransferReviewerPacketsFlags, resetTransferReviewerNomineePacketsFlags, transferReviewerPackets, 
  transferReviewerNomineePackets, resetAssignPacketsToSelectedReviewersFlags } from "../../../actions-index";

const mapStateToProps = (state, props) => {
  return {
    systemAward: state.systemAward,
    awardReviewers: state.awardReviewers,
    awardReviewersError: state.awardReviewersError,
    awardReviewersLoading: state.awardReviewersLoading,
    distributeReviewerPacketsError: state.distributeReviewerPacketsError,
    distributeReviewerPacketsProcessing: state.distributeReviewerPacketsProcessing,
    distributeReviewerPacketsSuccess: state.distributeReviewerPacketsSuccess,
    transferReviewerPacketsError: state.transferReviewerPacketsError,
    transferReviewerPacketsProcessing: state.transferReviewerPacketsProcessing,
    transferReviewerPacketsSuccess: state.transferReviewerPacketsSuccess,
    transferReviewerNomineePacketsError: state.transferReviewerNomineePacketsError,
    transferReviewerNomineePacketsProcessing: state.transferReviewerNomineePacketsProcessing,
    transferReviewerNomineePacketsSuccess: state.transferReviewerNomineePacketsSuccess,
    assignPacketsToReviewersSuccess: state.assignPacketsToReviewersSuccess,
    props
  };
};

const mapDispatchToProps = dispatch => {
  return {
    distributeReviewerPackets: (awardKey, reviewerId) => dispatch(distributeReviewerPackets(awardKey, reviewerId)),
    findReviewersByAward: (awardKey) => dispatch(findReviewersByAward(awardKey)),
    resetDistributeReviewerPacketsFlags: () => dispatch(resetDistributeReviewerPacketsFlags()),
    resetTransferReviewerPacketsFlags: () => dispatch(resetTransferReviewerPacketsFlags()),
    resetTransferReviewerNomineePacketsFlags: () => dispatch(resetTransferReviewerNomineePacketsFlags()),
    transferReviewerPackets: (awardKey, fromReviewerId, toReviewerId) => dispatch(transferReviewerPackets(awardKey, fromReviewerId, toReviewerId)),
    transferReviewerNomineePackets: (awardKey, nomineeId, fromReviewerId, toReviewerId) => dispatch(transferReviewerNomineePackets(awardKey, nomineeId, fromReviewerId, toReviewerId)),
    resetAssignPacketsToSelectedReviewersFlags: () => dispatch(resetAssignPacketsToSelectedReviewersFlags()),
  };
};

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(CommitteeTabs));
