import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import SecondaryReviewContent from '../components';
import { fetchSecondaryReviewList, searchSecondaryReviewList, addSecondaryReviewFilter, updateSecondaryReviewSortKey, updateSecondaryReviewDetails } from '../../../actions-index'

const mapStateToProps = (state, ownProps) => {
    const { systemAward, secondaryReviewList } = state
    const { list = [], status = '', query = '', sortKey = {}, details = [], filters = [] } = secondaryReviewList
    const { getWeightedScoreDefaultValue } = ownProps

    return {
        list,
        scoringCriteria: state.scoringCriteria,
        awardKey: systemAward && systemAward.pk ? systemAward.pk : null,
        noOfReviewers: systemAward && systemAward.reviewInfo && systemAward.reviewInfo.noOfReviewers ? systemAward.reviewInfo.noOfReviewers : null,
        query,
        status,
        sortKey,
        details,
        filters,
        tabIdentifier: {
            index: ownProps.tabIndex,
            type: 'secondary',
            title: ownProps.title
        },
        defaultScoreValue: getWeightedScoreDefaultValue(secondaryReviewList.filters)
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchSecondaryReviewList: (awardKey, sortKey) => dispatch(fetchSecondaryReviewList(awardKey, sortKey)),
        searchSecondaryReviewList: (query) => dispatch(searchSecondaryReviewList(query)),
        updateSortKey: (sortKey) => dispatch(updateSecondaryReviewSortKey(sortKey)),
        addSecondaryReviewFilter: (filter) => dispatch(addSecondaryReviewFilter(filter)),
        updateSecondaryReviewDetails: (values) => dispatch(updateSecondaryReviewDetails(values))
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SecondaryReviewContent));