import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import ScoringTabs from '../components';
import moment from "moment";

const mapStateToProps = (state, ownProps) => {
    const { systemAward } = state
    const scores = systemAward.nominationInfo && systemAward.reviewInfo && systemAward.reviewInfo.rubric ? systemAward.reviewInfo.rubric.scores : []
    const weightedScoreRanges = Array.isArray(scores) && scores.length > 0 ? createWeightedScoreFilters(scores[0].ranges) : null
    const tabIndex = ownProps.location && ownProps.location.state && typeof ownProps.location.state.initialTabIndex === 'number' ? ownProps.location.state.initialTabIndex : 0
    
    return {
        showAllNomineesColumn: ownProps.isAdmin || verifyReviewerEligibility(ownProps.isReviewer, systemAward),
        weightedScoreRanges,
        tabIndex,
        getWeightedScoreDefaultValue: (activeFilters) => getCurrentWeightedScoreFilters(weightedScoreRanges, activeFilters)
    };
};

function verifyReviewerEligibility(isReviewer, systemAward) {
    const meetingStartDate = (systemAward && systemAward.reviewCommitteeMeetingStartDate) ? systemAward.reviewCommitteeMeetingStartDate : null;
    const meetingEndDate = (systemAward && systemAward.reviewCommitteeMeetingEndDate) ? systemAward.reviewCommitteeMeetingEndDate : null;

    if (meetingStartDate && meetingEndDate && isReviewer) {
        const isToday = moment().isSameOrAfter(moment(meetingStartDate)) && moment().isSameOrBefore(moment(meetingEndDate).add(4,'hours'));
        return isToday;
    }
    return false;
}

function getCurrentWeightedScoreFilters(availableFilters = [], activeFilters = []) {
    if(!Array.isArray(activeFilters) || !Array.isArray(availableFilters) || activeFilters.length < 1 || availableFilters.length < 1) {
        return []
    }
    const matchingValues = []
    availableFilters.filter(o => o.value).forEach(availableFilter => {
        const { value } = availableFilter
        activeFilters.forEach(activeFilter => {
            if(activeFilter.key === value.key && activeFilter.min === value.min && activeFilter.max === value.max) {
                matchingValues.push(availableFilter)
            }
        })
    });

    return matchingValues
}

function createWeightedScoreFilters(ranges) {
    const list = [
        {min:101, max:200},
        {min:201, max:300},
        {min:301, max:400},
        {min:401, max:500},
        {min:501, max:599},

    ];





    return list.map(range => {
            const { min, max } = range;
            return {
                label: `Final Ranked Score (${range.min} - ${range.max})`,
                value: {
                    singleSelect: true,
                    type: 'rubric',
                    key: 'finalRankedScore',
                    min: min - .99, //min is .99 lower than the display value so that our filters capture all possible values, without us needing to display [0,20] [20.01,40], et cetera
                    max: max
                }
            }
    })
}


const mapDispatchToProps = (dispatch) => {
    return {

    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ScoringTabs));

