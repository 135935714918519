import React from 'react';
import { Table, Body2, Icon, OSUButton, OSULoading, PaginationWrapper } from 'osu-react-components';
import PropTypes from 'prop-types';
import { chunk, } from 'lodash';
import { Row, Input, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';
import * as Globals from '../../constants';
import Select from 'react-select';
import { CSVLink } from 'react-csv';

class NominationsTab extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            nominees: [],
            nomineesDetails: [],
            gpaType: "cumGpa",
            dataIndex: 0,
            rowsPerPage: 10
        };

    }

    setDataIndex = dataIndex => {
        this.setState({ dataIndex });
    };

    componentDidMount() {
        if(!(this.props.currentAwards && this.props.currentAwards.prevList))
        this.props.getPreviousAwards();
        if(this.props.academicProgramContext === "ADMIN")
        this.props.getCurrentAwards();
        this.getAllNominees();
    }


    getAllNominees() {
        const key = 'name';
        const direction = 'asc';
        this.props.getAllNomineesForAllAwards({ key, direction }, this.props.academicProgramContext);

    }

    displayStatusFilter(handleFilter, handleFilterProps, weightedScoreRanges) {
        if (window.innerWidth > Globals.MOBILE_MAXIMUM_RESOLUTION) {
            return (<div className="col-4 px-2 d-flex">
                <Select aria-label="Status" placeholder="Status" isMulti isClearable className="w-100 pr-2" onChange={program => handleFilter({ ...handleFilterProps, filter: program, type: "status" })} options={weightedScoreRanges.sort((left, right) => {return left.label > right.label ? 1 : left.label === right.label ? 0 : -1})} />
            </div>)
        }
        return undefined;
    }

    displayNominationsFilter(handleFilter, handleFilterProps, data) {
        if (window.innerWidth > Globals.MOBILE_MAXIMUM_RESOLUTION) {
            return (<div className="col-4 px-2 d-flex">
                <Select isClearable className="w-100 pr-2" isMulti aria-label="Nomination" placeholder="Nomination" onChange={program => handleFilter({ ...handleFilterProps, filter: program, type: "nominatedFor" })} options={data.sort((left, right) => {return left.label > right.label ? 1 : left.label === right.label ? 0 : -1})} />
            </div>)
        }
        return undefined;
    }

    displayAcademicProgramFilter(handleFilter, handleFilterProps, data) {
        if (window.innerWidth > Globals.MOBILE_MAXIMUM_RESOLUTION) {
            return (<div className="col-4 px-2 d-flex">
                <Select isClearable isMulti className="w-100 pr-2" aria-label="Academic Program" placeholder="Academic Program" onChange={program => handleFilter({ ...handleFilterProps, filter: program, type: "shortPlan" })} options={data.sort((left, right) => {return left.label > right.label ? 1 : left.label === right.label ? 0 : -1})} />
            </div>)
        }
        return undefined;
    }

    displayCollegeFilter(handleFilter, handleFilterProps, data) {
        if (window.innerWidth > Globals.MOBILE_MAXIMUM_RESOLUTION) {
            return (<div className="col-6 d-flex pl-2">
                <Select isClearable className="w-100 pr-0" isMulti aria-label="College" placeholder="College" onChange={program => handleFilter({ ...handleFilterProps, filter: program, type: "college" })} options={data.sort((left, right) => {return left.label > right.label ? 1 : left.label === right.label ? 0 : -1})} />
            </div>)
        }
        return undefined;
    }

    displayAwardFilter(handleFilter, handleFilterProps, data) {
        if (window.innerWidth > Globals.MOBILE_MAXIMUM_RESOLUTION) {
            return (<div className="col-6 d-flex pl-2">
                <Select isClearable className="w-100 pr-0" isMulti aria-label="Award" placeholder="Award" onChange={program => handleFilter({ ...handleFilterProps, filter: program, type: "pk" })} options={data.sort((left, right) => {return left.label > right.label ? 1 : left.label === right.label ? 0 : -1})} />
            </div>)
        }
        return undefined;
    }

    setRowsPerPage = rowsPerPage => {
        this.setState({ rowsPerPage });
    };

    dataKeys = (gpaType) => {

        const keys = Globals.MOBILE_MAXIMUM_RESOLUTION < window.innerWidth ? [

            {
                key: 'name',
                label: 'Name',

                className: 'align-self-top min-width-0',
                width: 40
            },
            {
                key: gpaType,
                label: 'GPA',

                className: 'align-self-top min-width-0',
                width: 10
            },
            {
                key: 'data',
                label: 'Student ID',

                className: 'align-self-top min-width-0',
                width: 20
            },
            {
                key: 'nominatedFor',
                label: 'Nomination',

                className: 'align-self-top min-width-0',
                width: 15
            },
            {
                key: 'status',
                label: 'Status',

                className: 'align-self-top min-width-0',
                width: 15
            }
        ]


            :
            [{
                key: 'name',
                label: "Name",

                className: 'align-self-start',
                width: 100
            }
            ]
            ;
        return keys
    };

    searchbarStyle() {
        return "pl-2 col-4 d-flex";
    }

    changePreviousAwardsCheckbox = (event) => {
        this.props.changePreviousAwardsCheckbox(event.target.checked);
    }

    render() {
        const { currentAwards, handleFilter, nominees, gpaType, nomineesCsvHeaders, nomineesCsvData, filters, query, addAwardNomineesFilter, searchAwardNominees, isAdmin } = this.props;
        const rowsPerPage = this.state.rowsPerPage;
        const dataIndex = this.state.dataIndex;
        const {nominations,statuses,shortPlans,colleges,awards} = this.props.filterLists;

        const data = nominees &&  currentAwards.list && currentAwards.list.length > 0 ? nominees.filter(nominee => currentAwards.list.some(award => nominee.pk === award.pk) || this.props.isPreviousAwardsBoxChecked) : [];
        data.forEach( nominee => {
            if(this.props.parentContainer === "Recruiting" && nominee.externalUgrdGpa === undefined){
                nominee.externalUgrdGpa = "-"
            } else if (this.props.parentContainer === "Recruiting"){
                nominee.externalUgrdGpa = nominee.externalUgrdGpa.toString();
            }          
        })
        const handleFilterProps = {
            activeFilters: filters,
            filterHandler: addAwardNomineesFilter
        };
        if (this.state.expandAll || this.state.collapseAll) {
            this.setState({
                expandAll: false,
                collapseAll: false
            });
        }
        const chunkedData = data && data.length > 0 ?
            chunk(data, rowsPerPage || data.length) : [{}];

        const searchbarLabel = "EMPLID, Name"

        const resultsData = () => {
            return data.length > 0 ? { shownResults: data.length, totalResults: data.length } : {};
        }
        if (this.props.status && this.props.status === "loading") {
            return (<OSULoading dataTestId="loading" text="Loading..." />);
        } else {
            return <div>
                <Body2 className="mb-1 align-items-bottom"><span className="pt-4">Filter Content &nbsp;&nbsp;&thinsp; | </span>
                    <OSUButton uppercase={false} variant="body2" ariaLabel="Clear all filters" link onClick={() => this.props.clearAllFilters(filters, addAwardNomineesFilter, searchAwardNominees)}>Clear All</OSUButton>
                </Body2>
                <CSVLink aria-label="Download table as CSV file" filename={`${new Date().toLocaleDateString()}-all-nominees.csv`} headers={nomineesCsvHeaders} data={nomineesCsvData}>
                            <Icon color="blue" className="mr-2" type="download" />Download CSV
                </CSVLink>
                {/* <Row className="px-2 align-items-center"> */}
                <Row className="pb-1">
                    <InputGroup className={this.searchbarStyle()}>
                        <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                                <Icon type="search" color="gray" />
                            </InputGroupText>
                        </InputGroupAddon>
                        <Input type="text" aria-label={searchbarLabel} placeholder={searchbarLabel} value={query} onChange={(e) => searchAwardNominees(e.target.value)} />
                    </InputGroup>
                    {
                        <div className ="col-8 d-flex px-0">
                            {isAdmin && this.displayCollegeFilter(handleFilter, handleFilterProps, colleges)}
                            {this.displayAwardFilter(handleFilter, handleFilterProps, awards)}
                        </div>
                    }

                </Row>
                <Row>

                    {this.displayNominationsFilter(handleFilter, handleFilterProps, nominations)}
                    {this.displayStatusFilter(handleFilter, handleFilterProps, statuses)}
                    {this.displayAcademicProgramFilter(handleFilter, handleFilterProps, shortPlans)}

                </Row>
                <div className="col-6 px-3 d-flex"><Input 
            checked={this.props.isPreviousAwardsBoxChecked}
            value="previousAwardsCheckBox"
            onChange={(event) => this.changePreviousAwardsCheckbox(event)}
            type="checkbox"
            id="previous-awards-checkbox"
          />
          Show Nominees for Previous Awards
          </div>
                <PaginationWrapper
                    persist
                    totalPageCount={chunkedData.length}
                    updateDataIndex={this.setDataIndex}
                    updateRowsPerPage={this.setRowsPerPage}
                    dataIndex={dataIndex}
                    rowsPerPageOptions={[10, 20, 30]}
                    rowsPerPage={rowsPerPage}
                    resultsData={resultsData()}
                    showOptionalCount={true}
                >
                    <Table multiOpen sortable
                        paginationData={{
                            rowsPerPage: rowsPerPage || 9999,
                            dataIndex: dataIndex,
                        }}
                        hover={false} headerVariant="subtitle2"
                        data={data}
                        dataKeys={this.dataKeys(gpaType)} />
                </PaginationWrapper>
                <br />
                {/* {this.grantAwardToSelected(handleFilter, handleFilterProps, defaultScoreValue, nominations)} */}

            </div>
        }
    }
}


NominationsTab.propTypes = {
    nominees: PropTypes.array,
    nomineesDetails: PropTypes.array,
    gpaType: PropTypes.string,
    awardKey: PropTypes.string,
    status: PropTypes.string,
    filters: PropTypes.array,
    query: PropTypes.string,
    scoringCriteria: PropTypes.array,
    programDropdown: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.shape({
            singleSelect: PropTypes.bool,
            type: '',
            key: ''
        })
    })),
    defaultScoreValue: PropTypes.array,
    defaultShortPlanValue: PropTypes.array
};

export default NominationsTab