import { Auth } from 'aws-amplify';
const apiConfig = {
  'aws_user_pools': 'enable',
  'aws_user_pools_mfa_type': 'OFF',
  'aws_user_pools_id': process.env.REACT_APP_AWS_USER_POOLS_ID,
  'aws_user_pools_web_client_id': process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID,

  'customAuthStorage': {
    // REQUIRED - Cookie domain (only required if cookieStorage is provided)
      'domain': process.env.REACT_APP_CUSTOM_AUTH_STORAGE_DOMAIN,
    // OPTIONAL - Cookie path
      'path': '/',
    // OPTIONAL - Cookie expiration in days
     'expires': 1,
    // OPTIONAL - Cookie secure flag
      'secure': process.env.REACT_APP_CUSTOM_AUTH_STORAGE_SECURE === "true" ? true : false
  },

  'oauth': {
    // 'identityPoolId' : process.env.REACT_APP_AWS_IDENTITY_POOL_ID,
    // Domain name
    'idp': process.env.REACT_APP_SAML_PROVIDER,
    'domain' : process.env.REACT_APP_OAUTH_DOMAIN,
    
    // Authorized scopes
    'scope' : ['aws.cognito.signin.user.admin','openid', 'profile'], 
  
    // Callback URL
    'redirectSignIn' : process.env.REACT_APP_SIGNIN, 
    'redirectSignOut': process.env.REACT_APP_SIGNOUT,
    
    // 'code' for Authorization code grant, 
    // 'token' for Implicit grant
    'responseType': 'code'
  },
  'API': {
    'endpoints': [
      {
        'name': process.env.REACT_APP_AWARD_REVIEWER_API_NAME,
        'endpoint': process.env.REACT_APP_AWARD_REVIEWER_API_URL,
        'region': 'us-east-2',
        'custom_header': async () => { 
          let session = await Auth.currentSession()
            .then(data => {console.log('this will have a session if available')
          return data; })
            .catch(err =>
              { 
                console.log('this will fire if no session')
                return null; 
              })

           return { Authorization: session ? session.idToken.jwtToken : "" } 
         }
      },
      {
        'name': process.env.REACT_APP_AWARD_NOMINEE_REVIEW_API_NAME,
        'endpoint': process.env.REACT_APP_AWARD_NOMINEE_REVIEW_API_URL,
        'region': 'us-east-2',
        'custom_header': async () => { 
          let session = await Auth.currentSession()
            .then(data => {console.log('this will have a session if available')
          return data; })
            .catch(err =>
              { 
                console.log('this will fire if no session')
                return null; 
              })

           return { Authorization: session ? session.idToken.jwtToken : "" } 
         }
      },
      {
        'name': process.env.REACT_APP_GRAD_AWARD_API_NAME,
        'endpoint': process.env.REACT_APP_GRAD_AWARD_API_URL,
        'region': 'us-east-2',
        'custom_header': async () => { 
          let session = await Auth.currentSession()
            .then(data => {console.log('this will have a session if available')
          return data; })
            .catch(err =>
              { 
                console.log('this will fire if no session')
                return null; 
              })

           return { Authorization: session ? session.idToken.jwtToken : "" } 
         }
      },
      {
        'name': process.env.REACT_APP_GRAD_AWARD_API_NAME + "-public-validate",
        'endpoint': process.env.REACT_APP_GRAD_AWARD_API_URL + "/validateAwardLetter",
        'region': 'us-east-2',
      },
      {
        'name': process.env.REACT_APP_GRAD_AWARD_API_NAME + "-public-submit",
        'endpoint': process.env.REACT_APP_GRAD_AWARD_API_URL + "/submitAcceptance",
        'region': 'us-east-2',
      }
    ]
  },
  Analytics: {
    disabled: true
  }
};

export default apiConfig;
