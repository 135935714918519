import React from 'react'
import { Table, Body2, OSUButton, Icon, PaginationWrapper } from 'osu-react-components'
import PropTypes from 'prop-types'
import { find, chunk, map, pick } from 'lodash'
import EvaluationDetails from '../../Evaluation/components/Details';
import * as Globals from '../../../constants'
class ReviewSheetTable extends React.Component {
    wrapNumber = (num, color = 'gray') => <Body2 className="text-center" color={color}>{num}</Body2>;


    constructor(props) {
        super(props);
        this.state = {
            rowsPerPage: 10,
            dataIndex: 0
        };
    }

    headerButton = (key, label, type, replaceDash = undefined) => {
        return <OSUButton ariaLabel={`Sort by ${label}`}
            className="p-0 text-left" color="black" uppercase={false} link variant="subtitle2"
            onClick={() => this.props.updateSortKey({
                key,
                type,
                replaceDash
            })}>
            {label}
        </OSUButton>
    };

    setDataIndex = dataIndex => {
        this.setState({ dataIndex });
    };

    setRowsPerPage = rowsPerPage => {
        this.setState({ rowsPerPage });
    };

    reviewerColumns = () => {
        const { noOfReviewers } = this.props;

        if (!noOfReviewers || typeof noOfReviewers !== 'number') {
            return {}
        }
        let data = [];

        for (let i = 0; i < noOfReviewers; i++) {
            const match = i === 0 ? {
                key: 'PRIMARY',
                label: 'Composite Reviewer'
            } : {
                    key: `SECONDARY${i}`,
                    label: `Reviewer ${i}`
                };

            data.push({
                key: match.key,
                label: this.headerButton(match.key, match.label, 'reviewer', true),
                width: 45 / noOfReviewers,
                className: 'text-center'
            });
        }

        return data
    };

    dataKeys = () => [{
        key: 'name',
        label: "Name",
        width: window.innerWidth > Globals.MOBILE_MAXIMUM_RESOLUTION ? 35 : 80,
    },
    ...Array.isArray(this.reviewerColumns()) && window.innerWidth > Globals.MOBILE_MAXIMUM_RESOLUTION ? this.reviewerColumns() : [],
    // {
    //     key: 'finalScore',
    //     label: this.headerButton('finalWeightedScore', 'Final Weighted Score'),
    //     width: window.innerWidth > Globals.MOBILE_MAXIMUM_RESOLUTION ? 20 : 20,
    //     className: 'text-center',
    //     sortFormatter: (val) => (isNaN(val) ? 0 : Number(val))
    // }
];

    determineNumber = (val) => typeof val === 'string' || (typeof val === 'number' && val > 0) ? val.toString() : '-'

    convertListToJsx = ({ list = [], details, updateDetails, scoringCriteria }) => {
        const expectedColumns = this.reviewerColumns() || [];
        let filteredList = list.filter(nom => nom.reviewers !== undefined);
        const primaries = Array.isArray(filteredList) ? filteredList.map((nom, index) => {
            const { reviewers } = nom;
            const hasComments = Array.isArray(nom.reviewers) ? nom.reviewers.filter(review => review.comment && review.comment.toString().trim()).length > 0 : false;

            const expansionAction = (rowIndex) => updateDetails({ ...nom, rowIndex });
            const reviewerScores = expectedColumns.map((col, index) => {
                if (index === 0) {
                    const primaryReviewer = find(reviewers, o => o.type === 'PRIMARY');
                    return {
                        reviewer: primaryReviewer,
                        [col.key]: this.determineNumber(primaryReviewer.finalWeightedScore),
                        [col.value]: "primary"
                    }
                }
                const currentReviewer = find(reviewers, o => o.type === `SECONDARY${index}` || (reviewers.length === 2 && o.type.includes("SECONDARY")));
                return {
                    reviewer: currentReviewer,
                    [col.key]: this.determineNumber(currentReviewer.finalWeightedScore)
                }
            });

            const match = find(details, detail => detail.nomineeId === nom.nomineeId)
            const hasScoreRangeCheck = (typeof find(nom.reviewers, reviewer => reviewer.notEvaluatedCount !== 0 && reviewer.rankedScore === 0) === "undefined");
            const hasScoreMinFlag = (typeof find(pick(nom, map(scoringCriteria, "field")), ["flag", true]) !== "undefined");
            let lastEditedByAdmin = false;
            nom.reviewers.forEach(reviewer => {
                if(reviewer.lastEditedBy && !reviewer.lastEditedBy.match(reviewer.email)){
                    lastEditedByAdmin = true;
                }
            });
            return {
                ...nom,
                innerExpandedAction: expansionAction,
                name: <span>
                    <OSUButton className={'p-0 text-left'} uppercase={false}
                        variant={'body1'}
                        ariaLabel={`Navigate to evaluation for ${nom.nomineeId}`}
                        path={{
                            pathname: `/nominee-reviews/evaluation/${nom.nomineeId}/${nom.shortPlan}`,
                            state: {
                                tabIdentifier: this.props.tabIdentifier
                            }
                        }}>{nom.name}</OSUButton>
                   
                    {hasScoreRangeCheck && <sup><Icon type="check" color="green" size="xs" className="pl-1" ariaLabel="Reviews are complete and in range." /></sup>}
                    {hasScoreMinFlag && <sup><Icon size="xs" className="pl-1" type="info" color="gray" ariaLabel="Category minimum qualifying score not met." /></sup>}
                    {(nom.comment || hasComments) && <sup><Icon size="xs" className="pl-1" type="commenting-o" color="gray" ariaLabel="Comments available to view." /></sup>}
                    {lastEditedByAdmin && <sup><Icon size="xs" className="pl-1" type="asterisk" color="black" ariaLabel="Admin has made a change on your behalf" /></sup>}
                </span>,
                ...reviewerScores.reduce(function (result, current) {
                    return Object.assign(result, current);
                }, {}),
                finalScore: this.determineNumber(nom.finalWeightedScore),
                minimumScore: this.determineNumber(nom.minimumQualifyingScore),
                expandedContent: match ? <EvaluationDetails {...match} order={expectedColumns.map(col => col.key)} reloadAction={expansionAction} maskReviewers={false} nomineeId={nom.nomineeId} scoringCriteria={scoringCriteria} /> : <div />
            }
        }) : [];

        return primaries
    };

    render() {
        const { list, details, updateDetails, scoringCriteria } = this.props;
        const jsxList = this.convertListToJsx({ list, details, updateDetails, scoringCriteria });
        const rowsPerPage = this.state.rowsPerPage;
        const dataIndex = this.state.dataIndex;

        const chunkedData = jsxList && jsxList.length > 0 ?
            chunk(jsxList, rowsPerPage || jsxList.length) : [{}];
        // const truncatedData = chunkedData[dataIndex] ? chunkedData[dataIndex] : [];
        return (<PaginationWrapper
            persist
            totalPageCount={chunkedData.length}
            updateDataIndex={this.setDataIndex}
            updateRowsPerPage={this.setRowsPerPage}
            dataIndex={dataIndex}
            rowsPerPageOptions={[10, 20, 30]}
            rowsPerPage={rowsPerPage}
            resultsData={{ shownResults: jsxList.length, totalResults: jsxList.length }}
            showOptionalCount={true}
        >
            <Table hover={false} sortable headerVariant="subtitle2" multiOpen
                paginationData={{
                    rowsPerPage: rowsPerPage,
                    dataIndex: dataIndex,
                }}
                data={jsxList} defaultUnsorted expandable dataKeys={this.dataKeys()} />
        </PaginationWrapper>)

    }
}


ReviewSheetTable.defaultProps = {
    list: [],
    details: [],
    scoringCriteria: [],
    dataIndex: 0
}

ReviewSheetTable.propTypes = {
    tabIdentifier: PropTypes.shape({
        index: PropTypes.number,
        type: PropTypes.string
    }),
    primaryReviewList: PropTypes.array
}

export default ReviewSheetTable