import { SAVE_AWARD_INFO, AWARD_NOMINEES, AWARD_NOMINEES_STATUS, AWARD_NOMINEES_SORT_KEY, AWARD_NOMINEES_DETAILS, AWARD_NOMINEES_FILTER, AWARD_NOMINEES_QUERY, AWARD_DOCUMENT_REFRESH, SHOW_PREVIOUS_AWARDS, SHOW_PREVIOUS_AWARDS_FOR_AWARDED } from '../../actions-index'
import { sortList, constructFilters, constructSort, searchList, searchListByQuery } from '../../redux-helpers';
import { updatedDetails } from '../util';

export function awardNominees(state = {}, action) {
    const searchableProperties = ['name', 'nomineeId', 'email', 'status', { key: 'reviewers', nestedProperty: 'email', formatter: value => { return value.replace("@osu.edu", ""); } }]
    const { _list } = state

    switch (action.type) {
        case AWARD_NOMINEES:
            return {
                ...state,
                list: action.payload,
                _list: action.payload,
                status: 'success'
            };
        case AWARD_NOMINEES_STATUS:
            return {
                ...state,
                status: action.payload
            };
        case AWARD_NOMINEES_DETAILS:
            return {
                ...state,
                details: updatedDetails(state.details, action.payload, 'nomineeId')
            };
        case AWARD_NOMINEES_SORT_KEY:
            const newSortKey = constructSort(state.sortKey, action.payload)

            return {
                ...state,
                sortKey: newSortKey,
                list: sortList(state.list, newSortKey)
            };
        case AWARD_NOMINEES_QUERY:
            const searchedList = searchList({ query: action.payload, list: _list, searchableProperties, filters: state.filters, sortKey: state.sortKey, filteringAction: filterAwardNominees })

            return {
                ...state,
                query: action.payload,
                list: searchedList
            }
        case AWARD_NOMINEES_FILTER:
            const allFilters = constructFilters(state.filters || [], action.payload)
            const filteredList = filterAwardNominees({ list: _list, filters: allFilters, query: state.query, searchableProperties, sortKey: state.sortKey })
            return {
                ...state,
                filters: allFilters,
                list: filteredList
            }
        case AWARD_DOCUMENT_REFRESH:
            return {
                ...state,
                docList: action.payload
            }
        case SAVE_AWARD_INFO:
            return {
                ...state,
                getCurrentAwardInfoResult: action.payload
            }
        case SHOW_PREVIOUS_AWARDS:
            return {
                ...state,
                isPreviousAwardsBoxChecked: action.payload
            }
        case SHOW_PREVIOUS_AWARDS_FOR_AWARDED:
            return {
                ...state,
                isPreviousAwardsBoxCheckedForAwarded: action.payload
            }
        default:
            return state
    }
}

function filterAwardNominees(values) {
    const { list, filters, query, sortKey, searchableProperties } = values
    let nomineeList = Array.isArray(list) ? getNomineesMatchingConditions(list, filters) : []

    if (query) {
        nomineeList = searchListByQuery({ query, list: nomineeList, searchableProperties })
    }
    if (sortKey) {
        nomineeList = sortList(nomineeList, sortKey)
    }

    return nomineeList
}

function getNomineesMatchingConditions(list = [], filters = []) {
    function onlyUnique(value, index, self) {
        return self.indexOf(value) === index;
    }
    const uniqueFilters = filters.filter(onlyUnique);
    const newList = list.filter(item => {
        const matchingNomProperties = uniqueFilters.filter((filter, index) => {
            const { type, key } = filter

            if (type === "nominatedFor" && key && item.hasOwnProperty(type)) {
                const itemValue = item[type];
                return itemValue === key;
            } else if (filter.type === 'rubric' && item.hasOwnProperty(filter.key)) {
                const score = item[`${filter.key}`];
                return score >= filter.min && score <= filter.max
            } else if (type === "status" && key && item.hasOwnProperty(type)) {
                const itemValue = item[type];
                return itemValue === key;
            } else if (type === "shortPlan" && key && item.hasOwnProperty(type)) {
                const itemValue = item[type];
                return itemValue === key;
            } else if (type === "displayShortPlan" && key && item.hasOwnProperty(type)) {
                const itemValue = item[type];
                return itemValue === key;
            } else if (type === "college" && key) {
                return item["college"] === key;

            } else if (type === "pk" && key) {
                return item["pk"] === key;

            }
            return false
        })

        let uniqueTypes = filters.map(filter => filter.type).filter(onlyUnique);
        return matchingNomProperties.length === uniqueTypes.length;
    })
    return newList
}