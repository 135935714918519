import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import ReviewerSwitchboard from '../components/ReviewerSwitchboard';
import { getCurrentAwards, setSystemAwardValues } from '../../Common/actions';

const mapStateToProps = (state, ownProps) => {
    return {
        awards: state.currentAwards.list || [],
        systemAward: state.systemAward,
        user: state.user,
        userIsLoading: state.userIsLoading
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getCurrentAwards: () => dispatch(getCurrentAwards()),
        setSystemAwardValues: (award) => dispatch(setSystemAwardValues(award))
    };
};


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ReviewerSwitchboard));

