import React, { useState } from 'react';
import PropTypes from 'prop-types'
import { FormGroup, Label, Input } from 'reactstrap';
import { Typography } from 'osu-react-components'
import { uniqueId } from 'lodash';
import PDFLink from '../../../Common/PDFLink';
import FileUpload from '../../../Common/FileUpload';
import usePacket from '../hooks/usePacket';
import { useContext } from 'react';
import { FormEnvironmentContext } from '../context';

function ManageFiles (props = {}) {
    const { label, name, type, updateFiles } = props
    const { awardKey, nomineeId, readonly, shortPlan } = useContext(FormEnvironmentContext)
    const { documentId, documentName, caption } = usePacket(label)
    const [selected, setSelected] = useState('ignore')
    const uniqName = type || uniqueId('radio-input-manage-files')

    const handleSelection = (value) => {
      let document = {
        action: value
      }
      if(value === 'delete') {
        document = {
          ...document,
          type,
          documentId,
          documentName,
          awardKey, 
          nomineeId,
          shortPlan
        }
      }
      updateFiles(document)
      setSelected(value)
    }
    
    function uploadFile(files) {
        let fileUpload = {
            action: 'upload',
            type,
            file: files,
            awardKey, 
            nomineeId,
            shortPlan
        }

        files && !!files.name && setSelected('replace')
        
        updateFiles(fileUpload)
    }

    if(!documentId || !documentName) {
        return (
          <div className="d-flex align-items-center">
            <Typography className="pr-2">
              No {label} available:&nbsp;
            </Typography>
            <FileUpload
              disabled={readonly}
              id={uniqName + "upload-file"}
              name={uniqName + "upload-file"}
              className="my-2"
              uploadFiles={(value) => uploadFile(value)}
            />
          </div>
        );
    }

    return (
      <div className="d-flex flex-column">
        <PDFLink
            className="mb-1"
            documentId={documentId}
            name={name}
            shortPlan={shortPlan}
            awardKey={awardKey}
          >
            Current {documentName} (PDF)
        </PDFLink>
        <FormGroup tag="fieldset" className="mb-2">
          <legend className="sr-only">Update {label}</legend>
          <RadioInput label="Do not modify" name={uniqName} onChange={(value) => handleSelection(value)} action="ignore" selected={selected} />
          <RadioInput label={`Replace ${label}`} name={uniqName} onChange={(value) => handleSelection(value)} action="replace" selected={selected}>
            {selected === "replace" && <FileUpload
              disabled={readonly}
              id={uniqName + "replace-file"}
              name={uniqName + "replace-file"}
              className="my-2"
              uploadFiles={(value) => uploadFile(value)}
            />}
          </RadioInput>
          <RadioInput label={`Delete ${label}`} name={uniqName} onChange={(value) => handleSelection(value)} action="delete" selected={selected} />
        </FormGroup>
        {caption && <Typography variant="caption" className="mb-3">{caption}</Typography>}
      </div>
    );
}

const RadioInput = (props = {}) => {
  const { name, onChange, action, selected, label, children } = props;
  const { readonly } = useContext(FormEnvironmentContext);

  return (
    <FormGroup check>
      <Label for={name + "-" + action} check>
        <Input
          disabled={readonly}
          value={action}
          onChange={(event) => onChange(event.target.value)}
          id={name + "-" + action}
          checked={selected === action}
          type="radio"
          name={name}
        />
        {label}
      </Label>
      {children}
    </FormGroup>
  );
};

ManageFiles.defaultProps = {
    label: 'Packet',
    onChange: (values) => console.log('values', values)
}

ManageFiles.propTypes = {
    label: PropTypes.oneOf(['Packet', 'GPA Petition']).isRequired,
    name: PropTypes.string,
    onChange: PropTypes.func
}

export default ManageFiles