import { forEach, orderBy } from 'lodash';

var hash = require('object-hash');

export function setApiStatus(type, status) {
    return {
        type,
         payload: status 
    }
}

export function sortList(list, sortKey) {
    let sortedList;
    if (sortKey.nestedProperty) {
        sortedList = orderBy(list, o => {
            return o[sortKey.key] && o[sortKey.key][sortKey.nestedProperty]
        }, sortKey.direction);

        return sortedList
    }

    if(sortKey.key === "random" && list){
        const hashedList = list.map(nominee => {

            nominee.hash = hash({
                name: nominee.name,
                awardKey: nominee.awardKey,
                shortPlan: nominee.shortPlan,
                reviewerName: nominee.reviewer ? nominee.reviewer.name : nominee.reviewers ? nominee.reviewers[0].name+nominee.reviewers[1].name+nominee.reviewers[2].name : undefined
            });
            return nominee;
        })
        sortedList = orderBy(hashedList, "hash", sortKey.direction);
    } else{
        sortedList = orderBy(list, sortKey.key, sortKey.direction);

    }
    return sortedList
}

export function constructFilters(existingFilters, newFilter) {
    const { remove = false, type } = newFilter;
    if(remove) {
        return existingFilters.filter(o => o.type !== newFilter.type)
    } 

    const singleSelectFilters = Array.isArray(existingFilters) ? existingFilters.filter(filter => {
        if (newFilter.singleSelect) {
            return filter.type !== type
        } else {
            return filter
        }
    }) : [];

    return [...singleSelectFilters, newFilter]
}

export function constructSort(existingSort, newSort) {
    const sortKeyBody = {
        key: newSort.key,
        nestedProperty: newSort && newSort.nestedProperty ? newSort.nestedProperty : undefined,
        direction: newSort.direction ? newSort.direction : existingSort && existingSort.key && existingSort.key === newSort.key && existingSort.direction && existingSort.direction === 'asc' ? 'desc' : 'asc'
    };

    return sortKeyBody;
}

export function searchList(values) {
    const { query, list, searchableProperties, filters, sortKey, filteringAction } = values;
    let searchedList = searchListByQuery({ query, list, searchableProperties });
    if (filters) {
        searchedList = filteringAction({ list: searchedList, filters })
    }
    if (sortKey) {
        searchedList = sortList(searchedList, sortKey)
    }

    return searchedList

}

export function searchListByQuery(values) {
    const { query, list, searchableProperties } = values;

    let filteredList = list;
    if (query && query.trim()) {
        filteredList = Array.isArray(list) ? list.filter(nom => {
            const matchingParams = searchableProperties.filter(param => {
                const nomineeValue = getPropertyValue(nom, param);
                if (nomineeValue && nomineeValue.includes(query.toLowerCase())) {
                    return param
                } else {
                    return undefined
                }
            });
            if (matchingParams.length > 0) {
                return nom
            } else {
                return undefined
            }
        }) : []

    }

    return filteredList
}

function getPropertyValue(object, property) {
    let propertyValue = null;
    if(object && property) {
        switch(typeof property) {
            case "string":
                if(typeof object[property] !== "undefined") propertyValue = object[property].trim().toLowerCase();
                break ;
            case "object":
                if(typeof property.key !== "undefined") {
                    let objectProperty = object[property.key];
                    if(typeof objectProperty !== "undefined") {
                        if(Array.isArray(objectProperty)) { // array of object
                            forEach(objectProperty, (arrayObject, arrayIndex) => {
                                if(arrayIndex === 0) {
                                    propertyValue = [];
                                }
                                if(typeof property.nestedProperty !== "undefined") { // array of object
                                    let nestedObjectProperty = arrayObject[property.nestedProperty];
                                    if(typeof nestedObjectProperty !== "undefined") {
                                        nestedObjectProperty = nestedObjectProperty.trim().toLowerCase();
                                        if(typeof property.formatter !== "undefined") {
                                            nestedObjectProperty = property.formatter(nestedObjectProperty);
                                        }
                                        propertyValue.push(nestedObjectProperty);
                                    }
                                } else if( typeof arrayObject === "string") { // array of string
                                    propertyValue.push(arrayObject.trim().toLowerCase());
                                }
                            });
                            if(Array.isArray(propertyValue) && propertyValue.length === 0) propertyValue = null;
                        } else { // object
                            if(typeof property.nestedProperty !== "undefined") {
                                let nestedObjectProperty = objectProperty[property.nestedProperty];
                                if(typeof nestedObjectProperty !== "undefined") {
                                    nestedObjectProperty = nestedObjectProperty.trim().toLowerCase();
                                    if(typeof property.formatter !== "undefined") {
                                        nestedObjectProperty = property.formatter(nestedObjectProperty);
                                    }
                                    propertyValue = nestedObjectProperty;
                                }
                            }
                        }
                    }
                }
                break;
            default:
        }
    }
    return propertyValue;
}