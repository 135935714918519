import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import SignOut from "../components/SignOut";
import { setSystemAwardValues } from "../../Common/actions";

const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return {
        setSystemAwardValues: (systemAward) => dispatch(setSystemAwardValues(systemAward))
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SignOut));
