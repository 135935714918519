import { PRIMARY_REVIEWS_LIST, PRIMARY_REVIEW_DETAILS, PRIMARY_REVIEWS_STATUS, SEARCH_PRIMARY_REVIEWS_LIST, ADD_PRIMARY_REVIEWS_LIST_FILTER, ADD_PRIMARY_REVIEWS_SORT_KEY } from '../../actions-index'
import { constructFilters, constructSort, searchList, searchListByQuery } from '../../redux-helpers';
import { orderBy } from 'lodash'
import { updatedDetails } from '../util';
var hash = require('object-hash');

export function primaryReviewList(state = {}, action) {
    const searchableProperties = ['name', 'nomineeId']
    const { _list } = state
    switch (action.type) {
        case PRIMARY_REVIEWS_LIST:
            return {
                ...state,
                list: action.payload,
                _list: action.payload,
                status: 'success'
            }
        case PRIMARY_REVIEWS_STATUS:
            return {
                ...state,
                status: action.payload
            }
        case SEARCH_PRIMARY_REVIEWS_LIST:
            const searchedList = searchList({ query: action.payload, list: _list, searchableProperties, filters: state.filters, sortKey: state.sortKey, filteringAction: addPrimaryListFilters })

            return {
                ...state,
                query: action.payload,
                list: searchedList
            }
        case ADD_PRIMARY_REVIEWS_LIST_FILTER:
            const allFilters = constructFilters(state.filters || [], action.payload)
            const filteredList = addPrimaryListFilters({ list: _list, filters: allFilters, query: state.query, searchableProperties, sortKey: state.sortKey })

            return {
                ...state,
                filters: allFilters,
                list: filteredList
            }
        case ADD_PRIMARY_REVIEWS_SORT_KEY:
            const newSortKey = {
                ...action.payload,
                ...constructSort(state.sortKey, action.payload)
            }

            return {
                ...state,
                sortKey: newSortKey,
                list: sortPrimaryReviewList(state.list || [], newSortKey)
            }
        case PRIMARY_REVIEW_DETAILS:
            return {
                ...state,
                details: updatedDetails(state.details, action.payload, 'nomineeId')
            }
        default:
            return state
    }
}

function addPrimaryListFilters(values) {
    const { list, filters, query, sortKey, searchableProperties } = values
    const getPrimaryNomineesMatchingConditions = (list = [], filters = []) => {
        const filteredList = list.filter(item => {
            const matchingNomProperties = filters.filter((filter, index) => {
                if (filter.type === 'rubric' && item.hasOwnProperty(filter.key)) {
                    const score = item[`${filter.key}`]
                    return score >= filter.min && score <= filter.max
                }
                return false
            })

            return matchingNomProperties.length === filters.length
        })

        return filteredList
    }

    let primaryList = Array.isArray(list) && Array.isArray(filters)
        ? getPrimaryNomineesMatchingConditions(list, filters) : []

    if (query) {
        primaryList = searchListByQuery({ query, list: primaryList, searchableProperties })
    }
    if (sortKey) {
        primaryList = sortPrimaryReviewList(primaryList, sortKey)
    }

    return primaryList
}

function sortPrimaryReviewList(list, sortKey) {
    const determineNumber = (val) => typeof val === 'string' || (typeof val === 'number' && val > 0) ? val : '-'

    let sortedList;
    if(sortKey.key === "random" && list && list[0] && !list[0].hash){
        const hashedList = list.map(nominee => {

            let jointReviewerName = "";
            if(nominee.reviewers && nominee.reviewers.length > 0) {
                nominee.reviewers.forEach(reviewer => {
                    jointReviewerName += reviewer.name;
                });
            } 

            nominee.hash = hash({
                name: nominee.name,
                awardKey: nominee.awardKey,
                shortPlan: nominee.shortPlan,
                reviewerName: nominee.reviewer ? nominee.reviewer.name : nominee.reviewers ? jointReviewerName : undefined
            });

            return nominee;
        })
        sortedList = orderBy(hashedList, "hash", sortKey.direction);
    } else {
        sortedList = orderBy(list, o => {
            if (sortKey.type === 'reviewers') {
                const { reviewers } = o
                const matchingReviewer = Array.isArray(reviewers) && reviewers.find(o => o.type === sortKey.key)
                return determineNumber(matchingReviewer.finalWeightedScore)
            }
            return o[sortKey.key]
        }, sortKey.direction)
    }

    

    return sortedList
}