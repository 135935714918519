import { SECONDARY_REVIEWS_LIST, SEARCH_SECONDARY_REVIEWS_LIST, SECONDARY_REVIEWS_STATUS, ADD_SECONDARY_REVIEWS_SORT_KEY, ADD_SECONDARY_REVIEWS_LIST_FILTER, SECONDARY_REVIEW_DETAILS } from '../../actions-index'
import { API } from "aws-amplify";
import { setApiStatus } from "../../redux-helpers";
import { systemHeaders} from '../../util/util';

export function fetchSecondaryReviewList(awardKey, sortKey) {
    const setSecondaryReviewStatus = (status) => setApiStatus(SECONDARY_REVIEWS_STATUS, status);

    return (dispatch) => {
        dispatch(setSecondaryReviewStatus('loading'));

        API.get(process.env.REACT_APP_AWARD_NOMINEE_REVIEW_API_NAME, `/getReviewerSecondaryNominees/${window.btoa(awardKey)}`, systemHeaders())
            .then(response => {
                if (response.data) {
                    dispatch({
                        type: SECONDARY_REVIEWS_LIST,
                        payload: response.data
                    });
                }else if(response.status!==undefined && response.status===401) {
                    console.error("401 Response in fetchSecondaryReviewList:",response.status);
                    dispatch(setSecondaryReviewStatus('401'));
                } else {
                    console.error("Response error fetchSecondaryReviewList:",response);
                    dispatch(setSecondaryReviewStatus('error'));
                }
            })
            .catch(error => {
                console.error("Error in fetchSecondaryReviewList:",error);
                if (error === 'No current user'|| error==='Request failed with status code 401' || (error.response!==undefined && error.response.status!==undefined && error.response.status===401)) {
                    dispatch(setSecondaryReviewStatus('401'));
                } else {
                    dispatch(setSecondaryReviewStatus('error'));
                }
            })
            .finally(() => sortKey && dispatch(updateSecondaryReviewSortKey(sortKey)))
    }
}

export function updateSecondaryReviewSortKey(sortKey) {
    return {
        type: ADD_SECONDARY_REVIEWS_SORT_KEY,
        payload: sortKey
    }
}


export function searchSecondaryReviewList(query) {
    return {
        type: SEARCH_SECONDARY_REVIEWS_LIST,
        payload: query
    }
}

export function addSecondaryReviewFilter(filter) {
    return {
        type: ADD_SECONDARY_REVIEWS_LIST_FILTER,
        payload: filter
    }
}

export function updateSecondaryReviewDetails(list) {
    return {
        type: SECONDARY_REVIEW_DETAILS,
        payload: list
    }
}