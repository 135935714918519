import React, { Component, Fragment } from "react";
import { Alert, Form, FormFeedback, FormGroup, Input, Label } from "reactstrap";
import { Body1, Caption, OSUButton } from "osu-react-components";
import { cloneDeep, isEmpty, values } from "lodash";
import PropTypes from "prop-types";
import { AWARD_DATE_FORMAT, EVENT_RESET_STATE, STUDENT_STATUSES } from "../../../constants";
import { FORM_FEEDBACK_SR_PREFIX } from "../../../../util/constants";
import moment from "moment";
import AwardLetterDocumentDefinition from "./DocumentDefinition";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

const DATE_DISPLAY_FORMAT = "M/D/YYYY h:mmA";
const DATE_INPUT_WIDTH = "15rem";
const DATE_LABEL_WIDTH = "15rem";

class AwardLetter extends Component {
    componentWillMount() {
        this.setComponentState();
        document.addEventListener(EVENT_RESET_STATE, this.setComponentState);
    }

    componentDidMount() {
        this.validate();
    }

    shouldComponentUpdate(nextProps) {
        return nextProps.isActive; // re-render only when active
    }

    componentWillUnmount() {
        document.removeEventListener(EVENT_RESET_STATE, this.setComponentState);
    }

    setComponentState = () => {
        const { award } = this.props;
        if(!award.awardLetter) {
            award.awardLetter = { templateEmailSubject: "", templateEmailBody:  "", awardDetailsUrl: "", templatePreAwardSummary: "", templatePostAwardSummary: "", awardeeLetters: [] };
            if(award.combineAwardResults === true) {
                award.awardLetter = { ...award.awardLetter, combinedAwardPostedDate: "", combinedAwardLetterSendOptionDate: "", combinedAwardLetterSentDate: "" };
            }
        }
        const { awardLetter } = award;
        const state = { 
            award,
            combinedAwardPostedDate: "",
            combinedAwardLetterSendOptionDate: "",
            combinedAwardLetterSentDate: "",
            invalid: {
                combinedAwardPostedDate: false,
                combinedAwardLetterSendOptionDate: false,
                combinedAwardLetterSentDate: false
            },
            pdfDocument: this.buildAwardLetterPDFDocument(award)
        }
        const combinedAwardPostedDate = !isEmpty(awardLetter.combinedAwardPostedDate) ? this.parseAwardDate(awardLetter.combinedAwardPostedDate) : null;
        if(combinedAwardPostedDate !== null) {
            if(combinedAwardPostedDate.isValid()) state.combinedAwardPostedDate = combinedAwardPostedDate.format(DATE_DISPLAY_FORMAT);
            state.invalid.combinedAwardPostedDate = !combinedAwardPostedDate.isValid();
        }
        const combinedAwardLetterSendOptionDate = !isEmpty(awardLetter.combinedAwardLetterSendOptionDate) ? this.parseAwardDate(awardLetter.combinedAwardLetterSendOptionDate) : null;
        if(combinedAwardLetterSendOptionDate !== null) {
            if(combinedAwardLetterSendOptionDate.isValid()) state.combinedAwardLetterSendOptionDate = combinedAwardLetterSendOptionDate.format(DATE_DISPLAY_FORMAT);
            state.invalid.combinedAwardLetterSendOptionDate = !combinedAwardLetterSendOptionDate.isValid();
        }
        const combinedAwardLetterSentDate = !isEmpty(awardLetter.combinedAwardLetterSentDate) ? this.parseAwardDate(awardLetter.combinedAwardLetterSentDate) : null;
        if(combinedAwardLetterSentDate !== null) {
            if(combinedAwardLetterSentDate.isValid()) state.combinedAwardLetterSentDate = combinedAwardLetterSentDate.format(DATE_DISPLAY_FORMAT);
            state.invalid.combinedAwardLetterSentDate = !combinedAwardLetterSentDate.isValid();
        }
        this.setState(state);
    }

    parseAwardDate = (dateString) => {
        return moment(dateString, AWARD_DATE_FORMAT, true);
    }

    buildAwardLetterPDFDocument = (award) => {
        const { awardLetter, createAwardLetter = false, externalUrl } = award;
        if(createAwardLetter === true) {
            return pdfMake.createPdf(new AwardLetterDocumentDefinition({
                firstName: "{FIRST}",
                lastName: "{LAST_NAME}",
                program: "{ACAD_PLAN}",
                templatePreAwardSummary: awardLetter.templatePreAwardSummary,
                awards: [{ name: "{AWARD_NAME}", numberYears: "{AWARD_YEARS}" }],
                awardDetailsUrl: (award.combineAwardResults ? awardLetter.awardDetailsUrl : externalUrl),
                templatePostAwardSummary: awardLetter.templatePostAwardSummary
            }));
        }
        return null;
    }

    onDateChange = (event) => {
        const input = event.target;
        this.setState({ [input.name]: input.value });
    }
    
    onDateBlur = (event) => {
        const input = event.target;
        const state = { invalid: this.state.invalid };
        if(input.value === "") { // not required
            const award = cloneDeep(this.state.award);
            award.awardLetter[input.name] = input.value;
            state.award = award;
            state.invalid[input.name] = false;
        } else {
            const date = moment(input.value, DATE_DISPLAY_FORMAT, true);
            if(date.isValid()) {
                const award = cloneDeep(this.state.award);
                award.awardLetter[input.name] = date.format(AWARD_DATE_FORMAT);
                state.award = award;
            }
            state.invalid[input.name] = !date.isValid();
        }
        this.setState(state, () => {
            this.validate();
        });
    }

    onFormInputChange = (event) => {
        const award = cloneDeep(this.state.award);
        award.awardLetter[event.target.name] = event.target.value;
        this.setState({ award, pdfDocument: this.buildAwardLetterPDFDocument(award) }, () => {
            this.validate();
        });
    }

    validate = () => {
        let isValid = values(this.state.invalid).every(invalid => (invalid === false));
        this.props.onValidate(isValid, isValid ? this.state.award : null);
    }

    render() {
        const { readOnly } = this.props;
        const { award, combinedAwardPostedDate, combinedAwardLetterSendOptionDate, combinedAwardLetterSentDate, invalid, pdfDocument } = this.state;
        const { academicYear, awardLetter, awardTerm, combineAwardResults = false, createAwardLetter = false, studentStatus } = award;
        const { templateEmailSubject = "", templateEmailBody = "", awardDetailsUrl = "", templatePreAwardSummary = "", templatePostAwardSummary = "" } = awardLetter;
        return (
            <section>
                <h2 className="heading6" data-testid="awardLetterHeader">Award Letter</h2>
                <hr />
                <Alert color="info" data-testid="noAwardLetterAlert" isOpen={!createAwardLetter}>
                    Not available for this award.
                </Alert>
                {createAwardLetter &&
                    <Fragment>
                        <Alert color="info" data-testid="combinedAwardAlert" isOpen={combineAwardResults}>
                            This award is listed as a combined award.  This award template applies to all combined awards with the Academic Year {academicYear}, Award Term {awardTerm}, and Student Status {STUDENT_STATUSES[studentStatus]}.
                        </Alert>
                        <Form id="awardLetterForm" data-testid="awardLetterForm" noValidate>
                            {combineAwardResults &&
                                <FormGroup tag="fieldset" data-testid="datesFormGroup">
                                    <legend>
                                        <h3><Body1 className="font-weight-bold">Dates</Body1></h3>
                                        <div className="d-flex flex-wrap my-2">
                                            <Caption color="gray" className="flex-column">Format: {DATE_DISPLAY_FORMAT}&nbsp;</Caption>
                                            <Caption color="gray" className="flex-column">(Example: {moment().format(DATE_DISPLAY_FORMAT)})</Caption>
                                        </div>
                                        <hr />
                                    </legend>
                                    <FormGroup data-testid="combinedAwardPostedDateFormGroup" className="d-flex flex-wrap">
                                        <Label for="combinedAwardPostedDate" className="flex-column pt-2" style={{ minWidth: DATE_LABEL_WIDTH }}>
                                            <Body1>Awards Posted:</Body1>
                                        </Label>
                                        <div className="flex-column">
                                            {readOnly ?
                                                (<Body1 id="combinedAwardPostedDate" name="combinedAwardPostedDate" dataTestId="combinedAwardPostedDate" className="flex-column pt-2">
                                                    {combinedAwardPostedDate}
                                                </Body1>) :
                                                (<Fragment>
                                                    <Input id="combinedAwardPostedDate" name="combinedAwardPostedDate" data-testid="combinedAwardPostedDate"
                                                        type="text" style={{ width: DATE_INPUT_WIDTH }} value={combinedAwardPostedDate}
                                                        invalid={invalid.combinedAwardPostedDate} aria-invalid={invalid.combinedAwardPostedDate}
                                                        onChange={this.onDateChange} onBlur={this.onDateBlur} />
                                                    <FormFeedback aria-live="polite">{FORM_FEEDBACK_SR_PREFIX}Awards Posted must be a valid date.</FormFeedback>
                                                </Fragment>)
                                            }
                                        </div>
                                    </FormGroup>
                                    <FormGroup data-testid="combinedAwardLetterSendOptionDateFormGroup" className="d-flex flex-wrap-combinedAwardLetterSendOptionDate">
                                        <Label for="combinedAwardLetterSendOptionDate" className="flex-column pt-2" style={{ minWidth: DATE_LABEL_WIDTH }}>
                                            <Body1>Award Letters Send Option:</Body1>
                                        </Label>
                                        <div className="flex-column">
                                            {readOnly ? 
                                                (<Body1 id="combinedAwardLetterSendOptionDate" name="combinedAwardLetterSendOptionDate" dataTestId="combinedAwardLetterSendOptionDate" className="flex-column pt-2">
                                                    {combinedAwardLetterSendOptionDate}
                                                </Body1>):
                                                (<Fragment>
                                                    <Input id="combinedAwardLetterSendOptionDate" name="combinedAwardLetterSendOptionDate" data-testid="combinedAwardLetterSendOptionDate"
                                                        type="text" style={{ width: DATE_INPUT_WIDTH }} value={combinedAwardLetterSendOptionDate}
                                                        invalid={invalid.combinedAwardLetterSendOptionDate} aria-invalid={invalid.combinedAwardLetterSendOptionDate}
                                                        onChange={this.onDateChange} onBlur={this.onDateBlur} />
                                                    <FormFeedback aria-live="polite" className="break-word">{FORM_FEEDBACK_SR_PREFIX}Award Letters Send Option must be a valid date.</FormFeedback>
                                                </Fragment>)
                                            }
                                        </div>
                                    </FormGroup>
                                    <FormGroup data-testid="combinedAwardLetterSentDateFormGroup" className="d-flex flex-wrap">
                                        <Label for="combinedAwardLetterSentDate" className="flex-column pt-2" style={{ minWidth: DATE_LABEL_WIDTH }}>
                                            <Body1>Award Letters Sent:</Body1>
                                        </Label>
                                        <div className="flex-column">
                                            {readOnly ?
                                                (<Body1 id="combinedAwardLetterSentDate" name="combinedAwardLetterSentDate" dataTestId="combinedAwardLetterSentDate" className="flex-column pt-2">
                                                    {combinedAwardLetterSentDate}
                                                </Body1>) :
                                                (<Fragment>
                                                    <Input id="combinedAwardLetterSentDate" name="combinedAwardLetterSentDate" data-testid="combinedAwardLetterSentDate"
                                                        type="text" style={{ width: DATE_INPUT_WIDTH }} value={combinedAwardLetterSentDate}
                                                        invalid={invalid.combinedAwardLetterSentDate} aria-invalid={invalid.combinedAwardLetterSentDate}
                                                        onChange={this.onDateChange} onBlur={this.onDateBlur} />
                                                    <FormFeedback aria-live="polite">{FORM_FEEDBACK_SR_PREFIX}Award Letters Sent must be a valid date.</FormFeedback>
                                                </Fragment>)
                                            }
                                        </div>
                                    </FormGroup>
                                </FormGroup>
                            }
                            <FormGroup tag="fieldset" data-testid="emailFormGroup">
                                <legend>
                                    <h3><Body1 className="font-weight-bold">Email</Body1></h3>
                                    <hr />
                                </legend>
                                <FormGroup data-testid="templateEmailSubjectFormGroup">
                                    <Label for="templateEmailSubject"><Body1>Subject:</Body1></Label>
                                    {readOnly ?
                                        (<Body1 id="templateEmailSubject" name="templateEmailSubject" dataTestId="templateEmailSubject" className="mx-2">{templateEmailSubject}</Body1>) :
                                        (<Input id="templateEmailSubject" name="templateEmailSubject" data-testid="templateEmailSubject" type="text"
                                            value={templateEmailSubject} onChange={e => this.onFormInputChange(e)} />)
                                    }
                                </FormGroup>
                                <FormGroup data-testid="templateEmailBodyFormGroup">
                                    <Label for="templateEmailBody" className="mb-0"><Body1>Body:</Body1></Label>
                                    {readOnly ?
                                        (<Body1 id="templateEmailBody" name="templateEmailBody" dataTestId="templateEmailBody" className="mx-2">{templateEmailBody}</Body1>) :
                                        (<Input id="templateEmailBody" name="templateEmailBody" data-testid="templateEmailBody" type="textarea" rows="10" 
                                                style={{ resize: "vertical" }} value={templateEmailBody} onChange={e => this.onFormInputChange(e)} />)
                                    }
                                </FormGroup>
                            </FormGroup>
                            <FormGroup tag="fieldset" data-testid="templateFormGroup">
                                <legend>
                                    <h3><Body1 className="font-weight-bold">Template</Body1></h3>
                                    <hr />
                                </legend>
                                {combineAwardResults &&
                                    <FormGroup data-testid="awardDetailsUrlFormGroup">
                                        <Label for="awardDetailsUrl"><Body1>Award Details URL:</Body1></Label>
                                        {readOnly ?
                                            (<Body1 id="awardDetailsUrl" name="awardDetailsUrl" dataTestId="awardDetailsUrl" className="mx-2">
                                                {!isEmpty(awardDetailsUrl) &&
                                                    <a rel="noopener noreferrer" href={awardDetailsUrl} target="_blank">{awardDetailsUrl}</a>
                                                }
                                            </Body1>) :
                                            (<Input id="awardDetailsUrl" name="awardDetailsUrl" data-testid="awardDetailsUrl" type="text" value={awardDetailsUrl} onChange={this.onFormInputChange}  />)
                                        }
                                    </FormGroup>   
                                }
                                <FormGroup data-testid="templatePreAwardSummaryFormGroup">
                                    <Label for="templatePreAwardSummary"><Body1>Before Award Summary:</Body1></Label>
                                    {readOnly ?
                                        (<Body1 id="templatePreAwardSummary" name="templatePreAwardSummary" dataTestId="templatePreAwardSummary" className="mx-2">{templatePreAwardSummary}</Body1>) :
                                        (<Input id="templatePreAwardSummary" name="templatePreAwardSummary" data-testid="templatePreAwardSummary" type="textarea"
                                            rows="10" style={{ resize: "vertical" }} value={templatePreAwardSummary} onChange={this.onFormInputChange} />)
                                    }
                                </FormGroup>
                                <FormGroup data-testid="templatePostAwardSummaryFormGroup">
                                    <Label for="templatePostAwardSummary"><Body1>After Award Summary:</Body1></Label>
                                    {readOnly ?
                                        (<Body1 id="templatePostAwardSummary" name="templatePostAwardSummary" dataTestId="templatePostAwardSummary" className="mx-2">{templatePostAwardSummary}</Body1>) :
                                        (<Input id="templatePostAwardSummary" name="templatePostAwardSummary" data-testid="templatePostAwardSummary" type="textarea"
                                            rows="10" style={{ resize: "vertical" }} value={templatePostAwardSummary} onChange={this.onFormInputChange} />)
                                    }
                                </FormGroup>
                            </FormGroup>
                        </Form>
                        <OSUButton link uppercase={false} ariaLabel="Download Award Letter Example" onClick={() => pdfDocument.download("award_letter_example.pdf")}>Download Award Letter Example</OSUButton>
                    </Fragment>
                }
            </section>
        );
    }
}

AwardLetter.defaultProps = {
    isActive: false,
    readOnly: false
}

AwardLetter.propTypes = {
    award: PropTypes.object.isRequired,
    isActive: PropTypes.bool,
    onValidate: PropTypes.func.isRequired,
    readOnly: PropTypes.bool
}

export default AwardLetter;