import React, { Component } from "react";
import Select, { components as SelectComponents } from "react-select";
import { Icon, OSUButton, OSULoading, Body1, Body2, Caption, Table } from "osu-react-components";
import * as Constants from "../../constants";
import _ from "lodash";

const ACTION_KEY_SWAP = "SWAP";
const ACTION_KEY_DISTRIBUTE = "DISTRIBUTE";
const ACTION_KEY_TRANSFER = "TRANSFER";
const SELECT_MAX_WIDTH = "26rem";
const SelectedLabelSelect = props => (
    <SelectComponents.SingleValue {...props}>
        {props.data.selectedLabel}
    </SelectComponents.SingleValue>
);
const SelectStyles = {
    control: (provided, state) => {
        const isInvalid = state.selectProps.isInvalid === true;
        const borderColor = isInvalid ? "red" : provided.borderColor; // validation error styling
        return { ...provided, borderColor, "&:hover": { borderColor } }
    },
    option: provided => ({
        ...provided,
        whiteSpace: "pre-wrap" // supports multi-line option labels
    })
}

export default class ManageCommittee extends Component {
    constructor(props) {
        super(props);
        this.state = {
            action: null,
            actions: [
                { label: "Unable to serve, swap nominees with alternate.", value: ACTION_KEY_SWAP, 
                    desc: "Selecting this action displays fields that allow you to swap nominees from a committee member to an alternate." },
                { label: "Unable to serve, evenly distribute nominees to the rest of the committee.", value: ACTION_KEY_DISTRIBUTE,
                    desc: "Selecting this action displays fields that allow you to distribute the nominees from a committee member to the rest of the committee." },
                { label: "Conflict with nominee, transfer nominee to another committee member.", value: ACTION_KEY_TRANSFER,
                    desc: "Selecting this action displays fields that allow you to transfer a nominee from one committee member to another." }
            ],
            alternateSelectOptions: [],
            memberSelectOptions: [],
            nomineeSelectOptions: [],
            swapFromMemberOption: null,
            swapToMemberOption: null,
            swapConflictKeys: [
                { key: "nomineeId", label: "Nominee ID", width: 33 },
                { key: "name", label: "Name", width: 33 },
                { key: "shortPlan", label: "Academic Program", width: 34 }
            ],
            swapConflicts: [],
            swapped: false,
            distributeFromMemberOption: null,
            distributed: false,
            transferFromMemberOption: null,
            transferNomineeOption: null,
            transferToMemberOption: null,
            transferred: false
        };
    }

    componentDidUpdate(prevProps) {
        if(prevProps.awardReviewers.length === 0 && this.props.awardReviewers.length > 0) { // received award reviewers
            const alternateSelectOptions = [];
            const memberSelectOptions = [];
            _.forEach(this.props.awardReviewers, (reviewer, index) => {
                if(reviewer.status === Constants.AWARD_STATUS_KEY_ALTERNATE) { // alternate
                    alternateSelectOptions.push(this.buildMemberSelectOption(reviewer));
                }
                if(reviewer.status === Constants.AWARD_STATUS_KEY_ASSIGNED) { // member
                    memberSelectOptions.push(this.buildMemberSelectOption(reviewer));
                }
                if(index === (this.props.awardReviewers.length - 1)) {
                    this.setState({ alternateSelectOptions, memberSelectOptions });
                }
            });
        }

        if(prevProps.nominees.length === 0 && this.props.nominees.length > 0) { // received nominees
            if(this.state.action === ACTION_KEY_SWAP) { // swap - find conflicts
                const shortPlans = (this.state.swapToMemberOption && this.state.swapToMemberOption.data.shortPlans) ? this.state.swapToMemberOption.data.shortPlans : [];
                if(shortPlans.length > 0) {
                    const swapConflicts = [];
                    _.forEach(this.props.nominees, (nominee, index) => {
                        if(shortPlans.includes(nominee.shortPlan)) {
                            swapConflicts.push({
                                nomineeId: (<Body2 key={`swapConflicts[${index}].nomineeId`}>{nominee.nomineeId}</Body2>),
                                name: (<Body2 key={`swapConflicts[${index}].name`}>{this.buildNomineeName(nominee)}</Body2>),
                                shortPlan: (<Body2 key={`swapConflicts[${index}].shortPlan`}>{nominee.shortPlan}</Body2>)
                            });
                        }
                        if(index === (this.props.nominees.length - 1)) {
                            this.setState({ swapConflicts });
                        }
                    });
                }
            }
            
            if(this.state.action === ACTION_KEY_TRANSFER) { // transfer - build select options
                const nomineeSelectOptions = [];
                _.forEach(this.props.nominees, (nominee, index) => {
                    nomineeSelectOptions.push(this.buildNomineeSelectOption(nominee));
                    if(index === (this.props.nominees.length - 1)) {
                        this.setState({ nomineeSelectOptions });
                    }
                });
            }
        }
        
        if(this.props.transferReviewerPacketsError === true || this.props.distributeReviewerPacketsError === true || 
            this.props.transferReviewerNomineePacketsError === true) { // errors
            this.props.setFocusOnAlerts(); // focus on alert
        }

        if(this.props.transferReviewerPacketsSuccess === true && this.state.swapped === true) { // swap - success
            this.props.setFocusOnAlerts(); // focus on alert
            this.setState(
                { action: null, swapFromMemberOption: null, swapToMemberOption: null, swapConflicts: [], swapped: false, 
                    alternateSelectOptions: [], memberSelectOptions: [] }, // clean-up
                () => this.props.findReviewers() // pull the latest reviewer updates
            );
        }

        if(this.props.distributeReviewerPacketsSuccess === true && this.state.distributed === true) { // distribute - success
            this.props.setFocusOnAlerts(); // focus on alert
            this.setState(
                { action: null, distributeFromMemberOption: null, distributed: false, 
                    alternateSelectOptions: [], memberSelectOptions: [] }, // clean-up
                () => this.props.findReviewers() // pull the latest reviewer updates
            );
        }

        if(this.props.transferReviewerNomineePacketsSuccess === true && this.state.transferred === true) { // transfer - success
            this.props.setFocusOnAlerts(); // focus on alert
            this.setState(
                { action: null, transferFromMemberOption: null, transferNomineeOption: null, transferToMemberOption: null, transferred: false, 
                    alternateSelectOptions: [], memberSelectOptions: [], nomineeSelectOptions: [] }, // clean-up
                () => this.props.findReviewers() // pull the latest reviewer updates
            );
        }
    }

    buildMemberSelectOption = (member) => {
        let label = member.name;

        const stats = member.stats || {};
        if(stats.totalNominees && stats.totalPrimaryNominees) {
            label += `\n${stats.totalNominees} nominees - ${stats.totalPrimaryNominees} primary`
        }

        let shortPlans = "";
        if(member.shortPlans && member.shortPlans.length > 0) {
            shortPlans = `(${member.shortPlans.join(", ")})`
            label += `\n${shortPlans}`
        }

        let selectedLabel = member.name;
        let displayLabel = `${member.name} ${shortPlans}`;
        return { data: member, label, selectedLabel, displayLabel, value: member.emplid };
    }

    buildNomineeSelectOption = (nominee) => {
        let selectedLabel = this.buildNomineeName(nominee);
        let label = selectedLabel;
        
        let shortPlan = "";
        if(nominee.shortPlan) {
            shortPlan = `(${nominee.shortPlan})`;
            selectedLabel += ` ${shortPlan}`;
            label += `\n${shortPlan}`;
        }

        let displayLabel = selectedLabel;
        return { data: nominee, label, selectedLabel, displayLabel, value: nominee.nomineeId };
    }

    buildNomineeName = (nominee) => {
        return `${nominee.lastName}, ${nominee.firstName}`;
    }

    findNominees = (reviewerId) => {
        this.props.findNominees(this.props.systemAward.pk, reviewerId);
    }

    swapNominees = () => {
        if(this.state.swapFromMemberOption !== null && this.state.swapToMemberOption !== null) {
            this.setState({ swapped: true }, () => { 
                this.props.transferReviewerPackets(this.props.systemAward.pk, this.state.swapFromMemberOption.value, this.state.swapToMemberOption.value);
            });
        }
    }

    distributeNominees = () => {
        if(this.state.distributeFromMemberOption !== null) {
            this.setState({ distributed: true }, () => {
                this.props.distributeReviewerPackets(this.props.systemAward.pk, this.state.distributeFromMemberOption.value);
            });
        }
    }

    transferNominee = () => {
        if(this.state.transferFromMemberOption !== null && this.state.transferNomineeOption !== null && this.state.transferToMemberOption !== null) {
            this.setState({ transferred: true }, () => {
                this.props.transferReviewerNomineePackets(this.props.systemAward.pk, this.state.transferNomineeOption.value, 
                    this.state.transferFromMemberOption.value, this.state.transferToMemberOption.value);
            });
        }
    }

    render() {
        return (
            <div className="mt-4">
                {this.renderPageContent()}
            </div>
        );
    }

    renderPageContent = () => {
        const isLoading = (this.props.awardReviewersLoading === true || this.props.transferReviewerPacketsProcessing === true || 
            this.props.distributeReviewerPacketsProcessing === true || this.props.transferReviewerNomineePacketsProcessing === true);
        const noCommitteeMembers = (this.state.memberSelectOptions.length === 0);
        return (
            <div>
                {this.props.awardReviewersLoading &&
                    <OSULoading dataTestId="committeeMembersLoadingIcon" text="Loading Committee Members..." />
                }
                {this.props.transferReviewerPacketsProcessing && 
                    <OSULoading dataTestId="transferReviewerPacketsProcessingIcon" text="Transferring Packets..." />
                }
                {this.props.distributeReviewerPacketsProcessing && 
                    <OSULoading dataTestId="distributeReviewerPacketsProcessingIcon" text="Distributing Packets..." />
                }
                {this.props.transferReviewerNomineePacketsProcessing &&
                    <OSULoading dataTestId="transferReviewerNomineePacketsProcessingIcon" text="Transferring Packet..." />
                }
                {(!isLoading && noCommitteeMembers) &&
                    <Body1 dataTestId="noCommitteeMembersMessage">There are currently no members assigned to this committee.  Manage members in Committee Selection.</Body1>
                }
                <div data-testid="pageContent" className={(isLoading || noCommitteeMembers) ? "d-none" : ""}>
                    {this.renderActions()}
                    {this.state.action !== null &&
                        <hr />
                    }
                    {this.renderSelectedAction()}
                </div>
            </div>
        );
    }

    renderActions = () => {
        const Actions = this.state.actions.map(action => {
            return (
                <div key={action.value} className="radio ml-2">
                    <label>
                        <input type="radio" data-testid={`action-${action.value}`} value={action.value} 
                            checked={this.state.action === action.value} aria-describedby={`actionDesc-${action.value}`}
                            onChange={e => {
                                this.setState({ action: e.target.value });
                                this.props.clearFlags();
                            }} />
                        <Body2 className="d-inline ml-2">{action.label}</Body2>
                        <span id={`actionDesc-${action.value}`} className="hat">{action.desc}</span>
                    </label>
                </div>
            );
        });
        return (
            <div>
                <Body1>Select an action to take on a current committee member:</Body1>
                <div className="row">
                    <div className="col">
                        {Actions}
                    </div>
                </div>
            </div>
        );
    }

    renderSelectedAction = () => {
        switch(this.state.action) {
            case ACTION_KEY_SWAP:
                return this.renderSwapAction();
            case ACTION_KEY_DISTRIBUTE:
                return this.renderDistributeAction();
            case ACTION_KEY_TRANSFER:
                return this.renderTransferAction();
            default:
                return null;
        };
    }

    renderSwapAction = () => {
        const swapToMemberInvalid = this.state.alternateSelectOptions.length === 0;
        return (
            <div>
                <div className="mb-4">
                    <form data-testid="swapFromMemberForm">
                        <label htmlFor="swapFromMemberSelect">Committee member who can NOT serve:</label>
                        <div className="ml-2" style={{maxWidth: SELECT_MAX_WIDTH}}>
                            <Select components={{ SingleValue: SelectedLabelSelect}} styles={SelectStyles}
                                inputId="swapFromMemberSelect" name="swapFromMemberSelect" placeholder="Select Committee Member..."
                                className="swapFromMemberSelect" classNamePrefix="swapFromMemberSelect"
                                options={this.state.memberSelectOptions} value={this.state.swapFromMemberOption}
                                isClearable={true} isSearchable={true} noOptionsMessage={() => { return "No Committee Members" }}
                                onChange={(e) => this.setState({ swapFromMemberOption: e, swapConflicts: [] }, () => {
                                    if(this.state.swapFromMemberOption !== null && this.state.swapToMemberOption !== null) {
                                        this.findNominees(this.state.swapFromMemberOption.value);
                                    }
                                })}/>
                        </div>
                    </form>
                </div>
                <div className="mb-4">
                    <form data-testid="swapToMemberForm">
                        <label htmlFor="swapToMemberSelect">Alternate who CAN serve:</label>
                        <div className="ml-2" style={{maxWidth: SELECT_MAX_WIDTH}}>
                            <Select components={{ SingleValue: SelectedLabelSelect}} styles={SelectStyles}
                                inputId="swapToMemberSelect" name="swapToMemberSelect" placeholder="Select Alternate..."
                                className="swapToMemberSelect" classNamePrefix="swapToMemberSelect"
                                options={this.state.alternateSelectOptions} value={this.state.swapToMemberOption}
                                isClearable={true} isSearchable={true} isInvalid={swapToMemberInvalid}
                                noOptionsMessage={() => { return "No Alternates" }}
                                onChange={(e) => this.setState({ swapToMemberOption: e, swapConflicts: [] }, () => {
                                    if(this.state.swapFromMemberOption !== null && this.state.swapToMemberOption !== null) {
                                        this.findNominees(this.state.swapFromMemberOption.value);
                                    }
                                })}/>
                        </div>
                    </form>
                    {swapToMemberInvalid &&
                        <Caption dataTestId="noAlternatesMessage" color="red" className="mt-1 ml-2">
                            There are no alternates to complete this action. Manage alternates in Committee Selection.
                        </Caption>
                    }
                </div>
                {!this.state.nomineesLoading && this.state.swapConflicts.length > 0 &&
                    <div data-testid="swapConflicts" className="mb-4">
                        Conflicts:
                        <Table dataKeys={this.state.swapConflictKeys} data={this.state.swapConflicts} headerVariant="subtitle2" 
                            rowHeight="1" border={false} hover={false} className="ml-2 mt-1" />
                    </div>
                }
                {this.state.swapFromMemberOption !== null && this.state.swapToMemberOption !== null &&
                    <div data-testid="swapCaption" className="mb-2">
                        <Caption>
                            Transfer all packets from {this.state.swapFromMemberOption.displayLabel + " "}
                            to {this.state.swapToMemberOption.displayLabel}.
                            {this.state.swapConflicts.length > 0 &&
                                " ABOVE CONFLICTS WILL TRANSFER. Use the “Conflict with nominee” action" + 
                                " to correct conflicts before or after the transfer."
                            }
                        </Caption>
                    </div>
                }
                <div>
                    <OSUButton solid color="blue" ariaLabel="Transfer Packets" onClick={this.swapNominees}
                        disabled={this.state.swapFromMemberOption === null || this.state.swapToMemberOption === null}>
                        Transfer Packets
                    </OSUButton>
                </div>
            </div>
        );
    }

    renderDistributeAction = () => {
        return (
            <div>
                <div className="mb-4">
                    <form data-testid="distributeFromMemberForm">
                        <label htmlFor="distributeFromMemberSelect">Committee member who can NOT serve:</label>
                        <div className="ml-2" style={{maxWidth: SELECT_MAX_WIDTH}}>
                            <Select components={{ SingleValue: SelectedLabelSelect}} styles={SelectStyles}
                                inputId="distributeFromMemberSelect" name="distributeFromMemberSelect" placeholder="Select Committee Member..."
                                className="distributeFromMemberSelect" classNamePrefix="distributeFromMemberSelect"
                                options={this.state.memberSelectOptions} value={this.state.distributeFromMemberOption}
                                isClearable={true} isSearchable={true} noOptionsMessage={() => { return "No Committee Members" }}
                                onChange={(e) => this.setState({ distributeFromMemberOption: e })} />
                        </div>
                    </form>
                </div>
                {this.state.distributeFromMemberOption !== null &&
                    <div data-testid="distributeCaption" className="mb-2">
                        <Caption>
                            Evenly distribute all packets from {this.state.distributeFromMemberOption.displayLabel + " "}
                            among assigned committee members.    
                        </Caption>
                    </div>
                }
                <div>
                    <OSUButton solid color="blue" ariaLabel="Distribute Packets" onClick={this.distributeNominees}
                        disabled={this.state.distributeFromMemberOption === null}>
                        Distribute Packets
                    </OSUButton>
                </div>
            </div>
        );
    }

    renderTransferAction = () => {
        let transferToMemberSelectOptions = _.map([...this.state.memberSelectOptions], _.clone);
        if(this.state.transferFromMemberOption !== null) { // filter transferFromMemberOption
            transferToMemberSelectOptions = _.filter(transferToMemberSelectOptions, option => {
                return option.value !== this.state.transferFromMemberOption.value;
            });
        }
        const transferNomineeInvalid = (this.state.transferFromMemberOption !== null && this.props.nomineesError);
        const transferConflict = (this.state.transferNomineeOption !== null && this.state.transferToMemberOption !== null && 
            this.state.transferToMemberOption.data.shortPlans.includes(this.state.transferNomineeOption.data.shortPlan));
        return (
            <div>
                <div className="mb-4">
                    <form data-testid="transferFromMemberForm">
                        <label htmlFor="transferFromMemberSelect">Committee member:</label>
                        <div className="ml-2" style={{maxWidth: SELECT_MAX_WIDTH}}>
                            <Select components={{ SingleValue: SelectedLabelSelect}} styles={SelectStyles}
                                inputId="transferFromMemberSelect" name="transferFromMemberSelect" placeholder="Select Committee Member..."
                                className="transferFromMemberSelect" classNamePrefix="transferFromMemberSelect"
                                options={this.state.memberSelectOptions} value={this.state.transferFromMemberOption}
                                isClearable={true} isSearchable={true} noOptionsMessage={() => { return "No Committee Members" }}
                                onChange={(e) => {
                                    this.setState({ transferFromMemberOption: e, transferNomineeOption: null, nomineeSelectOptions: [] }, () => {
                                        if(this.state.transferFromMemberOption !== null) this.findNominees(this.state.transferFromMemberOption.value);
                                    });
                                }}/>
                        </div>
                    </form>
                </div>
                <div className="mb-4">
                    <form data-testid="transferNomineeForm">
                        <label htmlFor="transferNomineeSelect">Nominee/Applicant:</label>
                        <div className="ml-2" style={{maxWidth: SELECT_MAX_WIDTH}}>
                            <Select components={{ SingleValue: SelectedLabelSelect}} styles={SelectStyles}
                                inputId="transferNomineeSelect" name="transferNomineeSelect" placeholder="Select Nominee/Applicant..."
                                className="transferNomineeSelect" classNamePrefix="transferNomineeSelect"
                                options={this.state.nomineeSelectOptions} value={this.state.transferNomineeOption}
                                isClearable={true} isSearchable={true} isLoading={this.props.nomineesLoading}
                                isInvalid={transferNomineeInvalid} loadingMessage={() => { return "Loading Nominees/Applicants..." }}
                                noOptionsMessage={() => { return this.state.transferFromMemberOption === null ? "Requires Committee Member" : "No Nominees/Applicants"; }}
                                onChange={(e) => this.setState({ transferNomineeOption: e })} />
                            <Caption dataTestId="nomineesErrorMessage" color="red" className={`align-items-center mt-1 ${(transferNomineeInvalid) ? "d-flex" : "d-none"}`}>
                                Failed to load Nominees/Applicants.
                                <OSUButton link color="red" uppercase={false} className="d-inline ml-1" ariaLabel="Reload Nominees/Applicants"
                                    onClick={(e) => { e.preventDefault(); this.findNominees(this.state.transferFromMemberOption.value);}}>Reload</OSUButton>
                            </Caption>
                        </div>
                    </form>
                </div>
                <div className="mb-4">
                    <form data-testid="transferToMemberForm">
                        <label htmlFor="transferToMemberSelect">Committee member to RECEIVE Nominee/Applicant:</label>
                        <div className="ml-2" style={{maxWidth: SELECT_MAX_WIDTH}}>
                            <Select components={{ SingleValue: SelectedLabelSelect}} styles={SelectStyles}
                                inputId="transferToMemberSelect" name="transferToMemberSelect" placeholder="Select Committee Member..."
                                className="transferToMemberSelect" classNamePrefix="transferToMemberSelect"
                                options={transferToMemberSelectOptions} value={this.state.transferToMemberOption}
                                isClearable={true} isSearchable={true} noOptionsMessage={() => { return "No Committee Members" }}
                                onChange={(e) => this.setState({ transferToMemberOption: e })} />
                        </div>
                    </form>
                </div>
                {transferConflict === true &&
                    <div data-testid="transferConflictMessage" className="mb-4">
                        <Caption color="red"><Icon color="red" type="exclamation" className="mr-1" />
                            The nominee/applicant and recipient committee member share an academic program.
                        </Caption>
                    </div>
                }
                {this.state.transferFromMemberOption !== null && this.state.transferNomineeOption !== null && this.state.transferToMemberOption !== null &&
                    <div data-testid="transferCaption" className="mb-2">
                        <Caption>
                            Transfer packet for {this.state.transferNomineeOption.displayLabel + " "} 
                            from {this.state.transferFromMemberOption.displayLabel + " "}
                            to {this.state.transferToMemberOption.displayLabel}.
                        </Caption>
                    </div>
                }
                <div>
                    <OSUButton solid color="blue" ariaLabel="Transfer Packet" onClick={this.transferNominee}
                        disabled={this.state.transferFromMemberOption === null || 
                            this.state.transferNomineeOption === null || 
                            this.state.transferToMemberOption === null}>
                        Transfer Packet
                    </OSUButton>
                </div>
            </div>
        );
    }
};