import React, { Component } from "react";
import { Alert } from "reactstrap";
import PropTypes from "prop-types";
import { isEmpty } from "lodash";
import { GPA_ELIGIBILITY_WAIVER_LIMITED, NOMINATIONS_LIMITED_BY, NOMINATIONS_LIMITED_BY_COLLEGE, NOMINATIONS_LIMITED_BY_PROGRAM } from "../../../AwardManagement/constants";

class CapsAndWaiversAlert extends Component {
    findCapsAndWaiversValue = (capsAndWaivers, key) => {
        let combine = key;
        let value = null;
        do {
            value = (capsAndWaivers[combine] || null);
            combine = (!isEmpty(value) ? (value.combine || null) : null);
        }
        while(combine !== null);
        return value;
    }

    render() {
        const { capsAndWaivers, college, gpaEligibilityWaiver, nominationsLimitedBy, academicProgram } = this.props;
        if(!isEmpty(capsAndWaivers)) {
            let capsAndWaiversValue = null;
            if(nominationsLimitedBy === NOMINATIONS_LIMITED_BY_COLLEGE) {
                capsAndWaiversValue = this.findCapsAndWaiversValue(capsAndWaivers, college);
            }
            if(nominationsLimitedBy === NOMINATIONS_LIMITED_BY_PROGRAM) {
                capsAndWaiversValue = this.findCapsAndWaiversValue(capsAndWaivers, academicProgram);
            }
            if(!isEmpty(capsAndWaiversValue)) {
                const { caps = 0, waivers = 0, usedCaps = 0, usedWaivers = 0 } = capsAndWaiversValue;
                const allCapsUsed = (caps === usedCaps);
                const allWaiversUsed = (waivers === usedWaivers);
                const alertColor = ((allCapsUsed || allWaiversUsed) ? "warning" : "info");
                const capsLabel = ((nominationsLimitedBy === NOMINATIONS_LIMITED_BY_COLLEGE) ? "Allocations" : "Caps" );
                return (
                    <Alert color={alertColor} data-testid="caps-and-waivers-alert">
                        <h2 className="heading6 sr-only">{capsLabel} and Waivers</h2>
                        <p>{caps} {NOMINATIONS_LIMITED_BY[nominationsLimitedBy]} {capsLabel}, {usedCaps} Used</p>
                        {gpaEligibilityWaiver === GPA_ELIGIBILITY_WAIVER_LIMITED &&
                            <p>{waivers} Eligibility Waivers, {usedWaivers} Used</p>
                        }
                    </Alert>
                );
            }
        }
        return null;
    }
}

CapsAndWaiversAlert.defaultProps = {
    academicProgram: "",
    capsAndWaivers: {},
    college: "",
    gpaEligibilityWaiver: "",
    nominationsLimitedBy: ""
}

CapsAndWaiversAlert.propTypes = {
    academicProgram: PropTypes.string,
    capsAndWaivers: PropTypes.object,
    college: PropTypes.string,
    gpaEligibilityWaiver: PropTypes.string,
    nominationsLimitedBy: PropTypes.string
}

export default CapsAndWaiversAlert;