import { NOMINEE_REVIEW, NOMINEE_REVIEW_STATUS, UPDATE_SCORES_STATUS } from "../../actions-index";

export function nomineeReviewStatus(state = '', action) {
    switch (action.type) {
        case NOMINEE_REVIEW_STATUS:
            return action.payload;
        default:
            return state
    }
}

export function nomineeReview(state = {}, action) {
    switch (action.type) {
        case NOMINEE_REVIEW:
            return action.payload
        default:
            return state
    }
}

export function updateScoresheetStatus(state = '', action) {
    switch (action.type) {
        case UPDATE_SCORES_STATUS:
            return action.payload;
        default:
            return state
    }
}