import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from "react-redux";
import { loadState, saveState } from './localStorage';
import configureStore from './storeConfig';
import 'bootstrap/dist/css/bootstrap.css';
import 'font-awesome/css/font-awesome.min.css'; 
import './index.scss';
import * as serviceWorker from './serviceWorker';
import AppContainer from './App/containers/App';
import cssVars from 'css-vars-ponyfill';

cssVars({ preserveVars: true }); // polyfill for vars in IE

const persistedState = loadState();
export const store = configureStore(persistedState);

store.subscribe(() => {
    saveState({
        systemAward: store.getState().systemAward,
        scoringCriteria: store.getState().scoringCriteria,
        gpaTypes: store.getState().gpaTypes,
        systemEmplid: store.getState().systemEmplid
    })
});

ReactDOM.render(<Provider store={store}>
    <BrowserRouter>
        <AppContainer />
    </BrowserRouter>
</Provider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
