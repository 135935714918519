import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import PrimaryReviewContent from '../components';
import { updatePrimaryReviewSortKey, updatePrimaryReviewDetails, getPrimaryReviewList, searchPrimaryReviewList, addPrimaryReviewFilter } from '../../../actions-index'

const mapStateToProps = (state, ownProps) => {
    const { systemAward, primaryReviewList } = state
    const { getWeightedScoreDefaultValue } = ownProps
    return {
        primaryReviewList: primaryReviewList.list || [],
        scoringCriteria: state.scoringCriteria,
        awardKey: systemAward && systemAward.pk ? systemAward.pk : null,
        noOfReviewers: systemAward && systemAward.reviewInfo && systemAward.reviewInfo.noOfReviewers ? systemAward.reviewInfo.noOfReviewers : null,
        primaryReviewDetails: state.primaryReviewList.details || [],
        primaryReviewListStatus: primaryReviewList.status,
        filters: primaryReviewList.filters,
        query: primaryReviewList.query || "",
        sortKey: primaryReviewList.sortKey,
        tabIdentifier: {
            index: ownProps.tabIndex,
            type: 'primary',
            title: ownProps.title
        },
        defaultScoreValue: getWeightedScoreDefaultValue(primaryReviewList.filters)
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updatePrimaryReviewDetails: (values) => dispatch(updatePrimaryReviewDetails(values)),
        getPrimaryReviewList: (awardKey, sortKey) => dispatch(getPrimaryReviewList(awardKey, sortKey)),
        searchPrimaryReviewList: (query) => dispatch(searchPrimaryReviewList(query)),
        addPrimaryReviewFilter: (filter) => dispatch(addPrimaryReviewFilter(filter)),
        updateSortKey: (sortKey) => dispatch(updatePrimaryReviewSortKey(sortKey))
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PrimaryReviewContent));