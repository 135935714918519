import { ASSIGNED_REVIEWERS, ASSIGNED_REVIEWERS_STATUS, ASSIGNED_REVIEWERS_QUERY, ADD_ASSIGNED_REVIEWERS_FILTERS, ADD_ASSIGNED_REVIEWERS_SORT_KEY } from '../../actions-index'
import { API } from "aws-amplify";
import { setApiStatus } from "../../redux-helpers";

export function findAssignedReviewersByAward(awardKey, sortKey = null) {
    const setAssignedReviewerStatus = (status) => setApiStatus(ASSIGNED_REVIEWERS_STATUS, status)

    return (dispatch) => {
        dispatch(setAssignedReviewerStatus('loading'));

        API.get(process.env.REACT_APP_AWARD_NOMINEE_REVIEW_API_NAME, `/getAssignedReviewersByAward/${window.btoa(awardKey)}`)
            .then(response => {
                if (response.data) {
                    dispatch(receiveAssignedReviewers(response.data));
                } else if(response.status!==undefined && response.status===401) {
                    console.error("401 Response in findAssignedReviewersByAward:",response.status);
                    dispatch(setAssignedReviewerStatus('401'));
                } else {
                    console.error("no Response data error findAssignedReviewersByAward:",response);
                    dispatch(setAssignedReviewerStatus('error'));
                }
            })
            .catch(error => {
                console.error("Error in findAssignedReviewersByAward:",error);
                if (error === 'No current user' || error==='Request failed with status code 401'|| (error.response!==undefined && error.response.status!==undefined && error.response.status===401)) {
                    dispatch(setAssignedReviewerStatus('401'));
                } else {
                    dispatch(setAssignedReviewerStatus('error'));
                }
            })
            .finally(() => sortKey && dispatch(updateAssignedReviewerSortKey(sortKey)))

    }
}

function receiveAssignedReviewers(list) {
    return {
        type: ASSIGNED_REVIEWERS,
        payload: list
    }
}

export function searchAssignedReviewers(query) {
    return {
        type: ASSIGNED_REVIEWERS_QUERY,
        payload: query
    }
}

export function addAssignedReviewerFilter(filter) {
    return {
        type: ADD_ASSIGNED_REVIEWERS_FILTERS,
        payload: filter
    }
}


export function updateAssignedReviewerSortKey(sortKey) {
    return {
        type: ADD_ASSIGNED_REVIEWERS_SORT_KEY,
        payload: sortKey
    }
}