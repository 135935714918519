import {
    SET_A11Y_MESSSAGE,
    SET_USER,
    USER_IS_LOADING,
    UPDATE_BREADCRUMB_TRAIL,
    SYSTEM_EMPLID,
    NOMINATOR_PROGRAMS,
    NOMINATOR_ROLES
} from '../actions-index';

export function a11yMessage(state = null, action = {}) {
    return action.type === SET_A11Y_MESSSAGE ? action.payload : state;
}

export function breadcrumbTrail(state = [], action) {
    switch (action.type) {
        case UPDATE_BREADCRUMB_TRAIL:
            return action.payload;
        default:
            return state;
    }
}

export function user(state = {}, action) {
    switch (action.type) {
        case SET_USER:
            return action.payload;
        default:
            return state;
    }
}

export function userIsLoading(state = true, action) {
    switch (action.type) {
        case USER_IS_LOADING:
            return action.payload;
        default:
            return state;
    }
}

export function systemEmplid(state = '200107678', action) {
    switch (action.type) {
        case SYSTEM_EMPLID:
            return action.payload;
        default:
            return state;
    }
}

export function nominatorPrograms(state = [], action = {}) {
    return (action.type === NOMINATOR_PROGRAMS ? action.payload : state);
}

export function nominatorRoles(state = [], action = {}) {
    return (action.type === NOMINATOR_ROLES ? action.payload : state);
}