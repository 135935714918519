import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import ReviewerDetails from "../components/ReviewerDetails";
import { findGraduateFacultyByEmplid, getAllAwards, inviteReviewerToAward, resetInviteReviewerToAwardState, resetSaveReviewerState, saveReviewer, updateBreadcrumbTrail } from "../../actions-index";

const mapStateToProps = (state, props) => {
    return {
        allAwards: state.allAwards,
        allAwardsError: state.allAwardsError,
        allAwardsLoading: state.allAwardsLoading,
        inviteReviewerToAwardDate: state.inviteReviewerToAwardDate,
        inviteReviewerToAwardError: state.inviteReviewerToAwardError,
        inviteReviewerToAwardProcessing: state.inviteReviewerToAwardProcessing,
        inviteReviewerToAwardSuccess: state.inviteReviewerToAwardSuccess,
        reviewerError: state.graduateFacultyByEmplidError,
        reviewerProcessing: state.graduateFacultyByEmplidProcessing,
        reviewer: state.graduateFacultyByEmplidData,
        saveReviewerError: state.saveReviewerError,
        saveReviewerIncomplete: state.saveReviewerIncomplete,
        saveReviewerProcessing: state.saveReviewerProcessing,
        saveReviewerSuccess: state.saveReviewerSuccess,
        props
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        findGraduateFacultyByEmplid: (emplid) => dispatch((findGraduateFacultyByEmplid(emplid))),
        getAllAwards: () => dispatch((getAllAwards())),
        inviteReviewerToAward: (emplid, awardKey, toEmailAddress, subject, body) => dispatch((inviteReviewerToAward(emplid, awardKey, toEmailAddress, subject, body))),
        resetInviteReviewerToAwardState: () => dispatch((resetInviteReviewerToAwardState())),
        resetSaveReviewerState: () => dispatch((resetSaveReviewerState())),
        saveReviewer: (reviewer) => dispatch((saveReviewer(reviewer))),
        updateBreadcrumbTrail: (pathObj) => dispatch(updateBreadcrumbTrail(pathObj))
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ReviewerDetails));