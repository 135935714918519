import React from "react";
import PropTypes from 'prop-types'
import { Link } from "react-router-dom";

const PDFLink = (props = {}) => {
  const { documentId, children, name, shortPlan, awardKey, ...rest } = props;
  let to = {
    pathname: `/ViewPdf/${documentId}`,
  }

  let queryStrings = []
  if(name) queryStrings.push(`name=${name}`)
  if(shortPlan) queryStrings.push(`plan=${shortPlan}`)
  if(awardKey) queryStrings.push(`awardPK=${encodeURIComponent(awardKey)}`)
  if(queryStrings.length > 0) {
    to.search = `?${queryStrings.join('&')}`
  }

  return (
    <Link
      target={`${documentId}`}
      rel="noopener noreferrer"
      to={to}
      {...rest}
    >
      {children}
    </Link>
  );
};

PDFLink.propTypes = {
  documentId: PropTypes.string,
  name: PropTypes.string,
  shortPlan: PropTypes.string,
  awardKey: PropTypes.string
}

export default PDFLink;
