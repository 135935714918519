import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { Body2, Icon, OSUButton, OSULoading, OSUError, Subtitle2 } from "osu-react-components";
import { Alert, Card, CardBody, CardTitle, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { AWARD_DATE_FORMAT, EVENT_RESET_STATE, NOMINATIONS_LIMITED_BY_COLLEGE, NOMINATIONS_LIMITED_BY_PROGRAM } from "../../constants";
import { AWARD_STATUS_KEY_ASSIGNED } from "../../../ReviewerManagement/constants";
import { cloneDeep, filter, forEach, forOwn, has, isEmpty, isEqual, remove } from "lodash";
import moment from "moment"
import AwardInformation from "./AwardInformation";
import Eligibility from "./Eligibility";
import CapsAndWaivers from "./CapsAndWaivers";
import ReviewerEmail from "./ReviewerEmail";
import Dates from "./Dates";
import ReviewInformation from "./ReviewInformation";
import AwardResults from "./AwardResults";
import AwardLetter from "./AwardLetter/";
import "../AwardDetails.css";
import { ACTION_STATE_ERROR, ACTION_STATE_LOADING, ACTION_STATE_SUCCESS } from "../../../util/constants";
import graduateColleges from "../data/graduateColleges.json";
import {PAGE_TITLE_POSTFIX} from "../../../constants";

export default class AwardDetails extends Component {
    constructor(props) {
        super(props);
        const { match = {} } = props;
        const params = match.params || {};
        const buildNavItem = (name, component) => {
            return { name, component };
        }
        const navItems = [
            buildNavItem("Award Information", AwardInformation),
            buildNavItem("Eligibility", Eligibility),
            buildNavItem("Caps and Waivers", CapsAndWaivers),
            buildNavItem("Reviewer Email", ReviewerEmail),
            buildNavItem("Dates", Dates),
            buildNavItem("Review Information", ReviewInformation),
            buildNavItem("Award Results", AwardResults),
            buildNavItem("Award Letter", AwardLetter)
        ];
        forEach(navItems, (navItem, index) => {
            navItem.id = (index + 1);
        });
        this.state = {
            activeNavItemId: navItems[0].id,
            targetNavItemId: null,
            award: null,
            contentAward: null,
            awardKey: params.awardKey ? window.atob(params.awardKey) : null,
            navItems,
            pageTitle: "Award Details",
            isEditMode: false,
            isLocked: false,
            saveEnabled: false,
            hasUnsavedChanges: false,
            unsavedChangesModalIsOpen: false,
            unsavedChangesModalShouldReturnFocus: false,
            deleteAwardModalIsOpen: false,
            deleteAwardModalShouldFocus: false,
            inReview: false,
            reconciledCapsAndWaivers: false
        }
    }

    componentWillMount() {
        this.setPageTitle();
    }

    componentDidMount() {
        this.props.setA11yMessage(`Navigated to the ${this.state.pageTitle} page`);
        this.pageContent.focus();
        this.loadAward();
    }

    componentDidUpdate(prevProps) {
        // received award
        const hasAward = (this.props.awardStatus === "success" && this.props.award !== null);
        if(prevProps.awardStatus !== "success" && hasAward) {
            const { award } = this.props;
            const { nominationInfo = {} } = award;
            let navItems = [...this.state.navItems];
            // update caps and waivers label when nominations are limited by college
            if(nominationInfo.nominationsLimitedBy === NOMINATIONS_LIMITED_BY_COLLEGE) {
                navItems[2].name = "Allocations and Waivers";
            }
            // remove review navItems when the award does not require review
            if(award.requireReview === false) {
                remove(navItems, (navItem, index) => {
                    return [3, 5].includes(index);
                });
                forEach(navItems, (navItem, index) => {
                    navItem.id = (index + 1);
                }); // reset ids
            }
            this.setState({ award, isEditMode: this.isEditMode(award), isLocked: this.isLocked(award), navItems }, () => {
                this.setPageTitle(); // append award name
            });
        }

        // received reviewers
        if(prevProps.reviewers.length === 0 && this.props.reviewers.length > 0) {
            // when there are assigned reviewers some data cannot be updated
            const assignedReviewers = filter(this.props.reviewers,  ["status", AWARD_STATUS_KEY_ASSIGNED]);
            this.setState({ inReview: assignedReviewers.length > 0 });
        }

        // delete award alerts
        if((prevProps.deleteAwardState !== ACTION_STATE_ERROR && this.props.deleteAwardState === ACTION_STATE_ERROR) ||
            (prevProps.deleteAwardState !== ACTION_STATE_SUCCESS && this.props.deleteAwardState === ACTION_STATE_SUCCESS)) {
            this.alerts.focus();
        }

        // award updated successfully
        if(prevProps.updateAwardState !== ACTION_STATE_SUCCESS && this.props.updateAwardState === ACTION_STATE_SUCCESS) {
            this.alerts.focus();
            const awardNameChange = (this.state.award.name !== this.state.contentAward.name);
            this.setState({ award: this.state.contentAward, contentAward: null, hasUnsavedChanges: false, targetNavItemId: null }, () => {
                if(awardNameChange) this.setPageTitle();
                document.dispatchEvent(new Event(EVENT_RESET_STATE)); // have all content pages reset state to use the newly updated award
            });
        }

        // award update error
        if(prevProps.updateAwardState !== ACTION_STATE_ERROR && this.props.updateAwardState === ACTION_STATE_ERROR) {
            this.alerts.focus();
        }

        // reconcile caps and waivers
        if(this.state.reconciledCapsAndWaivers === false && hasAward) {
            const { nominationInfo = {} } = this.props.award;
            const { nominationsLimitedBy = null } = nominationInfo;
            const needsGraduatePrograms = (nominationsLimitedBy === NOMINATIONS_LIMITED_BY_PROGRAM);
            const hasGraduatePrograms = (this.props.graduateProgramsState === ACTION_STATE_SUCCESS && this.props.graduatePrograms !== null);
            if(!needsGraduatePrograms || (needsGraduatePrograms && hasGraduatePrograms)) this.reconcileCapsAndWaivers();
        }
    }

    componentWillUnmount() {
        this.props.resetDeleteAwardState();
        this.props.resetUpdateAwardState();
    }

    setPageTitle = () => {
        let pageTitle = this.state.pageTitle;
        if(this.state.award && this.state.award.name) pageTitle += ` for ${this.state.award.name}  ${PAGE_TITLE_POSTFIX}`;
        document.title = pageTitle;
    }

    loadAward = () => {
        this.props.getAwardDetails(this.state.awardKey);
        this.props.findReviewersByAward(this.state.awardKey);
        if(this.props.graduateProgramsState !== ACTION_STATE_SUCCESS) {
            this.props.getGraduatePrograms();
        }
    }

    isEditMode = (award) => {
        let isEditMode = false;
        if(!!award.awardEndDate) {
            const awardEndDate = award.awardEndDate ? moment(award.awardEndDate, AWARD_DATE_FORMAT) : null;
            isEditMode = (awardEndDate && awardEndDate.isValid() && awardEndDate.isAfter());
        }
        return isEditMode;
    }

    isLocked = (award) => {
        let isLocked = false;
        const nominationStartDate = award.nominationStartDate ? moment(award.nominationStartDate, AWARD_DATE_FORMAT) : null;
        isLocked = (nominationStartDate && nominationStartDate.isValid() && nominationStartDate.isBefore());
        return isLocked;
    }

    reconcileCapsAndWaivers = () => {
        const state = { reconciledCapsAndWaivers: true };
        const award = cloneDeep(this.props.award);
        if(!award.capsAndWaivers) {
            award.capsAndWaivers = {};
            state.award = award;
        }
        const { nominationInfo = {} } = award;
        const { nominationsLimitedBy = null } = nominationInfo;

        let reconciliationData = null;
        if(nominationsLimitedBy === NOMINATIONS_LIMITED_BY_COLLEGE) reconciliationData = graduateColleges;
        if(nominationsLimitedBy === NOMINATIONS_LIMITED_BY_PROGRAM) reconciliationData = this.props.graduatePrograms;
        if(reconciliationData !== null) {
            forOwn(reconciliationData, (value, key) => { // add data keys not associated with the award
                if(!has(award.capsAndWaivers, key)) award.capsAndWaivers[key] = { combine: null, caps: 0, waivers: 0 };
            });
            if(!isEmpty(reconciliationData)) { // prevent all data keys from being removed when reconciliation data is an empty object
                forOwn(award.capsAndWaivers, (value, key) => { // remove data keys associated with the award that no longer exist
                    if(!has(reconciliationData, key)) delete award.capsAndWaivers[key];
                });
            }
            state.award = award;
        }
        this.setState(state, () => document.dispatchEvent(new Event(EVENT_RESET_STATE)));
    }

    skipTo = (elementId, preventScroll = false) => {
        var element = document.getElementById(elementId);
        if(element) {
            element.focus({ preventScroll });
        }
    }

    scrollToTop = () => {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    }

    setFocusOnContent = () => {
        this.content.focus({ preventScroll: true });
    }

    setActiveNavItem = (navItemId) => {
        if(navItemId !== this.state.activeNavItemId) {
            this.setState({ activeNavItemId: navItemId }, () => {
                this.setFocusOnContent();
                this.scrollToTop();
            });
        } else {
            this.setFocusOnContent();
        }
    }

    validateContent = (isValid, contentAward) => {
        const state = {};
        if(this.state.saveEnabled !== isValid) state.saveEnabled = isValid;
        if(!!contentAward) {
            state.contentAward = contentAward;
            const hasUnsavedChanges = !isEqual(this.state.award, contentAward);
            if(this.state.hasUnsavedChanges !== hasUnsavedChanges) state.hasUnsavedChanges = hasUnsavedChanges;
        }
        if(!isEmpty(state)) this.setState(state);
    }

    onNavigation = (navItemId) => {
        if(this.state.isEditMode && this.state.hasUnsavedChanges) {
            this.setState({ unsavedChangesModalIsOpen: true, targetNavItemId: navItemId })
        } else {
            this.setActiveNavItem(navItemId);
        }
    }

    onNavigationConfirmation = () => {
        const navItemId = this.state.targetNavItemId;
        const unsavedChangesModalShouldReturnFocus = false; // focus is set to the new content
        this.setState({ contentAward: null, unsavedChangesModalIsOpen: false, unsavedChangesModalShouldReturnFocus, hasUnsavedChanges: false, targetNavItemId: null }, () => {
            document.dispatchEvent(new Event(EVENT_RESET_STATE));
            this.setActiveNavItem(navItemId);
        })
    }

    onDeleteAwardConfirmation = () => {
        const deleteAwardModalShouldReturnFocus = false; // focus is set on the alert (success or error)
        this.setState({ deleteAwardModalIsOpen: false, deleteAwardModalShouldReturnFocus }, () => {
            this.props.deleteAward(this.state.award.pk);
        })
    }

    onSave = () => {
        this.props.updateAward(this.state.contentAward);
    }

    renderNavItem = (navItem) => {
        const isActive = (this.state.activeNavItemId === navItem.id);
        const TextContent = (<Fragment>{isActive && <span className="sr-only">Current page: </span>}{navItem.name}</Fragment>)
        const Element = React.createElement(isActive ? Subtitle2 : Body2, { className: "award-nav-item-name" }, TextContent);
        const onClick = () => { 
            this.onNavigation(navItem.id);
        };
        const onKeyPress = event => {
            if([event.which, event.keyCode].includes(13)) onClick();
        }
        return (
            <div role="button" id={`navigation${navItem.id}`} key={`navigation${navItem.id}`} tabIndex="0" aria-pressed={isActive} 
                className={`${isActive ? "active " : ""}award-nav-item mb-3 pl-4 pr-2 py-3`}
                onClick={onClick} 
                onKeyPress={onKeyPress}>
                {Element}
                {isActive && <span data-testid="activeIcon" aria-hidden="true" className="d-inline-block"><Icon type="chevron-right" color="gray" /></span> }
            </div>
        );
    }

    renderContent = (navItem) => {
        const { deleteAwardState, graduatePrograms } = this.props;
        const { award, inReview, isEditMode, isLocked } = this.state;
        const isActive = (this.state.activeNavItemId === navItem.id);
        const deleteAward = () => this.setState({ deleteAwardModalIsOpen: true });
        const isDeleted = (deleteAwardState === ACTION_STATE_SUCCESS);
        return (
            <div id={`content${navItem.id}`} key={`content${navItem.id}`} 
                className={`award-content ${(this.state.activeNavItemId !== navItem.id) ? "d-none" : ""}`}>
                <navItem.component award={award} isActive={isActive} deleteAward={deleteAward} isDeleted={isDeleted} inReview={inReview} onValidate={this.validateContent} readOnly={!isEditMode} isLocked={isLocked} graduatePrograms={graduatePrograms} />
            </div>
        );
    }

    renderPageContent = () => {
        const { award, awardStatus, graduateProgramsState, reviewersError, reviewersLoading } = this.props;
        if(awardStatus === "loading" || reviewersLoading === true || graduateProgramsState === ACTION_STATE_LOADING) {
            return (<OSULoading text="Loading Award..." />);
        } else if(awardStatus === "401") {
            // Auth.signOut();
            // redirectToLogOut();
            console.log("oh no");
        } else if(awardStatus === "error" || award === null || reviewersError === true || graduateProgramsState === ACTION_STATE_ERROR) {
            return (<OSUError text="An error occurred while loading the award details." ariaLabel="Reload the award details" onClick={() => this.loadAward()} />);
        } else if(this.state.award !== null) {
            const showPreviousButton = (this.state.activeNavItemId !== this.state.navItems[0].id);
            const showNextButton = (this.state.activeNavItemId !== this.state.navItems[this.state.navItems.length - 1].id);
            const deletingAward = (this.props.deleteAwardState === ACTION_STATE_LOADING);
            const updatingAward = (this.props.updateAwardState === ACTION_STATE_LOADING);
            return (
                <div>
                    <div data-testid="alerts" ref={(el) => { this.alerts = el; }} tabIndex="-1" className="ml-4">
                        <Alert data-testid="delete-award-error-alert" color="danger" isOpen={this.props.deleteAwardState === ACTION_STATE_ERROR} toggle={this.props.resetDeleteAwardState}>
                            <Subtitle2>Delete Award Failure</Subtitle2>
                            <p className="mb-0">Failed to delete the award. Please retry to see if it resolves the issue.</p>
                        </Alert>
                        <Alert data-testid="delete-award-success-alert" color="success" isOpen={this.props.deleteAwardState === ACTION_STATE_SUCCESS}>
                            <Subtitle2>Delete Award Success</Subtitle2>
                            <p>Successfully deleted the award.  Return to <Link data-testid="award-management-link" to="/award-management">Award Management</Link>.</p>
                        </Alert>
                        <Alert data-testid="update-award-error-alert" color="danger" isOpen={this.props.updateAwardState === ACTION_STATE_ERROR} toggle={this.props.resetUpdateAwardState}>
                            <Subtitle2>Save Award Failure</Subtitle2>
                            <p className="mb-0">Failed to save the award. Please retry to see if it resolves the issue.</p>
                        </Alert>
                        <Alert data-testid="update-award-success-alert" color="success" isOpen={this.props.updateAwardState === ACTION_STATE_SUCCESS} toggle={this.props.resetUpdateAwardState}>
                            <Subtitle2>Save Award Success</Subtitle2>
                            <p>Successfully saved the award.</p>
                        </Alert>
                    </div>
                    {deletingAward &&
                        <OSULoading dataTestId="deleting-award" text="Deleting Award..." />
                    }
                    {updatingAward &&
                        <OSULoading dataTestId="updating-award" text="Saving Award..." />
                    }
                    <div data-testid="nav-and-content" className={(deletingAward || updatingAward) ? "d-none" : "d-flex flex-xs-wrap"}>
                        <nav id="navigation" data-testid="navigation" tabIndex="-1" className="flex-column mb-4" style={{ minWidth: "13rem" }}>
                            <div className="pl-4 pb-4">
                                <OSUButton link uppercase={false} ariaLabel="Skip To Content" tabIndex={0} className="sr-only sr-only-focusable"
                                    onClick={() => this.skipTo("content", true)}>Skip To Content</OSUButton>
                            </div>
                            {this.state.navItems.map(navItem => {
                                return this.renderNavItem(navItem);
                            })}
                        </nav>
                        <div id="content" data-testid="content" tabIndex="-1" ref={(el) => { this.content = el; }} 
                            className="flex-column pl-4 w-100" style={{ minWidth: "17rem" }}>
                            {this.state.navItems.map(navItem => {
                                return this.renderContent(navItem);
                            })}
                            <div className="mt-5">
                                <div className="float-right">
                                    <OSUButton ariaLabel="Previous Navigation Item" link outline color="blue"
                                        className={`mr-2${!showPreviousButton ? " d-none" : ""}`}
                                        disabled={!showPreviousButton}
                                        onClick={() => this.onNavigation(this.state.activeNavItemId - 1)}>
                                        Previous
                                    </OSUButton>
                                    {this.state.isEditMode &&
                                        <OSUButton ariaLabel="Save Award" className="mx-2" color="blue"
                                            disabled={!(this.state.saveEnabled && this.state.hasUnsavedChanges)} onClick={() => this.onSave()}>
                                            Save
                                        </OSUButton>
                                    }
                                    <OSUButton ariaLabel="Next Navigation Item" link outline color="blue"
                                        className={`ml-2${!showNextButton ? " d-none" : ""}`}
                                        disabled={!showNextButton}
                                        onClick={() => this.onNavigation(this.state.activeNavItemId + 1)}>
                                        Next
                                    </OSUButton>
                                </div>
                            </div>
                            <div className="pt-4">
                                <OSUButton link uppercase={false} ariaLabel="Skip To Navigation" tabIndex={0} className="sr-only sr-only-focusable"
                                    onClick={() => { this.skipTo("navigation", false); this.scrollToTop(); }}>Skip To Navigation</OSUButton>
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            return null;
        }
    }

    render() {
        const { award } = this.state;
        return (
            <div data-testid="pageContent" ref={(el) => { this.pageContent = el; }} tabIndex="-1" aria-labelledby="pageHeader">
                <Card>
                    <CardTitle className="rounded-top mb-0">
                        <h1 id="pageHeader" data-testid="pageHeader" className="heading5 mt-2 ml-4">
                            <span className="sr-only">{`${this.state.pageTitle}${award ? " for " : ""}`}</span>{award && <Fragment>{award.name}</Fragment>}
                        </h1>
                        {award && <hr className="mt-2 mb-0" />}
                    </CardTitle>
                    <CardBody className="pl-0 pr-4" style={{ minHeight: "37.9rem" }}>
                        {this.renderPageContent()}
                    </CardBody>
                </Card>
                <Modal data-testid="unsavedChangesModal" autoFocus={true} isOpen={this.state.unsavedChangesModalIsOpen}
                    onOpened={() => this.setState({ unsavedChangesModalShouldReturnFocus: true })}
                    returnFocusAfterClose={this.state.unsavedChangesModalShouldReturnFocus}>
                    <ModalHeader>Unsaved Changes</ModalHeader>
                    <ModalBody><Body2>There are unsaved changes that will be lost if not saved.  Please confirm that you would like to continue.</Body2></ModalBody>
                    <ModalFooter>
                        <OSUButton ariaLabel="Cancel navigation" color="gray" solid uppercase={false} className="mr-1" 
                            onClick={() => this.setState({ unsavedChangesModalIsOpen: false })}>
                            Cancel
                        </OSUButton>
                        <OSUButton ariaLabel="Continue navigation and lose unsaved changes" color="blue" solid uppercase={false}
                            onClick={this.onNavigationConfirmation}>
                            Confirm
                        </OSUButton>
                    </ModalFooter>
                </Modal>
                <Modal data-testid="deleteAwardModal" autoFocus={true} isOpen={this.state.deleteAwardModalIsOpen}
                    onOpened={() => this.setState({ deleteAwardModalShouldReturnFocus: true })}
                    returnFocusAfterClose={this.state.deleteAwardModalShouldReturnFocus}>
                    <ModalHeader>Delete Award</ModalHeader>
                    <ModalBody><Body2>Are you sure that you want to delete this award?</Body2></ModalBody>
                    <ModalFooter>
                        <OSUButton ariaLabel="Cancel award deletion" color="gray" solid uppercase={false} className="mr-1" 
                            onClick={() => this.setState({ deleteAwardModalIsOpen: false })}>
                            Cancel
                        </OSUButton>
                        <OSUButton ariaLabel="Confirm award deletion" color="blue" solid uppercase={false}
                            onClick={this.onDeleteAwardConfirmation}>
                            Confirm
                        </OSUButton>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }
}