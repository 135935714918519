import React from 'react';
import '../Footer.css';


const mark = require('../../assets/osu-web-header-horiz.png')


const Footer = (props) =>{
    return props.pathName.includes('/ViewPdf') || (
    <footer>
       <div className="m-3">
          <a aria-label="The Ohio State University home page" href="https://www.osu.edu/">
              <img alt="" className={'osu-wordmark'} src={mark} />
          </a>
      </div>
      <div className="mx-3 my-2">© 2020 - The Ohio State University Graduate School</div>
      <div className="mx-3">250 University Hall | 230 N Oval Mall | Columbus OH 43210</div>
      <div className="mx-3">
          Fellowship Services Email: <a href="mailto:grad-schoolfellowships@osu.edu">grad-schoolfellowships@osu.edu</a>
      </div>
      <div variant="body2" className="mx-3 my-2">
          If you have accessibility needs using the Graduate School Fellowship and Awards system, please email <a href="mailto:grad-schoolaccessibility@osu.edu">grad-schoolaccessibility@osu.edu</a> for support.
      </div>
    </footer>
  )
}

export default Footer;